import {
  Box,
  Button,
  CheckBox,
  FormField,
  Heading,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextInput,
} from 'grommet';
import { Clock } from 'grommet-icons';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import DaySchedules from '@Components/setup-users-day-schedule/setup-users-day-schedule.component';
import { TimeSelect } from '@Pages/setup-users/setup-users.styles';
import { setCurrentAdvancedUser } from '@State/setup-advanced-users/setup-advanced-users.actions';
import { selectAdvancedCurrentUsers } from '@State/setup-advanced-users/setup-advanced-users.selectors';
import {
  getDetailsForOrderSelectedStart,
  getDetailsForOrderSelectedSuccess,
  updateDetailsForOrderSelectedStart,
} from '@State/setup-users-details-for-order-selected-in-user/setup-users-details-for-order-selected-in-user.actions';
import { selectDetailsForOrderSelected } from '@State/setup-users-details-for-order-selected-in-user/setup-users-details-for-order-selected-in-user.selectors';
import { pushOrderToDeleteQueueStart } from '@State/setup-users-order-selected-in-user/setup-users-order-selected-in-user.actions';
import {
  selectCurrentOrdersSelected,
  selectOrdersSelected,
} from '@State/setup-users-order-selected-in-user/setup-users-order-selected-in-user.selectors';
import { selectCurrentUser } from '@State/user/user.selectors';

const SetupUsersOrdersDetailsTab = (props) => {
  const dayDropdown = [
    { day: 'Sun', data: [{ key: 'MdfyIntvSund', desc: 'MdfyIntvSundDesc' }] },
    { day: 'Mon', data: [{ key: 'MdfyIntvMond', desc: 'MdfyIntvMondDesc' }] },
    { day: 'Tue', data: [{ key: 'MdfyIntvTues', desc: 'MdfyIntvTuesDesc' }] },
    { day: 'Wed', data: [{ key: 'MdfyIntvWedn', desc: 'MdfyIntvWednDesc' }] },
    { day: 'Thu', data: [{ key: 'MdfyIntvThur', desc: 'MdfyIntvThurDesc' }] },
    { day: 'Fri', data: [{ key: 'MdfyIntvFrid', desc: 'MdfyIntvFridDesc' }] },
    { day: 'Sat', data: [{ key: 'MdfyIntvSatr', desc: 'MdfyIntvSatrDesc' }] },
  ];
  const { getDetailsForOrderSelected, updateDetailsForOrderSelected, timeSelection } = props;

  const [showSaveCancelBtn, setShowSaveCancelBtn] = useState(true);
  const [showDeleteBtn, setShowdeleteBtn] = useState(false);
  const [showDeleteOrder, setShowDeleteOrder] = useState(false);
  const [orders, setOrders] = useState([]);
  useEffect(() => {
    // getDetailsForOrderSelected();
    // updateDetailsForOrderSelected();
  }, []);

  useEffect(() => {
    if (props.ordersSelected !== null) {
      if (typeof props.ordersSelected.data !== 'undefined') {
        setOrders(props.ordersSelected.data);
      } else {
        setOrders([]);
      }
    } else {
      setOrders([]);
    }
  }, [props.ordersSelected]);

  const handleChange = (event) => {
    const { value, name } = event.target;
    const currentadvancedOrder = props.detailsForOrderSelected;
    let newValue = '';
    const result = {};

    {
      value === '1' ? (newValue = '0') : (newValue = '1');
    }
    result[name] = newValue;
    if (name.endsWith('Mdfy')) {
      const view = name.replace('Mdfy', 'View');
      result[view] = newValue;
    } else if (value === '1') {
      const modify = name.replace('View', 'Mdfy');
      result[modify] = newValue;
    }
    props.setDetailsForOrderSelected({ ...currentadvancedOrder, ...result });
  };

  const onAllChecboxes = (value) => {
    const currentadvancedOrder = props.detailsForOrderSelected;
    const result = {};

    props.data.map((item) => {
      item.boxes.map((box, i) => {
        result[box.key] = value;
      });
    });
    props.setDetailsForOrderSelected({ ...currentadvancedOrder, ...result });
  };

  const onTimeChange = (time) => {
    props.setDetailsForOrderSelected({
      ...props.detailsForOrderSelected,
      CutfTime: formatCufOffTime(time),
    });
    setShowSaveCancelBtn(false);
    setShowdeleteBtn(true);
  };

  const formatCufOffTime = (time) => {
    if (time.includes('AM') || time.includes('PM')) {
      time = moment(time, 'hh:mm:ss A').format('HH:mm:ss');
    } else {
      time += ':00';
    }

    return moment().format('YYYY-MM-DDT') + time;
  };

  return (
    <>
      {props.detailsForOrderSelected ? (
        <Box>
          {/* {showDeleteOrder ?
            <DeleteModal
              type="Order"
              onClose={setShowDeleteOrder}
              data={orders}
              confirm={(data) => updateDetailsForOrderSelected(data)}
            />
            : null} */}
          <Heading margin="0" pad="medium" level={5}>
            Order Details - {props.currentOrderSelected.ObjDesc}{' '}
          </Heading>
          <Box alignSelf="start" direction="row">
            <Button
              label="Clear All"
              onClick={() => {
                onAllChecboxes('0');
                setShowSaveCancelBtn(false);
              }}
            />
            <Button
              label="Set All"
              onClick={() => {
                onAllChecboxes('1');
                setShowSaveCancelBtn(false);
              }}
            />
            <Button
              label="Save"
              disabled={showSaveCancelBtn}
              onClick={() => {
                setShowdeleteBtn(false);
                setShowSaveCancelBtn(true);
                updateDetailsForOrderSelected('1');
              }}
            />
            <Button
              label="Cancel"
              disabled={showSaveCancelBtn}
              onClick={() => {
                setShowSaveCancelBtn(true);
                setShowdeleteBtn(false);
                getDetailsForOrderSelected();
              }}
            />
            {/* <Button label="Delete" disabled={showDeleteBtn} onClick={() => { setShowDeleteOrder(true); }} /> */}
          </Box>
          <Heading margin="0" pad="medium" level={5}>
            Privileges
          </Heading>
          <Table>
            <TableBody>
              {props.data.map((item) => (
                <TableRow key={item.name}>
                  {item.boxes.map((box, i) => (
                    <TableCell key={box.key}>
                      <CheckBox
                        id={box.key}
                        name={box.key}
                        checked={props.detailsForOrderSelected[box.key] === 1}
                        value={props.detailsForOrderSelected[box.key]}
                        label={box.label}
                        onChange={handleChange}
                        onClick={() => {
                          setShowSaveCancelBtn(false);
                          setShowdeleteBtn(true);
                        }}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Heading margin="0" pad="medium" level={5}>
            Count Linen Schedule
          </Heading>
          <Box direction="row">
            <DaySchedules
              setShowSaveCancelBtn={setShowSaveCancelBtn}
              data={dayDropdown}
              type="orderDetails"
            />
          </Box>
          <Box alignSelf="start" direction="row">
            <FormField label="Cut Off Time" htmlFor="CutfTime" {...props}>
              <TextInput
                type="time"
                id="CutfTime"
                name="CutfTime"
                value={moment(props.detailsForOrderSelected.CutfTime.slice(0, 19)).format(
                  'HH:mm:ss',
                )}
                onChange={(event) => onTimeChange(event.target.value)}
              />
            </FormField>
            <TimeSelect
              id="select"
              name="select"
              placeholder="Select"
              value=""
              options={timeSelection.options}
              icon={
                <Box>
                  <Clock color="black" />
                </Box>
              }
              plain
              onChange={({ option }) => onTimeChange(option)}
            />
          </Box>
        </Box>
      ) : null}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  advancedCurrentUser: selectAdvancedCurrentUsers,
  detailsForOrderSelected: selectDetailsForOrderSelected,
  ordersSelected: selectOrdersSelected,
  currentOrderSelected: selectCurrentOrdersSelected,
});

const mapDispatchToProps = (dispatch) => ({
  setDetailsForOrderSelected: (order) => dispatch(getDetailsForOrderSelectedSuccess(order)),
  getDetailsForOrderSelected: () => dispatch(getDetailsForOrderSelectedStart()),
  updateDetailsForOrderSelected: (order) => dispatch(updateDetailsForOrderSelectedStart(order)),
  pushOrderToDeleteQueue: (order) => dispatch(pushOrderToDeleteQueueStart(order)),
  setCurrentUser: (user) => dispatch(setCurrentAdvancedUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SetupUsersOrdersDetailsTab));

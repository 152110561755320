import {
  Button,
  CheckBox,
  Heading,
  Layer,
  List,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Text,
  TextInput,
} from 'grommet';
import moment from 'moment';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const Selection = styled(List)`
  li {
    span {
      font-size: 12px;
    }
  }
`;

const GlobalAdjustmentsModal = ({
  showModal,
  toggleModal,
  data,
  currentDate,
  updateDeliveriesForGlobalAdjustmentsOnDate,
}) => {
  const [ordersData, setOrdersData] = useState([]);
  const [itemsData, setItemsData] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedRemoveOrders, setSelectedRemoveOrders] = useState([]);
  const [selectedRemoveItems, setSelectedRemoveItems] = useState([]);
  const [selectedOrdersList, setSelectedOrdersList] = useState([]);
  const [selectedItemsList, setSelectedItemsList] = useState([]);
  const [orderListProp, setOrderListProp] = useState({});
  const [itemListProp, setItemListProp] = useState({});
  const [orderRemoveListProp, setOrderRemoveListProp] = useState({});
  const [itemRemoveListProp, setItemRemoveListProp] = useState({});
  const [percentOf, setPercentOf] = useState({});
  const [adjustment, setAdjustment] = useState(100);
  const [prefill, setPrefill] = useState(false);
  const [show, setShow] = useState(showModal);
  const style = {
    height: '300px',
    overflowY: 'scroll',
  };

  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  useEffect(() => {
    setOrdersData(data.orderTable);
    setItemsData(data.itemTable);
    setAdjustment(100);
    setPrefill(false);
    if (data.hasOwnProperty('adjustType')) {
      const index = data.adjustType.findIndex((item) => item.ObjtDesc === 'Par');
      if (index >= 0) {
        setPercentOf(data.adjustType[index]);
      }
    }
  }, [data]);

  const onCancel = () => {
    setSelectedOrders([]);
    setSelectedItems([]);
    setSelectedOrdersList([]);
    setSelectedItemsList([]);
    setOrderListProp({});
    setItemListProp({});
    setSelectedRemoveOrders([]);
    setSelectedRemoveItems([]);
    setOrderRemoveListProp({});
    setItemRemoveListProp({});
    toggleModal({ id: 'GlobalAdjustments', data: [] });
  };

  const evalutateData = () => {
    const orders = [];
    const items = [];

    for (const i in selectedOrders) {
      const row = selectedOrders[i];
      row.Usef = true;
      delete row.index;
      orders.push(row);
    }

    for (const i in selectedItems) {
      const row = selectedItems[i];
      row.Usef = true;
      delete row.index;
      items.push(row.data);
    }

    setSelectedOrders(orders);
    setSelectedItems(items);
  };

  const onShiftSelect = (row, type, mode) => {
    const selected = [];

    const listProps = {};
    let startIndex = 0;
    let endIndex = 0;
    const isTypeOrder = type === 'Orders';
    const isAdd = mode === 'add';
    let keys = isTypeOrder
      ? isAdd
        ? orderListProp
        : orderRemoveListProp
      : isAdd
      ? itemListProp
      : itemRemoveListProp;
    keys = Object.keys(keys);
    const firstKey = keys.length > 0 ? keys[0] : 0;

    if (isTypeOrder) {
      if (isAdd) {
        setOrderListProp({});
      } else {
        setOrderRemoveListProp({});
      }
    } else if (isAdd) {
      setItemListProp({});
    } else {
      setItemRemoveListProp({});
    }

    if (row.index > firstKey) {
      startIndex = keys[keys.length - 1];
      endIndex = row.index;
    } else {
      startIndex = row.index;
      endIndex = firstKey;
    }

    for (let i = startIndex; i <= endIndex; i++) {
      const temp = isTypeOrder
        ? isAdd
          ? ordersData[i]
          : selectedOrdersList[i]
        : isAdd
        ? itemsData[i]
        : selectedItemsList[i];
      temp.index = i;

      updateSelected(temp, selected, type, mode);
      listProps[i] = { background: 'brand' };
    }

    if (isTypeOrder) {
      if (isAdd) {
        setOrderListProp(listProps);
      } else {
        setOrderRemoveListProp(listProps);
      }
    } else if (isAdd) {
      setItemListProp(listProps);
    } else {
      setItemRemoveListProp(listProps);
    }
  };

  const onCtrlSelect = (row, type, mode) => {
    const isTypeOrder = type === 'Orders';
    const isAdd = mode === 'add';
    const selected = isTypeOrder
      ? isAdd
        ? [...selectedOrders]
        : [...selectedRemoveOrders]
      : isAdd
      ? [...selectedItems]
      : [...selectedRemoveItems];
    updateSelected(row.item, selected, type, mode);

    const listProps = isTypeOrder
      ? isAdd
        ? orderListProp
        : orderRemoveListProp
      : isAdd
      ? itemListProp
      : itemRemoveListProp;

    if (typeof listProps[row.index] === 'undefined') {
      listProps[row.index] = { background: 'brand' };
    } else {
      const temp = selected;
      delete listProps[row.index];
      for (const i in temp) {
        if (temp[i].index === row.index) {
          delete temp[i];
        }
      }
      if (isTypeOrder) {
        if (isAdd) {
          setOrderListProp(listProps);
          setSelectedOrders(temp);
        } else {
          setOrderRemoveListProp(listProps);
          setSelectedRemoveOrders(temp);
        }
      } else if (isAdd) {
        setItemListProp(listProps);
        setSelectedItems(temp);
      } else {
        setItemRemoveListProp(listProps);
        setSelectedRemoveItems(temp);
      }
    }
  };

  const onDefaultSelect = (row, type, mode) => {
    const isTypeOrders = type === 'Orders';
    const isAdd = mode === 'add';
    if (isTypeOrders) {
      if (isAdd) {
        setSelectedOrders([]);
        setOrderListProp({});
      } else {
        setSelectedRemoveOrders([]);
        setOrderRemoveListProp({});
      }
    } else if (isAdd) {
      setSelectedItems([]);
      setItemListProp({});
    } else {
      setSelectedRemoveItems([]);
      setItemRemoveListProp({});
    }
    updateSelected(row.item, [], type, mode);

    const listProps = {};
    if (typeof listProps[row.index] === 'undefined') {
      listProps[row.index] = { background: 'brand' };
      if (isTypeOrders) {
        if (isAdd) {
          setOrderListProp(listProps);
        } else {
          setOrderRemoveListProp(listProps);
        }
      } else if (isAdd) {
        setItemListProp(listProps);
      } else {
        setItemRemoveListProp(listProps);
      }
    }
  };

  const addSelectedRows = (row, type, mode = 'add') => {
    if (row.shiftKey) {
      onShiftSelect(row, type, mode);
    } else if (row.ctrlKey || row.metaKey) {
      onCtrlSelect(row, type, mode);
    } else {
      onDefaultSelect(row, type, mode);
    }
  };

  const updateSelected = (row, selected, type, mode) => {
    const rows = selected;
    const isAdd = mode === 'add';

    rows.push(row);
    if (type === 'Orders') {
      if (isAdd) {
        setSelectedOrders(rows);
      } else {
        setSelectedRemoveOrders(rows);
      }
    } else if (isAdd) {
      setSelectedItems(rows);
    } else {
      setSelectedRemoveItems(rows);
    }
  };

  const cloneObject = (object) => {
    return JSON.parse(JSON.stringify(object));
  };

  const addSelected = (type, mode) => {
    const isTypeOrders = type === 'Orders';
    let listData = [];
    let rows = [];
    const key = isTypeOrders ? 'ObjtRcID' : 'RcID';
    let index = 0;
    const isAdd = mode === 'add';
    const prevData = cloneObject(isTypeOrders ? data.orderTable : data.itemTable);
    const currentList = cloneObject(isTypeOrders ? ordersData : itemsData);

    if (isTypeOrders) {
      listData = cloneObject(isAdd ? ordersData : selectedOrdersList);
      rows = isAdd ? [...selectedOrders] : [...selectedRemoveOrders];
    } else {
      listData = cloneObject(isAdd ? itemsData : selectedItemsList);
      rows = isAdd ? [...selectedItems] : [...selectedRemoveItems];
    }

    rows.forEach((row) => {
      index = listData.findIndex((item) => item[key] === row[key]);
      listData.splice(index, 1);

      if (!isAdd) {
        index = prevData.findIndex((item) => item[key] === row[key]);
        currentList.splice(index, 0, prevData[index]);
      }
    });

    if (isTypeOrders && rows.length) {
      if (isAdd) {
        setSelectedOrdersList(selectedOrdersList.concat(rows));
        setOrdersData(listData);
        setOrderListProp({});
        setSelectedOrders([]);
      } else {
        setSelectedOrdersList(listData);
        setOrdersData(currentList);
        setOrderRemoveListProp({});
        setSelectedRemoveOrders([]);
      }
    } else if (rows.length) {
      if (isAdd) {
        setSelectedItemsList(selectedItemsList.concat(rows));
        setItemsData(listData);
        setItemListProp({});
        setSelectedItems([]);
      } else {
        setSelectedItemsList(listData);
        setItemsData(currentList);
        setItemRemoveListProp({});
        setSelectedRemoveItems([]);
      }
    }
  };

  const getLength = (data) => {
    return data ? data.length : 0;
  };

  const save = () => {
    const type = parseInt(percentOf.ObjtRcID);
    evalutateData();
    updateDeliveriesForGlobalAdjustmentsOnDate({
      formData: {
        orderTable: selectedOrdersList,
        itemTable: selectedItemsList,
        adjustTable: {
          id: 'AdjustTable1',
          AdjtPcnt: adjustment,
          AdjtType: type,
          Prefill: prefill,
        },
        requestedDay: moment(currentDate).format('YYYY-MM-DDT12:00:00'),
        iCallerUTCOffset: 0,
        adjustment,
        type,
        prefill,
      },
    });

    onCancel();
  };
  return (
    <>
      {show && (
        <Layer style={{ width: '87%' }} onEsc={onCancel} onClickOutside={onCancel}>
          <Table style={{ width: '100%' }}>
            <TableHeader>
              <TableRow>
                <TableCell colSpan="4">
                  <Heading level="4" textAlign="center" margin="auto">
                    Global Adjustments
                  </Heading>
                </TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableCell colSpan="4">
                  Restock Date {moment(currentDate).format('MM/DD/YYYY')}
                </TableCell>
              </TableRow>
              <TableRow>
                {/* <TableCell colSpan="4">Use CTRL/SHIFT+Click to select multiple items</TableCell> */}
              </TableRow>
              <TableRow>
                <TableCell>Orders</TableCell>
                <TableCell>Selected Orders</TableCell>
                <TableCell>Items</TableCell>
                <TableCell>Selected Items</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Selection
                    primaryKey="ObjtDesc"
                    data={ordersData}
                    step={getLength(ordersData)}
                    style={style}
                    itemProps={orderListProp}
                    onClickItem={(datum) => addSelectedRows(datum, 'Orders')}
                  />
                </TableCell>
                <TableCell>
                  <Selection
                    primaryKey="ObjtDesc"
                    data={selectedOrdersList.length > 0 ? selectedOrdersList : []}
                    step={getLength(selectedOrdersList)}
                    style={style}
                    itemProps={orderRemoveListProp}
                    onClickItem={(datum) => addSelectedRows(datum, 'Orders', 'remove')}
                  />
                </TableCell>
                <TableCell>
                  <Selection
                    primaryKey="ItemDesc"
                    data={itemsData}
                    step={getLength(itemsData)}
                    style={style}
                    itemProps={itemListProp}
                    onClickItem={(datum) => addSelectedRows(datum, 'Items')}
                  />
                </TableCell>
                <TableCell>
                  <Selection
                    primaryKey="ItemDesc"
                    data={selectedItemsList.length > 0 ? selectedItemsList : []}
                    step={getLength(selectedItemsList)}
                    style={style}
                    itemProps={itemRemoveListProp}
                    onClickItem={(datum) => addSelectedRows(datum, 'Items', 'remove')}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Button label="Add Orders" onClick={() => addSelected('Orders', 'add')} />
                </TableCell>
                <TableCell>
                  <Button label="Remove Orders" onClick={() => addSelected('Orders', 'remove')} />
                </TableCell>
                <TableCell>
                  <Button label="Add Items" onClick={() => addSelected('Items', 'add')} />
                </TableCell>
                <TableCell>
                  <Button label="Remove Items" onClick={() => addSelected('Items', 'remove')} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Text>Adjust to</Text>
                  <TextInput
                    value={adjustment}
                    onChange={(event) => setAdjustment(event.target.value)}
                  />
                </TableCell>
                <TableCell>
                  <Text>Percent of</Text>
                  <Select
                    labelKey="ObjtDesc"
                    options={'adjustType' in data ? data.adjustType : []}
                    value={percentOf !== null ? percentOf.ObjtDesc : ''}
                    onChange={({ option }) => setPercentOf(option)}
                  />
                </TableCell>
                <TableCell>
                  <Text>Prefill</Text>
                  <CheckBox
                    checked={prefill}
                    onChange={(event) => setPrefill(event.target.checked)}
                  />
                </TableCell>
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell colSpan="4">
                  <Button
                    label="Perform Adjustments"
                    disabled={selectedOrdersList.length === 0 || selectedItemsList.length === 0}
                    onClick={() => save()}
                  />
                  <Button label="Cancel" onClick={() => onCancel()} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Layer>
      )}
    </>
  );
};

export default GlobalAdjustmentsModal;

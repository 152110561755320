import equal from 'fast-deep-equal/es6/react';
import { Box, List, TableCell, Text } from 'grommet';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import Modal from '@Components/modal/modal.component';
import ReportEmailDocumentModal from '@Components/reports/reports-email-documents-modal.component';
// import EmailFormModal from './email-form-modal.component';
import { download } from '@Helpers/download.helper';
import { CountLinenPageID, Labels, Links, Messages } from '@Pages/count-linen/count-linen-config';
import { ButtonMenu, DropMenu } from '@Pages/count-linen/count-linen.styles';
import {
  createCountLinenCartsAndClosetsDeliveryForOrderOnDateStart,
  getCountLinenCartsAndClosetsActiveHandheldNumbersStart,
  getCountLinenCartsAndClosetsAddItemsToDeliveryStart,
  getCountLinenCartsAndClosetsDeliveriesForGlobalAdjustOnDateStart,
  getCountLinenCartsAndClosetsDeliveryForDeliveryIDSuccess,
  getCountLinenCartsAndClosetsGetItemsNotInDeliveryStart,
  getCountLinenCartsAndClosetsOrderScheduleStart,
  getCountLinenCartsAndClosetsOrdersNotScheduledStart,
  linenRoomPickFormStart,
  pickFormStart,
  sendCountLinenCartsAndClosetsHandheldInfoStart,
  setPickFormIntervalStart,
  setPickFormTypeStart,
  setSelectedRcID,
  updateCountLinenCartsAndClosetsUpdateDeliveriesForGlobalAdjustOnDateStart,
  updateDeliveryCensusStart,
} from '@State/count-linen-carts-and-closets/count-linen-carts-and-closets.actions';
import {
  selectCountLinenCartsAndClosetsAddItemsToDeliveryReponse,
  selectCountLinenCartsAndClosetsDeliveriesForGlobalAdjustOnDate,
  selectCountLinenCartsAndClosetsDeliveryForDeliveryID,
  selectCountLinenCartsAndClosetsDeliveryForDeliveryIDPrevious,
  selectCountLinenCartsAndClosetsGetActiveHandheldNumbers,
  selectCountLinenCartsAndClosetsItemsNotInDelivery,
  selectCountLinenCartsAndClosetsOrderSchedulePrevious,
  selectCountLinenCartsAndClosetsOrdersNotScheduled,
  selectCountLinenCartsAndClosetsSendHandheldInfoResponse,
  selectCountLinenCartsAndClosetsUpdateDeliveriesForGlobalAdjustOnDateResponse,
  selectCountLinenCartsAndClosetsUpdateDeliveryCountsReponse,
  selectCountLinenLinenRoomDeliveryForCustomerOnDate,
  selectCreateCountLinenCartsAndClosetsDeliveryForOrderOnDateReponse,
  selectLinenRoomPickFormData,
  selectPickFormData,
  selectPickFormType,
  selectRcID,
  selectUpdateDeliveryCensusResponse,
} from '@State/count-linen-carts-and-closets/count-linen-carts-and-closets.selectors';
import { selectCurrentCustomer, selectCurrentUser } from '@State/user/user.selectors';
import { colors, ContentBox, ResizableColumnTable, SideHeading } from '@ThemeMain';

import AddUnScheduledModal from './add-unscheduled-modal.components';
import CountLinenTable from './count-linen-table.component';
import GlobalAdjustmentsModal from './global-adjustments-modal.component';
import HeaderInputBox from './header-input-box-component';
import SendToHandHeldModal from './send-to-handheld-modal.component';

const CountLinenResultBox = (props) => {
  const {
    pageID,
    userPrivileges,
    currentUser,
    orderScheduleData,
    currentDate,
    currentCustomer,
    orderScheduleParams,
    setOrderScheduleParams,
    activeOrder,
    setActiveOrder,
    getOrderSchedule,
    getDelivery,
    getDeliveryItemRights,
    deliveryItemData,
    deliveryForDeliveryIDPrevious,
    ordersNotScheduled,
    ordersScheduledPrevious,
    itemsNotInDelivery,
    deliveriesForGlobalAdjustmentsOnDate,
    activeHandheldNumbers,
    updateDeliveryCountsReponse,
    updateDeliveriesForGlobalAdjustmentsOnDateResponse,
    updateDeliveryCensusResponse,
    updateDeliveryCount,
    updateSharedVariables,
    addItemsToDeliveryReponse,
    createDeliveryForOrderOnDateReponse,
    getCountLinenCartsAndClosetsOrderSchedule,
    resetCountLinenCartsAndClosetsDeliveryForDeliveryID,
    getCountLinenCartsAndClosetsOrdersNotScheduled,
    getCountLinenCartsAndClosetsGetItemsNotInDelivery,
    getDeliveriesForGlobalAdjustmentsOnDate,
    getCountLinenCartsAndClosetsActiveHandheldNumbers,
    updateDeliveriesForGlobalAdjustmentsOnDate,
    sendCountLinenCartsAndClosetsHandheldInfo,
    createDeliveryForCustomerOnDateReponse,
    linenRoomPickForm,
    pickForm,
    setPickFormType,
    setPickFormInterval,
    updateDeliveryCensus,
    createDeliveryForOrderOnDate,
    addItemsToDelivery,
    laundryWeight,
    soilWeight,
    laundryCharge,
    linenRoomPickFormData,
    pickFormData,
    pickFormType,
    sendHandheldResponse,
    setChanges,
    changes,
    onInitialize,
    selectedRcID,
    setSelectedRcID,
    productsLoaded,
    setProductsLoaded,
    getGaugeInfo,
  } = props;

  const [firstLoad, setFirstLoad] = useState(true);
  const [changedKeys, setChangedKeys] = useState([]);
  const [formattedDate, setFormattedDate] = useState(moment(currentDate).format('MM/DD/YYYY'));
  const [showGlobalAdjustmentsModal, setShowGlobalAdjustmentsModal] = useState(false);
  const [showAddUnscheduledModal, setShowAddUnscheduledModal] = useState(false);
  const [showSendToHandheldModal, setShowSendToHandheldModal] = useState(false);
  const [showEmailPickFormModal, setShowEmailPickFormModal] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [AddUnscheduledData, setAddUnscheduledData] = useState([]);
  const [addUnscheduledType, setAddUnscheduledType] = useState('Orders');
  const [calculatingReqrd, setCalculatingReqrd] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const unScheduledOptions = ['Orders', 'Items'];
  const [orderTableData, setOrderTableData] = useState({
    headers: [],
    data: [...orderScheduleData],
  });

  const [productData, setProductData] = useState({
    headers: [],
    data: [],
  });
  useEffect(() => {
    if (hasChanges) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  });

  useEffect(() => {
    if (!firstLoad && changes === false && hasChanges) {
      setHasChanges(false);
    }
  }, [changes]);

  useEffect(() => {
    let data = pageID === CountLinenPageID.linenRoom ? linenRoomPickFormData : pickFormData;
    if (data.hasOwnProperty('data')) {
      data = data.data.NewDataSet.ReportTable;
      if (pickFormType === 'get') {
        download({
          name: data.ReptDesc ? data.ReptDesc : 'LinenHelperNET Delivery Pick Forms',
          type: data.ReptType,
          base64Data: data.ReptData,
        });
      } else {
        showConfirmation(linenRoomPickFormData);
      }
    }
  }, [linenRoomPickFormData, pickFormData]);

  useEffect(() => {
    if (
      deliveryItemData.hasOwnProperty('data') &&
      deliveryItemData.data.hasOwnProperty('deliveryItemTable')
    ) {
      setProductData({ ...productData, data: deliveryItemData.data.deliveryItemTable });
      if (pageID === 'linen-room') {
        updateSharedVariables({
          key: 'laundryWeight',
          value: deliveryItemData.data.deliveryTable.LdryWght,
        });
        updateSharedVariables({
          key: 'soilWeight',
          value: deliveryItemData.data.deliveryTable.RetnWght,
        });
        updateSharedVariables({
          key: 'laundryCharge',
          value: deliveryItemData.data.deliveryTable.LdryDelvChrg,
        });
      }
    } else {
      setProductData({ ...productData, data: [] });
    }
    setProductsLoaded(true);
  }, [deliveryItemData]);

  useEffect(() => {
    if (pageID === 'carts-and-closets') {
      setProductsLoaded(false);
      setOrderTableData({ ...orderTableData, data: orderScheduleData });
      getGaugeInfo({
        ...orderScheduleParams,
        activeOrderRcID: activeOrder.RcID,
        requestedDate: currentDate,
      });
    }
  }, [orderScheduleData]);

  useEffect(() => {
    if (ordersNotScheduled && ordersNotScheduled.data && ordersNotScheduled.data.length) {
      ordersNotScheduled.data.sort((a, b) => a.OrdrDesc.localeCompare(b.OrdrDesc));
    }
    setAddUnscheduledData(ordersNotScheduled);
  }, [ordersNotScheduled]);

  useEffect(() => {
    if (itemsNotInDelivery && itemsNotInDelivery.data && itemsNotInDelivery.data.length) {
      itemsNotInDelivery.data.sort((a, b) => a.ItemDesc.localeCompare(b.ItemDesc));
    }
    setAddUnscheduledData(itemsNotInDelivery);
  }, [itemsNotInDelivery]);

  useEffect(() => {
    if (updateDeliveryCountsReponse !== null) {
      if (pageID === CountLinenPageID.linenRoom) {
        getDelivery({ iDeliveryID: deliveryItemData.data.deliveryTable.RcID });
      } else {
        getCountLinenCartsAndClosetsOrderSchedule({
          requestedDate: currentDate,
          iCallerUTCOffset: 0,
          customerID: currentCustomer.RcID,
        });
      }
    }
  }, [updateDeliveryCountsReponse]);

  useEffect(() => {
    setFormattedDate(moment(currentDate).format('MM/DD/YYYY'));
  }, [currentDate]);

  useEffect(() => {
    const data =
      pageID === CountLinenPageID.cartAndClosets
        ? createDeliveryForOrderOnDateReponse
        : createDeliveryForCustomerOnDateReponse;
    if (data !== null) {
      if (pageID === CountLinenPageID.cartAndClosets) {
        getOrderSchedule(orderScheduleParams);
      }
    }
  }, [createDeliveryForOrderOnDateReponse, createDeliveryForCustomerOnDateReponse]);

  useEffect(() => {
    if (updateDeliveryCensusResponse !== null) {
      getOrderSchedule(orderScheduleParams);
    }
  }, [updateDeliveryCensusResponse]);

  useEffect(() => {
    if (addItemsToDeliveryReponse !== null) {
      getDelivery({ iDeliveryID: deliveryItemData.data.deliveryTable.RcID });
    }
  }, [addItemsToDeliveryReponse]);

  useEffect(() => {
    if (updateDeliveriesForGlobalAdjustmentsOnDateResponse !== null) {
      if (updateDeliveriesForGlobalAdjustmentsOnDateResponse.status === 'ok') {
        getCountLinenCartsAndClosetsOrderSchedule({
          requestedDate: currentDate,
          iCallerUTCOffset: 0,
          customerID: currentCustomer.RcID,
        });
      }
    }
  }, [updateDeliveriesForGlobalAdjustmentsOnDateResponse]);

  useEffect(() => {
    if (sendHandheldResponse !== null && !firstLoad) {
      setDialogMessage(sendHandheldResponse.data);
      setShowDialog(true);
    }
  }, [sendHandheldResponse]);

  const showConfirmation = (data) => {
    if (data.status === 'ok') {
      setDialogMessage('Email has been sent!');
    } else {
      setDialogMessage('Something has gone wrong.');
    }
    setShowDialog(true);
  };

  const evaluateModifiedItem = (data, type = 'columns') => {
    let tempData = [];
    if (pageID === CountLinenPageID.cartAndClosets && data.key === 'RqrdQnty' && calculatingReqrd) {
      return;
    }

    if (pageID === CountLinenPageID.cartAndClosets) {
      tempData = type === 'columns' ? orderTableData.data : productData.data;
    } else {
      tempData = productData.data;
    }

    data.value = data.value === '' ? 0 : data.value;
    updateTableData('RcID', data.id, data.key, data.value, tempData);

    if (data.key === 'CensQnty') {
      setChangedKeys([...changedKeys, `${data.id}-CensQnty`]);
    }
    if (type === 'columns') {
      setOrderTableData({ ...orderTableData, data: tempData });
    } else {
      setProductData({ ...productData, data: copyObject(tempData) });
    }
  };

  const calculateRequired = (data, type = 'columnsRight') => {
    let required = 0;
    if (
      pageID === CountLinenPageID.cartAndClosets &&
      (data.key === 'OhndQnty' || data.key === 'StckQnty')
    ) {
      setCalculatingReqrd(true);
      if (data.key === 'OhndQnty') {
        required = parseInt(data.data.StckQnty) - parseInt(data.value);
      } else if (data.key === 'StckQnty') {
        required = parseInt(data.value) - parseInt(data.data.OhndQnty);
      }

      if (required < 0) {
        required = 0;
      }

      data.key = 'RqrdQnty';
      data.value = required;
      evaluateModifiedItem(data, type);
    }
  };

  const copyObject = (data) => {
    if (data === null && data === undefined) {
      return [];
    }
    return JSON.parse(JSON.stringify(data));
  };

  const onCancelChanges = () => {
    resetData();
  };

  const resetData = () => {
    if (pageID === CountLinenPageID.cartAndClosets) {
      setOrderTableData({ ...orderTableData, data: copyObject(ordersScheduledPrevious.data) });
    } else if (deliveryForDeliveryIDPrevious.data) {
      const deliveryData = deliveryForDeliveryIDPrevious.data.deliveryTable;
      updateSharedVariables({ key: 'laundryWeight', value: deliveryData.LdryWght });
      updateSharedVariables({ key: 'soilWeight', value: deliveryData.RetnWght });
      updateSharedVariables({ key: 'laundryCharge', value: deliveryData.LdryDelvChrg });
    }

    if (deliveryForDeliveryIDPrevious.data) {
      resetCountLinenCartsAndClosetsDeliveryForDeliveryID(deliveryForDeliveryIDPrevious);
      setProductData({
        ...productData,
        data: copyObject(deliveryForDeliveryIDPrevious.data.deliveryItemTable),
      });
    }
  };

  const onSubmit = () => {
    const deliveryTableData = copyObject(deliveryItemData.data.deliveryTable);
    const orders = copyObject(orderTableData.data);
    const CensQnty = '-CensQnty';
    if (pageID === CountLinenPageID.linenRoom) {
      deliveryTableData.LdryWght = laundryWeight;
      deliveryTableData.RetnWght = soilWeight;
      deliveryTableData.LdryDelvChrg = laundryCharge;
    } else {
      let index = 0;
      const changedCensus = changedKeys.filter((elem) => {
        if (elem.includes(CensQnty)) {
          return elem;
        }
      });

      changedCensus.forEach((item) => {
        index = orders.findIndex((elem) => elem.RcID === item.replace(CensQnty, ''));
        updateDeliveryCensus({
          iDeliveryID: orders[index].DelvRcID,
          iCensusQnty: orders[index].CensQnty,
        });
      });
    }

    const formData = {
      DeliveryItemTable: deliveryForDeliveryIDPrevious.data.deliveryItemTable,
      modifiedDeliveryItemTable: productData.data,
      modifiedDeliveryTable: deliveryTableData,
    };

    setProductsLoaded(false);
    updateDeliveryCount({ formData });
    setChangedKeys([]);
  };

  const evaluateOptionPermission = (options) => {
    const filtered = [];
    options.map((option) => {
      switch (option) {
        case 'Orders':
          if (userPrivileges.canViewCountLinenAddNewOrders) {
            filtered.push(option);
          }
          break;
        case 'Items':
          if (userPrivileges.canViewCountLinenAddNewItems) {
            filtered.push(option);
          }
          break;
      }
    });
    return filtered;
  };

  const updateTableData = (primaryKey, primaryKeyValue, prop, value, data) => {
    const index = data.findIndex((item) => item[primaryKey] === primaryKeyValue);
    value = parseInt(value, 10);

    if (index > -1) {
      data[index][prop] = Number.isNaN(value) ? value : value.toString();
    }
  };

  const getDeliveryByID = (data) => {
    let deliveryTableData = deliveryItemData.data;
    deliveryTableData =
      typeof deliveryTableData === 'undefined'
        ? []
        : JSON.parse(JSON.stringify(deliveryItemData.data.deliveryTable));

    if (pageID === CountLinenPageID.linenRoom) {
      deliveryTableData.LdryWght = laundryWeight;
      deliveryTableData.RetnWght = soilWeight;
      deliveryTableData.LdryDelvChrg = laundryCharge;
    }

    setProductsLoaded(false);
    if (pageID === CountLinenPageID.cartAndClosets) {
      setSelectedRcID(data.RcID);

      if (parseInt(data.DelvRcID, 10) === 0) {
        createDeliveryForOrderOnDate({
          ...orderScheduleParams,
          iOrderID: parseInt(data.RcID, 10),
        });
      } else {
        getDelivery({ iDeliveryID: data.DelvRcID });
      }
      getDeliveryItemRights({ iOrderRecordID: data.RcID });
    }
  };

  const evaluateAutoStock = () => {
    const data = productData.data ? [...productData.data] : [];
    data.forEach((item) => {
      item.LdryQnty = item.RqrdQnty;
    });

    setProductData({ ...productData, data });
  };

  const setRequiredValue = (type) => {
    const deliveryItemTableData = productData.data;
    deliveryItemTableData.forEach((item) => {
      if (type === 'orders') {
        item.RqrdQnty = item.RetnQnty;
      } else {
        const par = parseInt(item.StckQnty);
        const onHand = parseInt(item.OhndQnty);
        item.RqrdQnty = par > onHand ? par - onHand : 0;
      }
    });

    setProductData({ ...productData, data: copyObject(deliveryItemTableData) });
  };

  const evaluatePickForm = (how, data = '') => {
    let params = {};
    setPickFormType(how);

    if (pageID === CountLinenPageID.linenRoom) {
      params = {
        iDeliveryID: deliveryItemData.data.deliveryTable.RcID,
        recipient: data,
      };
      linenRoomPickForm(params);
    } else {
      params = {
        selectedDate: moment(currentDate).format('YYYY-MM-DDT12:00:00'),
        iCallerUTCOffset: 0,
        RequestedIDsInfo: orderScheduleData,
        accessibleOrders:
          typeof ordersNotScheduled.data !== 'undefined' && ordersNotScheduled.data.length
            ? [...orderScheduleData, ...ordersNotScheduled.data]
            : null,
        customerID: currentCustomer.RcID,
        isAdmin: `${Number(currentUser.rules.isAdminUser)}`,
      };

      setPickFormInterval(data.toLowerCase());
      pickForm(params);
    }
  };

  const emailTransactions = (data) => {
    evaluatePickForm('email', data.formEmail);
  };

  const onInputHeaderChange = (key, value) => {
    updateSharedVariables({ key, value });
  };

  const toggleModal = (data) => {
    if (typeof data !== 'undefined') {
      switch (data.id) {
        case 'AddUnscheduled':
          if (!showAddUnscheduledModal && data.data === 'Orders') {
            getCountLinenCartsAndClosetsOrdersNotScheduled({
              requestedDate: currentDate,
              iCallerUTCOffset: 0,
              customerID: currentCustomer.RcID,
            });
          } else if (!showAddUnscheduledModal && data.data === 'Items') {
            getCountLinenCartsAndClosetsGetItemsNotInDelivery({
              iDeliveryID: deliveryItemData.data.deliveryTable.RcID,
            });
          }
          setAddUnscheduledType(data.data);
          setShowAddUnscheduledModal(!showAddUnscheduledModal);
          break;
        case 'GlobalAdjustments':
          if (!showGlobalAdjustmentsModal) {
            getDeliveriesForGlobalAdjustmentsOnDate(orderScheduleParams);
          }
          setShowGlobalAdjustmentsModal(!showGlobalAdjustmentsModal);
          break;
        case 'SendToHandHeld':
          if (!showSendToHandheldModal) {
            getCountLinenCartsAndClosetsActiveHandheldNumbers();
          }
          setShowSendToHandheldModal(!showSendToHandheldModal);
          break;
        default:
          break;
      }
    } else {
      setShowEmailPickFormModal(!showEmailPickFormModal);
    }
  };

  let addUnScheduledEvent = () => {};
  if (addUnscheduledType === 'Orders') {
    addUnScheduledEvent = (data) => createDeliveryForOrderOnDate(data);
  } else if (addUnscheduledType === 'Items') {
    addUnScheduledEvent = (data) => addItemsToDelivery(data);
  }

  const evaluateData = (data, type) => {
    if (
      type === 'deliveryitem' &&
      data.hasOwnProperty('data') &&
      data.data.hasOwnProperty('deliveryItemTable')
    ) {
      return data.data.deliveryItemTable;
    }
    if (data && type === 'order' && data.hasOwnProperty('data')) {
      return data.data;
    }

    return [];
  };

  const updateGauges = (id) => {
    getGaugeInfo({
      ...orderScheduleParams,
      activeOrderRcID: id,
      requestedDate: currentDate,
    });
  };

  const hasDataChanges = () => {
    let result = false;

    if (!productsLoaded) {
      return false;
    }

    if (pageID === CountLinenPageID.cartAndClosets) {
      result =
        !equal(productData.data, evaluateData(deliveryForDeliveryIDPrevious, 'deliveryitem')) ||
        !equal(orderTableData.data, evaluateData(ordersScheduledPrevious, 'order'));
    } else {
      result =
        (deliveryItemData.data &&
          deliveryItemData.data.hasOwnProperty('deliveryTable') &&
          (deliveryItemData.data.deliveryTable.LdryWght !== laundryWeight ||
            deliveryItemData.data.deliveryTable.RetnWght !== soilWeight ||
            deliveryItemData.data.deliveryTable.LdryDelvChrg !== laundryCharge)) ||
        !equal(productData.data, evaluateData(deliveryForDeliveryIDPrevious, 'deliveryitem'));
    }

    if (firstLoad) {
      setFirstLoad(false);
    }

    if (changes !== result) {
      setChanges(result);
    }

    if (hasChanges !== result) {
      setHasChanges(result);
    }

    return result;
  };

  const controlsHeaderElements = [];
  if (
    (pageID === CountLinenPageID.cartAndClosets && userPrivileges.canModifyCountLinenOrders) ||
    (pageID === CountLinenPageID.linenRoom && userPrivileges.canModifyCountLinenLinenRoom)
  ) {
    controlsHeaderElements.push(
      <ButtonMenu
        key="saveChanges"
        type="button"
        label="Save Changes"
        disabled={!hasDataChanges()}
        onClick={() => onSubmit()}
      />,
    );
    controlsHeaderElements.push(
      <ButtonMenu
        key="cancel"
        type="button"
        label="Cancel"
        disabled={!hasDataChanges()}
        onClick={() => onCancelChanges()}
      />,
    );
  }

  if (pageID === 'carts-and-closets') {
    controlsHeaderElements.push(
      <DropMenu
        label="View Pick Forms"
        alignSelf="center"
        margin={{ vertical: 'small' }}
        dropContent={
          <List
            data={['Daily', 'Weekly']}
            onClickItem={(event) => evaluatePickForm('get', event.item)}
          />
        }
        dropProps={{ align: { top: 'bottom' } }}
      />,
    );
    controlsHeaderElements.push(
      <ButtonMenu
        type="button"
        label="Send to Handheld"
        onClick={() => toggleModal({ id: 'SendToHandHeld' })}
      />,
    );
    controlsHeaderElements.push(
      <DropMenu
        label="Add Unscheduled"
        alignSelf="center"
        margin={{ vertical: 'small' }}
        dropContent={
          <List
            data={evaluateOptionPermission(unScheduledOptions)}
            onClickItem={(event) => toggleModal({ id: 'AddUnscheduled', data: event.item })}
          />
        }
        dropProps={{ align: { top: 'bottom' } }}
      />,
    );

    if (userPrivileges.canViewCountLinenAutoStock) {
      controlsHeaderElements.push(
        <ButtonMenu
          key="evaluateAutoStock"
          type="button"
          label="Auto Stock"
          onClick={() => evaluateAutoStock()}
        />,
      );
    }

    if (userPrivileges.canViewCountLinenGlobalAdjustments) {
      controlsHeaderElements.push(
        <ButtonMenu
          key="globalAdjustments"
          type="button"
          label="Global Adjustments"
          onClick={() => toggleModal({ id: 'GlobalAdjustments' })}
        />,
      );
    }
  } else {
    controlsHeaderElements.push(
      <ButtonMenu
        key="viewPick"
        type="button"
        label="View Pick Form"
        onClick={() => evaluatePickForm('get')}
      />,
    );
    controlsHeaderElements.push(
      <ButtonMenu
        key="emailPick"
        type="button"
        label="Email Pick Form"
        onClick={() => toggleModal()}
      />,
    );
  }

  controlsHeaderElements.push(
    <ButtonMenu
      key="help"
      type="button"
      label="Help"
      onClick={() => {
        window.open(Links[pageID].help);
      }}
    />,
  );

  return (
    <>
      <Prompt when={hasChanges} message={Messages.unSaveChanges} />
      <ContentBox direction="column" gap="small" background={colors.innerBg}>
        <SideHeading level="3">{Labels[pageID].pageTitle}</SideHeading>
        <Box direction="row" gap="small" pad="small" align="center">
          {controlsHeaderElements}
        </Box>
        {pageID === CountLinenPageID.linenRoom && (
          <HeaderInputBox
            laundryWeight={laundryWeight}
            soilWeight={soilWeight}
            laundryCharge={laundryCharge}
            setLaundryWeight={(data) => onInputHeaderChange('laundryWeight', data)}
            setSoilWeight={(data) => onInputHeaderChange('soilWeight', data)}
            setLaundryCharge={(data) => onInputHeaderChange('laundryCharge', data)}
          />
        )}
        {pageID === CountLinenPageID.cartAndClosets ? (
          <ResizableColumnTable>
            <TableCell>
              <CountLinenTable
                style={{ marginTop: '0px' }}
                pageID={pageID}
                className={`left-table cl-${pageID}`}
                type="columns"
                table="order"
                data={orderTableData}
                userPrivileges={userPrivileges}
                currentDate={formattedDate}
                changes={changes}
                setChanges={(data) => setChanges(data)}
                evaluateModifiedItem={(data) => evaluateModifiedItem(data)}
                calculateRequired={(data) => calculateRequired(data)}
                setCalculatingReqrd={(data) => setCalculatingReqrd(data)}
                getDeliveryByID={(data) => getDeliveryByID(data)}
                updateGauges={(data) => updateGauges(data)}
                setActiveOrder={(data) => setActiveOrder(data)}
              />
            </TableCell>
            <TableCell>
              <CountLinenTable
                style={{ marginTop: '0px', height: '600px', overflowY: 'scroll' }}
                pageID={pageID}
                className="right-table"
                type="columnsRight"
                table="deliveryitem"
                data={productData}
                userPrivileges={userPrivileges}
                currentDate={formattedDate}
                changes={changes}
                setChanges={(data) => setChanges(data)}
                evaluateModifiedItem={(data, tableType) => evaluateModifiedItem(data, tableType)}
                calculateRequired={(data) => calculateRequired(data)}
                setCalculatingReqrd={(data) => setCalculatingReqrd(data)}
                updateGauges={(data) => updateGauges(data)}
                setActiveOrder={(data) => setActiveOrder(data)}
              />
            </TableCell>
          </ResizableColumnTable>
        ) : (
          <ResizableColumnTable>
            <TableCell>
              <CountLinenTable
                pageID={pageID}
                className="left-table"
                type="columns"
                table="linenroom"
                currentDate={formattedDate}
                data={productData}
                userPrivileges={userPrivileges}
                changes={changes}
                setChanges={(data) => setChanges(data)}
                evaluateModifiedItem={(data) => evaluateModifiedItem(data)}
                calculateRequired={(data) => calculateRequired(data)}
                getDeliveryByID={(data) => getDeliveryByID(data)}
                setRequiredValue={(data) => setRequiredValue(data)}
                setCalculatingReqrd={(data) => setCalculatingReqrd(data)}
                setActiveOrder={(data) => setActiveOrder(data)}
              />
            </TableCell>
          </ResizableColumnTable>
        )}
      </ContentBox>
      {/* Modal components needed in this module */}
      <div style={{ position: 'absolute' } /* so that they don't introduce gaps, spacing */}>
        <AddUnScheduledModal
          showModal={showAddUnscheduledModal}
          type={addUnscheduledType}
          toggleModal={toggleModal}
          data={AddUnscheduledData}
          addUnScheduledEvent={addUnScheduledEvent}
          currentDate={currentDate}
        />
        <SendToHandHeldModal
          showModal={showSendToHandheldModal}
          currentDate={currentDate}
          toggleModal={toggleModal}
          data={typeof activeHandheldNumbers.data !== 'undefined' ? activeHandheldNumbers.data : []}
          sendCountLinenCartsAndClosetsHandheldInfo={(data) =>
            sendCountLinenCartsAndClosetsHandheldInfo(data)
          }
        />
        <GlobalAdjustmentsModal
          showModal={showGlobalAdjustmentsModal}
          currentDate={currentDate}
          toggleModal={toggleModal}
          data={
            typeof deliveriesForGlobalAdjustmentsOnDate.data !== 'undefined'
              ? deliveriesForGlobalAdjustmentsOnDate.data
              : []
          }
          updateDeliveriesForGlobalAdjustmentsOnDate={(data) =>
            updateDeliveriesForGlobalAdjustmentsOnDate(data)
          }
        />
        <ReportEmailDocumentModal
          emailTransactions={emailTransactions}
          toggleModal={toggleModal}
          showReportEmailDocumentModal={showEmailPickFormModal}
          emailType="Pick Form"
        />
        <Modal
          show={showDialog}
          setShow={setShowDialog}
          title="Email Confirmation"
          confirmLabel="Ok"
          cancelLabel=""
          confirmAction={() => setShowDialog(false)}
        >
          <Text>{dialogMessage}</Text>
        </Modal>
      </div>
      {/* <EmailFormModal/> */}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  currentCustomer: selectCurrentCustomer,
  ordersNotScheduled: selectCountLinenCartsAndClosetsOrdersNotScheduled,
  ordersScheduledPrevious: selectCountLinenCartsAndClosetsOrderSchedulePrevious,
  itemsNotInDelivery: selectCountLinenCartsAndClosetsItemsNotInDelivery,
  deliveryForDeliveryIDPrevious: selectCountLinenCartsAndClosetsDeliveryForDeliveryIDPrevious,
  deliveryItemData: selectCountLinenCartsAndClosetsDeliveryForDeliveryID,
  updateDeliveryCountsReponse: selectCountLinenCartsAndClosetsUpdateDeliveryCountsReponse,
  createDeliveryForOrderOnDateReponse:
    selectCreateCountLinenCartsAndClosetsDeliveryForOrderOnDateReponse,
  addItemsToDeliveryReponse: selectCountLinenCartsAndClosetsAddItemsToDeliveryReponse,
  deliveriesForGlobalAdjustmentsOnDate:
    selectCountLinenCartsAndClosetsDeliveriesForGlobalAdjustOnDate,
  updateDeliveriesForGlobalAdjustmentsOnDateResponse:
    selectCountLinenCartsAndClosetsUpdateDeliveriesForGlobalAdjustOnDateResponse,
  updateDeliveryCensusResponse: selectUpdateDeliveryCensusResponse,
  activeHandheldNumbers: selectCountLinenCartsAndClosetsGetActiveHandheldNumbers,
  createDeliveryForCustomerOnDateReponse: selectCountLinenLinenRoomDeliveryForCustomerOnDate,
  linenRoomPickFormData: selectLinenRoomPickFormData,
  pickFormData: selectPickFormData,
  pickFormType: selectPickFormType,
  selectedRcID: selectRcID,
  sendHandheldResponse: selectCountLinenCartsAndClosetsSendHandheldInfoResponse,
  currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  getCountLinenCartsAndClosetsOrderSchedule: (data) =>
    dispatch(getCountLinenCartsAndClosetsOrderScheduleStart(data)),
  resetCountLinenCartsAndClosetsDeliveryForDeliveryID: (data) =>
    dispatch(getCountLinenCartsAndClosetsDeliveryForDeliveryIDSuccess(data)),
  getCountLinenCartsAndClosetsOrdersNotScheduled: (data) =>
    dispatch(getCountLinenCartsAndClosetsOrdersNotScheduledStart(data)),
  getCountLinenCartsAndClosetsGetItemsNotInDelivery: (data) =>
    dispatch(getCountLinenCartsAndClosetsGetItemsNotInDeliveryStart(data)),
  getDeliveriesForGlobalAdjustmentsOnDate: (data) =>
    dispatch(getCountLinenCartsAndClosetsDeliveriesForGlobalAdjustOnDateStart(data)),
  getCountLinenCartsAndClosetsActiveHandheldNumbers: () =>
    dispatch(getCountLinenCartsAndClosetsActiveHandheldNumbersStart()),
  createDeliveryForOrderOnDate: (data) =>
    dispatch(createCountLinenCartsAndClosetsDeliveryForOrderOnDateStart(data)),
  addItemsToDelivery: (data) => dispatch(getCountLinenCartsAndClosetsAddItemsToDeliveryStart(data)),
  updateDeliveriesForGlobalAdjustmentsOnDate: (data) =>
    dispatch(updateCountLinenCartsAndClosetsUpdateDeliveriesForGlobalAdjustOnDateStart(data)),
  sendCountLinenCartsAndClosetsHandheldInfo: (data) =>
    dispatch(sendCountLinenCartsAndClosetsHandheldInfoStart(data)),
  updateDeliveryCensus: (data) => dispatch(updateDeliveryCensusStart(data)),
  linenRoomPickForm: (data) => dispatch(linenRoomPickFormStart(data)),
  pickForm: (data) => dispatch(pickFormStart(data)),
  setPickFormType: (data) => dispatch(setPickFormTypeStart(data)),
  setPickFormInterval: (data) => dispatch(setPickFormIntervalStart(data)),
  setSelectedRcID: (data) => dispatch(setSelectedRcID(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CountLinenResultBox);

import { Box } from 'grommet';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import NavBox from '@Components/nav-box/nav-box.component';
// COUNT-LINEN-CARTS-AND-CLOSETS
import {
  createCountLinenCartsAndClosetsDeliveryForOrderOnDateStart,
  createCountLinenLinenRoomDeliveryForCustomerOnDateStart,
  getCountLinenCartsAndClosetsDashboardGaugeInfoStart,
  getCountLinenCartsAndClosetsDeliveriesForGlobalAdjustOnDateStart,
  getCountLinenCartsAndClosetsDeliveryForDeliveryIDStart,
  getCountLinenCartsAndClosetsDeliveryForDeliveryIDSuccess,
  getCountLinenCartsAndClosetsDeliveryItemRightsStart,
  getCountLinenCartsAndClosetsOrderScheduleStart,
  getCountLinenCartsAndClosetsOrderScheduleSuccess,
  getCountLinenCartsAndClosetsOrdersNotScheduledStart,
  getCountLinenCartsAndClosetsUpdateDeliveryCountsStart,
  setSelectedRcID,
} from '@State/count-linen-carts-and-closets/count-linen-carts-and-closets.actions';
import {
  selectCountLinenCartsAndClosetsDashboardGaugeInfo,
  selectCountLinenCartsAndClosetsDeliveryItemRights,
  selectCountLinenCartsAndClosetsOrderSchedule,
  selectCountLinenCartsAndClosetsUpdateDeliveryCountsReponse,
  selectCountLinenLinenRoomDeliveryForCustomerOnDate,
  selectRcID,
} from '@State/count-linen-carts-and-closets/count-linen-carts-and-closets.selectors';
import { selectCurrentCustomer, selectPrivileges } from '@State/user/user.selectors';
import { Page } from '@ThemeMain';

import { CountLinenNav, CountLinenPageID } from './count-linen-config';
import CountLinenPanel from './count-linen-panel.component';

const CountLinenPage = (props) => {
  const {
    currentCustomer,
    userPrivileges,
    activeNav,
    getCountLinenCartsAndClosetsOrdersNotScheduled,
    getCountLinenCartsAndClosetsOrderSchedule,
    getCountLinenCartsAndClosetsOrderScheduleReset,
    getCountLinenCartsAndClosetsDeliveryItemRights,
    getCountLinenCartsAndClosetsDeliveryForDeliveryID,
    getCountLinenCartsAndClosetsDeliveryForDeliveryIDReset,
    getCountLinenCartsAndClosetsDashboardGaugeInfo,
    getCountLinenCartsAndClosetsUpdateDeliveryCounts,
    getDeliveriesForGlobalAdjustmentsOnDate,
    createCountLinenLinenRoomDeliveryForCustomerOnDate,
    createCountLinenCartsAndClosetsDeliveryForOrderOnDate,
    orderSchedule,
    dashboardGaugeInfo,
    linenRoomDeliveryForCustomerOnDate,
    updateDeliveryCountResponse,
    setSelectedRcID,
    rcID,
  } = props;
  const dateToday = moment(new Date()).format('YYYY-MM-DDT12:00:00');
  const [orderScheduleParams, setOrderScheduleParams] = useState({
    requestedDate: dateToday,
    requestedDay: dateToday,
    RequestedDay: dateToday,
    iCallerUTCOffset: 0,
    customerID: currentCustomer.RcID,
    custID: currentCustomer.RcID,
    iOrderID: 0,
  });
  const [firstLoad, setFirstLoad] = useState(true);
  const [activeOrder, setActiveOrder] = useState({});
  const [orderScheduleData, setOrderScheduleData] = useState([]);
  const [deliveryData, setDeliveryData] = useState([]);
  const [gaugeInfo, setGaugeInfo] = useState([]);

  useEffect(() => {
    const formData = { ...orderScheduleParams };
    formData.customerID = currentCustomer.RcID;
    formData.custID = currentCustomer.RcID;
    setSelectedRcID(null);

    // Reset table data
    getCountLinenCartsAndClosetsDeliveryForDeliveryIDReset({});
    switch (activeNav) {
      case CountLinenPageID.cartAndClosets:
        getCountLinenCartsAndClosetsOrderScheduleReset({});
        getCountLinenCartsAndClosetsOrderSchedule(formData);
        getCountLinenCartsAndClosetsOrdersNotScheduled(formData);
        break;
      case CountLinenPageID.linenRoom:
        createCountLinenLinenRoomDeliveryForCustomerOnDate(formData);
        break;
      default:
        break;
    }
    setOrderScheduleParams(formData);
  }, [activeNav, currentCustomer]);

  // Listen on changing state values
  useEffect(() => {
    if ('data' in orderSchedule) {
      const { data } = orderSchedule;
      if (data.length > 0) {
        const orderID = rcID || data[0].RcID;
        let delvID = null;

        setSelectedRcID(orderID);
        setOrderScheduleData(data);

        const index = data.findIndex((item) => item.RcID === orderID);
        if (index > -1) {
          delvID = data[index].DelvRcID;
        } else {
          delvID = data[0].DelvRcID;
        }

        if (parseInt(delvID, 10) !== 0) {
          getCountLinenCartsAndClosetsDeliveryForDeliveryID({ iDeliveryID: delvID });
        } else {
          createCountLinenCartsAndClosetsDeliveryForOrderOnDate({
            ...orderScheduleParams,
            iOrderID: orderID,
          });
        }
        if (firstLoad && activeNav === 'carts-and-closets') {
          setActiveOrder(data[0]);
          setFirstLoad(false);
        }
      }
    } else {
      setOrderScheduleData([]);
    }
  }, [orderSchedule]);

  useEffect(() => {
    const lrData = linenRoomDeliveryForCustomerOnDate.data;
    if (lrData !== '' && lrData !== null) {
      getCountLinenCartsAndClosetsDeliveryForDeliveryID({ iDeliveryID: lrData });
    }
  }, [linenRoomDeliveryForCustomerOnDate]);

  useEffect(() => {
    if ('data' in dashboardGaugeInfo) {
      setGaugeInfo(dashboardGaugeInfo.data);
    } else {
      setGaugeInfo([]);
    }
  }, [dashboardGaugeInfo]);

  if (userPrivileges && userPrivileges.canViewCountLinen) {
    const updateSharedVariables = (data) => {
      switch (data.key) {
        case 'activeOrder':
          setActiveOrder(data.value);
          break;
        default:
          break;
      }
    };

    let currentNav = null;
    switch (activeNav) {
      case CountLinenPageID.main:
        currentNav = CountLinenNav.mainNav;
        break;
      case CountLinenPageID.cartAndClosets:
      case CountLinenPageID.linenRoom:
        currentNav = null;
        break;
      default:
        currentNav = null;
        break;
    }

    return currentNav !== null ? (
      <Page>
        <NavBox
          currentCustomer={currentCustomer}
          fill={false}
          navMap={currentNav}
          userPrivileges={userPrivileges}
        />
      </Page>
    ) : (
      <Page>
        <Box>
          <CountLinenPanel
            currentCustomer={currentCustomer}
            userPrivileges={userPrivileges}
            pageID={activeNav}
            orderScheduleParams={orderScheduleParams}
            setOrderScheduleParams={setOrderScheduleParams}
            activeOrder={activeOrder}
            orderScheduleData={orderScheduleData}
            gaugeInfo={gaugeInfo}
            setActiveOrder={(data) => setActiveOrder(data)}
            updateSharedVariables={(data) => updateSharedVariables(data)}
            getOrderSchedule={(data) => getCountLinenCartsAndClosetsOrderSchedule(data)}
            getOrdersNotScheduled={(data) => getCountLinenCartsAndClosetsOrdersNotScheduled(data)}
            getDeliveryItemRights={(data) => getCountLinenCartsAndClosetsDeliveryItemRights(data)}
            getDelivery={(data) => getCountLinenCartsAndClosetsDeliveryForDeliveryID(data)}
            getGaugeInfo={(data) => getCountLinenCartsAndClosetsDashboardGaugeInfo(data)}
            updateDeliveryCount={(data) => getCountLinenCartsAndClosetsUpdateDeliveryCounts(data)}
            createLinenRoomDeliveryForCustomerOnDate={(data) =>
              createCountLinenLinenRoomDeliveryForCustomerOnDate(data)
            }
          />
        </Box>
      </Page>
    );
  }

  return null;
};

const mapStateToProps = createStructuredSelector({
  currentCustomer: selectCurrentCustomer,
  userPrivileges: selectPrivileges,
  orderSchedule: selectCountLinenCartsAndClosetsOrderSchedule,
  deliveryItemRights: selectCountLinenCartsAndClosetsDeliveryItemRights,
  dashboardGaugeInfo: selectCountLinenCartsAndClosetsDashboardGaugeInfo,
  updateDeliveryCountResponse: selectCountLinenCartsAndClosetsUpdateDeliveryCountsReponse,
  linenRoomDeliveryForCustomerOnDate: selectCountLinenLinenRoomDeliveryForCustomerOnDate,
  rcID: selectRcID,
});

const mapDispatchToProps = (dispatch) => ({
  getCountLinenCartsAndClosetsOrderSchedule: (data) =>
    dispatch(getCountLinenCartsAndClosetsOrderScheduleStart(data)),
  getCountLinenCartsAndClosetsOrderScheduleReset: (data) =>
    dispatch(getCountLinenCartsAndClosetsOrderScheduleSuccess(data)),
  getCountLinenCartsAndClosetsOrdersNotScheduled: (data) =>
    dispatch(getCountLinenCartsAndClosetsOrdersNotScheduledStart(data)),
  getCountLinenCartsAndClosetsDeliveryItemRights: (data) =>
    dispatch(getCountLinenCartsAndClosetsDeliveryItemRightsStart(data)),
  getCountLinenCartsAndClosetsDeliveryForDeliveryID: (data) =>
    dispatch(getCountLinenCartsAndClosetsDeliveryForDeliveryIDStart(data)),
  getCountLinenCartsAndClosetsDeliveryForDeliveryIDReset: (data) =>
    dispatch(getCountLinenCartsAndClosetsDeliveryForDeliveryIDSuccess(data)),
  getCountLinenCartsAndClosetsDashboardGaugeInfo: (data) =>
    dispatch(getCountLinenCartsAndClosetsDashboardGaugeInfoStart(data)),
  getCountLinenCartsAndClosetsUpdateDeliveryCounts: (data) =>
    dispatch(getCountLinenCartsAndClosetsUpdateDeliveryCountsStart(data)),
  getDeliveriesForGlobalAdjustmentsOnDate: (data) =>
    dispatch(getCountLinenCartsAndClosetsDeliveriesForGlobalAdjustOnDateStart(data)),
  createCountLinenLinenRoomDeliveryForCustomerOnDate: (data) =>
    dispatch(createCountLinenLinenRoomDeliveryForCustomerOnDateStart(data)),
  createCountLinenCartsAndClosetsDeliveryForOrderOnDate: (data) =>
    dispatch(createCountLinenCartsAndClosetsDeliveryForOrderOnDateStart(data)),
  setSelectedRcID: (data) => dispatch(setSelectedRcID(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CountLinenPage);

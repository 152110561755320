import { Box, Button, Layer, Stack } from 'grommet';
import { Close } from 'grommet-icons';
import styled from 'styled-components';

import { BoxHeading, colors, measures } from '@ThemeMain';

const ModalStyledLayer = styled(Layer)`
  background-color: transparent;
  box-shadow: ${measures.dropShadow} ${colors.dropShadow};
`;

const bodyBottomStyles = {
  withTitle: {
    borderRadius: `0 0 ${measures.borderRadius} ${measures.borderRadius}`,
  },
  withoutTitle: {
    borderRadius: measures.borderRadius,
  },
};

// separated for the sake of showing withtout the layer, in Style Guide
export const ModalInnerBody = (props) => {
  const { onCancel, title, showButtons } = props;

  const pad = props.pad || 'medium';

  return (
    <Stack anchor="top-right" {...props}>
      <Box>
        {title ? <BoxHeading pad={pad}>{title}</BoxHeading> : null}
        <Box
          pad={pad}
          background="white"
          style={bodyBottomStyles[title ? 'withTitle' : 'withoutTitle']}
        >
          {props.children}
        </Box>
      </Box>
      {showButtons === true ? (
        <Button
          id="exitButton"
          onClick={onCancel}
          icon={<Close color="white" />}
          style={{ display: 'none' }}
        ></Button>
      ) : (
        <Button
          id="exitButton"
          onClick={onCancel}
          icon={<Close color="white" />}
          style={{ display: 'block' }}
        ></Button>
      )}
    </Stack>
  );
};

export const ModalBody = (props) => {
  const { onCancel, title, showButtons } = props;

  return (
    <ModalStyledLayer {...props}>
      <ModalInnerBody onCancel={onCancel} title={title} showButtons={showButtons}>
        {props.children}
      </ModalInnerBody>
    </ModalStyledLayer>
  );
};

import AdministrationTypes from './administration.types';

/* #region Log Transactions */
export const getLogTransactionsSelectionTreeStart = (data) => ({
  type: AdministrationTypes.GET_LOG_TRANSACTIONS_SELECTION_TREE_START,
  payload: data,
});

export const getLogTransactionsSelectionTreeSuccess = (data) => ({
  type: AdministrationTypes.GET_LOG_TRANSACTIONS_SELECTION_TREE_SUCCESS,
  payload: data,
});

export const getLogTransactionsSelectionTreeFailure = (error) => ({
  type: AdministrationTypes.GET_LOG_TRANSACTIONS_SELECTION_TREE_FAILURE,
  payload: error,
});

export const getSystemConfigurationSelectionTreeStart = (data) => ({
  type: AdministrationTypes.GET_SYSTEM_CONFIGURATION_SELECTION_TREE_START,
  payload: data,
});

export const getSystemConfigurationSelectionTreeSuccess = (data) => ({
  type: AdministrationTypes.GET_SYSTEM_CONFIGURATION_SELECTION_TREE_SUCCESS,
  payload: data,
});

export const getSystemConfigurationSelectionTreeFailure = (error) => ({
  type: AdministrationTypes.GET_SYSTEM_CONFIGURATION_SELECTION_TREE_FAILURE,
  payload: error,
});

export const getSelectedLogsStart = (data) => ({
  type: AdministrationTypes.GET_SELECTED_LOGS_START,
  payload: data,
});

export const getSelectedLogsSuccess = (data) => ({
  type: AdministrationTypes.GET_SELECTED_LOGS_SUCCESS,
  payload: data,
});

export const getSelectedLogsFailure = (error) => ({
  type: AdministrationTypes.GET_SELECTED_LOGS_FAILURE,
  payload: error,
});

export const getItemsInLogStart = (data) => ({
  type: AdministrationTypes.GET_ITEMS_IN_LOG_START,
  payload: data,
});

export const getItemsInLogSuccess = (data) => ({
  type: AdministrationTypes.GET_ITEMS_IN_LOG_SUCCESS,
  payload: data,
});

export const getItemsInLogFailure = (error) => ({
  type: AdministrationTypes.GET_ITEMS_IN_LOG_FAILURE,
  payload: error,
});
/* #endregion */

/* #region System Configuration */
// General Tab
export const getGeneralTabOptionsStart = () => ({
  type: AdministrationTypes.GET_GENERAL_TAB_OPTIONS_START,
  payload: null,
});

export const getGeneralTabOptionsSuccess = (data) => ({
  type: AdministrationTypes.GET_GENERAL_TAB_OPTIONS_SUCCESS,
  payload: data,
});

export const getGeneralTabOptionsFailure = (error) => ({
  type: AdministrationTypes.GET_GENERAL_TAB_OPTIONS_FAILURE,
  payload: error,
});

export const setGeneralTabOptionsStart = (data) => ({
  type: AdministrationTypes.SET_GENERAL_TAB_OPTIONS_START,
  payload: data,
});

export const setGeneralTabOptionsSuccess = (data) => ({
  type: AdministrationTypes.SET_GENERAL_TAB_OPTIONS_SUCCESS,
  payload: data,
});

export const setGeneralTabOptionsFailure = (error) => ({
  type: AdministrationTypes.SET_GENERAL_TAB_OPTIONS_FAILURE,
  payload: error,
});

export const updateGeneralOptionsStart = (data) => ({
  type: AdministrationTypes.UPDATE_GENERAL_OPTIONS_START,
  payload: data,
});

export const updateGeneralOptionsSuccess = (data) => ({
  type: AdministrationTypes.UPDATE_GENERAL_OPTIONS_SUCCESS,
  payload: data,
});

export const updateGeneralOptionsFailure = (error) => ({
  type: AdministrationTypes.UPDATE_GENERAL_OPTIONS_FAILURE,
  payload: error,
});

// Mobile Devices
export const getMobileDeviceTabsStart = () => ({
  type: AdministrationTypes.GET_MOBILE_DEVICE_TABS_START,
  payload: null,
});

export const getMobileDeviceTabsSuccess = (data) => ({
  type: AdministrationTypes.GET_MOBILE_DEVICE_TABS_SUCCESS,
  payload: data,
});

export const getMobileDeviceTabsFailure = (error) => ({
  type: AdministrationTypes.GET_MOBILE_DEVICE_TABS_FAILURE,
  payload: error,
});

export const getMobileDeviceTabOptionsStart = (data) => ({
  type: AdministrationTypes.GET_MOBILE_DEVICE_TAB_OPTIONS_START,
  payload: data,
});

export const getMobileDeviceTabOptionsSuccess = (data) => ({
  type: AdministrationTypes.GET_MOBILE_DEVICE_TAB_OPTIONS_SUCCESS,
  payload: data,
});

export const getMobileDeviceTabOptionsFailure = (error) => ({
  type: AdministrationTypes.GET_MOBILE_DEVICE_TAB_OPTIONS_FAILURE,
  payload: error,
});

export const setMobileDeviceTabOptionsStart = (data) => ({
  type: AdministrationTypes.SET_MOBILE_DEVICE_TAB_OPTIONS_START,
  payload: data,
});

export const setMobileDeviceTabOptionsSuccess = (data) => ({
  type: AdministrationTypes.SET_MOBILE_DEVICE_TAB_OPTIONS_SUCCESS,
  payload: data,
});

export const setMobileDeviceTabOptionsFailure = (error) => ({
  type: AdministrationTypes.SET_MOBILE_DEVICE_TAB_OPTIONS_FAILURE,
  payload: error,
});

export const updateMobileDeviceTabOptionsStart = (data) => ({
  type: AdministrationTypes.UPDATE_MOBILE_DEVICE_TAB_OPTIONS_START,
  payload: data,
});

export const updateMobileDeviceTabOptionsSuccess = (data) => ({
  type: AdministrationTypes.UPDATE_MOBILE_DEVICE_TAB_OPTIONS_SUCCESS,
  payload: data,
});

export const updateMobileDeviceTabOptionsFailure = (error) => ({
  type: AdministrationTypes.UPDATE_MOBILE_DEVICE_TAB_OPTIONS_FAILURE,
  payload: error,
});

export const deleteMobileDeviceOptionsStart = (data) => ({
  type: AdministrationTypes.DELETE_MOBILE_DEVICE_OPTIONS_START,
  payload: data,
});

export const deleteMobileDeviceOptionsSuccess = (data) => ({
  type: AdministrationTypes.DELETE_MOBILE_DEVICE_OPTIONS_SUCCESS,
  payload: data,
});

export const deleteMobileDeviceOptionsFailure = (error) => ({
  type: AdministrationTypes.DELETE_MOBILE_DEVICE_OPTIONS_FAILURE,
  payload: error,
});
/* #endregion */

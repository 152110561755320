import { withRouter } from 'react-router-dom';

import { StyledNavBtn } from './nav-button.style';

const NavButton = (props) => {
  const { label, url, onSelect, icon, history } = props;

  const clickHandler = url ? () => history.push(url) : () => onSelect(true);

  return <StyledNavBtn fill={false} label={label} primary onClick={clickHandler} {...props} />;
};

export default withRouter(NavButton);

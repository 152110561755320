import { createSelector } from 'reselect';

const selectInventory = (state) => state.inventory;

export const selectCreateInjectionForCustomerOnDateResponse = createSelector(
  [selectInventory],
  (inventory) => inventory.create_injection_for_customer_on_date_response,
);

export const selectCreateRetirementForCustomerOnDateResponse = createSelector(
  [selectInventory],
  (inventory) => inventory.create_retirement_for_customer_on_date_response,
);

export const selectInventoryForInventoryID = createSelector(
  [selectInventory],
  (inventory) => inventory.inventory_for_inventory_id,
);

export const selectInventoryForInventoryIDPrevious = createSelector(
  [selectInventory],
  (inventory) => inventory.inventory_for_inventory_id_previous,
);

export const selectItemsNotInInventory = createSelector(
  [selectInventory],
  (inventory) => inventory.items_not_in_inventory,
);

export const selectOrderScheduleForCustomerOnDate = createSelector(
  [selectInventory],
  (inventory) => inventory.order_schedule_for_customer_on_date,
);

export const selectInventoryFormPDF = createSelector(
  [selectInventory],
  (inventory) => inventory.inventory_form_pdf,
);

export const selectUpdateInventoryCountsResponse = createSelector(
  [selectInventory],
  (inventory) => inventory.update_inventory_counts_response,
);

export const selectAddItemToInventoryResponse = createSelector(
  [selectInventory],
  (inventory) => inventory.add_item_to_inventory_response,
);

export const selectCreateInventoryForOrderOnDateResponse = createSelector(
  [selectInventory],
  (inventory) => inventory.create_inventory_for_order_on_date_response,
);

export const selectRcID = createSelector([selectInventory], (inventory) => inventory.selected_rcid);

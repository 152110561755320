import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from '@API';

import {
  getLeadDayValuesFailure,
  getLeadDayValuesSuccess,
} from './setup-users-lead-day-values.actions';
import SetupUsersLeadDayValuesActionTypes from './setup-users-lead-day-values.types';

const leadDayValuesApi = (params) => {
  return api.post('security/get-lead-day-values', params);
};

export function* getLeadDayValues() {
  try {
    const params = {};
    const { data } = yield call(leadDayValuesApi, params);
    yield put(getLeadDayValuesSuccess(data.data));
  } catch (error) {
    yield put(getLeadDayValuesFailure(error));
  }
}

export function* onGetLeadDayValuesStart() {
  yield takeLatest(SetupUsersLeadDayValuesActionTypes.GET_LEAD_DAY_VALUES_START, getLeadDayValues);
}

export function* advancedUserLeadDayValues() {
  yield all([call(onGetLeadDayValuesStart)]);
}

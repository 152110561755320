import SetUpTypes from './setup.types';

/* #region INITAL STATE VALUES */
const INITIAL_STATE = {
  /** PACKS * */
  packs_used_for_customer: [],
  packs_used_for_customer_previous: [],
  product_selected_in_pack: [],
  product_selected_in_pack_previous: [],
  products_not_selected_in_pack: [],
  update_packs_used_for_customer_response: null,
  update_products_selected_and_not_selected_in_pack_response: null,
  add_product_to_pack: null,
  /** PROCEDURES * */
  procedures_used_for_customer: [],
  procedures_used_for_customer_previous: [],
  product_selected_in_procedure: [],
  product_selected_in_procedure_previous: [],
  products_not_selected_in_procedure: [],
  update_procedures_used_for_customer_response: null,
  update_products_selected_and_not_selected_in_procedure_response: null,
  add_product_to_procedure: null,
  /** ORDERS * */
  orders_used_for_customer: [],
  orders_used_for_customer_previous: [],
  items_selected_in_order: [],
  items_selected_in_order_previous: [],
  items_not_selected_in_order: [],
  update_orders_used_for_customer_response: null,
  update_items_selected_and_not_selected_in_order_response: null,
  add_item_to_order: null,
  /** SETUP CUSTOMER * */
  page_of_customers: [],
  page_of_customers_previous: [],
  update_customers_response: null,
  customer_accounts: [],
  /** DEPARTMENTS * */
  departments_used_for_customer: [],
  departments_used_for_customer_previous: [],
  department_account_types: [],
  update_departments_used_for_customer_response: null,
  /** PRODUCTS * */
  products_used_for_customer: [],
  products_used_for_customer_previous: [],
  update_products_used_for_customer_response: null,
  /** COMMON * */
  item_accounts: [],
  handheld_numbers: [],
  inventory_flow: [],
  order_types: [],
  validate_deletion_response: null,
  inactivate_response: null,
  snapshot: null,
  move_to_row: null,
  setUpError: null,
  setUpImportError: null,
};
/* #endregion */

const setUpReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    /* #region PACKS */
    case SetUpTypes.GET_PACKS_USED_FOR_CUSTOMER_SUCCESS:
      return {
        ...state,
        packs_used_for_customer: action.payload,
        packs_used_for_customer_previous: JSON.parse(JSON.stringify(action.payload)),
        setUpError: null,
      };

    case SetUpTypes.GET_PACKS_USED_FOR_CUSTOMER_FAILURE:
      return {
        ...state,
        setUpError: action.payload,
      };

    case SetUpTypes.GET_PRODUCTS_SELECTED_IN_PACK_SUCCESS:
      return {
        ...state,
        product_selected_in_pack: action.payload,
        product_selected_in_pack_previous: JSON.parse(JSON.stringify(action.payload)),
        setUpError: null,
      };

    case SetUpTypes.GET_PRODUCTS_SELECTED_IN_PACK_FAILURE:
      return {
        ...state,
        setUpError: action.payload,
      };

    case SetUpTypes.GET_PRODUCTS_NOT_SELECTED_IN_PACK_SUCCESS:
      return {
        ...state,
        products_not_selected_in_pack: action.payload,
        setUpError: null,
      };

    case SetUpTypes.GET_PRODUCTS_NOT_SELECTED_IN_PACK_FAILURE:
      return {
        ...state,
        setUpError: action.payload,
      };

    case SetUpTypes.UPDATE_PACKS_USED_FOR_CUSTOMER_SUCCESS:
      return {
        ...state,
        update_packs_used_for_customer_response: action.payload,
        setUpError: null,
      };

    case SetUpTypes.UPDATE_PACKS_USED_FOR_CUSTOMER_FAILURE:
      return {
        ...state,
        setUpError: action.payload,
      };

    case SetUpTypes.UPDATE_PRODUCTS_SELECTED_AND_NOT_SELECTED_IN_PACK_SUCCESS:
      return {
        ...state,
        update_products_selected_and_not_selected_in_pack_response: action.payload,
        setUpError: null,
      };

    case SetUpTypes.UPDATE_PRODUCTS_SELECTED_AND_NOT_SELECTED_IN_PACK_FAILURE:
      return {
        ...state,
        setUpError: action.payload,
      };

    case SetUpTypes.ADD_PRODUCT_TO_PACK_SUCCESS:
      return {
        ...state,
        add_product_to_pack: action.payload,
        setUpError: null,
      };

    case SetUpTypes.ADD_PRODUCT_TO_PACK_FAILURE:
      return {
        ...state,
        setUpError: action.payload,
      };
    /* #endregion */

    /* #region PROCEDURES */
    case SetUpTypes.GET_PROCEDURES_USED_FOR_CUSTOMER_SUCCESS:
      return {
        ...state,
        procedures_used_for_customer: action.payload,
        procedures_used_for_customer_previous: JSON.parse(JSON.stringify(action.payload)),
        setUpError: null,
      };

    case SetUpTypes.GET_PROCEDURES_USED_FOR_CUSTOMER_FAILURE:
      return {
        ...state,
        setUpError: action.payload,
      };

    case SetUpTypes.GET_PRODUCTS_SELECTED_IN_PROCEDURE_SUCCESS:
      return {
        ...state,
        product_selected_in_procedure: action.payload,
        product_selected_in_procedure_previous: JSON.parse(JSON.stringify(action.payload)),
        setUpError: null,
      };

    case SetUpTypes.GET_PRODUCTS_SELECTED_IN_PROCEDURE_FAILURE:
      return {
        ...state,
        setUpError: action.payload,
      };

    case SetUpTypes.GET_PRODUCTS_NOT_SELECTED_IN_PROCEDURE_SUCCESS:
      return {
        ...state,
        products_not_selected_in_procedure: action.payload,
        setUpError: null,
      };

    case SetUpTypes.GET_PRODUCTS_NOT_SELECTED_IN_PROCEDURE_FAILURE:
      return {
        ...state,
        setUpError: action.payload,
      };

    case SetUpTypes.UPDATE_PROCEDURES_USED_FOR_CUSTOMER_SUCCESS:
      return {
        ...state,
        update_procedures_used_for_customer_response: action.payload,
        setUpError: null,
      };

    case SetUpTypes.UPDATE_PROCEDURES_USED_FOR_CUSTOMER_FAILURE:
      return {
        ...state,
        setUpError: action.payload,
      };

    case SetUpTypes.UPDATE_PRODUCTS_SELECTED_AND_NOT_SELECTED_IN_PROCEDURE_SUCCESS:
      return {
        ...state,
        update_products_selected_and_not_selected_in_procedure_response: action.payload,
        setUpError: null,
      };

    case SetUpTypes.UPDATE_PRODUCTS_SELECTED_AND_NOT_SELECTED_IN_PROCEDURE_FAILURE:
      return {
        ...state,
        setUpError: action.payload,
      };

    case SetUpTypes.ADD_PRODUCT_TO_PROCEDURE_SUCCESS:
      return {
        ...state,
        add_product_to_procedure: action.payload,
        setUpError: null,
      };

    case SetUpTypes.ADD_PRODUCT_TO_PROCEDURE_FAILURE:
      return {
        ...state,
        setUpError: action.payload,
      };
    /* #endregion */

    /* #region ORDERS */
    case SetUpTypes.GET_ORDERS_USED_FOR_CUSTOMER_SUCCESS:
      return {
        ...state,
        orders_used_for_customer: action.payload,
        orders_used_for_customer_previous: JSON.parse(JSON.stringify(action.payload)),
        setUpError: null,
      };

    case SetUpTypes.GET_ORDERS_USED_FOR_CUSTOMER_FAILURE:
      return {
        ...state,
        setUpError: action.payload,
      };

    case SetUpTypes.GET_ITEMS_SELECTED_IN_ORDER_SUCCESS:
      return {
        ...state,
        items_selected_in_order: action.payload,
        items_selected_in_order_previous: JSON.parse(JSON.stringify(action.payload)),
        setUpError: null,
      };

    case SetUpTypes.GET_ITEMS_SELECTED_IN_ORDER_FAILURE:
      return {
        ...state,
        setUpError: action.payload,
      };

    case SetUpTypes.GET_ITEMS_NOT_SELECTED_IN_ORDER_SUCCESS:
      return {
        ...state,
        items_not_selected_in_order: action.payload,
        setUpError: null,
      };

    case SetUpTypes.GET_ITEMS_NOT_SELECTED_IN_ORDER_FAILURE:
      return {
        ...state,
        setUpError: action.payload,
      };

    case SetUpTypes.UPDATE_ORDERS_USED_FOR_CUSTOMER_SUCCESS:
      return {
        ...state,
        update_orders_used_for_customer_response: action.payload,
        setUpError: null,
      };

    case SetUpTypes.UPDATE_ORDERS_USED_FOR_CUSTOMER_FAILURE:
      return {
        ...state,
        setUpError: action.payload,
      };

    case SetUpTypes.UPDATE_ITEMS_SELECTED_AND_NOT_SELECTED_IN_ORDER_SUCCESS:
      return {
        ...state,
        update_items_selected_and_not_selected_in_order_response: action.payload,
        setUpError: null,
      };

    case SetUpTypes.UPDATE_ITEMS_SELECTED_AND_NOT_SELECTED_IN_ORDER_FAILURE:
      return {
        ...state,
        setUpError: action.payload,
      };

    case SetUpTypes.ADD_ITEM_TO_ORDER_SUCCESS:
      return {
        ...state,
        add_item_to_order: action.payload,
        setUpError: null,
      };

    case SetUpTypes.ADD_ITEM_TO_ORDER_FAILURE:
      return {
        ...state,
        setUpError: action.payload,
      };

    case SetUpTypes.IMPORT_ORDERS_START:
      return {
        ...state,
        update_products_used_for_customer_response: null,
        update_orders_used_for_customer_response: null,
        setUpError: null,
        setUpImportError: null,
      };
    case SetUpTypes.IMPORT_ORDERS_SUCCESS:
      return {
        ...state,
        update_orders_used_for_customer_response: action.payload,
        setUpError: false,
        setUpImportError: false,
      };

    case SetUpTypes.IMPORT_ORDERS_FAILURE:
      return {
        ...state,
        setUpError: null,
        setUpImportError: action.payload,
      };
    /* #endregion */

    /* #region CUSTOMERS */
    case SetUpTypes.GET_PAGE_OF_CUSTOMERS_SUCCESS:
      return {
        ...state,
        page_of_customers: action.payload,
        page_of_customers_previous: JSON.parse(JSON.stringify(action.payload)),
        setUpError: null,
      };

    case SetUpTypes.GET_PAGE_OF_CUSTOMERS_FAILURE:
      return {
        ...state,
        setUpError: action.payload,
      };

    case SetUpTypes.UPDATE_CUSTOMERS_SUCCESS:
      return {
        ...state,
        update_customers_response: action.payload,
        setUpError: null,
      };

    case SetUpTypes.UPDATE_CUSTOMERS_FAILURE:
      return {
        ...state,
        setUpError: action.payload,
      };

    case SetUpTypes.GET_CUSTOMER_ACCOUNTS_SUCCESS:
      return {
        ...state,
        customer_accounts: action.payload,
        setUpError: null,
      };

    case SetUpTypes.GET_CUSTOMER_ACCOUNTS_FAILURE:
      return {
        ...state,
        setUpError: action.payload,
      };
    /* #endregion */

    /* #region DEPARTMENTS */
    case SetUpTypes.GET_DEPARTMENTS_USED_FOR_CUSTOMER_SUCCESS:
      return {
        ...state,
        departments_used_for_customer: action.payload,
        departments_used_for_customer_previous: JSON.parse(JSON.stringify(action.payload)),
        setUpError: null,
      };

    case SetUpTypes.GET_DEPARTMENTS_USED_FOR_CUSTOMER_FAILURE:
      return {
        ...state,
        setUpError: action.payload,
      };

    case SetUpTypes.UPDATE_DEPARTMENTS_USED_FOR_CUSTOMER_SUCCESS:
      return {
        ...state,
        update_departments_used_for_customer_response: action.payload,
        setUpError: null,
      };

    case SetUpTypes.UPDATE_DEPARTMENTS_USED_FOR_CUSTOMER_FAILURE:
      return {
        ...state,
        setUpError: action.payload,
      };

    case SetUpTypes.GET_DEPARTMENTS_ACCT_TYPE_SUCCESS:
      return {
        ...state,
        department_account_types: action.payload,
        setUpError: null,
      };

    case SetUpTypes.GET_DEPARTMENTS_ACCT_TYPE_FAILURE:
      return {
        ...state,
        setUpError: action.payload,
      };
    /* #endregion */

    /* #region PRODUCTS */
    case SetUpTypes.GET_PRODUCTS_USED_FOR_CUSTOMER_SUCCESS:
      return {
        ...state,
        products_used_for_customer: action.payload,
        products_used_for_customer_previous: JSON.parse(JSON.stringify(action.payload)),
        setUpError: null,
      };

    case SetUpTypes.GET_PRODUCTS_USED_FOR_CUSTOMER_FAILURE:
      return {
        ...state,
        setUpError: action.payload,
      };

    case SetUpTypes.UPDATE_PRODUCTS_USED_FOR_CUSTOMER_SUCCESS:
      return {
        ...state,
        update_products_used_for_customer_response: action.payload,
        setUpError: null,
      };

    case SetUpTypes.UPDATE_PRODUCTS_USED_FOR_CUSTOMER_FAILURE:
      return {
        ...state,
        setUpError: action.payload,
      };

    case SetUpTypes.IMPORT_PRODUCTS_START:
      return {
        ...state,
        update_products_used_for_customer_response: null,
        update_orders_used_for_customer_response: null,
        setUpError: null,
        setUpImportError: null,
      };

    case SetUpTypes.IMPORT_PRODUCTS_SUCCESS:
      return {
        ...state,
        update_products_used_for_customer_response: action.payload,
        setUpError: null,
        setUpImportError: false,
      };

    case SetUpTypes.IMPORT_PRODUCTS_FAILURE:
      return {
        ...state,
        setUpError: null,
        setUpImportError: action.payload,
      };
    /* #endregion */

    /* #region COMMON */
    case SetUpTypes.GET_ITEM_ACCOUNTS_SUCCESS:
      return {
        ...state,
        item_accounts: action.payload,
        setUpError: null,
      };

    case SetUpTypes.GET_ITEM_ACCOUNTS_FAILURE:
      return {
        ...state,
        setUpError: action.payload,
      };

    // HANDHELD NUMBERS
    case SetUpTypes.GET_HANDHELD_NUMBERS_SUCCESS:
      return {
        ...state,
        handheld_numbers: action.payload,
        setUpError: null,
      };

    case SetUpTypes.GET_HANDHELD_NUMBERS_FAILURE:
      return {
        ...state,
        setUpError: action.payload,
      };

    // INVENTORY FLOW
    case SetUpTypes.GET_INVENTORY_FLOW_SUCCESS:
      return {
        ...state,
        inventory_flow: action.payload,
        setUpError: null,
      };

    case SetUpTypes.GET_INVENTORY_FLOW_FAILURE:
      return {
        ...state,
        setUpError: action.payload,
      };

    // ORDER TYPES
    case SetUpTypes.GET_ORDER_TYPES_SUCCESS:
      return {
        ...state,
        order_types: action.payload,
        setUpError: null,
      };

    case SetUpTypes.GET_ORDER_TYPES_FAILURE:
      return {
        ...state,
        setUpError: action.payload,
      };

    // VALIDATE DELETION
    case SetUpTypes.VALIDATE_DELETION_SUCCESS:
      return {
        ...state,
        validate_deletion_response: action.payload,
        setUpError: null,
      };

    case SetUpTypes.VALIDATE_DELETION_FAILURE:
      return {
        ...state,
        setUpError: action.payload,
      };

    case SetUpTypes.INACTIVATE_SUCCESS:
      return {
        ...state,
        inactivate_response: action.payload,
        setUpError: null,
      };

    case SetUpTypes.INACTIVATE_FAILURE:
      return {
        ...state,
        setUpError: action.payload,
      };

    case SetUpTypes.GET_SNAPSHOT_SUCCESS:
      return {
        ...state,
        snapshot: action.payload,
        setUpError: null,
      };

    case SetUpTypes.GET_SNAPSHOT_FAILURE:
      return {
        ...state,
        setUpError: action.payload,
      };

    case SetUpTypes.MOVE_TO_ROW_SUCCESS:
      return {
        ...state,
        move_to_row: action.payload,
        setUpError: null,
      };

    case SetUpTypes.MOVE_TO_ROW_FAILURE:
      return {
        ...state,
        setUpError: action.payload,
      };
    /* #endregion */

    default:
      return state;
  }
};
export default setUpReducer;

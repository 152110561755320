import { Box, Form } from 'grommet';

import { PrimaryButton } from '@ThemeMain';

import { ModalBody } from './message-modal.style';

const MessageModal = (props) => {
  const {
    show,
    setShow,
    title,
    confirmLabel = 'OK',
    onConfirm,
    cancelLabel = 'Cancel',
    onCancel,
    onClose,
    showButtons,
  } = props;

  const closeModal = () => {
    onClose();
    setShow(false);
  };

  const confirmModal = (event) => {
    const confirmSuccess = onConfirm(event);
    if (confirmSuccess !== false) {
      // strictly false, i.e. undefined/void doesn't count
      closeModal();
    }
    event.preventDefault();
  };

  const cancelModal = (event) => {
    const cancelSuccess = onCancel(event);
    if (cancelSuccess !== false) {
      // strictly false, i.e. undefined/void doesn't count
      closeModal();
    }
    event.preventDefault();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    confirmModal(event);
  };

  // TODO: enable adding other buttons.
  if (show) {
    return (
      <ModalBody {...props} title={title} onCancel={closeModal}>
        <Form onSubmit={handleSubmit}>
          <Box gap="medium">
            <Box>{props.children}</Box>
            <Box direction="row" justify="end" gap="small">
              {showButtons === true ? (
                <PrimaryButton
                  id="cancelButton"
                  label={cancelLabel}
                  style={{ opacity: 0.3 }}
                  onClick={(event) => cancelModal(event)}
                />
              ) : (
                <PrimaryButton
                  id="cancelButton"
                  label={cancelLabel}
                  style={{ visibility: 'hidden', maxHeight: 0 }}
                  onClick={(event) => cancelModal(event)}
                />
              )}
              {showButtons === true ? (
                <PrimaryButton
                  id="submitButton"
                  type="submit"
                  label={confirmLabel}
                  onClick={(event) => confirmModal(event)}
                />
              ) : (
                <PrimaryButton
                  id="submitButton"
                  type="submit"
                  label={confirmLabel}
                  style={{ visibility: 'hidden', maxHeight: 0 }}
                  onClick={(event) => confirmModal(event)}
                />
              )}
            </Box>
          </Box>
        </Form>
      </ModalBody>
    );
  }
  return null;
};

export default MessageModal;

import SetupUsersDetailsForOrderSelectedActionTypes from './setup-users-details-for-order-selected-in-user.types';

export const getDetailsForOrderSelectedStart = () => ({
  type: SetupUsersDetailsForOrderSelectedActionTypes.GET_DETAILS_FOR_ORDER_SELECTED_START,
  payload: null,
});

export const getDetailsForOrderSelectedSuccess = (detailsForOrder) => ({
  type: SetupUsersDetailsForOrderSelectedActionTypes.GET_DETAILS_FOR_ORDER_SELECTED_SUCCESS,
  payload: detailsForOrder,
});

export const getDetailsForOrderSelectedFailure = (error) => ({
  type: SetupUsersDetailsForOrderSelectedActionTypes.GET_DETAILS_FOR_ORDER_SELECTED_FAILURE,
  payload: error,
});

export const updateDetailsForOrderSelectedStart = (orderdetails) => ({
  type: SetupUsersDetailsForOrderSelectedActionTypes.UPDATE_DETAILS_FOR_ORDER_SELECTED_START,
  payload: orderdetails,
});

export const updateDetailsForOrderSelectedSuccess = () => ({
  type: SetupUsersDetailsForOrderSelectedActionTypes.UPDATE_DETAILS_FOR_ORDER_SELECTED_SUCCESS,
  payload: null,
});

export const updateDetailsForOrderSelectedFailure = (error) => ({
  type: SetupUsersDetailsForOrderSelectedActionTypes.UPDATE_DETAILS_FOR_ORDER_SELECTED_FAILURE,
  payload: error,
});

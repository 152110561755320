import { Box, Button, Heading, Layer, Text } from 'grommet';
import { useEffect, useState } from 'react';

const Dialog = (props) => {
  const {
    showModal,
    setShowModal,
    message,
    pageTitle,
    timeout,
    showOkButton,
    showCancelButton,
    textColor,
    confirm,
  } = props;

  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(showModal);
    if (showModal && !showOkButton) {
      setTimeout(() => {
        setShowModal(false);
      }, timeout);
    }
  }, [showModal]);

  const onCancel = () => {
    setShowModal(false);
  };

  const onConfirm = () => {
    if (pageTitle === 'Warning') {
      confirm();
    }
    setShowModal(false);
  };

  return (
    <>
      {show && (
        <Layer size="" onEsc={onCancel} onClickOutside={onCancel}>
          <Box pad="medium" gap="small" width="medium">
            <Heading level={3} margin="none">
              {pageTitle}
            </Heading>
            <Text style={{ whiteSpace: 'pre-line', color: textColor }}>{message}</Text>
            <Box
              as="footer"
              gap="small"
              direction="row"
              align="center"
              justify="end"
              pad={{ top: 'medium', bottom: 'small' }}
            >
              {showOkButton && <Button label="OK" color="dark-3" onClick={() => onConfirm()} />}
              {showCancelButton && <Button label="Cancel" onClick={() => onCancel()} />}
            </Box>
          </Box>
        </Layer>
      )}
    </>
  );
};

export default Dialog;

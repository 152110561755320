import AdministrationTypes from './administration.types';

const INITIAL_STATE = {
  log_transactions_selection_tree: null,
  system_configuration_selection_tree: null,
  selected_logs: null,
  items_in_log: null,
  general_tab_options: null,
  prev_general_tab_options: null,
  update_general_options_response: null,
  mobile_device_tabs: null,
  mobile_device_tab_options: null,
  prev_mobile_device_tab_options: null,
  update_mobile_device_tab_options_response: null,
  delete_mobile_device_options_response: null,
  error: null,
};

const administrationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    /* #region Log Transactions */

    case AdministrationTypes.GET_LOG_TRANSACTIONS_SELECTION_TREE_SUCCESS:
      return {
        ...state,
        log_transactions_selection_tree: action.payload,
        error: null,
      };

    case AdministrationTypes.GET_LOG_TRANSACTIONS_SELECTION_TREE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case AdministrationTypes.GET_SYSTEM_CONFIGURATION_SELECTION_TREE_SUCCESS:
      return {
        ...state,
        system_configuration_selection_tree: action.payload,
        error: null,
      };

    case AdministrationTypes.GET_SYSTEM_CONFIGURATION_SELECTION_TREE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case AdministrationTypes.GET_SELECTED_LOGS_SUCCESS:
      return {
        ...state,
        selected_logs: action.payload,
        error: null,
      };

    case AdministrationTypes.GET_SELECTED_LOGS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case AdministrationTypes.GET_ITEMS_IN_LOG_SUCCESS:
      return {
        ...state,
        items_in_log: action.payload,
        error: null,
      };

    case AdministrationTypes.GET_ITEMS_IN_LOG_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    /* #endregion */

    /* #region System Configuration */
    case AdministrationTypes.GET_GENERAL_TAB_OPTIONS_SUCCESS:
      return {
        ...state,
        general_tab_options: action.payload,
        prev_general_tab_options: JSON.parse(JSON.stringify(action.payload)),
        error: null,
      };

    case AdministrationTypes.GET_GENERAL_TAB_OPTIONS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case AdministrationTypes.SET_GENERAL_TAB_OPTIONS_SUCCESS:
      return {
        ...state,
        general_tab_options: action.payload,
        error: null,
      };

    case AdministrationTypes.SET_GENERAL_TAB_OPTIONS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case AdministrationTypes.UPDATE_GENERAL_OPTIONS_SUCCESS:
      return {
        ...state,
        update_general_options_response: action.payload,
        error: null,
      };

    case AdministrationTypes.UPDATE_GENERAL_OPTIONS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case AdministrationTypes.GET_MOBILE_DEVICE_TABS_SUCCESS:
      return {
        ...state,
        mobile_device_tabs: action.payload,
        error: null,
      };

    case AdministrationTypes.GET_MOBILE_DEVICE_TABS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case AdministrationTypes.GET_MOBILE_DEVICE_TAB_OPTIONS_SUCCESS:
      return {
        ...state,
        mobile_device_tab_options: action.payload,
        prev_mobile_device_tab_options: JSON.parse(JSON.stringify(action.payload)),
        error: null,
      };

    case AdministrationTypes.GET_MOBILE_DEVICE_TAB_OPTIONS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case AdministrationTypes.SET_MOBILE_DEVICE_TAB_OPTIONS_SUCCESS:
      return {
        ...state,
        mobile_device_tab_options: action.payload,
        error: null,
      };

    case AdministrationTypes.SET_MOBILE_DEVICE_TAB_OPTIONS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case AdministrationTypes.UPDATE_MOBILE_DEVICE_TAB_OPTIONS_SUCCESS:
      return {
        ...state,
        update_mobile_device_tab_options_response: action.payload,
        error: null,
      };

    case AdministrationTypes.UPDATE_MOBILE_DEVICE_TAB_OPTIONS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case AdministrationTypes.DELETE_MOBILE_DEVICE_OPTIONS_SUCCESS:
      return {
        ...state,
        delete_mobile_device_options_response: action.payload,
        error: null,
      };

    case AdministrationTypes.DELETE_MOBILE_DEVICE_OPTIONS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    /* #endregion */

    default:
      return state;
  }
};
export default administrationReducer;

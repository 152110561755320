import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from '@API';

import {
  getHandHeldNumberFailure,
  getHandHeldNumberSuccess,
} from './setup-users-hand-held-numbers.actions';
import SetupUsersHandHeldNumberActionTypes from './setup-users-hand-held-numbers.types';

const handHeldNumberApi = (params) => {
  return api.post('setuptables/get-hand-held-numbers', params);
};

export function* getHandHeldNumber(action) {
  try {
    const params = {};
    const { data } = yield call(handHeldNumberApi, params);
    yield put(getHandHeldNumberSuccess(data.data));
  } catch (error) {
    yield put(getHandHeldNumberFailure(error));
  }
}

export function* onGetHandHeldNumberStart() {
  yield takeLatest(
    SetupUsersHandHeldNumberActionTypes.GET_HAND_HELD_NUMBER_START,
    getHandHeldNumber,
  );
}

export function* advancedUserHandHeldNumber() {
  yield all([call(onGetHandHeldNumberStart)]);
}

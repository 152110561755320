import dateformat from 'dateformat';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import api from '@API';
import { download } from '@Helpers/download.helper';
import { ReportNoBusinessLogicFilters } from '@Pages/reports/reports-config';

import { selectCurrentCustomer } from '../user/user.selectors';
import {
  emailTransactionsFailure,
  emailTransactionsSuccess,
  getMonthlyBudgetCensusUsedForCustomerFailure,
  getMonthlyBudgetCensusUsedForCustomerSuccess,
  getReportFilterOptionsFailure,
  getReportFilterOptionsSuccess,
  getReportSelectionTreeFailure,
  getReportSelectionTreeSuccess,
  getReportSnapshotFailure,
  getReportSnapshotSuccess,
  getReportTransactionsFailure,
  getReportTransactionsSuccess,
  updateMonthlyBudgetCensusUsedForCustomerFailure,
  updateMonthlyBudgetCensusUsedForCustomerSuccess,
} from './reports.actions';
import { selectReportData, selectReportSelectionTree, selectReportType } from './reports.selectors';
import ReportsTypes from './reports.types';

const budgetCensusDateFormat = "yyyy-mm-01'T'12:00:00";
const budgetCensusDownloadDateFormat = 'yyyy-mm';

// Get timezone offset for user's browser; multiply by -1 to give proper UTC offset
const timezoneOffset = -1 * (new Date().getTimezoneOffset() / 60);

const reportOptionsApi = (type, params) => {
  return api.post(`reports/options?report=${type}`, params);
};

const reportTransactionsApi = (type, params) => {
  return api.post(`reports?report=${type}`, params);
};

const reportSelectionTreeApi = (type, params) => {
  return api.post(`reports/selection-tree?report=${type}`, params);
};

const getReportBudgetCensusUsedForCustomerApi = (type, params) => {
  return api.post(`set-${type}/get-monthly-${type}-used-for-customer`, params);
};

const updateReportBudgetCensusUsedForCustomerApi = (type, params) => {
  return api.post(`set-${type}/update-monthly-${type}-used-for-customer`, params);
};

const getSnapshotApi = (type, params) => {
  return api.post(`set-${type}/get-snapshot`, params);
};

const getEmailDocumentApi = (params) => {
  return api.post(`reports/email-transactions`, params);
};

export function* getReportFilterOptions() {
  try {
    const backendType = yield select(selectReportType);
    const currentCustomer = yield select(selectCurrentCustomer);
    const params = { customerID: currentCustomer.RcID };

    if (backendType) {
      // Set Budget and Set Census
      if (ReportNoBusinessLogicFilters[backendType]) {
        // Format with month by month selection calendar

        // Default date is today
        const todayString = dateformat(new Date(), budgetCensusDateFormat);
        const promptString = dateformat(new Date(), 'mmmm, yyyy');

        yield put(
          getReportFilterOptionsSuccess({
            ReportOptionTable: [
              {
                $attributes: { id: 'ReportOptionTable1', rowOrder: '0', hasChanges: 'inserted' },
                OptionID: '1',
                DataType: 'MONTHLY',
                Prompt1: `${
                  backendType.charAt(0).toUpperCase() + backendType.slice(1)
                } for ${promptString}`,
                GroupNext: 'false',
                DateTimeValue1: todayString,
                Hide: 'false',
                DrillDown: '0',
              },
            ],
          }),
        );
        return;
      }

      const { data } = yield call(reportOptionsApi, backendType, params);
      yield put(getReportFilterOptionsSuccess(data.data[0]));
    }
  } catch (error) {
    yield put(getReportFilterOptionsFailure(error));
  }
}

export function* getReportTransactions(action) {
  try {
    const backendType = yield select(selectReportType);
    const currentCustomer = yield select(selectCurrentCustomer);
    const params = { FormData: action.payload, customerID: currentCustomer.RcID, timezoneOffset };
    const { data } = yield call(reportTransactionsApi, backendType, params);

    if (data.data) {
      // Data Table formatting
      data.hasDataTableData = true;
    }

    yield put(getReportTransactionsSuccess(data));
  } catch (error) {
    yield put(getReportTransactionsFailure(error));
  }
}

export function* getReportSelectionTree(action) {
  try {
    const currentCustomer = yield select(selectCurrentCustomer);
    const backendType = yield select(selectReportType);

    // TODO: [LH-294] Have report options changed
    let shouldRefresh = false;
    if (action.payload.shouldRefresh) {
      shouldRefresh = action.payload.shouldRefresh;

      if (action.payload.selection) {
        Object.keys(action.payload.selection).forEach((key) => {
          action.payload.selection[key] = 0;
        });
      }
    }

    if (action.payload.hasOwnProperty('shouldRefresh')) {
      delete action.payload.shouldRefresh;
    }

    const params = {
      FormData: action.payload,
      customerID: currentCustomer.RcID,
      timezoneOffset,
    };

    // Contains the chain of ids from the front end
    const idChain = action.payload.selection ? Object.values(action.payload.selection) : [];

    const { data } = yield call(reportSelectionTreeApi, backendType, params);
    if (data.status && data.status === 'ok') {
      const currentSelectionTree = yield select(selectReportSelectionTree);

      // Business logic sends back the true root node when Selection has a problem
      // If the first key comes back as root, and we're not refreshing, something went wrong
      // and we should refresh anyway
      const firstRow = data.rows[Object.keys(data.rows)[0]];
      const startsWithRoot = data.rows
        ? firstRow.RcID === '0' && firstRow.data.ObjtDesc !== 'Not Available'
        : false;
      if (!shouldRefresh && !startsWithRoot && currentSelectionTree && currentSelectionTree.rows) {
        // Merge data into existing tree
        Object.entries(data.rows).forEach(([idString, subsetInfo]) => {
          // Iterate down the chain of provided IDs to find where to put the data
          let selected = currentSelectionTree.rows['pId-0'];
          for (let i = 0; i < idChain.length; i++) {
            if (idChain[i] !== 0) {
              const idChainString = `pId-${idChain.slice(0, i + 1).join('-')}`;
              if (selected.rows[idChainString]) {
                selected = selected.rows[idChainString];
              }
            }
          }
          // TODO: use ids from form to find level of tree node
          // find that parent by id in existing selection tree
          if (selected && selected.rows instanceof Object) {
            const info = JSON.parse(JSON.stringify(subsetInfo));
            info.hasChildren = Object.keys(info.rows).length > 0;
            selected.rows[idString] = info;
          }
        });

        if (data.base64ReportingData && currentSelectionTree.base64ReportingData) {
          currentSelectionTree.base64ReportingData = data.base64ReportingData;
        }
        // Save final product to state
        yield put(getReportSelectionTreeSuccess({ ...currentSelectionTree }));
      } else {
        // TODO: Why is business logic returning null all of a sudden?
        if (data.rows === null) data.rows = {};
        // New tree goes into the state as is
        yield put(getReportSelectionTreeSuccess(data));
      }
    }
  } catch (error) {
    yield put(getReportSelectionTreeFailure(error));
  }
}

export function* getMonthlyBudgetCensusUsedForCustomer(action) {
  try {
    const currentCustomer = yield select(selectCurrentCustomer);
    const backendType = yield select(selectReportType);
    const params = {
      RequestedMonth: action.payload,
      customerID: currentCustomer.RcID,
      timezoneOffset,
    };
    const { data } = yield call(getReportBudgetCensusUsedForCustomerApi, backendType, params);

    yield put(getMonthlyBudgetCensusUsedForCustomerSuccess(data.data));
  } catch (error) {
    yield put(getMonthlyBudgetCensusUsedForCustomerFailure(error));
  }
}

export function* updateMonthlyBudgetCensusUsedForCustomer(action) {
  try {
    const params = { differences: action.payload.differences, timezoneOffset };
    const { type } = params.differences;
    const { data } = yield call(updateReportBudgetCensusUsedForCustomerApi, type, params);

    if (data.status === 'ok') {
      yield put(updateMonthlyBudgetCensusUsedForCustomerSuccess(action.payload.modded));
    }
  } catch (error) {
    yield put(updateMonthlyBudgetCensusUsedForCustomerFailure(error));
  }
}

export function* getSnapshot(action) {
  try {
    const currentCustomer = yield select(selectCurrentCustomer);
    const backendType = yield select(selectReportType);
    const snapshotDate = new Date(action.payload.ReportOptionTable[0].DateTimeValue1);
    const params = {
      customerID: currentCustomer.RcID,
      timezoneOffset,
      RequestedMonth: dateformat(snapshotDate, budgetCensusDateFormat),
    };
    const { data } = yield call(getSnapshotApi, backendType, params);

    if (data && data.data[0] && data.data[0].ReptData) {
      download({
        name: `report-snapshot-${dateformat(snapshotDate, budgetCensusDownloadDateFormat)}`,
        type: 'xlsx',
        base64Data: data.data[0].ReptData,
      });
    } else {
      console && console.warn && console.warn('No XLS snapshot data in API response.');
    }

    yield put(getReportSnapshotSuccess(data));
  } catch (error) {
    yield put(getReportSnapshotFailure(error));
  }
}

export function* emailTransactions(action) {
  try {
    const { formEmail, emailType } = action.payload;
    const ReportData = yield select(selectReportData);
    if (ReportData) {
      const [pdfPackets, sheetPackets] = ReportData;
      let packet;
      switch (emailType) {
        case 'document':
          // use spreadsheet element
          packet = pdfPackets;
          break;
        case 'spreadsheet':
          // use document element
          packet = sheetPackets;
          break;
        default:
          // fail
          return;
      }
      const params = {
        recipientEmailAddress: formEmail,
        packet,
      };

      if (packet) {
        const { data } = yield call(getEmailDocumentApi, params);

        yield put(emailTransactionsSuccess(data));
      }
    }
  } catch (error) {
    yield put(emailTransactionsFailure(error));
  }
}

export function* onReportSelectionTreeStart() {
  yield takeLatest(ReportsTypes.GET_REPORT_SELECTION_TREE_START, getReportSelectionTree);
}

export function* onReportFilterOptionsStart() {
  yield takeLatest(ReportsTypes.GET_REPORT_FILTER_OPTIONS_START, getReportFilterOptions);
}

export function* onReportTransactionsStart() {
  yield takeLatest(ReportsTypes.GET_REPORT_TRANSACTIONS_START, getReportTransactions);
}

export function* onGetMonthlyBudgetCensusStart() {
  yield takeLatest(
    ReportsTypes.GET_MONTHLY_BUDGET_CENSUS_USED_FOR_CUSTOMER_START,
    getMonthlyBudgetCensusUsedForCustomer,
  );
}

export function* onUpdateMonthlyBudgetCensusStart() {
  yield takeLatest(
    ReportsTypes.UPDATE_MONTHLY_BUDGET_CENSUS_USED_FOR_CUSTOMER_START,
    updateMonthlyBudgetCensusUsedForCustomer,
  );
}

export function* onGetReportSnapshotStart() {
  yield takeLatest(ReportsTypes.GET_REPORT_SNAPSHOT_START, getSnapshot);
}

export function* onEmailTransactionsStart() {
  yield takeLatest(ReportsTypes.EMAIL_DOCUMENT_START, emailTransactions);
}

export function* reportsSagas() {
  yield all([
    call(onReportFilterOptionsStart),
    call(onReportTransactionsStart),
    call(onReportSelectionTreeStart),
    call(onGetMonthlyBudgetCensusStart),
    call(onUpdateMonthlyBudgetCensusStart),
    call(onGetReportSnapshotStart),
    call(onEmailTransactionsStart),
  ]);
}

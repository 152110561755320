import { Box, DataTable, Text } from 'grommet';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import SetupHeader from '@Components/setup-advanced-header/setup-advanced-header.component';
import SetupUsersTab from '@Components/setup-users-tabs/setup-users-tabs.component';
import { TableCell } from '@material-ui/core';
import {
  getAdvancedUserStart,
  setCurrentAdvancedUser,
} from '@State/setup-advanced-users/setup-advanced-users.actions';
import {
  selectAdvancedCurrentUsers,
  selectAdvancedUsers,
} from '@State/setup-advanced-users/setup-advanced-users.selectors';
import { getCustomerNotSelectedStart } from '@State/setup-users-customer-not-selected-in-user/setup-users-customer-not-selected-in-user.actions';
import { getCustomerSelectedStart } from '@State/setup-users-customer-selected-in-user/setup-users-customer-selected-in-user.actions';
import { getHandHeldNumberStart } from '@State/setup-users-hand-held-numbers/setup-users-hand-held-numbers.actions';
import { getLeadDayValuesStart } from '@State/setup-users-lead-day-values/setup-users-lead-day-values.actions';
import { getOrderNotSelectedStart } from '@State/setup-users-order-not-selected-in-user/setup-users-order-not-selected-in-user.actions';
import {
  getOrderSelectedStart,
  setUserCurrentOrder,
} from '@State/setup-users-order-selected-in-user/setup-users-order-selected-in-user.actions';
import { selectPrivileges } from '@State/user/user.selectors';
import {
  borders,
  colors,
  measures,
  Page,
  Panel,
  ResizableColumnTable,
  ScrollableBox,
} from '@ThemeMain';

const UserList = styled(DataTable)`
  tbody {
    tr {
      th:first-child {
        display: none;
      }
    }
  }

  thead {
    tr {
      th:first-child {
        display: none;
      }
    }
  }
`;

const SetupUsersPage = ({
  getAdvancedUsers,
  advancedUser,
  setCurrentUser,
  getLeadDayValues,
  getHandHeldNumber,
  setCurrentOrder,
  userPrivileges,
  getCustomerSelected,
  getCustomerNotSelected,
  getOrderSelected,
  getOrderNotSelected,
}) => {
  const [currentSelected, setCurrentSelected] = useState({ RcID: null });
  const [changes, setChanges] = useState([]);

  const onRowClick = (data) => {
    setCurrentUser(data);
    setCurrentSelected(data);
    setCurrentOrder(null);
    getCustomerSelected();
    getCustomerNotSelected();
    getOrderSelected();
    getOrderNotSelected();
    setChanges([]);
  };

  const updateSelectedUser = (data) => {
    let selected = {};
    if (currentSelected.RcID !== null) {
      const index = advancedUser.findIndex((item) => item.RcID === currentSelected.RcID);
      if (index >= 0) {
        selected = advancedUser[index];
      } else if (changes.length > 0) {
        selected = advancedUser[advancedUser.length - 1];
      } else {
        selected = advancedUser[0];
      }
    } else {
      selected = advancedUser[0];
    }
    onRowClick(selected);
  };

  useEffect(() => {
    getAdvancedUsers();
    getLeadDayValues();
    getHandHeldNumber();
  }, []);

  useEffect(() => {
    if (advancedUser !== null && setCurrentSelected) {
      if (advancedUser.length > 0) {
        updateSelectedUser(advancedUser);
      }
    }
  }, [advancedUser]);

  if (userPrivileges && userPrivileges.isAdminUser) {
    const columns = [
      { property: 'RcID', header: '', primary: true },
      {
        property: 'Name',
        header: 'Name',
        render: (datum) => (
          <Text className={currentSelected.RcID === datum.RcID ? 'white-label' : ''}>
            {datum.Name}
          </Text>
        ),
      },
      {
        property: 'UserDesc',
        header: 'Description',
        render: (datum) => (
          <Text className={currentSelected.RcID === datum.RcID ? 'white-label' : ''}>
            {datum.UserDesc}
          </Text>
        ),
      },
    ];
    const formData = {
      RcID: '0',
      Name: '',
      Pswd: '',
      UserDesc: '',
      EmalAddr: '',
      Guid: '',
      HhldNmbr: '1',
      HhldIntvSund: '0',
      HhldIntvMond: '0',
      HhldIntvTues: '0',
      HhldIntvWedn: '0',
      HhldIntvThur: '0',
      HhldIntvFrid: '0',
      HhldIntvSatr: '0',
      HhldSwchTime: '0',
      PrivAdmn: '0',
      PrivEvryCust: '0',
      PrivEvryOrdr: '0',
      PrivCustView: '0',
      PrivCustMdfy: '0',
      PrivProdView: '0',
      PrivProdMdfy: '0',
      PrivPackView: '0',
      PrivPackMdfy: '0',
      PrivProcView: '0',
      PrivProcMdfy: '0',
      PrivOrdrView: '0',
      PrivOrdrMdfy: '0',
      PrivWearView: '0',
      PrivWearMdfy: '0',
      PrivGTypView: '0',
      PrivGTypMdfy: '0',
      PrivTaskView: '0',
      PrivTaskMdfy: '0',
      PrivDeptView: '0',
      PrivDeptMdfy: '0',
      PrivAcctView: '0',
      PrivAcctMdfy: '0',
      PrivCensView: '0',
      PrivCensMdfy: '0',
      PrivBudgView: '0',
      PrivBudgMdfy: '0',
      PrivDelvAutoStck: '0',
      PrivDelvGlobAdjt: '0',
      PrivDelvLdryView: '0',
      PrivDelvLdryMdfy: '0',
      PrivDelvLdryStckView: '0',
      PrivDelvLdryStckMdfy: '0',
      PrivDelvLdryOhndView: '0',
      PrivDelvLdryOhndMdfy: '0',
      PrivDelvLdryRqrdView: '0',
      PrivDelvLdryRqrdMdfy: '0',
      PrivDelvLdryLdryView: '0',
      PrivDelvLdryLdryMdfy: '0',
      PrivDelvLdryLnrmView: '0',
      PrivDelvLdryLnrmMdfy: '0',
      PrivDelvLdryRetnView: '0',
      PrivDelvLdryRetnMdfy: '0',
      PrivDelvLdrySpecOrdr: '0',
      PrivDelvLdrySpecItem: '0',
      PrivDelvLnrmView: '0',
      PrivDelvLnrmMdfy: '0',
      PrivInvtView: '0',
      PrivInvtMdfy: '0',
      PrivEvryDate: '0',
      PrivRept: '0',
      HomePageName: '',
    };

    const evaluateChange = (item) => {
      const data = changes;
      const index = data.indexOf(item.key);

      if (currentSelected[item.key] !== item.new && index === -1) {
        data.push(item.key);
      } else if (
        index > -1 &&
        (currentSelected[item.key] === item.new ||
          item.key.startsWith('customer') ||
          item.key.startsWith('order'))
      ) {
        data.splice(index, 1);
      }
      setChanges(data);
    };

    return (
      <Page>
        <Panel background={colors.innerBg} pad="medium">
          <SetupHeader
            title="Setup Users"
            data={formData}
            changes={changes}
            setChanges={setChanges}
            currentSelected={currentSelected}
            setCurrentSelected={setCurrentSelected}
          />
          <ResizableColumnTable className="td-block td-cell-2">
            <TableCell>
              <Box
                style={{
                  display: 'block',
                }}
                direction="column"
                gap="0.2rem"
                gridArea="nav"
                border={borders.grid}
              >
                <ScrollableBox>
                  {advancedUser ? (
                    <UserList
                      className="overlapped-box"
                      primaryKey="RcID"
                      columns={columns}
                      data={advancedUser}
                      step={advancedUser ? advancedUser.length : 0}
                      bodySize={measures.dataTableDoubleHeaderBodyHeight}
                      rowProps={{ [currentSelected.RcID]: { background: colors.brand } }}
                      sortable
                      onClickRow={(event) => onRowClick(event.datum)}
                    />
                  ) : (
                    <DataTable columns={columns} data={[]} />
                  )}
                </ScrollableBox>
              </Box>
            </TableCell>
            <TableCell>
              <Box
                style={{
                  display: 'block',
                }}
                direction="column"
                gap="0.2rem"
                gridArea="nav"
                border={borders.grid}
              >
                <ScrollableBox>
                  <SetupUsersTab
                    changes={changes}
                    evaluateChange={(data) => evaluateChange(data)}
                  />
                </ScrollableBox>
              </Box>
            </TableCell>
          </ResizableColumnTable>
        </Panel>
      </Page>
    );
  }
  return null;
};

const mapStateToProps = createStructuredSelector({
  userPrivileges: selectPrivileges,
  advancedUser: selectAdvancedUsers,
  advancedCurrentUser: selectAdvancedCurrentUsers,
});

const mapDispatchToProps = (dispatch) => ({
  getAdvancedUsers: () => dispatch(getAdvancedUserStart()),
  getLeadDayValues: () => dispatch(getLeadDayValuesStart()),
  getHandHeldNumber: () => dispatch(getHandHeldNumberStart()),
  setCurrentUser: (user) => dispatch(setCurrentAdvancedUser(user)),
  setCurrentOrder: (order) => dispatch(setUserCurrentOrder(order)),
  getCustomerSelected: () => dispatch(getCustomerSelectedStart()),
  getCustomerNotSelected: () => dispatch(getCustomerNotSelectedStart()),
  getOrderSelected: () => dispatch(getOrderSelectedStart()),
  getOrderNotSelected: () => dispatch(getOrderNotSelectedStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SetupUsersPage);

import axios from 'axios';

import { environment } from '@AppRoot/environments/environment';
import { store } from '@State/store';
import { signOutStart } from '@State/user/user.actions';

const instance = axios.create({ baseURL: environment.baseURL });

// Add authorization to each call if available
instance.interceptors.request.use(
  async (config) => {
    const state = store.getState();
    const { currentUser } = state.user;
    const siteInfoToken = state.user.siteInfo ? state.user.siteInfo.siteInfoToken : null;
    config.headers['client-name'] = window._appConfig.ENV_PREFIX;

    if (currentUser && currentUser.token) {
      config.headers.Authorization = `Bearer ${currentUser.token}`;
    } else if (siteInfoToken) {
      config.headers.Authorization = `Bearer ${siteInfoToken}`;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  },
);

// Check for responses from server before proceeding
instance.interceptors.response.use(
  (response) => {
    // Return response data on success
    return response;
  },
  (error) => {
    const UNAUTHORIZED = 401;
    const { dispatch } = store;
    const state = store.getState();
    const { currentUser } = state.user;
    // Do something with response error
    if (error?.response?.status === UNAUTHORIZED && currentUser) {
      // Unauthorized; so log them out
      dispatch(signOutStart());
    }
    return Promise.reject(error);
  },
);

export default instance;

import { Box, Form, Select, Table, TableCell, Text, TextInput } from 'grommet';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { BrandButton } from '@ThemeMain';

const CellMenu = styled(TableCell)`
  padding-top: 2px !important;
`;

const SearchFilterFieldSelect = styled(Select)`
  width: 8em;
  font-size: inherit;
`;

const SearchFilterTable = styled(Table)`
  display: inline-block;
  /* cl 01ihjLA
  height: 20px;


  tr {
    td:nth-child(1) {
      padding-right: 0px;
    }
    td:nth-child(2) {
      max-width: 97px;
      input {
        height: 20px;
        width: 95px;
      }
    }
    td:nth-child(3) {
      button {
        width: 66px;
      }
    }
    td:nth-child(4) {
      button {
        width: 125px;
      }
    }
  }
  */
`;

const SearchInput = styled(TextInput)`
  /* 01ihjPf
  height: 30px;
  width: 145px;
  display: inline-box;
  */
`;

const SearchSortBox = styled(Box)`
  width: 100%;
  overflow: auto;
`;

const SearchSortBar = (props) => {
  const { className, options, searchValue, setSearchValue, searchKey, setSearchKey, filter, type } =
    props;
  const input = React.createRef();
  // TODO: [LH-284] searchValue should be cleared upon navigating to a different page/inventory
  // FIXME: [LH-283] searching for an empty string does not restore all items to the datatable

  // wrapping in a Form so that pressing "Enter" will trigger the submit button.
  useEffect(() => {
    type && type === 'reports' && input.current.focus();
  }, [searchValue]);
  return (
    <Form>
      <SearchSortBox
        direction="row"
        className={className}
        align="center"
        gap="small"
        style={{ padding: '0 0.5rem' }}
      >
        <Text className="middle-align">Find</Text>
        <SearchInput
          ref={input}
          className="all-h-30"
          placeholder=""
          value={searchValue}
          onChange={(event) => {
            setSearchValue(event.target.value);
          }}
        />
        <BrandButton type="submit" label="Search" onClick={() => filter()} />
        <SearchFilterFieldSelect
          className="all-h-30"
          labelKey="name"
          options={options}
          value={searchKey.name}
          onChange={({ option }) => setSearchKey(option)}
        />
        {props.children}
      </SearchSortBox>
    </Form>
  );
};

export default connect()(SearchSortBar);

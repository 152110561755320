import { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { NavBoxInner, NavBoxOuter } from '@Components/nav-box/nav-box.style';
import NavButton from '@Components/nav-button/nav-button.component';
import SelectCustomer from '@Components/select-customer/select-customer.component';
import { selectCurrentCustomer, selectPrivileges } from '@State/user/user.selectors';
import { Page } from '@ThemeMain';

const HomePage = ({ currentCustomer, userPrivileges }) => {
  const [showCustomer, setShowCustomer] = useState(false);

  const pdf = {
    documentUrl: 'http://cloudapex.linenhelper.net/Help/LinenHelperNetFlyer.pdf',
  };

  const showPdf = (type) => {
    window.open(pdf[type], '_blank');
  };

  return (
    <Page fill={false} alignContent="start" align="center">
      {userPrivileges && userPrivileges.canViewSelectCustomer && (
        <SelectCustomer
          show={showCustomer}
          currentCustomer={currentCustomer}
          setShow={setShowCustomer}
        />
      )}
      <NavBoxOuter>
        <NavBoxInner>
          {userPrivileges &&
            (userPrivileges.canViewReports ||
              userPrivileges.canViewSetCensus ||
              userPrivileges.canViewSetBudget) && (
              <NavButton label="Reports" url="/reports" icon="" disabled={!currentCustomer} />
            )}
          {userPrivileges && userPrivileges.canViewSetup && (
            <NavButton label="Setup" url="/setup" icon="" disabled={!currentCustomer} />
          )}
          {userPrivileges && userPrivileges.canViewCountInventory && (
            <NavButton label="Inventory" url="/inventory" icon="" disabled={!currentCustomer} />
          )}
          {userPrivileges &&
            (userPrivileges.canViewCountLinen || userPrivileges.canViewCountLinenLinenRoom) && (
              <NavButton
                label="Count Linen"
                url="/count-linen"
                icon=""
                disabled={!currentCustomer}
              />
            )}
          {userPrivileges && userPrivileges.canViewSelectCustomer && (
            <NavButton label="Select Customer" icon="" onSelect={setShowCustomer} />
          )}
          <NavButton label="Help" url="/help" icon="" />
        </NavBoxInner>
      </NavBoxOuter>
    </Page>
  );
};

const mapStateToProps = createStructuredSelector({
  currentCustomer: selectCurrentCustomer,
  userPrivileges: selectPrivileges,
});

export default connect(mapStateToProps, null)(HomePage);

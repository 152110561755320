import SetupUsersOrderNotSelectedActionTypes from './setup-users-order-not-selected-in-user.types';

export const getOrderNotSelectedStart = () => ({
  type: SetupUsersOrderNotSelectedActionTypes.GET_ORDER_NOT_SELECTED_START,
  payload: null,
});

export const getOrderNotSelectedSuccess = (orderSelected) => ({
  type: SetupUsersOrderNotSelectedActionTypes.GET_ORDER_NOT_SELECTED_SUCCESS,
  payload: orderSelected,
});

export const getOrderNotSelectedFailure = (error) => ({
  type: SetupUsersOrderNotSelectedActionTypes.GET_ORDER_NOT_SELECTED_FAILURE,
  payload: error,
});

export const updateOrderNotSelectedStart = (order) => ({
  type: SetupUsersOrderNotSelectedActionTypes.UPDATE_ORDER_NOT_SELECTED,
  payload: order,
});

export const formConfig = {
  Name: { label: 'Name', required: true, vEmptyMessage: 'Name is required.' },
  Pswd: { label: 'Password', required: true, vEmptyMessage: 'Password is required.' },
  EmalAddr: {
    label: 'Email Address',
    required: true,
    vEmptyMessage: 'Email Address is required.',
    vValueMessage: 'Invalid Email Address.',
  },
  UserDesc: { label: 'Description', required: true, vEmptyMessage: 'Description is required.' },
  HomePageName: { label: 'Description', required: true, vEmptyMessage: 'Home Map is required.' },
};

export const Links = {
  help: 'http://cloudapex.linenhelper.net/Help/SetupUsers.pdf',
};

export const dialogConfig = {
  titles: {
    warning: 'Warning',
    validationError: 'Validation Error',
  },
  messages: {
    noOrderWarning:
      'User must have access to at least 1 order.\n\nDo you want to save this user anyway?',
  },
};

export const setupUserSelections = {
  homePageName: ['Linen Tech', 'Linen Supervisor', 'Manager'],
};

export default formConfig;

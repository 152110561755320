const SetUpTypes = {
  /* #region PACKS */
  GET_PACKS_USED_FOR_CUSTOMER_START: 'GET_PACKS_USED_FOR_CUSTOMER_START',
  GET_PACKS_USED_FOR_CUSTOMER_SUCCESS: 'GET_PACKS_USED_FOR_CUSTOMER_SUCCESS',
  GET_PACKS_USED_FOR_CUSTOMER_FAILURE: 'GET_PACKS_USED_FOR_CUSTOMER_FAILURE',

  UPDATE_PACKS_USED_FOR_CUSTOMER_START: 'UPDATE_PACKS_USED_FOR_CUSTOMER_START',
  UPDATE_PACKS_USED_FOR_CUSTOMER_SUCCESS: 'UPDATE_PACKS_USED_FOR_CUSTOMER_SUCCESS',
  UPDATE_PACKS_USED_FOR_CUSTOMER_FAILURE: 'UPDATE_PACKS_USED_FOR_CUSTOMER_FAILURE',

  // PACKS-PRODUCTS
  GET_PRODUCTS_SELECTED_IN_PACK_START: 'GET_PRODUCTS_SELECTED_IN_PACK_START',
  GET_PRODUCTS_SELECTED_IN_PACK_SUCCESS: 'GET_PRODUCTS_SELECTED_IN_PACK_SUCCESS',
  GET_PRODUCTS_SELECTED_IN_PACK_FAILURE: 'GET_PRODUCTS_SELECTED_IN_PACK_FAILURE',

  GET_PRODUCTS_NOT_SELECTED_IN_PACK_START: 'GET_PRODUCTS_NOT_SELECTED_IN_PACK_START',
  GET_PRODUCTS_NOT_SELECTED_IN_PACK_SUCCESS: 'GET_PRODUCTS_NOT_SELECTED_IN_PACK_SUCCESS',
  GET_PRODUCTS_NOT_SELECTED_IN_PACK_FAILURE: 'GET_PRODUCTS_NOT_SELECTED_IN_PACK_FAILURE',

  ADD_PRODUCT_TO_PACK_START: 'ADD_PRODUCT_TO_PACK_START',
  ADD_PRODUCT_TO_PACK_SUCCESS: 'ADD_PRODUCT_TO_PACK_SUCCESS',
  ADD_PRODUCT_TO_PACK_FAILURE: 'ADD_PRODUCT_TO_PACK_FAILURE',

  UPDATE_PRODUCTS_SELECTED_AND_NOT_SELECTED_IN_PACK_START:
    'UPDATE_PRODUCTS_SELECTED_AND_NOT_SELECTED_IN_PACK_START',
  UPDATE_PRODUCTS_SELECTED_AND_NOT_SELECTED_IN_PACK_SUCCESS:
    'UPDATE_PRODUCTS_SELECTED_AND_NOT_SELECTED_IN_PACK_SUCCESS',
  UPDATE_PRODUCTS_SELECTED_AND_NOT_SELECTED_IN_PACK_FAILURE:
    'UPDATE_PRODUCTS_SELECTED_AND_NOT_SELECTED_IN_PACK_FAILURE',
  /* #endregion */

  /* #region PROCEDURES */
  GET_PROCEDURES_USED_FOR_CUSTOMER_START: 'GET_PROCEDURES_USED_FOR_CUSTOMER_START',
  GET_PROCEDURES_USED_FOR_CUSTOMER_SUCCESS: 'GET_PROCEDURES_USED_FOR_CUSTOMER_SUCCESS',
  GET_PROCEDURES_USED_FOR_CUSTOMER_FAILURE: 'GET_PROCEDURES_USED_FOR_CUSTOMER_FAILURE',

  UPDATE_PROCEDURES_USED_FOR_CUSTOMER_START: 'UPDATE_PROCEDURES_USED_FOR_CUSTOMER_START',
  UPDATE_PROCEDURES_USED_FOR_CUSTOMER_SUCCESS: 'UPDATE_PROCEDURES_USED_FOR_CUSTOMER_SUCCESS',
  UPDATE_PROCEDURES_USED_FOR_CUSTOMER_FAILURE: 'UPDATE_PROCEDURES_USED_FOR_CUSTOMER_FAILURE',

  // PROCEDURES-PRODUCTS
  GET_PRODUCTS_SELECTED_IN_PROCEDURE_START: 'GET_PRODUCTS_SELECTED_IN_PROCEDURE_START',
  GET_PRODUCTS_SELECTED_IN_PROCEDURE_SUCCESS: 'GET_PRODUCTS_SELECTED_IN_PROCEDURE_SUCCESS',
  GET_PRODUCTS_SELECTED_IN_PROCEDURE_FAILURE: 'GET_PRODUCTS_SELECTED_IN_PROCEDURE_FAILURE',

  GET_PRODUCTS_NOT_SELECTED_IN_PROCEDURE_START: 'GET_PRODUCTS_NOT_SELECTED_IN_PROCEDURE_START',
  GET_PRODUCTS_NOT_SELECTED_IN_PROCEDURE_SUCCESS: 'GET_PRODUCTS_NOT_SELECTED_IN_PROCEDURE_SUCCESS',
  GET_PRODUCTS_NOT_SELECTED_IN_PROCEDURE_FAILURE: 'GET_PRODUCTS_NOT_SELECTED_IN_PROCEDURE_FAILURE',

  ADD_PRODUCT_TO_PROCEDURE_START: 'ADD_PRODUCT_TO_PROCEDURE_START',
  ADD_PRODUCT_TO_PROCEDURE_SUCCESS: 'ADD_PRODUCT_TO_PROCEDURE_SUCCESS',
  ADD_PRODUCT_TO_PROCEDURE_FAILURE: 'ADD_PRODUCT_TO_PROCEDURE_FAILURE',

  UPDATE_PRODUCTS_SELECTED_AND_NOT_SELECTED_IN_PROCEDURE_START:
    'UPDATE_PRODUCTS_SELECTED_AND_NOT_SELECTED_IN_PROCEDURE_START',
  UPDATE_PRODUCTS_SELECTED_AND_NOT_SELECTED_IN_PROCEDURE_SUCCESS:
    'UPDATE_PRODUCTS_SELECTED_AND_NOT_SELECTED_IN_PROCEDURE_SUCCESS',
  UPDATE_PRODUCTS_SELECTED_AND_NOT_SELECTED_IN_PROCEDURE_FAILURE:
    'UPDATE_PRODUCTS_SELECTED_AND_NOT_SELECTED_IN_PROCEDURE_FAILURE',
  /* #endregion */

  /* #region ORDERS */
  GET_ORDERS_USED_FOR_CUSTOMER_START: 'GET_ORDERS_USED_FOR_CUSTOMER_START',
  GET_ORDERS_USED_FOR_CUSTOMER_SUCCESS: 'GET_ORDERS_USED_FOR_CUSTOMER_SUCCESS',
  GET_ORDERS_USED_FOR_CUSTOMER_FAILURE: 'GET_ORDERS_USED_FOR_CUSTOMER_FAILURE',

  UPDATE_ORDERS_USED_FOR_CUSTOMER_START: 'UPDATE_ORDERS_USED_FOR_CUSTOMER_START',
  UPDATE_ORDERS_USED_FOR_CUSTOMER_SUCCESS: 'UPDATE_ORDERS_USED_FOR_CUSTOMER_SUCCESS',
  UPDATE_ORDERS_USED_FOR_CUSTOMER_FAILURE: 'UPDATE_ORDERS_USED_FOR_CUSTOMER_FAILURE',

  IMPORT_ORDERS_START: 'IMPORT_ORDERS_START',
  IMPORT_ORDERS_SUCCESS: 'IMPORT_ORDERS_SUCCESS',
  IMPORT_ORDERS_FAILURE: 'IMPORT_ORDERS_FAILURE',

  //ORDERS-ITEMS
  GET_ITEMS_SELECTED_IN_ORDER_START: 'GET_ITEMS_SELECTED_IN_ORDER_START',
  GET_ITEMS_SELECTED_IN_ORDER_SUCCESS: 'GET_ITEMS_SELECTED_IN_ORDER_SUCCESS',
  GET_ITEMS_SELECTED_IN_ORDER_FAILURE: 'GET_ITEMS_SELECTED_IN_ORDER_FAILURE',

  GET_ITEMS_NOT_SELECTED_IN_ORDER_START: 'GET_ITEMS_NOT_SELECTED_IN_ORDER_START',
  GET_ITEMS_NOT_SELECTED_IN_ORDER_SUCCESS: 'GET_ITEMS_NOT_SELECTED_IN_ORDER_SUCCESS',
  GET_ITEMS_NOT_SELECTED_IN_ORDER_FAILURE: 'GET_ITEMS_NOT_SELECTED_IN_ORDER_FAILURE',

  ADD_ITEM_TO_ORDER_START: 'ADD_ITEM_TO_ORDER_START',
  ADD_ITEM_TO_ORDER_SUCCESS: 'ADD_ITEM_TO_ORDER_SUCCESS',
  ADD_ITEM_TO_ORDER_FAILURE: 'ADD_ITEM_TO_ORDER_FAILURE',

  UPDATE_ITEMS_SELECTED_AND_NOT_SELECTED_IN_ORDER_START:
    'UPDATE_ITEMS_SELECTED_AND_NOT_SELECTED_IN_ORDER_START',
  UPDATE_ITEMS_SELECTED_AND_NOT_SELECTED_IN_ORDER_SUCCESS:
    'UPDATE_ITEMS_SELECTED_AND_NOT_SELECTED_IN_ORDER_SUCCESS',
  UPDATE_ITEMS_SELECTED_AND_NOT_SELECTED_IN_ORDER_FAILURE:
    'UPDATE_ITEMS_SELECTED_AND_NOT_SELECTED_IN_ORDER_FAILURE',
  /* #endregion */

  /* #region CUSTOMER */
  GET_PAGE_OF_CUSTOMERS_START: 'GET_PAGE_OF_CUSTOMERS_START',
  GET_PAGE_OF_CUSTOMERS_SUCCESS: 'GET_PAGE_OF_CUSTOMERS_SUCCESS',
  GET_PAGE_OF_CUSTOMERS_FAILURE: 'GET_PAGE_OF_CUSTOMERS_FAILURE',

  UPDATE_CUSTOMERS_START: 'UPDATE_CUSTOMERS_START',
  UPDATE_CUSTOMERS_SUCCESS: 'UPDATE_CUSTOMERS_SUCCESS',
  UPDATE_CUSTOMERS_FAILURE: 'UPDATE_CUSTOMERS_FAILURE',

  GET_CUSTOMER_ACCOUNTS_START: 'GET_CUSTOMER_ACCOUNTS_START',
  GET_CUSTOMER_ACCOUNTS_SUCCESS: 'GET_CUSTOMER_ACCOUNTS_SUCCESS',
  GET_CUSTOMER_ACCOUNTS_FAILURE: 'GET_CUSTOMER_ACCOUNTS_FAILURE',
  /* #endregion */

  /* #region DEPARTMENT */
  GET_DEPARTMENTS_USED_FOR_CUSTOMER_START: 'GET_DEPARTMENTS_USED_FOR_CUSTOMER_START',
  GET_DEPARTMENTS_USED_FOR_CUSTOMER_SUCCESS: 'GET_DEPARTMENTS_USED_FOR_CUSTOMER_SUCCESS',
  GET_DEPARTMENTS_USED_FOR_CUSTOMER_FAILURE: 'GET_DEPARTMENTS_USED_FOR_CUSTOMER_FAILURE',

  GET_DEPARTMENTS_ACCT_TYPE_START: 'GET_DEPARTMENTS_ACCT_TYPE_START',
  GET_DEPARTMENTS_ACCT_TYPE_SUCCESS: 'GET_DEPARTMENTS_ACCT_TYPE_SUCCESS',
  GET_DEPARTMENTS_ACCT_TYPE_FAILURE: 'GET_DEPARTMENTS_ACCT_TYPE_FAILURE',

  UPDATE_DEPARTMENTS_USED_FOR_CUSTOMER_START: 'UPDATE_DEPARTMENTS_USED_FOR_CUSTOMER_START',
  UPDATE_DEPARTMENTS_USED_FOR_CUSTOMER_SUCCESS: 'UPDATE_DEPARTMENTS_USED_FOR_CUSTOMER_SUCCESS',
  UPDATE_DEPARTMENTS_USED_FOR_CUSTOMER_FAILURE: 'UPDATE_DEPARTMENTS_USED_FOR_CUSTOMER_FAILURE',
  /* #endregion */

  /* #region PRODUCTS */
  GET_PRODUCTS_USED_FOR_CUSTOMER_START: 'GET_PRODUCTS_START',
  GET_PRODUCTS_USED_FOR_CUSTOMER_SUCCESS: 'GET_PRODUCTS_SUCCESS',
  GET_PRODUCTS_USED_FOR_CUSTOMER_FAILURE: 'GET_PRODUCTS_FAILURE',

  UPDATE_PRODUCTS_USED_FOR_CUSTOMER_START: 'UPDATE_PRODUCTS_USED_FOR_CUSTOMER_START',
  UPDATE_PRODUCTS_USED_FOR_CUSTOMER_SUCCESS: 'UPDATE_PRODUCTS_USED_FOR_CUSTOMER_SUCCESS',
  UPDATE_PRODUCTS_USED_FOR_CUSTOMER_FAILURE: 'UPDATE_PRODUCTS_USED_FOR_CUSTOMER_FAILURE',
  IMPORT_PRODUCTS_START: 'IMPORT_PRODUCTS_START',
  IMPORT_PRODUCTS_SUCCESS: 'IMPORT_PRODUCTS_SUCCESS',
  IMPORT_PRODUCTS_FAILURE: 'IMPORT_PRODUCTS_FAILURE',
  /* #endregion */

  /* #region COMMON */
  GET_ITEM_ACCOUNTS_START: 'GET_ITEM_ACCOUNTS_START',
  GET_ITEM_ACCOUNTS_SUCCESS: 'GET_ITEM_ACCOUNTS_SUCCESS',
  GET_ITEM_ACCOUNTS_FAILURE: 'GET_ITEM_ACCOUNTS_FAILURE',

  GET_HANDHELD_NUMBERS_START: 'GET_HANDHELD_NUMBERS_START',
  GET_HANDHELD_NUMBERS_SUCCESS: 'GET_HANDHELD_NUMBERS_SUCCESS',
  GET_HANDHELD_NUMBERS_FAILURE: 'GET_HANDHELD_NUMBERS_FAILURE',

  GET_INVENTORY_FLOW_START: 'GET_INVENTORY_FLOW_START',
  GET_INVENTORY_FLOW_SUCCESS: 'GET_INVENTORY_FLOW_SUCCESS',
  GET_INVENTORY_FLOW_FAILURE: 'GET_INVENTORY_FLOW_FAILURE',

  GET_ORDER_TYPES_START: 'GET_ORDER_TYPES_START',
  GET_ORDER_TYPES_SUCCESS: 'GET_ORDER_TYPES_SUCCESS',
  GET_ORDER_TYPES_FAILURE: 'GET_ORDER_TYPES_FAILURE',

  VALIDATE_DELETION_START: 'VALIDATE_DELETION_START',
  VALIDATE_DELETION_SUCCESS: 'VALIDATE_DELETION_SUCCESS',
  VALIDATE_DELETION_FAILURE: 'VALIDATE_DELETION_FAILURE',

  INACTIVATE_START: 'INACTIVATE_START',
  INACTIVATE_SUCCESS: 'INACTIVATE_SUCCESS',
  INACTIVATE_FAILURE: 'INACTIVATE_FAILURE',

  GET_SNAPSHOT_START: 'GET_SNAPSHOT_START',
  GET_SNAPSHOT_SUCCESS: 'GET_SNAPSHOT_SUCCESS',
  GET_SNAPSHOT_FAILURE: 'GET_SNAPSHOT_FAILURE',

  MOVE_TO_ROW_START: 'MOVE_TO_ROW_START',
  MOVE_TO_ROW_SUCCESS: 'MOVE_TO_ROW_SUCCESS',
  MOVE_TO_ROW_FAILURE: 'MOVE_TO_ROW_FAILURE',
  /* #endregion */
};

export default SetUpTypes;

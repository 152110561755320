import SetUpTypes from './setup.types';

/* #region PACKS */
export const getPacksUsedForCustomerStart = (data) => ({
  type: SetUpTypes.GET_PACKS_USED_FOR_CUSTOMER_START,
  payload: data,
});

export const getPacksUsedForCustomerSuccess = (data) => ({
  type: SetUpTypes.GET_PACKS_USED_FOR_CUSTOMER_SUCCESS,
  payload: data,
});

export const getPacksUsedForCustomerFailure = (error) => ({
  type: SetUpTypes.GET_PACKS_USED_FOR_CUSTOMER_FAILURE,
  payload: error,
});

export const getProductsSelectedInPackStart = (data) => ({
  type: SetUpTypes.GET_PRODUCTS_SELECTED_IN_PACK_START,
  payload: data,
});

export const getProductsSelectedInPackSuccess = (data) => ({
  type: SetUpTypes.GET_PRODUCTS_SELECTED_IN_PACK_SUCCESS,
  payload: data,
});

export const getProductsSelectedInPackFailure = (error) => ({
  type: SetUpTypes.GET_PRODUCTS_SELECTED_IN_PACK_FAILURE,
  payload: error,
});

export const getProductsNotSelectedInPackStart = (data) => ({
  type: SetUpTypes.GET_PRODUCTS_NOT_SELECTED_IN_PACK_START,
  payload: data,
});

export const getProductsNotSelectedInPackSuccess = (data) => ({
  type: SetUpTypes.GET_PRODUCTS_NOT_SELECTED_IN_PACK_SUCCESS,
  payload: data,
});

export const getProductsNotSelectedInPackFailure = (error) => ({
  type: SetUpTypes.GET_PRODUCTS_NOT_SELECTED_IN_PACK_FAILURE,
  payload: error,
});

export const updatePacksUsedForCustomerStart = (data) => ({
  type: SetUpTypes.UPDATE_PACKS_USED_FOR_CUSTOMER_START,
  payload: data,
});

export const updatePacksUsedForCustomerSuccess = (data) => ({
  type: SetUpTypes.UPDATE_PACKS_USED_FOR_CUSTOMER_SUCCESS,
  payload: data,
});

export const updatePacksUsedForCustomerFailure = (error) => ({
  type: SetUpTypes.UPDATE_PACKS_USED_FOR_CUSTOMER_FAILURE,
  payload: error,
});

export const updateProductsSelectedAndNotSelectedInPackStart = (data) => ({
  type: SetUpTypes.UPDATE_PRODUCTS_SELECTED_AND_NOT_SELECTED_IN_PACK_START,
  payload: data,
});

export const updateProductsSelectedAndNotSelectedInPackSuccess = (data) => ({
  type: SetUpTypes.UPDATE_PRODUCTS_SELECTED_AND_NOT_SELECTED_IN_PACK_SUCCESS,
  payload: data,
});

export const updateProductsSelectedAndNotSelectedInPackFailure = (error) => ({
  type: SetUpTypes.UPDATE_PRODUCTS_SELECTED_AND_NOT_SELECTED_IN_PACK_FAILURE,
  payload: error,
});

/** ADD PRODUCT TO PACK * */
export const addProductToPackStart = (data) => ({
  type: SetUpTypes.ADD_PRODUCT_TO_PACK_START,
  payload: data,
});

export const addProductToPackSuccess = (data) => ({
  type: SetUpTypes.ADD_PRODUCT_TO_PACK_SUCCESS,
  payload: data,
});

export const addProductToPackFailure = (error) => ({
  type: SetUpTypes.ADD_PRODUCT_TO_PACK_FAILURE,
  payload: error,
});
/* #endregion */

/* #region PROCEDURES */
export const getProceduresUsedForCustomerStart = (data) => ({
  type: SetUpTypes.GET_PROCEDURES_USED_FOR_CUSTOMER_START,
  payload: data,
});

export const getProceduresUsedForCustomerSuccess = (data) => ({
  type: SetUpTypes.GET_PROCEDURES_USED_FOR_CUSTOMER_SUCCESS,
  payload: data,
});

export const getProceduresUsedForCustomerFailure = (error) => ({
  type: SetUpTypes.GET_PROCEDURES_USED_FOR_CUSTOMER_FAILURE,
  payload: error,
});

export const getProductsSelectedInProcedureStart = (data) => ({
  type: SetUpTypes.GET_PRODUCTS_SELECTED_IN_PROCEDURE_START,
  payload: data,
});

export const getProductsSelectedInProcedureSuccess = (data) => ({
  type: SetUpTypes.GET_PRODUCTS_SELECTED_IN_PROCEDURE_SUCCESS,
  payload: data,
});

export const getProductsSelectedInProcedureFailure = (error) => ({
  type: SetUpTypes.GET_PRODUCTS_SELECTED_IN_PROCEDURE_FAILURE,
  payload: error,
});

export const getProductsNotSelectedInProcedureStart = (data) => ({
  type: SetUpTypes.GET_PRODUCTS_NOT_SELECTED_IN_PROCEDURE_START,
  payload: data,
});

export const getProductsNotSelectedInProcedureSuccess = (data) => ({
  type: SetUpTypes.GET_PRODUCTS_NOT_SELECTED_IN_PROCEDURE_SUCCESS,
  payload: data,
});

export const getProductsNotSelectedInProcedureFailure = (error) => ({
  type: SetUpTypes.GET_PRODUCTS_NOT_SELECTED_IN_PROCEDURE_FAILURE,
  payload: error,
});

export const updateProceduresUsedForCustomerStart = (data) => ({
  type: SetUpTypes.UPDATE_PROCEDURES_USED_FOR_CUSTOMER_START,
  payload: data,
});

export const updateProceduresUsedForCustomerSuccess = (data) => ({
  type: SetUpTypes.UPDATE_PROCEDURES_USED_FOR_CUSTOMER_SUCCESS,
  payload: data,
});

export const updateProceduresUsedForCustomerFailure = (error) => ({
  type: SetUpTypes.UPDATE_PROCEDURES_USED_FOR_CUSTOMER_FAILURE,
  payload: error,
});

export const updateProductsSelectedAndNotSelectedInProcedureStart = (data) => ({
  type: SetUpTypes.UPDATE_PRODUCTS_SELECTED_AND_NOT_SELECTED_IN_PROCEDURE_START,
  payload: data,
});

export const updateProductsSelectedAndNotSelectedInProcedureSuccess = (data) => ({
  type: SetUpTypes.UPDATE_PRODUCTS_SELECTED_AND_NOT_SELECTED_IN_PROCEDURE_SUCCESS,
  payload: data,
});

export const updateProductsSelectedAndNotSelectedInProcedureFailure = (error) => ({
  type: SetUpTypes.UPDATE_PRODUCTS_SELECTED_AND_NOT_SELECTED_IN_PROCEDURE_FAILURE,
  payload: error,
});

/** ADD PRODUCT TO PROCEDURE * */
export const addProductToProcedureStart = (data) => ({
  type: SetUpTypes.ADD_PRODUCT_TO_PROCEDURE_START,
  payload: data,
});

export const addProductToProcedureSuccess = (data) => ({
  type: SetUpTypes.ADD_PRODUCT_TO_PROCEDURE_SUCCESS,
  payload: data,
});

export const addProductToProcedureFailure = (error) => ({
  type: SetUpTypes.ADD_PRODUCT_TO_PROCEDURE_FAILURE,
  payload: error,
});
/* #endregion */

/* #region ORDER */
export const getOrdersUsedForCustomerStart = (data) => ({
  type: SetUpTypes.GET_ORDERS_USED_FOR_CUSTOMER_START,
  payload: data,
});

export const getOrdersUsedForCustomerSuccess = (data) => ({
  type: SetUpTypes.GET_ORDERS_USED_FOR_CUSTOMER_SUCCESS,
  payload: data,
});

export const getOrdersUsedForCustomerFailure = (error) => ({
  type: SetUpTypes.GET_ORDERS_USED_FOR_CUSTOMER_FAILURE,
  payload: error,
});

export const getItemsSelectedInOrderStart = (data) => ({
  type: SetUpTypes.GET_ITEMS_SELECTED_IN_ORDER_START,
  payload: data,
});

export const getItemsSelectedInOrderSuccess = (data) => ({
  type: SetUpTypes.GET_ITEMS_SELECTED_IN_ORDER_SUCCESS,
  payload: data,
});

export const getItemsSelectedInOrderFailure = (error) => ({
  type: SetUpTypes.GET_ITEMS_SELECTED_IN_ORDER_FAILURE,
  payload: error,
});

export const getItemsNotSelectedInOrderStart = (data) => ({
  type: SetUpTypes.GET_ITEMS_NOT_SELECTED_IN_ORDER_START,
  payload: data,
});

export const getItemsNotSelectedInOrderSuccess = (data) => ({
  type: SetUpTypes.GET_ITEMS_NOT_SELECTED_IN_ORDER_SUCCESS,
  payload: data,
});

export const getItemsNotSelectedInOrderFailure = (error) => ({
  type: SetUpTypes.GET_ITEMS_NOT_SELECTED_IN_ORDER_FAILURE,
  payload: error,
});

export const updateOrdersUsedForCustomerStart = (data) => ({
  type: SetUpTypes.UPDATE_ORDERS_USED_FOR_CUSTOMER_START,
  payload: data,
});

export const updateOrdersUsedForCustomerSuccess = (data) => ({
  type: SetUpTypes.UPDATE_ORDERS_USED_FOR_CUSTOMER_SUCCESS,
  payload: data,
});

export const updateOrdersUsedForCustomerFailure = (error) => ({
  type: SetUpTypes.UPDATE_ORDERS_USED_FOR_CUSTOMER_FAILURE,
  payload: error,
});

export const updateItemsSelectedAndNotSelectedInOrderStart = (data) => ({
  type: SetUpTypes.UPDATE_ITEMS_SELECTED_AND_NOT_SELECTED_IN_ORDER_START,
  payload: data,
});

export const updateItemsSelectedAndNotSelectedInOrderSuccess = (data) => ({
  type: SetUpTypes.UPDATE_ITEMS_SELECTED_AND_NOT_SELECTED_IN_ORDER_SUCCESS,
  payload: data,
});

export const updateItemsSelectedAndNotSelectedInOrderFailure = (error) => ({
  type: SetUpTypes.UPDATE_ITEMS_SELECTED_AND_NOT_SELECTED_IN_ORDER_FAILURE,
  payload: error,
});

export const importOrdersStart = form => ({
  type: SetUpTypes.IMPORT_ORDERS_START,
  payload: form
})

export const importOrdersSuccess = (data) => ({
  type: SetUpTypes.IMPORT_ORDERS_SUCCESS,
  payload: data
})

export const importOrdersFailure = error => ({
  type: SetUpTypes.IMPORT_ORDERS_FAILURE,
  payload: error
})

/** ADD PRODUCT TO ORDER * */
export const addItemToOrderStart = (data) => ({
  type: SetUpTypes.ADD_ITEM_TO_ORDER_START,
  payload: data,
});

export const addItemToOrderSuccess = (data) => ({
  type: SetUpTypes.ADD_ITEM_TO_ORDER_SUCCESS,
  payload: data,
});

export const addItemToOrderFailure = (error) => ({
  type: SetUpTypes.ADD_ITEM_TO_ORDER_FAILURE,
  payload: error,
});
/* #endregion */

/* #region CUSTOMERS */
export const getPageOfCustomersStart = (data) => ({
  type: SetUpTypes.GET_PAGE_OF_CUSTOMERS_START,
  payload: data,
});

export const getPageOfCustomersSuccess = (data) => ({
  type: SetUpTypes.GET_PAGE_OF_CUSTOMERS_SUCCESS,
  payload: data,
});

export const getPageOfCustomersFailure = (error) => ({
  type: SetUpTypes.GET_PAGE_OF_CUSTOMERS_FAILURE,
  payload: error,
});

export const updateCustomersStart = (data) => ({
  type: SetUpTypes.UPDATE_CUSTOMERS_START,
  payload: data,
});

export const updateCustomersSuccess = (data) => ({
  type: SetUpTypes.UPDATE_CUSTOMERS_SUCCESS,
  payload: data,
});

export const updateCustomersFailure = (error) => ({
  type: SetUpTypes.UPDATE_CUSTOMERS_FAILURE,
  payload: error,
});

export const getCustomerAccountsStart = (data) => ({
  type: SetUpTypes.GET_CUSTOMER_ACCOUNTS_START,
  payload: data,
});

export const getCustomerAccountsSuccess = (data) => ({
  type: SetUpTypes.GET_CUSTOMER_ACCOUNTS_SUCCESS,
  payload: data,
});

export const getCustomerAccountsFailure = (error) => ({
  type: SetUpTypes.GET_CUSTOMER_ACCOUNTS_FAILURE,
  payload: error,
});
/* #endregion */

/* #region DEPARTMENTS */
export const getDepartmentsUsedForCustomerStart = (data) => ({
  type: SetUpTypes.GET_DEPARTMENTS_USED_FOR_CUSTOMER_START,
  payload: data,
});

export const getDepartmentsUsedForCustomerSuccess = (data) => ({
  type: SetUpTypes.GET_DEPARTMENTS_USED_FOR_CUSTOMER_SUCCESS,
  payload: data,
});

export const getDepartmentsUsedForCustomerFailure = (error) => ({
  type: SetUpTypes.GET_DEPARTMENTS_USED_FOR_CUSTOMER_FAILURE,
  payload: error,
});

export const updateDepartmentsUsedForCustomerStart = (data) => ({
  type: SetUpTypes.UPDATE_DEPARTMENTS_USED_FOR_CUSTOMER_START,
  payload: data,
});

export const updateDepartmentsUsedForCustomerSuccess = (data) => ({
  type: SetUpTypes.UPDATE_DEPARTMENTS_USED_FOR_CUSTOMER_SUCCESS,
  payload: data,
});

export const updateDepartmentsUsedForCustomerFailure = (error) => ({
  type: SetUpTypes.UPDATE_DEPARTMENTS_USED_FOR_CUSTOMER_FAILURE,
  payload: error,
});

export const getDepartmentsAcctTypeStart = () => ({
  type: SetUpTypes.GET_DEPARTMENTS_ACCT_TYPE_START,
  payload: null,
});

export const getDepartmentsAcctTypeSuccess = (accountTypes) => ({
  type: SetUpTypes.GET_DEPARTMENTS_ACCT_TYPE_SUCCESS,
  payload: accountTypes,
});

export const getDepartmentsAcctTypeFailure = (error) => ({
  type: SetUpTypes.GET_DEPARTMENTS_ACCT_TYPE_FAILURE,
  payload: error,
});
/* #endregion */

/* #region PRODUCTS */
export const getProductsUsedForCustomerStart = (data) => ({
  type: SetUpTypes.GET_PRODUCTS_USED_FOR_CUSTOMER_START,
  payload: data,
});

export const getProductsUsedForCustomerSuccess = (products) => ({
  type: SetUpTypes.GET_PRODUCTS_USED_FOR_CUSTOMER_SUCCESS,
  payload: products,
});

export const getProductsUsedForCustomerFailure = (error) => ({
  type: SetUpTypes.GET_PRODUCTS_USED_FOR_CUSTOMER_FAILURE,
  payload: error,
});

export const updateProductsUsedForCustomerStart = (product) => ({
  type: SetUpTypes.UPDATE_PRODUCTS_USED_FOR_CUSTOMER_START,
  payload: product,
});

export const updateProductsUsedForCustomerSuccess = (data) => ({
  type: SetUpTypes.UPDATE_PRODUCTS_USED_FOR_CUSTOMER_SUCCESS,
  payload: data,
});

export const updateProductsUsedForCustomerFailure = (error) => ({
  type: SetUpTypes.UPDATE_PRODUCTS_USED_FOR_CUSTOMER_FAILURE,
  payload: error,
});

export const importProductsStart = form => ({
  type: SetUpTypes.IMPORT_PRODUCTS_START,
  payload: form
})

export const importProductsSuccess = (data) => ({
  type: SetUpTypes.IMPORT_PRODUCTS_SUCCESS,
  payload: data
})

export const importProductsFailure = error => ({
  type: SetUpTypes.IMPORT_PRODUCTS_FAILURE,
  payload: error
})

/* #endregion */

/* #region COMMON */
export const getItemAccountsStart = (data) => ({
  type: SetUpTypes.GET_ITEM_ACCOUNTS_START,
  payload: data,
});

export const getItemAccountsSuccess = (data) => ({
  type: SetUpTypes.GET_ITEM_ACCOUNTS_SUCCESS,
  payload: data,
});

export const getItemAccountsFailure = (error) => ({
  type: SetUpTypes.GET_ITEM_ACCOUNTS_FAILURE,
  payload: error,
});

// HANDHELD NUMBER
export const getHandheldNumbersStart = (data) => ({
  type: SetUpTypes.GET_HANDHELD_NUMBERS_START,
  payload: data,
});

export const getHandheldNumbersSuccess = (data) => ({
  type: SetUpTypes.GET_HANDHELD_NUMBERS_SUCCESS,
  payload: data,
});

export const getHandheldNumbersFailure = (error) => ({
  type: SetUpTypes.GET_HANDHELD_NUMBERS_FAILURE,
  payload: error,
});

// INVENTORY FLOW
export const getInventoryFlowStart = (data) => ({
  type: SetUpTypes.GET_INVENTORY_FLOW_START,
  payload: data,
});

export const getInventoryFlowSuccess = (data) => ({
  type: SetUpTypes.GET_INVENTORY_FLOW_SUCCESS,
  payload: data,
});

export const getInventoryFlowFailure = (error) => ({
  type: SetUpTypes.GET_INVENTORY_FLOW_FAILURE,
  payload: error,
});

// ORDER TYPES
export const getOrderTypesStart = (data) => ({
  type: SetUpTypes.GET_ORDER_TYPES_START,
  payload: data,
});

export const getOrderTypesSuccess = (data) => ({
  type: SetUpTypes.GET_ORDER_TYPES_SUCCESS,
  payload: data,
});

export const getOrderTypesFailure = (error) => ({
  type: SetUpTypes.GET_ORDER_TYPES_FAILURE,
  payload: error,
});

export const validateDeletionStart = (data) => ({
  type: SetUpTypes.VALIDATE_DELETION_START,
  payload: data,
});

export const validateDeletionSuccess = (data) => ({
  type: SetUpTypes.VALIDATE_DELETION_SUCCESS,
  payload: data,
});

export const validateDeletionFailure = (error) => ({
  type: SetUpTypes.VALIDATE_DELETION_FAILURE,
  payload: error,
});

export const inactivateStart = (data) => ({
  type: SetUpTypes.INACTIVATE_START,
  payload: data,
});

export const inactivateSuccess = (data) => ({
  type: SetUpTypes.INACTIVATE_SUCCESS,
  payload: data,
});

export const inactivateFailure = (error) => ({
  type: SetUpTypes.INACTIVATE_FAILURE,
  payload: error,
});

export const getSnapshotStart = (data) => ({
  type: SetUpTypes.GET_SNAPSHOT_START,
  payload: data,
});

export const getSnapshotSuccess = (data) => ({
  type: SetUpTypes.GET_SNAPSHOT_SUCCESS,
  payload: data,
});

export const getSnapshotFailure = (error) => ({
  type: SetUpTypes.GET_SNAPSHOT_FAILURE,
  payload: error,
});

export const moveToRowStart = (data) => ({
  type: SetUpTypes.MOVE_TO_ROW_START,
  payload: data,
});

export const moveToRowSuccess = (data) => ({
  type: SetUpTypes.MOVE_TO_ROW_SUCCESS,
  payload: data,
});

export const moveToRowFailure = (error) => ({
  type: SetUpTypes.MOVE_TO_ROW_FAILURE,
  payload: error,
});
/* #endregion */

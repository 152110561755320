const InventoryTypes = {
  CREATE_INJECTION_FOR_CUSTOMER_ON_DATE_START: 'CREATE_INJECTION_FOR_CUSTOMER_ON_DATE_START',
  CREATE_INJECTION_FOR_CUSTOMER_ON_DATE_SUCCESS: 'CREATE_INJECTION_FOR_CUSTOMER_ON_DATE_SUCCESS',
  CREATE_INJECTION_FOR_CUSTOMER_ON_DATE_FAILURE: 'CREATE_INJECTION_FOR_CUSTOMER_ON_DATE_FAILURE',

  CREATE_RETIREMENT_FOR_CUSTOMER_ON_DATE_START: 'CREATE_RETIREMENT_FOR_CUSTOMER_ON_DATE_START',
  CREATE_RETIREMENT_FOR_CUSTOMER_ON_DATE_SUCCESS: 'CREATE_RETIREMENT_FOR_CUSTOMER_ON_DATE_SUCCESS',
  CREATE_RETIREMENT_FOR_CUSTOMER_ON_DATE_FAILURE: 'CREATE_RETIREMENT_FOR_CUSTOMER_ON_DATE_FAILURE',

  // COMMON ON ALL INVENTORY MODULES
  GET_INVENTORY_FOR_INVENTORY_ID_START: 'GET_INVENTORY_FOR_INVENTORY_ID_START',
  GET_INVENTORY_FOR_INVENTORY_ID_SUCCESS: 'GET_INVENTORY_FOR_INVENTORY_ID_SUCCESS',
  GET_INVENTORY_FOR_INVENTORY_ID_FAILURE: 'GET_INVENTORY_FOR_INVENTORY_ID_FAILURE',

  GET_ITEMS_NOT_IN_INVENTORY_START: 'GET_ITEMS_NOT_IN_INVENTORY_START',
  GET_ITEMS_NOT_IN_INVENTORY_SUCCESS: 'GET_ITEMS_NOT_IN_INVENTORY_SUCCESS',
  GET_ITEMS_NOT_IN_INVENTORY_FAILURE: 'GET_ITEMS_NOT_IN_INVENTORY_FAILURE',

  GET_ORDER_SCHEDULE_FOR_CUSTOMER_ON_DATE_START: 'GET_ORDER_SCHEDULE_FOR_CUSTOMER_ON_DATE_START',
  GET_ORDER_SCHEDULE_FOR_CUSTOMER_ON_DATE_SUCCESS:
    'GET_ORDER_SCHEDULE_FOR_CUSTOMER_ON_DATE_SUCCESS',
  GET_ORDER_SCHEDULE_FOR_CUSTOMER_ON_DATE_FAILURE:
    'GET_ORDER_SCHEDULE_FOR_CUSTOMER_ON_DATE_FAILURE',

  GET_INVENTORY_FORM_PDF_START: 'GET_INVENTORY_FORM_PDF_START',
  GET_INVENTORY_FORM_PDF_SUCCESS: 'GET_INVENTORY_FORM_PDF_SUCCESS',
  GET_INVENTORY_FORM_PDF_FAILURE: 'GET_INVENTORY_FORM_PDF_FAILURE',

  UPDATE_INVENTORY_COUNTS_START: 'UPDATE_INVENTORY_COUNTS_START',
  UPDATE_INVENTORY_COUNTS_SUCCESS: 'UPDATE_INVENTORY_COUNTS_SUCCESS',
  UPDATE_INVENTORY_COUNTS_FAILURE: 'UPDATE_INVENTORY_COUNTS_FAILURE',

  ADD_ITEM_TO_INVENTORY_START: 'ADD_ITEM_TO_INVENTORY_START',
  ADD_ITEM_TO_INVENTORY_SUCCESS: 'ADD_ITEM_TO_INVENTORY_SUCCESS',
  ADD_ITEM_TO_INVENTORY_FAILURE: 'ADD_ITEM_TO_INVENTORY_FAILURE',

  CREATE_INVENTORY_FOR_ORDER_ON_DATE_START: 'CREATE_INVENTORY_FOR_ORDER_ON_DATE_START',
  CREATE_INVENTORY_FOR_ORDER_ON_DATE_SUCCESS: 'CREATE_INVENTORY_FOR_ORDER_ON_DATE_SUCCESS',
  CREATE_INVENTORY_FOR_ORDER_ON_DATE_FAILURE: 'CREATE_INVENTORY_FOR_ORDER_ON_DATE_FAILURE',

  SET_SELECTED_RCID: 'SET_SELECTED_RCID',
};

export default InventoryTypes;

export const InventoryUrl = {
  main: '/inventory',
  retirement: '/inventory/retirement/',
  injection: '/inventory/injection/',
  physicalInventory: '/inventory/physical-inventory/',
};

export const InventoryPageID = {
  main: 'main',
  retirement: 'retirement',
  injection: 'injection',
  physicalInventory: 'physical-inventory',
};

// TODO: env variable should be used instead
export const InventoryHelpUrls = {
  [InventoryPageID.retirement]: '/Inventory/InventoryRetirement.pdf',
  [InventoryPageID.injection]: '/Inventory/InventoryInjection.pdf',
  [InventoryPageID.physicalInventory]: '/Inventory/PhysicalInventory.pdf',
};

export const InventoryNav = {
  mainNav: [
    {
      label: 'Retirement',
      url: InventoryUrl.retirement,
      permission: 'canViewCountInventory',
    },
    {
      label: 'Injection',
      url: InventoryUrl.injection,
      permission: 'canViewCountInventory',
    },
    {
      label: 'Physical Inventory',
      url: InventoryUrl.physicalInventory,
      permission: 'canViewCountInventory',
    },
  ],
};

export const dateFormat = {
  default: 'MM/DD/YYYY',
  param: 'YYYY-MM-DDT12:00:00',
};

export const tableConfig = {
  [InventoryPageID.injection]: {
    searchKeys: [
      { id: 'ItemDesc', name: 'Product Name' },
      { id: 'TotlNmbr', name: 'Total' },
    ],
    columns: {
      ItemDesc: { type: 'text', label: 'Product Name' },
      TotlNmbr: { type: 'textInput', label: 'Total' },
    },
  },
  [InventoryPageID.retirement]: {
    searchKeys: [
      { id: 'ItemDesc', name: 'Product Name' },
      { id: 'TotlNmbr', name: 'Total' },
    ],
    columns: {
      ItemDesc: { type: 'text', label: 'Product Name' },
      TotlNmbr: { type: 'textInput', label: 'Total' },
    },
  },
  [InventoryPageID.physicalInventory]: {
    searchKeys: [{ id: 'OrdrDesc', name: 'Order Name' }],
    searchKeysRight: [
      { id: 'ItemDesc', name: 'Product Name' },
      { id: 'TotlNmbr', name: 'Total' },
    ],
    columns: {
      OrdrDesc: { type: 'text', label: 'Order Name' },
    },
    colmnsRight: {
      ItemDesc: { type: 'text', label: 'Product Name' },
      TotlNmbr: { type: 'textInput', label: 'Total' },
    },
  },
};

export const SetUpMessage = {
  unSaveChanges: 'You have some unsaved changes. Are you sure you want to leave?',
};

export default InventoryUrl;

import { Box, Button, DataTable, Grid, Heading } from 'grommet';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import SetupUserButton from '@Components/setup-users-buttons/setup-users-buttons.component';
import DeleteModal from '@Components/setup-users-delete-modal/setup-users-delete-modal.component';
import SetupUsersOrdersDetailsTab from '@Components/setup-users-orders-details-tab/setup-users-orders-details-tab.component';
import { getDetailsForOrderSelectedStart } from '@State/setup-users-details-for-order-selected-in-user/setup-users-details-for-order-selected-in-user.actions';
import {
  pushOrderToDeleteQueueStart,
  setUserCurrentOrder,
} from '@State/setup-users-order-selected-in-user/setup-users-order-selected-in-user.actions';
import {
  selectCurrentOrdersSelected,
  selectOrdersSelected,
} from '@State/setup-users-order-selected-in-user/setup-users-order-selected-in-user.selectors';
import { selectCurrentUser } from '@State/user/user.selectors';

const SetupUsersOrdersTab = (props) => {
  useEffect(() => {
    getDetailsForOrder();
  }, []);
  const columns = [
    {
      property: 'ObjDesc',
      header: 'Description',
      primary: true,
    },
  ];
  const orderCheckboxes = [
    {
      name: 'Par Quantities',
      boxes: [
        { key: 'StckView', label: 'View Par Quantities' },
        { key: 'StckMdfy', label: 'Modify Par Quantities' },
      ],
    },
    {
      name: 'Hand Quantities',
      boxes: [
        { key: 'OhndView', label: 'View On Hand Quantities' },
        { key: 'OhndMdfy', label: 'Modify On Hand Quantities' },
      ],
    },
    {
      name: 'Required Quantities',
      boxes: [
        { key: 'RqrdView', label: 'View Required Quantities' },
        { key: 'RqrdMdfy', label: 'Modify Required Quantities' },
      ],
    },
    {
      name: 'Stocked Quantities',
      boxes: [
        { key: 'LdryView', label: 'View Stocked Quantities' },
        { key: 'LdryMdfy', label: 'Modify Stocked Quantities' },
      ],
    },
    {
      name: 'Emergency Quantities',
      boxes: [
        { key: 'LnrmView', label: 'View Emergency Quantities' },
        { key: 'LnrmMdfy', label: 'Modify Emergency Quantities' },
      ],
    },
    {
      name: 'Returned Quantities',
      boxes: [
        { key: 'RetnView', label: 'View Returned Quantities' },
        { key: 'RetnMdfy', label: 'Modify Returned Quantities' },
      ],
    },
  ];
  const {
    orderSelected,
    setCurrentOrder,
    currentOrderSelected,
    getDetailsForOrder,
    timeSelection,
    evaluateChange,
    pushOrderToDeleteQueue,
  } = props;
  const [showAddOrderButton, setShowAddOrderButton] = useState(false);
  const [showDeleteOrder, setShowDeleteOrder] = useState(false);

  return (
    <Grid
      columns={['1/4', '3/4']}
      rows={['flex']}
      gap="small"
      areas={[
        { name: 'nav', start: [0, 0], end: [1, 0] },
        { name: 'main', start: [1, 0], end: [1, 0] },
      ]}
      fill
    >
      {showDeleteOrder ? (
        <DeleteModal
          type="Order"
          data={orderSelected}
          confirm={(data) => pushOrderToDeleteQueue(data)}
          evaluateChange={evaluateChange}
          onClose={setShowDeleteOrder}
        />
      ) : null}
      {showAddOrderButton ? (
        <SetupUserButton
          title="Add Order"
          evaluateChange={evaluateChange}
          onClose={setShowAddOrderButton}
        />
      ) : null}
      <Box pad="small" gridArea="nav">
        <Heading margin="0" pad="medium" level={5}>
          Orders
        </Heading>
        <Box alignSelf="start">
          <Button
            label="Add Order"
            onClick={() => {
              setShowAddOrderButton(true);
            }}
          />
          <Button
            label="Delete"
            disabled={!currentOrderSelected}
            onClick={() => {
              setShowDeleteOrder(true);
            }}
          />

          {orderSelected ? (
            <DataTable
              primaryKey="ObjRcID"
              columns={columns}
              data={orderSelected}
              onClickRow={(event) => {
                if (event.datum.UserRcID) {
                  setCurrentOrder(event.datum);
                  getDetailsForOrder();
                }
              }}
            />
          ) : (
            <DataTable primarykey="ObjRcID" columns={columns} data={[]} />
          )}
        </Box>
      </Box>
      <Box gridArea="main" pad="small">
        {currentOrderSelected ? (
          <SetupUsersOrdersDetailsTab
            data={orderCheckboxes}
            timeSelection={timeSelection}
            evaluateChange={evaluateChange}
          />
        ) : null}
      </Box>
    </Grid>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  orderSelected: selectOrdersSelected,
  currentOrderSelected: selectCurrentOrdersSelected,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentOrder: (order) => dispatch(setUserCurrentOrder(order)),
  getDetailsForOrder: () => dispatch(getDetailsForOrderSelectedStart()),
  pushOrderToDeleteQueue: (order) => dispatch(pushOrderToDeleteQueueStart(order)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SetupUsersOrdersTab));

import { Box } from 'grommet';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import NavBox from '@Components/nav-box/nav-box.component';
import Printer from '@Components/pdf-generator/pdf-generator.component';
import { CircularProgress } from '@material-ui/core';
import {
  emailTransactionsStart,
  getMonthlyBudgetCensusUsedForCustomerStart,
  getReportFilterOptionsStart,
  getReportSelectionTreeStart,
  getReportSelectionTreeUpdateStart,
  getReportSnapshotStart,
  getReportTransactionsStart,
  getReportTransactionsSuccess,
  setPageID,
  setReportTypeStart,
  updateMonthlyBudgetCensusUsedForCustomerStart,
} from '@State/reports/reports.actions';
// Generic Reports
import {
  selectEmailDocumentResponse,
  selectMonthlyBudgetCensusData,
  selectReportData,
  selectReportFilterOptions,
  selectReportLabel,
  selectReportSelectionTree,
  selectReportTransactions,
  selectReportType,
} from '@State/reports/reports.selectors';
import { selectCurrentCustomer, selectPrivileges } from '@State/user/user.selectors';
import { Page } from '@ThemeMain';

import {
  ReportBackendTypes,
  ReportButtonFunctionality,
  ReportNavigationMapping,
  ReportNoBusinessLogicFilters,
  ReportSelectionTreePages,
  ReportsTitles,
} from './reports-config';
import ReportsPanel from './reports-panel.component';

const ReportPage = (props) => {
  const {
    currentCustomer,
    userPrivileges,
    pageID,
    reportType,
    setReportType,
    reportTransactions,
    reportBusinessLogicLabel,
    getReportTransactions,
    reportFilterOptions,
    getReportFilterOptions,
    reportSelectionTree,
    getReportSelectionTree,
    getReportSelectionTreeUpdate,
    monthlyBudgetCensus,
    getMonthlyBudgetCensus,
    updateMonthlyBudgetCensus,
    emailTransactions,
    reportBase64Data,
    getReportSnapshot,
    emailDocumentResponse,
    resetReportTransactions,
    setPageID,
  } = props;
  const [reportsTableData, setReportsTableData] = useState([]);
  const [selectionTree, setSelectionTree] = useState({ rows: [] });
  const [monthlyBudgetCensusData, setMonthlyBudgetCensusData] = useState([]);
  const [reportsLabel, setReportsLabel] = useState(<>&nbsp;</>);
  const [showLoader, setShowLoader] = useState(false);

  // OPTIONS
  useEffect(() => {
    setPageID(pageID);
    resetReportTransactions();
    setReportType(ReportBackendTypes[pageID]);
    getReportFilterOptions();
  }, [pageID]);

  // TRANSACTIONS
  useEffect(() => {
    setReportsTableData(reportTransactions);
    setReportsLabel(reportBusinessLogicLabel);
    setShowLoader(false);
  }, [reportTransactions]);

  // SELECTION TREE
  useEffect(() => {
    if (ReportSelectionTreePages[pageID]) {
      setSelectionTree(reportSelectionTree);
      setShowLoader(false);
    }
  }, [reportSelectionTree]);

  // BUDGET/CENSUS
  useEffect(() => {
    if (ReportNoBusinessLogicFilters[pageID]) {
      setMonthlyBudgetCensusData(monthlyBudgetCensus);
      setShowLoader(false);
    }
  }, [monthlyBudgetCensus]);

  if (
    userPrivileges &&
    (userPrivileges.canViewReports ||
      userPrivileges.canViewSetBudget ||
      userPrivileges.canViewSetCensus)
  ) {
    let currentNav = null;
    let getBusinessLogicData = () => {};
    let getTreeSelection = () => {};
    let updateSelection = () => {};

    currentNav = ReportNavigationMapping[pageID];
    if (currentNav) {
      return (
        <Page>
          <NavBox
            currentCustomer={currentCustomer}
            fill={false}
            navMap={currentNav}
            userPrivileges={userPrivileges}
          />
        </Page>
      );
    }

    const reportsTitle = ReportsTitles[pageID];
    if (ReportSelectionTreePages[pageID]) {
      getTreeSelection = (data) => getReportSelectionTree(data);
      updateSelection = (data) => getReportSelectionTreeUpdate(data);
    }

    getBusinessLogicData = (data, shouldRefresh) => {
      if (data) {
        if (
          userPrivileges &&
          userPrivileges.canViewReports &&
          !ReportNoBusinessLogicFilters[pageID]
        ) {
          const reportsTransactionsNotInitiallyLoaded =
            !reportsTableData || reportsTableData.status !== 'ok' || shouldRefresh;
          setShowLoader(true);
          getReportTransactions(data);

          // Only want to call this on pages that don't already have reportsTransactions available
          if (ReportSelectionTreePages[pageID] && reportsTransactionsNotInitiallyLoaded) {
            if (shouldRefresh) {
              data.shouldRefresh = shouldRefresh;
            }
            setShowLoader(true);
            getReportSelectionTree(data);
          }
        }

        if (
          ReportNoBusinessLogicFilters[pageID] &&
          userPrivileges &&
          (userPrivileges.canViewSetBudget || userPrivileges.canViewSetCensus)
        ) {
          setShowLoader(true);
          getMonthlyBudgetCensus(data);
        }
      }
    };

    return (
      <>
        <Page>
          <ReportsPanel
            ReportButtonFunctionality={ReportButtonFunctionality}
            ReportSelectionTreePages={ReportSelectionTreePages}
            reportOptions={reportFilterOptions}
            type={reportType}
            userPrivileges={userPrivileges}
            pageID={pageID}
            getBusinessLogicData={(data, shouldRefresh) =>
              getBusinessLogicData(data, shouldRefresh)
            }
            emailTransactions={(data) => emailTransactions(data)}
            getTreeSelection={(data) => getTreeSelection(data)}
            updateSelection={(data) => updateSelection(data)}
            reportsTitle={reportsTitle}
            reportsLabel={reportsLabel}
            reportsTableData={reportsTableData}
            selectionTree={selectionTree}
            monthlyBudgetCensusData={monthlyBudgetCensusData}
            updateMonthlyBudgetCensus={(data) => updateMonthlyBudgetCensus(data)}
            getReportSnapshot={(data) => getReportSnapshot(data)}
            setReportsLabel={(label) => setReportsLabel(label)}
            setShowLoader={(data) => setShowLoader(data)}
            currentCustomer={currentCustomer}
            Printer={Printer}
            fill={false}
            reportBase64Data={reportBase64Data}
            emailDocumentResponse={emailDocumentResponse}
          />
        </Page>
        {showLoader && (
          <Box className="loader-box">
            <CircularProgress className="loader" />
          </Box>
        )}
      </>
    );
  }
  return null;
};

const mapStateToProps = createStructuredSelector({
  currentCustomer: selectCurrentCustomer,
  userPrivileges: selectPrivileges,
  reportType: selectReportType,
  reportBusinessLogicLabel: selectReportLabel,
  reportBase64Data: selectReportData,
  reportFilterOptions: selectReportFilterOptions,
  reportTransactions: selectReportTransactions,
  reportSelectionTree: selectReportSelectionTree,
  monthlyBudgetCensus: selectMonthlyBudgetCensusData,
  emailDocumentResponse: selectEmailDocumentResponse,
});

const mapDispatchToProps = (dispatch) => ({
  setReportType: (type) => dispatch(setReportTypeStart(type)),
  getReportFilterOptions: () => dispatch(getReportFilterOptionsStart()),
  getReportTransactions: (formData) => dispatch(getReportTransactionsStart(formData)),
  resetReportTransactions: () => dispatch(getReportTransactionsSuccess(null)),
  getReportSelectionTree: (formData) => dispatch(getReportSelectionTreeStart(formData)),
  getReportSelectionTreeUpdate: (selection) =>
    dispatch(getReportSelectionTreeUpdateStart(selection)),
  getMonthlyBudgetCensus: (formData) =>
    dispatch(getMonthlyBudgetCensusUsedForCustomerStart(formData)),
  getReportSnapshot: (formData) => dispatch(getReportSnapshotStart(formData)),
  updateMonthlyBudgetCensus: (formData) =>
    dispatch(updateMonthlyBudgetCensusUsedForCustomerStart(formData)),
  emailTransactions: (formData) => dispatch(emailTransactionsStart(formData)),
  setPageID: (data) => dispatch(setPageID(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportPage);

import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import NavBox from '@Components/nav-box/nav-box.component';
import { format, SetUpNav, SetUpPageID } from '@Pages/setup/setup-config';
import {
  getGeneralTabOptionsStart,
  getLogTransactionsSelectionTreeStart,
  getMobileDeviceTabsStart,
  getSelectedLogsStart,
  getSystemConfigurationSelectionTreeStart,
} from '@State/administration/administration.actions';
import {
  selectLogTransactionsSelectionTree,
  selectSelectedLogs,
  selectSystemConfigurationSelectionTree,
} from '@State/administration/administration.selectors';
import { selectCurrentCustomer, selectPrivileges } from '@State/user/user.selectors';
import { assetPaths, Page, Panel } from '@ThemeMain';

import AdministrationPanel from './administration-panel.component';

const AdministrationPage = (props) => {
  const {
    currentCustomer,
    activeNav,
    getLogTransactionsSelectionTree,
    getSystemConfigurationSelectionTree,
    getSelectedLogs,
    getGeneralTabOptions,
    getMobileDeviceTabs,
    selectedLogs,
    logTransactionsSelectionTree,
    systemConfigurationSelectionTree,
    userPrivileges,
  } = props;

  const [selectionTreeData, setSelectionTreeData] = useState(null);
  const [formData, setFormData] = useState({
    periodStart: moment().format(format.dateParam),
    periodEnd: moment().format(format.dateParam),
    iCallerUTCOffset: 0,
    selection: {
      type0: 0,
      type1: 0,
      type2: 0,
      type3: 0,
      type4: 0,
      type5: 0,
      type6: 0,
      type7: 0,
    },
  });

  useEffect(() => {
    switch (activeNav) {
      case SetUpPageID.advanceLogTransactions:
        getLogTransactionsSelectionTree(formData);
        getSelectedLogs(formData);
        break;
      case SetUpPageID.advanceSystemConfiguration:
        getGeneralTabOptions();
        getMobileDeviceTabs();
        break;
      default:
        break;
    }
  }, [activeNav]);

  useEffect(() => {
    switch (activeNav) {
      case SetUpPageID.advanceLogTransactions:
        setSelectionTreeData(logTransactionsSelectionTree);
        break;
      case SetUpPageID.advanceSystemConfiguration:
        setSelectionTreeData(systemConfigurationSelectionTree);
        break;
      default:
        break;
    }
  }, [logTransactionsSelectionTree, systemConfigurationSelectionTree]);

  if (userPrivileges && userPrivileges.isAdminUser) {
    let currentNav = null;
    let pageTitle = '';
    let getData = () => {};
    let getSelectionTree = () => {};
    let mainData;

    switch (activeNav) {
      case SetUpPageID.advanceLogTransactions:
        pageTitle = 'View Logs';
        currentNav = null;
        getData = (data) => getSelectedLogs(data);
        getSelectionTree = (data) => getLogTransactionsSelectionTree(data);
        mainData = selectedLogs;
        break;
      case SetUpPageID.advanceSystemConfiguration:
        pageTitle = 'System Configuration';
        currentNav = null;
        getData = () => {};
        getSelectionTree = (data) => getSystemConfigurationSelectionTree(data);
        mainData = selectedLogs;
        break;
      default:
        currentNav = SetUpNav.advanceNav;
        break;
    }

    if (currentNav !== null) {
      return (
        <Page backBgImage={assetPaths.bg.default}>
          <NavBox
            currentCustomer={currentCustomer}
            fill={false}
            navMap={currentNav}
            userPrivileges={userPrivileges}
          />
        </Page>
      );
    }
    return (
      <Page>
        <Panel>
          <AdministrationPanel
            currentCustomer={currentCustomer}
            userPrivileges={userPrivileges}
            pageID={activeNav}
            pageTitle={pageTitle}
            formData={formData}
            setFormData={setFormData}
            getSelectionTree={getSelectionTree}
            getGeneralTabOptions={getGeneralTabOptions}
            getMobileDeviceTabs={getMobileDeviceTabs}
            selectionTree={selectionTreeData}
            getData={getData}
            mainData={mainData}
          />
        </Panel>
      </Page>
    );
  }
  return null;
};

const mapStateToProps = createStructuredSelector({
  currentCustomer: selectCurrentCustomer,
  userPrivileges: selectPrivileges,
  selectedLogs: selectSelectedLogs,
  logTransactionsSelectionTree: selectLogTransactionsSelectionTree,
  systemConfigurationSelectionTree: selectSystemConfigurationSelectionTree,
});

const mapDispatchToProps = (dispatch) => ({
  getLogTransactionsSelectionTree: (data) => dispatch(getLogTransactionsSelectionTreeStart(data)),
  getSystemConfigurationSelectionTree: (data) =>
    dispatch(getSystemConfigurationSelectionTreeStart(data)),
  getSelectedLogs: (data) => dispatch(getSelectedLogsStart(data)),
  getGeneralTabOptions: () => dispatch(getGeneralTabOptionsStart()),
  getMobileDeviceTabs: () => dispatch(getMobileDeviceTabsStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdministrationPage);

import ReportsTypes from './reports.types';

const INITIAL_STATE = {
  report_type: null,
  report_filter_options: null,
  report_transactions: null,
  report_selection_tree: null,
  report_monthly_budget_census: [],
  report_snapshot_data: null,
  email_document_response: null,
  selected_rcid: null,
  page_id: null,
  options_history: {},
  error: null,
};

const reportsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ReportsTypes.SET_REPORT_TYPE:
      return {
        ...state,
        report_type: action.payload,
        report_filter_options: null,
        report_transactions: null,
        report_selection_tree: null,
        report_monthly_budget_census: [],
        report_snapshot_data: null,
        error: null,
      };

    case ReportsTypes.GET_REPORT_FILTER_OPTIONS_START:
      return {
        ...state,
        error: null,
      };

    case ReportsTypes.GET_REPORT_FILTER_OPTIONS_SUCCESS:
      return {
        ...state,
        report_filter_options: action.payload,
        error: null,
      };

    case ReportsTypes.GET_REPORT_FILTER_OPTIONS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case ReportsTypes.GET_REPORT_TRANSACTIONS_START:
      return {
        ...state,
        error: null,
      };

    case ReportsTypes.GET_REPORT_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        report_transactions: action.payload,
        error: null,
      };

    case ReportsTypes.GET_REPORT_TRANSACTIONS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case ReportsTypes.GET_REPORT_SELECTION_TREE_START:
      return {
        ...state,
        error: null,
      };

    case ReportsTypes.GET_REPORT_SELECTION_TREE_SUCCESS:
      return {
        ...state,
        report_selection_tree: action.payload,
        error: null,
      };

    case ReportsTypes.GET_REPORT_SELECTION_TREE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case ReportsTypes.GET_MONTHLY_BUDGET_CENSUS_USED_FOR_CUSTOMER_START:
      return {
        ...state,
        error: null,
      };

    case ReportsTypes.GET_MONTHLY_BUDGET_CENSUS_USED_FOR_CUSTOMER_SUCCESS:
      return {
        ...state,
        report_monthly_budget_census: action.payload,
        error: null,
      };

    case ReportsTypes.GET_MONTHLY_BUDGET_CENSUS_USED_FOR_CUSTOMER_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case ReportsTypes.UPDATE_MONTHLY_BUDGET_CENSUS_USED_FOR_CUSTOMER_START:
      return {
        ...state,
        error: null,
      };

    case ReportsTypes.UPDATE_MONTHLY_BUDGET_CENSUS_USED_FOR_CUSTOMER_SUCCESS:
      return {
        ...state,
        report_monthly_budget_census: action.payload,
        error: null,
      };

    case ReportsTypes.UPDATE_MONTHLY_BUDGET_CENSUS_USED_FOR_CUSTOMER_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case ReportsTypes.GET_SNAPSHOT_START:
      return {
        ...state,
        error: null,
      };

    case ReportsTypes.GET_SNAPSHOT_SUCCESS:
      return {
        ...state,
        report_snapshot_data: action.payload,
        error: null,
      };

    case ReportsTypes.GET_SNAPSHOT_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case ReportsTypes.EMAIL_DOCUMENT_START:
      return {
        ...state,
        error: null,
      };

    case ReportsTypes.EMAIL_DOCUMENT_SUCCESS:
      return {
        ...state,
        email_document_response: action.payload,
        error: null,
      };

    case ReportsTypes.EMAIL_DOCUMENT_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case ReportsTypes.SAVE_OPTIONS_HISTORY:
      return {
        ...state,
        options_history: {
          ...state.options_history,
          [action.report_type]: action.payload,
        },
      };

    case ReportsTypes.SET_PAGE_ID:
      return {
        ...state,
        page_id: action.payload,
      };

    case ReportsTypes.SET_RCID:
      return {
        ...state,
        selected_rcid: action.payload,
      };

    default:
      return state;
  }
};
export default reportsReducer;

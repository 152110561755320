import { createSelector } from 'reselect';

const selectSetUp = (state) => state.setup;

/* #region PACKS */
export const selectPacksUsedForCustomer = createSelector(
  [selectSetUp],
  (setup) => setup.packs_used_for_customer,
);

export const selectPacksUsedForCustomerPrevious = createSelector(
  [selectSetUp],
  (setup) => setup.packs_used_for_customer_previous,
);

export const selectProductsSelectedInPack = createSelector(
  [selectSetUp],
  (setup) => setup.product_selected_in_pack,
);

export const selectProductsSelectedInPackPrevious = createSelector(
  [selectSetUp],
  (setup) => setup.product_selected_in_pack_previous,
);

export const selectProductsNotSelectedInPack = createSelector(
  [selectSetUp],
  (setup) => setup.products_not_selected_in_pack,
);

export const selectUpdatePacksUsedForCustomerResponse = createSelector(
  [selectSetUp],
  (setup) => setup.update_packs_used_for_customer_response,
);

export const selectUpdateProductsSelectedAndNotSelectedInPackResponse = createSelector(
  [selectSetUp],
  (setup) => setup.update_products_selected_and_not_selected_in_pack_response,
);

export const selectAddProductToPackResponse = createSelector(
  [selectSetUp],
  (setup) => setup.add_product_to_pack,
);
/* #endregion */

/* #region PROCEDURES */
export const selectProceduresUsedForCustomer = createSelector(
  [selectSetUp],
  (setup) => setup.procedures_used_for_customer,
);

export const selectProceduresUsedForCustomerPrevious = createSelector(
  [selectSetUp],
  (setup) => setup.procedures_used_for_customer_previous,
);

export const selectProductsSelectedInProcedure = createSelector(
  [selectSetUp],
  (setup) => setup.product_selected_in_procedure,
);

export const selectProductsSelectedInProcedurePrevious = createSelector(
  [selectSetUp],
  (setup) => setup.product_selected_in_procedure_previous,
);

export const selectProductsNotSelectedInProcedure = createSelector(
  [selectSetUp],
  (setup) => setup.products_not_selected_in_procedure,
);

export const selectUpdateProceduresUsedForCustomerResponse = createSelector(
  [selectSetUp],
  (setup) => setup.update_procedures_used_for_customer_response,
);

export const selectUpdateProductsSelectedAndNotSelectedInProcedureResponse = createSelector(
  [selectSetUp],
  (setup) => setup.update_products_selected_and_not_selected_in_procedure_response,
);

export const selectAddProductToProcedureResponse = createSelector(
  [selectSetUp],
  (setup) => setup.add_product_to_procedure,
);
/* #endregion */

/* #region ORDERS */
export const selectOrdersUsedForCustomer = createSelector(
  [selectSetUp],
  (setup) => setup.orders_used_for_customer,
);

export const selectOrdersUsedForCustomerPrevious = createSelector(
  [selectSetUp],
  (setup) => setup.orders_used_for_customer_previous,
);

export const selectItemsSelectedInOrder = createSelector(
  [selectSetUp],
  (setup) => setup.items_selected_in_order,
);

export const selectItemsSelectedInOrderPrevious = createSelector(
  [selectSetUp],
  (setup) => setup.items_selected_in_order_previous,
);

export const selectItemsNotSelectedInOrder = createSelector(
  [selectSetUp],
  (setup) => setup.items_not_selected_in_order,
);

export const selectUpdateOrdersUsedForCustomerResponse = createSelector(
  [selectSetUp],
  (setup) => setup.update_orders_used_for_customer_response,
);

export const selectUpdateItemsSelectedAndNotSelectedInOrderResponse = createSelector(
  [selectSetUp],
  (setup) => setup.update_items_selected_and_not_selected_in_order_response,
);

export const selectAddItemToOrderResponse = createSelector(
  [selectSetUp],
  (setup) => setup.add_item_to_order,
);
/* #endregion */

/* #region CUSTOMERS */
export const selectPageOfCustomers = createSelector(
  [selectSetUp],
  (setup) => setup.page_of_customers,
);

export const selectPageOfCustomersPrevious = createSelector(
  [selectSetUp],
  (setup) => setup.page_of_customers_previous,
);

export const selectUpdateCustomersResponse = createSelector(
  [selectSetUp],
  (setup) => setup.update_customers_response,
);

export const selectCustomerAccounts = createSelector(
  [selectSetUp],
  (setup) => setup.customer_accounts,
);
/* #endregion */

/* #region DEPARTMENTS */
export const selectDepartmentsUsedForCustomer = createSelector(
  [selectSetUp],
  (setup) => setup.departments_used_for_customer,
);

export const selectDepartmentsUsedForCustomerPrevious = createSelector(
  [selectSetUp],
  (setup) => setup.departments_used_for_customer_previous,
);

export const selectDepartmentAcctTypes = createSelector(
  [selectSetUp],
  (setup) => setup.department_account_types,
);

export const selectUpdateDepartmentsUsedForCustomerResponse = createSelector(
  [selectSetUp],
  (setup) => setup.update_departments_used_for_customer_response,
);
/* #endregion */

/* #region PRODUCTS */
export const selectProductsUsedForCustomer = createSelector(
  [selectSetUp],
  (setup) => setup.products_used_for_customer,
);

export const selectProductsUsedForCustomerPrevious = createSelector(
  [selectSetUp],
  (setup) => setup.products_used_for_customer_previous,
);

export const selectUpdateProductsUsedForCustomerResponse = createSelector(
  [selectSetUp],
  (setup) => setup.update_products_used_for_customer_response,
);
/* #endregion */

/* #region COMMON */
export const selectSetUpError = createSelector([selectSetUp], (setup) => setup.setUpError);

export const selectImportError = createSelector([selectSetUp], (setup) => setup.setUpImportError);

export const selectItemAccounts = createSelector([selectSetUp], (setup) => setup.item_accounts);

export const selectHandheldNumbers = createSelector(
  [selectSetUp],
  (setup) => setup.handheld_numbers,
);

export const selectInventoryFlow = createSelector([selectSetUp], (setup) => setup.inventory_flow);

export const selectOrderTypes = createSelector([selectSetUp], (setup) => setup.order_types);

export const selectValidateDeletionResponse = createSelector(
  [selectSetUp],
  (setup) => setup.validate_deletion_response,
);

export const selectInactivateResponse = createSelector(
  [selectSetUp],
  (setup) => setup.inactivate_response,
);

export const selectSnapshot = createSelector([selectSetUp], (setup) => setup.snapshot);

export const selectMoveToRowResponse = createSelector([selectSetUp], (setup) => setup.move_to_row);

/* #endregion */

import { combineReducers } from 'redux';

import administrationReducer from './administration/administration.reducer';
import countLinenCartsAndClosetsReducer from './count-linen-carts-and-closets/count-linen-carts-and-closets.reducer';
import inventoryReducer from './inventory/inventory.reducer';
import reportsReducer from './reports/reports.reducer';
import setupAccountReducer from './setup-accounts/setup-accounts.reducer';
import advancedCustomerAccountReducer from './setup-advanced-customers-account-type/setup-advanced-customers-account-type.reducer';
import advancedCustomerReducer from './setup-advanced-customers/setup-advanced-customers.reducer';
import advancedUserReducer from './setup-advanced-users/setup-advanced-users.reducer';
import setupUserCustomerNotSelectedReducer from './setup-users-customer-not-selected-in-user/setup-users-customer-not-selected-in-user.reducer';
import setupUserCustomerSelectedReducer from './setup-users-customer-selected-in-user/setup-users-customer-selected-in-user.reducer';
import setupUserDetailsForOrderSelectedReducer from './setup-users-details-for-order-selected-in-user/setup-users-details-for-order-selected-in-user.reducer';
import setupUserHandHeldNumberReducer from './setup-users-hand-held-numbers/setup-users-hand-held-numbers.reducer';
import setupUserLeadDayValuesReducer from './setup-users-lead-day-values/setup-users-lead-day-values.reducer';
import setupUserOrderNotSelectedReducer from './setup-users-order-not-selected-in-user/setup-users-order-not-selected-in-user.reducer';
import setupUserOrderSelectedReducer from './setup-users-order-selected-in-user/setup-users-order-selected-in-user.reducer';
import setUpReducer from './setup/setup.reducer';
import userReducer from './user/user.reducer';

const rootReducer = combineReducers({
  user: userReducer,
  setup_accounts: setupAccountReducer,
  setup_advanced_user: advancedUserReducer,
  setup_advanced_customer: advancedCustomerReducer,
  setup_advanced_customer_account: advancedCustomerAccountReducer,
  report: reportsReducer, // REPORT
  setup_advanced_user_customer_selected: setupUserCustomerSelectedReducer,
  setup_advanced_user_customer_not_selected: setupUserCustomerNotSelectedReducer,
  setup_advanced_user_hand_held_number: setupUserHandHeldNumberReducer,
  setup_advanced_user_lead_day_values: setupUserLeadDayValuesReducer,
  setup_advanced_user_order_selected: setupUserOrderSelectedReducer,
  setup_advanced_user_order_not_selected: setupUserOrderNotSelectedReducer,
  setup_advanced_user_detals_for_order_selected: setupUserDetailsForOrderSelectedReducer,
  count_linen_carts_and_closets: countLinenCartsAndClosetsReducer,
  inventory: inventoryReducer,
  setup: setUpReducer,
  administration: administrationReducer,
});

const appReducer = (state, action) => {
  if (action.type === 'SIGN_OUT_START') {
    /** reset state variables on sign out */
    state = undefined;
  }
  return rootReducer(state, action);
};
export default appReducer;

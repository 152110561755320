import SetupUsersCustomerNotSelectedActionTypes from './setup-users-customer-not-selected-in-user.types';

const INITIAL_STATE = {
  customer_not_selected: [],
};

const setupUserCustomerNotSelectedReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SetupUsersCustomerNotSelectedActionTypes.GET_CUSTOMER_NOT_SELECTED_SUCCESS:
      return {
        ...state,
        customer_not_selected: action.payload ? action.payload : [],
        error: null,
      };

    case SetupUsersCustomerNotSelectedActionTypes.GET_CUSTOMER_NOT_SELECTED_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case SetupUsersCustomerNotSelectedActionTypes.UPDATE_CUSTOMER_NOT_SELECTED:
      const customerRow = {
        RcID: action.payload.RcID,
        Name: action.payload.ObjDesc,
        ObjDesc: action.payload.ObjDesc,
        ObjRcID: action.payload.ObjRcID,
        UserRcID: action.payload.UserRcID,
        RowOrder: state.customer_not_selected.length + 1,
      };
      const index = state.customer_not_selected.findIndex(
        (customerNotSelected) => customerNotSelected.RcID === customerRow.RcID,
      );
      if (index === -1) state.customer_not_selected.unshift(customerRow);
      else state.customer_not_selected.splice(index, 1);

      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export default setupUserCustomerNotSelectedReducer;

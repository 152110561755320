import { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import Modal from '@Components/modal/modal.component';
import { setCustomer } from '@State/user/user.actions';
import {
  selectCurrentCustomer,
  selectCurrentUser,
  selectCustomers,
} from '@State/user/user.selectors';
import { cssRules } from '@ThemeMain';

const CustomersList = styled.ul`
  overflow: auto;
  margin: 0;
  padding: 0;
  width: 100%;
  min-width: 20em;
  max-height: 600px;
}
`;

const ListItem = styled.li`
  font-weight: ${(props) => props.fontWeight || 'normal'};
  list-style: none;
  line-height: 2em;
  cursor: pointer;
  ${cssRules.ellipsis}
`;

function SelectCustomer(props) {
  const {
    show,
    setShow,
    onClose,
    customers,
    setCustomer,
    currentCustomer,
    setShowSingleCustomer,
    showSingleCustomer,
  } = props;
  const [customerLocal, setCustomerLocal] = useState(currentCustomer);

  let modal = null;
  if (show && customers) {
    // if (customers.length === 1) {
    //   if (showSingleCustomer === 1) {
    //     setShow(false);
    //     setShowSingleCustomer(showSingleCustomer + 1);
    //     setCustomer(customers[0]);
    //     return null;
    //   }
    // }
    modal = (
      <Modal
        show={show}
        setShow={setShow}
        confirmLabel="Select"
        confirmDisabled={!customerLocal}
        title="Select Customer"
        onCancel={() => onClose && onClose()}
        onConfirm={() => {
          setCustomer(customerLocal);
          setShow(false);
        }}
      >
        <CustomersList>
          {customers.map((item) => {
            const fontWeight =
              customerLocal && customerLocal.RcID === item.RcID ? 'bold' : 'normal';
            return (
              <ListItem
                fontWeight={fontWeight}
                key={item.RcID}
                title={item.Name}
                onClick={() => setCustomerLocal(item)}
              >
                {item.Name}
              </ListItem>
            );
          })}
        </CustomersList>
      </Modal>
    );
  }
  return modal;
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  customers: selectCustomers,
  currentCustomer: selectCurrentCustomer,
});

const mapDispatchToProps = (dispatch) => ({
  setCustomer: (customer) => dispatch(setCustomer(customer)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SelectCustomer));

import { Box } from 'grommet';
import { useEffect, useState } from 'react';

import { HeaderTextInput, HeaderTextLabel } from '@Pages/count-linen/count-linen.styles';
import colors from '@ThemeMain';

const HeaderInputBox = (props) => {
  const {
    laundryWeight,
    soilWeight,
    laundryCharge,
    setLaundryWeight,
    setSoilWeight,
    setLaundryCharge,
  } = props;
  const [lndryWgt, setLndryWgt] = useState(`${laundryWeight} Lb`);
  const [soilWgt, setSoilWgt] = useState(`${soilWeight} Lb`);
  const [lndryCharge, setLndryCharge] = useState(`$ ${laundryCharge}`);
  const trimUnit = (val) => {
    return val.replace(' Lb', '').replace('$ ', '');
  };

  useEffect(() => {
    setLndryWgt(`${laundryWeight} Lb`);
    setSoilWgt(`${soilWeight} Lb`);
    setLndryCharge(`$ ${laundryCharge}`);
  }, [laundryWeight, soilWeight, laundryCharge]);

  const evaluateNewValue = (newVal, oldVal, callbackLocal, callbackShared, unit) => {
    const val = unit === '$' ? `${unit} ${newVal}` : `${newVal} ${unit}`;
    if (oldVal === newVal) {
      callbackLocal(val);
    } else {
      callbackLocal(val);
      callbackShared(trimUnit(newVal));
    }
  };

  return (
    <Box
      gridArea="header"
      background={colors.innerBg}
      fill="horizontal"
      border={{ color: colors.faintestBlue, side: 'bottom', size: 'medium' }}
    >
      <Box direction="row" gap="small" pad="small" align="center">
        <HeaderTextLabel>Laundry</HeaderTextLabel>
        <HeaderTextInput
          placeholder={lndryWgt}
          value={lndryWgt}
          onBlur={(event) =>
            evaluateNewValue(
              event.target.value,
              laundryWeight,
              (data) => setLndryWgt(data),
              (data) => setLaundryWeight(data),
              'Lb',
            )
          }
          onFocus={(event) => setLndryWgt(trimUnit(event.target.value))}
          onChange={(event) => setLndryWgt(trimUnit(event.target.value))}
        />
        <HeaderTextLabel>Soil Weight</HeaderTextLabel>
        <HeaderTextInput
          placeholder={soilWgt}
          value={soilWgt}
          onBlur={(event) =>
            evaluateNewValue(
              event.target.value,
              soilWeight,
              (data) => setSoilWgt(data),
              (data) => setSoilWeight(data),
              'Lb',
            )
          }
          onFocus={(event) => setSoilWgt(trimUnit(event.target.value))}
          onChange={(event) => setSoilWgt(trimUnit(event.target.value))}
        />
        <HeaderTextLabel>Laundry Charge</HeaderTextLabel>
        <HeaderTextInput
          placeholder={lndryCharge}
          value={lndryCharge}
          onBlur={(event) =>
            evaluateNewValue(
              event.target.value,
              laundryCharge,
              (data) => setLndryCharge(data),
              (data) => setLaundryCharge(data),
              '$',
            )
          }
          onFocus={(event) => setLndryCharge(trimUnit(event.target.value))}
          onChange={(event) => setLndryCharge(trimUnit(event.target.value))}
        />
      </Box>
    </Box>
  );
};

export default HeaderInputBox;

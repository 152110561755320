import { Box, Calendar, DropButton, Select, Table, TableCell, Text, TextInput } from 'grommet';
import styled from 'styled-components';

import { BrandButton, colors, cssRules, ThemeDataTable } from '@ThemeMain';

export const ButtonMenu = styled(BrandButton)`
  font-size: inherit;
`;

export const CellMenu = styled(TableCell)`
  padding-top: 2px !important;
`;

export const CellNoPadding = styled(TableCell)`
  padding-left: 0px !important;
`;

export const LabelBox = styled(Box)`
  min-height: 45px;
  text-align: center;
  padding: 10px;
`;

export const SideBarCalendar = styled(Calendar)`
  margin: 0px auto;
`;

export const DropMenu = styled(DropButton)`
  color: #fff;
  background-color: #6c82c9;
`;

export const DropMenuButton = styled(Select)`
  font-size: inherit;
`;

export const HeaderTextLabel = styled(Text)`
  white-space: nowrap;
`;

export const HeaderTextInput = styled(TextInput)`
  width: 65%;
  display: inline-block;
  height: 15px;
  margin-left: 10px;
`;

const tableStyles = `
  td {
    padding: 0.3em;
  }

  thead {
    tr{
      th {
        position: sticky;
        top: 0;
        background: #fff;
        z-index: 9;
        box-shadow: 1px 1px 0 #6C82C9;
      }

      th:last-child {
        width: 99%;
      }
    }
  }
  tbody {
    td, th {
      border: 1px dashed ${colors['light-4']};
      border: 1px dashed ${colors['light-4']};
    }

    tr {
      display: table-row;

      td{ 
        input {
          height: 10px !important;
        }
      }
    }
  }
`;

const tableStylesV2 = `
  td {
    padding: 0.3em;
  }

  thead {
    tr {
      th:nth-child(1) {
        /* contains a the row "ID" */
        display: none;
      }

      th {
        width: auto;
        position: sticky;
        top: 0;
        background: #fff;
        z-index: 9;
        box-shadow: 1px 1px 0 #6C82C9;
      }
      
      th:last-child {
        width: 99%;
      }
    }
  }

  tbody {
    td, th {
      border: 1px dashed ${colors['light-4']};
      border: 1px dashed ${colors['light-4']};
    }

    tr {
      th:nth-child(1) {
        /* contains a the row "ID", still th, though it's in a tbody tr */
        display: none;
      }

      td{ 
        input {
          height: 10px !important;
        }
      }

      th, td {
        width: auto;
      }
    }
  }
`;

export const ResultsDataTable = styled(ThemeDataTable)`
  ${tableStyles}
`;

export const ResultsDataTableV2 = styled(ThemeDataTable)`
  ${tableStylesV2}
`;
export const ResultsTable = styled(Table)`
  ${tableStyles}
`;

export const FilterControlsBox = styled(Box)`
  font-size: 0.9rem;
  line-height: 2rem;
  background-color: ${colors.innerBg};
  border-bottom: 3px solid ${colors.faintestBlue};
  overflow-x: auto;
`;

export const ResultsSummary = styled(Box)`
  background-color: ${colors.faintestBlue};
  border-bottom: 3px solid ${colors.faintestBlue};
  padding: 0.5em;
`;

export const HeaderCell = styled(TableCell)`
  ${cssRules.ellipsis}
`;

import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from '@API';

import {
  addItemToInventoryFailure,
  addItemToInventorySuccess,
  createInjectionForCustomerOnDateFailure,
  createInjectionForCustomerOnDateSuccess,
  createInventoryForOrderOnDateFailure,
  createInventoryForOrderOnDateSuccess,
  createRetirementForCustomerOnDateFailure,
  createRetirementForCustomerOnDateSuccess,
  getInventoryForInventoryIDFailure,
  getInventoryForInventoryIDSuccess,
  getInventoryFormPDFFailure,
  getInventoryFormPDFSuccess,
  getItemsNotInInventoryFailure,
  getItemsNotInInventorySuccess,
  getOrderScheduleForCustomerOnDateFailure,
  getOrderScheduleForCustomerOnDateSuccess,
  updateInventoryCountsFailure,
  updateInventoryCountsSuccess,
} from './inventory.actions';
import InventoryTypes from './inventory.types';

const createInjectionForCustomerOnDateApi = (params) => {
  return api.post('/inventory/create-injection-for-customer-on-date', params);
};

const createRetirementForCustomerOnDateApi = (params) => {
  return api.post('/inventory/create-retirement-for-customer-on-date', params);
};

const getInventoryForInventoryIDApi = (params) => {
  return api.post('/inventory/get-inventory-for-inventory-id', params);
};

const getItemsNotInInventoryApi = (params) => {
  return api.post('/inventory/get-items-not-in-inventory', params);
};

const getOrderScheduleForCustomerOnDateApi = (params) => {
  return api.post('/inventory/get-order-schedule-for-customer-on-date', params);
};

const getInventoryFormPDFApi = (params) => {
  return api.post('/inventory/get-inventory-form-pdf', params);
};

const updateInventoryCountsApi = (params) => {
  return api.post('/inventory/update-inventory-counts', params);
};

const addItemToInventoryApi = (params) => {
  return api.post('/inventory/add-item-to-inventory', params);
};

const createInventoryForOrderOnDateApi = (params) => {
  return api.post('/inventory/create-inventory-for-order-on-date', params);
};

export function* createInjectionForCustomerOnDate(action) {
  try {
    const params = action.payload;
    const { data } = yield call(createInjectionForCustomerOnDateApi, params);

    yield put(createInjectionForCustomerOnDateSuccess(data));
  } catch (error) {
    yield put(createInjectionForCustomerOnDateFailure(error));
  }
}

export function* createRetirementForCustomerOnDate(action) {
  try {
    const params = action.payload;
    const { data } = yield call(createRetirementForCustomerOnDateApi, params);

    yield put(createRetirementForCustomerOnDateSuccess(data));
  } catch (error) {
    yield put(createRetirementForCustomerOnDateFailure(error));
  }
}

export function* getInventoryForInventoryID(action) {
  try {
    const params = action.payload;
    const { data } = yield call(getInventoryForInventoryIDApi, params);
    if (
      data.data !== undefined &&
      data.data.inventoryItemTable !== undefined &&
      data.data.inventoryItemTable.RcID !== undefined
    ) {
      data.data.inventoryItemTable = [data.data.inventoryItemTable];
    }
    yield put(getInventoryForInventoryIDSuccess(data));
  } catch (error) {
    yield put(getInventoryForInventoryIDFailure(error));
  }
}

export function* getItemsNotInInventory(action) {
  try {
    const params = action.payload;
    const { data } = yield call(getItemsNotInInventoryApi, params);

    yield put(getItemsNotInInventorySuccess(data));
  } catch (error) {
    yield put(getItemsNotInInventoryFailure(error));
  }
}

export function* getOrderScheduleForCustomerOnDate(action) {
  try {
    const params = action.payload;
    const { data } = yield call(getOrderScheduleForCustomerOnDateApi, params);

    yield put(getOrderScheduleForCustomerOnDateSuccess(data));
  } catch (error) {
    yield put(getOrderScheduleForCustomerOnDateFailure(error));
  }
}

export function* getInventoryFormPDF(action) {
  try {
    const params = action.payload;
    const { data } = yield call(getInventoryFormPDFApi, params);

    yield put(getInventoryFormPDFSuccess(data));
  } catch (error) {
    yield put(getInventoryFormPDFFailure(error));
  }
}

export function* updateInventoryCounts(action) {
  try {
    const params = action.payload;
    const { data } = yield call(updateInventoryCountsApi, params);

    yield put(updateInventoryCountsSuccess(data));
  } catch (error) {
    yield put(updateInventoryCountsFailure(error));
  }
}

export function* addItemToInventory(action) {
  try {
    const params = action.payload;
    const { data } = yield call(addItemToInventoryApi, params);

    yield put(addItemToInventorySuccess(data));
  } catch (error) {
    yield put(addItemToInventoryFailure(error));
  }
}

export function* createInventoryForOrderOnDate(action) {
  try {
    const params = action.payload;
    const { data } = yield call(createInventoryForOrderOnDateApi, params);

    yield put(createInventoryForOrderOnDateSuccess(data));
  } catch (error) {
    yield put(createInventoryForOrderOnDateFailure(error));
  }
}

export function* onCreateInjectionForCustomerOnDateStart() {
  yield takeLatest(
    InventoryTypes.CREATE_INJECTION_FOR_CUSTOMER_ON_DATE_START,
    createInjectionForCustomerOnDate,
  );
}

export function* onCreateRetirementForCustomerOnDateStart() {
  yield takeLatest(
    InventoryTypes.CREATE_RETIREMENT_FOR_CUSTOMER_ON_DATE_START,
    createRetirementForCustomerOnDate,
  );
}

export function* onGetInventoryForInventoryIDStart() {
  yield takeLatest(InventoryTypes.GET_INVENTORY_FOR_INVENTORY_ID_START, getInventoryForInventoryID);
}

export function* onGetItemsNotInInventoryStart() {
  yield takeLatest(InventoryTypes.GET_ITEMS_NOT_IN_INVENTORY_START, getItemsNotInInventory);
}

export function* onGetOrderScheduleForCustomerOnDateStart() {
  yield takeLatest(
    InventoryTypes.GET_ORDER_SCHEDULE_FOR_CUSTOMER_ON_DATE_START,
    getOrderScheduleForCustomerOnDate,
  );
}

export function* onGetInventoryFormPDFStart() {
  yield takeLatest(InventoryTypes.GET_INVENTORY_FORM_PDF_START, getInventoryFormPDF);
}

export function* onUpdateInventoryCountsStart() {
  yield takeLatest(InventoryTypes.UPDATE_INVENTORY_COUNTS_START, updateInventoryCounts);
}

export function* onAddItemToInventoryStart() {
  yield takeLatest(InventoryTypes.ADD_ITEM_TO_INVENTORY_START, addItemToInventory);
}

export function* onCreateInventoryForOrderOnDateStart() {
  yield takeLatest(
    InventoryTypes.CREATE_INVENTORY_FOR_ORDER_ON_DATE_START,
    createInventoryForOrderOnDate,
  );
}

export function* inventorySagas() {
  yield all([
    call(onCreateInjectionForCustomerOnDateStart),
    call(onCreateRetirementForCustomerOnDateStart),
    call(onGetInventoryForInventoryIDStart),
    call(onGetItemsNotInInventoryStart),
    call(onGetOrderScheduleForCustomerOnDateStart),
    call(onUpdateInventoryCountsStart),
    call(onAddItemToInventoryStart),
    call(onGetInventoryFormPDFStart),
    call(onCreateInventoryForOrderOnDateStart),
  ]);
}

import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import api from '@API';

import { selectCurrentCustomer, selectSiteInfo } from '../user/user.selectors';
import {
  getAdvancedCustomerAccountFailure,
  getAdvancedCustomerAccountSuccess,
} from './setup-advanced-customers-account-type.actions';
import SetupAdvancedCustomerAccountActionTypes from './setup-advanced-customers-account-type.types';

const advancedCustomerAccountApi = (params) => {
  return api.post('setuptables/get-customer-accounts', params);
};

export function* getAdvancedCustomerAccount(action) {
  try {
    const siteInfo = yield select(selectSiteInfo);
    const customerInfo = yield select(selectCurrentCustomer);
    const params = {};
    const { data } = yield call(advancedCustomerAccountApi, params);
    yield put(getAdvancedCustomerAccountSuccess(data.data));
  } catch (error) {
    yield put(getAdvancedCustomerAccountFailure(error));
  }
}

export function* onGetAdvancedCustomerAccountStart() {
  yield takeLatest(
    SetupAdvancedCustomerAccountActionTypes.GET_ADVANCED_CUSTOMER_ACCOUNT_START,
    getAdvancedCustomerAccount,
  );
}

export function* advancedCustomerAccountSagas() {
  yield all([call(onGetAdvancedCustomerAccountStart)]);
}

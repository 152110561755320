import SetupUsersLeadDayValuesActionTypes from './setup-users-lead-day-values.types';

export const getLeadDayValuesStart = () => ({
  type: SetupUsersLeadDayValuesActionTypes.GET_LEAD_DAY_VALUES_START,
  payload: null,
});

export const getLeadDayValuesSuccess = (leadDayValues) => ({
  type: SetupUsersLeadDayValuesActionTypes.GET_LEAD_DAY_VALUES_SUCCESS,
  payload: leadDayValues,
});

export const getLeadDayValuesFailure = (error) => ({
  type: SetupUsersLeadDayValuesActionTypes.GET_LEAD_DAY_VALUES_FAILURE,
  payload: error,
});

import { Box, Tab, Tabs } from 'grommet';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import CustomerAccessTab from '@Components/setup-users-customer-access-tab/setup-users-customer-access-tab.component';
import GeneralTab from '@Components/setup-users-general-tab/setup-users-general-tab.component';
import SetupUsersHandHeldTab from '@Components/setup-users-hand-held-tab/setup-users-hand-held-tab.component';
import SetupUsersOrdersTab from '@Components/setup-users-orders-tab/setup-users-orders-tab.component';
import SetupUsersPrivilegesTab from '@Components/setup-users-privileges-tab/setup-users-privileges-tab.component';
import { selectAdvancedCurrentUsers } from '@State/setup-advanced-users/setup-advanced-users.selectors';

const SetupUsersTab = (props) => {
  const { advancedCurrentUser, evaluateChange, changes } = props;
  const [timeSelection, setTimeSelection] = useState([]);

  useEffect(() => {
    const time = getTimeValues(15);
    setTimeSelection(time);
  }, []);

  const getTimeValues = (step = 1) => {
    const start = 0;
    const end = 24 * 60;
    const values = {};
    const options = [];
    for (let i = start; i <= end; i += step) {
      const time = minutesToTime(i, '12');
      values[i] = time;
      options.push(time);
    }
    return { values, options };
  };

  const minutesToTime = (minute, type = '24') => {
    const hrInMinute = 60;
    let hours = Math.floor(minute / hrInMinute);
    let mins = minute % hrInMinute;
    let ampm = '';
    let result = '';

    if (type === '24') {
      hours = hours < 10 ? `0${hours}` : hours;
      mins = mins < 10 ? `0${mins}` : mins;
      result = `${hours}:${mins}`;
    } else if (type === '12') {
      mins = mins < 10 ? `0${mins}` : mins;
      ampm = hours % 24 < 12 ? 'AM' : 'PM';
      hours %= 12;
      if (hours === 0) {
        hours = 12;
      }
      result = `${hours}:${mins} ${ampm}`;
    }

    return result;
  };

  const formatOptions = (data) => {
    const options = {};
    if (data !== null) {
      data.forEach((item) => {
        options[item.Value] = item.Desc;
      });
    }

    return options;
  };

  const timeToMinutes = (time, type = '24') => {
    let result = 0;
    time = time.replace(' ', ':').split(':');
    let hour = parseInt(time[0], 10);
    const minute = parseInt(time[1], 10);

    if (type === '24') {
      result = minute + hour * 60;
    } else if (type === '12') {
      if (time[2] === 'AM') {
        hour = hour === 12 ? 0 : hour;
      } else {
        hour = hour < 12 ? hour + 12 : hour;
      }
    }
    result = minute + hour * 60;
    return result;
  };

  return (
    <>
      {advancedCurrentUser ? (
        <Box className="overlapped-box">
          <Tabs>
            <Tab title="General">
              <GeneralTab evaluateChange={evaluateChange} />
            </Tab>
            <Tab title="Privileges">
              <SetupUsersPrivilegesTab evaluateChange={evaluateChange} />
            </Tab>
            <Tab title="Hand Held">
              <SetupUsersHandHeldTab
                timeSelection={timeSelection}
                minutesToTime={minutesToTime}
                timeToMinutes={timeToMinutes}
                formatOptions={formatOptions}
                evaluateChange={evaluateChange}
              />
            </Tab>
            {advancedCurrentUser.PrivEvryCust === '1' || advancedCurrentUser.RcID === '0' ? (
              <Tab title="Customer Access" disabled />
            ) : (
              <Tab title="Customer Access">
                <CustomerAccessTab evaluateChange={evaluateChange} />
              </Tab>
            )}
            {advancedCurrentUser.PrivEvryOrdr === '1' || advancedCurrentUser.RcID === '0' ? (
              <Tab title="Orders" disabled />
            ) : (
              <Tab title="Orders">
                <SetupUsersOrdersTab
                  evaluateChange={evaluateChange}
                  timeSelection={timeSelection}
                />
              </Tab>
            )}
          </Tabs>
        </Box>
      ) : null}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  advancedCurrentUser: selectAdvancedCurrentUsers,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SetupUsersTab));

import dateformat from 'dateformat';

export const filterInput = (event, type = 'int', chars = [], page = null) => {
  const { value } = event.target;
  const currentIndex = event.target.selectionEnd;

  if (value === undefined || value === null) {
    return;
  }

  let newValue = `${
    value.substring(0, currentIndex) + event.key + value.substring(currentIndex, value.length)
  }0`;

  newValue = newValue.replace(/^0+/, '');

  if (
    ((type === INPUT_TYPE.int && !isANumber(event.key)) ||
      (type === INPUT_TYPE.float &&
        (!/^(0|[1-9]\d*)?(\.\d+)?(?<=\d)$/.test(newValue) || newValue.split('.').length > 2)) ||
      chars.includes(event.key)) &&
    (!NAV_KEYS.hasOwnProperty(event.key) || event.key === NAV_KEYS.Tab)
  ) {
    event.preventDefault();
  }
  if (NAV_KEYS.hasOwnProperty(event.key)) {
    let parent = null;

    if (
      (event.key === NAV_KEYS.ArrowLeft && event.target.selectionStart === 0) ||
      ((event.key === NAV_KEYS.ArrowRight || event.key === NAV_KEYS.Tab) &&
        event.target.selectionEnd === event.target.value.length)
    ) {
      parent = event.target.parentElement.parentElement;
    } else if (
      event.key === NAV_KEYS.ArrowUp ||
      event.key === NAV_KEYS.ArrowDown ||
      event.key === NAV_KEYS.Enter
    ) {
      parent = event.target.parentElement.parentElement.parentElement;
    } else {
      return false;
    }

    let rowElement =
      event.key === NAV_KEYS.ArrowLeft ||
      event.key === NAV_KEYS.ArrowRight ||
      event.key === NAV_KEYS.Tab
        ? parent
        : event.target.parentElement.parentElement;

    let nextParent =
      event.key === NAV_KEYS.ArrowUp || event.key === NAV_KEYS.ArrowLeft
        ? parent.previousSibling
        : parent.nextElementSibling;

    let index = Array.from(rowElement.parentNode.children).indexOf(rowElement);

    if (nextParent === null && event.key === NAV_KEYS.Tab) {
      parent = event.target.parentElement.parentElement.parentElement;
      nextParent = parent.nextElementSibling;
      index = 0;
    }
    if (
      (event.key === NAV_KEYS.ArrowUp || event.key === NAV_KEYS.ArrowLeft || index > -1) &&
      nextParent &&
      (nextParent.tagName === 'TR' || nextParent.tagName === 'TD')
    ) {
      rowElement =
        event.key === NAV_KEYS.ArrowRight ||
        event.key === NAV_KEYS.Tab ||
        event.key === NAV_KEYS.ArrowLeft
          ? nextParent
          : nextParent.children[index];

      if (rowElement) {
        const inputs = rowElement.getElementsByTagName('input');
        const selects = rowElement.getElementsByTagName('select');
        const buttons = rowElement.getElementsByTagName('button');

        if (inputs.length) {
          const $input = inputs[0];
          $input.focus();
          setTimeout(() => {
            $input.select();
            $input.closest('td').classList.add('cell-focus');
          }, 50);
        } else if (selects.length) {
          selects[0].focus();
        } else if (buttons.length) {
          buttons[0].focus();
        }
      }
    }
  }
};

export const hightlightCell = (event, prevElem = null, select = true) => {
  select && event.target.select();
  event.target.closest('td').classList.add('cell-focus');

  if (prevElem && prevElem.target) {
    prevElem.target.closest('td').classList.remove('cell-focus');
  }
};
export const copyObject = (data) => {
  return data ? JSON.parse(JSON.stringify(data)) : null;
};

const isANumber = (value) => {
  return /^(0|[1-9]\d*)(\.\d+)?$/.test(value);
};

export const NAV_KEYS = {
  Enter: 'Enter',
  ArrowDown: 'ArrowDown',
  ArrowUp: 'ArrowUp',
  ArrowLeft: 'ArrowLeft',
  ArrowRight: 'ArrowRight',
  Backspace: 'Backspace',
  Tab: 'Tab',
};

export const INPUT_TYPE = {
  int: 'int',
  float: 'float',
};

export const MESSAGE = {
  invalidDate: 'You must first select both dates',
  dateComparison: 'Start date is greater than the End date',
  canDeleteItem: 'This item can be deleted.',
};

export const DATE_FORMAT = {
  standard: 'YYYY-MM-DD',
  businessLogicDateFormat: "yyyy-mm-dd'T'12:00:00.lo",
};

export const toUtcFormat = (date) => {
  return dateformat(date, DATE_FORMAT.businessLogicDateFormat, true).replace(
    /([+-]\d\d)(\d\d)$/,
    '$1:$2',
  );
};

export const toArray = (data) => {
  let result = [];

  if (data && !Array.isArray(data)) {
    result = [data];
  } else {
    result = data;
  }

  return result;
};

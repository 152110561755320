import { Box, Heading } from 'grommet';
import styled from 'styled-components';

import { BorderBox, colors, measures } from '@ThemeMain';

const NavBorderBox = styled(BorderBox)`
  box-shadow: ${measures.dropShadow} ${colors.dropShadow};
  width: auto;
  margin: 1rem auto;
  font-size: 1.2rem;
`;

export const NavBoxOuter = (props) => {
  return <Box direction="row" align="start" gap="medium" margin="auto" {...props} />;
};

export const NavBoxInner = (props) => {
  return (
    <NavBorderBox direction="column" alignSelf="start" pad="medium" gap="medium" {...props}>
      {props.label ? (
        <Heading level="4" alignSelf={props.labelAlign || 'start'} style={{ margin: 0 }}>
          {props.label}
        </Heading>
      ) : null}
      {props.children}
    </NavBorderBox>
  );
};

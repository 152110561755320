import CountLinenCartsAndClosetsTypes from './count-linen-carts-and-closets.types';

// ORDER SCHEDULE
export const getCountLinenCartsAndClosetsOrderScheduleStart = (data) => ({
  type: CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_ORDER_SCHEDULE_START,
  payload: data,
});

export const getCountLinenCartsAndClosetsOrderScheduleSuccess = (data) => ({
  type: CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_ORDER_SCHEDULE_SUCCESS,
  payload: data,
});

export const getCountLinenCartsAndClosetsOrderScheduleFailure = (error) => ({
  type: CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_ORDER_SCHEDULE_FAILURE,
  payload: error,
});

// ORDERS NOT SCHEDULED
export const getCountLinenCartsAndClosetsOrdersNotScheduledStart = (data) => ({
  type: CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_ORDERS_NOT_SCHEDULED_START,
  payload: data,
});

export const getCountLinenCartsAndClosetsOrdersNotScheduledSuccess = (data) => ({
  type: CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_ORDERS_NOT_SCHEDULED_SUCCESS,
  payload: data,
});

export const getCountLinenCartsAndClosetsOrdersNotScheduledFailure = (error) => ({
  type: CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_ORDERS_NOT_SCHEDULED_FAILURE,
  payload: error,
});

// DELIVERY ITEM RIGHTS
export const getCountLinenCartsAndClosetsDeliveryItemRightsStart = (data) => ({
  type: CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERY_ITEM_RIGHTS_START,
  payload: data,
});

export const getCountLinenCartsAndClosetsDeliveryItemRightsSuccess = (data) => ({
  type: CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERY_ITEM_RIGHTS_SUCCESS,
  payload: data,
});

export const getCountLinenCartsAndClosetsDeliveryItemRightsFailure = (error) => ({
  type: CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERY_ITEM_RIGHTS_FAILURE,
  payload: error,
});

// DELIVERY FOR DELIVERY ID
export const getCountLinenCartsAndClosetsDeliveryForDeliveryIDStart = (data) => ({
  type: CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERY_FOR_DELIVERY_ID_START,
  payload: data,
});

export const getCountLinenCartsAndClosetsDeliveryForDeliveryIDSuccess = (data) => ({
  type: CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERY_FOR_DELIVERY_ID_SUCCESS,
  payload: data,
});

export const getCountLinenCartsAndClosetsDeliveryForDeliveryIDFailure = (error) => ({
  type: CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERY_FOR_DELIVERY_ID_FAILURE,
  payload: error,
});

// GAUGE INFO
export const getCountLinenCartsAndClosetsDashboardGaugeInfoStart = (data) => ({
  type: CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_DASHBOARD_GAUGE_INFO_START,
  payload: data,
});

export const getCountLinenCartsAndClosetsDashboardGaugeInfoSuccess = (data) => ({
  type: CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_DASHBOARD_GAUGE_INFO_SUCCESS,
  payload: data,
});

export const getCountLinenCartsAndClosetsDashboardGaugeInfoFailure = (error) => ({
  type: CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_DASHBOARD_GAUGE_INFO_FAILURE,
  payload: error,
});

// UPDATE DELIVERY COUNTS
export const getCountLinenCartsAndClosetsUpdateDeliveryCountsStart = (data) => ({
  type: CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_UPDATE_DELIVERY_COUNTS_START,
  payload: data,
});

export const getCountLinenCartsAndClosetsUpdateDeliveryCountsSuccess = (data) => ({
  type: CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_UPDATE_DELIVERY_COUNTS_SUCCESS,
  payload: data,
});

export const getCountLinenCartsAndClosetsUpdateDeliveryCountsFailure = (error) => ({
  type: CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_UPDATE_DELIVERY_COUNTS_FAILURE,
  payload: error,
});

// CREATE DELIVERY FOR ORDER ON DATE
export const createCountLinenCartsAndClosetsDeliveryForOrderOnDateStart = (data) => ({
  type: CountLinenCartsAndClosetsTypes.CREATE_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERY_FOR_ORDER_ON_DATE_START,
  payload: data,
});

export const createCountLinenCartsAndClosetsDeliveryForOrderOnDateSuccess = (data) => ({
  type: CountLinenCartsAndClosetsTypes.CREATE_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERY_FOR_ORDER_ON_DATE_SUCCESS,
  payload: data,
});

export const createCountLinenCartsAndClosetsDeliveryForOrderOnDateFailure = (error) => ({
  type: CountLinenCartsAndClosetsTypes.CREATE_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERY_FOR_ORDER_ON_DATE_FAILURE,
  payload: error,
});

// GET ITEMS NOT IN DELIVERY
export const getCountLinenCartsAndClosetsGetItemsNotInDeliveryStart = (data) => ({
  type: CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_GET_ITEMS_NOT_IN_DELIVERY_START,
  payload: data,
});

export const getCountLinenCartsAndClosetsGetItemsNotInDeliverySuccess = (data) => ({
  type: CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_GET_ITEMS_NOT_IN_DELIVERY_SUCCESS,
  payload: data,
});

export const getCountLinenCartsAndClosetsGetItemsNotInDeliveryFailure = (error) => ({
  type: CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_GET_ITEMS_NOT_IN_DELIVERY_FAILURE,
  payload: error,
});

// ADD ITEMS TO DELIVERY
export const getCountLinenCartsAndClosetsAddItemsToDeliveryStart = (data) => ({
  type: CountLinenCartsAndClosetsTypes.COUNT_LINEN_CARTS_AND_CLOSETS_ADD_ITEMS_TO_DELIVERY_START,
  payload: data,
});

export const getCountLinenCartsAndClosetsAddItemsToDeliverySuccess = (data) => ({
  type: CountLinenCartsAndClosetsTypes.COUNT_LINEN_CARTS_AND_CLOSETS_ADD_ITEMS_TO_DELIVERY_SUCCESS,
  payload: data,
});

export const getCountLinenCartsAndClosetsAddItemsToDeliveryFailure = (error) => ({
  type: CountLinenCartsAndClosetsTypes.COUNT_LINEN_CARTS_AND_CLOSETS_ADD_ITEMS_TO_DELIVERY_FAILURE,
  payload: error,
});

// DELIVERIES FOR GLOBAL ADJUST ON DATE
export const getCountLinenCartsAndClosetsDeliveriesForGlobalAdjustOnDateStart = (data) => ({
  type: CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERIES_FOR_GLOBAL_ADJUSTMENT_ON_DATE_START,
  payload: data,
});

export const getCountLinenCartsAndClosetsDeliveriesForGlobalAdjustOnDateSuccess = (data) => ({
  type: CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERIES_FOR_GLOBAL_ADJUSTMENT_ON_DATE_SUCCESS,
  payload: data,
});

export const getCountLinenCartsAndClosetsDeliveriesForGlobalAdjustOnDateFailure = (error) => ({
  type: CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERIES_FOR_GLOBAL_ADJUSTMENT_ON_DATE_FAILURE,
  payload: error,
});

// UPDATE DELIVERIES FOR GLOBAL ADJUST ON DATE
export const updateCountLinenCartsAndClosetsUpdateDeliveriesForGlobalAdjustOnDateStart = (
  data,
) => ({
  type: CountLinenCartsAndClosetsTypes.UPDATE_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERIES_FOR_GLOBAL_ADJUSTMENT_ON_DATE_START,
  payload: data,
});

export const updateCountLinenCartsAndClosetsUpdateDeliveriesForGlobalAdjustOnDateSuccess = (
  data,
) => ({
  type: CountLinenCartsAndClosetsTypes.UPDATE_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERIES_FOR_GLOBAL_ADJUSTMENT_ON_DATE_SUCCESS,
  payload: data,
});

export const updateCountLinenCartsAndClosetsUpdateDeliveriesForGlobalAdjustOnDateFailure = (
  error,
) => ({
  type: CountLinenCartsAndClosetsTypes.UPDATE_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERIES_FOR_GLOBAL_ADJUSTMENT_ON_DATE_FAILURE,
  payload: error,
});

// GET ACTIVE HANDHELD NUMBER
export const getCountLinenCartsAndClosetsActiveHandheldNumbersStart = () => ({
  type: CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_ACTIVE_HANDHELD_NUMBERS_START,
  payload: null,
});

export const getCountLinenCartsAndClosetsActiveHandheldNumbersSuccess = (data) => ({
  type: CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_ACTIVE_HANDHELD_NUMBERS_SUCCESS,
  payload: data,
});

export const getCountLinenCartsAndClosetsActiveHandheldNumbersFailure = (error) => ({
  type: CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_ACTIVE_HANDHELD_NUMBERS_FAILURE,
  payload: error,
});

// SEND ACTIVE HANDHELD INFO
export const sendCountLinenCartsAndClosetsHandheldInfoStart = (data) => ({
  type: CountLinenCartsAndClosetsTypes.SEND_COUNT_LINEN_CARTS_AND_CLOSETS_HANDHELD_INFO_START,
  payload: data,
});

export const sendCountLinenCartsAndClosetsHandheldInfoSuccess = (data) => ({
  type: CountLinenCartsAndClosetsTypes.SEND_COUNT_LINEN_CARTS_AND_CLOSETS_HANDHELD_INFO_SUCCESS,
  payload: data,
});

export const sendCountLinenCartsAndClosetsHandheldInfoFailure = (error) => ({
  type: CountLinenCartsAndClosetsTypes.SEND_COUNT_LINEN_CARTS_AND_CLOSETS_HANDHELD_INFO_FAILURE,
  payload: error,
});

// UPDATE DELIVERY CENSUS
export const updateDeliveryCensusStart = (data) => ({
  type: CountLinenCartsAndClosetsTypes.UPDATE_DELIVERY_CENSUS_START,
  payload: data,
});

export const updateDeliveryCensusSuccess = (data) => ({
  type: CountLinenCartsAndClosetsTypes.UPDATE_DELIVERY_CENSUS_SUCCESS,
  payload: data,
});

export const updateDeliveryCensusFailure = (error) => ({
  type: CountLinenCartsAndClosetsTypes.UPDATE_DELIVERY_CENSUS_FAILURE,
  payload: error,
});

export const pickFormStart = (data) => ({
  type: CountLinenCartsAndClosetsTypes.PICK_FORM_START,
  payload: data,
});

export const pickFormSuccess = (data) => ({
  type: CountLinenCartsAndClosetsTypes.PICK_FORM_SUCCESS,
  payload: data,
});

export const pickFormFailure = (error) => ({
  type: CountLinenCartsAndClosetsTypes.PICK_FORM_FAILURE,
  payload: error,
});

/* #region Linen Room */
// LINEN ROOM CREATE DELIVERY FOR CUSTOMER ON DATE
export const createCountLinenLinenRoomDeliveryForCustomerOnDateStart = (data) => ({
  type: CountLinenCartsAndClosetsTypes.CREATE_COUNT_LINEN_LINEN_ROOM_DELIVERY_FOR_CUSTOMER_ON_DATE_START,
  payload: data,
});

export const createCountLinenLinenRoomDeliveryForCustomerOnDateSuccess = (data) => ({
  type: CountLinenCartsAndClosetsTypes.CREATE_COUNT_LINEN_LINEN_ROOM_DELIVERY_FOR_CUSTOMER_ON_DATE_SUCCESS,
  payload: data,
});

export const createCountLinenLinenRoomDeliveryForCustomerOnDateFailure = (error) => ({
  type: CountLinenCartsAndClosetsTypes.CREATE_COUNT_LINEN_LINEN_ROOM_DELIVERY_FOR_CUSTOMER_ON_DATE_FAILURE,
  payload: error,
});

// LINEN ROOM PICK FORM
export const linenRoomPickFormStart = (data) => ({
  type: CountLinenCartsAndClosetsTypes.LINEN_ROOM_PICK_FORM_START,
  payload: data,
});

export const linenRoomPickFormSuccess = (data) => ({
  type: CountLinenCartsAndClosetsTypes.LINEN_ROOM_PICK_FORM_SUCCESS,
  payload: data,
});

export const linenRoomPickFormFailure = (error) => ({
  type: CountLinenCartsAndClosetsTypes.LINEN_ROOM_PICK_FORM_FAILURE,
  payload: error,
});
/* #endregion */

export const setPickFormTypeStart = (data) => ({
  type: CountLinenCartsAndClosetsTypes.SET_PICK_FORM_TYPE,
  payload: data,
});

export const setPickFormIntervalStart = (data) => ({
  type: CountLinenCartsAndClosetsTypes.SET_PICK_FORM_INTERVAL,
  payload: data,
});

export const setSelectedRcID = (data) => ({
  type: CountLinenCartsAndClosetsTypes.SET_SELECTED_RCID,
  payload: data,
});

export const setSelectedDelvID = (data) => ({
  type: CountLinenCartsAndClosetsTypes.SET_SELECTED_DELVID,
  payload: data,
});

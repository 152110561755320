import { Box, FormField, Text, TextInput } from 'grommet';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { CircularProgress } from '@material-ui/core';
import { resetPasswordStart } from '@State/user/user.actions';
import { selectResetPassword } from '@State/user/user.selectors';
import { Page, PrimaryButton } from '@ThemeMain';

import { ResetPasswordForm } from './sign-in.styles';

const ResetPasswordPage = ({ resetPasswordStart, response }) => {
  const [username, setUsername] = useState('');
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    setShowLoader(false);
  }, [response]);

  const onSubmit = () => {
    setShowLoader(true);
    resetPasswordStart(username);
  };

  return (
    <>
      <Page flex={false} responsive={false} className="no-pad">
        <Box direction="column" fill="vertical" justify="center" pad="medium">
          <ResetPasswordForm heading="Reset Password" flex responsive>
            {response && !response.error ? (
              <Text
                alignSelf="center"
                textAlign="center"
                style={{ margin: '5px 0px', display: 'block', color: '#66bb6a' }}
              >
                {response.message}
              </Text>
            ) : (
              <>
                <Text
                  alignSelf="center"
                  textAlign="center"
                  style={{
                    margin: '5px 0px',
                    display: 'block',
                    color: response && response.error ? '#e53935' : 'inherit',
                  }}
                >
                  {response
                    ? response.message
                    : 'Password will be sent to the email address associated with your username'}
                </Text>
                <FormField>
                  <TextInput
                    name="username"
                    value={username}
                    placeholder="Username"
                    onChange={(e) => {
                      setUsername(e.target.value);
                    }}
                  />
                </FormField>
                <Box direction="row" justify="center" pad="small">
                  <PrimaryButton
                    type="submit"
                    label="Reset Password"
                    margin={{ left: 'xsmall' }}
                    alignSelf="center"
                    primary
                    onClick={() => onSubmit()}
                  />
                </Box>
              </>
            )}
          </ResetPasswordForm>
        </Box>
      </Page>
      {showLoader && (
        <Box className="loader-box">
          <CircularProgress className="loader" />
        </Box>
      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  response: selectResetPassword,
});

const mapDispatchToProps = (dispatch) => ({
  resetPasswordStart: (name) => dispatch(resetPasswordStart({ name })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);

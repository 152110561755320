import React, { useState, useEffect, useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { read, utils, writeFile } from 'xlsx';

import { selectCurrentCustomer } from '../../redux/user/user.selectors';
import Modal from '../modal/modal.component';
import { importProductsStart, importOrdersStart } from '../../redux/setup/setup.actions';

function processSheet(rows) {
  const sheetRows = [];

  // get index of last non empty element in the rows object
  let lastNonEmptyIndex = rows.length - 1;
  while (
    lastNonEmptyIndex >= 0 &&
    Object.values(rows[lastNonEmptyIndex]).every((val) => val === '')
  ) {
    lastNonEmptyIndex--;
  }

  // iterate through each element until the lastNonEmptyIndex so that extra blank rows are not included
  for (let index = 1; index <= lastNonEmptyIndex; index++) {
    const row = rows[index];
    const rowValues = Object.values(row).map((val) => val?.toString() ?? '');
    const tabDelimitedRow = rowValues.join('\t');
    sheetRows.push(tabDelimitedRow);
  }
  return sheetRows;
}

const UploadModal = ({ showModal, toggleModal, uploadType, currentCustomer }) => {
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState(null);
  const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(true);

  useEffect(() => {
    if (selectedFile) setConfirmButtonDisabled(false);
  }, [selectedFile]);

  const handleFileUpload = async (event) => {
    const buffer = await event.target.files[0].arrayBuffer();
    setSelectedFile(buffer);
  };

  const handleOnCancel = useCallback(() => {
    setSelectedFile(null);
    setConfirmButtonDisabled(true);
    toggleModal({ id: 'UploadModal' });
  }, [toggleModal]);

  const handleOnConfirm = useCallback(() => {
    if (selectedFile) {
      const workbook = read(selectedFile);

      const sheetsData = {
        OrderSheet: null,
        ProductSheet: null,
        customerRcID: currentCustomer.RcID,
      };

      workbook.SheetNames.forEach((sheetName) => {
        const rows = utils.sheet_to_json(workbook.Sheets[sheetName], {
          blankrows: true,
          defval: '',
          raw: true,
        });
        const handledSheetName = uploadType === 'products' ? 'ProductSheet' : sheetName;
        sheetsData[handledSheetName] = processSheet(rows).join('\n');
      });

      if (uploadType === 'products') {
        dispatch(importProductsStart(sheetsData));
      } else {
        dispatch(importOrdersStart(sheetsData));
      }
      toggleModal({ id: 'UploadModal' });
      setConfirmButtonDisabled(true);
    }
  }, [dispatch, selectedFile, toggleModal, uploadType]);

  return (
    <>
      <Modal
        show={showModal}
        setShow={toggleModal}
        title={'Upload'}
        onConfirm={handleOnConfirm}
        confirmLabel="Submit"
        onCancel={handleOnCancel}
        confirmDisabled={confirmButtonDisabled}
      >
        <input
          type="file"
          id="file-upload"
          accept=".xlsx, .xls"
          onChange={handleFileUpload}
          name="excelFile"
        />
      </Modal>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  currentCustomer: selectCurrentCustomer,
});

export default connect(mapStateToProps, null)(UploadModal);

import {
  Button,
  Heading,
  Layer,
  List,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from 'grommet';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { tableConfig } from '@Pages/setup/setup-config';
import { selectCurrentCustomer } from '@State/user/user.selectors';

const AddProductsModal = (props) => {
  const {
    rcID,
    showModal,
    toggleModal,
    type,
    pageTitle,
    data,
    method,
    pageID,
    addEvent,
    updateEvent,
  } = props;

  const [show, setShow] = useState(showModal);
  const [selectedRows, setSelectedRows] = useState([]);
  const [itemListProp, setItemListProp] = useState({});
  const [listData, setListData] = useState([]);

  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  useEffect(() => {
    getList(data);
  }, [data]);

  const getList = (rows) => {
    const rowData = [];
    const values = rows;

    if (!rowData.error) {
      for (const i in values) {
        if (pageID === 'orders') {
          const item = values[i];
          if (typeof item === 'object') {
            item.name = values[i].ItemDesc;
            item.id = values[i].ItemRcID;
          }
          rowData.push(item);
        } else if (pageID === 'packs' || pageID === 'procedures') {
          rowData.push({
            name: values[i].ProdDesc,
            id: values[i].RcID,
            RcID: values[i].RcID,
            PackRcID: values[i].PackRcID,
            ProdRcID: values[i].ProdRcID,
            ProdDesc: values[i].ProdDesc,
            Qnty: values[i].Qnty,
          });
        }
      }
    }
    setListData([...rowData]);
  };

  const updateSelected = (row, selected) => {
    const rows = selected;
    if (pageID === 'packs' || pageID === 'procedures') {
      rows.push({
        name: row.ProdDesc,
        id: row.RcID,
        RcID: row.RcID,
        PackRcID: row.PackRcID,
        ProdRcID: row.ProdRcID,
        ProdDesc: row.ProdDesc,
        Qnty: row.Qnty,
      });
    } else if (pageID === 'orders') {
      rows.push(row);
    }
    setSelectedRows(rows);
  };

  const onCtrlSelect = (row) => {
    updateSelected(row.item, selectedRows);

    const listProps = itemListProp;
    if (typeof listProps[row.index] === 'undefined') {
      listProps[row.index] = { background: 'brand' };
    } else {
      const temp = selectedRows;
      delete listProps[row.index];
      for (const i in temp) {
        if (temp[i].id === row.item.id) {
          delete temp[i];
        }
      }
      setSelectedRows(temp);
    }
    setItemListProp(listProps);
  };

  const onShiftSelect = (row) => {
    const selected = [];

    const listProps = {};
    let startIndex = 0;
    let endIndex = 0;
    const keys = Object.keys(itemListProp);
    const firstKey = keys.length > 0 ? keys[0] : 0;
    setItemListProp({});

    if (row.index > firstKey) {
      startIndex = keys[keys.length - 1];
      endIndex = row.index;
    } else {
      startIndex = row.index;
      endIndex = firstKey;
    }

    for (let i = startIndex; i <= endIndex; i++) {
      const temp = listData[i];
      temp.index = i;

      updateSelected(temp, selected);
      listProps[i] = { background: 'brand' };
    }

    setItemListProp(listProps);
  };

  const onDefaultSelect = (row) => {
    setSelectedRows([]);
    setItemListProp({});
    updateSelected(row.item, []);

    const listProps = {};
    if (typeof listProps[row.index] === 'undefined') {
      listProps[row.index] = { background: 'brand' };
      setItemListProp(listProps);
    }
  };

  const addSelectedRows = (row) => {
    if (row.shiftKey) {
      onShiftSelect(row);
    } else if (row.ctrlKey || row.metaKey) {
      onCtrlSelect(row);
    } else {
      onDefaultSelect(row);
    }
  };

  const onCancel = () => {
    toggleModal({ id: 'AddProductsModal' });
    setSelectedRows([]);
  };

  const onSubmit = () => {
    switch (method) {
      case 'Add':
        add();
        break;
      case 'Delete':
        remove();
        break;
    }
  };

  const remove = () => {
    const rows = [];
    if (selectedRows.length > 0) {
      selectedRows.map((item) => {
        const row = item;
        row.Usef = 0;
        rows.push(row);
      });

      if (pageID === 'packs' || pageID === 'procedures') {
        updateEvent({
          formData: {
            modifiedPackProductTable: rows,
          },
        });
      } else if (pageID === 'orders') {
        updateEvent({
          formData: {
            modifiedOrderItemTable: rows,
          },
        });
      }
      setSelectedRows([]);
      setItemListProp([]);
      toggleModal({ id: 'AddProductsModal', data: [] });
    }
  };

  const add = () => {
    if (selectedRows.length > 0) {
      selectedRows.map((item) => {
        if (pageID === 'packs' || pageID === 'procedures') {
          addEvent({
            [tableConfig[pageID].addProductKey]: rcID,
            iProductRcID: item.id,
          });
        } else if (pageID === 'orders') {
          addEvent({
            iOrderRcID: rcID,
            iItemRcID: item.id,
            iItemType: item.ItemType,
          });
        }
      });
      setSelectedRows([]);
      setItemListProp([]);
      toggleModal({ id: 'AddProductsModal', data: [] });
    }
  };
  return (
    <>
      {show && (
        <Layer onEsc={onCancel} onClickOutside={onCancel}>
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell colSpan="2">
                  <Heading level="4" textAlign="center" margin="auto">
                    {method} {type}
                  </Heading>
                </TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableCell colSpan="2">
                  New
                  {pageTitle}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan="2">Use CTRL/SHIFT+Click to select multiple items</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan="2">
                  <List
                    primaryKey="name"
                    data={listData}
                    style={{ height: '400px', overflowY: 'scroll' }}
                    itemProps={itemListProp}
                    step={1000}
                    onClickItem={(datum) => addSelectedRows(datum)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Button label={method} onClick={() => onSubmit()} />
                </TableCell>
                <TableCell>
                  <Button label="Cancel" onClick={() => onCancel()} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Layer>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = createStructuredSelector({
  currentCustomer: selectCurrentCustomer,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddProductsModal);

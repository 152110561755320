import { createSelector } from 'reselect';

const selectCountLinenCartsAndClosets = (state) => state.count_linen_carts_and_closets;

export const selectCountLinenCartsAndClosetsOrderSchedule = createSelector(
  [selectCountLinenCartsAndClosets],
  (countLinenCartsAndClosets) => countLinenCartsAndClosets.order_schedule,
);

export const selectCountLinenCartsAndClosetsOrderSchedulePrevious = createSelector(
  [selectCountLinenCartsAndClosets],
  (countLinenCartsAndClosets) => countLinenCartsAndClosets.order_schedule_previous,
);

export const selectCountLinenCartsAndClosetsOrdersNotScheduled = createSelector(
  [selectCountLinenCartsAndClosets],
  (countLinenCartsAndClosets) => countLinenCartsAndClosets.orders_not_scheduled,
);

export const selectCountLinenCartsAndClosetsDeliveryItemRights = createSelector(
  [selectCountLinenCartsAndClosets],
  (countLinenCartsAndClosets) => countLinenCartsAndClosets.delivery_item_rights,
);

export const selectCountLinenCartsAndClosetsDeliveryForDeliveryID = createSelector(
  [selectCountLinenCartsAndClosets],
  (countLinenCartsAndClosets) => countLinenCartsAndClosets.delivery_for_delivery_id,
);

export const selectCountLinenCartsAndClosetsDeliveryForDeliveryIDPrevious = createSelector(
  [selectCountLinenCartsAndClosets],
  (countLinenCartsAndClosets) => countLinenCartsAndClosets.delivery_for_delivery_id_previous,
);

export const selectCountLinenCartsAndClosetsDashboardGaugeInfo = createSelector(
  [selectCountLinenCartsAndClosets],
  (countLinenCartsAndClosets) => countLinenCartsAndClosets.dashboard_gauge_info,
);

export const selectCountLinenCartsAndClosetsUpdateDeliveryCountsReponse = createSelector(
  [selectCountLinenCartsAndClosets],
  (countLinenCartsAndClosets) => countLinenCartsAndClosets.update_delivery_counts_response,
);

export const selectCreateCountLinenCartsAndClosetsDeliveryForOrderOnDateReponse = createSelector(
  [selectCountLinenCartsAndClosets],
  (countLinenCartsAndClosets) =>
    countLinenCartsAndClosets.create_delivery_for_order_on_date_response,
);

export const selectCountLinenCartsAndClosetsItemsNotInDelivery = createSelector(
  [selectCountLinenCartsAndClosets],
  (countLinenCartsAndClosets) => countLinenCartsAndClosets.items_not_in_delivery,
);

export const selectCountLinenCartsAndClosetsAddItemsToDeliveryReponse = createSelector(
  [selectCountLinenCartsAndClosets],
  (countLinenCartsAndClosets) => countLinenCartsAndClosets.add_items_to_delivery_response,
);

export const selectCountLinenCartsAndClosetsDeliveriesForGlobalAdjustOnDate = createSelector(
  [selectCountLinenCartsAndClosets],
  (countLinenCartsAndClosets) => countLinenCartsAndClosets.deliveries_for_global_adjust_on_date,
);

export const selectCountLinenCartsAndClosetsUpdateDeliveriesForGlobalAdjustOnDateResponse =
  createSelector(
    [selectCountLinenCartsAndClosets],
    (countLinenCartsAndClosets) =>
      countLinenCartsAndClosets.update_deliveries_for_global_adjust_on_date_response,
  );

export const selectCountLinenCartsAndClosetsGetActiveHandheldNumbers = createSelector(
  [selectCountLinenCartsAndClosets],
  (countLinenCartsAndClosets) => countLinenCartsAndClosets.get_active_handheld_numbers,
);

export const selectCountLinenCartsAndClosetsSendHandheldInfoResponse = createSelector(
  [selectCountLinenCartsAndClosets],
  (countLinenCartsAndClosets) => countLinenCartsAndClosets.send_handheld_info_response,
);

export const selectUpdateDeliveryCensusResponse = createSelector(
  [selectCountLinenCartsAndClosets],
  (countLinenCartsAndClosets) => countLinenCartsAndClosets.update_delivery_census_response,
);

export const selectPickFormData = createSelector(
  [selectCountLinenCartsAndClosets],
  (countLinenCartsAndClosets) => countLinenCartsAndClosets.pick_form,
);

export const selectCountLinenLinenRoomDeliveryForCustomerOnDate = createSelector(
  [selectCountLinenCartsAndClosets],
  (countLinenCartsAndClosets) => countLinenCartsAndClosets.linen_room_delivery_for_customer_on_date,
);

export const selectLinenRoomPickFormData = createSelector(
  [selectCountLinenCartsAndClosets],
  (countLinenCartsAndClosets) => countLinenCartsAndClosets.linen_room_pick_form,
);

export const selectPickFormType = createSelector(
  [selectCountLinenCartsAndClosets],
  (countLinenCartsAndClosets) => countLinenCartsAndClosets.pick_form_type,
);

export const selectPickFormInterval = createSelector(
  [selectCountLinenCartsAndClosets],
  (countLinenCartsAndClosets) => countLinenCartsAndClosets.pick_form_interval,
);

export const selectRcID = createSelector(
  [selectCountLinenCartsAndClosets],
  (countLinenCartsAndClosets) => countLinenCartsAndClosets.selected_rcid,
);

export const selectDelvID = createSelector(
  [selectCountLinenCartsAndClosets],
  (countLinenCartsAndClosets) => countLinenCartsAndClosets.selected_delvid,
);

const CountLinenCartsAndClosetsTypes = {
  GET_COUNT_LINEN_CARTS_AND_CLOSETS_ORDER_SCHEDULE_START:
    'GET_COUNT_LINEN_CARTS_AND_CLOSETS_ORDER_SCHEDULE_START',
  GET_COUNT_LINEN_CARTS_AND_CLOSETS_ORDER_SCHEDULE_SUCCESS:
    'GET_COUNT_LINEN_CARTS_AND_CLOSETS_ORDER_SCHEDULE_SUCCESS',
  GET_COUNT_LINEN_CARTS_AND_CLOSETS_ORDER_SCHEDULE_FAILURE:
    'GET_COUNT_LINEN_CARTS_AND_CLOSETS_ORDER_SCHEDULE_FAILURE',

  GET_COUNT_LINEN_CARTS_AND_CLOSETS_ORDERS_NOT_SCHEDULED_START:
    'GET_COUNT_LINEN_CARTS_AND_CLOSETS_ORDERS_NOT_SCHEDULED_START',
  GET_COUNT_LINEN_CARTS_AND_CLOSETS_ORDERS_NOT_SCHEDULED_SUCCESS:
    'GET_COUNT_LINEN_CARTS_AND_CLOSETS_ORDERS_NOT_SCHEDULED_SUCCESS',
  GET_COUNT_LINEN_CARTS_AND_CLOSETS_ORDERS_NOT_SCHEDULED_FAILURE:
    'GET_COUNT_LINEN_CARTS_AND_CLOSETS_ORDERS_NOT_SCHEDULED_FAILURE',

  GET_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERY_ITEM_RIGHTS_START:
    'GET_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERY_ITEM_RIGHTS_START',
  GET_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERY_ITEM_RIGHTS_SUCCESS:
    'GET_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERY_ITEM_RIGHTS_SUCCESS',
  GET_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERY_ITEM_RIGHTS_FAILURE:
    'GET_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERY_ITEM_RIGHTS_FAILURE',
  GET_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERY_FOR_DELIVERY_ID_START:
    'GET_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERY_FOR_DELIVERY_ID_START',
  GET_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERY_FOR_DELIVERY_ID_SUCCESS:
    'GET_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERY_FOR_DELIVERY_ID_SUCCESS',
  GET_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERY_FOR_DELIVERY_ID_FAILURE:
    'GET_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERY_FOR_DELIVERY_ID_FAILURE',
  GET_COUNT_LINEN_CARTS_AND_CLOSETS_DASHBOARD_GAUGE_INFO_START:
    'GET_COUNT_LINEN_CARTS_AND_CLOSETS_DASHBOARD_GAUGE_INFO_START',
  GET_COUNT_LINEN_CARTS_AND_CLOSETS_DASHBOARD_GAUGE_INFO_SUCCESS:
    'GET_COUNT_LINEN_CARTS_AND_CLOSETS_DASHBOARD_GAUGE_INFO_SUCCESS',
  GET_COUNT_LINEN_CARTS_AND_CLOSETS_DASHBOARD_GAUGE_INFO_FAILURE:
    'GET_COUNT_LINEN_CARTS_AND_CLOSETS_DASHBOARD_GAUGE_INFO_FAILURE',
  GET_COUNT_LINEN_CARTS_AND_CLOSETS_UPDATE_DELIVERY_COUNTS_START:
    'GET_COUNT_LINEN_CARTS_AND_CLOSETS_UPDATE_DELIVERY_COUNTS_START',
  GET_COUNT_LINEN_CARTS_AND_CLOSETS_UPDATE_DELIVERY_COUNTS_SUCCESS:
    'GET_COUNT_LINEN_CARTS_AND_CLOSETS_UPDATE_DELIVERY_COUNTS_SUCCESS',
  GET_COUNT_LINEN_CARTS_AND_CLOSETS_UPDATE_DELIVERY_COUNTS_FAILURE:
    'GET_COUNT_LINEN_CARTS_AND_CLOSETS_UPDATE_DELIVERY_COUNTS_FAILURE',

  CREATE_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERY_FOR_ORDER_ON_DATE_START:
    'CREATE_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERY_FOR_ORDER_ON_DATE_START',
  CREATE_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERY_FOR_ORDER_ON_DATE_SUCCESS:
    'CREATE_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERY_FOR_ORDER_ON_DATE_SUCCESS',
  CREATE_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERY_FOR_ORDER_ON_DATE_FAILURE:
    'CREATE_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERY_FOR_ORDER_ON_DATE_FAILURE',

  GET_COUNT_LINEN_CARTS_AND_CLOSETS_GET_ITEMS_NOT_IN_DELIVERY_START:
    'GET_COUNT_LINEN_CARTS_AND_CLOSETS_GET_ITEMS_NOT_IN_DELIVERY_START',
  GET_COUNT_LINEN_CARTS_AND_CLOSETS_GET_ITEMS_NOT_IN_DELIVERY_SUCCESS:
    'GET_COUNT_LINEN_CARTS_AND_CLOSETS_GET_ITEMS_NOT_IN_DELIVERY_SUCCESS',
  GET_COUNT_LINEN_CARTS_AND_CLOSETS_GET_ITEMS_NOT_IN_DELIVERY_FAILURE:
    'GET_COUNT_LINEN_CARTS_AND_CLOSETS_GET_ITEMS_NOT_IN_DELIVERY_FAILURE',

  COUNT_LINEN_CARTS_AND_CLOSETS_ADD_ITEMS_TO_DELIVERY_START:
    'COUNT_LINEN_CARTS_AND_CLOSETS_ADD_ITEMS_TO_DELIVERY_START',
  COUNT_LINEN_CARTS_AND_CLOSETS_ADD_ITEMS_TO_DELIVERY_SUCCESS:
    'COUNT_LINEN_CARTS_AND_CLOSETS_ADD_ITEMS_TO_DELIVERY_SUCCESS',
  COUNT_LINEN_CARTS_AND_CLOSETS_ADD_ITEMS_TO_DELIVERY_FAILURE:
    'COUNT_LINEN_CARTS_AND_CLOSETS_ADD_ITEMS_TO_DELIVERY_FAILURE',

  GET_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERIES_FOR_GLOBAL_ADJUSTMENT_ON_DATE_START:
    'GET_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERIES_FOR_GLOBAL_ADJUSTMENT_ON_DATE_START',
  GET_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERIES_FOR_GLOBAL_ADJUSTMENT_ON_DATE_SUCCESS:
    'GET_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERIES_FOR_GLOBAL_ADJUSTMENT_ON_DATE_SUCCESS',
  GET_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERIES_FOR_GLOBAL_ADJUSTMENT_ON_DATE_FAILURE:
    'GET_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERIES_FOR_GLOBAL_ADJUSTMENT_ON_DATE_FAILURE',

  UPDATE_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERIES_FOR_GLOBAL_ADJUSTMENT_ON_DATE_START:
    'UPDATE_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERIES_FOR_GLOBAL_ADJUSTMENT_ON_DATE_START',
  UPDATE_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERIES_FOR_GLOBAL_ADJUSTMENT_ON_DATE_SUCCESS:
    'UPDATE_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERIES_FOR_GLOBAL_ADJUSTMENT_ON_DATE_SUCCESS',
  UPDATE_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERIES_FOR_GLOBAL_ADJUSTMENT_ON_DATE_FAILURE:
    'UPDATE_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERIES_FOR_GLOBAL_ADJUSTMENT_ON_DATE_FAILURE',

  GET_COUNT_LINEN_CARTS_AND_CLOSETS_ACTIVE_HANDHELD_NUMBERS_START:
    'GET_COUNT_LINEN_CARTS_AND_ACTIVE_HANDHELD_NUMBERS_START',
  GET_COUNT_LINEN_CARTS_AND_CLOSETS_ACTIVE_HANDHELD_NUMBERS_SUCCESS:
    'GET_COUNT_LINEN_CARTS_AND_ACTIVE_HANDHELD_NUMBERS_SUCCESS',
  GET_COUNT_LINEN_CARTS_AND_CLOSETS_ACTIVE_HANDHELD_NUMBERS_FAILURE:
    'GET_COUNT_LINEN_CARTS_AND_ACTIVE_HANDHELD_NUMBERS_FAILURE',

  SEND_COUNT_LINEN_CARTS_AND_CLOSETS_HANDHELD_INFO_START:
    'SEND_COUNT_LINEN_CARTS_AND_CLOSETS_HANDHELD_INFO_START',
  SEND_COUNT_LINEN_CARTS_AND_CLOSETS_HANDHELD_INFO_SUCCESS:
    'SEND_COUNT_LINEN_CARTS_AND_CLOSETS_HANDHELD_INFO_SUCCESS',
  SEND_COUNT_LINEN_CARTS_AND_CLOSETS_HANDHELD_INFO_FAILURE:
    'SEND_COUNT_LINEN_CARTS_AND_CLOSETS_HANDHELD_INFO_FAILURE',

  UPDATE_DELIVERY_CENSUS_START: 'UPDATE_DELIVERY_CENSUS_START',
  UPDATE_DELIVERY_CENSUS_SUCCESS: 'UPDATE_DELIVERY_CENSUS_SUCCESS',
  UPDATE_DELIVERY_CENSUS_FAILURE: 'UPDATE_DELIVERY_CENSUS_FAILURE',

  PICK_FORM_START: 'PICK_FORM_START',
  PICK_FORM_SUCCESS: 'PICK_FORM_SUCCESS',
  PICK_FORM_FAILURE: 'PICK_FORM_FAILURE',

  // LINEN ROOM
  CREATE_COUNT_LINEN_LINEN_ROOM_DELIVERY_FOR_CUSTOMER_ON_DATE_START:
    'CREATE_COUNT_LINEN_LINEN_ROOM_DELIVERY_FOR_CUSTOMER_ON_DATE_START',
  CREATE_COUNT_LINEN_LINEN_ROOM_DELIVERY_FOR_CUSTOMER_ON_DATE_SUCCESS:
    'CREATE_COUNT_LINEN_LINEN_ROOM_DELIVERY_FOR_CUSTOMER_ON_DATE_SUCCESS',
  CREATE_COUNT_LINEN_LINEN_ROOM_DELIVERY_FOR_CUSTOMER_ON_DATE_FAILURE:
    'CREATE_COUNT_LINEN_LINEN_ROOM_DELIVERY_FOR_CUSTOMER_ON_DATE_FAILURE',
  LINEN_ROOM_PICK_FORM_START: 'LINEN_ROOM_PICK_FORM_START',
  LINEN_ROOM_PICK_FORM_SUCCESS: 'LINEN_ROOM_PICK_FORM_SUCCESS',
  LINEN_ROOM_PICK_FORM_FAILURE: 'LINEN_ROOM_PICK_FORM_FAILURE',

  SET_PICK_FORM_TYPE: 'SET_PICK_FORM_TYPE',
  SET_PICK_FORM_INTERVAL: 'SET_PICK_FORM_INTERVAL',

  SET_SELECTED_RCID: 'SET_SELECTED_RCID',
  SET_SELECTED_DELVID: 'SET_SELECTED_DELVID',
};

export default CountLinenCartsAndClosetsTypes;

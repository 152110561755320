import { Box, FormField, Select } from 'grommet';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import { setCurrentAdvancedUser } from '@State/setup-advanced-users/setup-advanced-users.actions';
import { selectAdvancedCurrentUsers } from '@State/setup-advanced-users/setup-advanced-users.selectors';
import { getDetailsForOrderSelectedSuccess } from '@State/setup-users-details-for-order-selected-in-user/setup-users-details-for-order-selected-in-user.actions';
import { selectDetailsForOrderSelected } from '@State/setup-users-details-for-order-selected-in-user/setup-users-details-for-order-selected-in-user.selectors';
import { selectLeadDayValues } from '@State/setup-users-lead-day-values/setup-users-lead-day-values.selectors';
import { selectCurrentUser } from '@State/user/user.selectors';

const DaySchedules = (props) => {
  const {
    leadDayValues,
    setCurrentUser,
    setDetailsForOrderSelected,
    evaluateChange,
    setShowSaveCancelBtn,
  } = props;
  const [leadDayOptions, setleadDayOptions] = useState({});

  useEffect(() => {
    if (leadDayValues.length > 0) {
      setleadDayOptions(formatOptions([...leadDayValues]));
    }
  }, [leadDayValues]);

  const formatOptions = (data) => {
    const options = {};
    data.map((item) => {
      options[item.RcID] = item.Desc;
    });

    return options;
  };

  const handleChange = (key, value) => {
    switch (props.type) {
      case 'handHeld':
        evaluateChange({ new: value, key });
        setCurrentUser({ ...props.advancedCurrentUser, [key]: value });
        break;
      case 'orderDetails':
        setDetailsForOrderSelected({ ...props.detailsForOrderSelected, [key]: value });
        setShowSaveCancelBtn(false);
        break;
    }
  };

  return (
    <Box alignSelf="start" direction="row">
      {props.data.map((item) => (
        <FormField
          key={item.data['0'].key}
          margin="small"
          label={item.day}
          htmlFor={item.data['0'].key}
          {...props}
        >
          {(() => {
            switch (props.type) {
              case 'handHeld':
                return (
                  <Select
                    key={item.data['0'].key}
                    id={item.data['0'].key}
                    value={leadDayOptions[props.advancedCurrentUser[item.data['0'].key]]}
                    valueKey="RcID"
                    labelKey="Desc"
                    options={leadDayValues}
                    onChange={({ option }) => handleChange(item.data['0'].key, option.RcID)}
                  />
                );
              case 'orderDetails':
                return (
                  <Select
                    key={item.data['0'].key}
                    id={item.data['0'].key}
                    value={props.detailsForOrderSelected[item.data['0'].key]}
                    valueKey="RcID"
                    labelKey="Desc"
                    options={leadDayValues}
                    valueLabel={
                      <Box width="medium" pad="xsmall">
                        {leadDayValues
                          ? leadDayValues[props.detailsForOrderSelected[item.data['0'].key]].Desc
                          : null}
                      </Box>
                    }
                    onChange={({ option }) => handleChange(item.data['0'].key, option.RcID)}
                  />
                );
              default:
                return null;
            }
          })()}
        </FormField>
      ))}
    </Box>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  leadDayValues: selectLeadDayValues,
  advancedCurrentUser: selectAdvancedCurrentUsers,
  detailsForOrderSelected: selectDetailsForOrderSelected,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentAdvancedUser(user)),
  setDetailsForOrderSelected: (order) => dispatch(getDetailsForOrderSelectedSuccess(order)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DaySchedules));

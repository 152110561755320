export const mimeTypes = {
  pdf: 'application/pdf',
  xls: 'application/msexcel',
  txt: 'text/plain',
  png: 'image/png',
  jpg: 'image/jpeg',
  gif: 'image/gif',
};

const prepareDataOptions = (inputOptions) => {
  const options = {
    name: 'download',
    type: 'txt',
    ...inputOptions,
  };

  if (!options.mimeType) options.mimeType = mimeTypes[options.type];
  if (!options.filename) options.filename = `${options.name}.${options.type}`;

  if (!options.url) {
    if (options.base64Data) {
      options.url = encodeURI(`data:${options.mimeType};base64,${options.base64Data}`);
    } else {
      let blob = options.blob || null;

      if (options.data) {
        blob = new Blob([options.data], {
          type: options.mimeType,
        });
      }

      if (blob) {
        options.url = URL.createObjectURL(blob);
      }
    }
  }

  return options;
};

export const download = (inputOptions) => {
  const options = prepareDataOptions(inputOptions);

  const link = document.createElement('a');
  link.href = options.url;
  link.target = options.target || '_blank';
  link.download = options.filename;
  link.dispatchEvent(new MouseEvent('click'));
};

export const openData = (inputOptions) => {
  const options = prepareDataOptions(inputOptions);

  const iframe = `<html><body style="margin:0;"><iframe title='test' width='100%' height='100%' margin="0" src='${options.url}'></iframe></body></html>`;
  const x = window.open();
  x.document.open();
  x.document.write(iframe);
  x.document.close();
};

export default download;

import InventoryTypes from './inventory.types';

// INJECTION
export const createInjectionForCustomerOnDateStart = (data) => ({
  type: InventoryTypes.CREATE_INJECTION_FOR_CUSTOMER_ON_DATE_START,
  payload: data,
});

export const createInjectionForCustomerOnDateSuccess = (data) => ({
  type: InventoryTypes.CREATE_INJECTION_FOR_CUSTOMER_ON_DATE_SUCCESS,
  payload: data,
});

export const createInjectionForCustomerOnDateFailure = (error) => ({
  type: InventoryTypes.CREATE_INJECTION_FOR_CUSTOMER_ON_DATE_FAILURE,
  payload: error,
});

// RETIREMENT
export const createRetirementForCustomerOnDateStart = (data) => ({
  type: InventoryTypes.CREATE_RETIREMENT_FOR_CUSTOMER_ON_DATE_START,
  payload: data,
});

export const createRetirementForCustomerOnDateSuccess = (data) => ({
  type: InventoryTypes.CREATE_RETIREMENT_FOR_CUSTOMER_ON_DATE_SUCCESS,
  payload: data,
});

export const createRetirementForCustomerOnDateFailure = (error) => ({
  type: InventoryTypes.CREATE_RETIREMENT_FOR_CUSTOMER_ON_DATE_FAILURE,
  payload: error,
});

// COMMON
export const getInventoryForInventoryIDStart = (data) => ({
  type: InventoryTypes.GET_INVENTORY_FOR_INVENTORY_ID_START,
  payload: data,
});

export const getInventoryForInventoryIDSuccess = (data) => ({
  type: InventoryTypes.GET_INVENTORY_FOR_INVENTORY_ID_SUCCESS,
  payload: data,
});

export const getInventoryForInventoryIDFailure = (error) => ({
  type: InventoryTypes.GET_INVENTORY_FOR_INVENTORY_ID_FAILURE,
  payload: error,
});

export const getItemsNotInInventoryStart = (data) => ({
  type: InventoryTypes.GET_ITEMS_NOT_IN_INVENTORY_START,
  payload: data,
});

export const getItemsNotInInventorySuccess = (data) => ({
  type: InventoryTypes.GET_ITEMS_NOT_IN_INVENTORY_SUCCESS,
  payload: data,
});

export const getItemsNotInInventoryFailure = (error) => ({
  type: InventoryTypes.GET_ITEMS_NOT_IN_INVENTORY_FAILURE,
  payload: error,
});

export const getOrderScheduleForCustomerOnDateStart = (data) => ({
  type: InventoryTypes.GET_ORDER_SCHEDULE_FOR_CUSTOMER_ON_DATE_START,
  payload: data,
});

export const getOrderScheduleForCustomerOnDateSuccess = (data) => ({
  type: InventoryTypes.GET_ORDER_SCHEDULE_FOR_CUSTOMER_ON_DATE_SUCCESS,
  payload: data,
});

export const getOrderScheduleForCustomerOnDateFailure = (error) => ({
  type: InventoryTypes.GET_ORDER_SCHEDULE_FOR_CUSTOMER_ON_DATE_FAILURE,
  payload: error,
});

export const getInventoryFormPDFStart = (data) => ({
  type: InventoryTypes.GET_INVENTORY_FORM_PDF_START,
  payload: data,
});

export const getInventoryFormPDFSuccess = (data) => ({
  type: InventoryTypes.GET_INVENTORY_FORM_PDF_SUCCESS,
  payload: data,
});

export const getInventoryFormPDFFailure = (error) => ({
  type: InventoryTypes.GET_INVENTORY_FORM_PDF_FAILURE,
  payload: error,
});

export const updateInventoryCountsStart = (data) => ({
  type: InventoryTypes.UPDATE_INVENTORY_COUNTS_START,
  payload: data,
});

export const updateInventoryCountsSuccess = (data) => ({
  type: InventoryTypes.UPDATE_INVENTORY_COUNTS_SUCCESS,
  payload: data,
});

export const updateInventoryCountsFailure = (error) => ({
  type: InventoryTypes.UPDATE_INVENTORY_COUNTS_FAILURE,
  payload: error,
});

export const addItemToInventoryStart = (data) => ({
  type: InventoryTypes.ADD_ITEM_TO_INVENTORY_START,
  payload: data,
});

export const addItemToInventorySuccess = (data) => ({
  type: InventoryTypes.ADD_ITEM_TO_INVENTORY_SUCCESS,
  payload: data,
});

export const addItemToInventoryFailure = (error) => ({
  type: InventoryTypes.ADD_ITEM_TO_INVENTORY_FAILURE,
  payload: error,
});

export const createInventoryForOrderOnDateStart = (data) => ({
  type: InventoryTypes.CREATE_INVENTORY_FOR_ORDER_ON_DATE_START,
  payload: data,
});

export const createInventoryForOrderOnDateSuccess = (data) => ({
  type: InventoryTypes.CREATE_INVENTORY_FOR_ORDER_ON_DATE_SUCCESS,
  payload: data,
});

export const createInventoryForOrderOnDateFailure = (error) => ({
  type: InventoryTypes.CREATE_INVENTORY_FOR_ORDER_ON_DATE_FAILURE,
  payload: error,
});

export const setSelectedRcID = (data) => ({
  type: InventoryTypes.SET_SELECTED_RCID,
  payload: data,
});

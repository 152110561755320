import 'react-datepicker/dist/react-datepicker.css';

import { Box } from 'grommet';
import { CaretDown, CaretNext } from 'grommet-icons';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import TreeMenu from 'react-simple-tree-menu';
import { createStructuredSelector } from 'reselect';

import { selectCurrentCustomer } from '@State/user/user.selectors';

const TreeSelection = (props) => {
  const {
    pageID,
    formData,
    selectionTree,
    shouldResetTree,
    getSelectionTree,
    setFormData,
    setShouldResetTree,
    getData,
    getDataOnNodeClick,
  } = props;

  const DEFAULT_PADDING = 16;
  const ICON_SIZE = 8;
  const LEVEL_SPACE = 16;
  const [treeData, setTreeData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [selectedParentID, setSelectedParentID] = useState(0);
  const [openNodes, setOpenNodes] = useState([]);
  const initialSelection = {
    type0: 0,
    type1: 0,
    type2: 0,
    type3: 0,
    type4: 0,
    type5: 0,
    type6: 0,
    type7: 0,
  };
  const ListItem = ({
    level = 0,
    hasNodes,
    isOpen,
    label,
    openNodes,
    toggleNode,
    focused,
    ...props
  }) => (
    <Box
      {...props}
      style={{
        paddingLeft: DEFAULT_PADDING + ICON_SIZE + level * LEVEL_SPACE,
        cursor: 'pointer',
        boxShadow: focused ? '0px 0px 5px 0px #222' : 'none',
        zIndex: focused ? 999 : 'unset',
        position: 'relative',
      }}
      onClick={(e) => {
        e.target.tagName === 'DIV' && onNodeClick(e, { ...props });
      }}
    >
      {hasNodes && (
        <div
          style={{ display: 'inline-block' }}
          onClick={(e) => {
            hasNodes &&
              toggleNode &&
              (e.target.tagName === 'svg' || e.target.tagName === 'polygon') &&
              toggleItem(isOpen, { ...props }, label) &&
              toggleNode();
            e.preventDefault();
          }}
        >
          {label !== 'Not Available' &&
            (props.hasChildren || props.hasChildren === null) &&
            (isOpen ? <CaretDown className="toggleIcon" /> : <CaretNext className="toggleIcon" />)}
          {label}
        </div>
      )}
    </Box>
  );

  useEffect(() => {
    if (selectionTree !== null && treeData.length > 0) {
      if (selectedParentID === 0) {
        setInitialData(selectionTree);
      } else {
        refreshTree(selectionTree);
      }
    } else if (selectionTree !== null) {
      setInitialData(selectionTree);
    }
  }, [selectionTree]);

  const refreshTree = (data) => {
    if (shouldResetTree) {
      setOpenNodes([]);
      setShouldResetTree(false);
    }
    setTreeData(formatTreeData(data));
  };

  const setInitialData = (data) => {
    const tree = formatTreeData(data);
    setTreeData(tree);
    setOriginalData(data);
    setShouldResetTree(false);
  };

  const updateSelectionData = (prop, value, obj) => {
    obj.constructor === Object &&
      Object.keys(obj).forEach((key) => {
        if (key === prop) {
          obj[key].rows = value;
        } else if (typeof obj[key].rows !== 'undefined') {
          updateSelectionData(prop, value, obj[key].rows);
        }
      });
  };

  const formatTreeData = (data, path = 'pId-0') => {
    const result = [];
    const { rows } = data;
    let item = {};
    let rowData = {};
    let nodePath = '';
    for (const i in rows) {
      nodePath = rows[i].parentID === '0' ? 'pId-0' : path;
      nodePath = rows[i].parentID !== '' ? `${path}/${i}` : path;

      rowData = rows[i].data;
      item = {
        key: i,
        id: i,
        path: nodePath,
        index: rows[i].RcID,
        hasChildren: rows[i].hasOwnProperty('hasChildren') ? rows[i].hasChildren : null,
        label: rowData.hasOwnProperty('Description') ? rowData.Description : rowData.ObjtDesc,
        hasRows: Object.keys(rows[i].rows).length > 0,
        hasNodes: true,
        clickable: i.split('-').length <= 9,
        nodes:
          Object.keys(rows[i].rows).length > 0
            ? formatTreeData(rows[i], nodePath)
            : [{ key: 'temp', label: '' }],
      };
      result.push(item);
    }
    return result;
  };

  const evaluateSelection = (itemProps) => {
    const selections = formData.selection;
    const indexes = itemProps.id.replace('pId-', '');
    const ids =
      indexes.length > 0 ? indexes.split('-') : itemProps.index !== '0' ? [itemProps.index] : [];

    for (let i = 0; i < Object.keys(selections).length; i++) {
      if (i < ids.length) {
        selections[`type${i}`] = ids[i].replace('pId-').replace('/');
      } else {
        selections[`type${i}`] = 0;
      }
    }
    setFormData({ ...formData, selection: selections });
  };

  const evaluateOpenNodes = (nodes, currentPath) => {
    const newOpenNodes = [];
    let items = [];
    nodes.map((node) => {
      items = node.split('/');
      if (currentPath.indexOf(items[items.length - 1]) >= 0) {
        newOpenNodes.push(node);
      }
    });

    return newOpenNodes;
  };

  const onNodeClick = (e, itemProps) => {
    if (typeof e.target.className !== 'object' && itemProps.index !== '0') {
      evaluateSelection(itemProps);
      getData(formData);
    }
  };

  const toggleItem = (isOpen, itemProps, label) => {
    let prevOpenNodes = JSON.parse(JSON.stringify(openNodes));
    prevOpenNodes = evaluateOpenNodes(prevOpenNodes, itemProps.path);
    if (!prevOpenNodes.includes(itemProps.path)) {
      setOpenNodes(prevOpenNodes.concat([itemProps.path]));
    } else {
      prevOpenNodes.splice(prevOpenNodes.indexOf(itemProps.path), 1);
      setOpenNodes(prevOpenNodes);
    }

    evaluateSelection(itemProps);
    if (
      itemProps.id !== 'pId-0' &&
      !itemProps.hasRows &&
      !isOpen &&
      itemProps.clickable &&
      itemProps.parentID !== '0'
    ) {
      setSelectedParentID(itemProps.id);
      getSelectionTree(formData);
    }

    if (getDataOnNodeClick && itemProps.parentID !== '0') {
      getData(formData);
    }

    return true;
  };

  return (
    <Box fill="horizontal">
      <TreeMenu data={treeData} openNodes={openNodes} hasSearch={false}>
        {({ items }) => (
          <>
            {items.map(({ key, ...props }) => {
              return <ListItem key={key} {...props} />;
            })}
          </>
        )}
      </TreeMenu>
    </Box>
  );
};

const mapStateToProps = createStructuredSelector({
  currentCustomer: selectCurrentCustomer,
});
export default connect(mapStateToProps, null)(TreeSelection);

export const userPermissions = (userPrivileges) => {
  if (userPrivileges && userPrivileges.can) {
    return {
      // General Privileges

      canViewCustomers: userPrivileges.can('view', 'General_Customers'),
      canViewSelectCustomer: userPrivileges.can('view', 'General_Customers'),

      // Setup-Related Privileges
      canViewSetup:
        userPrivileges.can('view', 'General_Accounts') ||
        userPrivileges.can('view', 'General_Departments') ||
        userPrivileges.can('view', 'General_Products') ||
        userPrivileges.can('view', 'General_Packs') ||
        userPrivileges.can('view', 'General_Procedures') ||
        userPrivileges.can('view', 'General_Orders'),

      canViewAccounts: userPrivileges.can('view', 'General_Accounts'),
      canModifyAccounts: userPrivileges.can('modify', 'General_Accounts'),

      canViewDepartments: userPrivileges.can('view', 'General_Departments'),
      canModifyDepartments: userPrivileges.can('modify', 'General_Departments'),

      canViewProducts: userPrivileges.can('view', 'General_Products'),
      canModifyProducts: userPrivileges.can('modify', 'General_Products'),

      canViewPacks: userPrivileges.can('view', 'General_Packs'),
      canModifyPacks: userPrivileges.can('modify', 'General_Packs'),

      canViewProcedures: userPrivileges.can('view', 'General_Procedures'),
      canModifyProcedures: userPrivileges.can('modify', 'General_Procedures'),

      canViewOrders: userPrivileges.can('view', 'General_Orders'),
      canModifyOrders: userPrivileges.can('modify', 'General_Orders'),

      // Reports
      canViewReports: userPrivileges.can('view', 'General_CreateReports'),

      // Set Budget
      canViewSetBudget: userPrivileges.can('view', 'General_Budget'),
      canModifySetBudget: userPrivileges.can('modify', 'General_Budget'),

      // Set Census
      canViewSetCensus: userPrivileges.can('view', 'General_Census'),
      canModifySetCensus: userPrivileges.can('modify', 'General_Census'),

      // Count Linen
      canViewCountLinen:
        userPrivileges.can('view', 'General_CountLinenOrders') ||
        userPrivileges.can('modify', 'General_CountLinenRoom'),

      canViewCountLinenLinenRoom: userPrivileges.can('view', 'General_CountLinenRoom'),
      canModifyCountLinenLinenRoom: userPrivileges.can('modify', 'General_CountLinenRoom'),

      canViewCountLinenOrders: userPrivileges.can('view', 'General_CountLinenOrders'),
      canModifyCountLinenOrders: userPrivileges.can('modify', 'General_CountLinenOrders'),

      // Inventory
      canViewCountInventory: userPrivileges.can('view', 'General_CountInventory'),
      canModifyCountInventory: userPrivileges.can('modify', 'General_CountInventory'),

      // Count Linen Default Privileges
      canViewCountLinenPar: userPrivileges.can('view', 'CountLinen_Par'),
      canModifyCountLinenPar: userPrivileges.can('modify', 'CountLinen_Par'),

      canViewCountLinenOnHand: userPrivileges.can('view', 'CountLinen_OnHand'),
      canModifyCountLinenOnHand: userPrivileges.can('modify', 'CountLinen_OnHand'),

      canViewCountLinenRequired: userPrivileges.can('view', 'CountLinen_Required'),
      canModifyCountLinenRequired: userPrivileges.can('modify', 'CountLinen_Required'),

      canViewCountLinenStocked: userPrivileges.can('view', 'CountLinen_Stocked'),
      canModifyCountLinenStocked: userPrivileges.can('modify', 'CountLinen_Stocked'),

      canViewCountLinenEmergency: userPrivileges.can('view', 'CountLinen_Emergency'),
      canModifyCountLinenEmergency: userPrivileges.can('modify', 'CountLinen_Emergency'),

      canViewCountLinenReturned: userPrivileges.can('view', 'CountLinen_Returned'),
      canModifyCountLinenReturned: userPrivileges.can('modify', 'CountLinen_Returned'),

      canViewCountLinenAddNewOrders: userPrivileges.can('view', 'CountLinen_AddNewOrders'),
      canViewCountLinenAddNewItems: userPrivileges.can('view', 'CountLinen_AddNewItems'),
      canViewCountLinenGlobalAdjustments: userPrivileges.can(
        'view',
        'CountLinen_GlobalAdjustments',
      ),
      canViewCountLinenAutoStock: userPrivileges.can('view', 'CountLinen_AutoStock'),

      // Admin Privileges
      isAdminUser: userPrivileges.can('view', 'Advanced_IsAdminUser'),
      canModifyCustomers: userPrivileges.can('view', 'Advanced_CanModifyCustomers'),
      canSelectEveryCustomer: userPrivileges.can('view', 'Advanced_CanSelectEveryCustomer'),
      canSelectEveryOrder: userPrivileges.can('view', 'Advanced_CanSelectEveryOrder'),
      canCountLinenAllDates: userPrivileges.can('view', 'Advanced_CanCountLinenAllDates'),
    };
  }
  return {};
};

export default userPermissions;

import SetupUsersCustomerSelectedActionTypes from './setup-users-customer-selected-in-user.types';

export const getCustomerSelectedStart = () => ({
  type: SetupUsersCustomerSelectedActionTypes.GET_CUSTOMER_SELECTED_START,
  payload: null,
});

export const getCustomerSelectedSuccess = (customerSelected) => ({
  type: SetupUsersCustomerSelectedActionTypes.GET_CUSTOMER_SELECTED_SUCCESS,
  payload: customerSelected,
});

export const getCustomerSelectedFailure = (error) => ({
  type: SetupUsersCustomerSelectedActionTypes.GET_CUSTOMER_SELECTED_FAILURE,
  payload: error,
});

export const saveCustomerSelectedStart = (customerSave) => ({
  type: SetupUsersCustomerSelectedActionTypes.SAVE_CUSTOMER_SELECTED_START,
  payload: customerSave,
});

export const saveCustomerSelectedSuccess = () => ({
  type: SetupUsersCustomerSelectedActionTypes.SAVE_CUSTOMER_SELECTED_SUCCESS,
  payload: null,
});

export const saveCustomerSelectedFailure = (error) => ({
  type: SetupUsersCustomerSelectedActionTypes.SAVE_CUSTOMER_SELECTED_FAILURE,
  payload: error,
});

export const deleteCustomerSelectedStart = (customerDelete) => ({
  type: SetupUsersCustomerSelectedActionTypes.DELETE_CUSTOMER_SELECTED_START,
  payload: customerDelete,
});

export const deleteCustomerSelectedSuccess = () => ({
  type: SetupUsersCustomerSelectedActionTypes.DELETE_CUSTOMER_SELECTED_SUCCESS,
  payload: null,
});

export const deleteCustomerSelectedFailure = (error) => ({
  type: SetupUsersCustomerSelectedActionTypes.DELETE_CUSTOMER_SELECTED_FAILURE,
  payload: error,
});

export const addCustomerToDeleteQueueStart = (customerToDelete) => ({
  type: SetupUsersCustomerSelectedActionTypes.ADD_CUSTOMER_TO_DELETE_QUEUE_START,
  payload: customerToDelete,
});

export const addCustomerToDeleteQueueSuccess = (customerToDelete) => ({
  type: SetupUsersCustomerSelectedActionTypes.ADD_CUSTOMER_TO_DELETE_QUEUE_SUCCESS,
  payload: customerToDelete,
});

export const addCustomerToDeleteQueueFailure = (error) => ({
  type: SetupUsersCustomerSelectedActionTypes.ADD_CUSTOMER_TO_DELETE_QUEUE_FAILURE,
  payload: error,
});

export const addCustomerToAddQueueStart = (customerToAdd) => ({
  type: SetupUsersCustomerSelectedActionTypes.ADD_CUSTOMER_TO_ADD_QUEUE_START,
  payload: customerToAdd,
});

export const addCustomerToAddQueueSuccess = (customerToAdd) => ({
  type: SetupUsersCustomerSelectedActionTypes.ADD_CUSTOMER_TO_ADD_QUEUE_SUCCESS,
  payload: customerToAdd,
});

export const addCustomerToAddQueueFailure = (error) => ({
  type: SetupUsersCustomerSelectedActionTypes.ADD_CUSTOMER_TO_ADD_QUEUE_FAILURE,
  payload: error,
});

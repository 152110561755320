import { createSelector } from 'reselect';

const selectOrderSelected = (state) => state.setup_advanced_user_order_selected;

export const selectOrdersSelected = createSelector(
  [selectOrderSelected],
  (orderSelected) => orderSelected.order_selected,
);

export const selectCurrentOrdersSelected = createSelector(
  [selectOrderSelected],
  (currentorder) => currentorder.current_order_selected,
);

export const selectOrdersToAdd = createSelector(
  [selectOrderSelected],
  (orderSelected) => orderSelected.orders_to_add,
);

export const selectOrdersToDelete = createSelector(
  [selectOrderSelected],
  (orderSelected) => orderSelected.orders_to_delete,
);

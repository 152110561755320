import { Box, Form, FormField, Select, TextInput } from 'grommet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import { setupUserSelections } from '@Pages/setup-users/setup-users.config';
import { setCurrentAdvancedUser } from '@State/setup-advanced-users/setup-advanced-users.actions';
import { selectAdvancedCurrentUsers } from '@State/setup-advanced-users/setup-advanced-users.selectors';
import { selectCurrentUser } from '@State/user/user.selectors';

const GeneralTab = (props) => {
  const handleChange = (event) => {
    const oneOrMultipleWhiteSpaces = /\s+/g;
    const { value, name } = event.target;
    let val = typeof event.option !== 'undefined' ? event.option : value;
    val = name === 'Name' ? val.trimStart().replace(oneOrMultipleWhiteSpaces, ' ') : val;
    const currentadvancedUser = props.advancedCurrentUser;
    props.setCurrentUser({ ...currentadvancedUser, [name]: val });
    props.evaluateChange({
      prev: props.currentUser[name],
      new: val,
      key: name,
    });
  };

  return (
    <Box pad="small">
      <Form
        onReset={(event) => console.log(event)}
        onSubmit={({ value }) => console.log('Submit', value)}
      >
        <FormField label="Name" htmlFor="Name" {...props}>
          <TextInput
            id="Name"
            name="Name"
            placeholder="New User Name"
            maxLength="10" // TODO: More robust means of limiting input length
            value={props.advancedCurrentUser.Name}
            onChange={handleChange}
          />
        </FormField>
        <FormField label="Password" htmlFor="Pswd" {...props}>
          <TextInput
            id="Pswd"
            name="Pswd"
            type="password"
            value={props.advancedCurrentUser.Pswd}
            onChange={handleChange}
          />
        </FormField>
        <FormField label="Email" htmlFor="EmalAddr" {...props}>
          <TextInput
            id="EmalAddr"
            name="EmalAddr"
            value={props.advancedCurrentUser.EmalAddr}
            onChange={handleChange}
          />
        </FormField>
        <FormField label="Description" htmlFor="UserDesc" {...props}>
          <TextInput
            id="UserDesc"
            name="UserDesc"
            value={props.advancedCurrentUser.UserDesc}
            onChange={handleChange}
          />
        </FormField>
        <FormField label="Home Map" htmlFor="HomePageName" {...props}>
          <Select
            id="HomePageName"
            name="HomePageName"
            options={setupUserSelections.homePageName}
            value={props.advancedCurrentUser.HomePageName}
            onChange={handleChange}
          />
        </FormField>
        <FormField label="GUID" htmlFor="Guid" {...props}>
          <TextInput
            id="Guid"
            name="Guid"
            value={props.advancedCurrentUser.Guid}
            disabled
            onChange={handleChange}
          />
        </FormField>
      </Form>
    </Box>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  advancedCurrentUser: selectAdvancedCurrentUsers,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentAdvancedUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GeneralTab));

import { Box, Form, FormField, Heading, Select, TextInput } from 'grommet';
import { Clock } from 'grommet-icons';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import DaySchedules from '@Components/setup-users-day-schedule/setup-users-day-schedule.component';
import { TimeSelect } from '@Pages/setup-users/setup-users.styles';
import { setCurrentAdvancedUser } from '@State/setup-advanced-users/setup-advanced-users.actions';
import { selectAdvancedCurrentUsers } from '@State/setup-advanced-users/setup-advanced-users.selectors';
import { selectHandHeldNumbers } from '@State/setup-users-hand-held-numbers/setup-users-hand-held-numbers.selectors';
import { selectCurrentUser } from '@State/user/user.selectors';

const SetupUsersHandHeldTab = (props) => {
  const dayDropdown = [
    { day: 'Sun', data: [{ key: 'HhldIntvSund', desc: 'HhldIntvSundDesc' }] },
    { day: 'Mon', data: [{ key: 'HhldIntvMond', desc: 'HhldIntvMondDesc' }] },
    { day: 'Tue', data: [{ key: 'HhldIntvTues', desc: 'HhldIntvTuesDesc' }] },
    { day: 'Wed', data: [{ key: 'HhldIntvWedn', desc: 'HhldIntvWednDesc' }] },
    { day: 'Thu', data: [{ key: 'HhldIntvThur', desc: 'HhldIntvThurDesc' }] },
    { day: 'Fri', data: [{ key: 'HhldIntvFrid', desc: 'HhldIntvFridDesc' }] },
    { day: 'Sat', data: [{ key: 'HhldIntvSatr', desc: 'HhldIntvSatrDesc' }] },
  ];

  const {
    handHeldNumbers,
    setCurrentUser,
    timeSelection,
    minutesToTime,
    timeToMinutes,
    formatOptions,
    evaluateChange,
  } = props;
  const [handHeldOptions, setHandHeldOptions] = useState({});

  useEffect(() => {
    setHandHeldOptions(formatOptions(handHeldNumbers));
  }, [handHeldNumbers]);

  const handleInputChange = (event) => {
    const { name } = event.target;
    let { value } = event.target;
    if (event.target.type === 'time') {
      value = timeToMinutes(value, '24');
      evaluateChange({ new: value, key: name });
      setCurrentUser({ ...props.advancedCurrentUser, [name]: value });
    }
  };

  const handleSelectChange = (option, key, keyDesc) => {
    const data = { ...props.advancedCurrentUser };

    if (key === 'HhldSwchTime') {
      data[key] = timeToMinutes(option, '12');
    } else {
      data[key] = option.Value;
    }

    if (keyDesc !== '') {
      data[keyDesc] = option.Desc;
    }
    setCurrentUser(data);
    evaluateChange({ new: data[key], key });
  };

  return (
    <Box pad="small">
      <Heading margin="0" pad="xsmall" level={6}>
        Hand-Held Counting Interval
      </Heading>
      <Form
        onReset={(event) => console.log(event)}
        onSubmit={({ value }) => console.log('Submit', value)}
      >
        <Box alignSelf="start" direction="row">
          {handHeldNumbers ? (
            <FormField margin="small" label="Hand-Held" htmlFor="HhldNmbr" {...props}>
              <Select
                id="HhldNmbr"
                value={handHeldOptions[props.advancedCurrentUser.HhldNmbr]}
                valueKey="Value"
                labelKey="Desc"
                options={handHeldNumbers.slice(0, 52)}
                onChange={({ option }) => handleSelectChange(option, 'HhldNmbr', 'HhldNmbrDesc')}
              />
            </FormField>
          ) : null}
          <DaySchedules data={dayDropdown} {...props} type="handHeld" />
        </Box>
        <Box alignSelf="start" direction="row">
          <FormField label="Switch Time" htmlFor="HhldSwchTime" {...props}>
            <TextInput
              type="time"
              id="HhldSwchTime"
              name="HhldSwchTime"
              value={minutesToTime(props.advancedCurrentUser.HhldSwchTime, '24')}
              onChange={(event) => handleInputChange(event)}
            />
          </FormField>
          <TimeSelect
            id="select"
            name="select"
            placeholder="Select"
            value=""
            options={timeSelection.options}
            icon={
              <Box>
                <Clock color="black" />
              </Box>
            }
            plain
            onChange={({ option }) => handleSelectChange(option, 'HhldSwchTime', '')}
          />
        </Box>
      </Form>
    </Box>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  advancedCurrentUser: selectAdvancedCurrentUsers,
  handHeldNumbers: selectHandHeldNumbers,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentAdvancedUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SetupUsersHandHeldTab));

import {
  Box,
  Button,
  CheckBox,
  Heading,
  Layer,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Text,
  TextInput,
} from 'grommet';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { InventoryDataTable } from '@Pages/inventory/inventory.styles';
import { optionsConfig } from '@Pages/setup/setup-config';
import {
  getReportTransactionsStart,
  getReportTransactionsSuccess,
} from '@State/reports/reports.actions';
import {
  selectReportFilterOptions,
  selectReportTransactions,
} from '@State/reports/reports.selectors';
import { selectItemsSelectedInOrder } from '@State/setup/setup.selectors';
import { selectCurrentCustomer } from '@State/user/user.selectors';
import { measures, ScrollableBox } from '@ThemeMain';

const AdjustItemsModal = (props) => {
  const {
    showModal,
    toggleModal,
    rcID,
    type,
    method,
    pageTitle,
    currentCustomer,
    currentDate,
    deliveryData,
    addUnScheduledEvent,
    reportFilterOptions,
    reportTransactions,
    getReportTransactions,
    itemsSelectedInOrder,
    setAcceptedPar,
    acceptedPar,
    changedKeys,
    setChangedKeys,
    setSaveDisabledStatus,
    getReportTransactionsReset,
  } = props;

  const options = [
    { desc: 'None', value: 0 },
    { desc: '10%', value: 0.1 },
    { desc: '20%', value: 0.2 },
    { desc: '25%', value: 0.25 },
    { desc: '30%', value: 0.3 },
    { desc: '35%', value: 0.35 },
    { desc: '40%', value: 0.4 },
    { desc: '50%', value: 0.5 },
  ];
  const [show, setShow] = useState(showModal);
  const [selectedRows, setSelectedRows] = useState([]);
  const [changedRow, setChangeRow] = useState([]);
  const [safetyFactor, setSafetyFactor] = useState(options[2]);
  const [data, setData] = useState([]);
  const [disableSave, setDisableSave] = useState(false);
  const [orderName, setOrderName] = useState('');
  const [analysis, setAnalysis] = useState('');
  const dateDiff = 89;
  const oneWeek = 7;
  const endDate = moment().subtract(oneWeek, 'days');

  useEffect(() => {
    setSafetyFactor(options[2]);
    setShow(showModal);
    setOrderName('');
    setData([]);
    setDisableSave(true);
  }, [showModal]);

  useEffect(() => {
    getReportTransactionsReset();
    if (reportFilterOptions !== null) {
      let orderOptionIndex = reportFilterOptions.ReportOptionTable.findIndex(
        (obj) => obj.LookupType === 'REPORTOPTION100',
      );
      const orderDateIndex = reportFilterOptions.ReportOptionTable.findIndex(
        (obj) => obj.DataType === 'DATERANGE',
      );
      if (orderOptionIndex > -1 && orderDateIndex > -1) {
        const dateRange = getDateRange();
        reportFilterOptions.ReportOptionTable[orderOptionIndex].Int32Value = rcID;
        reportFilterOptions.ReportOptionTable[orderDateIndex].DateTimeValue1 = dateRange.start;
        reportFilterOptions.ReportOptionTable[orderDateIndex].DateTimeValue2 = dateRange.end;

        if (optionsConfig[type] && optionsConfig[type].adjustItems) {
          for (const i in optionsConfig[type].adjustItems) {
            orderOptionIndex = reportFilterOptions.ReportOptionTable.findIndex(
              (obj) => obj.$attributes.id === i,
            );
            reportFilterOptions.ReportOptionTable[orderOptionIndex].Int32Value =
              optionsConfig[type].adjustItems[i];
          }
          getReportTransactions(reportFilterOptions);
        }
      }
    }
  }, [reportFilterOptions]);

  useEffect(() => {
    if (reportTransactions !== null && reportTransactions.parentData) {
      setOrderName(reportTransactions.parentData[0].Description.replace(currentCustomer.Name, ''));

      if (reportTransactions.parentData[0].Selection) {
        const analysis = reportTransactions.parentData[0].Selection.replace(
          currentCustomer.Name,
          '',
        )
          .replace(orderName, '')
          .split(',');

        setAnalysis(analysis.length ? analysis[1].trim() : '');
      }

      if (typeof reportTransactions.dataTable.childData[`pId-${rcID}`] !== 'undefined') {
        const data = [...reportTransactions.dataTable.childData[`pId-${rcID}`]];
        setData(filterSelectedItems(data));
        if (data.filter((item) => item.Stocked !== 'N/A').length) {
          setDisableSave(false);
        }
      }
    }
  }, [reportTransactions]);

  const filterSelectedItems = (rows) => {
    const result = [];
    let index = 0;
    const accept = { ...acceptedPar };
    rows.map((row) => {
      index = itemsSelectedInOrder.data.findIndex((obj) => obj.ItemCode === row.ObjtCode);
      if (index >= 0) {
        row.Accept = true;
        row.proposedPar = 0;
        accept[row.ObjtCode] = calculateProposed(row).proposedPar;
        result.push(row);
      }
    });
    setAcceptedPar(accept);
    return result;
  };

  const getDateRange = () => {
    let result = {};
    const startDate = endDate.clone();
    startDate.subtract(dateDiff, 'days');

    result = {
      start: startDate.format('YYYY-MM-DDTHH:mm:ss+08:00'),
      end: endDate.format('YYYY-MM-DDTHH:mm:ss+08:00'),
      rangeLbl: `"${startDate.format('MM/DD/YYYY')}" to "${endDate.format('MM/DD/YYYY')}"`,
    };
    return result;
  };

  const onCancel = () => {
    toggleModal({ id: 'AdjustItemsModal', data: [] });
    setSelectedRows([]);
  };

  const onAcceptAdjustments = () => {
    let index = 0;
    const accepted = getUpdatedProposed({ ...acceptedPar });
    const changes = [...changedKeys.columnsRight];
    let id = '';

    for (const i in accepted) {
      index = itemsSelectedInOrder.data.findIndex((obj) => obj.ItemCode === i);

      const row = index > -1 ? itemsSelectedInOrder.data[index] : null;
      if (row) {
        row.InvtRqrdQnty = accepted[i];
        id = `${row.RcID}-InvtRqrdQnty`;
        if (!changes.includes(id)) {
          changes.push(id);
        }
      }
    }
    setChangeRow([]);
    setSaveDisabledStatus(false);
    setChangedKeys({ ...changedKeys, columnsRight: changes });
    toggleModal({ id: 'AdjustItemsModal', data: [] });
  };

  const getCurrentPar = (data) => {
    const index = itemsSelectedInOrder.data.findIndex((obj) => obj.ItemCode === data.ObjtCode);
    return index >= 0 ? parseFloat(itemsSelectedInOrder.data[index].InvtRqrdQnty) : 0;
  };

  const getCurrentSafety = (data) => {
    const currentPar = getCurrentPar(data);
    const quo = parseFloat(currentPar) / parseFloat(Math.floor(data.Stocked));
    return (!isFinite(quo) ? 1 : quo) - 1.0;
  };

  const calculateProposed = (data) => {
    const stocked = parseFloat(Math.floor(data.Stocked));
    let proposedPar = Math.round(stocked * (1 + safetyFactor.value));
    let proposedSafety = proposedPar / stocked - 1.0;

    if (proposedSafety < safetyFactor.value) {
      proposedPar += 1.0;
      proposedSafety = proposedPar / stocked - 1.0;
    }

    return {
      proposedPar: !isFinite(proposedPar) ? 0 : proposedPar,
      proposedSafety: !isFinite(proposedSafety) ? 0 : proposedSafety,
    };
  };

  const handleChange = (row) => {
    let accepted = { ...acceptedPar };
    const rows = [...data];
    let value = 0;
    const index = rows.findIndex((obj) => obj.ObjtCode === row.ObjtCode);
    rows[index].Accept = !rows[index].Accept;
    setData(rows);

    if (rows[index].Accept) {
      if (changedRow.includes(row.ObjtCode)) {
        value = row.proposedPar;
      } else {
        value = calculateProposed(row).proposedPar;
      }
      accepted[row.ObjtCode] = value;
    } else {
      delete accepted[row.ObjtCode];
    }
    accepted = getUpdatedProposed(accepted);
    setAcceptedPar(accepted);
  };

  const onInputChange = (row, value) => {
    setChangeRow(changedRow.concat([row.ObjtCode]));
    const tempData = [...data];
    const index = data.findIndex((obj) => obj.ObjtCode === row.ObjtCode);
    tempData[index].proposedPar = value;

    setData(tempData);
  };

  const getUpdatedProposed = (accepted) => {
    let index = 0;
    let value = 0;
    for (const i in accepted) {
      index = data.findIndex((obj) => obj.ObjtCode === i);
      if (index >= 0) {
        if (changedRow.includes(i)) {
          value = data[index].proposedPar;
        } else {
          value = calculateProposed(data[index]).proposedPar;
        }
        accepted[i] = value;
      }
    }

    return accepted;
  };

  const toggleCheckboxes = (checked) => {
    const rows = [...data];
    const accepted = { ...acceptedPar };

    rows.map((item) => {
      item.Accept = checked;
      if (checked) {
        accepted[item.ObjtCode] = calculateProposed(item).proposedPar;
      } else {
        delete accepted[item.ObjtCode];
      }
    });
    setAcceptedPar(accepted);
    setData(rows);
  };

  const onSafetyFactorChange = (option) => {
    setSafetyFactor(option);
    const accepted = getUpdatedProposed({ ...acceptedPar });
    setAcceptedPar(accepted);
    setChangeRow([]);
  };

  return (
    <>
      {show && (
        <Layer onEsc={onCancel} onClickOutside={onCancel}>
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell colSpan="7">
                  <Heading level="4" textAlign="center" margin="auto">
                    Adjust Items
                  </Heading>
                </TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableCell>Order</TableCell>
                <TableCell colSpan="6">
                  <Text>{orderName}</Text>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Analysis</TableCell>
                <TableCell colSpan="6">
                  <Text>{analysis}</Text>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Proposed Safety</TableCell>
                <TableCell>
                  <Select
                    disabled={disableSave}
                    options={options}
                    labelKey="desc"
                    value={safetyFactor}
                    onChange={({ option }) => onSafetyFactorChange(option)}
                  />
                </TableCell>
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell>
                  <Button
                    disabled={disableSave}
                    label="Clear All"
                    onClick={() => toggleCheckboxes(false)}
                  />
                </TableCell>
                <TableCell>
                  <Button
                    disabled={disableSave}
                    label="Set All"
                    onClick={() => toggleCheckboxes(true)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan="7">
                  <ScrollableBox style={{ maxHeight: '500px', minHeight: '400px' }}>
                    <InventoryDataTable
                      className="adjust-items-tbl"
                      bodySize={measures.dataTableBodyHeight}
                      columns={[
                        {
                          property: 'Description',
                          header: <Text>Item Desc</Text>,
                        },
                        {
                          property: 'Stocked',
                          header: <Text>Stocked</Text>,
                          render: (datum) => {
                            const value =
                              datum.Stocked === 'N/A' ? datum.Stocked : Math.floor(datum.Stocked);
                            return <Text>{value}</Text>;
                          },
                        },
                        {
                          property: 'Par',
                          header: <Text>Current Par</Text>,
                          render: (datum) => {
                            return <Text>{getCurrentPar(datum)}</Text>;
                          },
                        },
                        {
                          property: 'CurrentSafety',
                          header: <Text>Current Safety</Text>,
                          render: (datum) => {
                            const value = getCurrentSafety(datum);
                            return (
                              <Box
                                fill="horizontal"
                                background={value < safetyFactor.value ? 'red' : null}
                              >
                                <Text>{`${Math.round(value * 100)}%`}</Text>
                              </Box>
                            );
                          },
                        },
                        {
                          property: 'ProposedPar',
                          header: <Text>Proposed Par</Text>,
                          render: (datum) => {
                            return (
                              <TextInput
                                disabled={disableSave}
                                value={
                                  changedRow.includes(datum.ObjtCode)
                                    ? datum.proposedPar
                                    : calculateProposed(datum).proposedPar
                                }
                                onChange={(event) => onInputChange(datum, event.target.value)}
                              />
                            );
                          },
                        },
                        {
                          property: 'ProposedSafety',
                          header: <Text>Proposed Safety</Text>,
                          render: (datum) => {
                            const value = Math.round(calculateProposed(datum).proposedSafety * 100);
                            return (
                              <Box
                                fill="horizontal"
                                background={value < safetyFactor.value * 100 ? 'red' : null}
                              >
                                <Text>{`${value}%`}</Text>
                              </Box>
                            );
                          },
                        },
                        {
                          property: 'Accept',
                          header: <Text>Accept</Text>,
                          render: (datum) => {
                            return (
                              <CheckBox
                                name={datum.Description}
                                checked={datum.Accept}
                                onChange={() => handleChange(datum)}
                              />
                            );
                          },
                        },
                      ]}
                      data={data}
                    />
                  </ScrollableBox>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Button
                    disabled={disableSave}
                    label="Accept Adjustments"
                    onClick={() => onAcceptAdjustments()}
                  />
                </TableCell>
                <TableCell>
                  <Button label="Cancel" onClick={() => onCancel()} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Layer>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getReportTransactions: (data) => dispatch(getReportTransactionsStart(data)),
  getReportTransactionsReset: () => dispatch(getReportTransactionsSuccess(null)),
});

const mapStateToProps = createStructuredSelector({
  currentCustomer: selectCurrentCustomer,
  reportFilterOptions: selectReportFilterOptions,
  reportTransactions: selectReportTransactions,
  itemsSelectedInOrder: selectItemsSelectedInOrder,
});

export default connect(mapStateToProps, mapDispatchToProps)(AdjustItemsModal);

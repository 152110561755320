import { Box, Text } from 'grommet';
import { FormNext } from 'grommet-icons';
import Menu, { Item, SubMenu } from 'rc-menu';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { colors, measures } from '@ThemeMain';

const headerFontSize = '14px';
const headerItemPadding = `0.7rem 1rem`;
const unicodeCaretDown = '\f0d7';

const LogoImage = styled.img`
  margin-left: 1rem;
  max-height: 100%;
  max-width: 100%;
  height: 60px;
  vertical-align: middle;

  &.company {
    height: auto;
  }
`;

const LogoText = styled(Text)`
  vertical-align: middle;
  font-size: 1.5rem;
  font-weight: lighter;
  font-style: italic;
  color: ${colors.logoSecondary};
  position: relative;
  left: 0;
  top: 0.1em;

  > :nth-child(1) {
    color: ${colors.logoPrimary};
  }
`;

export const LogoContainer = styled((props) => {
  const innerProps = { ...props };
  delete innerProps.defaultLogoSrc;
  delete innerProps.bannerUrl;
  delete innerProps.setHasBannerImage;
  delete innerProps.hasBannerImage;

  return (
    <Link {...innerProps}>
      <Box direction="row" align="center" height="100%">
        <LogoImage
          src={props.hasBannerImage ? props.bannerUrl : props.defaultLogoSrc}
          className={props.bannerUrl ? 'company' : null}
          onError={() => props.setHasBannerImage(false)}
        />
        {props.hasBannerImage ? null : (
          <LogoText>
            <span>Linen</span>
            <span>Helper</span>
          </LogoText>
        )}
      </Box>
    </Link>
  );
})`
  text-decoration: none;
  margin-right: auto;
  display: block;
  height: 88px;

  :focus {
    outline: none;
  }
`;

export const HeaderBox = styled((props) => {
  const innerProps = { ...props };
  delete innerProps.blankBg;

  return <Box direction="column" justify="start" {...innerProps} />;
})`
  height: ${measures.headerHeight};
  box-sizing: border-box;

  background: ${(props) =>
    props.blankBg
      ? 'white'
      : `linear-gradient(0deg, rgba(187, 192, 215, 1) 25%, rgba(255, 255, 255, 1) 75%)`};
  position: relative;
  z-index: 1;
  box-shadow: 0 1px 3px 1px ${colors.dropShadow};
`;

export const HeaderMenuLink = styled(Link)`
  display: block;
  padding: ${headerItemPadding} !important;
  color: inherit;
  text-decoration: none;
  font-size: ${headerFontSize};
`;

export const HeaderMenuItem = styled(Item)`
  padding: 0 !important; /*put padding on link element, instead; easier to click */
  user-select: none;
  font-size: ${headerFontSize};
`;

export const HeaderLabel = styled(Item)`
  padding: 0 !important; /*put padding on link element, instead; easier to click */
  user-select: none;
  font-size: ${headerFontSize};
  float: right;
  border-left: 1px solid #bbc0d7;
`;

export const HeaderSubMenu = styled(SubMenu)`
  cursor: default;
  user-select: none;
  font-size: ${headerFontSize};
  cursor: default;
  user-select: none;

  & > * > * > .rc-menu-submenu {
    /* correct submenu depth */
    z-index: ${(props) => props.level};
  }

  .rc-menu-submenu-title {
    padding: ${headerItemPadding} !important;
  }

  ${(props) =>
    props.level === 1
      ? `
  :not(.rc-menu-submenu-vertical-left) .rc-menu-submenu-title {
    overflow: hidden;
    position: relative;

    :after { /* symbol denoting dropDOWN */
      font-family: FontAwesome; /*FontAwesome v4.2.0 included via rc-menu*/
      content: "${unicodeCaretDown}";
      display: block;
      position: absolute;
      bottom: -0.3rem;
      transition-property: bottom;
      transition-duration: 0.2s;
      left: 0;
      width: 100%;
      text-align: center;
      font-size: 0.7em;
    }
  }
  :hover .rc-menu-submenu-title:after,
  .rc-menu-submenu-title[aria-expanded=true]:after {
    bottom: -1rem;
  }
  `
      : `
  .rc-menu-submenu-title {
    padding-right: 2.5em !important; /* make room for right-arrow */
  }
  `}
`;

export const HeaderMenu = styled((props) => (
  <Menu subMenuCloseDelay="0.3" mode="horizontal" {...props}>
    {props.children}
  </Menu>
))`
  margin-block-start: 0;
  margin-block-end: 0;
  margin: 0;
`;

const breadcrumbBackgroundColor = 'rgba(255, 255, 255, 0.3)';
export const HeaderBreadcrumbsBox = styled((props) => {
  return (
    <Box direction="row" {...props}>
      {props.children}
    </Box>
  );
})`
  height: ${measures.breadcrumbHeight};
  position: absolute;
  z-index: 1;
  top: ${measures.headerHeight};
  left: 0;
  background-color: ${breadcrumbBackgroundColor};
  border-bottom: 1px solid ${colors.background};

  &,
  & * {
    font-size: 0.9rem !important;
  }

  &:after {
    display: inline-block;
    width: 3em;
    height: 100%;
    content: ' ';
    background: linear-gradient(
      90deg,
      ${breadcrumbBackgroundColor} 25%,
      rgba(255, 255, 255, 0) 100%
    );
    position: absolute;
    top: 0;
    right: -3em;
  }
`;

export const HeaderBreadcrumb = styled((props) => {
  return (
    <Box direction="row" {...props}>
      {props.notFirst ? <FormNext /> : null}
      {props.to ? <Link to={props.to}>{props.label}</Link> : <Text>{props.label}</Text>}
    </Box>
  );
})`
  line-height: ${measures.breadcrumbHeight};

  & > * {
    height: ${measures.breadcrumbHeight};
    line-height: ${measures.breadcrumbHeight};
  }

  & > a,
  & > span {
    padding: 0 0.5em;
  }

  a {
    color: inherit;
    font-weight: bold;
    transition-duration: 0.2s;
    transition-property: background-color;
  }

  a:hover {
    background-color: ${colors.innerBg};
    color: ${colors.active};
  }
`;

import { createSelector } from 'reselect';

const selectReport = (state) => state.report;
const selectOptionsHistory = (state) => state.report.options_history;
const selectPageID = (state) => state.report.page_id;

export const selectReportType = createSelector([selectReport], (reports) => reports.report_type);

export const selectReportFilterOptions = createSelector(
  [selectReport, selectPageID, selectOptionsHistory],
  (reports, pageID, optionsHistory) => {
    if (
      pageID &&
      optionsHistory &&
      optionsHistory[pageID] &&
      reports.report_filter_options &&
      reports.report_filter_options.ReportOptionTable
    ) {
      reports.report_filter_options.ReportOptionTable = optionsHistory[pageID];
    }

    return reports.report_filter_options;
  },
);

export const selectReportTransactions = createSelector(
  [selectReport],
  (reports) => reports.report_transactions,
);

export const selectReportSelectionTree = createSelector(
  [selectReport],
  (reports) => reports.report_selection_tree,
);

export const selectMonthlyBudgetCensusData = createSelector(
  [selectReport],
  (reports) => reports.report_monthly_budget_census,
);

// TODO: alter this to real location after filtering return object from node
export const selectReportData = createSelector([selectReport], (reports) => {
  if (reports.report_transactions && reports.report_transactions.base64ReportingData) {
    return reports.report_transactions.base64ReportingData;
  }
  if (reports.report_selection_tree && reports.report_selection_tree.base64ReportingData) {
    return reports.report_selection_tree.base64ReportingData;
  }
  return (
    (reports.report_transactions &&
    reports.report_transactions.rawData &&
    reports.report_transactions.rawData.ReportData
      ? reports.report_transactions.rawData.ReportData.ReportTable
      : null) ||
    (reports.report_selection_tree &&
    reports.report_selection_tree.rawData &&
    reports.report_selection_tree.rawData.ReportData
      ? reports.report_selection_tree.rawData.ReportData.ReportTable
      : null)
  );
});

export const selectReportLabel = createSelector([selectReport], (reports) => {
  let reportObject;
  if (reports.report_transactions && reports.report_transactions.status === 'ok') {
    reportObject = reports.report_transactions;
  } else {
    reportObject = reports.report_selection_tree;
  }
  return reportObject && reportObject.reportLabel ? reportObject.reportLabel : null;
});

export const selectEmailDocumentResponse = createSelector(
  [selectReport],
  (reports) => reports.email_document_response,
);

export const selectRcID = createSelector([selectReport], (reports) => reports.selected_rcid);

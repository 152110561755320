const AdministrationTypes = {
  GET_LOG_TRANSACTIONS_SELECTION_TREE_START: 'GET_LOG_TRANSACTIONS_SELECTION_TREE_START',
  GET_LOG_TRANSACTIONS_SELECTION_TREE_SUCCESS: 'GET_LOG_TRANSACTIONS_SELECTION_TREE_SUCCESS',
  GET_LOG_TRANSACTIONS_SELECTION_TREE_FAILURE: 'GET_LOG_TRANSACTIONS_SELECTION_TREE_FAILURE',
  GET_SYSTEM_CONFIGURATION_SELECTION_TREE_START: 'GET_SYSTEM_CONFIGURATION_SELECTION_TREE_START',
  GET_SYSTEM_CONFIGURATION_SELECTION_TREE_SUCCESS:
    'GET_SYSTEM_CONFIGURATION_SELECTION_TREE_SUCCESS',
  GET_SYSTEM_CONFIGURATION_SELECTION_TREE_FAILURE:
    'GET_SYSTEM_CONFIGURATION_SELECTION_TREE_FAILURE',
  GET_SELECTED_LOGS_START: 'GET_SELECTED_LOGS_START',
  GET_SELECTED_LOGS_SUCCESS: 'GET_SELECTED_LOGS_SUCCESS',
  GET_SELECTED_LOGS_FAILURE: 'GET_SELECTED_LOGS_FAILURE',
  GET_ITEMS_IN_LOG_START: 'GET_ITEMS_IN_LOG_START',
  GET_ITEMS_IN_LOG_SUCCESS: 'GET_ITEMS_IN_LOG_SUCCESS',
  GET_ITEMS_IN_LOG_FAILURE: 'GET_ITEMS_IN_LOG_FAILURE',

  // System Configuration
  GET_GENERAL_TAB_OPTIONS_START: 'GET_GENERAL_TAB_OPTIONS_START',
  GET_GENERAL_TAB_OPTIONS_SUCCESS: 'GET_GENERAL_TAB_OPTIONS_SUCCESS',
  GET_GENERAL_TAB_OPTIONS_FAILURE: 'GET_GENERAL_TAB_OPTIONS_FAILURE',
  SET_GENERAL_TAB_OPTIONS_START: 'SET_GENERAL_TAB_OPTIONS_START',
  SET_GENERAL_TAB_OPTIONS_SUCCESS: 'SET_GENERAL_TAB_OPTIONS_SUCCESS',
  SET_GENERAL_TAB_OPTIONS_FAILURE: 'SET_GENERAL_TAB_OPTIONS_FAILURE',
  UPDATE_GENERAL_OPTIONS_START: 'UPDATE_GENERAL_OPTIONS_START',
  UPDATE_GENERAL_OPTIONS_SUCCESS: 'UPDATE_GENERAL_OPTIONS_SUCCESS',
  UPDATE_GENERAL_OPTIONS_FAILURE: 'UPDATE_GENERAL_OPTIONS_FAILURE',

  GET_MOBILE_DEVICE_TABS_START: 'GET_MOBILE_DEVICE_TABS_START',
  GET_MOBILE_DEVICE_TABS_SUCCESS: 'GET_MOBILE_DEVICE_TABS_SUCCESS',
  GET_MOBILE_DEVICE_TABS_FAILURE: 'GET_MOBILE_DEVICE_TABS_FAILURE',
  GET_MOBILE_DEVICE_TAB_OPTIONS_START: 'GET_MOBILE_DEVICE_TAB_OPTIONS_START',
  GET_MOBILE_DEVICE_TAB_OPTIONS_SUCCESS: 'GET_MOBILE_DEVICE_TAB_OPTIONS_SUCCESS',
  GET_MOBILE_DEVICE_TAB_OPTIONS_FAILURE: 'GET_MOBILE_DEVICE_TAB_OPTIONS_FAILURE',

  SET_MOBILE_DEVICE_TAB_OPTIONS_START: 'SET_MOBILE_DEVICE_TAB_OPTIONS_START',
  SET_MOBILE_DEVICE_TAB_OPTIONS_SUCCESS: 'SET_MOBILE_DEVICE_TAB_OPTIONS_SUCCESS',
  SET_MOBILE_DEVICE_TAB_OPTIONS_FAILURE: 'SET_MOBILE_DEVICE_TAB_OPTIONS_FAILURE',

  UPDATE_MOBILE_DEVICE_TAB_OPTIONS_START: 'UPDATE_MOBILE_DEVICE_TAB_OPTIONS_START',
  UPDATE_MOBILE_DEVICE_TAB_OPTIONS_SUCCESS: 'UPDATE_MOBILE_DEVICE_TAB_OPTIONS_SUCCESS',
  UPDATE_MOBILE_DEVICE_TAB_OPTIONS_FAILURE: 'UPDATE_MOBILE_DEVICE_TAB_OPTIONS_FAILURE',
  DELETE_MOBILE_DEVICE_OPTIONS_START: 'DELETE_MOBILE_DEVICE_OPTIONS_START',
  DELETE_MOBILE_DEVICE_OPTIONS_SUCCESS: 'DELETE_MOBILE_DEVICE_OPTIONS_SUCCESS',
  DELETE_MOBILE_DEVICE_OPTIONS_FAILURE: 'DELETE_MOBILE_DEVICE_OPTIONS_FAILURE',
};

export default AdministrationTypes;

import { Box, FormField, Text } from 'grommet';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import validator from 'validator';

import Modal from '@Components/modal/modal.component';
import { selectCurrentUser } from '@State/user/user.selectors';
import { colors } from '@ThemeMain';

const ReportEmailDocumentModal = (props) => {
  const { showReportEmailDocumentModal, toggleModal, emailTransactions, currentUser, emailType } =
    props;
  const [show, setShow] = useState(showReportEmailDocumentModal);
  const [formEmail, setFormEmail] = useState(currentUser.emailAddress);
  const [showDialog, setShowDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');

  const modalTitle = emailType ? `Email ${emailType[0].toUpperCase() + emailType.slice(1)}` : null;

  useEffect(() => {
    setFormEmail(currentUser.emailAddress);
    setShow(showReportEmailDocumentModal);
  }, [showReportEmailDocumentModal]);

  const onCancel = () => {
    toggleModal();
  };

  const handleEmailChange = (e) => {
    setFormEmail(e.target.value);
  };

  const handleSubmit = () => {
    if (!validate()) {
      emailTransactions({ formEmail, emailType });
      toggleModal();
      return true;
    }
    return false; // cancel modal closing
  };

  const validate = () => {
    let error = false;

    if (validator.isEmpty(formEmail)) {
      setDialogMessage('Email Address is Required.');
      error = true;
    } else if (!validator.isEmail(formEmail) && !validator.isEmpty(formEmail)) {
      setDialogMessage(`" ${formEmail} " is not a valid Email Address.`);
      error = true;
    }
    setShowDialog(error);

    return error;
  };

  return (
    <>
      <Modal
        show={show}
        setShow={setShow}
        title={modalTitle}
        confirmLabel="Submit"
        onConfirm={handleSubmit}
      >
        <FormField
          name="email"
          label="Enter your email"
          value={formEmail}
          onChange={handleEmailChange}
        />
        {showDialog ? (
          <Box pad="small">
            <Text color={colors['neutral-4']}>{dialogMessage}</Text>
          </Box>
        ) : null}
      </Modal>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(ReportEmailDocumentModal);

const ReportTypes = {
  SET_REPORT_TYPE: 'SET_REPORT_TYPE',
  GET_REPORT_FILTER_OPTIONS_START: 'GET_REPORT_FILTER_OPTIONS_START',
  GET_REPORT_FILTER_OPTIONS_SUCCESS: 'GET_REPORT_FILTER_OPTIONS_SUCCESS',
  GET_REPORT_FILTER_OPTIONS_FAILURE: 'GET_REPORT_FILTER_OPTIONS_FAILURE',
  GET_REPORT_TRANSACTIONS_START: 'GET_REPORT_TRANSACTIONS_START',
  GET_REPORT_TRANSACTIONS_SUCCESS: 'GET_REPORT_TRANSACTIONS_SUCCESS',
  GET_REPORT_TRANSACTIONS_FAILURE: 'GET_REPORT_TRANSACTIONS_FAILURE',
  GET_REPORT_SELECTION_TREE_START: 'GET_REPORT_SELECTION_TREE_START',
  GET_REPORT_SELECTION_TREE_SUCCESS: 'GET_REPORT_SELECTION_TREE_SUCCESS',
  GET_REPORT_SELECTION_TREE_FAILURE: 'GET_REPORT_SELECTION_TREE_FAILURE',
  GET_MONTHLY_BUDGET_CENSUS_USED_FOR_CUSTOMER_START:
    'GET_MONTHLY_BUDGET_CENSUS_USED_FOR_CUSTOMER_START',
  GET_MONTHLY_BUDGET_CENSUS_USED_FOR_CUSTOMER_SUCCESS:
    'GET_MONTHLY_BUDGET_CENSUS_USED_FOR_CUSTOMER_SUCCESS',
  GET_MONTHLY_BUDGET_CENSUS_USED_FOR_CUSTOMER_FAILURE:
    'GET_MONTHLY_BUDGET_CENSUS_USED_FOR_CUSTOMER_FAILURE',
  UPDATE_MONTHLY_BUDGET_CENSUS_USED_FOR_CUSTOMER_START:
    'UPDATE_MONTHLY_BUDGET_CENSUS_USED_FOR_CUSTOMER_START',
  UPDATE_MONTHLY_BUDGET_CENSUS_USED_FOR_CUSTOMER_SUCCESS:
    'UPDATE_MONTHLY_BUDGET_CENSUS_USED_FOR_CUSTOMER_SUCCESS',
  UPDATE_MONTHLY_BUDGET_CENSUS_USED_FOR_CUSTOMER_FAILURE:
    'UPDATE_MONTHLY_BUDGET_CENSUS_USED_FOR_CUSTOMER_FAILURE',
  GET_REPORT_SNAPSHOT_START: 'GET_REPORT_SNAPSHOT_START',
  GET_REPORT_SNAPSHOT_SUCCESS: 'GET_REPORT_SNAPSHOT_SUCCESS',
  GET_REPORT_SNAPSHOT_FAILURE: 'GET_REPORT_SNAPSHOT_FAILURE',

  EMAIL_DOCUMENT_START: 'EMAIL_DOCUMENT_START',
  EMAIL_DOCUMENT_SUCCESS: 'EMAIL_DOCUMENT_SUCCESS',
  EMAIL_DOCUMENT_FAILURE: 'EMAIL_DOCUMENT_FAILURE',

  EMAIL_SPREADSHEET_START: 'EMAIL_SPREADSHEET_START',
  EMAIL_SPREADSHEET_SUCCESS: 'EMAIL_SPREADSHEET_SUCCESS',
  EMAIL_SPREADSHEET_FAILURE: 'EMAIL_SPREADSHEET_FAILURE',

  VIEW_DOCUMENT_START: 'VIEW_DOCUMENT_START',
  VIEW_DOCUMENT_SUCCESS: 'VIEW_DOCUMENT_SUCCESS',
  VIEW_DOCUMENT_FAILURE: 'VIEW_DOCUMENT_FAILURE',

  VIEW_SPREADSHEET_START: 'VIEW_SPREADSHEET_START',
  VIEW_SPREADSHEET_SUCCESS: 'VIEW_SPREADSHEET_SUCCESS',
  VIEW_SPREADSHEET_FAILURE: 'VIEW_SPREADSHEET_FAILURE',

  SAVE_OPTIONS_HISTORY: 'SAVE_OPTIONS_HISTORY',

  SET_PAGE_ID: 'SET_PAGE_ID',

  SET_RCID: 'SET_RCID',
};

export default ReportTypes;

import { Box, Button, Form } from 'grommet';

import { PrimaryButton } from '@ThemeMain';

import { ModalBody } from './modal.style';

const Modal = (props) => {
  const {
    show,
    setShow,
    title,
    confirmLabel = 'OK',
    onConfirm,
    confirmDisabled,
    cancelLabel = 'Cancel',
    onCancel,
  } = props;

  const closeModal = () => {
    setShow(false);
  };

  const confirmModal = (event) => {
    let confirmSuccess = true;
    if (onConfirm) {
      confirmSuccess = onConfirm(event);
    }
    if (confirmSuccess !== false) {
      // strictly false, i.e. undefined/void doesn't count
      closeModal();
    }
  };

  const cancelModal = (event) => {
    closeModal();
    if (onCancel) {
      onCancel(event);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    confirmModal(event);
  };

  // TODO: enable adding other buttons.
  if (show) {
    return (
      <ModalBody {...props} title={title} onCancel={cancelModal}>
        <Form onSubmit={handleSubmit}>
          <Box gap="medium">
            <Box>{props.children}</Box>
            <Box direction="row" justify="end" gap="small">
              {cancelLabel !== '' ? <Button label={cancelLabel} onClick={cancelModal} /> : null}
              <PrimaryButton
                type="submit"
                disabled={confirmDisabled}
                label={confirmLabel}
                onClick={(event) => confirmModal(event)}
              />
            </Box>
          </Box>
        </Form>
      </ModalBody>
    );
  }
  return null;
};

export default Modal;

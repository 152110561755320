const SetupUsersCustomerSelectedActionTypes = {
  GET_CUSTOMER_SELECTED_START: 'GET_CUSTOMER_SELECTED_START',
  GET_CUSTOMER_SELECTED_SUCCESS: 'GET_CUSTOMER_SELECTED_SUCCESS',
  GET_CUSTOMER_SELECTED_FAILURE: 'GET_CUSTOMER_SELECTED_FAILURE',
  SAVE_CUSTOMER_SELECTED_START: 'SAVE_CUSTOMER_SELECTED_START',
  SAVE_CUSTOMER_SELECTED_SUCCESS: 'SAVE_CUSTOMER_SELECTED_SUCCESS',
  SAVE_CUSTOMER_SELECTED_FAILURE: 'SAVE_CUSTOMER_SELECTED_FAILURE',
  DELETE_CUSTOMER_SELECTED_START: 'DELETE_CUSTOMER_SELECTED_START',
  DELETE_CUSTOMER_SELECTED_SUCCESS: 'DELETE_CUSTOMER_SELECTED_SUCCESS',
  DELETE_CUSTOMER_SELECTED_FAILURE: 'DELETE_CUSTOMER_SELECTED_FAILURE',
  ADD_CUSTOMER_TO_DELETE_QUEUE_START: 'ADD_CUSTOMER_TO_DELETE_QUEUE_START',
  ADD_CUSTOMER_TO_DELETE_QUEUE_SUCCESS: 'ADD_CUSTOMER_TO_DELETE_QUEUE_SUCCESS',
  ADD_CUSTOMER_TO_DELETE_QUEUE_FAILURE: 'ADD_CUSTOMER_TO_DELETE_QUEUE_FAILURE',
  ADD_CUSTOMER_TO_ADD_QUEUE_START: 'ADD_CUSTOMER_TO_ADD_QUEUE_START',
  ADD_CUSTOMER_TO_ADD_QUEUE_SUCCESS: 'ADD_CUSTOMER_TO_ADD_QUEUE_SUCCESS',
  ADD_CUSTOMER_TO_ADD_QUEUE_FAILURE: 'ADD_CUSTOMER_TO_ADD_QUEUE_FAILURE',
};

export default SetupUsersCustomerSelectedActionTypes;

import { Tab, Tabs } from 'grommet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import SetupUsersPrivilegesSubTab from '@Components/setup-users-privileges-sub-tab/setup-users-privileges-sub-tab.component';
import { selectCurrentUser } from '@State/user/user.selectors';

const SetupUsersPrivilegesTab = ({ evaluateChange }) => {
  const generalCheckboxes = [
    {
      name: 'Customer',
      boxes: [{ key: 'PrivCustView', label: 'View' }],
    },
    {
      name: 'Accounts',
      boxes: [
        { key: 'PrivAcctView', label: 'View' },
        { key: 'PrivAcctMdfy', label: 'Mdfy' },
      ],
    },
    {
      name: 'Departments',
      boxes: [
        { key: 'PrivDeptView', label: 'View' },
        { key: 'PrivDeptMdfy', label: 'Mdfy' },
      ],
    },
    {
      name: 'Products',
      boxes: [
        { key: 'PrivProdView', label: 'View' },
        { key: 'PrivProdMdfy', label: 'Mdfy' },
      ],
    },
    {
      name: 'Packs',
      boxes: [
        { key: 'PrivPackView', label: 'View' },
        { key: 'PrivPackMdfy', label: 'Mdfy' },
      ],
    },
    {
      name: 'Procedures',
      boxes: [
        { key: 'PrivProcView', label: 'View' },
        { key: 'PrivProcMdfy', label: 'Mdfy' },
      ],
    },
    {
      name: 'Orders',
      boxes: [
        { key: 'PrivOrdrView', label: 'View' },
        { key: 'PrivOrdrMdfy', label: 'Mdfy' },
      ],
    },
    {
      name: 'Census',
      boxes: [
        { key: 'PrivCensView', label: 'View' },
        { key: 'PrivCensMdfy', label: 'Mdfy' },
      ],
    },
    {
      name: 'Budgets',
      boxes: [
        { key: 'PrivBudgView', label: 'View' },
        { key: 'PrivBudgMdfy', label: 'Mdfy' },
      ],
    },
    {
      name: 'Count Linen Orders',
      boxes: [
        { key: 'PrivDelvLdryView', label: 'View' },
        { key: 'PrivDelvLdryMdfy', label: 'Mdfy' },
      ],
    },
    {
      name: 'Count Linen Room',
      boxes: [
        { key: 'PrivDelvLnrmView', label: 'View' },
        { key: 'PrivDelvLnrmMdfy', label: 'Mdfy' },
      ],
    },
    {
      name: 'Count Inventory',
      boxes: [
        { key: 'PrivInvtView', label: 'View' },
        { key: 'PrivInvtMdfy', label: 'Mdfy' },
      ],
    },
    {
      name: 'Create Reports',
      boxes: [{ key: 'PrivRept', label: 'View' }],
    },
  ];
  const countLinenCheckboxes = [
    {
      name: 'Par',
      boxes: [
        { key: 'PrivDelvLdryStckView', label: 'PrivDelvLdryStckView' },
        { key: 'PrivDelvLdryStckMdfy', label: 'PrivDelvLdryStckMdfy' },
      ],
    },
    {
      name: 'On Hand',
      boxes: [
        { key: 'PrivDelvLdryOhndView', label: 'PrivDelvLdryOhndView' },
        { key: 'PrivDelvLdryOhndMdfy', label: 'PrivDelvLdryOhndMdfy' },
      ],
    },
    {
      name: 'Required',
      boxes: [
        { key: 'PrivDelvLdryRqrdView', label: 'PrivDelvLdryRqrdView' },
        { key: 'PrivDelvLdryRqrdMdfy', label: 'PrivDelvLdryRqrdMdfy' },
      ],
    },
    {
      name: 'Stocked',
      boxes: [
        { key: 'PrivDelvLdryLdryView', label: 'PrivDelvLdryLdryView' },
        { key: 'PrivDelvLdryLdryMdfy', label: 'PrivDelvLdryLdryMdfy' },
      ],
    },
    {
      name: 'Emergency',
      boxes: [
        { key: 'PrivDelvLdryLnrmView', label: 'PrivDelvLdryLnrmView' },
        { key: 'PrivDelvLdryLnrmMdfy', label: 'PrivDelvLdryLnrmMdfy' },
      ],
    },
    {
      name: 'Returned',
      boxes: [
        { key: 'PrivDelvLdryRetnView', label: 'PrivDelvLdryRetnView' },
        { key: 'PrivDelvLdryRetnMdfy', label: 'PrivDelvLdryRetnMdfy' },
      ],
    },
    {
      name: 'Add New Orders',
      boxes: [{ key: 'PrivDelvLdrySpecOrdr', label: 'PrivDelvLdrySpecOrdr' }],
    },
    {
      name: 'Add New Items',
      boxes: [{ key: 'PrivDelvLdrySpecItem', label: 'PrivDelvLdrySpecItem' }],
    },
    {
      name: 'Global Adjustments',
      boxes: [{ key: 'PrivDelvGlobAdjt', label: 'PrivDelvGlobAdjt' }],
    },
    {
      name: 'Auto Stock',
      boxes: [{ key: 'PrivDelvAutoStck', label: 'PrivDelvAutoStck' }],
    },
  ];
  const advancedCheckboxes = [
    { name: 'Administration', boxes: [{ key: 'PrivAdmn', label: 'PrivAdmn' }] },
    { name: 'Modify Customer', boxes: [{ key: 'PrivCustMdfy', label: 'PrivCustMdfy' }] },
    { name: 'Select Every Customer', boxes: [{ key: 'PrivEvryCust', label: 'PrivEvryCust' }] },
    { name: 'Select Every Order', boxes: [{ key: 'PrivEvryOrdr', label: 'PrivEvryOrdr' }] },
    { name: 'Count Linen All Dates', boxes: [{ key: 'PrivEvryDate', label: 'PrivEvryDate' }] },
  ];
  return (
    <Tabs>
      <Tab title="General Privileges">
        <SetupUsersPrivilegesSubTab
          data={generalCheckboxes}
          evaluateChange={evaluateChange}
          type="General User Privileges"
        />
      </Tab>
      <Tab title="Count Linen Default Privileges">
        <SetupUsersPrivilegesSubTab
          data={countLinenCheckboxes}
          evaluateChange={evaluateChange}
          type="Count Linen Default Privileges"
        />
      </Tab>
      <Tab title="Advanced Privileges">
        <SetupUsersPrivilegesSubTab
          data={advancedCheckboxes}
          evaluateChange={evaluateChange}
          type="Advanced Privileges"
        />
      </Tab>
    </Tabs>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SetupUsersPrivilegesTab));

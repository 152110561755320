import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from '@API';

import {
  addProductToPackFailure,
  addProductToPackSuccess,
  addProductToProcedureFailure,
  addProductToProcedureSuccess,
  /* #region ORDERS */
  getOrdersUsedForCustomerSuccess,
  getOrdersUsedForCustomerFailure,
  getItemsSelectedInOrderSuccess,
  getItemsSelectedInOrderFailure,
  getItemsNotSelectedInOrderSuccess,
  getItemsNotSelectedInOrderFailure,
  updateOrdersUsedForCustomerSuccess,
  updateOrdersUsedForCustomerFailure,
  updateItemsSelectedAndNotSelectedInOrderSuccess,
  updateItemsSelectedAndNotSelectedInOrderFailure,
  addItemToOrderSuccess,
  addItemToOrderFailure,
  importOrdersSuccess,
  importOrdersFailure,
  /* #endregion */
  /* #region CUSTOMERS */
  getPageOfCustomersSuccess,
  getPageOfCustomersFailure,
  updateCustomersSuccess,
  updateCustomersFailure,
  getCustomerAccountsFailure,
  getCustomerAccountsSuccess,
  getDepartmentsAcctTypeFailure,
  getDepartmentsAcctTypeSuccess,
  getDepartmentsUsedForCustomerFailure,
  getDepartmentsUsedForCustomerSuccess,
  getHandheldNumbersFailure,
  getHandheldNumbersSuccess,
  getInventoryFlowFailure,
  getInventoryFlowSuccess,
  getItemAccountsFailure,
  getItemAccountsSuccess,
  getOrderTypesFailure,
  getOrderTypesSuccess,
  getPacksUsedForCustomerFailure,
  getPacksUsedForCustomerSuccess,
  getProceduresUsedForCustomerFailure,
  getProceduresUsedForCustomerSuccess,
  getProductsNotSelectedInPackFailure,
  getProductsNotSelectedInPackSuccess,
  getProductsNotSelectedInProcedureFailure,
  getProductsNotSelectedInProcedureSuccess,
  getProductsSelectedInPackFailure,
  getProductsSelectedInPackSuccess,
  getProductsSelectedInProcedureFailure,
  getProductsSelectedInProcedureSuccess,
  getProductsUsedForCustomerFailure,
  getProductsUsedForCustomerSuccess,
  getSnapshotFailure,
  getSnapshotSuccess,
  inactivateFailure,
  inactivateSuccess,
  moveToRowSuccess,
  moveToRowFailure,
  importProductsSuccess,
  importProductsFailure,
  /* #endregion */
  updateDepartmentsUsedForCustomerFailure,
  updateDepartmentsUsedForCustomerSuccess,
  updatePacksUsedForCustomerFailure,
  updatePacksUsedForCustomerSuccess,
  updateProceduresUsedForCustomerFailure,
  updateProceduresUsedForCustomerSuccess,
  updateProductsSelectedAndNotSelectedInPackFailure,
  updateProductsSelectedAndNotSelectedInPackSuccess,
  updateProductsSelectedAndNotSelectedInProcedureFailure,
  updateProductsSelectedAndNotSelectedInProcedureSuccess,
  updateProductsUsedForCustomerFailure,
  updateProductsUsedForCustomerSuccess,
  validateDeletionFailure,
  validateDeletionSuccess,
} from './setup.actions';
import SetUpTypes from './setup.types';

/* #region PACKS ENDPOINTS */
const getPacksUsedForCustomerApi = (params) => {
  return api.post('/setuptables/get-packs-used-for-customer', params);
};

const getProductsSelectedInPackApi = (params) => {
  return api.post('/setuptables/get-products-selected-in-pack', params);
};

const getProductsNotSelectedInPackApi = (params) => {
  return api.post('/setuptables/get-products-not-selected-in-pack', params);
};

const updatePacksUsedForCustomerApi = (params) => {
  return api.post('/setuptables/update-packs-used-for-customer', params);
};

const updateProductsSelectedAndNotSelectedInPackApi = (params) => {
  return api.post('/setuptables/update-products-selected-and-not-selected-in-pack', params);
};

const addProductToPackApi = (params) => {
  return api.post('/setuptables/add-product-to-pack', params);
};
/* #endregion */

/* #region PROCEDURES ENDPOINTS */
const getProceduresUsedForCustomerApi = (params) => {
  return api.post('/setuptables/get-procedures-used-for-customer', params);
};

const getProductsSelectedInProcedureApi = (params) => {
  return api.post('/setuptables/get-products-selected-in-procedure', params);
};

const getProductsNotSelectedInProcedureApi = (params) => {
  return api.post('/setuptables/get-products-not-selected-in-procedure', params);
};

const updateProceduresUsedForCustomerApi = (params) => {
  return api.post('/setuptables/update-procedures-used-for-customer', params);
};

const updateProductsSelectedAndNotSelectedInProcedureApi = (params) => {
  return api.post('/setuptables/update-products-selected-and-not-selected-in-procedure', params);
};

const addProductToProcedureApi = (params) => {
  return api.post('/setuptables/add-product-to-procedure', params);
};
/* #endregion */

/* #region ORDERS ENDPOINTS */
const getOrdersUsedForCustomerApi = (params) => {
  return api.post('/setuptables/get-orders-used-by-customer', params);
};

const getItemsSelectedInOrderApi = (params) => {
  return api.post('/setuptables/get-items-selected-in-order', params);
};

const getItemsNotSelectedInOrderApi = (params) => {
  return api.post('/setuptables/get-items-not-selected-in-order', params);
};

const updateOrdersUsedForCustomerApi = (params) => {
  return api.post('/setuptables/update-orders-used-by-customer', params);
};

const updateItemsSelectedAndNotSelectedInOrderApi = (params) => {
  return api.post('/setuptables/update-items-selected-and-not-selected-in-order', params);
};

const addItemToOrderApi = (params) => {
  return api.post('/setuptables/add-item-to-order', params);
};

const importOrdersApi = (params) => {
  return api.post('setuptables/import-orders-for-customer', params);
};
/* #endregion */

/* #region CUSTOMERS ENDPOINTS */
const getPageOfCustomersApi = (params) => {
  return api.post('/setuptables/get-page-of-customer', params);
};

const updateCustomersApi = (params) => {
  return api.post('/setuptables/update-customers', params);
};

const getCustomerAccountsApi = (params) => {
  return api.post('/setuptables/get-customer-accounts', params);
};
/* #endregion */

/* #region DEPARTMENTS ENDPOINTS */
const getDepartmentsForCustomerApi = (params) => {
  return api.post('/setuptables/get-departments-used-for-customer', params);
};

const updateDepartmentsUsedForCustomerApi = (params) => {
  return api.post('/setuptables/update-departments-used-for-customer', params);
};

const departmentAccountsTypeApi = (params) => {
  return api.post('setuptables/get-department-accounts', params);
};
/* #endregion */

/* #region PRODUCTS */
const getProductsForCustomerApi = (params) => {
  return api.post('setuptables/get-products-used-for-customer', params);
};

const updateProductsUsedForCustomerApi = (params) => {
  return api.post('setuptables/update-products-used-for-customer', params);
};

const importProductsApi = (params) => {
  return api.post('setuptables/import-products-for-customer', params);
};

/* #endregion */

/* #region COMMON SETUP ENDPOINTS  */
const getHandheldNumbersApi = (params) => {
  return api.post('/setuptables/get-hand-held-numbers', params);
};

const getInventoryFlowApi = (params) => {
  return api.post('/setuptables/get-inventory-flow', params);
};

const getOrderTypesApi = (params) => {
  return api.post('/setuptables/get-order-types', params);
};

const getItemAccountsApi = (params) => {
  return api.post('/setuptables/get-item-accounts', params);
};

const validateDeletionApi = (params) => {
  return api.post('/setuptables/setup-validate-deletion', params);
};

const inactivateApi = (params) => {
  return api.post('/setuptables/setup-inactivate', params);
};

const getSnapshotApi = (params) => {
  return api.post('/setuptables/get-snapshot', params);
};

const moveToRowApi = (params) => {
  return api.post('/setuptables/move-to-row', params);
};
/* #endregion */

/* #region PACKS */
export function* getPacksUsedForCustomer(action) {
  try {
    const params = action.payload;
    const { data } = yield call(getPacksUsedForCustomerApi, params);

    yield put(getPacksUsedForCustomerSuccess(data));
  } catch (error) {
    yield put(getPacksUsedForCustomerFailure(error));
  }
}

export function* getProductsSelectedInPack(action) {
  try {
    const params = action.payload;
    const { data } = yield call(getProductsSelectedInPackApi, params);

    yield put(getProductsSelectedInPackSuccess(data));
  } catch (error) {
    yield put(getProductsSelectedInPackFailure(error));
  }
}

export function* getProductsNotSelectedInPack(action) {
  try {
    const params = action.payload;
    const { data } = yield call(getProductsNotSelectedInPackApi, params);

    yield put(getProductsNotSelectedInPackSuccess(data));
  } catch (error) {
    yield put(getProductsNotSelectedInPackFailure(error));
  }
}

export function* updatePacksUsedForCustomer(action) {
  try {
    const params = action.payload;
    const { data } = yield call(updatePacksUsedForCustomerApi, params);

    yield put(updatePacksUsedForCustomerSuccess(data));
  } catch (error) {
    yield put(updatePacksUsedForCustomerFailure(error));
  }
}

export function* updateProductsSelectedAndNotSelectedInPack(action) {
  try {
    const params = action.payload;
    const { data } = yield call(updateProductsSelectedAndNotSelectedInPackApi, params);

    yield put(updateProductsSelectedAndNotSelectedInPackSuccess(data));
  } catch (error) {
    yield put(updateProductsSelectedAndNotSelectedInPackFailure(error));
  }
}

export function* addProductToPack(action) {
  try {
    const params = action.payload;
    const { data } = yield call(addProductToPackApi, params);

    yield put(addProductToPackSuccess(data));
  } catch (error) {
    yield put(addProductToPackFailure(error));
  }
}
/* #endregion */

/* #region PROCEDURES */
export function* getProceduresUsedForCustomer(action) {
  try {
    const params = action.payload;
    const { data } = yield call(getProceduresUsedForCustomerApi, params);

    yield put(getProceduresUsedForCustomerSuccess(data));
  } catch (error) {
    yield put(getProceduresUsedForCustomerFailure(error));
  }
}

export function* getProductsSelectedInProcedure(action) {
  try {
    const params = action.payload;
    const { data } = yield call(getProductsSelectedInProcedureApi, params);

    yield put(getProductsSelectedInProcedureSuccess(data));
  } catch (error) {
    yield put(getProductsSelectedInProcedureFailure(error));
  }
}

export function* getProductsNotSelectedInProcedure(action) {
  try {
    const params = action.payload;
    const { data } = yield call(getProductsNotSelectedInProcedureApi, params);

    yield put(getProductsNotSelectedInProcedureSuccess(data));
  } catch (error) {
    yield put(getProductsNotSelectedInProcedureFailure(error));
  }
}

export function* updateProceduresUsedForCustomer(action) {
  try {
    const params = action.payload;
    const { data } = yield call(updateProceduresUsedForCustomerApi, params);

    yield put(updateProceduresUsedForCustomerSuccess(data));
  } catch (error) {
    yield put(updateProceduresUsedForCustomerFailure(error));
  }
}

export function* updateProductsSelectedAndNotSelectedInProcedure(action) {
  try {
    const params = action.payload;
    const { data } = yield call(updateProductsSelectedAndNotSelectedInProcedureApi, params);

    yield put(updateProductsSelectedAndNotSelectedInProcedureSuccess(data));
  } catch (error) {
    yield put(updateProductsSelectedAndNotSelectedInProcedureFailure(error));
  }
}

export function* addProductToProcedure(action) {
  try {
    const params = action.payload;
    const { data } = yield call(addProductToProcedureApi, params);

    yield put(addProductToProcedureSuccess(data));
  } catch (error) {
    yield put(addProductToProcedureFailure(error));
  }
}
/* #endregion */

/* #region ORDERS */
export function* getOrdersUsedForCustomer(action) {
  try {
    const params = action.payload;
    const { data } = yield call(getOrdersUsedForCustomerApi, params);

    yield put(getOrdersUsedForCustomerSuccess(data));
  } catch (error) {
    yield put(getOrdersUsedForCustomerFailure(error));
  }
}

export function* getItemsSelectedInOrder(action) {
  try {
    const params = action.payload;
    const { data } = yield call(getItemsSelectedInOrderApi, params);

    yield put(getItemsSelectedInOrderSuccess(data));
  } catch (error) {
    yield put(getItemsSelectedInOrderFailure(error));
  }
}

export function* getItemsNotSelectedInOrder(action) {
  try {
    const params = action.payload;
    const { data } = yield call(getItemsNotSelectedInOrderApi, params);

    yield put(getItemsNotSelectedInOrderSuccess(data));
  } catch (error) {
    yield put(getItemsNotSelectedInOrderFailure(error));
  }
}

export function* updateOrdersUsedForCustomer(action) {
  try {
    const params = action.payload;
    const { data } = yield call(updateOrdersUsedForCustomerApi, params);

    yield put(updateOrdersUsedForCustomerSuccess(data));
  } catch (error) {
    yield put(updateOrdersUsedForCustomerFailure(error));
  }
}

export function* updateItemsSelectedAndNotSelectedInOrder(action) {
  try {
    const params = action.payload;
    const { data } = yield call(updateItemsSelectedAndNotSelectedInOrderApi, params);

    yield put(updateItemsSelectedAndNotSelectedInOrderSuccess(data));
  } catch (error) {
    yield put(updateItemsSelectedAndNotSelectedInOrderFailure(error));
  }
}

export function* addItemToOrder(action) {
  try {
    const params = action.payload;
    const { data } = yield call(addItemToOrderApi, params);

    yield put(addItemToOrderSuccess(data));
  } catch (error) {
    yield put(addItemToOrderFailure(error));
  }
}

export function* importOrders(action) {
  try {
    const params = action.payload;
    console.log('params', params)
    const { data } = yield call(importOrdersApi, params);

    yield put(importOrdersSuccess(data));
  } catch (error) {
    yield put(importOrdersFailure(error));
  }
}

/* #endregion */

/* #region CUSTOMERS */
export function* getPageOfCustomers(action) {
  try {
    const params = action.payload;
    const { data } = yield call(getPageOfCustomersApi, params);

    yield put(getPageOfCustomersSuccess(data));
  } catch (error) {
    yield put(getPageOfCustomersFailure(error));
  }
}

export function* updateCustomers(action) {
  try {
    const params = action.payload;
    const { data } = yield call(updateCustomersApi, params);

    yield put(updateCustomersSuccess(data));
  } catch (error) {
    yield put(updateCustomersFailure(error));
  }
}

export function* getCustomerAccounts(action) {
  try {
    const params = action.payload;
    const { data } = yield call(getCustomerAccountsApi, params);

    yield put(getCustomerAccountsSuccess(data));
  } catch (error) {
    yield put(getCustomerAccountsFailure(error));
  }
}
/* #endregion */

/* #region DEPARTMENTS */
export function* getDepartmentsForCustomer(action) {
  try {
    const params = action.payload;
    const { data } = yield call(getDepartmentsForCustomerApi, params);

    yield put(getDepartmentsUsedForCustomerSuccess(data));
  } catch (error) {
    yield put(getDepartmentsUsedForCustomerFailure(error));
  }
}

export function* updateDepartmentsUsedForCustomer(action) {
  try {
    const params = action.payload;
    const { data } = yield call(updateDepartmentsUsedForCustomerApi, params);

    yield put(updateDepartmentsUsedForCustomerSuccess(data));
  } catch (error) {
    yield put(updateDepartmentsUsedForCustomerFailure(error));
  }
}

export function* getDepartmentAccounts(action) {
  try {
    const params = action.payload;
    const { data } = yield call(departmentAccountsTypeApi, params);

    yield put(getDepartmentsAcctTypeSuccess(data));
  } catch (error) {
    yield put(getDepartmentsAcctTypeFailure(error));
  }
}
/* #endregion */

/* #region PRODUCTS */
export function* getProductsForCustomer(action) {
  try {
    const params = action.payload;
    const { data } = yield call(getProductsForCustomerApi, params);

    yield put(getProductsUsedForCustomerSuccess(data));
  } catch (error) {
    yield put(getProductsUsedForCustomerFailure(error));
  }
}

export function* updateProductsUsedForCustomer(action) {
  try {
    const params = action.payload;
    const { data } = yield call(updateProductsUsedForCustomerApi, params);

    yield put(updateProductsUsedForCustomerSuccess(data));
  } catch (error) {
    yield put(updateProductsUsedForCustomerFailure(error));
  }
}

export function* importProducts(action) {
  try {
    const params = action.payload;
    const { data } = yield call(importProductsApi, params);

    yield put(importProductsSuccess(data));
  } catch (error) {
    yield put(importProductsFailure(error));
  }
}

/* #endregion */

/* #region COMMON */
export function* getItemAccounts(action) {
  try {
    const params = action.payload;
    const { data } = yield call(getItemAccountsApi, params);

    yield put(getItemAccountsSuccess(data));
  } catch (error) {
    yield put(getItemAccountsFailure(error));
  }
}

export function* getHandheldNumbers(action) {
  try {
    const params = action.payload;
    const { data } = yield call(getHandheldNumbersApi, params);

    yield put(getHandheldNumbersSuccess(data));
  } catch (error) {
    yield put(getHandheldNumbersFailure(error));
  }
}

export function* getInventoryFlow(action) {
  try {
    const params = action.payload;
    const { data } = yield call(getInventoryFlowApi, params);

    yield put(getInventoryFlowSuccess(data));
  } catch (error) {
    yield put(getInventoryFlowFailure(error));
  }
}

export function* getOrderTypes(action) {
  try {
    const params = action.payload;
    const { data } = yield call(getOrderTypesApi, params);

    yield put(getOrderTypesSuccess(data));
  } catch (error) {
    yield put(getOrderTypesFailure(error));
  }
}

export function* validateDeletion(action) {
  try {
    const params = action.payload;
    const { data } = yield call(validateDeletionApi, params);
    if (action.payload && action.payload.apiCall === 'ValidateAccountDeletion') {
      if (data.data.valid && data.data.code === 'Success') {
        // Trigger account deletion automatically
        // const deleteParams = { ...action.payload };
        // deleteParams.apiCall = 'InactivateAccount';
        // const deleteData = yield call(inactivateApi, deleteParams);
        // TODO: Handle deleting account data elsewhere?
      }
    }

    yield put(validateDeletionSuccess(data));
  } catch (error) {
    yield put(validateDeletionFailure(error));
  }
}

export function* inactivate(action) {
  try {
    const params = action.payload;
    const { data } = yield call(inactivateApi, params);

    yield put(inactivateSuccess(data));
  } catch (error) {
    yield put(inactivateFailure(error));
  }
}

export function* getSnapshot(action) {
  try {
    const params = action.payload;
    const { data } = yield call(getSnapshotApi, params);

    yield put(getSnapshotSuccess(data));
  } catch (error) {
    yield put(getSnapshotFailure(error));
  }
}

export function* moveToRow(action) {
  try {
    const params = action.payload;
    const { data } = yield call(moveToRowApi, params);

    yield put(moveToRowSuccess(data));
  } catch (error) {
    yield put(moveToRowFailure(error));
  }
}
/* #endregion */

/* #region PACKS CALLS */
export function* onGetPacksUsedForCustomerStart() {
  yield takeLatest(SetUpTypes.GET_PACKS_USED_FOR_CUSTOMER_START, getPacksUsedForCustomer);
}

export function* onGetProductsSelectedInPackStart() {
  yield takeLatest(SetUpTypes.GET_PRODUCTS_SELECTED_IN_PACK_START, getProductsSelectedInPack);
}

export function* onGetProductsNoySelectedInPackStart() {
  yield takeLatest(
    SetUpTypes.GET_PRODUCTS_NOT_SELECTED_IN_PACK_START,
    getProductsNotSelectedInPack,
  );
}

export function* onUpdatePacksUsedForCustomerStart() {
  yield takeLatest(SetUpTypes.UPDATE_PACKS_USED_FOR_CUSTOMER_START, updatePacksUsedForCustomer);
}

export function* onUpdateProductsSelectedAndNotSelectedInPackStart() {
  yield takeLatest(
    SetUpTypes.UPDATE_PRODUCTS_SELECTED_AND_NOT_SELECTED_IN_PACK_START,
    updateProductsSelectedAndNotSelectedInPack,
  );
}

export function* onAddProductToPackStart() {
  yield takeLatest(SetUpTypes.ADD_PRODUCT_TO_PACK_START, addProductToPack);
}
/* #endregion */

/* #region PROCEDURES CALLS */
export function* onGetProceduresUsedForCustomerStart() {
  yield takeLatest(SetUpTypes.GET_PROCEDURES_USED_FOR_CUSTOMER_START, getProceduresUsedForCustomer);
}

export function* onGetProductsSelectedInProcedureStart() {
  yield takeLatest(
    SetUpTypes.GET_PRODUCTS_SELECTED_IN_PROCEDURE_START,
    getProductsSelectedInProcedure,
  );
}

export function* onGetProductsNoySelectedInProcedureStart() {
  yield takeLatest(
    SetUpTypes.GET_PRODUCTS_NOT_SELECTED_IN_PROCEDURE_START,
    getProductsNotSelectedInProcedure,
  );
}

export function* onUpdateProceduresUsedForCustomerStart() {
  yield takeLatest(
    SetUpTypes.UPDATE_PROCEDURES_USED_FOR_CUSTOMER_START,
    updateProceduresUsedForCustomer,
  );
}

export function* onUpdateProductsSelectedAndNotSelectedInProcedureStart() {
  yield takeLatest(
    SetUpTypes.UPDATE_PRODUCTS_SELECTED_AND_NOT_SELECTED_IN_PROCEDURE_START,
    updateProductsSelectedAndNotSelectedInProcedure,
  );
}

export function* onAddProductToProcedureStart() {
  yield takeLatest(SetUpTypes.ADD_PRODUCT_TO_PROCEDURE_START, addProductToProcedure);
}
/* #endregion */

/* #region ORDERS CALLS */
export function* onGetOrdersUsedForCustomerStart() {
  yield takeLatest(SetUpTypes.GET_ORDERS_USED_FOR_CUSTOMER_START, getOrdersUsedForCustomer);
}

export function* onGetItemsSelectedInOrderStart() {
  yield takeLatest(SetUpTypes.GET_ITEMS_SELECTED_IN_ORDER_START, getItemsSelectedInOrder);
}

export function* onGetItemsNoySelectedInOrderStart() {
  yield takeLatest(SetUpTypes.GET_ITEMS_NOT_SELECTED_IN_ORDER_START, getItemsNotSelectedInOrder);
}

export function* onUpdateOrdersUsedForCustomerStart() {
  yield takeLatest(SetUpTypes.UPDATE_ORDERS_USED_FOR_CUSTOMER_START, updateOrdersUsedForCustomer);
}

export function* onUpdateItemsSelectedAndNotSelectedInOrderStart() {
  yield takeLatest(
    SetUpTypes.UPDATE_ITEMS_SELECTED_AND_NOT_SELECTED_IN_ORDER_START,
    updateItemsSelectedAndNotSelectedInOrder,
  );
}

export function* onImportOrdersStart() {
  yield takeLatest(
    SetUpTypes.IMPORT_ORDERS_START,
    importOrders,
  );
}

export function* onAddItemToOrderStart() {
  yield takeLatest(SetUpTypes.ADD_ITEM_TO_ORDER_START, addItemToOrder);
}
/* #endregion */

/* #region CUSTOMERS CALLS */
export function* onGetPageOfCustomersStart() {
  yield takeLatest(SetUpTypes.GET_PAGE_OF_CUSTOMERS_START, getPageOfCustomers);
}

export function* onUpdateCustomersStart() {
  yield takeLatest(SetUpTypes.UPDATE_CUSTOMERS_START, updateCustomers);
}

export function* onGetCustomerAccountsStart() {
  yield takeLatest(SetUpTypes.GET_CUSTOMER_ACCOUNTS_START, getCustomerAccounts);
}
/* #endregion */

/* #region DEPARTMENTS CALLS */
export function* onGetDepartmentsForCustomerStart() {
  yield takeLatest(SetUpTypes.GET_DEPARTMENTS_USED_FOR_CUSTOMER_START, getDepartmentsForCustomer);
}

export function* onUpdateDepartmentsUsedForCustomerStart() {
  yield takeLatest(
    SetUpTypes.UPDATE_DEPARTMENTS_USED_FOR_CUSTOMER_START,
    updateDepartmentsUsedForCustomer,
  );
}

export function* onGetDepartmentAccountsStart() {
  yield takeLatest(SetUpTypes.GET_DEPARTMENTS_ACCT_TYPE_START, getDepartmentAccounts);
}
/* #endregion */

/* #region PRODUCTS CALLS */
export function* onGetProductsForCustomerStart() {
  yield takeLatest(SetUpTypes.GET_PRODUCTS_USED_FOR_CUSTOMER_START, getProductsForCustomer);
}

export function* onUpdateProductsUsedForCustomerStart() {
  yield takeLatest(
    SetUpTypes.UPDATE_PRODUCTS_USED_FOR_CUSTOMER_START,
    updateProductsUsedForCustomer,
  );
}

export function* onImportProductStart() {
  yield takeLatest(
    SetUpTypes.IMPORT_PRODUCTS_START,
    importProducts,
  );
}

/* #endregion */

/* #region COMMON CALLS */
export function* onGetItemAccountsStart() {
  yield takeLatest(SetUpTypes.GET_ITEM_ACCOUNTS_START, getItemAccounts);
}

export function* onGetHandheldNumbersStart() {
  yield takeLatest(SetUpTypes.GET_HANDHELD_NUMBERS_START, getHandheldNumbers);
}

export function* onGetInventoryFlowStart() {
  yield takeLatest(SetUpTypes.GET_INVENTORY_FLOW_START, getInventoryFlow);
}

export function* onGetOrderTypesStart() {
  yield takeLatest(SetUpTypes.GET_ORDER_TYPES_START, getOrderTypes);
}

export function* onValidateDeletion() {
  yield takeLatest(SetUpTypes.VALIDATE_DELETION_START, validateDeletion);
}

export function* onInactivate() {
  yield takeLatest(SetUpTypes.INACTIVATE_START, inactivate);
}

export function* onGetSnapshot() {
  yield takeLatest(SetUpTypes.GET_SNAPSHOT_START, getSnapshot);
}

export function* onMoveToRow() {
  yield takeLatest(SetUpTypes.MOVE_TO_ROW_START, moveToRow);
}
/* #endregion */

export function* setUpSagas() {
  yield all([
    // PACKS
    call(onGetPacksUsedForCustomerStart),
    call(onGetProductsSelectedInPackStart),
    call(onGetProductsNoySelectedInPackStart),
    call(onUpdatePacksUsedForCustomerStart),
    call(onUpdateProductsSelectedAndNotSelectedInPackStart),
    call(onAddProductToPackStart),
    // PROCEDURES
    call(onGetProceduresUsedForCustomerStart),
    call(onGetProductsSelectedInProcedureStart),
    call(onGetProductsNoySelectedInProcedureStart),
    call(onUpdateProceduresUsedForCustomerStart),
    call(onUpdateProductsSelectedAndNotSelectedInProcedureStart),
    call(onAddProductToProcedureStart),
    // ORDERS
    call(onGetOrdersUsedForCustomerStart),
    call(onGetItemsSelectedInOrderStart),
    call(onGetItemsNoySelectedInOrderStart),
    call(onUpdateOrdersUsedForCustomerStart),
    call(onUpdateItemsSelectedAndNotSelectedInOrderStart),
    call(onAddItemToOrderStart),
    call(onImportOrdersStart),
    // SETUP CUSTOMERS
    call(onGetPageOfCustomersStart),
    call(onUpdateCustomersStart),
    call(onGetCustomerAccountsStart),
    // DEPARTMENTS
    call(onGetDepartmentsForCustomerStart),
    call(onUpdateDepartmentsUsedForCustomerStart),
    call(onGetDepartmentAccountsStart),
    // PRODUCTS
    call(onGetProductsForCustomerStart),
    call(onUpdateProductsUsedForCustomerStart),
    call(onImportProductStart),
    // COMMON
    call(onGetHandheldNumbersStart),
    call(onGetInventoryFlowStart),
    call(onGetOrderTypesStart),
    call(onGetItemAccountsStart),
    call(onValidateDeletion),
    call(onInactivate),
    call(onGetSnapshot),
    call(onMoveToRow),
  ]);
}

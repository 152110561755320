import { createSelector } from 'reselect';

const selectCustomerSelected = (state) => state.setup_advanced_user_customer_selected;

export const selectCustomersSelected = createSelector(
  [selectCustomerSelected],
  (customerSelected) => customerSelected.customer_selected,
);

export const selectCustomersToAdd = createSelector(
  [selectCustomerSelected],
  (customerSelected) => customerSelected.customers_to_add,
);

export const selectCustomersToDelete = createSelector(
  [selectCustomerSelected],
  (customerSelected) => customerSelected.customers_to_delete,
);

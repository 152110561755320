export const ReportPageID = {
  main: 'main',

  setBudget: 'budget',
  setCensus: 'census',

  // ITEM USAGE
  itemUsage: 'item-usage',
  itemUsageItemDescription: 'item-usage-item-description',
  itemUsageItemDescriptionCustomer: 'item-usage-item-description-by-customer',
  itemUsageItemDescriptionDepartment: 'item-usage-item-description-by-department',
  itemUsageItemDescriptionDepartmentAccount: 'item-usage-item-description-by-department-account',
  itemUsageItemDescriptionOrder: 'item-usage-item-description-by-order',
  itemUsageItemAccount: 'item-usage-item-account',
  itemUsageItemAccountCustomer: 'item-usage-item-account-by-customer',
  itemUsageItemAccountDepartment: 'item-usage-item-account-by-department',
  itemUsageItemAccountDepartmentAccount: 'item-usage-item-account-by-department-account',
  itemUsageItemAccountOrder: 'item-usage-item-account-by-order',
  itemUsageLinenRoom: 'item-usage-linen-room',

  // ITEM FILL RATES
  itemFillRates: 'item-fill-rates',
  itemFillRatesDescription: 'item-fill-rates-item-description',
  itemFillRatesItemDescriptionCustomer: 'item-fill-rates-item-description-by-customer',
  itemFillRatesItemDescriptionDepartment: 'item-ufill-rates-item-description-by-department',
  itemFillRatesItemDescriptionOrder: 'item-ufill-rates-item-description-by-order',
  itemFillRatesItemAccount: 'item-fill-rates-item-account',
  itemFillRatesItemAccountCustomer: 'item-fill-rates-item-account-by-customer',
  itemFillRatesItemAccountDepartment: 'item-fill-rates-item-account-by-department',
  itemFillRatesItemAccountOrder: 'item-fill-rates-item-account-by-order',
  itemFillRatesLinenRoomReceivingAccuracy: 'item-fill-rates-linen-room-receiving-accuracy',
  itemFillRatesLinenRoomSoilCleanComparison: 'item-fill-rates-linen-room-soil-clean-comparison',
  itemFillRatesLinenRoomDistributionAccuracy: 'item-fill-rates-linen-room-distribution-accuracy',

  // ITEM INVENTORY
  itemInventory: 'item-inventory',
  itemInventoryDescription: 'item-inventory-item-description',
  itemInventoryItemDescriptionCustomer: 'item-inventory-item-description-by-customer',
  itemInventoryItemDescriptionDepartment: 'item-inventory-item-description-by-department',
  itemInventoryItemDescriptionOrder: 'item-inventory-item-description-by-order',
  itemInventoryItemAccount: 'item-inventory-item-account',
  itemInventoryItemAccountCustomer: 'item-inventory-item-account-by-customer',
  itemInventoryItemAccountDepartment: 'item-inventory-item-account-by-department',
  itemInventoryItemAccountOrder: 'item-inventory-item-account-by-order',
  itemInventoryInjectedRetired: 'item-inventory-injected-retired',
  itemInventoryPhysicalInventoryQuantities: 'item-inventory-physical-inventory-quantities',
  itemInventoryPhysicalInventory: 'item-inventory-physical-inventory',
  itemInventoryInventoryLoss: 'item-inventory-inventory-loss',
  itemInventoryInventoryProjections: 'item-inventory-inventory-projections',

  // ORDER SCHEDULES
  orderOrderSchedules: 'order-schedules',

  // PERIOD TRENDING
  periodTrending: 'period-trending',
  periodTrendingQuantitiesItemDescription: 'period-trending-quantities-by-item-description',
  periodTrendingQuantitiesItemAccount: 'period-trending-quantities-by-item-account',
  periodTrendingQuantitiesCustomerDepartmentOrder:
    'period-trending-quantities-by-customer-department-order',
  periodTrendingWeightCustomerDepartmentOrder:
    'period-trending-weight-by-customer-department-order',
  periodTrendingChargeCustomerDepartmentOrder:
    'period-trending-charge-by-customer-department-order',
  periodTrendingLinenRoomQuantitiesCustomer: 'period-trending-linen-room-quantities-by-customer',
  periodTrendingLinenRoomQuantitiesItemDescription:
    'period-trending-linen-room-quantities-by-item-description',
  periodTrendingLinenRoomWeightCustomer: 'period-trending-linen-room-weight-by-customer',
  periodTrendingLinenRoomChargeCustomer: 'period-trending-linen-room-charge-by-customer',

  // CHARGES COSTS
  chargesCosts: 'charges-costs',
  chargesCostsChargeReconciliation: 'charges-costs-charge-reconciliation',
  chargesCostsLinenRoomCostCleanWeight: 'charges-costs-cost-clean-weight',
  chargesCostsLinenRoomCostSoilWeight: 'charges-costs-soil-weight',
};

export const ReportDataTableConfig = {
  // [ReportPageID.itemUsageItemDescriptionCustomer]: true,
  // [ReportPageID.itemUsageItemDescriptionDepartment]: true,
  // [ReportPageID.itemUsageItemDescriptionDepartmentAccount]: true,
  // [ReportPageID.itemUsageItemDescriptionOrder]: true,
  // [ReportPageID.itemUsageItemAccountCustomer]: true,
  // [ReportPageID.itemUsageItemAccountDepartment]: true,
  // [ReportPageID.itemUsageItemAccountDepartmentAccount]: true,
  // [ReportPageID.itemUsageItemAccountOrder]: true,
  // [ReportPageID.itemUsageLinenRoom]: true,

  // [ReportPageID.itemFillRatesItemDescriptionCustomer]: true,
  // [ReportPageID.itemFillRatesItemDescriptionDepartment]: true,
  // [ReportPageID.itemFillRatesItemDescriptionOrder]: true,
  // [ReportPageID.itemFillRatesItemAccountCustomer]: true,
  // [ReportPageID.itemFillRatesItemAccountDepartment]: true,
  // [ReportPageID.itemFillRatesItemAccountOrder]: true,
  // [ReportPageID.itemFillRatesLinenRoomReceivingAccuracy]: true,
  // [ReportPageID.itemFillRatesLinenRoomSoilCleanComparison]: true,
  // [ReportPageID.itemFillRatesLinenRoomDistributionAccuracy]: true,

  // [ReportPageID.itemInventoryItemDescriptionCustomer]: true,
  // [ReportPageID.itemInventoryItemDescriptionDepartment]: true,
  // [ReportPageID.itemInventoryItemDescriptionOrder]: true,
  // [ReportPageID.itemInventoryItemAccountCustomer]: true,
  // [ReportPageID.itemInventoryItemAccountDepartment]: true,
  // [ReportPageID.itemInventoryItemAccountOrder]: true,
  [ReportPageID.itemInventoryInjectedRetired]: {
    primaryKey: 'Description',
    columnFormat: {
      sortable: true,
      search: true,
    },
  },
  [ReportPageID.itemInventoryPhysicalInventoryQuantities]: {
    primaryKey: 'Description',
    columnFormat: {
      sortable: true,
      search: true,
    },
  },
  [ReportPageID.itemInventoryPhysicalInventory]: {
    primaryKey: 'Description',
    columnFormat: {
      sortable: true,
      search: true,
    },
  },
  [ReportPageID.itemInventoryInventoryLoss]: {
    primaryKey: 'Description',
    columnFormat: {
      sortable: true,
      search: true,
    },
  },
  [ReportPageID.itemInventoryInventoryProjections]: {
    primaryKey: 'Description',
    columnFormat: {
      sortable: true,
      search: true,
    },
  },

  [ReportPageID.orderOrderSchedules]: {
    primaryKey: 'RcID',
    columns: [
      { property: 'Order', header: 'Order', sortable: true, search: true },
      { property: 'Department', header: 'Department', sortable: true, search: true },
      {
        property: 'Cost Account',
        header: 'Cost Account',
        sortable: true,
        search: true,
      },
      {
        property: 'Department Account',
        header: 'Department Account',
        sortable: true,
        search: true,
      },
      { property: 'UsefDelvMond', header: 'Monday', sortable: true, search: true },
      { property: 'UsefDelvTues', header: 'Tuesday', sortable: true, search: true },
      { property: 'UsefDelvWedn', header: 'Wednesday', sortable: true, search: true },
      { property: 'UsefDelvThur', header: 'Thursday', sortable: true, search: true },
      { property: 'UsefDelvFrid', header: 'Friday', sortable: true, search: true },
      { property: 'UsefDelvSatr', header: 'Saturday', sortable: true, search: true },
      { property: 'UsefDelvSund', header: 'Sunday', sortable: true, search: true },
    ],
  },

  [ReportPageID.periodTrendingQuantitiesItemDescription]: {
    primaryKey: 'Description',
    columnFormat: {
      sortable: true,
      search: true,
    },
  },
  [ReportPageID.periodTrendingQuantitiesItemAccount]: {
    primaryKey: 'Description',
    columnFormat: {
      sortable: true,
      search: true,
    },
  },
  // [ReportPageID.periodTrendingQuantitiesCustomerDepartmentOrder]: true,
  // [ReportPageID.periodTrendingWeightCustomerDepartmentOrder]: true,
  // [ReportPageID.periodTrendingChargeCustomerDepartmentOrder]: true,
  // [ReportPageID.periodTrendingLinenRoomQuantitiesCustomer]: true,
  [ReportPageID.periodTrendingLinenRoomQuantitiesItemDescription]: {
    primaryKey: 'Description',
    columnFormat: {
      sortable: true,
      search: true,
    },
  },
  // [ReportPageID.periodTrendingLinenRoomWeightCustomer]: true,
  // [ReportPageID.periodTrendingLinenRoomChargeCustomer]: true,

  // [ReportPageID.chargesCostsChargeReconciliation]: true,
  // [ReportPageID.chargesCostsLinenRoomCostCleanWeight]: true,
  // [ReportPageID.chargesCostsLinenRoomCostSoilWeight]: true,
};

export const ReportButtonFunctionality = {
  emailDocument: {
    [ReportPageID.itemUsageItemDescriptionCustomer]: true,
    [ReportPageID.itemUsageItemDescriptionDepartment]: true,
    [ReportPageID.itemUsageItemDescriptionDepartmentAccount]: true,
    [ReportPageID.itemUsageItemDescriptionOrder]: true,
    [ReportPageID.itemUsageItemAccountCustomer]: true,
    [ReportPageID.itemUsageItemAccountDepartment]: true,
    [ReportPageID.itemUsageItemAccountDepartmentAccount]: true,
    [ReportPageID.itemUsageItemAccountOrder]: true,
    [ReportPageID.itemUsageLinenRoom]: true,

    [ReportPageID.itemFillRatesItemDescriptionCustomer]: true,
    [ReportPageID.itemFillRatesItemDescriptionDepartment]: true,
    [ReportPageID.itemFillRatesItemDescriptionOrder]: true,
    [ReportPageID.itemFillRatesItemAccountCustomer]: true,
    [ReportPageID.itemFillRatesItemAccountDepartment]: true,
    [ReportPageID.itemFillRatesItemAccountOrder]: true,
    [ReportPageID.itemFillRatesLinenRoomReceivingAccuracy]: true,
    [ReportPageID.itemFillRatesLinenRoomSoilCleanComparison]: true,
    [ReportPageID.itemFillRatesLinenRoomDistributionAccuracy]: true,

    [ReportPageID.itemInventoryItemDescriptionCustomer]: true,
    [ReportPageID.itemInventoryItemDescriptionDepartment]: true,
    [ReportPageID.itemInventoryItemDescriptionOrder]: true,
    [ReportPageID.itemInventoryItemAccountCustomer]: true,
    [ReportPageID.itemInventoryItemAccountDepartment]: true,
    [ReportPageID.itemInventoryItemAccountOrder]: true,
    [ReportPageID.itemInventoryInjectedRetired]: true,
    [ReportPageID.itemInventoryPhysicalInventoryQuantities]: true,
    [ReportPageID.itemInventoryPhysicalInventory]: true,
    [ReportPageID.itemInventoryInventoryLoss]: true,
    [ReportPageID.itemInventoryInventoryProjections]: true,

    [ReportPageID.orderOrderSchedules]: true,

    [ReportPageID.periodTrendingQuantitiesItemDescription]: true,
    [ReportPageID.periodTrendingQuantitiesItemAccount]: true,
    [ReportPageID.periodTrendingQuantitiesCustomerDepartmentOrder]: true,
    [ReportPageID.periodTrendingWeightCustomerDepartmentOrder]: true,
    [ReportPageID.periodTrendingChargeCustomerDepartmentOrder]: true,
    [ReportPageID.periodTrendingLinenRoomQuantitiesCustomer]: true,
    [ReportPageID.periodTrendingLinenRoomQuantitiesItemDescription]: true,
    [ReportPageID.periodTrendingLinenRoomWeightCustomer]: true,
    [ReportPageID.periodTrendingLinenRoomChargeCustomer]: true,

    [ReportPageID.chargesCostsChargeReconciliation]: true,
    [ReportPageID.chargesCostsLinenRoomCostCleanWeight]: true,
    [ReportPageID.chargesCostsLinenRoomCostSoilWeight]: true,
  },
  emailSpreadsheet: {
    [ReportPageID.itemUsageItemDescriptionCustomer]: true,
    [ReportPageID.itemUsageItemDescriptionDepartment]: true,
    [ReportPageID.itemUsageItemDescriptionDepartmentAccount]: true,
    [ReportPageID.itemUsageItemDescriptionOrder]: true,
    [ReportPageID.itemUsageItemAccountCustomer]: true,
    [ReportPageID.itemUsageItemAccountDepartment]: true,
    [ReportPageID.itemUsageItemAccountDepartmentAccount]: true,
    [ReportPageID.itemUsageItemAccountOrder]: true,
    [ReportPageID.itemUsageLinenRoom]: true,

    [ReportPageID.itemFillRatesItemDescriptionCustomer]: true,
    [ReportPageID.itemFillRatesItemDescriptionDepartment]: true,
    [ReportPageID.itemFillRatesItemDescriptionOrder]: true,
    [ReportPageID.itemFillRatesItemAccountCustomer]: true,
    [ReportPageID.itemFillRatesItemAccountDepartment]: true,
    [ReportPageID.itemFillRatesItemAccountOrder]: true,
    [ReportPageID.itemFillRatesLinenRoomReceivingAccuracy]: true,
    [ReportPageID.itemFillRatesLinenRoomSoilCleanComparison]: true,
    [ReportPageID.itemFillRatesLinenRoomDistributionAccuracy]: true,

    [ReportPageID.itemInventoryItemDescriptionCustomer]: true,
    [ReportPageID.itemInventoryItemDescriptionDepartment]: true,
    [ReportPageID.itemInventoryItemDescriptionOrder]: true,
    [ReportPageID.itemInventoryItemAccountCustomer]: true,
    [ReportPageID.itemInventoryItemAccountDepartment]: true,
    [ReportPageID.itemInventoryItemAccountOrder]: true,
    [ReportPageID.itemInventoryInjectedRetired]: true,
    [ReportPageID.itemInventoryPhysicalInventoryQuantities]: true,
    [ReportPageID.itemInventoryPhysicalInventory]: true,
    [ReportPageID.itemInventoryInventoryLoss]: true,
    [ReportPageID.itemInventoryInventoryProjections]: true,

    [ReportPageID.orderOrderSchedules]: true,

    [ReportPageID.periodTrendingQuantitiesItemDescription]: true,
    [ReportPageID.periodTrendingQuantitiesItemAccount]: true,
    [ReportPageID.periodTrendingQuantitiesCustomerDepartmentOrder]: true,
    [ReportPageID.periodTrendingWeightCustomerDepartmentOrder]: true,
    [ReportPageID.periodTrendingChargeCustomerDepartmentOrder]: true,
    [ReportPageID.periodTrendingLinenRoomQuantitiesCustomer]: true,
    [ReportPageID.periodTrendingLinenRoomQuantitiesItemDescription]: true,
    [ReportPageID.periodTrendingLinenRoomWeightCustomer]: true,
    [ReportPageID.periodTrendingLinenRoomChargeCustomer]: true,

    [ReportPageID.chargesCostsChargeReconciliation]: true,
    [ReportPageID.chargesCostsLinenRoomCostCleanWeight]: true,
    [ReportPageID.chargesCostsLinenRoomCostSoilWeight]: true,
  },
  viewDocument: {
    [ReportPageID.itemUsageItemDescriptionCustomer]: true,
    [ReportPageID.itemUsageItemDescriptionDepartment]: true,
    [ReportPageID.itemUsageItemDescriptionDepartmentAccount]: true,
    [ReportPageID.itemUsageItemDescriptionOrder]: true,
    [ReportPageID.itemUsageItemAccountCustomer]: true,
    [ReportPageID.itemUsageItemAccountDepartment]: true,
    [ReportPageID.itemUsageItemAccountDepartmentAccount]: true,
    [ReportPageID.itemUsageItemAccountOrder]: true,
    [ReportPageID.itemUsageLinenRoom]: true,

    [ReportPageID.itemFillRatesItemDescriptionCustomer]: true,
    [ReportPageID.itemFillRatesItemDescriptionDepartment]: true,
    [ReportPageID.itemFillRatesItemDescriptionOrder]: true,
    [ReportPageID.itemFillRatesItemAccountCustomer]: true,
    [ReportPageID.itemFillRatesItemAccountDepartment]: true,
    [ReportPageID.itemFillRatesItemAccountOrder]: true,
    [ReportPageID.itemFillRatesLinenRoomReceivingAccuracy]: true,
    [ReportPageID.itemFillRatesLinenRoomSoilCleanComparison]: true,
    [ReportPageID.itemFillRatesLinenRoomDistributionAccuracy]: true,

    [ReportPageID.itemInventoryItemDescriptionCustomer]: true,
    [ReportPageID.itemInventoryItemDescriptionDepartment]: true,
    [ReportPageID.itemInventoryItemDescriptionOrder]: true,
    [ReportPageID.itemInventoryItemAccountCustomer]: true,
    [ReportPageID.itemInventoryItemAccountDepartment]: true,
    [ReportPageID.itemInventoryItemAccountOrder]: true,
    [ReportPageID.itemInventoryInjectedRetired]: true,
    [ReportPageID.itemInventoryPhysicalInventoryQuantities]: true,
    [ReportPageID.itemInventoryPhysicalInventory]: true,
    [ReportPageID.itemInventoryInventoryLoss]: true,
    [ReportPageID.itemInventoryInventoryProjections]: true,

    [ReportPageID.orderOrderSchedules]: true,

    [ReportPageID.periodTrendingQuantitiesItemDescription]: true,
    [ReportPageID.periodTrendingQuantitiesItemAccount]: true,
    [ReportPageID.periodTrendingQuantitiesCustomerDepartmentOrder]: true,
    [ReportPageID.periodTrendingWeightCustomerDepartmentOrder]: true,
    [ReportPageID.periodTrendingChargeCustomerDepartmentOrder]: true,
    [ReportPageID.periodTrendingLinenRoomQuantitiesCustomer]: true,
    [ReportPageID.periodTrendingLinenRoomQuantitiesItemDescription]: true,
    [ReportPageID.periodTrendingLinenRoomWeightCustomer]: true,
    [ReportPageID.periodTrendingLinenRoomChargeCustomer]: true,

    [ReportPageID.chargesCostsChargeReconciliation]: true,
    [ReportPageID.chargesCostsLinenRoomCostCleanWeight]: true,
    [ReportPageID.chargesCostsLinenRoomCostSoilWeight]: true,
  },
  viewSpreadsheet: {
    [ReportPageID.itemUsageItemDescriptionCustomer]: true,
    [ReportPageID.itemUsageItemDescriptionDepartment]: true,
    [ReportPageID.itemUsageItemDescriptionDepartmentAccount]: true,
    [ReportPageID.itemUsageItemDescriptionOrder]: true,
    [ReportPageID.itemUsageItemAccountCustomer]: true,
    [ReportPageID.itemUsageItemAccountDepartment]: true,
    [ReportPageID.itemUsageItemAccountDepartmentAccount]: true,
    [ReportPageID.itemUsageItemAccountOrder]: true,
    [ReportPageID.itemUsageLinenRoom]: true,

    [ReportPageID.itemFillRatesItemDescriptionCustomer]: true,
    [ReportPageID.itemFillRatesItemDescriptionDepartment]: true,
    [ReportPageID.itemFillRatesItemDescriptionOrder]: true,
    [ReportPageID.itemFillRatesItemAccountCustomer]: true,
    [ReportPageID.itemFillRatesItemAccountDepartment]: true,
    [ReportPageID.itemFillRatesItemAccountOrder]: true,
    [ReportPageID.itemFillRatesLinenRoomReceivingAccuracy]: true,
    [ReportPageID.itemFillRatesLinenRoomSoilCleanComparison]: true,
    [ReportPageID.itemFillRatesLinenRoomDistributionAccuracy]: true,

    [ReportPageID.itemInventoryItemDescriptionCustomer]: true,
    [ReportPageID.itemInventoryItemDescriptionDepartment]: true,
    [ReportPageID.itemInventoryItemDescriptionOrder]: true,
    [ReportPageID.itemInventoryItemAccountCustomer]: true,
    [ReportPageID.itemInventoryItemAccountDepartment]: true,
    [ReportPageID.itemInventoryItemAccountOrder]: true,
    [ReportPageID.itemInventoryInjectedRetired]: true,
    [ReportPageID.itemInventoryPhysicalInventoryQuantities]: true,
    [ReportPageID.itemInventoryPhysicalInventory]: true,
    [ReportPageID.itemInventoryInventoryLoss]: true,
    [ReportPageID.itemInventoryInventoryProjections]: true,

    [ReportPageID.orderOrderSchedules]: true,

    [ReportPageID.periodTrendingQuantitiesItemDescription]: true,
    [ReportPageID.periodTrendingQuantitiesItemAccount]: true,
    [ReportPageID.periodTrendingQuantitiesCustomerDepartmentOrder]: true,
    [ReportPageID.periodTrendingWeightCustomerDepartmentOrder]: true,
    [ReportPageID.periodTrendingChargeCustomerDepartmentOrder]: true,
    [ReportPageID.periodTrendingLinenRoomQuantitiesCustomer]: true,
    [ReportPageID.periodTrendingLinenRoomQuantitiesItemDescription]: true,
    [ReportPageID.periodTrendingLinenRoomWeightCustomer]: true,
    [ReportPageID.periodTrendingLinenRoomChargeCustomer]: true,

    [ReportPageID.chargesCostsChargeReconciliation]: true,
    [ReportPageID.chargesCostsLinenRoomCostCleanWeight]: true,
    [ReportPageID.chargesCostsLinenRoomCostSoilWeight]: true,
  },
  snapshot: {
    [ReportPageID.setBudget]: true,
    [ReportPageID.setCensus]: true,
  },
  editable: {
    [ReportPageID.setBudget]: true,
    [ReportPageID.setCensus]: true,
  },
};

export const ReportsUrl = {
  [ReportPageID.main]: '/reports',

  [ReportPageID.setCensus]: '/reports/set-census',
  [ReportPageID.setBudget]: '/reports/set-budget',

  // ITEM USAGE
  [ReportPageID.itemUsage]: '/reports/item-usage',
  [ReportPageID.itemUsageItemDescriptionCustomer]: '/reports/item-usage/item-description/customer',
  [ReportPageID.itemUsageItemDescriptionDepartment]:
    '/reports/item-usage/item-description/department',
  [ReportPageID.itemUsageItemDescriptionDepartmentAccount]:
    '/reports/item-usage/item-description/department-account',
  [ReportPageID.itemUsageItemDescriptionOrder]: '/reports/item-usage/item-description/order',

  [ReportPageID.itemUsageItemAccountCustomer]: '/reports/item-usage/item-account/customer',
  [ReportPageID.itemUsageItemAccountDepartment]: '/reports/item-usage/item-account/department',
  [ReportPageID.itemUsageItemAccountDepartmentAccount]:
    '/reports/item-usage/item-account/department-account',
  [ReportPageID.itemUsageItemAccountOrder]: '/reports/item-usage/item-account/order',

  [ReportPageID.itemUsageLinenRoom]: '/reports/item-usage/linen-room',

  // ITEM FILL RATES
  [ReportPageID.itemFillRates]: '/reports/item-fill-rates',
  [ReportPageID.itemFillRatesItemDescriptionCustomer]:
    '/reports/item-fill-rates/item-description/customer',
  [ReportPageID.itemFillRatesItemDescriptionDepartment]:
    '/reports/item-fill-rates/item-description/department',
  [ReportPageID.itemFillRatesItemDescriptionOrder]:
    '/reports/item-fill-rates/item-description/order',
  [ReportPageID.itemFillRatesItemAccountCustomer]: '/reports/item-fill-rates/item-account/customer',
  [ReportPageID.itemFillRatesItemAccountDepartment]:
    '/reports/item-fill-rates/item-account/department',
  [ReportPageID.itemFillRatesItemAccountOrder]: '/reports/item-fill-rates/item-account/order',
  [ReportPageID.itemFillRatesLinenRoomReceivingAccuracy]:
    '/reports/item-fill-rates/linen-room-receiving-accuracy',
  [ReportPageID.itemFillRatesLinenRoomDistributionAccuracy]:
    '/reports/item-fill-rates/linen-room-distribution-accuracy',
  [ReportPageID.itemFillRatesLinenRoomSoilCleanComparison]:
    '/reports/item-fill-rates/linen-room-soil-clean-comparison',

  // ITEM INVENTORY
  [ReportPageID.itemInventory]: '/reports/item-inventory/',
  [ReportPageID.itemInventoryItemDescriptionCustomer]:
    '/reports/item-inventory/item-description/customer',
  [ReportPageID.itemInventoryItemDescriptionDepartment]:
    '/reports/item-inventory/item-description/department',
  [ReportPageID.itemInventoryItemDescriptionOrder]:
    '/reports/item-inventory/item-description/order',
  [ReportPageID.itemInventoryItemAccountCustomer]: '/reports/item-inventory/item-account/customer',
  [ReportPageID.itemInventoryItemAccountDepartment]:
    '/reports/item-inventory/item-account/department',
  [ReportPageID.itemInventoryItemAccountOrder]: '/reports/item-inventory/item-account/order',
  [ReportPageID.itemInventoryInjectedRetired]: '/reports/item-inventory/injected-retired',
  [ReportPageID.itemInventoryPhysicalInventory]: '/reports/item-inventory/physical-inventory',
  [ReportPageID.itemInventoryPhysicalInventoryQuantities]:
    '/reports/item-inventory/physical-inventory-quantities',
  [ReportPageID.itemInventoryInventoryLoss]: '/reports/item-inventory/inventory-loss',
  [ReportPageID.itemInventoryInventoryProjections]: '/reports/item-inventory/inventory-projections',

  // Order Schedule
  [ReportPageID.orderOrderSchedules]: '/reports/orders/order-schedules',

  // PERIOD TRENDING
  [ReportPageID.periodTrending]: '/reports/period-trending',
  [ReportPageID.periodTrendingQuantitiesItemDescription]:
    '/reports/period-trending/quantities-item-description',
  [ReportPageID.periodTrendingQuantitiesItemAccount]:
    '/reports/period-trending/quantities-item-account',
  [ReportPageID.periodTrendingQuantitiesCustomerDepartmentOrder]:
    '/reports/period-trending/quantities-customer-department-order',
  [ReportPageID.periodTrendingWeightCustomerDepartmentOrder]:
    '/reports/period-trending/weight-customer-department-order',
  [ReportPageID.periodTrendingChargeCustomerDepartmentOrder]:
    '/reports/period-trending/charge-customer-department-order',
  [ReportPageID.periodTrendingLinenRoomQuantitiesCustomer]:
    '/reports/period-trending/linen-room-quantities-customer',
  [ReportPageID.periodTrendingLinenRoomQuantitiesItemDescription]:
    '/reports/period-trending/linen-room-quantities-item-description',
  [ReportPageID.periodTrendingLinenRoomWeightCustomer]:
    '/reports/period-trending/linen-room-weight-customer',
  [ReportPageID.periodTrendingLinenRoomChargeCustomer]:
    '/reports/period-trending/linen-room-charge-customer',

  // CHARGES COSTS
  [ReportPageID.chargesCosts]: '/reports/charges-costs',
  [ReportPageID.chargesCostsChargeReconciliation]: '/reports/charges-costs/charge-reconciliation',
  [ReportPageID.chargesCostsLinenRoomCostCleanWeight]: '/reports/charges-costs/cost-clean-weight',
  [ReportPageID.chargesCostsLinenRoomCostSoilWeight]: '/reports/charges-costs/cost-soil-weight',
};

export const ReportSelectionTreePages = {
  [ReportPageID.itemFillRatesLinenRoomReceivingAccuracy]: true,
  [ReportPageID.itemFillRatesLinenRoomDistributionAccuracy]: true,
  [ReportPageID.itemFillRatesLinenRoomSoilCleanComparison]: true,

  [ReportPageID.itemInventoryInjectedRetired]: true,
  [ReportPageID.itemInventoryPhysicalInventory]: true,
  [ReportPageID.itemInventoryPhysicalInventoryQuantities]: true,

  [ReportPageID.periodTrendingQuantitiesItemDescription]: true,
  [ReportPageID.periodTrendingQuantitiesItemAccount]: true,
  [ReportPageID.periodTrendingQuantitiesCustomerDepartmentOrder]: true,
  [ReportPageID.periodTrendingWeightCustomerDepartmentOrder]: true,
  [ReportPageID.periodTrendingChargeCustomerDepartmentOrder]: true,
  [ReportPageID.periodTrendingLinenRoomQuantitiesCustomer]: true,
  [ReportPageID.periodTrendingLinenRoomQuantitiesItemDescription]: true,
  [ReportPageID.periodTrendingLinenRoomWeightCustomer]: true,
  [ReportPageID.periodTrendingLinenRoomChargeCustomer]: true,

  [ReportPageID.chargesCostsLinenRoomCostCleanWeight]: true,
  [ReportPageID.chargesCostsLinenRoomCostSoilWeight]: true,
};

export const ReportNoBusinessLogicFilters = {
  [ReportPageID.setBudget]: true,
  [ReportPageID.setCensus]: true,
};

export const ReportTypes = {
  // ITEM USAGE
  [ReportPageID.itemUsageItemDescriptionCustomer]: 'Customers',
  [ReportPageID.itemUsageItemDescriptionDepartment]: 'Departments',
  [ReportPageID.itemUsageItemDescriptionDepartmentAccount]: 'Department Accounts',
  [ReportPageID.itemUsageItemDescriptionOrder]: 'Order',

  [ReportPageID.itemUsageItemAccountCustomer]: 'Customers',
  [ReportPageID.itemUsageItemAccountDepartment]: 'Departments',
  [ReportPageID.itemUsageItemAccountDepartmentAccount]: 'Department Accounts',
  [ReportPageID.itemUsageItemAccountOrder]: 'Order',

  // ITEM FILL RATES
  [ReportPageID.itemFillRatesItemDescriptionCustomer]: 'Customer',
  [ReportPageID.itemFillRatesItemDescriptionDepartment]: 'Department',
  [ReportPageID.itemFillRatesItemDescriptionOrder]: 'Order',

  [ReportPageID.itemFillRatesItemAccountCustomer]: 'Customer',
  [ReportPageID.itemFillRatesItemAccountDepartment]: 'Department',
  [ReportPageID.itemFillRatesItemAccountOrder]: 'Order',

  // ITEM INVENTORY
  [ReportPageID.itemInventoryItemDescriptionCustomer]: 'Customer',
  [ReportPageID.itemInventoryItemDescriptionDepartment]: 'Department',
  [ReportPageID.itemInventoryItemDescriptionOrder]: 'Order',

  [ReportPageID.itemInventoryItemAccountCustomer]: 'Customer',
  [ReportPageID.itemInventoryItemAccountDepartment]: 'Department',
  [ReportPageID.itemInventoryItemAccountOrder]: 'Order',
};

// TODO: Filter out repeated pages for Reports Navmap
export const ReportBackendTypes = {
  [ReportPageID.setBudget]: 'budget',
  [ReportPageID.setCensus]: 'census',

  [ReportPageID.itemUsageItemDescriptionCustomer]:
    'LinenHelperMultiCustomerItemFillQuantityByDescription',
  [ReportPageID.itemUsageItemDescriptionDepartment]:
    'LinenHelperMultiDepartmentItemFillQuantityByDescription',
  [ReportPageID.itemUsageItemDescriptionDepartmentAccount]:
    'LinenHelperMultiDepartmentTypeItemFillQuantityByDescription',
  [ReportPageID.itemUsageItemDescriptionOrder]:
    'LinenHelperMultiOrderItemFillQuantityByDescription',

  [ReportPageID.itemUsageItemAccountCustomer]:
    'LinenHelperMultiCustomerItemFillQuantityByItemAccount',
  [ReportPageID.itemUsageItemAccountDepartment]:
    'LinenHelperMultiDepartmentItemFillQuantityByItemAccount',
  [ReportPageID.itemUsageItemAccountDepartmentAccount]:
    'LinenHelperMultiDepartmentTypeItemFillQuantityByItemAccount',
  [ReportPageID.itemUsageItemAccountOrder]: 'LinenHelperMultiOrderItemFillQuantityByItemAccount',

  [ReportPageID.itemUsageLinenRoom]:
    'LinenHelperLinenRoomMultiCustomerItemFillQuantityByDescription',

  [ReportPageID.itemFillRatesItemDescriptionCustomer]:
    'LinenHelperMultiCustomerItemFillRateByDescription',
  [ReportPageID.itemFillRatesItemDescriptionDepartment]: 'MultiDepartmentItemFillRateByDescription',
  [ReportPageID.itemFillRatesItemDescriptionOrder]:
    'LinenHelperMultiOrderItemFillRateByDescription',

  [ReportPageID.itemFillRatesItemAccountCustomer]:
    'LinenHelperMultiCustomerItemFillRateByItemAccount',
  [ReportPageID.itemFillRatesItemAccountDepartment]: 'MultiDepartmentItemFillRateByItemAccount',
  [ReportPageID.itemFillRatesItemAccountOrder]: 'LinenHelperMultiOrderItemFillRateByItemAccount',

  [ReportPageID.itemFillRatesLinenRoomReceivingAccuracy]:
    'LinenHelperLinenRoomReceivingAccuracyByPeriod',
  [ReportPageID.itemFillRatesLinenRoomDistributionAccuracy]:
    'LinenHelperLinenRoomDistributionAccuracyByPeriod',
  [ReportPageID.itemFillRatesLinenRoomSoilCleanComparison]: 'LinenHelperLinenRoomSoilCleanByPeriod',

  [ReportPageID.itemInventoryItemDescriptionCustomer]:
    'LinenHelperMultiCustomerItemUtilizationByDescription',
  [ReportPageID.itemInventoryItemDescriptionDepartment]:
    'MultiDepartmentItemUtilizationByDescription',
  [ReportPageID.itemInventoryItemDescriptionOrder]:
    'LinenHelperMultiOrderItemUtilizationByDescription',

  [ReportPageID.itemInventoryItemAccountCustomer]:
    'LinenHelperMultiCustomerItemUtilizationByItemAccount',
  [ReportPageID.itemInventoryItemAccountDepartment]: 'MultiDepartmentItemUtilizationByItemAccount',
  [ReportPageID.itemInventoryItemAccountOrder]: 'LinenHelperMultiOrderItemUtilizationByItemAccount',

  [ReportPageID.itemInventoryInjectedRetired]: 'LinenHelperInventoryQuantityByPeriodByDescription',
  [ReportPageID.itemInventoryPhysicalInventory]:
    'LinenHelperPhysicalInventoryQuantityByPeriodByDescription',
  [ReportPageID.itemInventoryPhysicalInventoryQuantities]:
    'LinenHelperPhysicalInventoryQuantityByPeriodByDescription',
  [ReportPageID.itemInventoryInventoryLoss]: 'LinenHelperInventoryLoss',
  [ReportPageID.itemInventoryInventoryProjections]: 'LinenHelperInventoryRequired',

  [ReportPageID.periodTrendingQuantitiesItemDescription]:
    'LinenHelperItemFillQuantityByPeriodByDescription',
  [ReportPageID.periodTrendingQuantitiesItemAccount]:
    'LinenHelperItemFillQuantityByPeriodByItemAccount',
  [ReportPageID.periodTrendingQuantitiesCustomerDepartmentOrder]:
    'LinenHelperDeliveryQuantityByPeriod',
  [ReportPageID.periodTrendingWeightCustomerDepartmentOrder]: 'LinenHelperDeliveryWeightByPeriod',
  [ReportPageID.periodTrendingChargeCustomerDepartmentOrder]: 'LinenHelperDeliveryRevenueByPeriod',

  [ReportPageID.periodTrendingLinenRoomQuantitiesCustomer]:
    'LinenHelperLinenRoomDeliveryQuantityByPeriod',
  [ReportPageID.periodTrendingLinenRoomQuantitiesItemDescription]:
    'LinenHelperLinenRoomItemFillQuantityByPeriodByDescription',
  [ReportPageID.periodTrendingLinenRoomWeightCustomer]:
    'LinenHelperLinenRoomDeliveryWeightByPeriod',
  [ReportPageID.periodTrendingLinenRoomChargeCustomer]:
    'LinenHelperLinenRoomDeliveryRevenueByPeriod',

  [ReportPageID.orderOrderSchedules]: 'OrderSchedules_LinenHelper',

  [ReportPageID.chargesCostsChargeReconciliation]: 'MultiDepartmentChargeReconciliation',
  [ReportPageID.chargesCostsLinenRoomCostCleanWeight]:
    'LinenHelperLinenRoomPricePerCleanWeightByPeriod',
  [ReportPageID.chargesCostsLinenRoomCostSoilWeight]:
    'LinenHelperLinenRoomPricePerSoilWeightByPeriod',

  // linenRoomReceivingAccuracy: 'LinenHelperLinenRoomReceivingAccuracyByPeriod',
  // linenRoomDistributionAccuracy: 'LinenHelperLinenRoomDistributionAccuracyByPeriod',
  // linenRoomSoilCleanComparison: 'LinenHelperLinenRoomSoilCleanByPeriod',
  // linenRoomCostCleanWeight: 'LinenHelperLinenRoomPricePerCleanWeightByPeriod',
  // linenRoomCostSoilWeight: 'LinenHelperLinenRoomPricePerSoilWeightByPeriod',
  // linenRoomItemUsage: 'LinenHelperMultiCustomerItemFillQuantityByDescription',
  // linenRoomPeriodTrendingQuantitiesCustomer: 'LinenHelperLinenRoomDeliveryQuantityByPeriod',
  // linenRoomPeriodTrendingQuantitiesItemDescription: 'LinenHelperLinenRoomItemFillQuantityByPeriodByDescription',
  // linenRoomPeriodTrendingWeightCustomer: 'LinenHelperLinenRoomDeliveryWeightByPeriod',
  // linenRoomPeriodTrendingChargeCustomer: 'LinenHelperLinenRoomDeliveryRevenueByPeriod',

  // inventoryInjectedRetiredQuantities: 'LinenHelperInventoryQuantityByPeriodByDescription',
  //  [ReportPageID.inventoryPhysicalInventoryQuantities]:
  //  'LinenHelperPhysicalInventoryQuantityByDescription',
  // inventoryInventoryLoss: 'LinenHelperInventoryLoss',
  // inventoryInventoryProjections: 'LinenHelperInventoryRequired',
};

export const ReportHelpPages = {
  [ReportPageID.setBudget]: '/Reports/SetBudget.pdf',
  [ReportPageID.setCensus]: '/Reports/SetCensus.pdf',

  [ReportPageID.itemUsageItemDescriptionCustomer]:
    '/Reports/Item+Usage/ItemUsagebyItemDescriptionbyCustomer.pdf',
  [ReportPageID.itemUsageItemDescriptionDepartment]:
    '/Reports/Item+Usage/ItemUsagebyItemDescriptionbyDepartment.pdf',
  [ReportPageID.itemUsageItemDescriptionDepartmentAccount]:
    '/Reports/Item+Usage/ItemUsagebyItemDescriptionbyDepartmentAccount.pdf',
  [ReportPageID.itemUsageItemDescriptionOrder]:
    '/Reports/Item+Usage/ItemUsagebyItemDescriptionbyOrder.pdf',
  [ReportPageID.itemUsageItemAccountCustomer]:
    '/Reports/Item+Usage/ItemUsagebyItemAccountbyCustomer.pdf',
  [ReportPageID.itemUsageItemAccountDepartment]:
    '/Reports/Item+Usage/ItemUsagebyItemAccountbyDepartment.pdf',
  [ReportPageID.itemUsageItemAccountDepartmentAccount]:
    '/Reports/Item+Usage/ItemUsagebyItemAccountbyDepartmentAccount.pdf',
  [ReportPageID.itemUsageItemAccountOrder]: '/Reports/Item+Usage/ItemUsagebyItemAccountbyOrder.pdf',
  [ReportPageID.itemUsageLinenRoom]: '/Reports/Item+Usage/LinenRoomItemUsage.pdf',

  [ReportPageID.itemFillRatesItemDescriptionCustomer]:
    '/Reports/Item+Fill+Rates/ItemFillRatesbyDescriptionbyCustomer.pdf',
  [ReportPageID.itemFillRatesItemDescriptionDepartment]:
    '/Reports/Item+Fill+Rates/ItemFillRatesbyDescriptionbyDepartment.pdf',
  [ReportPageID.itemFillRatesItemDescriptionOrder]:
    '/Reports/Item+Fill+Rates/ItemFillRatesbyDescriptionbyOrder.pdf',
  [ReportPageID.itemFillRatesItemAccountCustomer]:
    '/Reports/Item+Fill+Rates/ItemFillRatesItemAccountbyCustomer.pdf',
  [ReportPageID.itemFillRatesItemAccountDepartment]:
    '/Reports/Item+Fill+Rates/ItemFillRatesItemAccountbyDepartment.pdf',
  [ReportPageID.itemFillRatesItemAccountOrder]:
    '/Reports/Item+Fill+Rates/ItemFillRatesItemAccountbyOrder.pdf',
  [ReportPageID.itemFillRatesLinenRoomReceivingAccuracy]:
    '/Reports/Item+Fill+Rates/LinenRoomReceivingAccuracy.pdf',
  [ReportPageID.itemFillRatesLinenRoomSoilCleanComparison]:
    '/Reports/Item+Fill+Rates/LinenRoomSoilCleanComparison.pdf',
  [ReportPageID.itemFillRatesLinenRoomDistributionAccuracy]:
    '/Reports/Item+Fill+Rates/LinenRoomDistributionAccuracy.pdf',

  [ReportPageID.itemInventoryItemDescriptionCustomer]:
    '/Reports/Item+Inventory/ItemInventoryUtilizationbyDescriptionbyCustomer.pdf',
  [ReportPageID.itemInventoryItemDescriptionDepartment]:
    '/Reports/Item+Inventory/ItemInventoryUtilizationbyDescriptionbyDepartment.pdf',
  [ReportPageID.itemInventoryItemDescriptionOrder]:
    '/Reports/Item+Inventory/ItemInventoryUtilizationbyDescriptionbyOrder.pdf',
  [ReportPageID.itemInventoryItemAccountCustomer]:
    '/Reports/Item+Inventory/ItemInventoryUtilizationbyAccountbyCustomer.pdf',
  [ReportPageID.itemInventoryItemAccountDepartment]:
    '/Reports/Item+Inventory/ItemInventoryUtilizationbyAccountbyDepartment.pdf',
  [ReportPageID.itemInventoryItemAccountOrder]:
    '/Reports/Item+Inventory/ItemInventoryUtilizationbyAccountbyOrder.pdf',
  [ReportPageID.itemInventoryInjectedRetired]:
    '/Reports/Item+Inventory/PhysicalInventoryInjectedRetired.pdf',
  [ReportPageID.itemInventoryPhysicalInventoryQuantities]:
    '/Reports/Item+Inventory/PhysicalInventoryReport.pdf',
  [ReportPageID.itemInventoryPhysicalInventory]:
    '/Reports/Item+Inventory/PhysicalInventoryReport.pdf',
  [ReportPageID.itemInventoryInventoryLoss]: '/Reports/Item+Inventory/PhysicalInventoryLoss.pdf',
  [ReportPageID.itemInventoryInventoryProjections]:
    '/Reports/Item+Inventory/PhysicalInventoryProjections.pdf',

  // [ReportPageID.orderOrderSchedules]: '/Reports/',

  [ReportPageID.periodTrendingQuantitiesItemDescription]:
    '/Reports/Period+Trending/PeriodTrendingQuantitiesbyItemDescription.pdf',
  [ReportPageID.periodTrendingQuantitiesItemAccount]:
    '/Reports/Period+Trending/PeriodTrendingQuantitiesbyItemAccount.pdf',
  [ReportPageID.periodTrendingQuantitiesCustomerDepartmentOrder]:
    '/Reports/Period+Trending/PeriodTrendingQuantitiesbyCustomerDepartmentOrder.pdf',
  [ReportPageID.periodTrendingWeightCustomerDepartmentOrder]:
    '/Reports/Period+Trending/PeriodTrendingWeightbyCustomerDepartmentOrder.pdf',
  [ReportPageID.periodTrendingChargeCustomerDepartmentOrder]:
    '/Reports/Period+Trending/PeriodTrendingChargebyCustomerDepartmentOrder.pdf',
  [ReportPageID.periodTrendingLinenRoomQuantitiesCustomer]:
    '/Reports/Period+Trending/PeriodTrendingLinenRoomQuantitiesbyCustomer.pdf',
  [ReportPageID.periodTrendingLinenRoomQuantitiesItemDescription]:
    '/Reports/Period+Trending/PeriodTrendingLinenRoomQuantitiesbyItemDescription.pdf',
  [ReportPageID.periodTrendingLinenRoomWeightCustomer]:
    '/Reports/Period+Trending/PeriodTrendingLinenRoomWeightbyCustomer.pdf',
  [ReportPageID.periodTrendingLinenRoomChargeCustomer]:
    '/Reports/Period+Trending/PeriodTrendingLinenRoomChargebyCustomer.pdf',

  [ReportPageID.chargesCostsChargeReconciliation]:
    '/Reports/Charges+Costs/DepartmentChargeReconcilation.pdf',
  [ReportPageID.chargesCostsLinenRoomCostCleanWeight]:
    '/Reports/Charges+Costs/Linen+Room+Cost+Per+Clean+Weight+Report.pdf',
  [ReportPageID.chargesCostsLinenRoomCostSoilWeight]:
    '/Reports/Charges+Costs/Linen+Room+Cost+Per+Soil+Weight+Report.pdf',
};

export const ReportNav = {
  mainNav: [
    {
      label: 'Set Census',
      url: ReportsUrl[ReportPageID.setCensus],
      permission: 'canViewSetCensus',
    },
    {
      label: 'Set Budget',
      url: ReportsUrl[ReportPageID.setBudget],
      permission: 'canViewSetBudget',
    },
    {
      label: 'Item Usage',
      url: ReportsUrl[ReportPageID.itemUsage],
      permission: 'canViewReports',
    },
    {
      label: 'Item Fill Rates',
      url: ReportsUrl[ReportPageID.itemFillRates],
      permission: 'canViewReports',
    },
    {
      label: 'Item Inventory',
      url: ReportsUrl[ReportPageID.itemInventory],
      permission: 'canViewReports',
    },
    {
      label: 'Order Schedules',
      url: ReportsUrl[ReportPageID.orderOrderSchedules],
      permission: 'canViewReports',
    },
    {
      label: 'Period Trending',
      url: ReportsUrl[ReportPageID.periodTrending],
      permission: 'canViewReports',
    },
    {
      label: 'Charges Costs',
      url: ReportsUrl[ReportPageID.chargesCosts],
      permission: 'canViewReports',
    },
  ],

  // ITEM USAGE
  itemUsage: [
    {
      label: 'Item Description',
      url: null,
      onSelect: null,
      permission: 'canViewReports',
      subMenu: [
        {
          label: 'By Customer',
          url: ReportsUrl[ReportPageID.itemUsageItemDescriptionCustomer],
          onSelect: null,
          permission: 'canViewReports',
        },
        {
          label: 'By Department Account',
          url: ReportsUrl[ReportPageID.itemUsageItemDescriptionDepartmentAccount],
          onSelect: null,
          permission: 'canViewReports',
        },
        {
          label: 'By Department',
          url: ReportsUrl[ReportPageID.itemUsageItemDescriptionDepartment],
          onSelect: null,
          permission: 'canViewReports',
        },
        {
          label: 'By Order',
          url: ReportsUrl[ReportPageID.itemUsageItemDescriptionOrder],
          onSelect: null,
          permission: 'canViewReports',
        },
      ],
    },
    {
      label: 'Item Account',
      url: null,
      onSelect: null,
      permission: 'canViewReports',
      subMenu: [
        {
          label: 'By Customer',
          url: ReportsUrl[ReportPageID.itemUsageItemAccountCustomer],
          onSelect: null,
          permission: 'canViewReports',
        },
        {
          label: 'By Department Account',
          url: ReportsUrl[ReportPageID.itemUsageItemAccountDepartmentAccount],
          onSelect: null,
          permission: 'canViewReports',
        },
        {
          label: 'By Department',
          url: ReportsUrl[ReportPageID.itemUsageItemAccountDepartment],
          onSelect: null,
          permission: 'canViewReports',
        },
        {
          label: 'By Order',
          url: ReportsUrl[ReportPageID.itemUsageItemAccountOrder],
          onSelect: null,
          permission: 'canViewReports',
        },
      ],
    },
    {
      label: 'Linen Room',
      url: null,
      onSelect: null,
      permission: 'canViewReports',
      subMenu: [
        {
          label: 'Item Usage',
          url: ReportsUrl[ReportPageID.itemUsageLinenRoom],
          onSelect: null,
          permission: 'canViewReports',
        },
      ],
    },
  ],

  // ITEM FILL RATES
  itemFillRates: [
    {
      label: 'Item Description',
      url: null,
      onSelect: null,
      permission: 'canViewReports',
      subMenu: [
        {
          label: 'By Customer',
          url: ReportsUrl[ReportPageID.itemFillRatesItemDescriptionCustomer],
          onSelect: null,
          permission: 'canViewReports',
        },
        {
          label: 'By Department',
          url: ReportsUrl[ReportPageID.itemFillRatesItemDescriptionDepartment],
          onSelect: null,
          permission: 'canViewReports',
        },
        {
          label: 'By Order',
          url: ReportsUrl[ReportPageID.itemFillRatesItemDescriptionOrder],
          onSelect: null,
          permission: 'canViewReports',
        },
      ],
    },
    {
      label: 'Item Account',
      url: null,
      onSelect: null,
      permission: 'canViewReports',
      subMenu: [
        {
          label: 'By Customer',
          url: ReportsUrl[ReportPageID.itemFillRatesItemAccountCustomer],
          onSelect: null,
          permission: 'canViewReports',
        },
        {
          label: 'By Department',
          url: ReportsUrl[ReportPageID.itemFillRatesItemAccountDepartment],
          onSelect: null,
          permission: 'canViewReports',
        },
        {
          label: 'By Order',
          url: ReportsUrl[ReportPageID.itemFillRatesItemAccountOrder],
          onSelect: null,
          permission: 'canViewReports',
        },
      ],
    },
    {
      label: 'Linen Room',
      url: null,
      onSelect: null,
      permission: 'canViewReports',
      subMenu: [
        {
          label: 'Linen Room Receiving Accuracy',
          url: ReportsUrl[ReportPageID.itemFillRatesLinenRoomReceivingAccuracy],
          onSelect: null,
          permission: 'canViewReports',
        },
        {
          label: 'Linen Room Distribution Accuracy',
          url: ReportsUrl[ReportPageID.itemFillRatesLinenRoomDistributionAccuracy],
          onSelect: null,
          permission: 'canViewReports',
        },
        {
          label: 'Linen Room Soil/Clean Comparison',
          url: ReportsUrl[ReportPageID.itemFillRatesLinenRoomSoilCleanComparison],
          onSelect: null,
          permission: 'canViewReports',
        },
      ],
    },
  ],

  // ITEM INVENTORY
  itemInventory: [
    {
      label: 'Item Description',
      url: null,
      onSelect: null,
      permission: 'canViewReports',
      subMenu: [
        {
          label: 'By Customer',
          url: ReportsUrl[ReportPageID.itemInventoryItemDescriptionCustomer],
          onSelect: null,
          permission: 'canViewReports',
        },
        {
          label: 'By Department',
          url: ReportsUrl[ReportPageID.itemInventoryItemDescriptionDepartment],
          onSelect: null,
          permission: 'canViewReports',
        },
        {
          label: 'By Order',
          url: ReportsUrl[ReportPageID.itemInventoryItemDescriptionOrder],
          onSelect: null,
          permission: 'canViewReports',
        },
      ],
    },
    {
      label: 'Item Account',
      url: null,
      onSelect: null,
      permission: 'canViewReports',
      subMenu: [
        {
          label: 'By Customer',
          url: ReportsUrl[ReportPageID.itemInventoryItemAccountCustomer],
          onSelect: null,
          permission: 'canViewReports',
        },
        {
          label: 'By Department',
          url: ReportsUrl[ReportPageID.itemInventoryItemAccountDepartment],
          onSelect: null,
          permission: 'canViewReports',
        },
        {
          label: 'By Order',
          url: ReportsUrl[ReportPageID.itemInventoryItemAccountOrder],
          onSelect: null,
          permission: 'canViewReports',
        },
      ],
    },
    {
      label: 'Physical Inventory',
      url: null,
      onSelect: null,
      permission: 'canViewReports',
      subMenu: [
        {
          label: 'Injected/Retired Quantities',
          url: ReportsUrl[ReportPageID.itemInventoryInjectedRetired],
          onSelect: null,
          permission: 'canViewReports',
        },
        {
          label: 'Physical Inventory',
          url: ReportsUrl[ReportPageID.itemInventoryPhysicalInventory],
          onSelect: null,
          permission: 'canViewReports',
        },
        {
          label: 'Physical Inventory Quantities',
          url: ReportsUrl[ReportPageID.itemInventoryPhysicalInventoryQuantities],
          onSelect: null,
          permission: 'canViewReports',
        },
        {
          label: 'Inventory Loss',
          url: ReportsUrl[ReportPageID.itemInventoryInventoryLoss],
          onSelect: null,
          permission: 'canViewReports',
        },
        {
          label: 'Inventory Projections',
          url: ReportsUrl[ReportPageID.itemInventoryInventoryProjections],
          onSelect: null,
          permission: 'canViewReports',
        },
      ],
    },
  ],

  // ORDERS SCHEDULES
  orderSchedules: [
    {
      label: 'Order Schedules',
      url: ReportsUrl[ReportPageID.orderOrderSchedules],
      onSelect: null,
      permission: 'canViewReports',
    },
  ],

  // PERIOD TRENDING
  periodTrending: [
    {
      label: 'Carts and Closets',
      url: null,
      onSelect: null,
      permission: 'canViewReports',
      subMenu: [
        {
          label: 'Quantities By Item Description',
          url: ReportsUrl[ReportPageID.periodTrendingQuantitiesItemDescription],
          onSelect: null,
          permission: 'canViewReports',
        },
        {
          label: 'Quantities By Item Account',
          url: ReportsUrl[ReportPageID.periodTrendingQuantitiesItemAccount],
          onSelect: null,
          permission: 'canViewReports',
        },
        {
          label: 'Quantities By Customer/Department/Order',
          url: ReportsUrl[ReportPageID.periodTrendingQuantitiesCustomerDepartmentOrder],
          onSelect: null,
          permission: 'canViewReports',
        },
        {
          label: 'Weight By Customer/Department/Order',
          url: ReportsUrl[ReportPageID.periodTrendingWeightCustomerDepartmentOrder],
          onSelect: null,
          permission: 'canViewReports',
        },
        {
          label: 'Charges By Customer/Department/Order',
          url: ReportsUrl[ReportPageID.periodTrendingChargeCustomerDepartmentOrder],
          onSelect: null,
          permission: 'canViewReports',
        },
      ],
    },
    {
      label: 'Linen Room',
      url: null,
      onSelect: null,
      permission: 'canViewReports',
      subMenu: [
        {
          label: 'Quantities By Customer',
          url: ReportsUrl[ReportPageID.periodTrendingLinenRoomQuantitiesCustomer],
          onSelect: null,
          permission: 'canViewReports',
        },
        {
          label: 'Quantities By Item Description',
          url: ReportsUrl[ReportPageID.periodTrendingLinenRoomQuantitiesItemDescription],
          onSelect: null,
          permission: 'canViewReports',
        },
        {
          label: 'Weight By Customer',
          url: ReportsUrl[ReportPageID.periodTrendingLinenRoomWeightCustomer],
          onSelect: null,
          permission: 'canViewReports',
        },
        {
          label: 'Charge By Customer',
          url: ReportsUrl[ReportPageID.periodTrendingLinenRoomChargeCustomer],
          onSelect: null,
          permission: 'canViewReports',
        },
      ],
    },
  ],

  // CHARGES COSTS
  chargesCosts: [
    {
      label: 'Carts and Closests',
      url: null,
      onSelect: null,
      permission: 'canViewReports',
      subMenu: [
        {
          label: 'Charges Reconciliation',
          url: ReportsUrl[ReportPageID.chargesCostsChargeReconciliation],
          onSelect: null,
          permission: 'canViewReports',
        },
      ],
    },

    {
      label: 'Linen Room',
      url: null,
      onSelect: null,
      permission: 'canViewReports',
      subMenu: [
        {
          label: 'Cost Per Clean Weight',
          url: ReportsUrl[ReportPageID.chargesCostsLinenRoomCostCleanWeight],
          onSelect: null,
          permission: 'canViewReports',
        },
        {
          label: 'Cost Per Soil Weight',
          url: ReportsUrl[ReportPageID.chargesCostsLinenRoomCostSoilWeight],
          onSelect: null,
          permission: 'canViewReports',
        },
      ],
    },
  ],
};

export const ReportNavigationMapping = {
  [ReportPageID.main]: ReportNav.mainNav,

  [ReportPageID.itemUsage]: ReportNav.itemUsage,
  [ReportPageID.itemFillRates]: ReportNav.itemFillRates,
  [ReportPageID.itemInventory]: ReportNav.itemInventory,
  [ReportPageID.periodTrending]: ReportNav.periodTrending,
  [ReportPageID.chargesCosts]: ReportNav.chargesCosts,
};

export const ReportsTitles = {
  [ReportPageID.setBudget]: 'Set Budget',
  [ReportPageID.setCensus]: 'Set Census',

  // ITEM USAGE
  [ReportPageID.itemUsageItemDescriptionCustomer]: 'Item Usage by Item Description by Customer',
  [ReportPageID.itemUsageItemDescriptionDepartment]: 'Item Usage by Item Description by Department',
  [ReportPageID.itemUsageItemDescriptionDepartmentAccount]:
    'Item Usage by Item Description by Department Account',
  [ReportPageID.itemUsageItemDescriptionOrder]: 'Item Usage by Item Description by Order',

  [ReportPageID.itemUsageItemAccountCustomer]: 'Item Usage by Account by Customer',
  [ReportPageID.itemUsageItemAccountDepartment]: 'Item Usage by Account by Department',
  [ReportPageID.itemUsageItemAccountDepartmentAccount]:
    'Item Usage by Account by Department Account',
  [ReportPageID.itemUsageItemAccountOrder]: 'Item Usage by Account by Order',
  [ReportPageID.itemUsageLinenRoom]: 'Linen Room Item Usage by Item Description',

  // ITEM FILL RATES
  [ReportPageID.itemFillRatesItemDescriptionCustomer]: 'Item Fill Rate by Description by Customer',
  [ReportPageID.itemFillRatesItemDescriptionDepartment]:
    'Item Fill Rate by Description by Department',
  [ReportPageID.itemFillRatesItemDescriptionOrder]: 'Item Fill Rate by Description by Order',

  [ReportPageID.itemFillRatesItemAccountCustomer]: 'Item Fill Rate by Account by Customer',
  [ReportPageID.itemFillRatesItemAccountDepartment]: 'Item Fill Rate by Account by Department',
  [ReportPageID.itemFillRatesItemAccountOrder]: 'Item Fill Rate by Account by Order',
  [ReportPageID.itemFillRatesLinenRoomReceivingAccuracy]: 'Linen Room Receiving Accuracy',
  [ReportPageID.itemFillRatesLinenRoomDistributionAccuracy]: 'Linen Room Distribution Accuracy',
  [ReportPageID.itemFillRatesLinenRoomSoilCleanComparison]: 'Linen Room Soil/Clean Comparison',

  // ITEM INVENTORY
  [ReportPageID.itemInventoryItemDescriptionCustomer]: 'Item Inventory by Description by Customer',
  [ReportPageID.itemInventoryItemDescriptionDepartment]:
    'Item Inventory by Description by Department',
  [ReportPageID.itemInventoryItemDescriptionOrder]: 'Item Inventory by Description by Order',

  [ReportPageID.itemInventoryItemAccountCustomer]: 'Item Inventory by Account by Customer',
  [ReportPageID.itemInventoryItemAccountDepartment]: 'Item Inventory by Account by Department',
  [ReportPageID.itemInventoryItemAccountOrder]: 'Item Inventory by Account by Order',

  [ReportPageID.itemInventoryInjectedRetired]: 'Injected/Retired Quantities',
  [ReportPageID.itemInventoryPhysicalInventory]: 'Physical Inventory Quantities',
  [ReportPageID.itemInventoryPhysicalInventoryQuantities]: 'Physical Inventory Quantities',
  [ReportPageID.itemInventoryInventoryLoss]: 'Inventory Loss',
  [ReportPageID.itemInventoryInventoryProjections]: 'Inventory Projections',

  // ORDER SCHEDULES
  [ReportPageID.orderOrderSchedules]: 'Order Schedules',

  // PERIOD TRENDING
  [ReportPageID.periodTrendingQuantitiesItemDescription]:
    'Period Trending Quantities By Item Description',
  [ReportPageID.periodTrendingQuantitiesItemAccount]: 'Period Trending Quantities By Item Account',
  [ReportPageID.periodTrendingQuantitiesCustomerDepartmentOrder]:
    'Period Trending Quantities By Customer/Department/Order',
  [ReportPageID.periodTrendingWeightCustomerDepartmentOrder]:
    'Period Trending Weight By Customer/Department/Order',
  [ReportPageID.periodTrendingChargeCustomerDepartmentOrder]:
    'Period Trending Charge By Customer/Department/Order',
  [ReportPageID.periodTrendingLinenRoomQuantitiesCustomer]:
    'Linen Room Period Trending Quantities By Customer',
  [ReportPageID.periodTrendingLinenRoomQuantitiesItemDescription]:
    'Linen Room Period Trending Quantities By Item Description',
  [ReportPageID.periodTrendingLinenRoomWeightCustomer]:
    'Linen Room Period Trending Weight By Customer',
  [ReportPageID.periodTrendingLinenRoomChargeCustomer]:
    'Linen Room Period Trending Charge By Customer',

  // CHARGES COSTS
  [ReportPageID.chargesCostsChargeReconciliation]: 'Department Charge Reconciliation',
  [ReportPageID.chargesCostsLinenRoomCostCleanWeight]: 'Linen Room Cost Per Clean Weight',
  [ReportPageID.chargesCostsLinenRoomCostSoilWeight]: 'Linen Room Cost Per Soil Weight',
};

export default ReportsUrl;

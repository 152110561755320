import SetupAccountsActionTypes from './setup-accounts.types';

const INITIAL_STATE = {
  accounts: [],
  accounts_previous: [],
  save_accounts_response: null,
  error: null,
};

const setupAccountReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SetupAccountsActionTypes.GET_ACCOUNTS_SUCCESS:
      return {
        ...state,
        accounts: action.payload,
        accounts_previous: JSON.parse(JSON.stringify(action.payload)),
        error: null,
      };

    case SetupAccountsActionTypes.GET_ACCOUNTS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case SetupAccountsActionTypes.SAVE_ACCOUNTS_SUCCESS:
      return {
        ...state,
        save_accounts_response: action.payload,
        error: null,
      };

    default:
      return state;
  }
};

export default setupAccountReducer;

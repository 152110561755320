import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import api from '@API';
import { selectCurrentOrdersSelected } from '@State/setup-users-order-selected-in-user/setup-users-order-selected-in-user.selectors';

import {
  getDetailsForOrderSelectedFailure,
  getDetailsForOrderSelectedSuccess,
  updateDetailsForOrderSelectedFailure,
  updateDetailsForOrderSelectedSuccess,
} from './setup-users-details-for-order-selected-in-user.actions';
import { selectDetailsForOrderSelected } from './setup-users-details-for-order-selected-in-user.selectors';
import SetupUsersDetailsForOrderSelectedActionTypes from './setup-users-details-for-order-selected-in-user.types';

const detailsForOrderApi = (params) => {
  return api.post('security/get-details-for-order-selected-in-user', params);
};

export function* getDetailsForOrderSelected(action) {
  try {
    const currentOrderInfo = yield select(selectCurrentOrdersSelected);
    if (currentOrderInfo) {
      const params = { iUserOrderID: currentOrderInfo.RcID };
      const { data } = yield call(detailsForOrderApi, params);
      yield put(getDetailsForOrderSelectedSuccess(data.data['0']));
    }
  } catch (error) {
    yield put(getDetailsForOrderSelectedFailure(error));
  }
}

const updateDetailsForOrderApi = (params) => {
  return api.post('security/update-details-for-orders-selected-and-not-selected-in-user', params);
};

export function* updateDetailsForOrderSelected(action) {
  try {
    const orderDetails = yield select(selectDetailsForOrderSelected);
    if (orderDetails) {
      const params = {};
      if (action.payload.payload === '0') {
        params.order = action.payload.details;
        params.mode = action.payload.payload;
      } else {
        params.order = orderDetails;
        params.mode = action.payload;
      }
      const { data } = yield call(updateDetailsForOrderApi, params);
      yield put(updateDetailsForOrderSelectedSuccess(data.data));
      // if (params.mode === 1) {
      //   yield put(getDetailsForOrderSelectedStart());
      // } else {
      //   // yield put(getOrderSelectedStart());
      // }
    }
  } catch (error) {
    yield put(updateDetailsForOrderSelectedFailure(error));
  }
}

export function* onGetDetailsForOrderSelectedStart() {
  yield takeLatest(
    SetupUsersDetailsForOrderSelectedActionTypes.GET_DETAILS_FOR_ORDER_SELECTED_START,
    getDetailsForOrderSelected,
  );
}

export function* onUpdateDetailsForOrderSelectedStart() {
  yield takeLatest(
    SetupUsersDetailsForOrderSelectedActionTypes.UPDATE_DETAILS_FOR_ORDER_SELECTED_START,
    updateDetailsForOrderSelected,
  );
}

export function* advancedUserDetailsForOrderSelectedCustomer() {
  yield all([call(onGetDetailsForOrderSelectedStart), call(onUpdateDetailsForOrderSelectedStart)]);
}

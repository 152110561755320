import {
  Button,
  Heading,
  Layer,
  List,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from 'grommet';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectCurrentCustomer } from '@State/user/user.selectors';

const AddUnScheduledModal = (props) => {
  const {
    rcID,
    showModal,
    toggleModal,
    type,
    data,
    currentCustomer,
    currentDate,
    addUnScheduledEvent,
  } = props;

  const [show, setShow] = useState(showModal);
  const [selectedRows, setSelectedRows] = useState([]);
  const [itemListProp, setItemListProp] = useState({});
  const [listData, setListData] = useState([]);

  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  useEffect(() => {
    getList(data);
  }, [data]);

  const getList = (rows) => {
    const rowData = [];
    const values = rows.data;

    if (!rowData.error) {
      for (const i in values) {
        if (type === 'Orders') {
          rowData.push({ name: values[i].OrdrDesc, id: values[i].RcID });
        } else if (type === 'Items') {
          rowData.push({
            name: values[i].ItemDesc,
            id: values[i].RcID,
            itemType: values[i].ItemType,
          });
        }
      }
    }

    setListData([...rowData]);
  };

  const updateSelected = (row, selected) => {
    const rows = selected;
    if (type === 'Orders') {
      rows.push({ id: row.item.id });
    } else if (type === 'Items') {
      rows.push({
        index: row.index,
        id: row.item.id,
        name: row.item.name,
        itemType: row.item.itemType,
      });
    }
    setSelectedRows(rows);
  };

  const onCtrlSelect = (row) => {
    updateSelected(row, selectedRows);

    const listProps = itemListProp;
    if (typeof listProps[row.index] === 'undefined') {
      listProps[row.index] = { background: 'brand' };
    } else {
      const temp = selectedRows;
      delete listProps[row.index];
      for (const i in temp) {
        if (temp[i].index === row.index) {
          delete temp[i];
        }
      }
      setSelectedRows(temp);
    }
    setItemListProp(listProps);
  };

  const onShiftSelect = (row) => {
    const selected = [];

    const listProps = {};
    let startIndex = 0;
    let endIndex = 0;
    const keys = Object.keys(itemListProp);
    const firstKey = keys.length > 0 ? keys[0] : 0;
    setItemListProp({});

    if (row.index > firstKey) {
      startIndex = keys[keys.length - 1];
      endIndex = row.index;
    } else {
      startIndex = row.index;
      endIndex = firstKey;
    }

    for (let i = startIndex; i <= endIndex; i++) {
      const temp = listData[i];

      temp.item = { id: temp.id };
      if (type === 'Items') {
        temp.item = { id: temp.id, itemType: temp.itemType };
      }
      temp.index = i;
      updateSelected(temp, selected);
      listProps[i] = { background: 'brand' };
    }

    setItemListProp(listProps);
  };

  const onDefaultSelect = (row) => {
    setSelectedRows([]);
    setItemListProp({});
    updateSelected(row, []);

    const listProps = {};
    if (typeof listProps[row.index] === 'undefined') {
      listProps[row.index] = { background: 'brand' };
      setItemListProp(listProps);
    }
  };

  const addSelectedRows = (row) => {
    if (row.shiftKey) {
      onShiftSelect(row);
    } else if (row.ctrlKey || row.metaKey) {
      onCtrlSelect(row);
    } else {
      onDefaultSelect(row);
    }
  };

  const onCancel = () => {
    toggleModal();
    setSelectedRows([]);
  };

  const add = () => {
    if (selectedRows.length > 0) {
      selectedRows.map((item) => {
        if (type === 'Orders') {
          addUnScheduledEvent({
            iOrderID: item.id,
            RequestedDay: currentDate,
            iCallerUTCOffset: 0,
            customerID: currentCustomer.RcID,
          });
        } else if (type === 'Items') {
          addUnScheduledEvent({
            id: rcID,
            iItemID: item.id,
            iItemType: item.itemType,
          });
        }
      });
      setSelectedRows([]);
      setItemListProp([]);
      toggleModal({ id: 'AddUnscheduled', data: [] });
    }
  };
  return (
    <>
      {show && (
        <Layer onEsc={onCancel} onClickOutside={onCancel}>
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell colSpan="2">
                  <Heading level="4" textAlign="center" margin="auto">
                    Add {type}
                  </Heading>
                </TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableCell colSpan="2">
                  Inventory Date {moment(currentDate).format('MM/DD/YYYY')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan="2" />
              </TableRow>
              <TableRow>
                <TableCell colSpan="2">
                  <List
                    primaryKey="name"
                    data={listData}
                    style={{ height: '400px', overflowY: 'scroll' }}
                    itemProps={itemListProp}
                    onClickItem={(datum) => addSelectedRows(datum)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Button label="Add" onClick={() => add()} />
                </TableCell>
                <TableCell>
                  <Button label="Cancel" onClick={() => onCancel()} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Layer>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = createStructuredSelector({
  currentCustomer: selectCurrentCustomer,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUnScheduledModal);

import styled from 'styled-components';

import { colors, ThemeDataTable } from '@ThemeMain';

// TODO: [LH-293] It seems .pi-table is only on the parent and tr, which these rules wouldn't match. Circle back: does something need to be 30% wide on the Physical Inventory page?
export const InventoryDataTable = styled(ThemeDataTable)`
  width: 100%;
  thead {
    th:nth-child(2) {
      text-align: right;
    }
  }

  tbody {
    tr {
      /*border: 1px solid ${colors['dark-1']};*/

      td:nth-child(1) {
        width: 80%;
      }
      td:nth-child(2) {
        width: 20%;
        text-align: right;
      }

      td:not(:nth-child(2)) {
        width: 100px;
      }

      .pi-table:nth-of-type(1) {
        td {
          width: 30% !important;
        }
      }

      td input {
        width: initial;
      }
    }
  }
`;

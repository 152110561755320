export const SetUpUrl = {
  main: '/setup',
  departments: '/setup/departments',
  products: '/setup/products',
  accounts: '/setup/accounts',
  customerAccounts: '/setup/accounts/customer',
  itemAccounts: '/setup/accounts/item',
  departmentAccounts: '/setup/accounts/department',
  advanced: '/advanced',
  packs: '/setup/packs',
  procedures: '/setup/procedures',
  orders: '/setup/orders',
  advanceCustomers: '/advanced/setup-customers',
  advanceUsers: '/advanced/setup-users',
  advanceLogTransactions: '/advanced/view-log-transactions/',
  advanceSystemConfiguration: '/advanced/system-configuration/',
};

export const SetUpPageID = {
  main: 'main',
  accounts: 'accounts',
  departments: 'departments',
  products: 'products',
  customerAccounts: 'customer-accounts',
  itemAccounts: 'item-accounts',
  departmentAccounts: 'department-accounts',
  advanced: 'advanced',
  packs: 'packs',
  procedures: 'procedures',
  orders: 'orders',
  advanceCustomers: 'setup-advance-customers',
  advanceLogTransactions: 'advance-log-transactions',
  advanceSystemConfiguration: 'advance-system-configuration',
};

export const modals = {
  departments: {
    addModal: 'EditPackModal',
  },
  products: {
    addModal: 'EditPackModal',
  },
  packs: {
    addModal: 'EditPackModal',
  },
  procedures: {
    addModal: 'EditPackModal',
  },
  orders: {
    addModal: 'EditPackModal',
  },
  advanceCustomers: {
    addModal: 'ConfirmationModal',
  },
  customerAccounts: {
    addModal: 'EditPackModal',
  },
  itemAccounts: {
    addModal: 'EditPackModal',
  },
  departmentAccounts: {
    addModal: 'EditPackModal',
  },
};

export const tableConfig = {
  advanceCustomers: {
    primaryKey: 'RcID',
    getProductKey: 'RcID',
    validateKey: 'customerID',
    dataKey: 'advanceCustomersTable',
    searchKeys: [
      { id: 'Row', name: 'Row' },
      { id: 'Name', name: 'Name' },
      { id: 'CustAcctRcID', name: 'Account Type' },
    ],
    columns: {
      RcID: { type: 'text', label: 'ID' },
      Row: { type: 'text', label: 'Row' },
      Name: { type: 'textInput', label: 'Name' },
      CustAcctRcID: {
        type: 'select',
        key: 'CustAcctRcID',
        value: 'RcID',
        keyID: 'AcctDesc',
        label: 'Account Type',
        options: 'customerAccounts',
      },
    },
    searchKeysRight: [],
    columnsRight: {},
  },
  departments: {
    primaryKey: 'RcID',
    getProductKey: 'iPackID',
    validateKey: 'iDepartmentID',
    addProductKey: 'iPackRcID',
    dataKey: 'departmentTable',
    moveToRowKey: 'iDepartmentID',
    moveToRowType: 'Department',
    searchKeys: [
      { id: 'Row', name: 'Row' },
      { id: 'DeptDesc', name: 'Department Name' },
      { id: 'IntfNmbr', name: 'Cost Account' },
      { id: 'AcctDesc', name: 'Account Type' },
      { id: 'Qnty01', name: 'Target' },
    ],
    columns: {
      RcID: { type: 'text', inputType: 'text', label: 'ID' },
      Row: { type: 'text', inputType: 'text', label: 'Row' },
      DeptDesc: { type: 'textInput', inputType: 'text', label: 'Department Name' },
      IntfNmbr: { type: 'textInput', inputType: 'text', label: 'Cost Account' },
      AcctDesc: {
        type: 'select',
        key: 'DeptAcctRcID',
        value: 'RcID',
        label: 'Department Account Type',
        options: 'departmentAcctTypes',
      },
      Qnty01: {
        type: 'textInput',
        inputType: 'float',
        label: 'Target Lbs/Census',
        unit: '',
        precision: 2,
      },
    },
    searchKeysRight: [],
    columnsRight: {},
    required: [
      { id: 'DeptDesc', message: 'Department Name is required' },
      { id: 'IntfNmbr', message: 'Cost Account is required' },
      { id: 'DeptAcctRcID', message: 'Account Type is required' },
      { id: 'Qnty01', message: 'Target is required' },
    ],
  },
  products: {
    primaryKey: 'ProdRcID',
    getProductKey: 'iPackID',
    validateKey: 'iProductID',
    addProductKey: 'iProdRcID',
    dataKey: 'productPricingTable',
    moveToRowKey: 'ProdRcID',
    moveToRowType: 'Product',
    searchKeys: [
      { id: 'Row', name: 'Row' },
      { id: 'ProdDesc', name: 'Product Name' },
      { id: 'AcctDesc', name: 'Account Type' },
      { id: 'Wght', name: 'Weight' },
      { id: 'ClenPiecChrg', name: 'Piece Cost' },
      { id: 'SaleChrg', name: 'Purchase Cost' },
      { id: 'LnrmRqrdQnty', name: 'Linen Room Par' },
      { id: 'StckRqrdQnty', name: 'System Par' },
      { id: 'TargCensQnty', name: 'Target/Census' },
    ],
    columns: {
      ProdRcID: { type: 'text', inputType: 'text', label: 'ID' },
      Row: { type: 'text', inputType: 'text', label: 'Row' },
      ProdDesc: { type: 'textInput', inputType: 'text', label: 'Product Name' },
      AcctDesc: {
        type: 'select',
        key: 'ItemAcct01RcID',
        value: 'RcID',
        label: 'Account Type',
        options: 'itemAccounts',
      },
      Wght: { type: 'textInput', inputType: 'float', label: 'Weight', unit: 'lbs', precision: 3 },
      ClenPiecChrg: {
        type: 'textInput',
        inputType: 'float',
        label: 'Piece Cost',
        unit: '$',
        precision: 6,
      },
      SaleChrg: {
        type: 'textInput',
        inputType: 'float',
        label: 'Purchase Cost',
        unit: '$',
        precision: 6,
      },
      LnrmRqrdQnty: {
        type: 'textInput',
        inputType: 'number',
        label: 'Linen Room Par',
      },
      StckRqrdQnty: {
        type: 'textInput',
        inputType: 'number',
        label: 'System Par',
      },
      TargCensQnty: {
        type: 'textInput',
        inputType: 'number',
        label: 'Target/Census',
        unit: '',
        precision: 2,
      },
    },
    searchKeysRight: [],
    columnsRight: {},
    required: [
      { id: 'ProdDesc', message: 'Product Name is required' },
      { id: 'ItemAcct01RcID', message: 'Account Type is required' },
      { id: 'Wght', message: 'Weight is required' },
      { id: 'ClenPiecChrg', message: 'Piece Cost is required' },
      { id: 'SaleChrg', message: 'Purchase Cost is required' },
      { id: 'LnrmRqrdQnty', message: 'Linen Room Par is required' },
      { id: 'StckRqrdQnty', message: 'System Par is required' },
      { id: 'TargCensQnty', message: 'Target/Census is required' },
    ],
    requiredV2: [{ id: 'WghtChrg', message: 'Delivery Cost is required' }],
  },
  packs: {
    primaryKey: 'PackRcID',
    primaryKeyRight: 'ProdDesc',
    getProductKey: 'iPackID',
    validateKey: 'iPackID',
    addProductKey: 'iPackRcID',
    dataKey: 'packPricingTable',
    moveToRowKey: 'iPackID',
    moveToRowType: 'Pack',
    searchKeys: [
      { id: 'Row', name: 'Row' },
      { id: 'PackDesc', name: 'Pack Name' },
      { id: 'AcctDesc', name: 'Account Type' },
      { id: 'Wght', name: 'Weight' },
      { id: 'ClenPiecChrg', name: 'Piece Cost' },
    ],
    columns: {
      PackRcID: { type: 'text', inputType: 'text', label: 'Product Id' },
      Row: { type: 'text', inputType: 'text', label: 'Row' },
      PackDesc: { type: 'textInput', inputType: 'text', label: 'Pack Name' },
      AcctDesc: {
        type: 'select',
        key: 'ItemAcct01RcID',
        value: 'RcID',
        label: 'Account Type',
        options: 'itemAccounts',
      },
      Wght: { type: 'textInput', inputType: 'float', label: 'Weight', unit: 'lbs', precision: 3 },
      ClenPiecChrg: {
        type: 'textInput',
        inputType: 'float',
        label: 'Piece Cost',
        unit: '$',
        precision: 6,
      },
    },
    searchKeysRight: [
      { id: 'ProdDesc', name: 'Product Name' },
      { id: 'Qnty', name: 'Quantity' },
    ],
    columnsRight: {
      ProdDesc: { type: 'text', label: 'Product Name' },
      Qnty: { type: 'textInput', inputType: 'number', label: 'Quantity' },
    },
    required: [
      { id: 'PackDesc', message: 'Description is required' },
      { id: 'ItemAcct01RcID', message: 'Account Type is required' },
      { id: 'Wght', message: 'Weight is required' },
      { id: 'ClenPiecChrg', message: 'Piece Cost is required' },
    ],
  },
  procedures: {
    primaryKey: 'PackRcID',
    primaryKeyRight: 'ProdDesc',
    getProductKey: 'iProcedureID',
    validateKey: 'iProcedureID',
    addProductKey: 'iProcedureRcID',
    dataKey: 'packPricingTable',
    moveToRowKey: 'iProcedureID',
    moveToRowType: 'Procedure',
    searchKeys: [
      { id: 'Row', name: 'Row' },
      { id: 'PackDesc', name: 'Procedure Name' },
      { id: 'AcctDesc', name: 'Account Type' },
      { id: 'Wght', name: 'Weight' },
      { id: 'ClenPiecChrg', name: 'Piece Cost' },
    ],
    columns: {
      PackRcID: { type: 'text', label: 'Product Id' },
      Row: { type: 'text', label: 'Row' },
      PackDesc: { type: 'textInput', inputType: 'text', label: 'Procedure Name' },
      AcctDesc: {
        type: 'select',
        key: 'ItemAcct01RcID',
        value: 'RcID',
        label: 'Account Type',
        options: 'itemAccounts',
      },
      Wght: { type: 'textInput', inputType: 'float', label: 'Weight', unit: 'lbs', precision: 3 },
      ClenPiecChrg: {
        type: 'textInput',
        inputType: 'float',
        label: 'Piece Cost',
        unit: '$',
        precision: 6,
      },
    },
    searchKeysRight: [
      { id: 'ProdDesc', name: 'Product Name' },
      { id: 'Qnty', name: 'Quantity' },
    ],
    columnsRight: {
      ProdDesc: { type: 'text', label: 'Product Name' },
      Qnty: { type: 'textInput', inputType: 'number', label: 'Quantity' },
    },
    required: [
      { id: 'PackDesc', message: 'Description is required' },
      { id: 'ItemAcct01RcID', message: 'Account is required' },
      { id: 'Wght', message: 'Weight is required' },
      { id: 'ClenPiecChrg', message: 'Piece Cost is required' },
    ],
  },
  orders: {
    primaryKey: 'RcID',
    primaryKeyRight: 'RcID',
    getProductKey: 'iOrderID',
    validateKey: 'iOrderID',
    dataKey: 'orderTable',
    moveToRowKey: 'iOrderID',
    moveToRowType: 'Order',
    searchKeys: [
      { id: 'Row', name: 'Row' },
      { id: 'OrdrDesc', name: 'Order Name' },
      { id: 'DeptDesc', name: 'Department Type' },
      { id: 'InvtFlow', name: 'Build' },
      { id: 'TypeDesc', name: 'Stocked' },
      { id: 'HhldNmbr', name: 'Handheld' },
    ],
    columns: {
      RcID: { type: 'text', label: 'RcID' },
      Row: { type: 'text', label: 'Row' },
      OrdrDesc: {
        type: 'textInput',
        inputType: 'text',
        key: 'Desc',
        value: '',
        label: 'Order Name',
      },
      DeptDesc: {
        type: 'select',
        key: 'DeptRcID',
        value: 'RcID',
        label: 'Department',
        options: 'departmentsUsedForCustomer',
      },
      InvtFlow: {
        type: 'select',
        key: 'InvtFlow',
        keyID: 'Desc',
        value: 'RcID',
        label: 'Build',
        options: 'inventoryFlow',
      },
      TypeDesc: {
        type: 'select',
        key: 'Type',
        keyID: 'Desc',
        value: 'RcID',
        label: 'Stocked',
        options: 'orderTypes',
      },
      HhldNmbr: {
        type: 'select',
        key: 'HhldNmbr',
        keyID: 'Desc',
        value: 'Value',
        label: 'Handheld',
        options: 'handheldNumbers',
      },
      UsefDelvMond: { type: 'checkBox', label: 'Mon' },
      UsefDelvTues: { type: 'checkBox', label: 'Tue' },
      UsefDelvWedn: { type: 'checkBox', label: 'Wed' },
      UsefDelvThur: { type: 'checkBox', label: 'Thu' },
      UsefDelvFrid: { type: 'checkBox', label: 'Fri' },
      UsefDelvSatr: { type: 'checkBox', label: 'Sat' },
      UsefDelvSund: { type: 'checkBox', label: 'Sun' },
      UsefPfil: { type: 'checkBox', label: 'Prefill' },
    },
    searchKeysRight: [
      { id: 'ItemDesc', name: 'Item Name' },
      { id: 'InvtRqrdQnty', name: 'Quantity' },
      { id: 'PcntDelvMond', name: 'Mon' },
      { id: 'PcntDelvTues', name: 'Tue' },
      { id: 'PcntDelvWedn', name: 'Wed' },
      { id: 'PcntDelvThur', name: 'Thu' },
      { id: 'PcntDelvFrid', name: 'Fri' },
      { id: 'PcntDelvSatr', name: 'Sat' },
      { id: 'PcntDelvSund', name: 'Sun' },
      { id: 'CensMinmQnty', name: 'Min' },
      { id: 'CensIncrQnty', name: 'Incr' },
      { id: 'PickSqnc', name: 'Sequence' },
    ],
    columnsRight: {
      RcID: { type: 'text', label: 'Id', width: '60px' },
      ItemDesc: { type: 'text', label: 'Item Name', width: '60px' },
      InvtRqrdQnty: { type: 'textInput', inputType: 'number', label: 'Quantity', width: '60px' },
      PcntDelvMond: { type: 'textInput', inputType: 'number', label: 'Mon', width: '60px' },
      PcntDelvTues: { type: 'textInput', inputType: 'number', label: 'Tue', width: '60px' },
      PcntDelvWedn: { type: 'textInput', inputType: 'number', label: 'Wed', width: '60px' },
      PcntDelvThur: { type: 'textInput', inputType: 'number', label: 'Thu', width: '60px' },
      PcntDelvFrid: { type: 'textInput', inputType: 'number', label: 'Fri', width: '60px' },
      PcntDelvSatr: { type: 'textInput', inputType: 'number', label: 'Sat', width: '60px' },
      PcntDelvSund: { type: 'textInput', inputType: 'number', label: 'Sun', width: '60px' },
      PickSqnc: { type: 'textInput', inputType: 'number', label: 'Sequence', width: '60px' },
    },
    columnsRightCensus: {
      RcID: { type: 'text', label: 'Id', width: '60px' },
      ItemDesc: { type: 'text', label: 'Item Name', width: '60px' },
      CensMinmQnty: { type: 'textInput', inputType: 'number', label: 'Min', width: '60px' },
      CensIncrQnty: { type: 'textInput', inputType: 'number', label: 'Incr', width: '60px' },
      PickSqnc: { type: 'textInput', inputType: 'number', label: 'Sequence', width: '60px' },
    },
    required: [
      { id: 'OrdrDesc', message: 'Description is required' },
      { id: 'DeptRcID', message: 'You must select a Department' },
      { id: 'InvtFlow', message: 'You must select a Build' },
      { id: 'Type', message: 'Stock By is required' },
      { id: 'HhldNmbr', message: 'You must select a Hand-held number' },
    ],
  },
  customerAccounts: {
    primaryKey: 'RcID',
    getProductKey: 'RcID',
    validateKey: 'iAccountID',
    dataKey: 'accountsTable',
    accountType: 'CUSTOMER',
    searchKeys: [
      { id: 'Row', name: 'Row' },
      { id: 'AcctDesc', name: 'Customer Account Type' },
    ],
    columns: {
      RcID: { type: 'text', label: 'ID' },
      Row: { type: 'text', label: 'Row' },
      AcctDesc: { type: 'textInput', label: 'Customer Account Type' },
    },
    searchKeysRight: [],
    columnsRight: {},
    required: [{ id: 'AcctDesc', message: 'Customer Account Type is required' }],
  },
  itemAccounts: {
    primaryKey: 'RcID',
    getProductKey: 'RcID',
    validateKey: 'iAccountID',
    dataKey: 'accountsTable',
    accountType: 'ITEM',
    searchKeys: [
      { id: 'Row', name: 'Row' },
      { id: 'AcctDesc', name: 'Product Account Type' },
    ],
    columns: {
      RcID: { type: 'text', label: 'ID' },
      Row: { type: 'text', label: 'Row' },
      AcctDesc: { type: 'textInput', label: 'Product Account Type' },
    },
    searchKeysRight: [],
    columnsRight: {},
    required: [{ id: 'AcctDesc', message: 'Product Account Type is required' }],
  },
  departmentAccounts: {
    primaryKey: 'RcID',
    getProductKey: 'RcID',
    validateKey: 'iAccountID',
    dataKey: 'departmentsTable',
    accountType: 'DEPARTMENT',
    searchKeys: [
      { id: 'Row', name: 'Row' },
      { id: 'AcctDesc', name: 'Department Account Type' },
      { id: 'Qnty01', name: 'Target Lbs/Census' },
    ],
    columns: {
      RcID: { type: 'text', label: 'ID' },
      Row: { type: 'text', label: 'Row' },
      AcctDesc: { type: 'textInput', label: 'Department Account Type' },
      Qnty01: {
        type: 'textInput',
        inputType: 'float',
        label: 'Target Lbs/Census',
        unit: '',
        precision: 2,
      },
    },
    searchKeysRight: [],
    columnsRight: {},
    required: [
      { id: 'AcctDesc', message: 'Department Account Type is required' },
      { id: 'Qnty01', message: 'Target Lbs/Census is required' },
    ],
  },
};

export const formData = {
  departments: {
    main: {
      RcID: '0',
      DeptDesc: '',
      IntfNmbr: '',
      DeptAcctRcID: '',
      Qnty01: '0',
      id: '',
    },
  },
  products: {
    main: {
      ProdRcID: '0',
      ProdCode: '',
      ProdDesc: '',
      Wght: '0',
      ItemAcct01RcID: '0',
      IntfLnhpItemRcID: '0',
      ContItemRcID: '0',
      ClenWghtChrg: '0',
      ClenPiecChrg: '0',
      SaleChrg: '0',
      LnrmRqrdQnty: '0',
      StckRqrdQnty: '0',
      TargCensQnty: '0',
      AcctDesc: '',
      id: '',
      Row: 0,
    },
  },
  packs: {
    main: {
      PackRcID: '0',
      PackCode: '0',
      PackDesc: '',
      Wght: '0',
      ItemAcct01RcID: '0',
      IntfLnhpItemRcID: '0',
      ContItemRcID: '0',
      ClenWghtChrg: '0',
      ClenPiecChrg: '0',
      SaleChrg: '0',
      AcctDesc: '',
      id: '',
    },
  },
  procedures: {
    main: {
      PackRcID: '0',
      PackCode: '0',
      PackDesc: '',
      Wght: '0',
      ItemAcct01RcID: '0',
      IntfLnhpItemRcID: '0',
      ContItemRcID: '0',
      ClenWghtChrg: '0',
      ClenPiecChrg: '0',
      SaleChrg: '0',
      AcctDesc: '',
      id: '',
    },
  },
  orders: {
    main: {
      RcID: '0',
      OrdrCode: '0',
      OrdrDesc: '',
      UsefDelvSund: '0',
      UsefDelvMond: '0',
      UsefDelvTues: '0',
      UsefDelvWedn: '0',
      UsefDelvThur: '0',
      UsefDelvFrid: '0',
      UsefDelvSatr: '0',
      HhldNmbr: '',
      Type: '',
      UsefPfil: '0',
      InvtFlow: '',
      DeptRcID: '0',
      DeptDesc: '',
      TypeDesc: '',
      id: '',
    },
  },
  advanceCustomers: {
    main: {
      RcID: '0',
      Name: '',
      CustAcctRcID: '0',
      id: '',
    },
  },
  customerAccounts: {
    main: {
      RcID: '0',
      AcctDesc: '',
      Qnty01: '0',
      id: '',
    },
  },
  itemAccounts: {
    main: {
      RcID: '0',
      AcctDesc: '',
      Qnty01: '0',
      id: '',
    },
  },
  departmentAccounts: {
    main: {
      RcID: '0',
      AcctDesc: '',
      Qnty01: '0',
      id: '',
    },
  },
};

export const methodCalls = {
  packs: {
    validateDeletion: 'ValidateInactivatePack',
    inactivate: 'InactivatePack',
    snapshot: 'PACK',
  },
  procedures: {
    validateDeletion: 'ValidateInactivateProcedure',
    inactivate: 'InactivateProcedure',
    snapshot: 'PROCEDURE',
  },
  orders: {
    validateDeletion: 'ValidateInactivateOrder',
    inactivate: 'InactivateOrder',
    snapshot: 'ORDER',
    reportType: 'LinenHelperMultiOrderItemFillRateByDescription',
  },
  advanceCustomers: {
    validateDeletion: '',
    inactivate: 'InactivateCustomer',
  },
  customerAccounts: {
    validateDeletion: 'ValidateAccountDeletion',
    inactivate: 'InactivateAccount',
  },
  itemAccounts: {
    validateDeletion: 'ValidateAccountDeletion',
    inactivate: 'InactivateAccount',
  },
  departmentAccounts: {
    validateDeletion: 'ValidateAccountDeletion',
    inactivate: 'InactivateAccount',
  },
  departments: {
    validateDeletion: 'ValidateInactivateDepartment',
    inactivate: 'InactivateDepartment',
    snapshot: 'DEPARTMENT',
  },
  products: {
    validateDeletion: 'ValidateInactivateProduct',
    inactivate: 'InactivateProduct',
    snapshot: 'PRODUCT',
  },
};

export const SetUpNav = {
  mainNav: [
    {
      label: 'Department',
      url: SetUpUrl.departments,
      permission: 'canViewDepartments',
    },
    { label: 'Products', url: SetUpUrl.products, permission: 'canViewProducts' },
    { label: 'Accounts', url: SetUpUrl.accounts, permission: 'canViewAccounts' },
    { label: 'Advanced', url: SetUpUrl.advanced, permission: 'isAdminUser' },
    { label: 'Packs', url: SetUpUrl.packs, permission: 'canViewPacks' },
    {
      label: 'Procedures',
      url: SetUpUrl.procedures,
      permission: 'canViewProcedures',
    },
    { label: 'Orders', url: SetUpUrl.orders, permission: 'canViewOrders' },
  ],
  advanceNav: [
    {
      label: 'Setup Users',
      url: SetUpUrl.advanceUsers,
      permission: 'isAdminUser',
    },
    {
      label: 'SetUp Customers',
      url: SetUpUrl.advanceCustomers,
      permission: 'isAdminUser', // Figure out permission to access setup customers page
    },
    {
      label: 'System Configuration',
      url: SetUpUrl.advanceSystemConfiguration,
      permission: 'isAdminUser',
    },
    {
      label: 'Log Transactions',
      url: SetUpUrl.advanceLogTransactions,
      permission: 'isAdminUser',
    },
  ],
  accountsNav: [
    {
      label: 'Customers',
      url: SetUpUrl.customerAccounts,
      permission: 'canViewAccounts',
    },
    {
      label: 'Departments',
      url: SetUpUrl.departmentAccounts,
      permission: 'canViewAccounts',
    },
    { label: 'Item', url: SetUpUrl.itemAccounts, permission: 'canViewAccounts' },
  ],
};

export const optionsConfig = {
  orders: {
    adjustItems: {
      ReportOptionTable3: 3,
      ReportOptionTable6: 1,
      ReportOptionTable8: 0,
      ReportOptionTable9: 0,
      ReportOptionTable10: 0,
    },
  },
};

export const format = {
  dateParam: 'YYYY-MM-DDT12:00:00',
  date: 'DD-MM-YYYY',
};
export const SetUpMessage = {
  unSaveChanges: 'You have some unsaved changes. Are you sure you want to leave?',
};

export const helpLink = 'http://cloudapex.linenhelper.net/Help/Setup{0}.pdf';
export const helpAccountLink = 'http://cloudapex.linenhelper.net/Help/SetupAccounts{0}.pdf';

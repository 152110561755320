import SetupAdvancedUserActionTypes from './setup-advanced-users.types';

export const getAdvancedUserStart = () => ({
  type: SetupAdvancedUserActionTypes.GET_ADVANCED_USER_START,
  payload: null,
});

export const getAdvancedUserSuccess = (advancedUser) => ({
  type: SetupAdvancedUserActionTypes.GET_ADVANCED_USER_SUCCESS,
  payload: advancedUser,
});

export const getAdvancedUserFailure = (error) => ({
  type: SetupAdvancedUserActionTypes.GET_ADVANCED_USER_FAILURE,
  payload: error,
});

export const setCurrentAdvancedUser = (user) => ({
  type: SetupAdvancedUserActionTypes.SET_CURRENT_USER,
  payload: user,
});

export const deleteAdvancedUserStart = (deleteUser) => ({
  type: SetupAdvancedUserActionTypes.DELETE_ADVANCED_USER_START,
  payload: deleteUser,
});

export const deleteAdvancedUserSuccess = () => ({
  type: SetupAdvancedUserActionTypes.DELETE_ADVANCED_USER_SUCCESS,
  payload: null,
});

export const deleteAdvancedUserFailure = (error) => ({
  type: SetupAdvancedUserActionTypes.DELETE_ADVANCED_USER_FAILURE,
  payload: error,
});

export const updateAdvancedUserStart = (updateUser) => ({
  type: SetupAdvancedUserActionTypes.UPDATE_ADVANCED_USER_START,
  payload: updateUser,
});

export const updateAdvancedUserSuccess = () => ({
  type: SetupAdvancedUserActionTypes.UPDATE_ADVANCED_USER_SUCCESS,
  payload: null,
});

export const updateAdvancedUserFailure = (error) => ({
  type: SetupAdvancedUserActionTypes.UPDATE_ADVANCED_USER_FAILURE,
  payload: error,
});

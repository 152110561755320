import { createLogger } from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';
import createSagaMiddleware from 'redux-saga';

import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import rootReducer from './root-reducer';
import rootSaga from './root-saga';

const sagaMiddleware = createSagaMiddleware();
const logger = createLogger({ collapsed: true });

const middlewares = [
  sagaMiddleware,
  ...getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
  }),
];

if (window._appConfig.REDUX_LOGGER) {
  middlewares.push(logger);
}

const persistConfig = {
  key: 'root',
  storage: sessionStorage,
  whitelist: ['user', 'report'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: middlewares,
  devTools: window._appConfig.REDUX_LOGGER,
});

sagaMiddleware.run(rootSaga);

const persistor = persistStore(store);

export { store, persistor };

import SetupUsersOrderSelectedActionTypes from './setup-users-order-selected-in-user.types';

export const getOrderSelectedStart = () => ({
  type: SetupUsersOrderSelectedActionTypes.GET_ORDER_SELECTED_START,
  payload: null,
});

export const getOrderSelectedSuccess = (orderSelected) => ({
  type: SetupUsersOrderSelectedActionTypes.GET_ORDER_SELECTED_SUCCESS,
  payload: orderSelected,
});

export const getOrderSelectedFailure = (error) => ({
  type: SetupUsersOrderSelectedActionTypes.GET_ORDER_SELECTED_FAILURE,
  payload: error,
});

export const setUserCurrentOrder = (order) => ({
  type: SetupUsersOrderSelectedActionTypes.SET_CURRENT_ORDER,
  payload: order,
});

export const saveOrderSelectedStart = (order) => ({
  type: SetupUsersOrderSelectedActionTypes.SAVE_ORDER_SELECTED_START,
  payload: order,
});

export const saveOrderSelectedSuccess = () => ({
  type: SetupUsersOrderSelectedActionTypes.SAVE_ORDER_SELECTED_SUCCESS,
  payload: null,
});

export const saveOrderSelectedFailure = (error) => ({
  type: SetupUsersOrderSelectedActionTypes.SAVE_ORDER_SELECTED_FAILURE,
  payload: error,
});

export const pushOrderToDeleteQueueStart = (orderToDelete) => ({
  type: SetupUsersOrderSelectedActionTypes.PUSH_ORDER_TO_DELETE_QUEUE_START,
  payload: orderToDelete,
});

export const pushOrderToDeleteQueueSuccess = (orderToDelete) => ({
  type: SetupUsersOrderSelectedActionTypes.PUSH_ORDER_TO_DELETE_QUEUE_SUCCESS,
  payload: orderToDelete,
});

export const pushOrderToDeleteQueueFailure = (error) => ({
  type: SetupUsersOrderSelectedActionTypes.PUSH_ORDER_TO_DELETE_QUEUE_FAILURE,
  payload: error,
});

export const pushOrderToAddQueueStart = (orderToAdd) => ({
  type: SetupUsersOrderSelectedActionTypes.PUSH_ORDER_TO_ADD_QUEUE_START,
  payload: orderToAdd,
});

export const pushOrderToAddQueueSuccess = (orderToAdd) => ({
  type: SetupUsersOrderSelectedActionTypes.PUSH_ORDER_TO_ADD_QUEUE_SUCCESS,
  payload: orderToAdd,
});

export const pushOrderToAddQueueFailure = (error) => ({
  type: SetupUsersOrderSelectedActionTypes.PUSH_ORDER_TO_ADD_QUEUE_FAILURE,
  payload: error,
});

import { Box, Button } from 'grommet';
import moment from 'moment';
import { useEffect, useState } from 'react';
import ReactSpeedometer from 'react-d3-speedometer';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import CustomCalendar from '@Components/custom-calendar/custom-calendar.component';
import SideBar from '@Components/sidebar/sidebar.component';
import { Messages } from '@Pages/count-linen/count-linen-config';
import { LabelBox } from '@Pages/count-linen/count-linen.styles';
import {
  selectCountLinenCartsAndClosetsDeliveryForDeliveryID,
  selectCountLinenCartsAndClosetsDeliveryForDeliveryIDPrevious,
} from '@State/count-linen-carts-and-closets/count-linen-carts-and-closets.selectors';
import { selectCurrentCustomer } from '@State/user/user.selectors';
import { Text16 } from '@ThemeMain';

const SideBarControl = (props) => {
  const {
    currentCustomer,
    pageID,
    type,
    activeOrder,
    gaugeInfo,
    getOrderSchedule,
    getOrdersNotScheduled,
    getGaugeInfo,
    orderScheduleParams,
    setOrderScheduleParams,
    updateSharedVariables,
    laundryWeight,
    soilWeight,
    laundryCharge,
    deliveryItemData,
    deliveryForDeliveryIDPrevious,
    createLinenRoomDeliveryForCustomerOnDate,
    updateDeliveryCount,
    changes,
    setChanges,
    productsLoaded,
    setProductsLoaded,
    onInitialize,
  } = props;

  const [date, setDate] = useState(new Date());

  useEffect(() => {
    setOrderScheduleParams({ ...orderScheduleParams, customerID: currentCustomer.RcID });
  }, [currentCustomer]);

  const getGauges = (data, columns) => {
    const gaugeRows = [];
    const gauges = [];

    const gaugeBoxStyle = {
      height: '140px',
      overflow: 'hidden',
    };

    data.forEach((item, index) => {
      gauges.push(
        <Box key={`gauge${index + 1}`} style={gaugeBoxStyle}>
          <ReactSpeedometer
            key={`gauge ${index + 1}`}
            maxValue={parseInt(item.Maximum, 10)}
            minValue={parseInt(item.Minimum, 10)}
            value={parseInt(item.Needle, 10)}
            segments={5}
            currentValueText={
              item.DescLine1.includes('Counted')
                ? `${item.DescLine1} ${item.DescLine2}`
                : `${item.DescLine1} ${parseInt(item.Needle, 10)}%`
            }
            paddingVertical="5"
            valueTextFontSize="12px"
            width={160}
          />
        </Box>,
      );

      if (gauges.length === columns || index === data.length - 1) {
        gaugeRows.push(
          <Box direction="row" gap="small" key={`gaugeRow${gaugeRows.length + 1}`} justify="center">
            {gauges.splice(0, columns)}
          </Box>,
        );
      }
    });

    return gaugeRows;
  };

  const saveChanges = () => {
    if (deliveryItemData && deliveryItemData.data) {
      const deliveryTableData = JSON.parse(JSON.stringify(deliveryItemData.data.deliveryTable));
      if (pageID === 'linen-room') {
        deliveryTableData.LdryWght = laundryWeight;
        deliveryTableData.RetnWght = soilWeight;
        deliveryTableData.LdryDelvChrg = laundryCharge;
      }

      const formData = {
        deliveryItemTable: deliveryForDeliveryIDPrevious.data.deliveryItemTable,
        modifiedDeliveryItemTable: deliveryItemData.data.deliveryItemTable,
        modifiedDeliveryTable: deliveryTableData,
      };
      updateSharedVariables({ key: 'onInitialize', value: true });
      updateDeliveryCount({ formData });
    }
  };

  const datesNotEqual = (current, prev) => {
    return current.format('YYYY-MM-DD') !== prev.format('YYYY-MM-DD');
  };

  const onDateChange = (value) => {
    let formattedDate = null;
    const currentDate = moment(value).clone();
    const previousDate = moment(date).clone();

    setDate(value);

    if (
      (changes &&
        datesNotEqual(currentDate, previousDate) &&
        window.confirm(Messages.unSaveChanges)) ||
      (!changes && datesNotEqual(currentDate, previousDate))
    ) {
      formattedDate = moment(value).format('YYYY-MM-DDT12:00:00');
      const params = {
        ...orderScheduleParams,
        requestedDate: formattedDate,
        requestedDay: formattedDate,
        RequestedDay: formattedDate,
        activeOrderRcID: activeOrder.RcID,
      };

      if (changes) {
        setChanges(false);
      }
      setOrderScheduleParams(params);

      if (pageID === 'carts-and-closets') {
        setProductsLoaded(false);
        getOrderSchedule(params);
        getOrdersNotScheduled(params);
      } else {
        createLinenRoomDeliveryForCustomerOnDate(params);
      }
    } else if (currentDate !== previousDate) {
      formattedDate = previousDate.format('YYYY-MM-DDT12:00:00');
      setTimeout(() => {
        setDate(previousDate._i);
      }, 100);
    }

    updateSharedVariables({ key: 'date', value: formattedDate });
  };

  return (
    <SideBar>
      <LabelBox fill="horizontal">
        <Text16>
          {type} Date
          {moment(date).format('MM/DD/YYYY')}
        </Text16>
      </LabelBox>
      <Box fill="horizontal">
        <CustomCalendar value={date} onChange={(value) => onDateChange(value)} />
      </Box>
      <Button
        alignSelf="center"
        label={`Go to Today: ${moment(Date.now()).format('MM/DD/YYYY')}`}
        onClick={() => onDateChange(new Date())}
      />
      {pageID === 'carts-and-closets' ? (
        <Box direction="column" gap="xsmall">
          {gaugeInfo.length > 0 ? getGauges(gaugeInfo, 2) : null}
        </Box>
      ) : (
        <Box />
      )}
    </SideBar>
  );
};

const mapStateToProps = createStructuredSelector({
  currentCustomer: selectCurrentCustomer,
  deliveryItemData: selectCountLinenCartsAndClosetsDeliveryForDeliveryID,
  deliveryForDeliveryIDPrevious: selectCountLinenCartsAndClosetsDeliveryForDeliveryIDPrevious,
});
export default connect(mapStateToProps, null)(SideBarControl);

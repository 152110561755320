import { Box, Button, DataTable, Heading } from 'grommet';
import { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import SetupUserButton from '@Components/setup-users-buttons/setup-users-buttons.component';
import { getCustomerNotSelectedStart } from '@State/setup-users-customer-not-selected-in-user/setup-users-customer-not-selected-in-user.actions';
import { selectCustomersNotSelected } from '@State/setup-users-customer-not-selected-in-user/setup-users-customer-not-selected-in-user.selectors';
import { getCustomerSelectedStart } from '@State/setup-users-customer-selected-in-user/setup-users-customer-selected-in-user.actions';
import { selectCustomersSelected } from '@State/setup-users-customer-selected-in-user/setup-users-customer-selected-in-user.selectors';
import { selectCurrentUser } from '@State/user/user.selectors';

const CustomerAccessTab = (props) => {
  // useEffect(() => {
  //   getCustomerSelected();
  //   getCustomerNotSelected();
  // }, []);

  const [showAddCustomerButton, setShowAddCustomerButton] = useState(false);
  const [showDeleteCustomerButton, setShowDeleteCustomerButton] = useState(false);

  const columns = [
    {
      property: 'ObjDesc',
      header: 'Description',
      primary: true,
    },
  ];

  const { customerSelected, evaluateChange } = props;

  return (
    <Box pad="small">
      {showAddCustomerButton ? (
        <SetupUserButton
          evaluateChange={evaluateChange}
          title="Add Customer"
          onClose={setShowAddCustomerButton}
        />
      ) : null}
      {showDeleteCustomerButton ? (
        <SetupUserButton
          evaluateChange={evaluateChange}
          title="Delete Customer"
          onClose={setShowDeleteCustomerButton}
        />
      ) : null}
      <Heading margin="0" pad="medium" level={6}>
        Customer Access
      </Heading>
      <Box width="medium" alignSelf="start" direction="row">
        <Button
          label="Add Customer"
          onClick={() => {
            setShowAddCustomerButton(true);
          }}
        />
        <Button
          label="Delete Customer"
          onClick={() => {
            setShowDeleteCustomerButton(true);
          }}
        />
      </Box>
      <Box fill>
        {customerSelected ? (
          <DataTable primarykey="RcID" columns={columns} data={customerSelected} sortable />
        ) : (
          <DataTable primarykey="RcID" columns={columns} data={[]} sortable />
        )}
      </Box>
    </Box>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  customerSelected: selectCustomersSelected,
  customerNotSelected: selectCustomersNotSelected,
});

const mapDispatchToProps = (dispatch) => ({
  getCustomerSelected: () => dispatch(getCustomerSelectedStart()),
  getCustomerNotSelected: () => dispatch(getCustomerNotSelectedStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CustomerAccessTab));

import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Prompt } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { Box, Grid, TableCell, Select, Text, CheckBox, TextInput } from 'grommet';
import AddProductsModal from './add-products-modal.component';
import EditPackModal from './edit-pack-modal.component';
import AdjustItemsModal from './adjust-items-modal.component';
import ConfirmationModal from './confirmation-modal.component';
import UploadModal from './upload-modal.component';
import ProductImportTemplate from '../../assets/templates/product-import-template.xlsx';
import OrderImportTemplate from '../../assets/templates/order-import-template.xlsx';

import SearchSortBar from '@Components/search-sort-bar/search-sort-bar.component';
import { filterInput, hightlightCell, INPUT_TYPE, toArray } from '@Helpers/common.helper';
import { Messages } from '@Pages/count-linen/count-linen-config';
import { ResultsDataTable, ResultsDataTableV2 } from '@Pages/count-linen/count-linen.styles';
import {
  formData,
  helpAccountLink,
  helpLink,
  methodCalls,
  modals,
  SetUpMessage,
  SetUpPageID,
  tableConfig,
} from '@Pages/setup/setup-config';
import { getReportFilterOptionsStart, setReportTypeStart } from '@State/reports/reports.actions';
import {
  selectAccounts,
  selectAccountsPrevious,
  selectSaveAccountsResponse,
} from '@State/setup-accounts/setup-accounts.selectors';
import {
  selectAddItemToOrderResponse,
  selectAddProductToPackResponse,
  selectAddProductToProcedureResponse,
  selectCustomerAccounts,
  selectDepartmentAcctTypes,
  selectDepartmentsUsedForCustomer,
  selectDepartmentsUsedForCustomerPrevious,
  selectHandheldNumbers,
  selectInactivateResponse,
  selectInventoryFlow,
  selectItemAccounts,
  selectItemsNotSelectedInOrder,
  selectItemsSelectedInOrder,
  selectItemsSelectedInOrderPrevious,
  selectMoveToRowResponse,
  selectOrdersUsedForCustomer,
  selectOrdersUsedForCustomerPrevious,
  selectOrderTypes,
  selectPacksUsedForCustomer,
  selectPacksUsedForCustomerPrevious,
  selectPageOfCustomers,
  selectPageOfCustomersPrevious,
  selectProceduresUsedForCustomer,
  selectProceduresUsedForCustomerPrevious,
  selectProductsNotSelectedInPack,
  selectProductsNotSelectedInProcedure,
  selectProductsSelectedInPack,
  selectProductsSelectedInPackPrevious,
  selectProductsSelectedInProcedure,
  selectProductsSelectedInProcedurePrevious,
  selectProductsUsedForCustomer,
  selectProductsUsedForCustomerPrevious,
  selectSnapshot,
  selectUpdateCustomersResponse,
  selectUpdateDepartmentsUsedForCustomerResponse,
  selectUpdateItemsSelectedAndNotSelectedInOrderResponse,
  selectUpdateOrdersUsedForCustomerResponse,
  selectUpdatePacksUsedForCustomerResponse,
  selectUpdateProceduresUsedForCustomerResponse,
  selectUpdateProductsSelectedAndNotSelectedInPackResponse,
  selectUpdateProductsSelectedAndNotSelectedInProcedureResponse,
  selectUpdateProductsUsedForCustomerResponse,
  selectValidateDeletionResponse,
  selectImportError,
} from '@State/setup/setup.selectors';
import Dialog from '../dialog/dialog.component';

import { selectCurrentCustomer, selectCurrentUser } from '@State/user/user.selectors';
import {
  BrandButton,
  colors,
  measures,
  ResizableColumnTable,
  ScrollableBox,
  SideHeading,
  TemplateButton,
} from '@ThemeMain';
let emptyStringChanges = [];

const SetUpResultBox = (props) => {
  const {
    pageID,
    pageTitle,
    canModify,
    canView,
    type,
    currentCustomer,
    currentUser,
    /** Packs * */
    packsUsedForCustomer,
    packsUsedForCustomerPrevious,
    productsSelectedInPack,
    productsNotSelectedInPack,
    productsSelectedInPackPrevious,
    updatePacksUsedForCustomerResponse,
    updateProductsSelectedAndNotSelectedInPackResponse,
    addProductToPackResponse,
    /** Procedures * */
    proceduresUsedForCustomer,
    proceduresUsedForCustomerPrevious,
    productsSelectedInProcedure,
    productsNotSelectedInProcedure,
    productsSelectedInProcedurePrevious,
    updateProceduresUsedForCustomerResponse,
    updateProductsSelectedAndNotSelectedInProcedureResponse,
    addProductToProcedureResponse,
    /** Setup Advance Customers * */
    pageOfCustomers,
    pageOfCustomersPrevious,
    updateCustomersResponse,
    customerAccounts,
    /** Orders * */
    ordersUsedForCustomer,
    ordersUsedForCustomerPrevious,
    itemsSelectedInOrder,
    itemsNotSelectedInOrder,
    itemsSelectedInOrderPrevious,
    updateOrdersUsedForCustomerResponse,
    updateItemsSelectedAndNotSelectedInOrderResponse,
    addItemToOrderResponse,
    /** Accounts * */
    accounts,
    accountsPrevious,
    saveAccountsResponse,
    /** Departments * */
    departmentsUsedForCustomer,
    departmentsUsedForCustomerPrevious,
    departmentAcctTypes,
    updateDepartmentsUsedForCustomerResponse,
    /** Products * */
    productsUsedForCustomer,
    productsUsedForCustomerPrevious,
    updateProductsUsedForCustomerResponse,
    /** Common Methods* */
    updateMainData,
    updateRightTableData,
    getMainData,
    getProducts,
    addProducts,
    validateDeletion,
    inactivate,
    getRightTableData,
    getSnapshot,
    /** Common Values* */
    itemAccounts,
    handheldNumbers,
    inventoryFlow,
    orderTypes,
    inactivateResponse,
    snapshot,
    setUpImportError,
    /** Adjust items **/
    setReportType,
    getReportFilterOptions,
    moveToRowResponse,
  } = props;

  const [showDialog, setShowDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogColor, setDialogColor] = useState(null);

  /** modals * */
  const [showAddProductsModal, setShowAddProductsModal] = useState(false);
  const [showEditPackModal, setShowEditPackModal] = useState(false);
  const [showAdjustItemsModal, setShowAdjustItemsModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  /** --modals end-- */
  const [showRightTable, setShowRightTable] = useState(false);
  const [modalMethod, setModalMethod] = useState('Add');
  const [tableData, setTableData] = useState([]);
  const [rightTableData, setRightTableData] = useState([]);
  const [mainData, setMainData] = useState(formData[type].main);
  const [modalData, setModalData] = useState({});
  const [searchKey, setSearchKey] = useState(tableConfig[type].searchKeys[0]);
  const [searchValue, setSearchValue] = useState('');
  const [searchKeyRight, setSearchKeyRight] = useState(tableConfig[type].searchKeysRight[0]);
  const [searchValueRight, setSearchValueRight] = useState('');
  const [onEdit, setOnEdit] = useState(false);
  /** options * */
  const [accntTypeOptions, setAccntTypeOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [departmentAccntTypeOptions, setDepartmentAccntTypeOptions] = useState([]);
  const [handheldOptions, setHandheldOptions] = useState([]);
  const [inventoryFlowOptions, setInventoryFlowOptions] = useState([]);
  /** --options end-- */
  const [orderTypeOptions, setOrderTypeOptions] = useState([]);
  const [rcID, setRcID] = useState(0);
  const [rcIDRight, setRcIDRight] = useState(0);
  const [clickCount, setClickCount] = useState(0);
  const [selectedMainRow, setSelectedMainRow] = useState({});
  const [activeInlineEdit, setActiveInlineEdit] = useState({ id: 0, key: 0 });
  const [currentCustomerTable, setCurrentCustomerTable] = useState({});
  const [modifiedCurrentCustomerTable, setModifiedCurrentCustomerTable] = useState({});
  const [activeRow, setActiveRow] = useState({});
  const [saveDisabledStatus, setSaveDisabledStatus] = useState(true);
  const [changesHistory, setChangesHistory] = useState({ columns: {}, columnsRight: {} });
  const [changedKeys, setChangedKeys] = useState({ columns: [], columnsRight: [] });
  const [showMainButtons, setShowMainButtons] = useState(true);
  const [isSelect, setIsSelect] = useState(false);
  const [selectedElem, setSelectedElem] = useState(null);
  /** for adjust items */
  const [acceptedPar, setAcceptedPar] = useState({});
  const noRightTable = [
    SetUpPageID.advanceCustomers,
    SetUpPageID.customerAccounts,
    SetUpPageID.itemAccounts,
    SetUpPageID.departmentAccounts,
    SetUpPageID.departments,
    SetUpPageID.products,
  ];
  const noSnapshot = [
    SetUpPageID.customerAccounts,
    SetUpPageID.departmentAccounts,
    SetUpPageID.itemAccounts,
  ];

  const [percentage, setPercentage] = useState(1);

  const getImportMessage = () => {
    let message;
    switch (pageID) {
      case SetUpPageID.orders:
        message = updateOrdersUsedForCustomerResponse?.data?.sReturnValue?._text;
        break;
      case SetUpPageID.products:
        message = updateProductsUsedForCustomerResponse?.data?.sReturnValue?._text;
        break;
      default:
        message = '';
        break;
    }
    return message;
  };

  useEffect(() => {
    if (setUpImportError === false) {
      setShowDialog(true);
      setDialogMessage('Proccess Complete' + `${getImportMessage()}`);
      setDialogTitle('Complete');
      setDialogColor(colors['text']);
    } else if (setUpImportError != null) {
      setShowDialog(true);
      setDialogMessage('An error occurred during import');
      setDialogTitle('Error');
      setDialogColor(colors['neutral-4']);
    }
  }, [setUpImportError]);

  useEffect(() => {
    setSearchValue('');
    setSearchValueRight('');
    setSearchKey(tableConfig[type].searchKeys[0]);
    setSearchKeyRight(tableConfig[type].searchKeysRight[0]);
    setShowMainButtons(true);
  }, [pageID]);

  useEffect(() => {
    if (!saveDisabledStatus) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  });

  useEffect(() => {
    if (snapshot !== null) {
      if (snapshot.data.length > 0) {
        const data = snapshot.data[0];
        const link = document.createElement('a');
        link.download = `${data.ReptDesc}.xlsx`;
        link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data.ReptData}`;
        link.click();
      }
    }
  }, [snapshot]);

  useEffect(() => {
    setShowRightTable(false);
    setMainData(formData[type].main);
    setSaveDisabledStatus(true);
    setSearchKey(tableConfig[type].searchKeys[0]);
    setSearchKeyRight(tableConfig[type].searchKeysRight[0]);
    if (type === SetUpPageID.orders) {
      setReportType(methodCalls[type].reportType);
    }
  }, [type]);

  useEffect(() => {
    let data = {};
    switch (pageID) {
      case SetUpPageID.packs:
        data = packsUsedForCustomer;
        break;
      case SetUpPageID.procedures:
        data = proceduresUsedForCustomer;
        break;
      case SetUpPageID.orders:
        data = ordersUsedForCustomer;
        break;
      case SetUpPageID.departments:
        data = departmentsUsedForCustomer;
        break;
      case SetUpPageID.products:
        data = productsUsedForCustomer;
        break;
      case SetUpPageID.advanceCustomers:
        data = pageOfCustomers;
        if (typeof pageOfCustomers.data !== 'undefined') {
          data = { data: { [tableConfig[type].dataKey]: pageOfCustomers.data } };
        }
        break;
      case SetUpPageID.customerAccounts:
      case SetUpPageID.itemAccounts:
      case SetUpPageID.departmentAccounts:
        data = accounts.length > 0 ? { data: { [tableConfig[type].dataKey]: accounts } } : accounts;
        break;
      default:
        break;
    }

    if (typeof data.data !== 'undefined') {
      const rows = [...evaluateData(data.data[tableConfig[type].dataKey])];
      const tempTable = rows;
      setTableData(appendRowNumber(tempTable));
      if (rows.length > 0) {
        const id = rows[0][tableConfig[type].primaryKey];
        setActiveRow(rows[0]);
        selectRowMaintable(id);
        setSelectedMainRow(rows[0]);
        getRightTableData({ [tableConfig[type].getProductKey]: id });
      }
    }
  }, [
    packsUsedForCustomer,
    proceduresUsedForCustomer,
    ordersUsedForCustomer,
    pageOfCustomers,
    accounts,
    departmentsUsedForCustomer,
    productsUsedForCustomer,
  ]);

  useEffect(() => {
    let data = {};
    switch (pageID) {
      case SetUpPageID.packs:
        data = productsSelectedInPack;
        break;
      case SetUpPageID.procedures:
        data = productsSelectedInProcedure;
        break;
      case SetUpPageID.orders:
        data = itemsSelectedInOrder;
        break;
      default:
        break;
    }

    if (typeof data.data !== 'undefined') {
      if (data.data !== null) {
        setRightTableData(data.data instanceof Array ? data.data : []);
      } else {
        setRightTableData([]);
      }
    }
  }, [productsSelectedInPack, productsSelectedInProcedure, itemsSelectedInOrder]);

  useEffect(() => {
    let data = [];
    switch (pageID) {
      case SetUpPageID.packs:
        data = productsNotSelectedInPack;
        break;
      case SetUpPageID.procedures:
        data = productsNotSelectedInProcedure;
        break;
      case SetUpPageID.orders:
        data = itemsNotSelectedInOrder;
        if (data && data.data && data.data.length) {
          data.data.sort((a, b) => a.ItemDesc.localeCompare(b.ItemDesc));
        }
        break;
      default:
        break;
    }
    if (typeof data.data !== 'undefined') {
      if (data.data.length && data.data.ProdDesc) {
        data.data.sort((a, b) => a.ProdDesc.localeCompare(b.ProdDesc));
      }
      setModalData(data.data);
    }
  }, [productsNotSelectedInPack, productsNotSelectedInProcedure, itemsNotSelectedInOrder]);

  useEffect(() => {
    let data = [];
    if (pageID === SetUpPageID.advanceCustomers) {
      data = customerAccounts;
    } else {
      data = itemAccounts;
    }
    if (typeof data.data !== 'undefined') {
      setAccntTypeOptions(formatOptions(evaluateData(data.data), 'AcctDesc'));
    }
  }, [itemAccounts, customerAccounts]);

  useEffect(() => {
    let departments = evaluateData(departmentsUsedForCustomer.data);
    departments = departments.length ? [...evaluateData(departments[0].departmentTable)] : [];
    departments.push({ RcID: '0', DeptDesc: '-- Select Department --' });
    setDepartmentOptions(formatOptions(departments, 'DeptDesc'));
  }, [departmentsUsedForCustomer]);

  useEffect(() => {
    setDepartmentAccntTypeOptions(
      formatOptions(evaluateData(departmentAcctTypes.data), 'AcctDesc'),
    );
  }, [departmentAcctTypes]);

  useEffect(() => {
    setHandheldOptions(formatOptions(evaluateData(handheldNumbers.data), 'Desc', 'Value'));
  }, [handheldNumbers]);

  useEffect(() => {
    setInventoryFlowOptions(formatOptions(evaluateData(inventoryFlow.data), 'Desc'));
  }, [inventoryFlow]);

  useEffect(() => {
    setOrderTypeOptions(formatOptions(evaluateData(orderTypes.data), 'Desc'));
  }, [orderTypes]);

  useEffect(() => {
    let data = [];
    let formData = { customerID: currentCustomer.RcID };
    switch (pageID) {
      case SetUpPageID.packs:
        data = updatePacksUsedForCustomerResponse;
        break;
      case SetUpPageID.procedures:
        data = updateProceduresUsedForCustomerResponse;
        break;
      case SetUpPageID.orders:
        data = updateOrdersUsedForCustomerResponse;
        break;
      case SetUpPageID.advanceCustomers:
        data = updateCustomersResponse;
        formData = { startRow: 0, fetchRowCount: 1000 };
        break;
      case SetUpPageID.departments:
        data = updateDepartmentsUsedForCustomerResponse;
        break;
      case SetUpPageID.products:
        data = updateProductsUsedForCustomerResponse;
        break;
      case SetUpPageID.customerAccounts:
      case SetUpPageID.itemAccounts:
        data = saveAccountsResponse;
        formData = { accountType: tableConfig[type].accountType };
        break;
      default:
        break;
    }
    if (data !== null || moveToRowResponse) {
      setSaveDisabledStatus(true);
      getMainData(formData);
    }
  }, [
    updatePacksUsedForCustomerResponse,
    updateProceduresUsedForCustomerResponse,
    updateOrdersUsedForCustomerResponse,
    updateCustomersResponse,
    updateDepartmentsUsedForCustomerResponse,
    saveAccountsResponse,
    updateProductsUsedForCustomerResponse,
    moveToRowResponse,
  ]);

  useEffect(() => {
    let data = [];
    switch (pageID) {
      case SetUpPageID.packs:
        data = addProductToPackResponse;
        break;
      case SetUpPageID.procedures:
        data = addProductToProcedureResponse;
        break;
      case SetUpPageID.orders:
        data = addItemToOrderResponse;
        break;
      default:
        break;
    }
    if (data !== null) {
      getRightTableData({ [tableConfig[type].getProductKey]: rcID });
    }
  }, [addProductToPackResponse, addProductToProcedureResponse, addItemToOrderResponse]);

  useEffect(() => {
    let data = [];
    switch (pageID) {
      case SetUpPageID.packs:
        data = updateProductsSelectedAndNotSelectedInPackResponse;
        break;
      case SetUpPageID.procedures:
        data = updateProductsSelectedAndNotSelectedInProcedureResponse;
        break;
      case SetUpPageID.orders:
        data = updateItemsSelectedAndNotSelectedInOrderResponse;
        break;
      default:
        break;
    }
    if (data !== null) {
      getRightTableData({ [tableConfig[type].getProductKey]: rcID });
    }
  }, [
    updateProductsSelectedAndNotSelectedInPackResponse,
    updateProductsSelectedAndNotSelectedInProcedureResponse,
    updateItemsSelectedAndNotSelectedInOrderResponse,
  ]);

  useEffect(() => {
    if (inactivateResponse !== null) {
      getMainData({ customerID: currentCustomer.RcID });
    }
  }, [inactivateResponse]);

  const evaluateData = (data) => {
    if (typeof data === 'undefined') {
      return [];
    }
    if (!Array.isArray(data)) {
      return [data];
    }

    return data;
  };

  const formatOptions = (data, key = '', id = 'RcID') => {
    const options = {};
    data.forEach((item) => {
      options[item[id]] = item[key];
    });
    return options;
  };

  const filter = (mode) => {
    const searchResults = [];
    let currentData = [];
    let sKey = '';
    let sValue = '';
    const sValues = [];

    if (mode === 'right') {
      let data = [];
      switch (pageID) {
        case SetUpPageID.packs:
          data = productsSelectedInPack;
          break;
        case SetUpPageID.procedures:
          data = productsSelectedInProcedure;
          break;
        case SetUpPageID.orders:
          data = itemsSelectedInOrder;
          break;
        default:
          break;
      }
      currentData = tableData.length > 0 ? [...data.data] : [];
      sKey = searchKeyRight;
      sValue = searchValueRight;
    } else {
      let data = [];
      switch (pageID) {
        case SetUpPageID.packs:
          data = packsUsedForCustomer.data[tableConfig[type].dataKey];
          break;
        case SetUpPageID.procedures:
          data = proceduresUsedForCustomer.data[tableConfig[type].dataKey];
          break;
        case SetUpPageID.orders:
          data = ordersUsedForCustomer.data[tableConfig[type].dataKey];
          break;
        case SetUpPageID.advanceCustomers:
          data = pageOfCustomers.data;
          break;
        case SetUpPageID.departments:
          data = departmentsUsedForCustomer.data[tableConfig[type].dataKey];
          break;
        case SetUpPageID.products:
          data = productsUsedForCustomer.data[tableConfig[type].dataKey];
          break;
        case SetUpPageID.customerAccounts:
        case SetUpPageID.itemAccounts:
        case SetUpPageID.departmentAccounts:
          data = accounts;
          break;
        default:
          break;
      }
      currentData = data;
      sKey = searchKey;
      sValue = searchValue;

      if (pageID === SetUpPageID.orders || pageID === SetUpPageID.advanceCustomers) {
        let option = [];
        switch (sKey.id) {
          case 'InvtFlow':
            option = inventoryFlowOptions;
            break;
          case 'HhldNmbr':
            option = handheldOptions;
            break;
          case 'CustAcctRcID':
            option = accntTypeOptions;
            break;
          default:
            break;
        }

        for (const i in option) {
          if (option[i].toLowerCase().indexOf(sValue.toLowerCase()) > -1) {
            sValues.push(i);
          }
        }
      }
    }

    for (const i in currentData) {
      if (typeof currentData[i][sKey.id] !== 'undefined') {
        const value = currentData[i][sKey.id].toString().toLowerCase();
        if (sValues.length > 0 && sValues.includes(value)) {
          searchResults.push(currentData[i]);
        } else if (value.includes(sValue.toLowerCase())) {
          searchResults.push(currentData[i]);
        }
      }
    }

    if (mode === 'right') {
      setRightTableData(searchResults);
    } else {
      setTableData(searchResults);
    }
  };

  const getModuleData = (data) => {
    if (Array.isArray(data)) {
      return data;
    }

    switch (pageID) {
      case SetUpPageID.departments:
        return data.departmentTable;
      case SetUpPageID.products:
        return data.productPricingTable;
      case SetUpPageID.packs:
      case SetUpPageID.procedures:
        return data.packPricingTable;
      case SetUpPageID.orders:
        return data.orderTable;
    }
  };

  const appendRowNumber = (data) => {
    if (!Array.isArray(data)) {
      if (data && data !== undefined) {
        data = 'data' in data ? getModuleData(data.data) : [];
      }
    }

    for (const i in data) {
      data[i].Row = parseInt(i, 10) + 1;
    }

    return copyObject(data);
  };

  const evaluateModifiedItem = (data, mode = '', list = null) => {
    if (data.value === '') {
      if (!data.key.endsWith('Desc')) {
        data.value = 0;
      } else {
        emptyStringChanges.unshift({ id: data.id, key: data.key });
      }
    } else if (
      emptyStringChanges.findIndex((elem) => elem.id === data.id && elem.key === data.key) !== -1
    ) {
      emptyStringChanges.splice(
        emptyStringChanges.findIndex((elem) => elem.id === data.id && elem.key === data.key),
        1,
      );
    }
    let tempData = [];
    let primaryKey = 'RcID';
    const index = `${data.id}-${data.key}`;
    const changesList = { ...(list !== null ? list : changesHistory) };
    const changes = Object.assign([], changedKeys);
    const modeChanges = changes[mode];

    if (keyExistInList(changesList[mode], index)) {
      const rowData = changesList[mode][index];
      const matchedModeChangesIndex = modeChanges.indexOf(index);
      if (rowData[data.key] === data.value && matchedModeChangesIndex !== -1) {
        modeChanges.splice(matchedModeChangesIndex, 1);
      } else if (matchedModeChangesIndex === -1) {
        modeChanges.push(index);
      }
      setChangedKeys({ ...changedKeys, [mode]: modeChanges });
    }
    if (changes.columns.length > 0 || changes.columnsRight.length > 0 || modeChanges.length > 0) {
      setSaveDisabledStatus(false);
    } else {
      setSaveDisabledStatus(true);
    }

    if (mode === 'columnsRight') {
      tempData = [...rightTableData];
    } else {
      tempData = [...tableData];
      primaryKey = tableConfig[type].primaryKey;
    }

    updateTableData(primaryKey, data.id, data.key, data.value, tempData);
    if (mode === 'columnsRight') {
      setRightTableData(tempData);
    } else {
      setTableData(tempData);
    }
  };

  const updateTableData = (primaryKey, primaryKeyValue, prop, value, obj) => {
    let matchFound = false;
    (obj.constructor === Object || obj.constructor === Array) &&
      Object.keys(obj).forEach((key) => {
        if (!matchFound) {
          if (key === primaryKey && obj[key] === primaryKeyValue) {
            obj[prop] = value;
            matchFound = true;
          } else {
            updateTableData(primaryKey, primaryKeyValue, prop, value, obj[key]);
          }
        } else {
        }
      });
  };

  const prepareDataForEdit = (data) => {
    const resultData = [...data];
    resultData.forEach((item) => {
      item.Usef = 1;
    });

    return resultData;
  };

  const onUpdate = (mode = '') => {
    if (mode === 'columnsRight') {
      let dataPrevious = [];
      const modifiedTable = prepareDataForEdit(rightTableData);
      let formData = {};
      switch (pageID) {
        case SetUpPageID.packs:
          dataPrevious = prepareDataForEdit(productsSelectedInPackPrevious.data);
          formData = {
            packProductTable: dataPrevious,
            modifiedPackProductTable: modifiedTable,
          };
          break;
        case SetUpPageID.procedures:
          dataPrevious = prepareDataForEdit(productsSelectedInProcedurePrevious.data);
          formData = {
            packProductTable: dataPrevious,
            modifiedPackProductTable: modifiedTable,
          };
          break;
        case SetUpPageID.orders:
          dataPrevious = prepareDataForEdit(itemsSelectedInOrderPrevious.data);
          formData = {
            orderItemTable: dataPrevious,
            modifiedOrderItemTable: modifiedTable,
          };
          break;
        default:
          break;
      }

      updateRightTableData({ formData });
    } else {
      let dataPrevious = [];
      let data = [];
      let formData = {};
      switch (pageID) {
        case SetUpPageID.packs:
          dataPrevious = packsUsedForCustomerPrevious;
          data = packsUsedForCustomer;
          formData = {
            formData: {
              packPricingTable: toArray(dataPrevious.data.packPricingTable),
              modifiedPackPricingTable: tableData,
              modifiedWeightPricingTable: data.data.weightPricingTable,
            },
          };
          break;
        case SetUpPageID.procedures:
          dataPrevious = proceduresUsedForCustomerPrevious;
          data = proceduresUsedForCustomer;
          formData = {
            formData: {
              packPricingTable: toArray(dataPrevious.data.packPricingTable),
              modifiedPackPricingTable: tableData,
              modifiedWeightPricingTable: data.data.weightPricingTable,
            },
          };
          break;
        case SetUpPageID.orders:
          dataPrevious = ordersUsedForCustomerPrevious;
          data = ordersUsedForCustomer;
          formData = {
            formData: {
              orderTable: toArray(dataPrevious.data.orderTable),
              modifiedOrderTable: tableData,
              modifiedCustomerTable: data.data.customerTable,
            },
          };
          break;
        case SetUpPageID.advanceCustomers:
          dataPrevious = pageOfCustomersPrevious.data;
          formData = {
            formData: {
              customerAccountTable: customerAccounts.data,
              modifiedCustomerAccountTable: customerAccounts.data,
              customerTable: dataPrevious,
              modifiedCustomerTable: tableData,
            },
          };
          break;
        case SetUpPageID.departments:
          dataPrevious = departmentsUsedForCustomerPrevious.data;
          formData = {
            formData: {
              departmentTable: dataPrevious.departmentTable,
              modifiedDepartmentTable: tableData,
              modifiedCustomerTable: departmentsUsedForCustomer.data.customerTable,
            },
          };
          break;
        case SetUpPageID.products:
          dataPrevious = productsUsedForCustomerPrevious.data;
          formData = {
            formData: {
              productPricingTable: dataPrevious.productPricingTable,
              modifiedProductPricingTable: tableData,
              modifiedWeightPricingTable: dataPrevious.weightPricingTable,
            },
          };
          break;
        case SetUpPageID.customerAccounts:
        case SetUpPageID.itemAccounts:
        case SetUpPageID.departmentAccounts:
          formData = {
            accountType: tableConfig[type].accountType,
            formData: {
              accountTable: accountsPrevious,
              modifiedAccountTable: tableData,
            },
          };
          break;
        default:
          break;
      }
      updateMainData(formData);
    }
  };

  const formatValue = (value, data, label) => {
    let formattedValue;
    if (!onEdit) {
      switch (data.unit) {
        case 'lbs':
          formattedValue = `${parseFloat(value).toFixed(data.precision)} ${data.unit}`;
          break;
        case '$':
          formattedValue = `${data.unit} ${parseFloat(value).toFixed(data.precision)}`;
          break;
        case '':
          formattedValue = parseFloat(value).toFixed(data.precision);
          break;
        default:
          formattedValue = value;
          break;
      }
    }
    return formattedValue;
  };

  const onSelectChange = (data, currentData = {}, mode = '', elem = '') => {
    const modifiedData = copyObject(currentData);
    if (canModify) {
      if (pageID === SetUpPageID.advanceCustomers) {
        modifiedData.CustAcctRcID = data.value;
        setCurrentCustomerTable(currentData);
        setModifiedCurrentCustomerTable(modifiedData);
      }

      const changeList = updatedChangesHistory(currentData, data.key, mode);
      evaluateModifiedItem(data, mode, changeList);
      if (elem === 'checkbox') {
        setSaveDisabledStatus(false);
      }
    }
  };

  const handleDoubleClick = (data, mode) => {
    const prevID = rcID;
    const id = data[tableConfig[type].primaryKey];

    setActiveRow(data);
    if (clickCount === 1 && pageID !== SetUpPageID.advanceCustomers && prevID === id) {
      if (!showRightTable && canModify) {
        toggleModal({ id: modals[type].addModal, data, method: 'Edit' });
      }
      setClickCount(0);
    } else if (mode === 'columns') {
      if (!saveDisabledStatus && showRightTable && window.confirm(Messages.unSaveChanges)) {
        onCancel();
        onItemSelect(data, mode);
      } else if (saveDisabledStatus && showRightTable) {
        onItemSelect(data, mode);
      } else if (!showRightTable) {
        selectRowMaintable(id);
        setSelectedMainRow(data);
      }

      setClickCount(1);
      setTimeout(() => {
        setClickCount(0);
      }, 1000);
    }
  };

  const deleteRow = () => {
    inactivate({ [tableConfig[type].validateKey]: rcID, apiCall: methodCalls[type].inactivate });
    toggleModal({ id: modals[type].addModal, data: [], method: '' });
  };

  const activeEdit = (data, key, mode) => {
    const id = mode === 'columnsRight' ? data.RcID : data[tableConfig[type].primaryKey];
    return activeInlineEdit.id === id && activeInlineEdit.key === key;
  };

  const keyExistInList = (list, key) => {
    const keys = Object.keys(list);
    return keys.includes(key);
  };

  const updatedChangesHistory = (data, key, mode) => {
    let changesList = { ...changesHistory };
    const rows = { ...changesList[mode] };
    const id = mode === 'columns' ? data[tableConfig[type].primaryKey] : data.RcID;
    const index = `${id}-${key}`;

    if (!keyExistInList(rows, index)) {
      rows[index] = { ...data };
      changesList = { ...changesHistory, [mode]: rows };
      setChangesHistory(changesList);
    }

    return changesList;
  };

  const onInlineEdit = (value, data, key, mode = '') => {
    if (canModify) {
      if (mode === 'columns') {
        selectRowMaintable(data[tableConfig[type].primaryKey]);
        setSelectedMainRow(data);
        pageID !== SetUpPageID.orders &&
          setActiveInlineEdit({ id: data[tableConfig[type].primaryKey], key });
      } else {
        setRcIDRight(data[tableConfig[type].primaryKeyRight]);
        pageID !== SetUpPageID.orders && setActiveInlineEdit({ id: data.RcID, key });
      }
      updatedChangesHistory(data, key, mode);
    }
  };

  const saveChanges = () => {
    setSaveDisabledStatus(true);
    for (const index in changedKeys) {
      if (Object.keys(changedKeys[index]).length > 0) {
        onUpdate(index);
      }
    }
    setChangedKeys({ columns: [], columnsRight: [] });
    setChangesHistory({ columns: {}, columnsRight: {} });
  };

  const copyObject = (data) => {
    return data ? JSON.parse(JSON.stringify(data)) : [];
  };

  const onCancel = () => {
    emptyStringChanges = [];
    setSaveDisabledStatus(true);
    switch (pageID) {
      case SetUpPageID.customerAccounts:
        setTableData(appendRowNumber(accountsPrevious));
        break;
      case SetUpPageID.departmentAccounts:
      case SetUpPageID.departmentAcctTypes:
      case SetUpPageID.itemAccounts:
        setTableData(appendRowNumber(accountsPrevious));
        break;
      case SetUpPageID.departments:
        setTableData(appendRowNumber(departmentsUsedForCustomerPrevious));
        break;
      case SetUpPageID.products:
        setTableData(appendRowNumber(productsUsedForCustomerPrevious));
        break;
      case SetUpPageID.packs:
        setTableData(appendRowNumber(packsUsedForCustomerPrevious));
        setRightTableData(appendRowNumber(productsSelectedInPackPrevious));
        break;
      case SetUpPageID.procedures:
        setTableData(appendRowNumber(proceduresUsedForCustomerPrevious));
        setRightTableData(appendRowNumber(productsSelectedInProcedurePrevious));
        break;
      case SetUpPageID.orders:
        setTableData(appendRowNumber(ordersUsedForCustomerPrevious));
        setRightTableData(appendRowNumber(itemsSelectedInOrderPrevious));
        break;
      case SetUpPageID.advanceCustomers:
        setTableData(appendRowNumber(pageOfCustomersPrevious));
        break;
    }
  };

  const onHeaderClick = (key, mode) => {
    const searchKeys =
      mode === 'columnsRight' ? tableConfig[type].searchKeysRight : tableConfig[type].searchKeys;
    const index = searchKeys.map((e) => e.id).indexOf(key);

    if (mode === 'columnsRight' && index > -1) {
      setSearchKeyRight(searchKeys[index]);
    } else if (index > -1) {
      setSearchKey(searchKeys[index]);
    }
  };

  const onSelectItem = (datum, mode) => {
    evaluateRowClick(datum, mode);
    getRightTableData({
      [tableConfig[type].getProductKey]: datum[tableConfig[type].primaryKey],
    });
  };

  const onFocus = (datum, mode, key, event) => {
    const $elem = event.target;
    setActiveRow(datum);
    onInlineEdit(datum[key], datum, key, mode);

    if (isSelect) {
      setTimeout(() => {
        $elem.select();
        setIsSelect(false);
      }, 50);
    }
  };

  const onKeyDown = (event, inputType) => {
    if (event.key === 'Tab') {
      event.preventDefault();
    }
    filterInput(
      event,
      inputType === undefined || inputType === 'text'
        ? 'text'
        : inputType === 'float'
        ? INPUT_TYPE.float
        : INPUT_TYPE.int,
      inputType === 'number' || inputType === 'float' ? ['-'] : [],
    );
  };

  const assignClass = (datum, mode) => {
    return (mode === 'columns' && datum[tableConfig[type].primaryKey] === rcID) ||
      (mode === 'columnsRight' && datum[tableConfig[type].primaryKeyRight] === rcIDRight)
      ? 'white-label'
      : '';
  };

  const onBlur = () => {
    if (pageID !== SetUpPageID.orders) {
      setActiveInlineEdit({ id: 0, key: 0 });
    }
  };

  const onItemSelect = (datum, mode) => {
    evaluateRowClick(datum, mode);
    getRightTableData({
      [tableConfig[type].getProductKey]: datum[tableConfig[type].primaryKey],
    });
  };

  const onItemClick = (datum, mode) => {
    if (!saveDisabledStatus && showRightTable && window.confirm(Messages.unSaveChanges)) {
      onCancel();
      onItemSelect(datum, mode);
    } else if (saveDisabledStatus) {
      onItemSelect(datum, mode);
    }
  };

  const getColumns = (mode = '') => {
    const columns = [];
    let columnConfig = tableConfig[type][mode];
    if (type === 'orders' && mode === 'columnsRight' && selectedMainRow.TypeDesc === 'Census') {
      columnConfig = tableConfig[type].columnsRightCensus;
    }
    let column = {};
    const allowedOnProductsShow = [
      'PackRcID',
      'RcID',
      'Row',
      'PackDesc',
      'OrdrDesc',
      'ProdDesc',
      'Qnty',
    ];

    for (const key in columnConfig) {
      column = {};
      column.property = key;
      column.header = (
        <Box fill="horizontal" onClick={() => onHeaderClick(key, mode)}>
          <Text>{columnConfig[key].label}</Text>
        </Box>
      );

      if (showRightTable && !allowedOnProductsShow.includes(key) && mode !== 'columnsRight') {
        continue;
      }
      const blockContentStyle = {
        // width: typeof columnConfig[key].width !== 'undefined' ? columnConfig[key].width : '',
        // height: "30px",
      };

      if (!canModify) {
        columnConfig[key].type =
          columnConfig[key].type !== 'checkBox' ? 'text' : columnConfig[key].type;
      }

      switch (columnConfig[key].type) {
        case 'text':
          column.render = (datum) => (
            <Box
              fill="horizontal"
              style={blockContentStyle}
              className={assignClass(datum, mode)}
              onClick={() => handleDoubleClick(datum, mode)}
            >
              <Text>
                {!canModify && (key === 'InvtFlow' || key === 'HhldNmbr')
                  ? key === 'InvtFlow'
                    ? inventoryFlowOptions[datum[key]]
                    : handheldOptions[datum[key]]
                  : datum[key]}
              </Text>
            </Box>
          );
          break;
        case 'textInput':
          column.render = (datum) =>
            showRightTable && mode !== 'columnsRight' ? (
              <Box
                primary={false}
                style={blockContentStyle}
                className={assignClass(datum, mode)}
                onBlur={(event) => setActiveInlineEdit({ id: 0, key: 0 })}
                onClick={() => onItemClick(datum, mode)}
              >
                <Text>{datum[key]}</Text>
              </Box>
            ) : (
              <TextInput
                className={`${assignClass(datum, mode)} wd-auto`}
                type="text"
                value={
                  activeEdit(datum, key, mode) &&
                  (columnConfig[key].precision || columnConfig[key].unit)
                    ? datum[key]
                    : formatValue(datum[key], columnConfig[key], columnConfig[key].label)
                }
                style={blockContentStyle}
                primary={false}
                onClick={(event) => {
                  hightlightCell(event, selectedElem);
                }}
                onFocus={(event) => {
                  hightlightCell(event, selectedElem);
                  onFocus(datum, mode, key, event);
                }}
                onBlur={(event) => {
                  event.target.closest('td').classList.remove('cell-focus');
                  onBlur();
                }}
                onKeyDown={(event) => onKeyDown(event, columnConfig[key].inputType)}
                onChange={(event) =>
                  evaluateModifiedItem(
                    {
                      id:
                        mode === 'columnsRight' ? datum.RcID : datum[tableConfig[type].primaryKey],
                      key,
                      value: event.target.value,
                    },
                    mode,
                  )
                }
              />
            );
          break;
        case 'checkBox':
          column.align = 'center';
          column.render = (datum) => (
            <CheckBox
              style={{ margin: 'auto' }}
              className={`debug-checkbux ${
                rcID === datum[tableConfig[type].primaryKey] ? 'white-checkbox' : ''
              }`}
              checked={datum[key] === '1'}
              onBeforeInput={() =>
                onInlineEdit(datum[columnConfig[key].key], datum, columnConfig[key].key, mode)
              }
              onChange={(event) =>
                onSelectChange(
                  {
                    id: type === 'columnsRight' ? datum.RcID : datum[tableConfig[type].primaryKey],
                    key,
                    value: datum[key] === '1' ? '0' : '1',
                  },
                  datum,
                  mode,
                  'checkbox',
                )
              }
            />
          );
          break;
        case 'select':
          let options = [];
          let value = '';

          switch (columnConfig[key].options) {
            case 'itemAccounts':
              options = evaluateData(itemAccounts.data);
              value = accntTypeOptions;
              break;
            case 'customerAccounts':
              options = evaluateData(customerAccounts.data);
              value = accntTypeOptions;
              break;
            case 'departmentsUsedForCustomer':
              let departments = evaluateData(departmentsUsedForCustomer.data);
              if (departments.length) {
                departments = [...evaluateData(departments[0].departmentTable)];
              }
              departments = evaluateData(departments);
              options = departments;
              value = departmentOptions;
              break;
            case 'departmentAcctTypes':
              options = evaluateData(departmentAcctTypes.data);
              value = departmentAccntTypeOptions;
              break;
            case 'handheldNumbers':
              options = evaluateData(handheldNumbers.data);
              value = handheldOptions;
              break;
            case 'inventoryFlow':
              options = evaluateData(inventoryFlow.data);
              value = inventoryFlowOptions;
              break;
            case 'orderTypes':
              options = evaluateData(orderTypes.data);
              value = orderTypeOptions;
              break;
            default:
              break;
          }

          column.render = (datum) => (
            <Select
              labelKey={columnConfig[key].hasOwnProperty('keyID') ? columnConfig[key].keyID : key}
              value={value[datum[columnConfig[key].key]]}
              options={options}
              style={blockContentStyle}
              onOpen={() =>
                onInlineEdit(datum[columnConfig[key].key], datum, columnConfig[key].key, mode)
              }
              onChange={({ option }) =>
                onSelectChange(
                  {
                    id: type === 'columnsRight' ? datum.RcID : datum[tableConfig[type].primaryKey],
                    key: columnConfig[key].key,
                    value: option[columnConfig[key].value],
                  },
                  datum,
                  mode,
                )
              }
              onBlur={(event) => hightlightCell(event, null, null)}
              onClick={(event) => {
                setSelectedElem({ ...event });
                hightlightCell(event, null, null);
              }}
              onFocus={(event) => {
                setSelectedElem({ ...event });
                hightlightCell(event, null, null);
                setIsSelect(true);
              }}
              onKeyDown={(event) => filterInput(event, INPUT_TYPE.int, ['-'])}
            />
          );
          break;
        default:
          break;
      }
      columns.push(column);
    }
    return columns;
  };

  const selectRowMaintable = (id) => {
    setRcID(id);
    setRcIDRight(0);
  };

  const evaluateRowClick = (data, mode) => {
    if (mode === 'columns') {
      selectRowMaintable(data[tableConfig[type].primaryKey]);
      setSelectedMainRow(data);
    } else {
      setRcIDRight(data[tableConfig[type].primaryKeyRight]);
    }
    setActiveRow(data);
  };

  const toggleModal = (data) => {
    switch (data.id) {
      case 'AddProductsModal':
        if (!showAddProductsModal) {
          if (data.method === 'Add') {
            getProducts({ [tableConfig[type].getProductKey]: rcID });
          } else if (data.method === 'Delete') {
            let selectedData = [];
            switch (pageID) {
              case SetUpPageID.packs:
                selectedData = productsSelectedInPack;
                break;
              case SetUpPageID.procedures:
                selectedData = productsSelectedInProcedure;
                break;
              case SetUpPageID.orders:
                selectedData = itemsSelectedInOrder;
                break;
            }
            setModalData([...selectedData.data]);
          }
        }
        setModalMethod(data.method);
        setShowAddProductsModal(!showAddProductsModal);
        break;
      case 'EditPackModal':
        if (typeof data.data !== 'undefined') {
          setModalData(data.data);
        } else if (data.method === 'Delivery Cost') {
          setModalData(productsUsedForCustomer.data.weightPricingTable);
        } else {
          setModalData(mainData);
        }
        setModalMethod(data.method);
        setShowEditPackModal(!showEditPackModal);
        break;
      case 'ConfirmationModal':
        setModalMethod(data.method);
        setShowConfirmationModal(!showConfirmationModal);
        break;
      case 'AdjustItemsModal':
        if (!showAdjustItemsModal) {
          getReportFilterOptions();
        }
        setShowAdjustItemsModal(!showAdjustItemsModal);
        break;
      case 'UploadModal':
        setShowUploadModal(!showUploadModal ? true : false);
        break;
      default:
        break;
    }
  };

  const openHelpPage = () => {
    const link = type.indexOf('Accounts') > -1 ? helpAccountLink : helpLink;
    const setupType = type.replace('Accounts', '').replace('advance', '');
    window.open(link.replace('{0}', setupType, '_blank'));
  };

  const toggleTables = () => {
    if (!showRightTable) {
      getRightTableData({
        [tableConfig[type].getProductKey]: rcID,
      });
    }
    setShowRightTable(!showRightTable);
    setShowMainButtons(!showMainButtons);
  };

  const onTableEdit = () => {
    if (!saveDisabledStatus && window.confirm(Messages.unSaveChanges)) {
      onCancel();
      toggleTables();
    } else if (saveDisabledStatus) {
      toggleTables();
    }
  };

  const applyPercent = (key) => {
    if (key == 'Enter') {
      for (let i = 0; i < tableData.length; i++) {
        let row = tableData[i];
        for (const key in row) {
          if (key === 'ClenPiecChrg') {
            evaluateModifiedItem(
              { id: row['ProdRcID'], key: key, value: row['ClenPiecChrg'] * percentage },
              'columns',
            );
          }
        }
      }
      onUpdate('columns');
      alert('Successfully updated');
    }
  };

  const downloadImportTemplate = () => {
    let template;
    let downloadName;
    switch (pageID) {
      case 'orders':
        template = OrderImportTemplate;
        downloadName = 'OrderImportTemplate.xlsx';
        break;
      case 'products':
        template = ProductImportTemplate;
        downloadName = 'ProductImportTemplate.xlsx';
        break;
      default:
        break;
    }

    if (template) {
      const link = document.createElement('a');
      link.href = template;
      link.download = downloadName;
      link.click();
    }
  };

  return (
    <>
      <Prompt when={!saveDisabledStatus} message={SetUpMessage.unSaveChanges} />
      {(() => {
        return (
          <>
            <Grid
              areas={[
                { name: 'header', start: [0, 0], end: [0, 0] },
                { name: 'report-table', start: [0, 1], end: [0, 1] },
              ]}
              columns={['100%', '100%', '100%', '100%']}
              rows={['auto', 'auto', 'auto', 'flex']}
              gap="0px"
              justify="stretch"
            >
              <Box
                className="right-table"
                direction="column"
                gap="small"
                background={colors.innerBg}
                style={{ width: '100%', minWidth: '1400px' }}
              >
                <SideHeading level="3">{`Setup ${pageTitle}`}</SideHeading>
                <ResizableColumnTable>
                  <TableCell className={showRightTable ? 'overflow-auto' : ''}>
                    <Box
                      className={showRightTable ? 'left-table' : ''}
                      style={{
                        display: 'block',
                      }}
                      direction="column"
                      gap="0.2rem"
                    >
                      <Box align="center" direction="row" gap="small" pad="small">
                        {canModify && showMainButtons && (
                          <>
                            <BrandButton
                              type="button"
                              label="Save Changes"
                              className={!saveDisabledStatus ? 'hasChanges' : ''}
                              disabled={saveDisabledStatus || emptyStringChanges.length > 0}
                              onClick={() => saveChanges()}
                              style={{ minWidth: '9%' }}
                            />
                            <BrandButton
                              type="button"
                              label="Cancel"
                              className={`${!saveDisabledStatus ? 'hasChanges' : ''} mgl-10`}
                              disabled={saveDisabledStatus}
                              onClick={() => onCancel()}
                            />
                          </>
                        )}
                        {!noRightTable.includes(pageID) && (canModify || canView) ? (
                          <BrandButton
                            label={`Edit ${
                              !showRightTable
                                ? pageID === SetUpPageID.orders
                                  ? 'Items'
                                  : 'Products'
                                : pageTitle
                            }`}
                            onClick={() => onTableEdit()}
                          />
                        ) : null}
                        <SearchSortBar
                          options={tableConfig[type].searchKeys}
                          searchKey={searchKey}
                          setSearchKey={(data) => setSearchKey(data)}
                          searchValue={searchValue}
                          setSearchValue={(data) => setSearchValue(data)}
                          filter={() => filter()}
                        />
                        {canModify && showMainButtons && (
                          <BrandButton
                            type="button"
                            label={`Add ${pageTitle}`}
                            onClick={() =>
                              toggleModal({ id: modals[type].addModal, method: 'Add' })
                            }
                            style={{ minWidth: '9%' }}
                          />
                        )}
                        {pageID === SetUpPageID.products ? (
                          <>
                            <Text style={{ paddingRight: '0.5em' }}>
                              Cost to Deliver One Pound of Linen $
                              {typeof productsUsedForCustomerPrevious.data !== 'undefined'
                                ? productsUsedForCustomerPrevious.data.weightPricingTable.WghtChrg
                                : '0.0000'}
                            </Text>
                            <BrandButton
                              onClick={() =>
                                toggleModal({ id: modals[type].addModal, method: 'Delivery Cost' })
                              }
                              type="button"
                              label="Change"
                            />
                            <TableCell verticalAlign="bottom" size="xsmall">
                              <Text>Piece Cost % change</Text>
                            </TableCell>
                            <TableCell size="xsmall">
                              <TextInput
                                onChange={(event) => setPercentage(event.target.value / 100)}
                                onKeyDown={(event) => applyPercent(event.key)}
                                value={percentage * 100}
                              />
                            </TableCell>
                          </>
                        ) : null}
                        {pageID === SetUpPageID.advanceCustomers && canModify ? (
                          <BrandButton
                            type="button"
                            label={`Delete ${pageTitle}`}
                            onClick={() =>
                              toggleModal({ id: 'ConfirmationModal', method: 'Delete' })
                            }
                          />
                        ) : !noSnapshot.includes(pageID) && showMainButtons ? (
                          <BrandButton
                            type="button"
                            label="Snapshot"
                            disabled={tableData.length === 0}
                            onClick={() =>
                              getSnapshot({
                                snapshotType: methodCalls[type].snapshot,
                                rcID: currentCustomer.RcID,
                              })
                            }
                          />
                        ) : null}
                        {showMainButtons && (
                          <BrandButton
                            type="button"
                            label="Help"
                            onClick={() => {
                              openHelpPage();
                            }}
                          />
                        )}
                        {(pageID === SetUpPageID.products || pageID === SetUpPageID.orders) &&
                        currentUser.rules.isAdminUser ? (
                          <>
                            <TemplateButton
                              onClick={downloadImportTemplate}
                              type="button"
                              label="Template"
                            />
                            <BrandButton
                              onClick={() => toggleModal({ id: 'UploadModal' })}
                              type="button"
                              label="Upload"
                            />
                          </>
                        ) : null}
                      </Box>
                      <ScrollableBox>
                        <ResultsDataTableV2
                          className={`${pageID} setup`}
                          bodySize={
                            showRightTable
                              ? measures.dataTableDoubleHeaderBodyHeight
                              : measures.dataTableBodyHeight
                          }
                          columns={getColumns('columns')}
                          data={tableData}
                          step={tableData.length}
                          // onClickRow={(event) => {
                          //   console.log(event,event.Key);
                          //   if(event.Key !== 'Enter') {
                          //     evaluateRowClick(event.datum, 'columns')
                          //   }
                          // }}
                          rowProps={{ [rcID]: { background: colors.brand } }}
                          sortable
                        />
                        {tableData.length === 0 ? (
                          <Box
                            align="center"
                            fill="horizontal"
                            background={colors['status-unknown']}
                          >
                            <Text weight="bold">No Data Available</Text>
                          </Box>
                        ) : null}
                      </ScrollableBox>
                    </Box>
                  </TableCell>
                  {showRightTable ? (
                    <TableCell className="overflow-auto">
                      <Box
                        className="right-table"
                        style={{
                          display: 'block',
                        }}
                        direction="column"
                        gap="0.2rem"
                      >
                        <Box direction="row" gap="small" pad="small">
                          <Text style={{ display: 'inline-block' }}>Products</Text>
                          {canModify && !showMainButtons && (
                            <>
                              <BrandButton
                                type="button"
                                label="Save Changes"
                                className={!saveDisabledStatus ? 'hasChanges' : ''}
                                disabled={saveDisabledStatus}
                                onClick={() => saveChanges()}
                              />
                              <BrandButton
                                type="button"
                                label="Cancel"
                                className={`${!saveDisabledStatus ? 'hasChanges' : ''} mgl-10`}
                                disabled={saveDisabledStatus}
                                onClick={() => onCancel()}
                              />
                            </>
                          )}
                          <SearchSortBar
                            options={tableConfig[type].searchKeysRight}
                            searchKey={searchKeyRight}
                            setSearchKey={(data) => setSearchKeyRight(data)}
                            searchValue={searchValueRight}
                            setSearchValue={(data) => setSearchValueRight(data)}
                            filter={() => filter('right')}
                          />
                          {canModify && (
                            <BrandButton
                              type="button"
                              label="Add Products"
                              onClick={() => toggleModal({ id: 'AddProductsModal', method: 'Add' })}
                            />
                          )}
                          {canModify && (
                            <BrandButton
                              type="button"
                              label="Delete Products"
                              onClick={() =>
                                toggleModal({ id: 'AddProductsModal', method: 'Delete' })
                              }
                            />
                          )}
                          {canModify && pageID === 'orders' ? (
                            <BrandButton
                              type="button"
                              label="Adjust Items"
                              onClick={() => toggleModal({ id: 'AdjustItemsModal' })}
                            />
                          ) : null}
                        </Box>
                        <ScrollableBox>
                          <ResultsDataTable
                            className={`setup-right right-${pageID}`}
                            style={{
                              minWidth: '750px',
                              maxWidth: '100%',
                            }}
                            bodySize={measures.dataTableDoubleHeaderBodyHeight}
                            step={rightTableData.length}
                            rowProps={{ [rcIDRight]: { background: colors['brand'] } }}
                            sortable
                            columns={getColumns('columnsRight')}
                            // onClickRow={(event) => evaluateRowClick(event.datum, 'columnsRight')}
                            data={rightTableData}
                          />
                        </ScrollableBox>
                      </Box>
                    </TableCell>
                  ) : null}
                </ResizableColumnTable>
              </Box>
            </Grid>
            {canModify && (
              <>
                <AddProductsModal
                  showModal={showAddProductsModal}
                  toggleModal={toggleModal}
                  rcID={rcID}
                  pageTitle={pageTitle}
                  data={modalData}
                  addEvent={addProducts}
                  updateEvent={updateRightTableData}
                  type="Products"
                  pageID={pageID}
                  method={modalMethod}
                />
                <EditPackModal
                  showModal={showEditPackModal}
                  toggleModal={toggleModal}
                  pageTitle={pageTitle}
                  rcID={rcID}
                  accntTypeOptions={accntTypeOptions}
                  departmentOptions={departmentOptions}
                  handheldOptions={handheldOptions}
                  inventoryFlowOptions={inventoryFlowOptions}
                  orderTypeOptions={orderTypeOptions}
                  departmentAccntTypeOptions={departmentAccntTypeOptions}
                  pageID={pageID}
                  type={type}
                  itemAccounts={itemAccounts}
                  departments={departmentsUsedForCustomer}
                  handheldNumbers={handheldNumbers}
                  inventoryFlow={inventoryFlow}
                  orderTypes={orderTypes}
                  departmentAcctTypes={departmentAcctTypes}
                  updateMainData={(data) => updateMainData(data)}
                  remove={() => deleteRow()}
                  validateDeletion={validateDeletion}
                  data={modalData}
                  method={modalMethod}
                  hasChanges={!saveDisabledStatus}
                />
                <ConfirmationModal
                  showModal={showConfirmationModal}
                  toggleModal={toggleModal}
                  pageTitle={pageTitle}
                  method={modalMethod}
                  confirm={deleteRow}
                  data={activeRow}
                />
                <AdjustItemsModal
                  showModal={showAdjustItemsModal}
                  toggleModal={toggleModal}
                  pageTitle={pageTitle}
                  type={type}
                  rcID={rcID}
                  data={[]}
                  method={modalMethod}
                  acceptedPar={acceptedPar}
                  setAcceptedPar={setAcceptedPar}
                  changedKeys={changedKeys}
                  setChangedKeys={setChangedKeys}
                  setSaveDisabledStatus={setSaveDisabledStatus}
                  sendCountLinenCartsAndClosetsHandheldInfo={() => {}}
                />
                <UploadModal
                  showModal={showUploadModal}
                  toggleModal={toggleModal}
                  setShowDialog={setShowDialog}
                  uploadType={pageID}
                />
              </>
            )}
            <Dialog
              showModal={showDialog}
              setShowModal={setShowDialog}
              message={dialogMessage}
              confirm={null}
              pageTitle={dialogTitle}
              showOkButton={true}
              showCancelButton={false}
              textColor={dialogColor}
            />
          </>
        );
      })()}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  currentCustomer: selectCurrentCustomer,
  currentUser: selectCurrentUser,
  /** Packs * */
  packsUsedForCustomer: selectPacksUsedForCustomer,
  packsUsedForCustomerPrevious: selectPacksUsedForCustomerPrevious,
  productsSelectedInPack: selectProductsSelectedInPack,
  productsNotSelectedInPack: selectProductsNotSelectedInPack,
  productsSelectedInPackPrevious: selectProductsSelectedInPackPrevious,
  updatePacksUsedForCustomerResponse: selectUpdatePacksUsedForCustomerResponse,
  updateProductsSelectedAndNotSelectedInPackResponse:
    selectUpdateProductsSelectedAndNotSelectedInPackResponse,
  addProductToPackResponse: selectAddProductToPackResponse,
  /** Procedures * */
  proceduresUsedForCustomer: selectProceduresUsedForCustomer,
  proceduresUsedForCustomerPrevious: selectProceduresUsedForCustomerPrevious,
  productsSelectedInProcedure: selectProductsSelectedInProcedure,
  productsNotSelectedInProcedure: selectProductsNotSelectedInProcedure,
  productsSelectedInProcedurePrevious: selectProductsSelectedInProcedurePrevious,
  updateProceduresUsedForCustomerResponse: selectUpdateProceduresUsedForCustomerResponse,
  updateProductsSelectedAndNotSelectedInProcedureResponse:
    selectUpdateProductsSelectedAndNotSelectedInProcedureResponse,
  addProductToProcedureResponse: selectAddProductToProcedureResponse,
  /** Orders * */
  ordersUsedForCustomer: selectOrdersUsedForCustomer,
  ordersUsedForCustomerPrevious: selectOrdersUsedForCustomerPrevious,
  itemsSelectedInOrder: selectItemsSelectedInOrder,
  itemsNotSelectedInOrder: selectItemsNotSelectedInOrder,
  itemsSelectedInOrderPrevious: selectItemsSelectedInOrderPrevious,
  updateOrdersUsedForCustomerResponse: selectUpdateOrdersUsedForCustomerResponse,
  updateItemsSelectedAndNotSelectedInOrderResponse:
    selectUpdateItemsSelectedAndNotSelectedInOrderResponse,
  addItemToOrderResponse: selectAddItemToOrderResponse,
  /** Setup Customers * */
  pageOfCustomers: selectPageOfCustomers,
  pageOfCustomersPrevious: selectPageOfCustomersPrevious,
  updateCustomersResponse: selectUpdateCustomersResponse,
  customerAccounts: selectCustomerAccounts,
  /** Accounts * */
  accounts: selectAccounts,
  accountsPrevious: selectAccountsPrevious,
  saveAccountsResponse: selectSaveAccountsResponse,
  /** Departments * */
  departmentsUsedForCustomer: selectDepartmentsUsedForCustomer,
  departmentsUsedForCustomerPrevious: selectDepartmentsUsedForCustomerPrevious,
  departmentAcctTypes: selectDepartmentAcctTypes,
  updateDepartmentsUsedForCustomerResponse: selectUpdateDepartmentsUsedForCustomerResponse,
  /** Products * */
  productsUsedForCustomer: selectProductsUsedForCustomer,
  productsUsedForCustomerPrevious: selectProductsUsedForCustomerPrevious,
  updateProductsUsedForCustomerResponse: selectUpdateProductsUsedForCustomerResponse,
  /** Common * */
  itemAccounts: selectItemAccounts,
  handheldNumbers: selectHandheldNumbers,
  inventoryFlow: selectInventoryFlow,
  orderTypes: selectOrderTypes,
  validateDeletionResponse: selectValidateDeletionResponse,
  inactivateResponse: selectInactivateResponse,
  snapshot: selectSnapshot,
  moveToRowResponse: selectMoveToRowResponse,
  setUpImportError: selectImportError,
});

const mapDispatchToProps = (dispatch) => ({
  setReportType: (type) => dispatch(setReportTypeStart(type)),
  getReportFilterOptions: () => dispatch(getReportFilterOptionsStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SetUpResultBox));

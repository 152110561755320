import { Box, Button, DataTable, Heading, Layer, List } from 'grommet';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import { setCurrentAdvancedUser } from '@State/setup-advanced-users/setup-advanced-users.actions';
import { selectAdvancedCurrentUsers } from '@State/setup-advanced-users/setup-advanced-users.selectors';
import { updateCustomerNotSelected } from '@State/setup-users-customer-not-selected-in-user/setup-users-customer-not-selected-in-user.actions';
import { selectCustomersNotSelected } from '@State/setup-users-customer-not-selected-in-user/setup-users-customer-not-selected-in-user.selectors';
import {
  addCustomerToAddQueueStart,
  addCustomerToDeleteQueueStart,
  deleteCustomerSelectedStart,
  saveCustomerSelectedStart,
} from '@State/setup-users-customer-selected-in-user/setup-users-customer-selected-in-user.actions';
import { selectCustomersSelected } from '@State/setup-users-customer-selected-in-user/setup-users-customer-selected-in-user.selectors';
import { updateOrderNotSelectedStart } from '@State/setup-users-order-not-selected-in-user/setup-users-order-not-selected-in-user.actions';
import {
  selectOrdersNotSelected,
  selectUserOrdersNotSelected,
} from '@State/setup-users-order-not-selected-in-user/setup-users-order-not-selected-in-user.selectors';
import {
  pushOrderToAddQueueStart,
  saveOrderSelectedStart,
} from '@State/setup-users-order-selected-in-user/setup-users-order-selected-in-user.actions';
import { selectCurrentUser } from '@State/user/user.selectors';
import { PrimaryButton } from '@ThemeMain';

const SetupUserButton = (props) => {
  // useEffect(() => {
  //   getCustomerSelected();
  //   getCustomerNotSelected();
  // }, []);
  const objColumn = [{ property: 'ObjDesc', primary: true }];
  const nameColumn = [{ property: 'Name', primary: true }];
  const noData = [{ Name: '-- Nothing Available --', ObjDesc: '-- Nothing Available --' }];

  const {
    onClose,
    customerNotSelected,
    customerSelected,
    advancedCurrentUser,
    orderNotSelected,
    userOrderNotSelected,
    saveCustomerSelected,
    saveOrderSelected,
    deleteCustomerSelected,
    evaluateChange,
    addCustomerToDeleteQueue,
    addCustomerToAddQueue,
    updateCustomerNotSelected,
    setCurrentUser,
    pushOrderToAddQueue,
    updateOrderNotSelected,
  } = props;

  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [selectedData, setSelectedData] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [itemListProp, setItemListProp] = useState({});
  const [listData, setListData] = useState([]);
  const style = { height: '400px', overflowY: 'scroll' };
  // useEffect(() => {
  //   switch (props.title) {
  //     case 'Add Customer':
  //         setListData(customerNotSelected !== null ? [...customerNotSelected] : []);
  //       break;
  //     case 'Delete Customer':
  //         setListData(customerSelected !== null ? [...customerSelected] : []);
  //       break;
  //     case 'Add Order':
  //         setListData(orderNotSelected !== null ? [...orderNotSelected] : []);
  //       break;
  //   }
  // }, [customerNotSelected, customerSelected, orderNotSelected]);

  useEffect(() => {
    switch (props.title) {
      case 'Add Customer':
        setListData(customerNotSelected !== null ? [...customerNotSelected] : []);
        break;
      case 'Delete Customer':
        setListData(customerSelected !== null ? [...customerSelected] : []);
        break;
      case 'Add Order':
        setListData(userOrderNotSelected !== null ? [...userOrderNotSelected] : []);
        break;
      default:
        break;
    }
  }, [props.title]);

  const onCtrlSelect = (row) => {
    updateSelected(row.item, selectedRows);

    const listProps = itemListProp;
    if (typeof listProps[row.index] === 'undefined') {
      listProps[row.index] = { background: 'brand' };
    } else {
      const temp = selectedRows;
      delete listProps[row.index];
      for (const i in temp) {
        if (temp[i].index === row.index) {
          delete temp[i];
        }
      }
      setSelectedRows(temp);
    }
    setItemListProp(listProps);
  };

  const updateSelected = (row, selected) => {
    const rows = selected;
    rows.push(row);
    setSelectedRows(rows);
  };

  const onShiftSelect = (row) => {
    const selected = [];

    const listProps = {};
    let startIndex = 0;
    let endIndex = 0;
    const keys = Object.keys(itemListProp);
    const firstKey = keys.length > 0 ? keys[0] : 0;
    setItemListProp({});

    if (row.index > firstKey) {
      startIndex = keys[keys.length - 1];
      endIndex = row.index;
    } else {
      startIndex = row.index;
      endIndex = firstKey;
    }

    for (let i = startIndex; i <= endIndex; i++) {
      const temp = listData[i];
      temp.index = i;

      updateSelected(temp, selected);
      listProps[i] = { background: 'brand' };
    }

    setItemListProp(listProps);
  };

  const onDefaultSelect = (row) => {
    setSelectedRows([]);
    setItemListProp({});
    updateSelected(row.item, []);

    const listProps = {};
    if (typeof listProps[row.index] === 'undefined') {
      listProps[row.index] = { background: 'brand' };
      setItemListProp(listProps);
    }
  };

  const addSelectedRows = (row) => {
    if (row.shiftKey) {
      onShiftSelect(row);
    } else if (row.ctrlKey || row.metaKey) {
      onCtrlSelect(row);
    } else {
      onDefaultSelect(row);
    }
  };

  const onConfirm = () => {
    selectedRows.map((item) => {
      switch (props.title) {
        case 'Add Customer':
          addCustomerToAddQueue(item);
          updateCustomerNotSelected(item);
          const addCustomerChange = { new: item, key: `customer-${item.RcID}` };
          evaluateChange(addCustomerChange);
          setCurrentUser({ ...advancedCurrentUser, ...addCustomerChange });
          break;
        case 'Delete Customer':
          addCustomerToDeleteQueue(item);
          updateCustomerNotSelected(item);
          const deleteCustomerChange = { new: item, key: `customer-${item.RcID}` };
          evaluateChange(deleteCustomerChange);
          setCurrentUser({ ...advancedCurrentUser, ...deleteCustomerChange });
          break;
        case 'Add Order':
          pushOrderToAddQueue(item);
          updateOrderNotSelected(item);
          const addOrderChange = { new: item, key: `order-${item.RcID}` };
          evaluateChange(addOrderChange);
          setCurrentUser({ ...advancedCurrentUser, ...addOrderChange });
          break;
        default:
          break;
      }
    });
    onClose(false);
  };

  return (
    <Layer onEsc={() => onClose(false)} onClickOutside={() => onClose(false)}>
      <Box pad="xsmall">
        <Heading margin="0" pad="xsmall" level={6}>
          {props.title}
        </Heading>
        <Heading margin="0" pad="xsmall" level={6}>
          Use CTRL/SHIFT + Click to select multiple items
        </Heading>
        <Box align="center" justify="center" fill>
          <Box width="medium">
            <Box overflow="auto" pad="xsmall">
              {(() => {
                switch (props.title) {
                  case 'Add Customer':
                    return customerNotSelected ? (
                      <List
                        data={customerNotSelected}
                        primaryKey="Name"
                        style={style}
                        itemProps={itemListProp}
                        onClickItem={(datum) => addSelectedRows(datum)}
                      />
                    ) : (
                      <DataTable columns={objColumn} data={noData} />
                    );
                  case 'Delete Customer':
                    return customerSelected ? (
                      <List
                        data={customerSelected}
                        primaryKey="ObjDesc"
                        style={style}
                        itemProps={itemListProp}
                        onClickItem={(datum) => addSelectedRows(datum)}
                      />
                    ) : (
                      <DataTable columns={objColumn} data={noData} />
                    );
                  case 'Add Order':
                    return userOrderNotSelected ? (
                      <List
                        data={userOrderNotSelected}
                        primaryKey="Name"
                        style={style}
                        itemProps={itemListProp}
                        onClickItem={(datum) => addSelectedRows(datum)}
                      />
                    ) : (
                      <DataTable columns={objColumn} data={noData} />
                    );
                  default:
                    return null;
                }
              })()}
            </Box>
            <Box width="medium">
              <Box overflow="auto" pad="xsmall">
                {(() => {
                  switch (props.title) {
                    case 'Delete Customer':
                      return (
                        <div>
                          <Box size="small">
                            {showDeletePrompt ? (
                              <Heading size="14px" color="red" onClose={setShowDeletePrompt}>
                                Warning: Removing the user's access to a customer will also remove
                                their access to the customer's order(s). Are you sure you would like
                                to proceed?
                              </Heading>
                            ) : null}
                          </Box>
                          <Box direction="row" justify="between" margin={{ top: 'medium' }}>
                            {showDeletePrompt ? null : (
                              <PrimaryButton
                                type="button"
                                label={props.title}
                                onClick={() => setShowDeletePrompt(true)}
                              />
                            )}
                            {showDeletePrompt ? (
                              <PrimaryButton
                                type="button"
                                label="Yes"
                                onClick={() => onConfirm()}
                              />
                            ) : null}
                            <Button label="Cancel" onClick={() => onClose(false)} />
                          </Box>
                        </div>
                      );
                    default:
                      return (
                        <div>
                          <Box direction="row" justify="between" margin={{ top: 'medium' }}>
                            <PrimaryButton
                              type="button"
                              label={props.title}
                              onClick={() => onConfirm()}
                            />
                            <Button label="Cancel" onClick={() => onClose(false)} />
                          </Box>
                        </div>
                      );
                  }
                })()}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Layer>
  );
};
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  customerSelected: selectCustomersSelected,
  customerNotSelected: selectCustomersNotSelected,
  orderNotSelected: selectOrdersNotSelected,
  userOrderNotSelected: selectUserOrdersNotSelected,
  advancedCurrentUser: selectAdvancedCurrentUsers,
});

const mapDispatchToProps = (dispatch) => ({
  saveCustomerSelected: (customer) => dispatch(saveCustomerSelectedStart(customer)),
  saveOrderSelected: (order) => dispatch(saveOrderSelectedStart(order)),
  deleteCustomerSelected: (customer) => dispatch(deleteCustomerSelectedStart(customer)),
  addCustomerToDeleteQueue: (customer) => dispatch(addCustomerToDeleteQueueStart(customer)),
  addCustomerToAddQueue: (customer) => dispatch(addCustomerToAddQueueStart(customer)),
  updateCustomerNotSelected: (customer) => dispatch(updateCustomerNotSelected(customer)),
  setCurrentUser: (user) => dispatch(setCurrentAdvancedUser(user)),
  pushOrderToAddQueue: (order) => dispatch(pushOrderToAddQueueStart(order)),
  updateOrderNotSelected: (order) => dispatch(updateOrderNotSelectedStart(order)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SetupUserButton));

import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

const sharedConfig = {
  applicationId: window._appConfig.DD_APPLICATION_ID,
  clientToken: window._appConfig.DD_CLIENT_TOKEN,
  service: window._appConfig.DD_SERVICE,
  site: window._appConfig.DD_SITE,
  env: window._appConfig.ENV_TYPE,
  version: window._appConfig.VERSION,
};

const datadogClient = {
  ...datadogRum,
  init: () => {
    // DataDog RUM Init
    datadogRum.init({
      ...sharedConfig,
      sessionSampleRate: 100,
      premiumSampleRate: 100,
      trackResources: true,
      trackLongTasks: true,
      trackFrustrations: true,
      trackInteractions: true,
      trackUserInteractions: true,
      trackViewsManually: true,
      defaultPrivacyLevel: 'mask-user-input',
    });

    // DataDog Logs Init
    datadogLogs.init({
      ...sharedConfig,
      forwardConsoleLogs: ['warn', 'error'],
      forwardErrorsToLogs: true,
    });
  },

  setUser: (data) => {
    datadogRum.setUser(data);
    datadogLogs.setUser(data);
  },

  setGlobalContext: (context) => {
    datadogRum.setRumGlobalContext(context);
    datadogLogs.setLoggerGlobalContext(context);
  },
};

datadogClient.init();

export default datadogClient;

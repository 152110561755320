import InventoryTypes from './inventory.types';

const INITIAL_STATE = {
  create_injection_for_customer_on_date_response: null,
  create_retirement_for_customer_on_date_response: null,
  inventory_for_inventory_id: [],
  inventory_for_inventory_id_previous: [],
  items_not_in_inventory: [],
  order_schedule_for_customer_on_date: [],
  inventory_form_pdf: null,
  update_inventory_counts_response: null,
  add_item_to_inventory_response: null,
  create_inventory_for_order_on_date_response: null,
  selected_rcid: null,
  error: null,
};

const inventoryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case InventoryTypes.CREATE_INJECTION_FOR_CUSTOMER_ON_DATE_SUCCESS:
      return {
        ...state,
        create_injection_for_customer_on_date_response: action.payload,
        error: null,
      };

    case InventoryTypes.CREATE_INJECTION_FOR_CUSTOMER_ON_DATE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case InventoryTypes.CREATE_RETIREMENT_FOR_CUSTOMER_ON_DATE_SUCCESS:
      return {
        ...state,
        create_retirement_for_customer_on_date_response: action.payload,
        error: null,
      };

    case InventoryTypes.CREATE_RETIREMENT_FOR_CUSTOMER_ON_DATE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case InventoryTypes.GET_INVENTORY_FOR_INVENTORY_ID_SUCCESS:
      return {
        ...state,
        inventory_for_inventory_id: action.payload,
        inventory_for_inventory_id_previous: JSON.parse(JSON.stringify(action.payload)),
        error: null,
      };

    case InventoryTypes.GET_INVENTORY_FOR_INVENTORY_ID_FAILURE:
      return {
        ...state,
        error: null,
      };

    case InventoryTypes.GET_ITEMS_NOT_IN_INVENTORY_SUCCESS:
      return {
        ...state,
        items_not_in_inventory: action.payload,
        error: null,
      };

    case InventoryTypes.GET_ITEMS_NOT_IN_INVENTORY_FAILURE:
      return {
        ...state,
        error: null,
      };

    case InventoryTypes.GET_ORDER_SCHEDULE_FOR_CUSTOMER_ON_DATE_SUCCESS:
      return {
        ...state,
        order_schedule_for_customer_on_date: action.payload,
        error: null,
      };

    case InventoryTypes.GET_ORDER_SCHEDULE_FOR_CUSTOMER_ON_DATE_FAILURE:
      return {
        ...state,
        error: null,
      };

    case InventoryTypes.GET_INVENTORY_FORM_PDF_SUCCESS:
      return {
        ...state,
        inventory_form_pdf: action.payload,
        error: null,
      };

    case InventoryTypes.GET_INVENTORY_FORM_PDF_FAILURE:
      return {
        ...state,
        error: null,
      };

    case InventoryTypes.UPDATE_INVENTORY_COUNTS_SUCCESS:
      return {
        ...state,
        update_inventory_counts_reponse: action.payload,
        error: null,
      };

    case InventoryTypes.UPDATE_INVENTORY_COUNTS_FAILURE:
      return {
        ...state,
        error: null,
      };

    case InventoryTypes.ADD_ITEM_TO_INVENTORY_SUCCESS:
      return {
        ...state,
        add_item_to_inventory_response: action.payload,
        error: null,
      };

    case InventoryTypes.ADD_ITEM_TO_INVENTORY_FAILURE:
      return {
        ...state,
        error: null,
      };

    case InventoryTypes.CREATE_INVENTORY_FOR_ORDER_ON_DATE_SUCCESS:
      return {
        ...state,
        create_inventory_for_order_on_date_response: action.payload,
        error: null,
      };

    case InventoryTypes.CREATE_INVENTORY_FOR_ORDER_ON_DATE_FAILURE:
      return {
        ...state,
        error: null,
      };

    case InventoryTypes.SET_SELECTED_RCID:
      return {
        ...state,
        selected_rcid: action.payload,
      };

    default:
      return state;
  }
};
export default inventoryReducer;

import ReportTypes from './reports.types';

export const setReportTypeStart = (type) => ({
  type: ReportTypes.SET_REPORT_TYPE,
  payload: type,
});

export const getReportFilterOptionsStart = () => ({
  type: ReportTypes.GET_REPORT_FILTER_OPTIONS_START,
  payload: null,
});

export const getReportFilterOptionsSuccess = (options) => ({
  type: ReportTypes.GET_REPORT_FILTER_OPTIONS_SUCCESS,
  payload: options,
});

export const getReportFilterOptionsFailure = (error) => ({
  type: ReportTypes.GET_REPORT_FILTER_OPTIONS_FAILURE,
  payload: error,
});

// SELECTED TRANSACTIONS
export const getReportTransactionsStart = (options) => ({
  type: ReportTypes.GET_REPORT_TRANSACTIONS_START,
  payload: options,
});

export const getReportTransactionsSuccess = (transactions) => ({
  type: ReportTypes.GET_REPORT_TRANSACTIONS_SUCCESS,
  payload: transactions,
});

export const getReportTransactionsFailure = (error) => ({
  type: ReportTypes.GET_REPORT_TRANSACTIONS_FAILURE,
  payload: error,
});

// SELECTION TREE
export const getReportSelectionTreeStart = (options) => ({
  type: ReportTypes.GET_REPORT_SELECTION_TREE_START,
  payload: options,
});

export const getReportSelectionTreeSuccess = (transactions) => ({
  type: ReportTypes.GET_REPORT_SELECTION_TREE_SUCCESS,
  payload: transactions,
});

export const getReportSelectionTreeFailure = (error) => ({
  type: ReportTypes.GET_REPORT_SELECTION_TREE_FAILURE,
  payload: error,
});

export const getReportSelectionTreeUpdateStart = () => ({
  type: ReportTypes.GET_REPORT_SELECTION_TREE_START,
  payload: null,
});

export const getReportSelectionTreeUpdateSuccess = (transactions) => ({
  type: ReportTypes.GET_REPORT_SELECTION_TREE_SUCCESS,
  payload: transactions,
});

export const getReportSelectionTreeUpdateFailure = (error) => ({
  type: ReportTypes.GET_REPORT_SELECTION_TREE_FAILURE,
  payload: error,
});

// GET BUDGET AND CENSUS
export const getMonthlyBudgetCensusUsedForCustomerStart = (parameters) => ({
  type: ReportTypes.GET_MONTHLY_BUDGET_CENSUS_USED_FOR_CUSTOMER_START,
  payload: parameters,
});

export const getMonthlyBudgetCensusUsedForCustomerSuccess = (dataset) => ({
  type: ReportTypes.GET_MONTHLY_BUDGET_CENSUS_USED_FOR_CUSTOMER_SUCCESS,
  payload: dataset,
});

export const getMonthlyBudgetCensusUsedForCustomerFailure = (error) => ({
  type: ReportTypes.GET_MONTHLY_BUDGET_CENSUS_USED_FOR_CUSTOMER_FAILURE,
  payload: error,
});

export const getReportSnapshotStart = (parameters) => ({
  type: ReportTypes.GET_REPORT_SNAPSHOT_START,
  payload: parameters,
});

export const getReportSnapshotSuccess = (dataset) => ({
  type: ReportTypes.GET_REPORT_SNAPSHOT_SUCCESS,
  payload: dataset,
});

export const getReportSnapshotFailure = (error) => ({
  type: ReportTypes.GET_REPORT_SNAPSHOT_FAILURE,
  payload: error,
});

export const updateMonthlyBudgetCensusUsedForCustomerStart = (parameters) => ({
  type: ReportTypes.UPDATE_MONTHLY_BUDGET_CENSUS_USED_FOR_CUSTOMER_START,
  payload: parameters,
});

export const updateMonthlyBudgetCensusUsedForCustomerSuccess = (dataset) => ({
  type: ReportTypes.UPDATE_MONTHLY_BUDGET_CENSUS_USED_FOR_CUSTOMER_SUCCESS,
  payload: dataset,
});

export const updateMonthlyBudgetCensusUsedForCustomerFailure = (error) => ({
  type: ReportTypes.UPDATE_MONTHLY_BUDGET_CENSUS_USED_FOR_CUSTOMER_FAILURE,
  payload: error,
});

export const emailTransactionsStart = (parameters) => ({
  type: ReportTypes.EMAIL_DOCUMENT_START,
  payload: parameters,
});

export const emailTransactionsSuccess = (dataset) => ({
  type: ReportTypes.EMAIL_DOCUMENT_SUCCESS,
  payload: dataset,
});

export const emailTransactionsFailure = (error) => ({
  type: ReportTypes.EMAIL_DOCUMENT_FAILURE,
  payload: error,
});

export const saveOptionsHistory = (report_type, options) => ({
  type: ReportTypes.SAVE_OPTIONS_HISTORY,
  report_type,
  payload: options,
});

export const setPageID = (data) => ({
  type: ReportTypes.SET_PAGE_ID,
  payload: data,
});

export const setRcID = (data) => ({
  type: ReportTypes.SET_RCID,
  payload: data,
});

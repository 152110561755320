import CountLinenCartsAndClosetsTypes from './count-linen-carts-and-closets.types';

const INITIAL_STATE = {
  order_schedule: [],
  orders_not_scheduled: [],
  delivery_item_rights: [],
  delivery_for_delivery_id: [],
  delivery_for_delivery_id_previous: [],
  dashboard_gauge_info: [],
  update_delivery_counts_response: null,
  create_delivery_for_order_on_date_response: null,
  add_items_to_delivery_response: null,
  items_not_in_delivery: [],
  deliveries_for_global_adjust_on_date: [],
  update_deliveries_for_global_adjust_on_date_response: null,
  get_active_handheld_numbers: [],
  send_handheld_info_response: null,
  update_delivery_census_response: null,
  linen_room_delivery_for_customer_on_date: [],
  linen_room_pick_form: [],
  pick_form: [],
  pick_form_type: null,
  pick_form_interval: null,
  selected_rcid: null,
  selected_delvid: null,
  error: null,
};

const countLinenCartsAndClosetsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_ORDER_SCHEDULE_SUCCESS:
      return {
        ...state,
        order_schedule: action.payload,
        order_schedule_previous: JSON.parse(JSON.stringify(action.payload)),
        error: null,
      };

    case CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_ORDER_SCHEDULE_FAILURE:
      return {
        ...state,
        order_schedule: [],
        delivery_for_delivery_id: [],
        error: action.payload,
      };

    case CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_ORDERS_NOT_SCHEDULED_SUCCESS:
      return {
        ...state,
        orders_not_scheduled: action.payload,
        error: null,
      };

    case CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_ORDERS_NOT_SCHEDULED_FAILURE:
      return {
        ...state,
        orders_not_scheduled: [],
        error: action.payload,
      };

    case CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERY_ITEM_RIGHTS_SUCCESS:
      return {
        ...state,
        delivery_item_rights: action.payload,
        error: null,
      };

    case CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERY_ITEM_RIGHTS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERY_FOR_DELIVERY_ID_SUCCESS:
      return {
        ...state,
        delivery_for_delivery_id: action.payload,
        delivery_for_delivery_id_previous: JSON.parse(JSON.stringify(action.payload)),
        error: null,
      };

    case CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERY_FOR_DELIVERY_ID_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_DASHBOARD_GAUGE_INFO_SUCCESS:
      return {
        ...state,
        dashboard_gauge_info: action.payload,
        error: null,
      };

    case CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_DASHBOARD_GAUGE_INFO_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_UPDATE_DELIVERY_COUNTS_SUCCESS:
      return {
        ...state,
        update_delivery_counts_response: action.payload,
        error: null,
      };

    case CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_UPDATE_DELIVERY_COUNTS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_GET_ITEMS_NOT_IN_DELIVERY_SUCCESS:
      return {
        ...state,
        items_not_in_delivery: action.payload,
        error: null,
      };

    case CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_GET_ITEMS_NOT_IN_DELIVERY_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case CountLinenCartsAndClosetsTypes.COUNT_LINEN_CARTS_AND_CLOSETS_ADD_ITEMS_TO_DELIVERY_SUCCESS:
      return {
        ...state,
        add_items_to_delivery_response: action.payload,
        error: null,
      };

    case CountLinenCartsAndClosetsTypes.COUNT_LINEN_CARTS_AND_CLOSETS_ADD_ITEMS_TO_DELIVERY_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case CountLinenCartsAndClosetsTypes.CREATE_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERY_FOR_ORDER_ON_DATE_SUCCESS:
      return {
        ...state,
        create_delivery_for_order_on_date_response: action.payload,
        error: null,
      };

    case CountLinenCartsAndClosetsTypes.CREATE_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERY_FOR_ORDER_ON_DATE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERIES_FOR_GLOBAL_ADJUSTMENT_ON_DATE_SUCCESS:
      return {
        ...state,
        deliveries_for_global_adjust_on_date: action.payload,
        error: null,
      };

    case CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERIES_FOR_GLOBAL_ADJUSTMENT_ON_DATE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case CountLinenCartsAndClosetsTypes.UPDATE_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERIES_FOR_GLOBAL_ADJUSTMENT_ON_DATE_SUCCESS:
      return {
        ...state,
        update_deliveries_for_global_adjust_on_date_response: action.payload,
        error: null,
      };

    case CountLinenCartsAndClosetsTypes.UPDATE_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERIES_FOR_GLOBAL_ADJUSTMENT_ON_DATE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_ACTIVE_HANDHELD_NUMBERS_SUCCESS:
      return {
        ...state,
        get_active_handheld_numbers: action.payload,
        error: null,
      };

    case CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_ACTIVE_HANDHELD_NUMBERS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case CountLinenCartsAndClosetsTypes.SEND_COUNT_LINEN_CARTS_AND_CLOSETS_HANDHELD_INFO_SUCCESS:
      return {
        ...state,
        send_handheld_info_response: action.payload,
        error: null,
      };

    case CountLinenCartsAndClosetsTypes.SEND_COUNT_LINEN_CARTS_AND_CLOSETS_HANDHELD_INFO_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case CountLinenCartsAndClosetsTypes.UPDATE_DELIVERY_CENSUS_SUCCESS:
      return {
        ...state,
        update_delivery_census_response: action.payload,
        error: null,
      };

    case CountLinenCartsAndClosetsTypes.UPDATE_DELIVERY_CENSUS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case CountLinenCartsAndClosetsTypes.PICK_FORM_SUCCESS:
      return {
        ...state,
        pick_form: action.payload,
        error: null,
      };

    case CountLinenCartsAndClosetsTypes.PICK_FORM_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case CountLinenCartsAndClosetsTypes.CREATE_COUNT_LINEN_LINEN_ROOM_DELIVERY_FOR_CUSTOMER_ON_DATE_SUCCESS:
      return {
        ...state,
        linen_room_delivery_for_customer_on_date: action.payload,
        error: null,
      };

    case CountLinenCartsAndClosetsTypes.CREATE_COUNT_LINEN_LINEN_ROOM_DELIVERY_FOR_CUSTOMER_ON_DATE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case CountLinenCartsAndClosetsTypes.LINEN_ROOM_PICK_FORM_SUCCESS:
      return {
        ...state,
        linen_room_pick_form: action.payload,
        error: null,
      };

    case CountLinenCartsAndClosetsTypes.LINEN_ROOM_PICK_FORM_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case CountLinenCartsAndClosetsTypes.SET_PICK_FORM_TYPE:
      return {
        ...state,
        pick_form_type: action.payload,
      };

    case CountLinenCartsAndClosetsTypes.SET_PICK_FORM_INTERVAL:
      return {
        ...state,
        pick_form_interval: action.payload,
      };

    case CountLinenCartsAndClosetsTypes.SET_SELECTED_RCID:
      return {
        ...state,
        selected_rcid: action.payload,
      };

    case CountLinenCartsAndClosetsTypes.SET_SELECTED_DELVID:
      return {
        ...state,
        selected_delvid: action.payload,
      };

    default:
      return state;
  }
};
export default countLinenCartsAndClosetsReducer;

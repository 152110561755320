import { useState } from 'react';

import InventoryResultBox from '@Components/inventory/inventory-result-box.component';
import SideBarControl from '@Components/inventory/sidebar-controls.component';
import { colors, ContentBox, Panel } from '@ThemeMain';

const InventoryPanel = ({
  currentCustomer,
  pageID,
  pageTitle,
  type,
  updateInventoryCounts,
  setOnInitialize,
  createDelivery,
  getOrderSchedule,
  userPrivileges,
}) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [hasChanges, setHasChanges] = useState(false);

  return (
    <>
      {currentCustomer ? (
        <Panel direction="row" gap="small" height="large">
          <SideBarControl
            pageID={pageID}
            type={type}
            setOnInitialize={(data) => setOnInitialize(data)}
            updateInventoryCounts={(data) => updateInventoryCounts(data)}
            createDelivery={(data) => createDelivery(data)}
            getOrderSchedule={(data) => getOrderSchedule(data)}
            hasChanges={hasChanges}
            setHasChanges={(data) => setHasChanges(data)}
            setCurrentDate={setCurrentDate}
          />
          <ContentBox background={colors.innerBg} style={{ minWidth: '900px' }} fill>
            <InventoryResultBox
              userPrivileges={userPrivileges}
              pageTitle={pageTitle}
              pageID={pageID}
              type={type}
              currentDate={currentDate}
              hasChanges={hasChanges}
              setHasChanges={(data) => setHasChanges(data)}
              updateInventoryCounts={(data) => updateInventoryCounts(data)}
            />
          </ContentBox>
        </Panel>
      ) : null}
    </>
  );
};

export default InventoryPanel;

import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import api from '@API';

import { selectAdvancedCurrentUsers } from '../setup-advanced-users/setup-advanced-users.selectors';
import {
  addCustomerToAddQueueFailure,
  addCustomerToAddQueueSuccess,
  addCustomerToDeleteQueueFailure,
  addCustomerToDeleteQueueSuccess,
  deleteCustomerSelectedFailure,
  deleteCustomerSelectedSuccess,
  getCustomerSelectedFailure,
  getCustomerSelectedStart,
  getCustomerSelectedSuccess,
  saveCustomerSelectedFailure,
  saveCustomerSelectedSuccess,
} from './setup-users-customer-selected-in-user.actions';
import SetupUsersCustomerSelectedActionTypes from './setup-users-customer-selected-in-user.types';

const customerSelectedApi = (params) => {
  return api.post('security/get-customers-selected-in-user', params);
};

export function* getCustomerSelected(action) {
  try {
    const currentUserInfo = yield select(selectAdvancedCurrentUsers);
    if (currentUserInfo) {
      const params = { iUserID: currentUserInfo.RcID };
      const { data } = yield call(customerSelectedApi, params);
      yield put(getCustomerSelectedSuccess(data.data));
    }
  } catch (error) {
    yield put(getCustomerSelectedFailure(error));
  }
}

const saveCustomerSelectedApi = (params) => {
  return api.post('security/add-customer-to-user', params);
};

export function* saveCustomerSelected(action) {
  try {
    const currentUserInfo = yield select(selectAdvancedCurrentUsers);
    if (currentUserInfo) {
      const params = { iUserID: currentUserInfo.RcID };
      params.customerInfo = action.payload;
      const { data } = yield call(saveCustomerSelectedApi, params);
      yield put(saveCustomerSelectedSuccess(data.data));
      yield put(getCustomerSelectedStart());
    }
  } catch (error) {
    yield put(saveCustomerSelectedFailure(error));
  }
}

const deleteCustomerSelectedApi = (params) => {
  return api.post('security/update-customers-selected-and-not-selected-in-user', params);
};

export function* deleteCustomerSelected(action) {
  try {
    const params = {};
    params.customer = action.payload;
    const { data } = yield call(deleteCustomerSelectedApi, params);
    yield put(deleteCustomerSelectedSuccess(data.data));
    yield put(getCustomerSelectedStart());
  } catch (error) {
    yield put(deleteCustomerSelectedFailure(error));
  }
}

export function* addCustomerToDeleteQueue(action) {
  try {
    yield put(addCustomerToDeleteQueueSuccess(action.payload));
  } catch (error) {
    yield put(addCustomerToDeleteQueueFailure(error));
  }
}

export function* addCustomerToAddQueue(action) {
  try {
    yield put(addCustomerToAddQueueSuccess(action.payload));
  } catch (error) {
    yield put(addCustomerToAddQueueFailure(error));
  }
}

export function* onGetCustomerSelectedStart() {
  yield takeLatest(
    SetupUsersCustomerSelectedActionTypes.GET_CUSTOMER_SELECTED_START,
    getCustomerSelected,
  );
}

export function* onSaveCustomerSelectedStart() {
  yield takeLatest(
    SetupUsersCustomerSelectedActionTypes.SAVE_CUSTOMER_SELECTED_START,
    saveCustomerSelected,
  );
}

export function* onDeleteCustomerSelectedStart() {
  yield takeLatest(
    SetupUsersCustomerSelectedActionTypes.DELETE_CUSTOMER_SELECTED_START,
    deleteCustomerSelected,
  );
}

export function* onAddCustomerToDeleteQueueStart() {
  yield takeLatest(
    SetupUsersCustomerSelectedActionTypes.ADD_CUSTOMER_TO_DELETE_QUEUE_START,
    addCustomerToDeleteQueue,
  );
}

export function* onAddCustomerToAddQueueStart() {
  yield takeLatest(
    SetupUsersCustomerSelectedActionTypes.ADD_CUSTOMER_TO_ADD_QUEUE_START,
    addCustomerToAddQueue,
  );
}

export function* advancedUserCustomerSelectedCustomer() {
  yield all([
    call(onGetCustomerSelectedStart),
    call(onSaveCustomerSelectedStart),
    call(onDeleteCustomerSelectedStart),
    call(onAddCustomerToDeleteQueueStart),
    call(onAddCustomerToAddQueueStart),
  ]);
}

import { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import MessageModal from '@Components/modal/message-modal.component';
import { clearMessageStart, setCustomer } from '@State/user/user.actions';
import {
  selectCurrentCustomer,
  selectCurrentUser,
  selectCustomers,
  selectLoginMessages,
} from '@State/user/user.selectors';
import { cssRules } from '@ThemeMain';

const CustomersList = styled.ul`
  overflow: auto;
  margin: 0;
  padding: 0;
  width: 100%;
  min-width: 20em;
  max-height: 600px;
}
`;

const ListItem = styled.li`
  font-weight: ${(props) => props.fontWeight || 'normal'};
  list-style: none;
  line-height: 2em;
  cursor: pointer;
  ${cssRules.ellipsis}
`;

let index = 0;

function LoginMessage(props) {
  const {
    show,
    setShow,
    onClose,
    customers,
    setCustomer,
    currentCustomer,
    setShowSingleCustomer,
    showSingleCustomer,
    loginMessages,
    clearMessageStart,
  } = props;
  const [customerLocal, setCustomerLocal] = useState(currentCustomer);

  let modal = null;
  if (show && customers) {
    // if (customers.length === 1) {
    //   if (showSingleCustomer === 1) {
    //     setShow(false);
    //     setShowSingleCustomer(showSingleCustomer + 1);
    //     setCustomer(customers[0]);
    //     return null;
    //   }
    // }
    modal = (
      <MessageModal
        show={show}
        setShow={setShow}
        confirmLabel="Next"
        cancelLabel="Previous"
        showButtons={loginMessages.length > 1}
        title="Login Messages"
        onClose={() => {
          clearMessageStart({});
        }}
        onCancel={() => {
          if (index > 0) index--;
          if (index === 0) {
            document.getElementById('messageId').innerHTML = loginMessages[index].message;
            document.getElementById('cancelButton').disabled = true;
            document.getElementById('cancelButton').style.opacity = '0.3';
          }
          if (index < loginMessages.length - 1) {
            document.getElementById('submitButton').disabled = false;
            document.getElementById('submitButton').style.opacity = '1.0';
          }
          return false;
        }}
        onConfirm={() => {
          if (index < loginMessages.length - 1) index++;
          if (index > 0) {
            document.getElementById('cancelButton').disabled = false;
            document.getElementById('cancelButton').style.opacity = '1.0';
          }
          if (index === loginMessages.length - 1) {
            document.getElementById('messageId').innerHTML = loginMessages[index].message;
            document.getElementById('submitButton').disabled = true;
            document.getElementById('submitButton').style.opacity = '0.3';
          }
          if (index === loginMessages.length - 1) {
            document.getElementById('exitButton').style.display = 'block';
          }
          return false;
        }}
      >
        <div
          id="messageId"
          dangerouslySetInnerHTML={{ __html: loginMessages[0].message }}
          style={{ textAlign: 'center' }}
        />
      </MessageModal>
    );
  }
  return modal;
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  customers: selectCustomers,
  currentCustomer: selectCurrentCustomer,
  loginMessages: selectLoginMessages,
});

const mapDispatchToProps = (dispatch) => ({
  setCustomer: (customer) => dispatch(setCustomer(customer)),
  clearMessageStart: () => dispatch(clearMessageStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginMessage));

import { Box } from 'grommet';

import { colors } from '@ThemeMain';

const SideBar = (props) => {
  return (
    <Box
      background={colors.innerBg}
      direction="column"
      gap="medium"
      style={{ width: '380px', minWidth: '380px' }}
      {...props}
    >
      {props.children}
    </Box>
  );
};

export default SideBar;

import SetupUsersOrderNotSelectedActionTypes from './setup-users-order-not-selected-in-user.types';

const INITIAL_STATE = {
  order_not_selected: null,
};

const setupUserOrderNotSelectedReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SetupUsersOrderNotSelectedActionTypes.GET_ORDER_NOT_SELECTED_SUCCESS:
      return {
        ...state,
        order_not_selected: action.payload,
        error: null,
      };

    case SetupUsersOrderNotSelectedActionTypes.GET_ORDER_NOT_SELECTED_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case SetupUsersOrderNotSelectedActionTypes.UPDATE_ORDER_NOT_SELECTED:
      const orderRow = {
        RcID: action.payload.RcID,
        Name: action.payload.ObjDesc,
        ObjDesc: action.payload.ObjDesc,
        ObjRcID: action.payload.ObjRcID,
        UserRcID: action.payload.UserRcID,
        RowOrder: state.order_not_selected.length + 1,
      };
      const index = state.order_not_selected.findIndex(
        (orderNotSelected) => orderNotSelected.RcID === orderRow.RcID,
      );
      if (index === -1) state.order_not_selected.unshift(orderRow);
      else state.order_not_selected.splice(index, 1);

      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export default setupUserOrderNotSelectedReducer;

/* eslint-disable no-underscore-dangle */
import {
  Box,
  Button,
  Heading,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Text,
  TextInput,
} from 'grommet';
import { CaretDown, CaretNext } from 'grommet-icons';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import Modal from '@Components/modal/modal.component';
import SearchSortBar from '@Components/search-sort-bar/search-sort-bar.component';
import { copyObject, filterInput, hightlightCell, INPUT_TYPE } from '@Helpers/common.helper';
import { download, openData } from '@Helpers/download.helper';
import { Messages } from '@Pages/count-linen/count-linen-config';
import { ResultsDataTable } from '@Pages/count-linen/count-linen.styles';
import {
  ReportDataTableConfig,
  ReportHelpPages,
  ReportNoBusinessLogicFilters,
  ReportPageID,
} from '@Pages/reports/reports-config';
import { emailTransactionsSuccess, setRcID } from '@State/reports/reports.actions';
import { selectRcID } from '@State/reports/reports.selectors';
import {
  BrandButton,
  colors,
  cssRules,
  measures,
  Panel,
  ScrollableBox,
  ThemeDataTable,
} from '@ThemeMain';

import ReportEmailDocumentModal from './reports-email-documents-modal.component';
import ReportsTreeTable from './reports-tree-table.component';

const ReportsTable = styled(ThemeDataTable)`
  th {
    ${cssRules.ellipsis}
  }

  &:not(.firstHeaderColumnAuto) th:first-child {
    width: 300px;
  }

  th:not(:first-child) {
    width: 150px;
    text-align: center;
    padding-right: 14px;
  }
`;

const ParentTable = styled(Table)`
  max-height: 571px;
  th {
    ${cssRules.ellipsis}
  }

  thead {
    tr {
      th {
        position: sticky;
        top: 0;
        background: #fff;
        z-index: 9;
        box-shadow: 1px 1px 0 #6c82c9;
      }
    }
  }

  tr {
    display: table-row;
  }

  th:first-child {
    width: 300px;
  }
`;

const NumberCell = styled(TableCell)`
  text-align: right;
  padding-right: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
`;

const ReportsResultBox = (props) => {
  const {
    reportsTableData,
    selectionTree,
    monthlyBudgetCensusData,
    userPrivileges,
    reportBase64Data,
    updateMonthlyBudgetCensus,
    reportsTitle,
    emailTransactions,
    getCurrentTreeSelection,
    handleGetReportSnapshot,
    shouldResetTree,
    setShouldResetTree,
    setSelectedTreeIds,
    setDisplayedRows,
    reportsLabel,
    setReportsLabel,
    updateSelection,
    selectedTreeIds,
    displayedRows,
    ReportButtonFunctionality,
    ReportSelectionTreePages,
    type,
    pageID,
    emailDocumentResponse,
    hasChanges,
    setHasChanges,
    emailTransactionsReset,
    reportOptions,
  } = props;

  const [showReportEmailDocumentModal, setShowReportEmailDocumentModal] = useState(false);
  const [emailType, setEmailType] = useState('');
  const deepCopy = monthlyBudgetCensusData
    ? monthlyBudgetCensusData.map((item) => ({ ...item }))
    : [];
  const [editableDataPlaceholder, setEditableDataPlaceholder] = useState(deepCopy);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [emailDocumentText, setEmailDocumentText] = useState('');
  const [selectedID, setSelectedID] = useState(0);
  const [searchKey, setSearchKey] = useState({ id: '', name: '' });
  const [searchValue, setSearchValue] = useState('');
  const [filterKeys, setFilterKeys] = useState([]);
  const [openTables, setOpenTables] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [treeTableData, setTreeTableData] = useState([]);
  const [selectedNode, setSelectedNode] = React.useState(0);
  const [filteredNodes, setFilteredNodes] = React.useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const noneHeaders = [
    'RcID',
    'ObjtRcID',
    'ObjtCode',
    'Selection',
    'MinDate',
    'MaxDate',
    'NumDays',
    'Census',
    'ColmSort',
    'BudCalc',
    'DeltaCalc',
    'CostAcctRcID',
  ];
  useEffect(() => {
    if (!evaluateChanges()) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  });

  useEffect(() => {
    setHasError(false);
    setErrorMessage(null);
  }, [pageID]);

  useEffect(() => {
    const key = reportsTableData && reportsTableData.header ? 'header' : 'headers';
    setFilterSelection([]);

    if (reportsTableData && reportsTableData.status && reportsTableData.status === 'failed') {
      setHasError(true);
      setErrorMessage(reportsTableData.data._text);
      setTableData({ columns: [], data: [] });
    } else if (reportsTableData && reportsTableData[key]) {
      setFilterSelection(reportsTableData[key]);
      setTableData(copyObject(reportsTableData));

      setHasError(false);
      setErrorMessage(null);
    }
  }, [reportsTableData]);

  useEffect(() => {
    selectionTree && selectionTree.header && setFilterSelection(selectionTree.header);
    setTreeTableData(selectionTree);
    filter();

    setHasError(false);
    setErrorMessage(null);
  }, [selectionTree]);

  useEffect(() => {
    // Use a deep copy to avoid editing the state directly
    if (monthlyBudgetCensusData) {
      const copy = monthlyBudgetCensusData.map((item) => ({ ...item }));
      setEditableDataPlaceholder(copy);
    }
  }, [monthlyBudgetCensusData]);

  useEffect(() => {
    if (emailDocumentResponse) {
      modifyModalText();
      toggleConfModal();
      emailTransactionsReset();
    }
  }, [emailDocumentResponse]);

  const setFilterSelection = (data) => {
    const values = [];
    Object.keys(data).forEach((item) => {
      !noneHeaders.includes(item) && values.push({ id: item, name: item });
    });

    values.length && setSearchKey(values[0]);
    setFilterKeys(values);
  };

  const updateData = (value, datum) => {
    const dataParameterName = type === 'budget' ? 'BdgtQnty' : 'CensQnty';
    const updated = [...editableDataPlaceholder];
    updated[monthlyBudgetCensusKeyMap[datum[monthlyBudgetCensusPrimaryKey]]][dataParameterName] =
      value;
    setEditableDataPlaceholder(updated);
  };

  const isPageEditable =
    userPrivileges &&
    ((userPrivileges.canModifySetBudget && type === 'budget') ||
      (userPrivileges.canModifySetCensus && type === 'census'));

  const monthlyBudgetCensusPrimaryKey = type === 'budget' ? 'CostAcctRcID' : 'CensAcctRcID';
  const monthlyBudgetCensusKeyMap = {};
  editableDataPlaceholder.forEach((datum, index) => {
    monthlyBudgetCensusKeyMap[datum[monthlyBudgetCensusPrimaryKey]] = index;
  });
  const monthlyBudgetCensusColumns = [
    {
      property: 'DeptDesc',
      header: <Text>Department Name</Text>,
      align: 'center',
      search: true,
      sortable: true,
      render: (datum) => {
        return (
          <Text
            className={selectedID === datum[monthlyBudgetCensusPrimaryKey] ? 'white-label' : null}
            onClick={() => setSelectedID(datum[monthlyBudgetCensusPrimaryKey])}
          >
            {datum.DeptDesc}
          </Text>
        );
      },
    },
    {
      property: type === 'budget' ? 'BdgtQnty' : 'CensQnty',
      header: type && <Text>{`${type.charAt(0).toUpperCase() + type.slice(1)} Qty`}</Text>,
      align: 'center',
      search: true,
      sortable: true,
      render: (datum) => {
        if (isPageEditable) {
          return (
            <TextInput
              key={datum.$attributes ? datum.$attributes.rowOrder : 'DeptDesc'}
              type="text"
              step={type === 'budget' ? 0.01 : 1}
              style={{ height: '30px', padding: '0 10px' }}
              placeholder="Edit values here"
              className={selectedID === datum[monthlyBudgetCensusPrimaryKey] ? 'white-label' : null}
              value={datum.BdgtQnty || datum.CensQnty}
              onClick={(event) => hightlightCell(event)}
              onFocus={(event) => {
                hightlightCell(event);
                setSelectedID(datum[monthlyBudgetCensusPrimaryKey]);
              }}
              onKeyDown={(event) => filterInput(event, INPUT_TYPE.float, ['-'])}
              onBlur={(event) => {
                event.target.closest('td').classList.remove('cell-focus');
                updateData(parseFloat(event.target.value).toString(), datum);
              }}
              onChange={(event) => updateData(event.target.value, datum)}
            />
          );
        }
        return (
          <Text
            size="small"
            key={datum.$attributes ? datum.$attributes.rowOrder : 'DeptDesc'}
            className={selectedID === datum[monthlyBudgetCensusPrimaryKey] ? 'white-label' : null}
            onClick={() => setSelectedID(datum[monthlyBudgetCensusPrimaryKey])}
          >
            {datum.BdgtQnty || datum.CensQnty}
          </Text>
        );
      },
    },
  ];

  const getTableParentHeaders = (columnFormatInfo, schemaData) => {
    const headers = [];
    if (columnFormatInfo) {
      Object.entries(columnFormatInfo).forEach(([key, item]) => {
        if (!(item.format && item.format.isHidden)) {
          let title = item.header;
          if (
            schemaData &&
            schemaData.ParentColumnFormatTable &&
            schemaData.ParentColumnFormatTable[key]
          ) {
            title = schemaData.ParentColumnFormatTable[key];
          }

          const cell =
            title === 'Description' ? (
              <TableCell key={`${title}tablecell`} width="300px" scope="col">
                {title}
              </TableCell>
            ) : (
              <NumberCell key={`${title}numbercell`} width="130px" scope="col">
                {title}
              </NumberCell>
            );
          headers.push(cell);
        }
      });
    }

    return headers;
  };

  const toggle = (id) => {
    const element = document.getElementById(`row-${id}`);
    const caretDown = document.getElementById(`cdown-${id}`);
    const caretUp = document.getElementById(`cnext-${id}`);

    if (element !== null) {
      if (element.style.display === 'none') {
        element.style.display = 'table-row';
        caretDown.style.display = 'inline-block';
        caretUp.style.display = 'none';
        !openTables.includes(id) && openTables.push(id);
      } else {
        element.style.display = 'none';
        caretUp.style.display = 'inline-block';
        caretDown.style.display = 'none';
        openTables.includes(id) && openTables.splice(openTables.indexOf(id), 1);
      }
    }
  };

  const getParentColumns = (item, headerDefinitions) => {
    const cols = [];

    Object.entries(item).forEach(([key, value]) => {
      if (key !== 'Description' && !(headerDefinitions && headerDefinitions[key].format.isHidden)) {
        cols.push(
          <NumberCell key={`${key}numbercell`} scope="row">
            {formatValue(headerDefinitions[key].format, value)}
          </NumberCell>,
        );
      }
    });

    return cols;
  };

  const formatValue = (format, value) => {
    if (!isNaN(value) && format && format.formatMask) {
      let formattedValue = parseFloat(value).toLocaleString(undefined, format.formatMask || {});
      if (format.formatMask.unitToUse) {
        formattedValue += format.formatMask.unitToUse;
      }

      return formattedValue;
    }

    return value;
  };

  const getTableData = (backendData) => {
    const row = [];
    if (typeof backendData.parentData !== 'undefined') {
      const { dataTable, headers, schemaData } = backendData;
      const parentTable = backendData.parentData;
      let childData = [];

      parentTable.forEach((item) => {
        row.push(
          <TableRow
            key={
              item.ObjtRcID +
              (item.ObjtCode || item.ObjtRcId) +
              (item.minDate || item.ObjtRcID) +
              (item.maxDate || item.ObjtRcID)
            }
            onClick={() => {
              setReportsLabel(item.Selection);
            }}
          >
            <TableCell scope="row">
              <Button type="button" onClick={() => toggle(item.ObjtRcID)}>
                <CaretNext id={`cnext-${item.ObjtRcID}`} size="small" />
                <CaretDown id={`cdown-${item.ObjtRcID}`} size="small" style={{ display: 'none' }} />
              </Button>
              {item.Description}
            </TableCell>
            {getParentColumns(item, headers)}
          </TableRow>,
        );

        childData = dataTable.childData[`pId-${item.ObjtRcID}`];

        if (childData) {
          const dataTableColumns = Object.values(dataTable.columns)
            .filter((elem) => !elem.format.isHidden)
            .map((elem) => {
              const columnData = { ...elem };
              let textAlign = 'center';
              if (elem.format.textAlign) {
                switch (elem.format.textAlign) {
                  case 'left':
                    textAlign = 'start';
                    break;
                  case 'right':
                    textAlign = 'center';
                    break;
                  default:
                    break;
                }
              }
              if (schemaData.ParentTable && schemaData.ParentTable[elem.property]) {
                columnData.header = schemaData.ParentTable[elem.property];
              }
              columnData.header = (
                <Text size="medium" alignSelf="stretch">
                  {elem.header}
                </Text>
              );
              columnData.render = (datum) => {
                return (
                  <Text size="small" alignSelf="stretch" textAlign={textAlign}>
                    {formatValue(elem.format, datum[elem.property])}
                  </Text>
                );
              };
              return columnData;
            });

          row.push(
            <TableRow
              key={`row-${item.ObjtRcID}`}
              id={`row-${item.ObjtRcID}`}
              style={{ display: 'none' }}
            >
              <TableCell colSpan="11">
                <ScrollableBox>
                  <ReportsTable
                    columns={dataTableColumns}
                    style={{ maxHeight: '450px' }}
                    border={{
                      header: true,
                      color: colors.lightGreen,
                      body: { side: 'right' },
                      size: 'xsmall',
                    }}
                    data={childData}
                    step={childData ? childData.length : 0}
                    bodySize={measures.dataTableMidBodyHeight}
                    sortable
                  />
                </ScrollableBox>
              </TableCell>
            </TableRow>,
          );
        }
      });
    }

    return row;
  };

  // TODO: better in the header?
  const helpButtonClick = () => {
    // TODO: [LH-285] env variable the first part
    const win = window.open(
      `https://linenhelper-help-documents.s3.amazonaws.com${ReportHelpPages[pageID]}`,
      '_blank',
    );
    win.focus();
  };

  const viewSpreadsheet = (event) => {
    download({
      name: 'report',
      type: 'xls',
      base64Data: reportBase64Data[1].ReptData._text
        ? reportBase64Data[1].ReptData._text
        : reportBase64Data[1].ReptData,
    });
  };

  const viewDocument = (event) => {
    openData({
      type: 'pdf',
      base64Data: reportBase64Data[0].ReptData._text
        ? reportBase64Data[0].ReptData._text
        : reportBase64Data[0].ReptData,
    });
  };

  const toggleModal = (documentType, event) => {
    if (documentType) {
      setEmailType(documentType);
    }
    setShowReportEmailDocumentModal(!showReportEmailDocumentModal);
  };

  const toggleConfModal = () => {
    setShowConfirmationModal(!showConfirmationModal);
  };

  const modifyModalText = () => {
    emailDocumentResponse.data &&
    emailDocumentResponse.data.hasOwnProperty('isValid') &&
    emailDocumentResponse.data.isValid._text === 'true'
      ? setEmailDocumentText('has been sent.')
      : setEmailDocumentText('has not been sent.');
  };

  const ControlsBox = (props) => (
    <Box
      direction="row"
      gap="xsmall"
      pad="xsmall"
      background={colors.innerBg}
      border={{
        color: colors.clearDarkBlue,
        side: 'bottom',
        size: 'medium',
      }}
      {...props}
    >
      {props.children}
    </Box>
  );

  const evaluateChanges = () => {
    if (_.isEqual(editableDataPlaceholder, monthlyBudgetCensusData)) {
      setHasChanges(false);
      return true;
    }
    if (!hasChanges) {
      setHasChanges(true);
    }

    return false;
  };

  const filter = (type) => {
    const query = searchValue.toLowerCase();
    let result = [];
    let filteredData = null;
    if (
      openTables.length &&
      reportsTableData &&
      searchKey.id &&
      ReportSelectionTreePages[pageID] === undefined
    ) {
      const prevChildData = copyObject(reportsTableData.dataTable.childData);
      const dataTable = copyObject(tableData.dataTable);
      openTables.forEach((id) => {
        result = prevChildData[`pId-${id}`].filter((item) =>
          item[searchKey.id].toLowerCase().includes(query),
        );
        dataTable.childData[`pId-${id}`] = [...result];
        setTableData({ ...tableData, dataTable });
      });
    } else if (
      reportsTableData &&
      searchKey.id &&
      (ReportSelectionTreePages[pageID] === undefined ||
        pageID === ReportPageID.itemInventoryInjectedRetired ||
        pageID === ReportPageID.itemInventoryPhysicalInventoryQuantities)
    ) {
      const prevParentData = copyObject(
        reportsTableData.hasOwnProperty('parentData')
          ? reportsTableData.parentData
          : reportsTableData.data,
      );
      result = prevParentData.filter((item) => item[searchKey.id].toLowerCase().includes(query));

      if (reportsTableData.hasOwnProperty('parentData')) {
        filteredData = {
          ...tableData,
          parentData: result,
          dataTable: copyObject(reportsTableData.dataTable),
        };
      } else if (reportsTableData.hasOwnProperty('data')) {
        filteredData = {
          ...tableData,
          data: result,
        };
      }
      setTableData(filteredData);
    } else if (ReportSelectionTreePages[pageID]) {
      const data = copyObject(selectionTree);
      updateBySearchQuery(data);
      setTreeTableData(copyObject(data));
    }
  };

  const updateBySearchQuery = (data) => {
    const query = searchValue.toLowerCase();
    const rows = data ? data.rows : {};
    const result = {};
    Object.keys(rows).forEach((key) => {
      if (
        key === selectedNode &&
        _.isObject(rows[key]) &&
        rows[key].hasOwnProperty('rows') &&
        Object.keys(rows[key].rows).length
      ) {
        const values = copyObject(rows[key].rows);
        const items = Object.keys(values).forEach((item) => {
          if (values[item].data[searchKey.id].toLowerCase().includes(query)) {
            result[item] = values[item];
          }
        });

        rows[key].rows = result;
        return;
      }

      updateBySearchQuery(rows[key]);
    });
  };

  const evaluateValue = (headerFormat, value, key) => {
    let index = null;
    if (reportOptions && reportOptions.ReportOptionTable) {
      index = reportOptions.ReportOptionTable.findIndex((item) => item.OptionID === 3);
    }
    if (
      index > -1 &&
      reportOptions &&
      reportOptions.ReportOptionTable[index].Int32Value === 12 &&
      pageID === ReportPageID.periodTrendingQuantitiesItemDescription &&
      key &&
      key.includes('Week ')
    ) {
      value = value[key.replace('Week ', 'Week')];
    } else {
      value = value[key];
    }

    return parseFloat(value).toLocaleString(undefined, headerFormat.formatMask);
  };

  return (
    <>
      <Prompt when={hasChanges} message={Messages.unSaveChanges} />
      <Panel direction="column" justify="stretch">
        <ControlsBox direction="column">
          <Heading level="3" margin="xsmall">
            {reportsTitle}
          </Heading>
          <Heading level="4" margin="xsmall">
            {reportsLabel}
          </Heading>
        </ControlsBox>
        <ControlsBox>
          {isPageEditable && ReportButtonFunctionality.editable[pageID] ? (
            <BrandButton
              type="button"
              label="Save"
              disabled={evaluateChanges()}
              alignSelf="start"
              onClick={() => {
                // Kick off save event to backend
                // TODO: better way to check for differences with objects?
                const differences = { type, newData: [], oldData: [] };
                editableDataPlaceholder.forEach((element, arrayLocation) => {
                  if (!_.isEqual(element, monthlyBudgetCensusData[arrayLocation])) {
                    differences.newData.push(element);
                    differences.oldData.push(monthlyBudgetCensusData[arrayLocation]);
                  }
                });

                updateMonthlyBudgetCensus({ differences, modded: editableDataPlaceholder });
              }}
            />
          ) : null}
          {isPageEditable && ReportButtonFunctionality.editable[pageID] ? (
            <BrandButton
              type="button"
              label="Cancel"
              disabled={evaluateChanges()}
              alignSelf="start"
              onClick={() => {
                // Reset the editable object with the state's copy from above
                setEditableDataPlaceholder(deepCopy);
              }}
            />
          ) : null}
          {!ReportNoBusinessLogicFilters[pageID] && (
            <SearchSortBar
              type="reports"
              options={filterKeys}
              searchKey={searchKey}
              setSearchKey={(data) => setSearchKey(data)}
              searchValue={searchValue}
              setSearchValue={(data) => setSearchValue(data)}
              filter={() => filter()}
            />
          )}
          {ReportButtonFunctionality.viewDocument[pageID] ? (
            <BrandButton
              type="button"
              label="View Document"
              alignSelf="start"
              disabled={!reportBase64Data}
              onClick={(event) => viewDocument(event)}
            />
          ) : null}
          {ReportButtonFunctionality.viewSpreadsheet[pageID] ? (
            <BrandButton
              type="button"
              label="View Spreadsheet"
              alignSelf="start"
              disabled={!tableData}
              onClick={viewSpreadsheet}
            />
          ) : null}
          {ReportButtonFunctionality.emailDocument[pageID] ? (
            <BrandButton
              type="button"
              label="Email Document"
              alignSelf="start"
              disabled={!reportBase64Data}
              onClick={(e) => toggleModal('document', e)}
            />
          ) : null}
          {ReportButtonFunctionality.emailSpreadsheet[pageID] ? (
            <BrandButton
              type="button"
              label="Email Spreadsheet"
              alignSelf="start"
              disabled={!reportBase64Data}
              onClick={(e) => toggleModal('spreadsheet', e)}
            />
          ) : null}
          {ReportButtonFunctionality.snapshot[pageID] ? (
            <BrandButton
              type="button"
              label="Snapshot"
              disabled={!tableData}
              alignSelf="start"
              onClick={handleGetReportSnapshot}
            />
          ) : null}
          <BrandButton type="button" label="Help" alignSelf="start" onClick={helpButtonClick} />
        </ControlsBox>
        <Box
          className="font-14"
          background={colors.innerBg}
          style={{ maxWidth: '100%', overflowX: 'auto' }}
        >
          {!hasError && ReportSelectionTreePages[pageID] && tableData && !tableData.header ? (
            <ReportsTreeTable
              tableData={tableData}
              selectionTree={treeTableData}
              shouldResetTree={shouldResetTree}
              setShouldResetTree={(bool) => setShouldResetTree(bool)}
              getCurrentTreeSelection={(data) => getCurrentTreeSelection(data)}
              updateSelection={(data) => updateSelection(data)}
              setSelectedTreeIds={setSelectedTreeIds}
              selectedTreeIds={selectedTreeIds}
              setDisplayedRows={setDisplayedRows}
              displayedRows={displayedRows}
              selectedNode={selectedNode}
              setSelectedNode={(data) => setSelectedNode(data)}
            />
          ) : null}
          {!ReportNoBusinessLogicFilters[pageID] ? (
            <ParentTable>
              <TableHeader>
                <TableRow>
                  {tableData !== null
                    ? getTableParentHeaders(tableData.headers, tableData.schemaData)
                    : null}
                </TableRow>
              </TableHeader>
              <TableBody>{tableData !== null ? getTableData(tableData) : null}</TableBody>
            </ParentTable>
          ) : (
            <ScrollableBox>
              <ResultsDataTable
                className="budget-census"
                primaryKey={monthlyBudgetCensusPrimaryKey}
                columns={monthlyBudgetCensusColumns}
                data={editableDataPlaceholder}
                step={editableDataPlaceholder ? editableDataPlaceholder.length : 0}
                bodySize={measures.dataTableMidBodyHeight}
                rowProps={{ [selectedID]: { background: colors.brand } }}
                sortable
              />
            </ScrollableBox>
          )}
          {/* This will be the format going forward for datatables returned from node */}
          {ReportDataTableConfig[pageID] &&
          tableData &&
          tableData.header &&
          tableData.hasDataTableData ? (
            <ReportsTable
              primaryKey={ReportDataTableConfig[pageID].primaryKey}
              step={tableData.data ? tableData.data.length : 0}
              className={pageID === ReportPageID.orderOrderSchedules ? 'firstHeaderColumnAuto' : ''}
              columns={
                ReportDataTableConfig[pageID].columns ||
                Object.entries(tableData.header).map(([headerName, headerFormat]) => {
                  const columnConfig = ReportDataTableConfig[pageID].columnFormat;
                  const headerFormatMask =
                    headerFormat &&
                    headerFormat.formatMask &&
                    Object.keys(headerFormat.formatMask).length;

                  const { formatMask } = headerFormat;
                  if (
                    headerFormatMask &&
                    formatMask.hasOwnProperty('currency') &&
                    formatMask.currency === 'USD' &&
                    formatMask.hasOwnProperty('maximumFractionDigits') &&
                    formatMask.maximumFractionDigits === 0
                  ) {
                    headerFormat.formatMask.minimumFractionDigits = 0;
                  }

                  let textAlign = 'start';
                  if (headerFormat && headerFormat.textAlign) {
                    switch (headerFormat.textAlign) {
                      case 'left':
                        textAlign = 'start';
                        break;
                      case 'right':
                        textAlign = 'center';
                        break;
                      default:
                        break;
                    }
                  }
                  return {
                    property: headerName,
                    header: <text>{headerName}</text>,
                    ...columnConfig,
                    // render behavior
                    render: (datum) => {
                      return (
                        <Box pad={{ vertical: 'xsmall' }}>
                          <Text size="small" textAlign={textAlign}>
                            {headerFormatMask
                              ? evaluateValue(headerFormat, datum, headerName)
                              : datum[headerName]}
                          </Text>
                        </Box>
                      );
                    },
                  };
                })
              }
              data={tableData.data}
              bodySize={measures.dataTableMidBodyHeight}
              sortable
            />
          ) : null}
          {hasError && errorMessage ? <Box className="error-box">{errorMessage}</Box> : null}
        </Box>
      </Panel>
      <ReportEmailDocumentModal
        emailTransactions={emailTransactions}
        toggleModal={toggleModal}
        showReportEmailDocumentModal={showReportEmailDocumentModal}
        emailType={emailType}
      />
      <Modal
        title="Email Confirmation"
        show={showConfirmationModal}
        setShow={setShowConfirmationModal}
        cancelLabel=""
        confirmAction={() => setShowConfirmationModal(false)}
      >
        <Text>{`Email ${emailDocumentText}`}</Text>
      </Modal>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  rcID: selectRcID,
});

const mapDispatchToProps = (dispatch) => ({
  setRcID: (data) => dispatch(setRcID(data)),
  emailTransactionsReset: () => dispatch(emailTransactionsSuccess(null)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportsResultBox);

import { Box, Button, DataTable, Heading, Table, TableBody, TableRow, Text } from 'grommet';
import { deepFreeze } from 'grommet/utils/object';
import React from 'react';
import ColumnResizer from 'react-column-resizer';
import styled from 'styled-components';

import { Select as MaterialSelect, TextField as MaterialTextField } from '@material-ui/core';

export const measures = {
  borderRadius: '0.25rem',
  dropShadow: '0 0.3rem 1rem',
  dataTableBodyHeight: '591px',
  dataTableTallBodyHeight: '688px',
  dataTableMidBodyHeight: '571px',
  dataTableDoubleHeaderBodyHeight: '555px',
  headerHeight: '136px',
  breadcrumbHeight: '32px',
  minTextWidth: '4em',
  minTextColumnWidth: '6em',
};

export const assetPaths = {
  bg: {
    default: '/images/LinenShelf.jpg',
  },
};

function formatAsCssString(input) {
  if (typeof input === 'string') {
    return '#' + input;
  } else if (Array.isArray(input)) {
    let prefix = 'rgb';
    if (input.length === 4) {
      prefix += 'a';
    }
    return `${prefix}(${input.join(',')})`;
  }
}

const colorsHex = {
  outerBg: 'BBC0D7',
  innerBg: 'FFFFFF',
  clearDarkBlue: '1E1C774F', // '04569F4F' // rgba(11, 40, 66, 0.46), was 0B284275
  darkBlue: '2C2F77',
  darkGray: '333333',
  faintestBlue: 'C8D2F34D',
  lightGreen: 'E2E9E2',
  lighterBlue: 'A5BAFD',
  lightestBlue: 'DEE6FD',
  lightestCyan: 'EBF5FF',
  logoPrimary: '2F6EBA',
  logoSecondary: '34116B',
  tealGray: '5698C1',
  electricTeal: '76BAFF',
};
// TODO: buttons have this border color '7D4CDB'; change to colorsHex.brand

const colorsByType = {
  brand: '6C82C9',
  // original Grommet colors in comments
  // background: colorsHex.outerBg,
  background: 'transparent',
  focus: colorsHex.lighterBlue,
  hover: colorsHex.lightestBlue, // was '2BAEE9'
  active: colorsHex.lighterBlue, // was colorsHex.darkBlue
  text: colorsHex.darkGray,
  dropShadow: colorsHex.clearDarkBlue,
  clickableInput: colorsHex.faintestBlue,
  interactiveBorder: colorsHex.lightestBlue,
  'accent-1': colorsHex.darkBlue,
  'accent-2': 'FD6FFF',
  'accent-3': '81FCED',
  'accent-4': 'FFCA58',
  'neutral-1': colorsHex.logoPrimary,
  'neutral-2': colorsHex.logoSecondary,
  'neutral-3': '00739D',
  'neutral-4': 'B52C26', // legible red
  'status-critical': 'FF4040',
  'status-error': 'FF4040',
  'status-warning': 'FFAA15',
  'status-ok': '00C781',
  'status-unknown': 'D3E1F0',
  'status-disabled': 'D3E1F0',
  'light-1': colorsHex.lightestCyan,
  'light-2': 'F2F2F2',
  'light-3': 'EDEDED',
  'light-4': 'DADADA',
  'light-5': 'DADADA',
  'light-6': 'DADADA',
  'dark-1': '333333',
  'dark-2': '555555',
  'dark-3': '777777',
  'dark-4': '999999',
  'dark-5': '999999',
  'dark-6': '999999',
  'text-strong': colorsHex.darkBlue,
  'text-weak': '51608C',
  'background-strong': colorsHex.lightestCyan,
  'background-weak': 'C6D4FD80',
  'background-xweak': colorsHex.faintestBlue,
  navHoverBg: 'EAF8FE',
};
colorsByType.brandDisabled = colorsByType.brand + '4C'; // opacity 0.3
colorsByType.border = colorsByType.brand;

const objectMap = (object, fn) => {
  const newObject = {};

  const keys = Object.keys(object);

  const numKeys = keys.length;
  let i;
  let key;

  for (i = 0; i < numKeys; i++) {
    key = keys[i];
    newObject[key] = fn(object[key]);
  }

  return newObject;
};

export const colors = objectMap(Object.assign({}, colorsHex, colorsByType), formatAsCssString);

export const borders = {
  width: {
    thick: '2px',
  },
  box: `1px solid ${colors.border}`,
  grid: `1px solid ${colors.border}`,
};

export const cssRules = {
  ellipsis: `
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `,
};

const theme = deepFreeze({
  global: {
    colors: objectMap(colorsByType, formatAsCssString),
    font: {
      family:
        '"Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
      size: '16px',
    },
  },
  button: {
    primary: {
      color: colors.brand,
      extend: {
        color: 'white',
      },
    },
    color: { dark: 'white', light: colors.darkGray }, // i.e. when the _button_ is dark
    border: {
      radius: measures.borderRadius,
    },
    margin: 'small',
  },
  select: {
    color: colors.faintestBlue,
    background: 'white',
    step: 1000,
  },
  layer: {
    border: {
      radius: measures.borderRadius,
    },
  },
  text: {
    size: '16px',
  },
});

export const ContentBox = styled(Box)`
  width: 100%;
  min-width: 1180px;
  max-width: 100%;
`;

export const Page = styled((props) => {
  const pad = props.pad || 'medium';
  delete props.pad;

  if (props.backBgImage || props.frontBgImage) {
    const background = props.background || 'white';
    delete props.background;
    delete props.height;
  }

  return (
    <Box pad={pad} {...props}>
      {props.children}
    </Box>
  );
})`
  padding-top: ${parseInt(measures.breadcrumbHeight) + 24}px;

  ${(props) =>
    props.backBgImage || props.frontBgImage
      ? `
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: ${measures.headerHeight};
    overflow: scroll;

    & > * {
      position: relative;
    }
    `
      : `
    position: relative;
    `}
  ${(props) =>
    props.backBgImage &&
    `
    background-color: ${props.background || colors.innerBg};
    background-image: url(${props.backBgImage});
    background-repeat: no-repeat;
    background-position: top right;
    background-size: inherit;
    `}
  ${(props) =>
    props.frontBgImage &&
    `
    &:before {
      z-index: 0;
      position: fixed;
      left: 0;
      bottom: 0;
      width: auto;
      height: auto;
      display: block;
      content: url(${props.frontBgImage});
    }
    `}
`;

export const PrimaryButton = styled(Button)`
  background-color: ${colors.brand};
  color: ${theme.button.color.dark};
`;

export const BrandButton = styled(Button)`
  background-color: ${colors.brand};
  color: ${theme.button.color.dark};
  font-size: inherit;
`;

export const TemplateButton = styled(Button)`
  background-color: ${colors.brand};
  color: ${theme.button.color.dark};
  font-size: inherit;
  margin-right: 10px;
`;

const HeadingBoxStyle = styled(Box)`
  background-color: ${colors.brand};
  color: white;
  /*overflow: hidden;*/
  border-radius: ${measures.borderRadius} ${measures.borderRadius} 0 0;
`;

export const BoxHeading = (props) => {
  return (
    <HeadingBoxStyle pad="small">
      <Heading level={3} margin="0" style={props.style}>
        {props.children}
      </Heading>
    </HeadingBoxStyle>
  );
};

export const boxFormStyle = `
  max-width: 400px;
  border-radius: 0.5rem;
  /*overflow: hidden;*/
`;

export const BorderBox = styled(Box)`
  border-radius: ${measures.borderRadius};
  background-color: ${colors.innerBg};
  border: ${borders.box};
`;

const ResizeTable = styled(Table)`
  table-layout: fixed;

  & > tbody > tr > td:not(:nth-child(2)) {
    min-width: ${measures.minTextWidth}; /*avoid crushing when resizing*/
  }
  & > tbody > tr > td:not(:nth-child(2)) > table {
    width: 100%; /*override default table width "inherit", which will be _again_ 50% (e.g.) of the resized column.*/
  }
`;

const Resizer = styled(ColumnResizer)`
  width: 0.8em;
  background-clip: padding-box;
  position: relative;
  vertical-align: middle;
  text-align: center;

  :before {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${colors.innerBg};
    box-sizing: border-box;
    border-left: 2px solid ${colors.interactiveBorder};
    border-right: 2px solid ${colors.interactiveBorder};
  }

  :after {
    content: '\\FE19'; /* vertical ellipsis, signifying "grab me" */
    color: ${colors['text-strong']};
    display: inline;
    position: relative;
    left: -0.1em;
    font-weight: bold;
  }
`;

/**
 * FIXME: [LH-309] If the window is resized from larger to smaller after the table has
 * been resized, the table will be stuck too wide. Because the style
 * `table-layout: fixed` is in effect, cell widths summing to > 100% are still
 * obeyed. This style is needed to allow the table cell to be sized smaller
 * than its contents, letting it scroll). With the default `table-layout: auto`
 * style, a table cell will not ever scroll.
 *
 * Possible solution: listen for resize and explicitly clear width style on
 * resized columns.
 * https://stackoverflow.com/questions/19014250/rerender-view-on-browser-resize-with-react
 */
export const ResizableColumnTable = styled((props) => {
  const children = React.Children.toArray(props.children);

  if (children.length > 1) {
    // insert the resizer as the 2nd child
    children.splice(
      1,
      0,
      <Resizer key="separator" minWidth={150} className="resizer-handle-column" />,
    );
  }

  return (
    <ResizeTable {...props}>
      <TableBody>
        <TableRow>{children}</TableRow>
      </TableBody>
    </ResizeTable>
  );
})`
  /*width: 100%;*/
  & > tbody > tr > td {
    padding: 0;
  }
  & > tbody > tr > td:not(:nth-child(2)) {
    // width: 50%;
    vertical-align: top;
    overflow: hidden; /* handle overflow scrolling in its content, if needed */
  }

  ${(props) => {
    let output = '';
    if (props.initialWidths) {
      if (props.initialWidths[0]) {
        output += `
          & > tbody > tr > td:nth-child(1) {
            width: ${props.initialWidths[0]};
          }
        `;
      }
      if (props.initialWidths[1]) {
        output += `
          & > tbody > tr > td:nth-child(3) {
            width: ${props.initialWidths[1]};
          }
        `;
      }
    }
    return output;
  }}
`;

export const Text16 = styled(Text)`
  font-size: 16px !important;
`;

export const Panel = styled(Box)`
  span {
    font-size: 14px;
    line-height: 20px;
  }

  input {
    font-size: 14px;
    line-height: 20px;
  }

  label {
    font-size: 14px;
    line-height: 20px;
  }

  button {
    font-size: 14px;
    line-height: 20px;
    span {
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

export const ScrollableBox = styled(Box)`
  overflow: auto;
  max-height: 700px;

  table {
    span {
      font-size: 14px !important;
      line-height: 20px !important;
    }

    input {
      border: 1px solid #cbcdd6;
    }

    button {
      input {
        border: inherit;
      }
    }
  }

  tbody {
    td,
    th {
      min-width: auto;
    }
  }

  .setup {
    thead {
      tr {
        th:nth-child(2) {
          width: 50px;
          max-width: 50px;
          min-width: auto;
        }
      }
    }

    tbody {
      tr {
        td:nth-child(2) {
          span {
            text-align: center;
          }
        }
      }
    }
  }

  /** counte linen **/
  .cl-carts-and-closets {
    tbody {
      td:nth-child(n + 3):nth-child(-n + 4) {
        label {
          margin: auto;

          div {
            div {
              margin-top: -10px;
            }
          }
        }
      }
    }
  }
  /** setup left tables with specific column width **/
  .orders {
    thead {
      tr {
        th {
          width: 25px;
          max-width: 25px;
          min-width: auto;
        }

        th:nth-child(n + 7) {
          width: 50px;
          min-width: 40px;
        }
      }
    }
    tbody {
      tr {
        td:nth-child(4) {
          button {
            width: 250px;
          }
        }

        td:nth-child(5) {
          button {
            width: 165px;
          }
        }

        td:nth-child(6),
        td:nth-child(7) {
          button {
            width: 110px;
          }
        }

        td:nth-child(n + 7) {
          label {
            margin: auto;

            div {
              div {
                margin-top: -10px;
              }
            }
          }
        }
      }
    }
  }

  .procedures,
  .packs,
  .products {
    tbody {
      tr {
        td:nth-child(3) {
          input {
            width: 300px !important;
          }
        }

        td:nth-child(4) {
          input {
            width: 200px;
          }
        }

        td:nth-child(n + 5):nth-child(-n + 7) {
          input {
            width: 200px !important;
          }
        }
      }
    }
  }

  .products {
    tbody {
      tr {
        td:nth-child(n + 8),
        td:nth-child(5) {
          input {
            width: 120px !important;
          }
        }
      }
    }
  }

  .departments {
    tbody {
      tr {
        td:nth-child(3) {
          input {
            width: 300px !important;
          }
        }

        td:nth-child(5) {
          button {
            width: 300px;
          }
        }

        td:nth-child(6) {
          input {
            width: 120px !important;
          }
        }
      }
    }
  }

  .department-accounts,
  .customer-accounts,
  .item-accounts {
    tbody {
      tr {
        td:nth-child(3) {
          input {
            width: 300px !important;
          }
        }
      }
    }
  }

  .budget-census {
    tbody {
      tr {
        td {
          input {
            height: 24px !important;
          }
        }
      }
    }
  }

  /** setup tables on the right **/
  .right-orders {
    tbody {
      tr {
        td {
          input {
            width: 120px !important;
          }
        }
      }
    }
  }
`;

export const ThemeDataTable = styled((props) => {
  const furtherProps = {
    border: {
      body: {
        color: colors['dark-1'],
        side: 'left',
      },
      footer: 'none',
    },
  };
  if (props.bodySize) {
    // furtherProps.size = "large"; // any size from https://v2.grommet.io/datatable#size to trigger body scrolling
  }

  return <DataTable {...props} {...furtherProps} />;
})`
  /*width: 100%;*/
  // table-layout: fixed;
  thead {
    th {
      font-weight: 900;
      /* width: 100px; */
      top: 0;
      background: #fff;
    }
    th,
    th button {
      ${cssRules.ellipsis}
    }
  }

  tbody {
    ${(props) => (props.bodySize ? `max-height: ${props.bodySize};` : '')}
    tr:nth-child(odd):not(:last-child) {
      background-color: ${colors.lightestBlue};
    }
  }

  th,
  td {
    // word-break: break-all; /*fallback for IE11*/
    // word-break: break-word;
    white-space: nowrap;
    min-width: ${measures.minTextColumnWidth}; /*avoid crushing when resizing*/
  }

  tr > :first-child {
    border-left: none;
  }
`;

export const SideHeading = styled(Heading)`
  margin: 0.5em 0.5em 0 0.5em;
`;

export const Select = styled(MaterialSelect)`
  display: block !important; /* from inline-flex */
  margin: 0.5em 0;

  .MuiSelect-select.MuiSelect-select {
    box-sizing: border-box; /* fix text appearing under arrow */
  }
  &.Mui-selected,
  &.Mui-selected:hover {
    background-color: ${colors.active} !important;
  }
  .MuiListItem-button:hover {
    background-color: ${colors.hover} !important;
  }
`;

export const TextField = styled(MaterialTextField)`
  &,
  .MuiInputBase-root {
    display: block !important; /* from inline-flex */
  }
  margin: 0.5em 0 !important;
`;

export default theme;

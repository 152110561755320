import SetupUsersLeadDayValuesActionTypes from './setup-users-lead-day-values.types';

const INITIAL_STATE = {
  lead_day_values: null,
};

const setupUserLeadDayValuesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SetupUsersLeadDayValuesActionTypes.GET_LEAD_DAY_VALUES_SUCCESS:
      return {
        ...state,
        lead_day_values: action.payload,
        error: null,
      };

    case SetupUsersLeadDayValuesActionTypes.GET_LEAD_DAY_VALUES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default setupUserLeadDayValuesReducer;

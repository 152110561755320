import { Box, Text } from 'grommet';
import React, { useEffect, useState } from 'react';

import Modal from '@Components/modal/modal.component';
import ReportsFilterBox from '@Components/reports/reports-filter.component';
import ReportsResultBox from '@Components/reports/reports-result-box.component';
import SideBar from '@Components/sidebar/sidebar.component';
import TreeSelection from '@Components/tree-selection/tree-selection.components';
import { copyObject, toUtcFormat } from '@Helpers/common.helper';
import { colors } from '@ThemeMain';

import { ReportBackendTypes } from './reports-config';

const ReportsPanel = (props) => {
  const {
    ReportButtonFunctionality,
    ReportSelectionTreePages,
    currentCustomer,
    reportsTableData,
    selectionTree,
    monthlyBudgetCensusData,
    reportBase64Data,
    updateMonthlyBudgetCensus,
    setReportsLabel,
    reportsTitle,
    reportsLabel,
    emailTransactions,
    getBusinessLogicData,
    getReportSnapshot,
    getTreeSelection,
    updateSelection,
    reportOptions,
    setShowLoader,
    type,
    pageID,
    Printer,
    emailDocumentResponse,
    userPrivileges,
  } = props;
  // SHARED
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());
  const [treeSelectionOptions, setTreeSelectionOptions] = React.useState({});
  const [treeSelection, setTreeSelection] = React.useState({});
  const [selectedTreeIds, setSelectedTreeIds] = useState(['pId-0']);
  const [displayedRows, setDisplayedRows] = useState(['']);
  const [shouldResetTree, setShouldResetTree] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [treeMenuFormData, setTreeMenuFormData] = useState({});
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const maxNodeCount = 8; // Node count limit of the selection tree
  const hasSideTree = selectionTree && reportsTableData && reportsTableData.header;
  const initialSelection = {
    type0: 0,
    type1: 0,
    type2: 0,
    type3: 0,
    type4: 0,
    type5: 0,
    type6: 0,
    type7: 0,
  };

  useEffect(() => {
    if (reportOptions) {
      setTreeMenuFormData({ ...reportOptions, selection: initialSelection });
    }
  }, [reportOptions]);

  const updateShared = (values) => {
    setStartDate(values.startDate);
    setEndDate(values.endDate);
    if (typeof values.options !== 'undefined') {
      setTreeSelectionOptions(values.options);
    }
    if (typeof values.selectedTreeIds !== 'undefined') {
      setSelectedTreeIds(values.selectedTreeIds);
      updateSelection([]);
    }
    if (typeof values.displayedRows !== 'undefined') {
      setDisplayedRows(values.displayedRows);
    }
  };

  const handleGetReportSnapshot = () => {
    const formData = { ...reportOptions };
    getReportSnapshot(formData);
  };

  const handleTreeSelection = (params) => {
    const { selection, hasNoChildren } = params;
    const options = {
      ...reportOptions,
      selection,
    };

    // If there's an even number of ids, send for more transactions
    const shouldRefreshTransactions =
      Object.values(selection).filter((val) => val !== 0).length % 2 === 0;

    // TODO: [LH-289] Side trees refresh transactions
    if (hasSideTree) {
      // get transactions with selection tacked on
      if (hasNoChildren) {
        setShowLoader(true);
        getTreeSelection(options);
      }
      // alternates between getSelectionTree and Transactions
      if (shouldRefreshTransactions) {
        getBusinessLogicData({ ...reportOptions, selection });
      }
    } else if (hasNoChildren) {
      setShowLoader(true);
      getTreeSelection(options);
    }
  };

  // Moved so could be used by side bar selection tree
  const getCurrentTreeSelection = (params) => {
    const { id, hasNoChildren } = params;
    const selection = {
      type0: 0,
      type1: 0,
      type2: 0,
      type3: 0,
      type4: 0,
      type5: 0,
      type6: 0,
      type7: 0,
    };
    const ids = id.split('-');
    ids.shift();

    if (ids.length < maxNodeCount) {
      for (let i = 0; i < ids.length; i++) {
        selection[`type${i}`] = ids[i];
      }
      handleTreeSelection({ selection, hasNoChildren });
    }
  };

  const formatDateRange = (data) => {
    const index =
      data && data.hasOwnProperty('ReportOptionTable')
        ? data.ReportOptionTable.findIndex((item) => item.DataType === 'DATERANGE')
        : -1;
    if (index > -1) {
      data.ReportOptionTable[index].DateTimeValue1 = toUtcFormat(
        data.ReportOptionTable[index].DateTimeValue1,
      );
      data.ReportOptionTable[index].DateTimeValue2 = toUtcFormat(
        data.ReportOptionTable[index].DateTimeValue2,
      );
    }

    return data;
  };

  // When current customer changes, resubmit the form with
  // new customer info
  useEffect(() => {
    if (
      pageID &&
      (reportsTableData !== null || selectionTree !== null) &&
      ReportBackendTypes[pageID] === type
    ) {
      if (['census', 'budget'].includes(type)) {
        getBusinessLogicData(reportOptions.ReportOptionTable[0].DateTimeValue1, true);
      } else {
        let formOptions = {};
        formOptions = formatDateRange(copyObject(reportOptions));
        getBusinessLogicData(formOptions);
      }
    }
  }, [currentCustomer]);

  return (
    <>
      {currentCustomer ? (
        <Box gap="small" justify="stretch" direction="row">
          <SideBar>
            <ReportsFilterBox
              key="filterBox"
              reportsTitle={reportsTitle}
              reportsLabel={reportsLabel}
              reportOptions={reportOptions}
              reportsTableData={reportsTableData}
              selectionTree={selectionTree}
              ReportSelectionTreePages={ReportSelectionTreePages}
              pageID={pageID}
              type={type}
              getBusinessLogicData={(data, shouldRefresh) =>
                getBusinessLogicData(data, shouldRefresh)
              }
              setShouldResetTree={(bool) => setShouldResetTree(bool)}
              startDate={startDate}
              endDate={endDate}
              updateShared={(values) => updateShared(values)}
              setDisplayedRows={setDisplayedRows}
              setSelectedTreeIds={setSelectedTreeIds}
              hasChanges={hasChanges}
              setHasChanges={(data) => setHasChanges(data)}
              setShowErrorModal={(data) => setShowErrorModal(data)}
              setErrorMessage={(data) => setErrorMessage(data)}
              gridArea="nav"
            />
            {/* Inner selection tree on side of page */}
            {hasSideTree && (
              <TreeSelection
                selectionTree={selectionTree}
                shouldResetTree={shouldResetTree}
                getSelectionTree={(data) => getTreeSelection(data)}
                getData={getBusinessLogicData}
                setShouldResetTree={setShouldResetTree}
                setFormData={(data) => setTreeMenuFormData(data)}
                formData={treeMenuFormData}
                getDataOnNodeClick
              />
            )}
          </SideBar>
          <Box
            background={colors.innerBg}
            width="100%"
            className="right-table"
            style={{ minWidth: '1250px' }}
          >
            <ReportsResultBox
              ReportButtonFunctionality={ReportButtonFunctionality}
              ReportSelectionTreePages={ReportSelectionTreePages}
              getCurrentTreeSelection={(data) => getCurrentTreeSelection(data)}
              setReportsLabel={(label) => setReportsLabel(label)}
              type={type}
              userPrivileges={userPrivileges}
              pageID={pageID}
              reportsTitle={reportsTitle}
              reportsLabel={reportsLabel}
              startDate={startDate}
              endDate={endDate}
              reportsTableData={reportsTableData}
              reportBase64Data={reportBase64Data}
              selectionTree={selectionTree}
              shouldResetTree={shouldResetTree}
              setShouldResetTree={(bool) => setShouldResetTree(bool)}
              emailTransactions={emailTransactions}
              monthlyBudgetCensusData={monthlyBudgetCensusData}
              updateMonthlyBudgetCensus={(data) => updateMonthlyBudgetCensus(data)}
              handleGetReportSnapshot={() => handleGetReportSnapshot()}
              handleTreeSelection={(data) => handleTreeSelection(data)}
              updateSelection={(data) => updateSelection(data)}
              selectedTreeIds={selectedTreeIds}
              setSelectedTreeIds={setSelectedTreeIds}
              setDisplayedRows={setDisplayedRows}
              displayedRows={displayedRows}
              hasChanges={hasChanges}
              setHasChanges={(data) => setHasChanges(data)}
              reportOptions={reportOptions}
              Printer={Printer}
              emailDocumentResponse={emailDocumentResponse}
            />
          </Box>
          <Modal
            title="Error"
            show={showErrorModal}
            setShow={setShowErrorModal}
            confirmAction={() => setShowErrorModal(false)}
            cancelLabel=""
          >
            <Text>{errorMessage}</Text>
          </Modal>
        </Box>
      ) : null}
    </>
  );
};

export default ReportsPanel;

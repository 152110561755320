import SetupAdvancedCustomerActionTypes from './setup-advanced-customers.types';

const INITIAL_STATE = {
  advanced_customers: null,
  error: null,
};

const setupAdvancedCustomerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SetupAdvancedCustomerActionTypes.GET_ADVANCED_CUSTOMER_SUCCESS:
      return {
        ...state,
        advanced_customers: action.payload,
        error: null,
      };

    case SetupAdvancedCustomerActionTypes.GET_ADVANCED_CUSTOMER_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default setupAdvancedCustomerReducer;

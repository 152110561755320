import SetupUsersCustomerSelectedActionTypes from './setup-users-customer-selected-in-user.types';

const INITIAL_STATE = {
  customer_selected: [],
  customers_to_delete: [],
  customers_to_add: [],
};

const setupUserCustomerSelectedReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SetupUsersCustomerSelectedActionTypes.GET_CUSTOMER_SELECTED_SUCCESS:
      return {
        ...state,
        customer_selected: action.payload ? action.payload : [],
        customers_to_delete: [],
        customers_to_add: [],
        error: null,
      };

    case SetupUsersCustomerSelectedActionTypes.GET_CUSTOMER_SELECTED_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case SetupUsersCustomerSelectedActionTypes.ADD_CUSTOMER_TO_DELETE_QUEUE_SUCCESS:
      const customersToDeleteAndSelectedMatchIndex = state.customer_selected.findIndex(
        (customer) => customer.RcID === action.payload.RcID,
      );
      if (customersToDeleteAndSelectedMatchIndex !== -1)
        state.customer_selected.splice(customersToDeleteAndSelectedMatchIndex, 1);
      const customersToDeleteMatchIndex = state.customers_to_add.findIndex(
        (customer) => customer.RcID === action.payload.RcID,
      );
      if (customersToDeleteMatchIndex !== -1)
        state.customers_to_add.splice(customersToDeleteMatchIndex, 1);
      const customerRowForDeleteQueue = {
        RcID: action.payload.RcID,
        Name: action.payload.ObjDesc,
        ObjDesc: action.payload.ObjDesc,
        ObjRcID: action.payload.ObjRcID,
        UserRcID: action.payload.UserRcID,
        RowOrder: state.customer_selected.length + 1,
      };
      state.customers_to_delete.unshift(customerRowForDeleteQueue);

      return {
        ...state,
        error: null,
      };

    case SetupUsersCustomerSelectedActionTypes.ADD_CUSTOMER_TO_ADD_QUEUE_SUCCESS:
      const customerToAddMatchIndex = state.customers_to_delete.findIndex(
        (customer) => customer.RcID === action.payload.RcID,
      );
      if (customerToAddMatchIndex !== -1)
        state.customers_to_delete.splice(customerToAddMatchIndex, 1);
      const customerRowForAddQueue = {
        RcID: action.payload.RcID,
        ObjDesc: action.payload.Name,
        ObjRcID: action.payload.ObjRcID,
        UserRcID: action.payload.UserRcID,
        RowOrder: state.customer_selected.length + 1,
      };
      state.customers_to_add.unshift(customerRowForAddQueue);
      state.customer_selected.unshift(customerRowForAddQueue);
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export default setupUserCustomerSelectedReducer;

import dateformat from 'dateformat';
import { Box, Form, Paragraph, Table, TableBody, TableCell, TableRow, Text } from 'grommet';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import CustomCalendar from '@Components/custom-calendar/custom-calendar.component';
import { copyObject, DATE_FORMAT, MESSAGE, toUtcFormat } from '@Helpers/common.helper';
import { InputLabel, MenuItem, TextField as MaterialTextField } from '@material-ui/core';
import { Messages } from '@Pages/count-linen/count-linen-config';
import { ReportBackendTypes, ReportPageID } from '@Pages/reports/reports-config';
import { saveOptionsHistory, setRcID } from '@State/reports/reports.actions';
import { selectRcID } from '@State/reports/reports.selectors';
import { BrandButton, colors, cssRules, Select, TextField } from '@ThemeMain';

const FilterTable = styled(Table)`
  width: 100%;
  overflow-y: auto;
`;

const LabelText = styled(Text)`
  ${cssRules.ellipsis}
  display: inline-box;
  max-width: 100%;
`;

// 2019-12-07T14:08:20.6284446-09:00
const businessLogicDateFormat = "yyyy-mm-dd'T'12:00:00.lo";
const budgetCensusDateFormat = "yyyy-mm-01'T'12:00:00";

function toDataTableFormat(data) {
  const result = [];
  if (data !== null) {
    Array.from(data, (item) => {
      result.push({ label: item.Name, value: item.RcID });
    });
  }

  return result;
}

const ReportsFilterBox = (props) => {
  const {
    reportOptions,
    getBusinessLogicData,
    pageID,
    type,
    ReportSelectionTreePages,
    setShouldResetTree,
    setDisplayedRows,
    setSelectedTreeIds,
    reportsTableData,
    selectionTree,
    rcID,
    setRcID,
    hasChanges,
    setHasChanges,
    setShowErrorModal,
    setErrorMessage,
    saveOptionsHistory,
  } = props;

  useEffect(() => {
    if (
      ['census', 'budget'].includes(type) &&
      reportOptions &&
      ReportBackendTypes[pageID] === type
    ) {
      const options = reportOptions.ReportOptionTable;
      const date = options.length
        ? options[0].DateTimeValue1
        : dateformat(moment(), budgetCensusDateFormat);
      handleMonthChange(date, 0, 'DateTimeValue1');
    }
  }, [type]);

  const displaySubmit = !['census', 'budget'].includes(type);
  const [currentDate, setCurrentDate] = useState(new Date());

  const [formData, setFormData] = React.useState({
    options: reportOptions ? reportOptions.ReportOptionTable : {},
    selection: {
      type0: 0,
      type1: 0,
      type2: 0,
      type3: 0,
      type4: 0,
      type5: 0,
      type6: 0,
      type7: 0,
    },
  });

  const formOptions = {};
  if (reportOptions !== null) {
    Object.entries(reportOptions).forEach(([key, value]) => {
      if (key !== 'ReportOptionTable') formOptions[key] = toDataTableFormat(value);
    });
  }

  const handleDateChange = (date, reportOptionKey, name) => {
    const options = reportOptions.ReportOptionTable;
    // Add : between time for business logic
    options[reportOptionKey][name] = toUtcFormat(date);

    reportOptions.ReportOptionTable = options;

    setFormData({ ...formData, options });
    props.updateShared({ reportOptions });
  };

  const handleMonthChange = (date, reportOptionKey, name) => {
    const options = reportOptions.ReportOptionTable;
    const previousDate = options[reportOptionKey][name];
    setCurrentDate(new Date(dateformat(date, budgetCensusDateFormat)));

    if (
      (hasChanges &&
        previousDate !== dateformat(date, budgetCensusDateFormat) &&
        window.confirm(Messages.unSaveChanges)) ||
      !hasChanges
    ) {
      options[reportOptionKey][name] = dateformat(date, budgetCensusDateFormat);
      options[reportOptionKey].Prompt1 = `${
        type.charAt(0).toUpperCase() + type.slice(1)
      } for ${dateformat(date, 'mmmm, yyyy')}`;
      reportOptions.ReportOptionTable = options;
      setFormData({ ...formData, options });
      props.updateShared({ reportOptions });
      // Get Data
      getBusinessLogicData(dateformat(date, budgetCensusDateFormat));
    } else {
      setTimeout(() => {
        setCurrentDate(new Date(previousDate));
      }, 200);
    }
  };

  const handleSelectChange = (event, child, rowOrder) => {
    const options = reportOptions.ReportOptionTable;
    options[rowOrder].Int32Value = event.target.value;
    reportOptions.ReportOptionTable = options;

    setFormData({ ...formData, options });
    props.updateShared({ reportOptions });
  };

  const handleDoubleChange = (event, child, rowOrder, min, max) => {
    const doubleValue = parseFloat(event.target.value) || 0;
    if (parseFloat(min) <= doubleValue && parseFloat(max) >= doubleValue) {
      const options = reportOptions.ReportOptionTable;
      options[rowOrder].DoubleValue = event.target.value || '0';
      reportOptions.ReportOptionTable = options;

      setFormData({ ...formData, options });
      props.updateShared({ reportOptions });
    }
  };

  const renderFormGroupsInputs = (inputs) => {
    const element = [];

    inputs.forEach((item, index) => {
      const options = formOptions[item.LookupType];
      element.push(
        <TableCell key={`${item.$attributes.rowOrder}`} scope="row">
          <InputLabel id={`label_${item.$attributes.id}`}>{item.Prompt1}</InputLabel>
          <Select
            labelId={`label_${item.$attributes.id}`}
            value={
              formOptions[item.LookupType].filter((elem) => elem.value.toString() === item.Int32Value.toString())[0].value
            }
            defaultValue={
              formOptions[item.LookupType].filter((elem) => elem.value.toString() === item.Int32Value.toString())[0].value
            }
            name={item.OptionID}
            onChange={(event, child) => {
              handleSelectChange(event, child, item.$attributes.rowOrder);
            }}
          >
            {options.map((option) => {
              return (
                <MenuItem key={`${option.label}_${option.value}`} value={option.value}>
                  {option.label}
                </MenuItem>
              );
            })}
          </Select>
        </TableCell>,
      );
    });

    return element;
  };

  const gateDate = (value) => {
    const date = moment(value).format('YYYY-MM-DD');
    return date;
  };

  const renderFormInputs = (input) => {
    let element = <></>;
    switch (input.DataType) {
      case 'DATERANGE':
        if (input.Prompt1 && input.Prompt2) {
          element = (
            <TableRow key={input.Prompt1 + input.Prompt2}>
              <TableCell key={input.Prompt1} scope="row" style={{ marginTop: '10px' }}>
                <InputLabel>{input.Prompt1}</InputLabel>
                <Box margin={{ vertical: 'small' }}>
                  {/* <CustomCalendar
                    name={input.OptionID}
                    value={[new Date(input.DateTimeValue1), new Date(input.DateTimeValue2)]}
                    selectRange
                    onChange={([startDate, endDate]) => {
                      handleDateChange(startDate, input.$attributes.rowOrder, 'DateTimeValue1');
                      handleDateChange(endDate, input.$attributes.rowOrder, 'DateTimeValue2');
                    }}
                  /> */}
                  <MaterialTextField
                    type="date"
                    defaultValue={gateDate(input.DateTimeValue1)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(event) => {
                      handleDateChange(
                        event.target.value,
                        input.$attributes.rowOrder,
                        'DateTimeValue1',
                      );
                    }}
                  />
                </Box>
              </TableCell>
              <TableCell key={input.Prompt2} scope="row">
                <InputLabel>{input.Prompt2}</InputLabel>
                <Box margin={{ vertical: 'small' }}>
                  <MaterialTextField
                    type="date"
                    defaultValue={gateDate(input.DateTimeValue2)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(event) => {
                      handleDateChange(
                        event.target.value,
                        input.$attributes.rowOrder,
                        'DateTimeValue2',
                      );
                    }}
                  />
                </Box>
              </TableCell>
            </TableRow>
          );
        } else {
          element = (
            <TableRow key={input.Prompt1}>
              <TableCell scope="row" colSpan="2">
                <LabelText>{input.Prompt1}</LabelText>
                <Box margin={{ vertical: 'small' }}>
                  <CustomCalendar
                    name={input.OptionID}
                    value={new Date(input.DateTimeValue1)}
                    onChange={(date) => {
                      handleDateChange(date, input.$attributes.rowOrder, 'startDate');
                    }}
                  />
                </Box>
              </TableCell>
            </TableRow>
          );
        }
        break;
      case 'LOOKUP':
        element = (
          <TableRow key={input.Prompt1}>
            <TableCell colSpan="2" scope="row">
              <InputLabel id={`label_${input.$attributes.id}`}>{input.Prompt1}</InputLabel>
              <Select
                labelId={`label_${input.$attributes.id}`}
                background={colors.clickableInput}
                value={
                  formOptions[input.LookupType].filter((elem) => elem.value.toString() === input.Int32Value.toString())[0]
                    ?.value
                }
                defaultValue={
                  formOptions[input.LookupType].filter((elem) => elem.value.toString() === input.Int32Value.toString())[0]
                    ?.value
                }
                name={input.OptionID}
                onChange={(event, child) => {
                  handleSelectChange(event, child, input.$attributes.rowOrder);
                }}
              >
                {formOptions[input.LookupType].map((option) => {
                  return (
                    <MenuItem key={`${option.label}_${option.value}`} value={option.value}>
                      {option.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </TableCell>
          </TableRow>
        );
        break;
      case 'MONTHLY':
        element = (
          <TableRow key={input.Prompt1}>
            <TableCell scope="row">
              <Text>{input.Prompt1}</Text>
              <Box margin={{ vertical: 'small' }}>
                <CustomCalendar
                  name={input.OptionID}
                  value={currentDate}
                  maxDetail="year"
                  onChange={(date) => handleMonthChange(date, 0, 'DateTimeValue1')}
                />
              </Box>
            </TableCell>
          </TableRow>
        );
        break;
      case 'DOUBLE':
        element = (
          <TableRow key={input.Prompt1}>
            <TableCell scope="row" colSpan="2">
              <InputLabel id={`label_${input.$attributes.id}`}>{input.Prompt1}</InputLabel>
              <TextField
                labelId={`label_${input.$attributes.id}`}
                value={input.DoubleValue}
                id={input.$attributes.id}
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: input.DataMin,
                  max: input.DataMax,
                  step: 0.01,
                }}
                onChange={(event, child) => {
                  handleDoubleChange(
                    event,
                    child,
                    input.$attributes.rowOrder,
                    input.DataMin,
                    input.DataMax,
                  );
                }}
              />
            </TableCell>
          </TableRow>
        );
        break;
      default:
        break;
    }

    return element;
  };

  const openErrorModal = (message) => {
    setErrorMessage(message);
    setShowErrorModal(true);
  };

  const validDates = (item) => {
    const startDate = moment(item.DateTimeValue1, DATE_FORMAT.standard);
    const endDate = moment(item.DateTimeValue2, DATE_FORMAT.standard);

    if (startDate.isValid() && endDate.isValid()) {
      if (startDate <= endDate) {
        return true;
      }
      openErrorModal(MESSAGE.dateComparison);
    } else {
      openErrorModal(MESSAGE.invalidDate);
    }

    return false;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formatOptions = copyObject(reportOptions);
    // compare dates
    if (reportOptions.hasOwnProperty('ReportOptionTable')) {
      const index = reportOptions.ReportOptionTable.findIndex(
        (item) => item.DataType === 'DATERANGE',
      );

      if (
        index > -1 &&
        reportOptions.ReportOptionTable[index].hasOwnProperty('DateTimeValue1') &&
        reportOptions.ReportOptionTable[index].hasOwnProperty('DateTimeValue2') &&
        !validDates(reportOptions.ReportOptionTable[index])
      ) {
        return false;
      }
      if (index > -1) {
        formatOptions.ReportOptionTable[index].DateTimeValue1 = toUtcFormat(
          reportOptions.ReportOptionTable[index].DateTimeValue1,
        );
        formatOptions.ReportOptionTable[index].DateTimeValue2 = toUtcFormat(
          reportOptions.ReportOptionTable[index].DateTimeValue2,
        );
      }

      saveOptionsHistory(pageID, reportOptions.ReportOptionTable);
    }
    // Send variable to refresh data on form submit
    if (ReportSelectionTreePages[pageID]) {
      setDisplayedRows([]);
      setSelectedTreeIds(['pId-0']);
    }
    // Clear reports data on form submit
    if (reportsTableData) {
      clearReportData(reportsTableData);
    }
    if (selectionTree) {
      clearReportData(selectionTree);
    }

    getBusinessLogicData(formatOptions, true);
    setShouldResetTree(true);
  };

  const clearReportData = (data) => {
    Object.keys(data).forEach((key) => {
      delete data[key];
    });
  };

  return (
    <Form onSubmit={(event) => handleSubmit(event)}>
      <FilterTable>
        <TableBody>
          {(() => {
            let grouped = [];
            const formElements = [];
            if (reportOptions !== null) {
              props.updateShared({
                options: reportOptions.ReportOptionTable,
              });

              reportOptions.ReportOptionTable.forEach((item, index) => {
                if (typeof item.Prompt1 !== 'undefined') {
                  if (item.GroupNext === 'true') {
                    grouped.push(item);
                  } else if (grouped.length === 1) {
                    grouped.push(item);
                    const elements = (
                      <TableRow key={`${index}_key_${item.Prompt1}`}>
                        {renderFormGroupsInputs(grouped)}
                      </TableRow>
                    );
                    grouped = [];
                    formElements.push(elements);
                  } else if (grouped.length === 0) {
                    formElements.push(renderFormInputs(item, reportOptions));
                  }
                } else {
                  console.log('DOES THIS HAPPEN?', item);
                }
              });
              return formElements;
            }
          })()}
          {pageID === ReportPageID.itemInventoryInventoryProjections && (
            <TableRow>
              <TableCell colSpan="2" scope="row">
                <Paragraph>This report is limited to the active customer</Paragraph>
                <Paragraph>To view a different customer's information</Paragraph>
                <Paragraph>Select that customer and rerun the report</Paragraph>
              </TableCell>
            </TableRow>
          )}
          {displaySubmit && (
            <TableRow>
              <TableCell colSpan="2" scope="row">
                <BrandButton
                  type="submit"
                  label="Display Report"
                  disabled={!reportOptions}
                  fill="horizontal"
                  margin={{ vertical: 'small' }}
                />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </FilterTable>
    </Form>
  );
};

const mapStateToProps = createStructuredSelector({
  rcID: selectRcID,
});

const mapDispatchToProps = (dispatch) => ({
  setRcID: (data) => dispatch(setRcID(data)),
  saveOptionsHistory: (type, data) => dispatch(saveOptionsHistory(type, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ReportsFilterBox));

import styled from 'styled-components';

import { BrandButton } from '@ThemeMain';

export const StyledNavBtn = styled(BrandButton)`
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  text-transform: uppercase;
  font-weight: bold;
`;

import 'react-datepicker/dist/react-datepicker.css';

import dateformat from 'dateformat';
import { Box, Table, TableBody, TableCell, TableHeader, TableRow, Text } from 'grommet';
import { CaretDown, CaretNext } from 'grommet-icons';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import TreeMenu from 'react-simple-tree-menu';
import { createStructuredSelector } from 'reselect';

import SideBar from '@Components/sidebar/sidebar.component';
import { DATE_FORMAT, MESSAGE } from '@Helpers/common.helper';
import { TextField as MaterialTextField } from '@material-ui/core';
import { ButtonMenu } from '@Pages/count-linen/count-linen.styles';
import { SetUpPageID } from '@Pages/setup/setup-config';
import { selectCurrentCustomer } from '@State/user/user.selectors';

const businessLogicDateFormat = "yyyy-mm-dd'T'12:00:00.lo";
const pad = '10px 40px 0px 40px;';

const SideBarControls = (props) => {
  const {
    pageID,
    formData,
    selectionTree,
    getSelectionTree,
    setFormData,
    getData,
    setShowErrorModal,
    setErrorMessage,
  } = props;

  const DEFAULT_PADDING = 16;
  const ICON_SIZE = 8;
  const LEVEL_SPACE = 16;
  const [treeData, setTreeData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [selectedParentID, setSelectedParentID] = useState(0);
  const initialSelection = {
    type0: 0,
    type1: 0,
    type2: 0,
    type3: 0,
    type4: 0,
    type5: 0,
    type6: 0,
    type7: 0,
  };
  const ListItem = ({
    level = 0,
    hasNodes,
    isOpen,
    label,
    openNodes,
    toggleNode,
    focused,
    ...props
  }) => (
    <Box
      {...props}
      style={{
        paddingLeft: DEFAULT_PADDING + ICON_SIZE + level * LEVEL_SPACE,
        cursor: 'pointer',
        boxShadow: focused ? '0px 0px 5px 0px #222' : 'none',
        zIndex: focused ? 999 : 'unset',
        position: 'relative',
      }}
      onClick={(e) => {
        onNodeClick(e, { ...props });
      }}
    >
      {hasNodes && (
        <div
          style={{ display: 'inline-block' }}
          onClick={(e) => {
            hasNodes && toggleNode && toggleItem(isOpen, { ...props }, label) && toggleNode();
            e.preventDefault();
          }}
        >
          {label !== 'Not Available' &&
            (isOpen ? <CaretDown className="toggleIcon" /> : <CaretNext className="toggleIcon" />)}
          {label}
        </div>
      )}
    </Box>
  );

  useEffect(() => {
    if (selectionTree !== null) {
      if (treeData.length > 0) {
        if (selectedParentID === 0) {
          setInitialData(selectionTree);
        } else {
          updateSelectionData(selectedParentID, selectionTree.rows, originalData.rows);
          setTreeData(formatTreeData(originalData));
        }
      } else {
        setInitialData(selectionTree);
      }
    }
  }, [selectionTree]);

  const setInitialData = (data) => {
    setTreeData(formatTreeData(data));
    setOriginalData(data);
  };

  const updateSelectionData = (prop, value, obj) => {
    obj.constructor === Object &&
      Object.keys(obj).forEach((key) => {
        if (key === prop) {
          obj[key].rows = value;
        } else if (typeof obj[key].rows !== 'undefined') {
          updateSelectionData(prop, value, obj[key].rows);
        }
      });
  };

  const formatTreeData = (data) => {
    const result = [];
    const { rows } = data;
    let item = {};
    let rowData = {};
    for (const i in rows) {
      rowData = rows[i].data;
      item = {
        key: i,
        id: i,
        index: rows[i].RcID,
        label: rowData.hasOwnProperty('Description') ? rowData.Description : rowData.ObjtDesc,
        hasRows: Object.keys(rows[i].rows).length > 0,
        hasNodes: true,
        clickable: i.split('-').length <= 9,
        nodes:
          Object.keys(rows[i].rows).length > 0
            ? formatTreeData(rows[i])
            : [{ key: 'temp', label: '' }],
      };

      result.push(item);
    }
    return result;
  };

  const resetSelection = () => {
    formData.selection = initialSelection;
    setSelectedParentID(0);
    setFormData({ ...formData, selection: initialSelection });
  };

  const evaluateSelection = (itemProps) => {
    const selections = formData.selection;
    const indexes = itemProps.id.replace('pId-', '');
    const ids =
      indexes.length > 0 ? indexes.split('-') : itemProps.index !== '0' ? [itemProps.index] : [];

    for (let i = 0; i < Object.keys(selections).length; i++) {
      if (i < ids.length) {
        selections[`type${i}`] = ids[i].replace('pId-').replace('/');
      } else {
        selections[`type${i}`] = 0;
      }
    }
    setFormData({ ...formData, selection: selections });
  };

  const onNodeClick = (e, itemProps) => {
    if (typeof e.target.className !== 'object') {
      evaluateSelection(itemProps);
      getData(formData);
    }
  };

  const toggleItem = (isOpen, itemProps, label) => {
    if (
      itemProps.id !== 'pId-0' &&
      !itemProps.hasRows &&
      !isOpen &&
      itemProps.clickable &&
      label !== 'Not Available'
    ) {
      setSelectedParentID(itemProps.id);
      evaluateSelection(itemProps);
      getSelectionTree(formData);
    }

    return true;
  };

  const openErrorModal = (message) => {
    setErrorMessage(message);
    setShowErrorModal(true);
  };

  const onSubmit = () => {
    const startDate = moment(formData.periodStart, DATE_FORMAT.standard);
    const endDate = moment(formData.periodEnd, DATE_FORMAT.standard);

    if (startDate.isValid() && endDate.isValid()) {
      if (startDate <= endDate) {
        resetSelection();
        getSelectionTree(formData);
        getData(formData);
      } else {
        openErrorModal(MESSAGE.dateComparison);
      }
    } else {
      openErrorModal(MESSAGE.invalidDate);
    }
  };

  const formatDate = (value) => {
    let date = '';

    if (value) {
      date = dateformat(moment(value), businessLogicDateFormat).replace(
        /([+-]\d\d)(\d\d)$/,
        '$1:$2',
      );
    }

    return date;
  };

  const gateDate = (value) => {
    const date = moment(value).format('YYYY-MM-DD');
    return date;
  };

  return (
    <SideBar gap="xsmall">
      {pageID === SetUpPageID.advanceLogTransactions ? (
        <Box direction="column" pad={pad} margin={{ bottom: '38px' }}>
          <Box pad="10px" fill="horizontal">
            <Text textAlign="left">Date Range</Text>
          </Box>
          <Box pad="10px" fill="horizontal">
            <Text textAlign="left">Start</Text>
            <MaterialTextField
              type="date"
              defaultValue={gateDate(formData.periodStart)}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => {
                setFormData({
                  ...formData,
                  periodStart: formatDate(event.target.value),
                });
              }}
            />
          </Box>
          <Box pad="10px" fill="horizontal">
            <Text textAlign="left">End</Text>
            <MaterialTextField
              type="date"
              defaultValue={gateDate(formData.periodEnd)}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => {
                setFormData({
                  ...formData,
                  periodEnd: formatDate(event.target.value),
                });
              }}
            />
          </Box>
          <ButtonMenu
            type="button"
            label="Refresh"
            alignSelf="center"
            style={{ marginTop: '0.5em' }}
            onClick={onSubmit}
          />
        </Box>
      ) : null}
      <Box basis="368px" style={{ overflow: 'auto' }}>
        {treeData && treeData.length > 0 ? (
          <Table style={{ width: '100%' }} className="font-14">
            {pageID === SetUpPageID.advanceLogTransactions && (
              <TableHeader>
                <TableRow>
                  <TableCell />
                  <TableCell>Description</TableCell>
                </TableRow>
              </TableHeader>
            )}
            <TableBody>
              <TableRow>
                <TableCell colSpan="2">
                  <Box fill="horizontal">
                    <TreeMenu data={treeData} hasSearch={false}>
                      {({ items }) => (
                        <>
                          {items.map(({ key, ...props }) => {
                            return <ListItem key={key} {...props} />;
                          })}
                        </>
                      )}
                    </TreeMenu>
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        ) : null}
      </Box>
    </SideBar>
  );
};

const mapStateToProps = createStructuredSelector({
  currentCustomer: selectCurrentCustomer,
});
export default connect(mapStateToProps, null)(SideBarControls);

// import DatePicker from "react-datepicker";
import { Button, Table, TableBody, TableCell, TableHeader, TableRow } from 'grommet';
import { CaretDown, CaretNext } from 'grommet-icons';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { cssRules } from '@ThemeMain';

const TreeTable = styled(Table)`
  max-height: 550px;
  white-space: nowrap;
  th {
    ${cssRules.ellipsis}
  }

  thead {
    tr {
      td {
        position: sticky;
        top: 0;
        background: #fff;
        z-index: 9;
        box-shadow: 1px 1px 0 #6c82c9;
      }
    }
  }

  /*
  thead {
    tr {
      td:first-child {
        div {
          width: 300px;
        }
      }

      td:not(:first-child) {
        div {
          width: 150px;
        }
      }
    }
  }
  */

  tbody {
    tr {
      border-bottom: solid 1px rgba(0, 0, 0, 0.33);
    }
  }
`;

const TreeCell = styled(TableCell)``;

const ReportsTreeTable = (props) => {
  const {
    reportsTableData,
    selectionTree,
    getCurrentTreeSelection,
    updateSelection,
    selectedTreeIds,
    setDisplayedRows,
    displayedRows,
    shouldResetTree,
    setShouldResetTree,
    setSelectedTreeIds,
    selectedNode,
    setSelectedNode,
  } = props;
  // const [selectedNode, setSelectedNode] = React.useState(0);
  const [prevSelected, setPrevSelected] = React.useState({ rows: {} });
  const [prevSelectedTreeIds, setPrevSelectedTreeIds] = React.useState(selectedTreeIds);
  const [visibleRows, setvisibleRows] = React.useState(displayedRows);
  const maxNodeCount = 8; // Node count limit of the selection tree
  let headerFormaters = {};

  useEffect(() => {
    setPrevSelectedTreeIds(selectedTreeIds);
    setvisibleRows(displayedRows);
  }, [selectedTreeIds, displayedRows]);

  // TODO: [LH-280] When button is clicked for submitting the form, the selection tree
  // should completely reset before handling new data that's going to come in
  const resetTree = () => {
    // setSelectedNode(0);
    // setPrevSelected({ rows: {} });
    setSelectedTreeIds(['pId-0']);
    // setPrevSelectedTreeIds(['pId-0'] ); // TODO: also clear the passed in selected tree ids
    setDisplayedRows(['']);
    // setvisibleRows(['']); // TODO: also clear the passed in visible rows

    // Prevent infinite loop
    setShouldResetTree(false);
  };

  if (shouldResetTree) {
    resetTree();
  }

  // const updateTableData = (prop, value, obj) => {
  // obj.constructor === Object &&
  //   Object.keys(obj).forEach((key) => {
  //     if (key === prop) {
  //       obj[key].rows = value;
  //       updateSelection(obj);
  //       evaluateChildren(value);
  //     } else {
  //       updateTableData(prop, value, obj[key]);
  //     }
  //   });
  // };

  // const evaluateChildren = (rows) => {
  //   const selectedIds = prevSelectedTreeIds;
  //   for (const i in rows) {
  //     if (!selectedIds.includes(i)) {
  //       selectedIds.push(i);
  //       setPrevSelectedTreeIds(selectedIds);
  //     }
  //   }
  // };

  // const searchForSelectionFromRoot = (rows) => {
  //   let idLinks = [];
  //   const resultIds = [];
  //   idLinks = searchForSelectionChild(rows);

  //   updateTableData(idLinks);
  // };

  // const searchForSelectionChild = (rows) => {
  //   let idLinks = [];
  //   const resultIds = [];
  //   for (const i in rows) {
  //     idLinks = ['rows'];
  //     idLinks.push(i);
  //     if (i !== selectedNode) {
  //       if (Object.keys(rows[i].rows).length > 0) {
  //         idLinks.concat(searchForSelectionChild(rows[i].rows));
  //       }
  //     } else {
  //       return idLinks;
  //     }
  //   }
  // };

  // if (selectionTree !== null && reportsTableData !== null && selectedNode !== 'pId-0') {
  //   if (prevSelected !== null && !(selectionTree instanceof Array)) {
  //     if (Object.keys(prevSelected.rows)[0] !== Object.keys(selectionTree.rows)[0]) {
  //       updateTableData(selectedNode, selectionTree.rows, selectionTree);
  //     }
  //   } else if (prevSelected === null) {
  //     updateTableData(selectedNode, selectionTree.rows, selectionTree);
  //   }
  // }

  const toggle = (id) => {
    const pID = id.split('-');
    let children = document.querySelectorAll(`[class^="${id}"],[class*=" ${id}"]`);
    const caretDown = document.getElementById(`cdown-${id}`);
    const caretUp = document.getElementById(`cnext-${id}`);
    let displayed = [...visibleRows];
    setPrevSelected(selectionTree);
    setSelectedNode(id);
    pID.shift();

    if (pID.length >= maxNodeCount) {
      return;
    }

    if (displayed.indexOf(id) === -1) {
      displayed = evaluateOpenNodes(displayed, id);
      displayed.push(id);
    }

    if (caretDown.style.display === 'inline-block') {
      Array.from(children).forEach((item) => {
        if (item !== null) {
          item.style.display = 'none';
          const index = displayed.indexOf(item.id);
          if (index > -1) {
            displayed.splice(displayed.indexOf(item.id), 1);
          }
        }
      });
      caretDown.style.display = 'none';
      caretUp.style.display = 'inline-block';

      const ids = id.split('-');
      ids.length > 2 && ids.splice(ids.length - 1, 1) && setSelectedNode(ids.join('-', ids));
    } else {
      children = document.getElementsByClassName(id);
      Array.from(children).forEach((item) => {
        if (item !== null) {
          item.style.display = 'table-row';
        }
      });
      caretDown.style.display = 'inline-block';
      caretUp.style.display = 'none';
    }

    setvisibleRows(displayed);

    const previousIds = prevSelectedTreeIds;
    if (!prevSelectedTreeIds.includes(id)) {
      const hasNoChildren = children.length === 0;
      getCurrentTreeSelection({ id, hasNoChildren });

      previousIds.push(id);
      setPrevSelectedTreeIds(previousIds);
    }
  };

  const evaluateOpenNodes = (openNodes, currentNode) => {
    const nodes = JSON.parse(JSON.stringify(openNodes));
    const keyOrigin = currentNode.substr(0, currentNode.lastIndexOf('-'));
    let withinLength = false;
    for (const i in nodes) {
      withinLength = nodes[i].split('-').length >= currentNode.split('-').length;

      if (
        (!keyOrigin.includes(nodes[i]) || (nodes[i].includes(keyOrigin) && withinLength)) &&
        nodes[i] !== 'pId-0'
      ) {
        openNodes.splice(openNodes.indexOf(nodes[i]), 1);
      }
    }

    return openNodes;
  };

  const getData = (data, marginLeft = 0, display = '', parentID = '') => {
    let tableRows = [];
    const cols = [];
    if (data.header) {
      headerFormaters = data.header;
    }

    if (data.rows !== undefined && data.rows !== null) {
      const { rows } = data;
      Object.entries(rows).forEach(([index, rowData]) => {
        let displayString = 'table-row';
        if (!visibleRows.includes(parentID)) {
          if (!parentID === false) displayString = 'none';
        }

        tableRows.push(
          <TableRow
            id={index}
            key={`${rowData.parentID}_${index}`}
            className={rowData.parentID !== '' ? parentID : ''}
            style={{ display: displayString }}
          >
            {getColumnData(index, rowData, marginLeft)}
          </TableRow>,
        );

        if (Object.keys(rowData.rows).length > 0) {
          tableRows = tableRows.concat(getData(rowData, marginLeft + 10, 'none', index));
        }
      });
    }
    return tableRows;
  };

  const getColumnData = (rowId, columns, marginLeft) => {
    const colData = [];
    const inlineBlock = 'inline-block';

    Object.entries(columns.data).forEach(([key, entry]) => {
      if (key !== 'RcID') {
        if (key === 'Description') {
          colData.push(
            <TreeCell key={`${rowId}_${key}_${entry}`}>
              <Button
                style={{ marginLeft: `${marginLeft}px` }}
                type="button"
                onClick={() => toggle(rowId)}
              >
                {(!columns.hasOwnProperty('hasChildren') || columns.hasChildren) && (
                  <>
                    <CaretNext
                      id={`cnext-${rowId}`}
                      size="small"
                      style={{ display: visibleRows.includes(rowId) ? 'none' : inlineBlock }}
                    />
                    <CaretDown
                      id={`cdown-${rowId}`}
                      size="small"
                      style={{ display: visibleRows.includes(rowId) ? inlineBlock : 'none' }}
                    />
                  </>
                )}
                {entry}
              </Button>
            </TreeCell>,
          );
        } else {
          let moddedEntry = entry;
          if (!isNaN(moddedEntry) && headerFormaters[key]) {
            // Undefined means the browser will choose the locale
            moddedEntry = parseFloat(entry).toLocaleString(
              undefined,
              headerFormaters[key].formatMask || {},
            );
          }
          if (
            headerFormaters[key] &&
            headerFormaters[key].formatMask &&
            headerFormaters[key].formatMask.unitToUse
          ) {
            moddedEntry += headerFormaters[key].formatMask.unitToUse;
          }
          colData.push(<TreeCell key={`${rowId}_${key}_${entry}`}>{moddedEntry}</TreeCell>);
        }
      }
    });

    if (Object.keys(columns.data).length === 1 && selectionTree.header) {
      const selectionTreeHeaderLength =
        Object.keys(selectionTree.header).length || selectionTree.header.length;
      colData.push(<TreeCell key="endingColSpan" colSpan={selectionTreeHeaderLength - 1} />);
    }

    return colData;
  };

  const getHeader = (headers) => {
    const tableHeaders = [];

    if (headers !== undefined && headers !== null) {
      Object.entries(headers).forEach(([key, item]) => {
        if (!item.isHidden) {
          tableHeaders.push(<TreeCell key={key}>{key}</TreeCell>);
        }
      });
    }

    return tableHeaders;
  };

  return (
    <TreeTable className="printableTreeTable">
      <TableHeader>
        <TableRow>{selectionTree !== null ? getHeader(selectionTree.header) : null}</TableRow>
      </TableHeader>
      <TableBody>{selectionTree !== null ? getData(selectionTree) : null}</TableBody>
    </TreeTable>
  );
};

export default connect()(ReportsTreeTable);

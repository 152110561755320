import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import api from '@API';

import { selectAdvancedCurrentUsers } from '../setup-advanced-users/setup-advanced-users.selectors';
import {
  getOrderSelectedFailure,
  getOrderSelectedStart,
  getOrderSelectedSuccess,
  pushOrderToAddQueueFailure,
  pushOrderToAddQueueSuccess,
  pushOrderToDeleteQueueFailure,
  pushOrderToDeleteQueueSuccess,
  saveOrderSelectedFailure,
  saveOrderSelectedSuccess,
} from './setup-users-order-selected-in-user.actions';
import SetupUsersOrderSelectedActionTypes from './setup-users-order-selected-in-user.types';

const orderSelectedApi = (params) => {
  return api.post('security/get-orders-selected-in-user', params);
};

export function* getOrderSelected(action) {
  try {
    const currentUserInfo = yield select(selectAdvancedCurrentUsers);
    if (currentUserInfo) {
      const params = { iUserID: currentUserInfo.RcID };
      const { data } = yield call(orderSelectedApi, params);
      yield put(getOrderSelectedSuccess(data.data));
    }
  } catch (error) {
    yield put(getOrderSelectedFailure(error));
  }
}

const saveOrderSelectedApi = (params) => {
  return api.post('security/add-order-to-user', params);
};

export function* saveOrderSelected(action) {
  try {
    const currentUserInfo = yield select(selectAdvancedCurrentUsers);
    if (currentUserInfo) {
      const params = { iUserID: currentUserInfo.RcID };
      params.orderInfo = action.payload;
      const { data } = yield call(saveOrderSelectedApi, params);
      yield put(saveOrderSelectedSuccess(data.data));
      yield put(getOrderSelectedStart());
    }
  } catch (error) {
    yield put(saveOrderSelectedFailure(error));
  }
}

export function* pushOrderToDeleteQueue(action) {
  try {
    yield put(pushOrderToDeleteQueueSuccess(action.payload));
  } catch (error) {
    yield put(pushOrderToDeleteQueueFailure(error));
  }
}

export function* pushOrderToAddQueue(action) {
  try {
    yield put(pushOrderToAddQueueSuccess(action.payload));
  } catch (error) {
    yield put(pushOrderToAddQueueFailure(error));
  }
}

export function* onGetOrderSelectedStart() {
  yield takeLatest(SetupUsersOrderSelectedActionTypes.GET_ORDER_SELECTED_START, getOrderSelected);
}

export function* onSaveOrderSelectedStart() {
  yield takeLatest(SetupUsersOrderSelectedActionTypes.SAVE_ORDER_SELECTED_START, saveOrderSelected);
}

export function* onPushOrderToDeleteQueueStart() {
  yield takeLatest(
    SetupUsersOrderSelectedActionTypes.PUSH_ORDER_TO_DELETE_QUEUE_START,
    pushOrderToDeleteQueue,
  );
}

export function* onPushOrderToAddQueueStart() {
  yield takeLatest(
    SetupUsersOrderSelectedActionTypes.PUSH_ORDER_TO_ADD_QUEUE_START,
    pushOrderToAddQueue,
  );
}

export function* advancedUserOrderSelectedCustomer() {
  yield all([
    call(onGetOrderSelectedStart),
    call(onSaveOrderSelectedStart),
    call(onPushOrderToDeleteQueueStart),
    call(onPushOrderToAddQueueStart),
  ]);
}

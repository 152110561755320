import { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import NavBox from '@Components/nav-box/nav-box.component';
import { getAccountsStart, saveAccountsStart } from '@State/setup-accounts/setup-accounts.actions';
import {
  addItemToOrderStart,
  addProductToPackStart,
  addProductToProcedureStart,
  getCustomerAccountsStart,
  getDepartmentsAcctTypeStart,
  getDepartmentsUsedForCustomerStart,
  getHandheldNumbersStart,
  getInventoryFlowStart,
  getItemAccountsStart,
  getItemsNotSelectedInOrderStart,
  getItemsSelectedInOrderStart,
  getOrdersUsedForCustomerStart,
  getOrderTypesStart,
  getPacksUsedForCustomerStart,
  getPageOfCustomersStart,
  getProceduresUsedForCustomerStart,
  getProductsNotSelectedInPackStart,
  getProductsNotSelectedInProcedureStart,
  getProductsSelectedInPackStart,
  getProductsSelectedInProcedureStart,
  getProductsUsedForCustomerStart,
  getSnapshotStart,
  inactivateStart,
  updateCustomersStart,
  updateDepartmentsUsedForCustomerStart,
  updateItemsSelectedAndNotSelectedInOrderStart,
  updateOrdersUsedForCustomerStart,
  updatePacksUsedForCustomerStart,
  updateProceduresUsedForCustomerStart,
  updateProductsSelectedAndNotSelectedInPackStart,
  updateProductsSelectedAndNotSelectedInProcedureStart,
  updateProductsUsedForCustomerStart,
  validateDeletionStart,
} from '@State/setup/setup.actions';
import {
  selectDepartmentsUsedForCustomer,
  selectHandheldNumbers,
  selectInventoryFlow,
  selectItemAccounts,
  selectOrderTypes,
  selectPacksUsedForCustomer,
  selectPageOfCustomers,
  selectProceduresUsedForCustomer,
} from '@State/setup/setup.selectors';
import { selectCurrentCustomer, selectPrivileges } from '@State/user/user.selectors';
import { Page } from '@ThemeMain';

import { SetUpNav, SetUpPageID, tableConfig } from './setup-config';
import SetUpPanel from './setup-panel.component';

const SetUpPage = (props) => {
  const {
    currentCustomer,
    userPrivileges,
    activeNav,
    /** Packs * */
    getPacksUsedForCustomer,
    getProductsSelectedInPack,
    getProductsNotSelectedInPack,
    updatePacksUsedForCustomer,
    updateProductsSelectedAndNotSelectedInPack,
    addProductToPack,
    /** Procedures * */
    getProceduresUsedForCustomer,
    getProductsSelectedInProcedure,
    getProductsNotSelectedInProcedure,
    updateProceduresUsedForCustomer,
    updateProductsSelectedAndNotSelectedInProcedure,
    addProductToProcedure,
    /** Procedures * */
    getOrdersUsedForCustomer,
    getItemsSelectedInOrder,
    getItemsNotSelectedInOrder,
    updateOrdersUsedForCustomer,
    updateItemsSelectedAndNotSelectedInOrder,
    addItemToOrder,
    getPageOfCustomers,
    updateCustomers,
    getCustomerAccounts,
    /** Accounts * */
    getAccounts,
    saveAccounts,
    /** Departments * */
    getDepartmentsUsedForCustomer,
    getDepartmentsAcctType,
    updateDepartmentsUsedForCustomer,
    /** Products * */
    getProductsUsedForCustomer,
    updateProductsUsedForCustomer,
    /** Common * */
    getHandheldNumbers,
    getInventoryFlow,
    getOrderTypes,
    getItemAccounts,
    validateDeletion,
    inactivate,
    getSnapshot,
  } = props;

  useEffect(() => {
    switch (activeNav) {
      case SetUpPageID.packs:
        getPacksUsedForCustomer({ customerID: currentCustomer.RcID });
        getItemAccounts();
        break;
      case SetUpPageID.procedures:
        getProceduresUsedForCustomer({ customerID: currentCustomer.RcID });
        getItemAccounts();
        break;
      case SetUpPageID.orders:
        getOrdersUsedForCustomer({ customerID: currentCustomer.RcID });
        getDepartmentsUsedForCustomer({ customerID: currentCustomer.RcID });
        getItemAccounts();
        getHandheldNumbers();
        getInventoryFlow();
        getOrderTypes();
        break;
      case SetUpPageID.advanceCustomers:
        getPageOfCustomers({ startRow: 0, fetchRowCount: 1000 });
        getCustomerAccounts();
        break;
      case SetUpPageID.departments:
        getDepartmentsUsedForCustomer({ customerID: currentCustomer.RcID });
        getDepartmentsAcctType();
        break;
      case SetUpPageID.products:
        getProductsUsedForCustomer({ customerID: currentCustomer.RcID });
        getItemAccounts();
        break;
      case SetUpPageID.customerAccounts:
        getAccounts({ accountType: 'CUSTOMER' });
        break;
      case SetUpPageID.itemAccounts:
        getAccounts({ accountType: 'ITEM' });
        break;
      case SetUpPageID.departmentAccounts:
        getAccounts({ accountType: 'DEPARTMENT' });
        break;
      default:
        break;
    }
  }, [activeNav, currentCustomer]);

  if (userPrivileges && userPrivileges.canViewSetup) {
    let currentNav = null;
    let pageTitle = '';
    let type = '';
    let canModify = false;
    let canView = false;
    let updateMainData = () => {};
    let updateRightTableData = () => {};
    let getMainData = () => {};
    let getRightTableData = () => {};
    let getProducts = () => {};
    let addProducts = () => {};

    switch (activeNav) {
      case SetUpPageID.main:
        currentNav = SetUpNav.mainNav;
        break;
      case SetUpPageID.departments:
        if (userPrivileges && userPrivileges.canViewDepartments) {
          pageTitle = 'Departments';
          type = 'departments';
          currentNav = null;
          canModify = userPrivileges.canModifyDepartments;
          updateMainData = (data) => updateDepartmentsUsedForCustomer(data);
          getMainData = (data) => getDepartmentsUsedForCustomer(data);
        } else {
          currentNav = SetUpNav.mainNav;
        }
        break;
      case SetUpPageID.products:
        if (userPrivileges && userPrivileges.canViewProducts) {
          pageTitle = 'Products';
          type = 'products';
          currentNav = null;
          canModify = userPrivileges.canModifyProducts;
          updateMainData = (data) => updateProductsUsedForCustomer(data);
          getMainData = (data) => getProductsUsedForCustomer(data);
        } else {
          currentNav = SetUpNav.mainNav;
        }
        break;
      case SetUpPageID.accounts:
        if (userPrivileges && userPrivileges.canViewAccounts) {
          pageTitle = 'Accounts';
          type = 'Accounts';
          currentNav = SetUpNav.accountsNav;
        } else {
          currentNav = SetUpNav.mainNav;
        }
        break;
      case SetUpPageID.customerAccounts:
        if (userPrivileges && userPrivileges.canViewAccounts) {
          pageTitle = 'Customer Account Type';
          type = 'customerAccounts';
          currentNav = null;
          canModify = userPrivileges.canModifyAccounts;
          updateMainData = (data) => saveAccounts(data);
          getMainData = () => getAccounts({ accountType: tableConfig[type].accountType });
        } else {
          currentNav = SetUpNav.mainNav;
        }
        break;
      case SetUpPageID.itemAccounts:
        if (userPrivileges && userPrivileges.canViewAccounts) {
          pageTitle = 'Product Account Type';
          type = 'itemAccounts';
          currentNav = null;
          canModify = userPrivileges.canModifyAccounts;
          updateMainData = (data) => saveAccounts(data);
          getMainData = () => getAccounts({ accountType: tableConfig[type].accountType });
        } else {
          currentNav = SetUpNav.mainNav;
        }
        break;
      case SetUpPageID.departmentAccounts:
        if (userPrivileges && userPrivileges.canViewAccounts) {
          pageTitle = 'Department Account Type';
          type = 'departmentAccounts';
          currentNav = null;
          canModify = userPrivileges.canModifyAccounts;
          updateMainData = (data) => saveAccounts(data);
          getMainData = () => getAccounts({ accountType: tableConfig[type].accountType });
        } else {
          currentNav = SetUpNav.mainNav;
        }
        break;
      case SetUpPageID.advanced:
        if (userPrivileges && userPrivileges.isAdminUser) {
          pageTitle = 'Advance';
          type = 'Advance';
          currentNav = SetUpNav.advanceNav;
        } else {
          currentNav = SetUpNav.mainNav;
        }
        break;
      case SetUpPageID.advanceCustomers:
        if (userPrivileges && userPrivileges.isAdminUser) {
          pageTitle = 'Customers';
          type = 'advanceCustomers';
          currentNav = null;
          canModify = userPrivileges.canModifyCustomers;
          updateMainData = (data) => updateCustomers(data);
          getMainData = (data) => getPageOfCustomers(data);
        } else {
          currentNav = SetUpNav.mainNav;
        }
        break;
      case SetUpPageID.packs:
        if (userPrivileges && userPrivileges.canViewPacks) {
          pageTitle = 'Packs';
          type = 'packs';
          currentNav = null;
          canModify = userPrivileges.canModifyPacks;
          updateMainData = (data) => updatePacksUsedForCustomer(data);
          updateRightTableData = (data) => updateProductsSelectedAndNotSelectedInPack(data);
          getMainData = (data) => getPacksUsedForCustomer(data);
          getRightTableData = (data) => getProductsSelectedInPack(data);
          getProducts = (data) => getProductsNotSelectedInPack(data);
          addProducts = (data) => addProductToPack(data);
        } else {
          currentNav = SetUpNav.mainNav;
        }
        break;
      case SetUpPageID.procedures:
        if (userPrivileges && userPrivileges.canViewProducts) {
          pageTitle = 'Procedures';
          type = 'procedures';
          currentNav = null;
          canModify = userPrivileges.canModifyProcedures;
          updateMainData = (data) => updateProceduresUsedForCustomer(data);
          updateRightTableData = (data) => updateProductsSelectedAndNotSelectedInProcedure(data);
          getMainData = (data) => getProceduresUsedForCustomer(data);
          getRightTableData = (data) => getProductsSelectedInProcedure(data);
          getProducts = (data) => getProductsNotSelectedInProcedure(data);
          addProducts = (data) => addProductToProcedure(data);
        } else {
          currentNav = SetUpNav.mainNav;
        }
        break;
      case SetUpPageID.orders:
        if (userPrivileges && userPrivileges.canViewOrders) {
          pageTitle = 'Orders';
          type = 'orders';
          currentNav = null;
          canModify = userPrivileges.canModifyOrders;
          canView = userPrivileges.canViewOrders;
          updateMainData = (data) => updateOrdersUsedForCustomer(data);
          updateRightTableData = (data) => updateItemsSelectedAndNotSelectedInOrder(data);
          getMainData = (data) => getOrdersUsedForCustomer(data);
          getRightTableData = (data) => getItemsSelectedInOrder(data);
          getProducts = (data) => getItemsNotSelectedInOrder(data);
          addProducts = (data) => addItemToOrder(data);
        } else {
          currentNav = SetUpNav.mainNav;
        }
        break;
      default:
        currentNav = SetUpNav.mainNav;
        break;
    }

    if (currentNav !== null) {
      return (
        <Page>
          <NavBox
            currentCustomer={currentCustomer}
            fill={false}
            navMap={currentNav}
            userPrivileges={userPrivileges}
          />
        </Page>
      );
    }
    return (
      <Page>
        <SetUpPanel
          currentCustomer={currentCustomer}
          pageTitle={pageTitle}
          pageID={activeNav}
          type={type}
          canModify={canModify}
          canView={canView}
          updateMainData={(data) => updateMainData(data)}
          updateRightTableData={(data) => updateRightTableData(data)}
          getMainData={(data) => getMainData(data)}
          getRightTableData={(data) => getRightTableData(data)}
          getProducts={(data) => getProducts(data)}
          addProducts={(data) => addProducts(data)}
          validateDeletion={(data) => validateDeletion(data)}
          inactivate={(data) => inactivate(data)}
          getSnapshot={(data) => getSnapshot(data)}
        />
      </Page>
    );
  }
  return null;
};

const mapStateToProps = createStructuredSelector({
  currentCustomer: selectCurrentCustomer,
  userPrivileges: selectPrivileges,
  packsUsedForCustomer: selectPacksUsedForCustomer,
  proceduresUsedForCustomer: selectProceduresUsedForCustomer,
  itemAccounts: selectItemAccounts,
  departmentsUsedForCustomer: selectDepartmentsUsedForCustomer,
  handheldNumbers: selectHandheldNumbers,
  inventoryFlow: selectInventoryFlow,
  orderTypes: selectOrderTypes,
  pageOfCustomers: selectPageOfCustomers,
});

const mapDispatchToProps = (dispatch) => ({
  /** Packs */
  getPacksUsedForCustomer: (data) => dispatch(getPacksUsedForCustomerStart(data)),
  getProductsSelectedInPack: (data) => dispatch(getProductsSelectedInPackStart(data)),
  getProductsNotSelectedInPack: (data) => dispatch(getProductsNotSelectedInPackStart(data)),
  updatePacksUsedForCustomer: (data) => dispatch(updatePacksUsedForCustomerStart(data)),
  updateProductsSelectedAndNotSelectedInPack: (data) =>
    dispatch(updateProductsSelectedAndNotSelectedInPackStart(data)),
  addProductToPack: (data) => dispatch(addProductToPackStart(data)),
  /** Procedures */
  getProceduresUsedForCustomer: (data) => dispatch(getProceduresUsedForCustomerStart(data)),
  getProductsSelectedInProcedure: (data) => dispatch(getProductsSelectedInProcedureStart(data)),
  getProductsNotSelectedInProcedure: (data) =>
    dispatch(getProductsNotSelectedInProcedureStart(data)),
  updateProceduresUsedForCustomer: (data) => dispatch(updateProceduresUsedForCustomerStart(data)),
  updateProductsSelectedAndNotSelectedInProcedure: (data) =>
    dispatch(updateProductsSelectedAndNotSelectedInProcedureStart(data)),
  addProductToProcedure: (data) => dispatch(addProductToProcedureStart(data)),
  /** Orders */
  getOrdersUsedForCustomer: (data) => dispatch(getOrdersUsedForCustomerStart(data)),
  getItemsSelectedInOrder: (data) => dispatch(getItemsSelectedInOrderStart(data)),
  getItemsNotSelectedInOrder: (data) => dispatch(getItemsNotSelectedInOrderStart(data)),
  updateOrdersUsedForCustomer: (data) => dispatch(updateOrdersUsedForCustomerStart(data)),
  updateItemsSelectedAndNotSelectedInOrder: (data) =>
    dispatch(updateItemsSelectedAndNotSelectedInOrderStart(data)),
  addItemToOrder: (data) => dispatch(addItemToOrderStart(data)),
  /** Customers */
  getPageOfCustomers: (data) => dispatch(getPageOfCustomersStart(data)),
  updateCustomers: (data) => dispatch(updateCustomersStart(data)),
  getCustomerAccounts: (data) => dispatch(getCustomerAccountsStart(data)),
  /** Departments */
  getDepartmentsUsedForCustomer: (data) => dispatch(getDepartmentsUsedForCustomerStart(data)),
  getDepartmentsAcctType: (data) => dispatch(getDepartmentsAcctTypeStart(data)),
  updateDepartmentsUsedForCustomer: (data) => dispatch(updateDepartmentsUsedForCustomerStart(data)),
  /** Products * */
  getProductsUsedForCustomer: (data) => dispatch(getProductsUsedForCustomerStart(data)),
  updateProductsUsedForCustomer: (data) => dispatch(updateProductsUsedForCustomerStart(data)),
  /** Common * */
  getHandheldNumbers: (data) => dispatch(getHandheldNumbersStart(data)),
  getInventoryFlow: (data) => dispatch(getInventoryFlowStart(data)),
  getOrderTypes: (data) => dispatch(getOrderTypesStart(data)),
  getItemAccounts: (data) => dispatch(getItemAccountsStart(data)),
  validateDeletion: (data) => dispatch(validateDeletionStart(data)),
  inactivate: (data) => dispatch(inactivateStart(data)),
  getSnapshot: (data) => dispatch(getSnapshotStart(data)),
  /** Accounts * */
  getAccounts: (data) => dispatch(getAccountsStart(data)),
  saveAccounts: (data) => dispatch(saveAccountsStart(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SetUpPage);

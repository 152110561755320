import { Box } from 'grommet';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import NavBox from '@Components/nav-box/nav-box.component';
import {
  createInjectionForCustomerOnDateStart,
  createRetirementForCustomerOnDateStart,
  getInventoryForInventoryIDStart,
  getOrderScheduleForCustomerOnDateStart,
  setSelectedRcID,
  updateInventoryCountsStart,
} from '@State/inventory/inventory.actions';
import {
  selectCreateInjectionForCustomerOnDateResponse,
  selectCreateRetirementForCustomerOnDateResponse,
  selectInventoryForInventoryID,
  selectUpdateInventoryCountsResponse,
} from '@State/inventory/inventory.selectors';
import { selectCurrentCustomer, selectPrivileges } from '@State/user/user.selectors';
import { Page } from '@ThemeMain';

import { dateFormat, InventoryNav, InventoryPageID } from './inventory-config';
import InventoryPanel from './inventory-panel.component';

const InventoryPage = (props) => {
  const {
    currentCustomer,
    userPrivileges,
    activeNav,
    createInjectionForCustomerOnDateResponse,
    createRetirementForCustomerOnDateResponse,
    inventoryForInventoryID,
    createInjectionForCustomerOnDate,
    createRetirementForCustomerOnDate,
    getInventoryForInventoryID,
    getOrderScheduleForCustomerOnDate,
    updateInventoryCounts,
    updateInventoryCountsResponse,
    setSelectedRcID,
  } = props;
  const initialCreateParams = {
    custID: currentCustomer.RcID,
    customerID: currentCustomer.RcID,
    iCallerUTCOffset: 0,
    requestedDay: moment(new Date()).format(dateFormat.param),
    requestedDate: moment(new Date()).format(dateFormat.param),
  };
  const [onInitialize, setOnInitialize] = useState(true);

  useEffect(() => {
    setSelectedRcID(null);
    switch (activeNav) {
      case InventoryPageID.injection:
        createInjectionForCustomerOnDate(initialCreateParams);
        break;
      case InventoryPageID.retirement:
        createRetirementForCustomerOnDate(initialCreateParams);
        break;
      case InventoryPageID.physicalInventory:
        getOrderScheduleForCustomerOnDate(initialCreateParams);
        break;
      default:
        break;
    }
  }, [activeNav]);

  useEffect(() => {
    if (createInjectionForCustomerOnDateResponse !== null && onInitialize) {
      getInventoryForInventoryID({ inventoryID: createInjectionForCustomerOnDateResponse.data });
    }
  }, [createInjectionForCustomerOnDateResponse]);

  useEffect(() => {
    if (createRetirementForCustomerOnDateResponse !== null && onInitialize) {
      getInventoryForInventoryID({ inventoryID: createRetirementForCustomerOnDateResponse.data });
    }
  }, [createRetirementForCustomerOnDateResponse]);

  useEffect(() => {
    if (updateInventoryCountsResponse !== null && !onInitialize) {
      getInventoryForInventoryID({ inventoryID: inventoryForInventoryID.data.inventoryTable.RcID });
    }
  }, [updateInventoryCountsResponse]);

  if (userPrivileges && userPrivileges.canViewCountInventory) {
    let currentNav = null;
    let pageTitle = '';
    let type = '';
    let createDelivery = (data) => {};
    let getOrderSchedule = (data) => {};

    switch (activeNav) {
      case InventoryPageID.main:
        currentNav = InventoryNav.mainNav;
        break;
      case InventoryPageID.retirement:
        pageTitle = 'Inventory Retirement';
        type = 'Retirement';
        createDelivery = (data) => createRetirementForCustomerOnDate(data);
        currentNav = null;
        break;
      case InventoryPageID.injection:
        pageTitle = 'Inventory Injection';
        type = 'Injection';
        createDelivery = (data) => createInjectionForCustomerOnDate(data);
        currentNav = null;
        break;
      case InventoryPageID.physicalInventory:
        pageTitle = 'Physical Inventory';
        type = 'Physical Inventory';
        getOrderSchedule = (data) => getOrderScheduleForCustomerOnDate(data);
        currentNav = null;
        break;
      default:
        currentNav = null;
        break;
    }

    if (currentNav !== null) {
      return (
        <Page>
          <NavBox
            currentCustomer={currentCustomer}
            fill={false}
            navMap={currentNav}
            userPrivileges={userPrivileges}
          />
        </Page>
      );
    }

    return (
      <Page>
        <Box>
          <InventoryPanel
            currentCustomer={currentCustomer}
            userPrivileges={userPrivileges}
            pageTitle={pageTitle}
            pageID={activeNav}
            type={type}
            setOnInitialize={(data) => setOnInitialize(data)}
            updateInventoryCounts={(data) => updateInventoryCounts(data)}
            createDelivery={(data) => createDelivery(data)}
            getOrderSchedule={(data) => getOrderSchedule(data)}
          />
        </Box>
      </Page>
    );
  }
  return null;
};

const mapStateToProps = createStructuredSelector({
  currentCustomer: selectCurrentCustomer,
  userPrivileges: selectPrivileges,
  createInjectionForCustomerOnDateResponse: selectCreateInjectionForCustomerOnDateResponse,
  createRetirementForCustomerOnDateResponse: selectCreateRetirementForCustomerOnDateResponse,
  inventoryForInventoryID: selectInventoryForInventoryID,
  updateInventoryCountsResponse: selectUpdateInventoryCountsResponse,
});

const mapDispatchToProps = (dispatch) => ({
  createInjectionForCustomerOnDate: (data) => dispatch(createInjectionForCustomerOnDateStart(data)),
  createRetirementForCustomerOnDate: (data) =>
    dispatch(createRetirementForCustomerOnDateStart(data)),
  getInventoryForInventoryID: (data) => dispatch(getInventoryForInventoryIDStart(data)),
  getOrderScheduleForCustomerOnDate: (data) =>
    dispatch(getOrderScheduleForCustomerOnDateStart(data)),
  updateInventoryCounts: (data) => dispatch(updateInventoryCountsStart(data)),
  setSelectedRcID: (data) => dispatch(setSelectedRcID(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryPage);

import { Box, Button } from 'grommet';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import CustomCalendar from '@Components/custom-calendar/custom-calendar.component';
import SideBar from '@Components/sidebar/sidebar.component';
import { Messages } from '@Pages/count-linen/count-linen-config';
import { LabelBox } from '@Pages/count-linen/count-linen.styles';
import { dateFormat } from '@Pages/inventory/inventory-config';
import {
  selectInventoryForInventoryID,
  selectInventoryForInventoryIDPrevious,
} from '@State/inventory/inventory.selectors';
import { selectCurrentCustomer } from '@State/user/user.selectors';
import { Text16 } from '@ThemeMain';

const SideBarControl = (props) => {
  const {
    currentCustomer,
    pageID,
    type,
    updateInventoryCounts,
    createDelivery,
    getOrderSchedule,
    inventoryForInventoryID,
    inventoryForInventoryIDPrevious,
    setOnInitialize,
    setCurrentDate,
    onInitialize,
    hasChanges,
    setHasChanges,
  } = props;

  const [date, setDate] = useState(new Date());

  // When current customer changes, resubmit the form with
  // new customer info
  useEffect(() => {
    if (date !== null && inventoryForInventoryIDPrevious.data && inventoryForInventoryID.data) {
      onDateChange(date);
    }
  }, [currentCustomer]);

  const datesNotEqual = (current, prev) => {
    return current.format('YYYY-MM-DD') !== prev.format('YYYY-MM-DD');
  };

  const onDateChange = (value) => {
    const currentDate = moment(value).clone();
    const previousDate = moment(date).clone();
    setDate(value);

    if (
      (hasChanges &&
        datesNotEqual(currentDate, previousDate) &&
        window.confirm(Messages.unSaveChanges)) ||
      (!hasChanges && datesNotEqual(currentDate, previousDate))
    ) {
      setCurrentDate(value);
      setOnInitialize(true);
      setHasChanges(false);

      const params = {
        requestedDay: moment(value).format(dateFormat.param),
        requestedDate: moment(value).format(dateFormat.param),
        custID: currentCustomer.RcID,
        customerID: currentCustomer.RcID,
        iCallerUTCOffset: 0,
      };

      if (pageID === 'physical-inventory') {
        getOrderSchedule(params);
      } else {
        createDelivery(params);
      }
    } else {
      setTimeout(() => {
        setDate(previousDate._i);
      }, 200);
    }
  };
  return (
    <SideBar {...props}>
      <LabelBox fill="horizontal">
        <Text16>
          Inventory Date
          {moment(date).format(dateFormat.default)}
        </Text16>
      </LabelBox>
      <Box fill="horizontal">
        <CustomCalendar value={date} onChange={(date) => onDateChange(date)} />
      </Box>
      <Button
        alignSelf="center"
        label={`Go to Today: ${moment(Date.now()).format('MM/DD/YYYY')}`}
        onClick={() => onDateChange(new Date())}
      />
    </SideBar>
  );
};

const mapStateToProps = createStructuredSelector({
  currentCustomer: selectCurrentCustomer,
  inventoryForInventoryID: selectInventoryForInventoryID,
  inventoryForInventoryIDPrevious: selectInventoryForInventoryIDPrevious,
});

export default connect(mapStateToProps, null)(SideBarControl);

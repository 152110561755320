import SetupAccountActionTypes from './setup-accounts.types';

export const getAccountsStart = (data) => ({
  type: SetupAccountActionTypes.GET_ACCOUNTS_START,
  payload: data,
});

export const getAccountsSuccess = (account) => ({
  type: SetupAccountActionTypes.GET_ACCOUNTS_SUCCESS,
  payload: account,
});

export const getAccountsFailure = (error) => ({
  type: SetupAccountActionTypes.GET_ACCOUNTS_FAILURE,
  payload: error,
});

export const saveAccountsStart = (customer) => ({
  type: SetupAccountActionTypes.SAVE_ACCOUNTS_START,
  payload: customer,
});

export const saveAccountsSuccess = (data) => ({
  type: SetupAccountActionTypes.SAVE_ACCOUNTS_SUCCESS,
  payload: data,
});

export const saveAccountsFailure = (error) => ({
  type: SetupAccountActionTypes.getAccountsFailure,
  payload: error,
});

import { withRouter } from 'react-router-dom';

import NavButton from '@Components/nav-button/nav-button.component';

import { NavBoxInner, NavBoxOuter } from './nav-box.style';

const NavBox = ({ currentCustomer, fill, navMap, userPrivileges, showSubMenu }) => {
  if (currentCustomer) {
    if (navMap) {
      let hasSubMenus = false;

      const navTopLayer = navMap.map((navMapMenus) => {
        if (navMapMenus.permission && userPrivileges[navMapMenus.permission]) {
          if (navMapMenus.subMenu) {
            hasSubMenus = true;
            return (
              <NavBoxInner key={navMapMenus.label} label={navMapMenus.label}>
                {navMapMenus.subMenu.map((submenu, index) => {
                  return (
                    <NavButton
                      key={`${submenu.label}_${index}`}
                      label={submenu.label}
                      url={submenu.url}
                      icon={submenu.icon}
                    />
                  );
                })}
              </NavBoxInner>
            );
          }
          return (
            <NavButton
              key={navMapMenus.label}
              label={navMapMenus.label}
              url={navMapMenus.url}
              icon={navMapMenus.icon}
            />
          );
        }
        return null;
      });

      if (hasSubMenus) {
        return <NavBoxOuter fill={fill}>{navTopLayer}</NavBoxOuter>;
      }
      return (
        <NavBoxOuter fill={fill}>
          <NavBoxInner>{navTopLayer}</NavBoxInner>
        </NavBoxOuter>
      );
    }
    return null;
  }
  return null;
};
export default withRouter(NavBox);

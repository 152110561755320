import SetupAdvancedCustomerActionTypes from './setup-advanced-customers.types';

export const getAdvancedCustomerStart = () => ({
  type: SetupAdvancedCustomerActionTypes.GET_ADVANCED_CUSTOMER_START,
  payload: null,
});

export const getAdvancedCustomerSuccess = (advancedCustomer) => ({
  type: SetupAdvancedCustomerActionTypes.GET_ADVANCED_CUSTOMER_SUCCESS,
  payload: advancedCustomer,
});

export const getAdvancedCustomerFailure = (error) => ({
  type: SetupAdvancedCustomerActionTypes.GET_ADVANCED_CUSTOMER_FAILURE,
  payload: error,
});

import { NavBoxInner, NavBoxOuter } from '@Components/nav-box/nav-box.style';
import NavButton from '@Components/nav-button/nav-button.component';
import { Page } from '@ThemeMain';

const HelpPage = (props) => {
  const pdf = {
    documentUrl: 'https://linen-helper-help-documents.s3.amazonaws.com/Help/MenuDocument.pdf',
    whatsNewUrl: 'https://linennmastersupport.force.com/s/topic/0TO3i000000HylLGAS/release-notes',
  };

  const showPdf = (type) => {
    window.open(pdf[type], '_blank');
  };

  return (
    <Page>
      <NavBoxOuter>
        <NavBoxInner label="Help" labelAlign="center">
          <NavButton label="Document" onSelect={() => showPdf('documentUrl')} />
          <NavButton label="What's New" onClick={() => showPdf('whatsNewUrl')} />
        </NavBoxInner>
      </NavBoxOuter>
    </Page>
  );
};

export default HelpPage;

import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import api from '@API';

import { selectAdvancedCurrentUsers } from '../setup-advanced-users/setup-advanced-users.selectors';
import {
  getOrderNotSelectedFailure,
  getOrderNotSelectedSuccess,
} from './setup-users-order-not-selected-in-user.actions';
import SetupUsersOrderNotSelectedActionTypes from './setup-users-order-not-selected-in-user.types';

const orderNotSelectedApi = (params) => {
  return api.post('security/get-orders-not-selected-in-user', params);
};

export function* getOrderNotSelected(action) {
  try {
    const currentUserInfo = yield select(selectAdvancedCurrentUsers);
    if (currentUserInfo) {
      const params = { iUserID: currentUserInfo.RcID };
      const { data } = yield call(orderNotSelectedApi, params);
      yield put(getOrderNotSelectedSuccess(data.data));
    }
  } catch (error) {
    yield put(getOrderNotSelectedFailure(error));
  }
}

export function* onGetOrderNotSelectedStart() {
  yield takeLatest(
    SetupUsersOrderNotSelectedActionTypes.GET_ORDER_NOT_SELECTED_START,
    getOrderNotSelected,
  );
}

export function* advancedUserOrderNotSelectedCustomer() {
  yield all([call(onGetOrderNotSelectedStart)]);
}

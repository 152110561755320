import Color from 'color';
import { Box, Button, CheckBox, Form, Heading, TableCell, Text } from 'grommet';
import ReactSpeedometer from 'react-d3-speedometer';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { InputLabel, MenuItem } from '@material-ui/core';

import CustomCalendar from '@Components/custom-calendar/custom-calendar.component';
import {
  HeaderMenu,
  HeaderMenuItem,
  HeaderMenuLink,
  HeaderSubMenu,
} from '@Components/header/header.styles';
import { ModalInnerBody } from '@Components/modal/modal.style';
import NavBox from '@Components/nav-box/nav-box.component';
import {
  selectCurrentCustomer,
  selectCurrentUser,
  selectPrivileges,
} from '@State/user/user.selectors';
import {
  colors,
  measures,
  Page,
  PrimaryButton,
  ResizableColumnTable,
  Select,
  TextField,
  ThemeDataTable,
} from '@ThemeMain';

let styleGuideUniqueId = 0;
const getUniqueId = () => ++styleGuideUniqueId;

const DemoModalBody = styled(ModalInnerBody)`
  max-width: 400px;
  box-shadow: ${measures.dropShadow} ${colors.dropShadow};
`;

const StyleGuidePage = ({ currentUser, currentCustomer, userPrivileges }) => {
  const StyleElementTitle = styled((props) => (
    <Heading level="4" style={{ marginTop: '1em', marginBottom: '0.5em' }}>
      {props.title}
    </Heading>
  ))``;

  const StyleGroupBox = styled((props) => {
    const titleStyle = {
      fontSize: '1.2em',
      margin: '0.5em 0',
    };

    return (
      <Box
        align="stretch"
        fill={props && props.outerFill}
        style={(props && props.outerStyle) || undefined}
        id={`section_${props.title}`}
      >
        <Heading level="4" style={titleStyle}>
          {props.title}
        </Heading>
        <Box
          background={colors.innerBg}
          pad="medium"
          gap="medium"
          className="styleGroupContent"
          {...props}
        >
          {props.children}
        </Box>
      </Box>
    );
  })`
    .styleGroupContent > :first-child {
      margin-top: 0;
    }
  `;

  const StyleElementBox = styled((props) => {
    return (
      <Box {...props}>
        <StyleElementTitle title={props.title} />
        <Box
          direction={(props && props.direction) || 'column'}
          gap={(props && props.gap) || undefined}
          pad={(props && props.pad) || undefined}
          fill={(props && props.fill) || false}
          style={props && props.innerStyle}
        >
          {props.children}
        </Box>
      </Box>
    );
  })``;

  const dummyWords = ['text', 'example', 'style', 'guide'];

  const wordsLength = dummyWords.length;
  const getDummyWords = (maxWords) => {
    const outCount = 1 + Math.floor(Math.random() * (maxWords - 1));
    const outWords = [];
    for (let i = 0; i < outCount; i++) {
      outWords.push(dummyWords[Math.floor(Math.random() * wordsLength)]);
    }
    return outWords.join(' ');
  };

  const dummySelectData = [
    { value: '1', label: 'Default Option' },
    { value: '2', label: 'Something Else' },
    { value: '3', label: 'Another' },
    { value: '4', label: 'And Another' },
    { value: '5', label: 'Final' },
  ];
  const getDummySelect = () => (
    <Select defaultValue="1">
      {dummySelectData.map((option) => (
        <MenuItem key={getUniqueId()} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );

  const demoData = {
    websiteStructure:
      currentUser &&
      currentUser.websiteStructure &&
      currentUser.websiteStructure
        .slice(0, 1) // home
        .concat(
          currentUser.websiteStructure.slice(6, 7), // setup
        ),
    navMap: [
      {
        label: 'First Option',
        url: '#',
        permission: 'canViewReports',
      },
      {
        label: 'Second Option',
        url: '#',
        permission: 'canViewReports',
      },
      {
        label: 'Third Option',
        url: '#',
        permission: 'canViewReports',
      },
    ],
    resizeTableCell: {
      style: {
        minWidth: '400px' /* ensure that table data doesn't get crushed beyond recognition */,
      },
    },
    dataTable: {
      sortable: true,
      size: 'medium',
      columns: [
        { property: 'a', header: 'Description' },
        { property: 'b', header: 'First', render: getDummySelect },
        { property: 'c', header: 'Second' },
        { property: 'd', header: 'Third' },
      ],
      primaryKey: 'key',
      data: (() => {
        const data = [];
        for (let i = 0; i < 20; i++) {
          data.push({
            key: getUniqueId(),
            a: getDummyWords(4),
            b: 1,
            c: getDummyWords(1),
            d: getDummyWords(1),
          });
        }
        return data;
      })(),
    },
    treeTable: [],
    selectionTree: [],
    modal: {},
  };

  const getHeaderMenu = () => {
    const buildSubMenu = (index, urlPrefix, item, depth = 0) => {
      return (
        <HeaderSubMenu key={getUniqueId()} title="Dropdown">
          {item.submenus
            .filter((menuItem) => menuItem.dropdown)
            .map((innerItem, innerIndex) => {
              return innerItem.submenus && innerItem.submenus.length > 0
                ? buildSubMenu(
                    `${index}_${innerIndex}`,
                    urlPrefix + item.link,
                    innerItem,
                    depth + 1,
                  )
                : buildMenuItem(`${index}_${innerIndex}`, urlPrefix + item.link, innerItem);
            })}
        </HeaderSubMenu>
      );
    };

    const buildMenuItem = (index, urlPrefix, item) => {
      return (
        <HeaderMenuItem key={getUniqueId()}>
          <HeaderMenuLink to="#">Link</HeaderMenuLink>
        </HeaderMenuItem>
      );
    };

    return (
      <HeaderMenu subMenuCloseDelay={0.2}>
        {demoData.websiteStructure &&
          demoData.websiteStructure
            .filter((item) => item.dropdown)
            .map((item, index) => {
              return item.submenus && item.submenus.length > 0
                ? buildSubMenu(index, '', item)
                : buildMenuItem(index, '', item);
            })}
      </HeaderMenu>
    );
  };

  const CheckBoxWrap = (props) => {
    // this makes a solo checkbox the same height as a button.
    return (
      <Box style={{ padding: '6px 0' }}>
        <CheckBox checked={props && props.checked} onChange={() => {}} />
      </Box>
    );
  };

  const getFormInputs = () => {};

  const getAllColorSwatches = () => {
    const colorGroups = [
      {
        title: 'Content',
        direction: 'column',
        colors: [
          { title: 'Page BG', color: colors.outerBg },
          { title: 'Content, Dropdown BG', color: colors.innerBg },
          { title: 'Text', color: colors.text },
        ],
      },
      {
        title: 'Navigation',
        direction: 'row',
        colors: [
          {
            direction: 'column',
            colors: [
              { title: 'BG', color: '#F3F5F7' },
              { title: 'Text', color: colors.text },
            ],
          },
          {
            direction: 'column',
            colors: [
              { title: 'Hover BG', color: colors.navHoverBg },
              { title: 'Hover Text', color: colors.electricTeal },
            ],
          },
        ],
      },
      {
        title: 'Selection',
        direction: 'column',
        colors: [
          { title: 'Active', color: colors.active },
          { title: 'Hover', color: colors.hover },
        ],
      },
      {
        title: 'Button',
        direction: 'column',
        colors: [
          { title: 'Enabled', color: colors.brand },
          { title: 'Disabled', color: colors.brandDisabled },
        ],
      },
      {
        title: 'Table',
        direction: 'column',
        colors: [
          { title: 'Odd Rows', color: colors.innerBg },
          { title: 'Even Rows', color: colors.lightestBlue },
        ],
      },
    ];

    const SwatchTitle = styled(Text)`
      font-size: 14px;
    `;

    const SwatchValue = styled(SwatchTitle)`
      font-weight: bold;
    `;

    const SwatchGroupTitle = styled(Text)`
      font-weight: bold;
      color: ${colors.text};
      position: absolute;
      top: -2em;
      left: 0;
    `;

    const outerColorSwatchProps = {
      width: '200px',
      height: '150px',
      fill: false,
      style: {
        border: '1px solid #999',
        borderRadius: '5px',
        overflow: 'hidden',
      },
    };

    const getColorSwatches = (colorGroup, props) => {
      if (colorGroup.colors) {
        return (
          <Box
            key={`colorSwatch${getUniqueId()}`}
            direction={colorGroup.direction || 'row'}
            fill
            {...props}
          >
            {colorGroup.colors.map((innerGroup) => getColorSwatches(innerGroup))}
          </Box>
        );
      }
      const color = Color(colorGroup.color);
      const textColor = color.isLight() ? 'black' : 'white';

      return (
        <Box
          {...props}
          background={colorGroup.color}
          key={`colorSwatch${getUniqueId()}`}
          align="center"
          justify="center"
          fill
        >
          <SwatchTitle color={textColor}>{colorGroup.title}</SwatchTitle>
          <SwatchValue color={textColor}>{colorGroup.color}</SwatchValue>
        </Box>
      );
    };

    return (
      <Box direction="row" gap="medium" align="center" justify="center" wrap fill>
        {colorGroups.map((group, index) => (
          <StyleElementBox title={group.title} key={`swatchGroup${getUniqueId()}`}>
            {getColorSwatches(group, outerColorSwatchProps)}
          </StyleElementBox>
        ))}
      </Box>
    );
  };

  const HeadingsBox = styled(Box)`
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 0;
    }
  `;

  return (
    <Page gap="medium">
      <Heading level="3" style={{ margin: 0 }}>
        Style Guide
      </Heading>
      <Box direction="row" gap="small" align="stretch" justify="stretch">
        <StyleGroupBox title="Font" fill="vertical" outerStyle={{ minWidth: '350px' }}>
          <Text>
            <strong>Font face:</strong>{' '}
            <a
              href="https://fonts.google.com/specimen/Nunito+Sans"
              target="_blank"
              rel="noreferrer"
            >
              Nunito Sans
            </a>
          </Text>
          <HeadingsBox>
            <Heading level="3">Heading 3: Page Titles</Heading>
            <Heading level="4">Heading 4: Table Column Headings</Heading>
            <Heading level="5">Heading 5: Form Group Label</Heading>
            <Heading level="6">Heading 6: Modal Window Subtitle</Heading>
          </HeadingsBox>
        </StyleGroupBox>

        <StyleGroupBox title="Color" fill="vertical" outerFill="horizontal">
          {getAllColorSwatches()}
        </StyleGroupBox>
      </Box>

      <Box direction="row" gap="small" align="stretch" justify="stretch">
        <StyleGroupBox
          title="Navigation"
          direction="row"
          gap="large"
          justify="center"
          outerFill="horizontal"
          fill
        >
          <StyleElementBox title="Header" style={{ minWidth: '160px' }}>
            {getHeaderMenu()}
          </StyleElementBox>
          <StyleElementBox title="Sign Post">
            <NavBox
              fill={false}
              userPrivileges={userPrivileges}
              navMap={demoData.navMap}
              currentCustomer
            />
          </StyleElementBox>
        </StyleGroupBox>

        <StyleGroupBox
          title="Modal Window"
          background="rgba(0,0,0,0.5)"
          pad="large"
          align="center"
          justify="center"
          outerFill="horizontal"
          fill
        >
          <DemoModalBody title="Modal Window Example" onCancel={() => null}>
            <Form onSubmit={(event) => event.preventDefault()}>
              <Box gap="medium">
                <Box>
                  This is an example Modal window, which will pop up when the user needs to make a
                  decision.
                </Box>
                <Box direction="row" justify="end" gap="small">
                  <Button label="Cancel" />
                  <PrimaryButton type="submit" label="OK" />
                </Box>
              </Box>
            </Form>
          </DemoModalBody>
        </StyleGroupBox>
      </Box>

      <StyleGroupBox title="Controls" direction="row" gap="large" justify="center">
        <StyleElementBox title="Form Inputs">
          <Box direction="row" gap="medium">
            <Box gap="medium" style={{ minWidth: '300px' }}>
              <Box>
                <InputLabel id="selectLabel">Select Input</InputLabel>
                {getDummySelect()}
              </Box>
              <Box>
                <InputLabel id="textLabel">Text Input</InputLabel>
                <TextField
                  defaultValue="Text Value"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
              <Box>
                <InputLabel id="numberLabel">Number Input</InputLabel>
                <TextField
                  defaultValue={0.5}
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min: 0,
                    max: 1,
                    step: 0.01,
                  }}
                />
              </Box>
            </Box>
            <Box direction="row" gap="medium">
              <Box gap="medium" basis="30%" align="center">
                <InputLabel>Checkboxes</InputLabel>
                <CheckBoxWrap checked />
                <CheckBoxWrap />
              </Box>
              <Box gap="medium" basis="70%">
                <InputLabel>Buttons</InputLabel>
                <PrimaryButton label="Primary Button" />
                <Button label="Secondary Button" />
              </Box>
            </Box>
          </Box>
        </StyleElementBox>
        <StyleElementBox title="Calendar">
          <CustomCalendar
            value={[new Date(Date.now() - 86400 * 5), new Date(Date.now())]}
            selectRange
          />
        </StyleElementBox>
      </StyleGroupBox>

      <StyleGroupBox title="Data" fill outerFill>
        <Box direction="row" gap="medium" align="stretch">
          <Box
            gap="medium"
            style={{
              minWidth: '160px',
            }}
          >
            <StyleElementBox
              title="Gauge"
              innerStyle={{
                maxHeight: '140px',
                overflow: 'hidden',
              }}
            >
              <ReactSpeedometer
                maxValue={100}
                minValue={0}
                value={85}
                segments={5}
                currentValueText="Some Property 85%"
                paddingVertical="5"
                valueTextFontSize="12px"
                width={160}
              />
            </StyleElementBox>
            {/*
            <StyleElementBox title="Selection Tree">
              TODO: sidebar-style selection tree.
            </StyleElementBox>
            */}
          </Box>
          <StyleElementBox title="Resizeable Columns">
            <ResizableColumnTable height="100%" style={{ height: '300px' }}>
              <TableCell {...demoData.resizeTableCell}>
                <StyleElementBox title="Tree Table" align="center" justify="center" fill>
                  <ThemeDataTable {...demoData.dataTable} />
                </StyleElementBox>
              </TableCell>
              <TableCell {...demoData.resizeTableCell}>
                <StyleElementBox title="Data Table" align="center" justify="center" fill>
                  <ThemeDataTable {...demoData.dataTable} />
                </StyleElementBox>
              </TableCell>
            </ResizableColumnTable>
          </StyleElementBox>
        </Box>
      </StyleGroupBox>
    </Page>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  currentCustomer: selectCurrentCustomer,
  userPrivileges: selectPrivileges,
});

export default connect(mapStateToProps, null)(StyleGuidePage);

import { Box, TableCell, Text } from 'grommet';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import { ResultsDataTable } from '@Pages/count-linen/count-linen.styles';
import { getItemsInLogStart } from '@State/administration/administration.actions';
import { selectItemsInLog } from '@State/administration/administration.selectors';
import { selectCurrentCustomer } from '@State/user/user.selectors';
import { colors, measures, ResizableColumnTable, ScrollableBox, ThemeDataTable } from '@ThemeMain';

const MainDataTable = styled(ThemeDataTable)`
  tbody {
    tr {
      td {
        word-break: break-all;
        word-break: break-word; /*not in IE11, will fall back to break-all*/
        overflow-wrap: break-word;
      }
    }
  }
`;

const ResultBox = (props) => {
  const { mainData, itemsInLog, getItemsInLog } = props;
  const [mainTableData, setMainTableData] = useState([]);
  const [rightTableData, setRightTableData] = useState([]);

  useEffect(() => {
    if (mainData !== null) {
      if (mainData.status !== 'failed') {
        setMainTableData(mainData.hasOwnProperty('data') ? mainData.data : []);
      }
      setRightTableData([]);
    }
  }, [mainData]);

  useEffect(() => {
    if (itemsInLog !== null) {
      if (itemsInLog.status !== 'failed') {
        setRightTableData(itemsInLog.data.NewDataSet.LogItemTable);
      }
    }
  }, [itemsInLog]);

  const wordBreak = 'break-word';
  const mainTableColumns = [
    {
      property: 'Log',
      header: <Text wordBreak={wordBreak}>Log</Text>,
      render: (datum) => {
        return <Text size="small">{datum.Log}</Text>;
      },
    },
    {
      property: 'LogDesc',
      header: <Text wordBreak={wordBreak}>Description</Text>,
      render: (datum) => {
        return <Text size="small">{datum.LogDesc}</Text>;
      },
    },
    {
      property: 'User',
      header: <Text wordBreak={wordBreak}>User</Text>,
      render: (datum) => {
        return <Text size="small">{datum.User}</Text>;
      },
    },
    {
      property: 'Time',
      header: <Text wordBreak={wordBreak}>Time</Text>,
      render: (datum) => {
        return <Text size="small">{datum.Time}</Text>;
      },
    },
    {
      property: 'Duration',
      header: <Text wordBreak={wordBreak}>Duration</Text>,
      render: (datum) => {
        return <Text size="small">{datum.Duration}</Text>;
      },
    },
  ];
  const rightTableColumns = [
    {
      property: 'Description',
      header: <Text wordBreak={wordBreak}>Description</Text>,
      render: (datum) => {
        return <Text size="small">{datum.LoggDesc._text}</Text>;
      },
    },
    {
      property: 'Time',
      header: <Text wordBreak={wordBreak}>Time</Text>,
      render: (datum) => {
        return <Text size="small">{datum.Time._text}</Text>;
      },
    },
  ];
  useEffect(() => {}, []);

  return (
    <Box direction="column" gap="small" background={colors.innerBg} style={{ width: '100%' }}>
      <ResizableColumnTable>
        <TableCell>
          <Box
            style={{
              display: 'block',
            }}
            direction="column"
            gap="0.2rem"
          >
            <ScrollableBox>
              <ResultsDataTable
                style={{
                  minWidth: '750px',
                  maxWidth: '100%',
                }}
                pad="2px 6px"
                bodySize={measures.dataTableDoubleHeaderBodyHeight}
                columns={mainTableColumns}
                data={mainTableData}
                step={mainTableData.length}
                sortable
                onClickRow={(event) => getItemsInLog({ LogRcID: event.datum.Log })}
              />
              {mainTableData.length === 0 ? (
                <Box
                  align="center"
                  fill="horizontal"
                  background={colors['status-unknown']}
                  pad="small"
                >
                  <Text weight="bold">No Data Available</Text>
                </Box>
              ) : null}
            </ScrollableBox>
          </Box>
        </TableCell>
        <TableCell>
          <Box
            style={{
              display: 'block',
            }}
            direction="column"
            gap="0.2rem"
          >
            <ScrollableBox>
              <ResultsDataTable
                style={{
                  minWidth: '750px',
                  maxWidth: '100%',
                }}
                pad="2px 6px"
                bodySize={measures.dataTableDoubleHeaderBodyHeight}
                columns={rightTableColumns}
                data={rightTableData}
                step={rightTableData.length}
                sortable
              />
              {rightTableData.length === 0 ? (
                <Box
                  align="center"
                  fill="horizontal"
                  background={colors['status-unknown']}
                  pad="small"
                >
                  <Text weight="bold">No Data Available</Text>
                </Box>
              ) : null}
            </ScrollableBox>
          </Box>
        </TableCell>
      </ResizableColumnTable>
    </Box>
  );
};

const mapStateToProps = createStructuredSelector({
  currentCustomer: selectCurrentCustomer,
  itemsInLog: selectItemsInLog,
});

const mapDispatchToProps = (dispatch) => ({
  getItemsInLog: (data) => dispatch(getItemsInLogStart(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResultBox);

const SetupUsersOrderSelectedActionTypes = {
  GET_ORDER_SELECTED_START: 'GET_ORDER_SELECTED_START',
  GET_ORDER_SELECTED_SUCCESS: 'GET_ORDER_SELECTED_SUCCESS',
  GET_ORDER_SELECTED_FAILURE: 'GET_ORDER_SELECTED_FAILURE',
  SET_CURRENT_ORDER: 'SET_CURRENT_ORDER',
  SAVE_ORDER_SELECTED_START: 'SAVE_ORDER_SELECTED_START',
  SAVE_ORDER_SELECTED_SUCCESS: 'SAVE_ORDER_SELECTED_SUCCESS',
  SAVE_ORDER_SELECTED_FAILURE: 'SAVE_ORDER_SELECTED_FAILURE',
  PUSH_ORDER_TO_DELETE_QUEUE_START: 'PUSH_ORDER_TO_DELETE_QUEUE_START',
  PUSH_ORDER_TO_DELETE_QUEUE_SUCCESS: 'PUSH_ORDER_TO_DELETE_QUEUE_SUCCESS',
  PUSH_ORDER_TO_DELETE_QUEUE_FAILURE: 'PUSH_ORDER_TO_DELETE_QUEUE_FAILURE',
  PUSH_ORDER_TO_ADD_QUEUE_START: 'PUSH_ORDER_TO_ADD_QUEUE_START',
  PUSH_ORDER_TO_ADD_QUEUE_SUCCESS: 'PUSH_ORDER_TO_ADD_QUEUE_SUCCESS',
  PUSH_ORDER_TO_ADD_QUEUE_FAILURE: 'PUSH_ORDER_TO_ADD_QUEUE_FAILURE',
};

export default SetupUsersOrderSelectedActionTypes;

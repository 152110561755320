import { createSelector } from 'reselect';

const selectUser = (state) => state.user;

export const selectCurrentUser = createSelector([selectUser], (user) => user.currentUser);

export const selectPrivileges = createSelector([selectUser], (user) => {
  if (user.currentUser && user.currentUser.rules) {
    return user.currentUser.rules;
  }
  return null;
});

export const selectSiteInfo = createSelector([selectUser], (user) => user.siteInfo);

export const selectBannerUrl = createSelector(
  [selectUser],
  (user) => user.siteInfo && user.siteInfo.BannerUrl,
);

export const selectCurrentCustomer = createSelector([selectUser], (user) => user.currentCustomer);

export const selectCustomers = createSelector([selectUser], (user) => user.customers);

export const selectResetPassword = createSelector([selectUser], (user) => user.resetPassword);

export const selectLoginMessages = createSelector([selectUser], (user) => user.loginMessages);

export const selectError = createSelector([selectUser], (user) => user.error);

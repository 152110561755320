import { createSelector } from 'reselect';

const selectAccount = (state) => state.setup_accounts;

export const selectAccounts = createSelector([selectAccount], (account) => account.accounts);

export const selectAccountsPrevious = createSelector(
  [selectAccount],
  (account) => account.accounts_previous,
);

export const selectSaveAccountsResponse = createSelector(
  [selectAccount],
  (account) => account.save_accounts_response,
);

import { createSelector } from 'reselect';

const selectAdministration = (state) => state.administration;

/* #region Log Transactions */
export const selectLogTransactionsSelectionTree = createSelector(
  [selectAdministration],
  (administration) => administration.log_transactions_selection_tree,
);

export const selectSystemConfigurationSelectionTree = createSelector(
  [selectAdministration],
  (administration) => administration.system_configuration_selection_tree,
);

export const selectSelectedLogs = createSelector(
  [selectAdministration],
  (administration) => administration.selected_logs,
);

export const selectItemsInLog = createSelector(
  [selectAdministration],
  (administration) => administration.items_in_log,
);
/* #endregion */

/* #region System Configuration */
export const selectGeneralTabOptions = createSelector(
  [selectAdministration],
  (administration) => administration.general_tab_options,
);

export const selectPrevGeneralTabOptions = createSelector(
  [selectAdministration],
  (administration) => administration.prev_general_tab_options,
);

export const selectUpdateGeneralOptionsResponse = createSelector(
  [selectAdministration],
  (administration) => administration.update_general_options_response,
);

export const selectMobileDeviceTabs = createSelector(
  [selectAdministration],
  (administration) => administration.mobile_device_tabs,
);

export const selectMobileDeviceTabOptions = createSelector(
  [selectAdministration],
  (administration) => administration.mobile_device_tab_options,
);

export const selectPrevMobileDeviceTabOptions = createSelector(
  [selectAdministration],
  (administration) => administration.prev_mobile_device_tab_options,
);

export const selectUpdateMobileDeviceTabOptionsResponse = createSelector(
  [selectAdministration],
  (administration) => administration.update_mobile_device_tab_options_response,
);

export const selectDeleteMobileDeviceOptionsResponse = createSelector(
  [selectAdministration],
  (administration) => administration.delete_mobile_device_options_response,
);
/* #endregion */

import { Box, Button, Heading, Layer, Text } from 'grommet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import { setCurrentAdvancedUser } from '@State/setup-advanced-users/setup-advanced-users.actions';
import { selectAdvancedCurrentUsers } from '@State/setup-advanced-users/setup-advanced-users.selectors';
import { selectDetailsForOrderSelected } from '@State/setup-users-details-for-order-selected-in-user/setup-users-details-for-order-selected-in-user.selectors';
import { selectCurrentOrdersSelected } from '@State/setup-users-order-selected-in-user/setup-users-order-selected-in-user.selectors';
import { selectCurrentUser } from '@State/user/user.selectors';
import { PrimaryButton } from '@ThemeMain';

const DeleteModal = (props) => {
  const {
    onClose,
    confirm,
    data,
    currentOrdersSelected,
    setCurrentUser,
    advancedCurrentUser,
    evaluateChange,
    detailsForOrderSelected,
  } = props;
  const text = '';

  const onConfirm = (type) => {
    switch (props.type) {
      case 'Users':
        confirm();
        break;
      case 'Order':
        const currentOrderCopy = {
          new: { ...currentOrdersSelected },
          key: currentOrdersSelected.RcID,
        };
        confirm({ ...currentOrdersSelected, details: detailsForOrderSelected });
        evaluateChange(currentOrderCopy);
        setCurrentUser({ ...advancedCurrentUser, ...currentOrderCopy });
        break;
    }
    onClose(false);
  };

  return (
    <Layer onEsc={() => onClose(false)} onClickOutside={() => onClose(false)}>
      <Box pad="xsmall">
        <Heading margin="0" pad="xsmall" level={6}>
          Delete {props.type}
        </Heading>
        <Box align="center" justify="center" fill>
          <Box width="medium" pad="small">
            <Text style={{ whiteSpace: 'pre-line' }}>
              {data === 1
                ? 'User must have access to at least 1 order.\n Are you sure you want to delete this order?'
                : `Are you sure you want to delete ${
                    props.type === 'Users' ? props.advancedCurrentUser.Name : 'this order'
                  }?`}
            </Text>
            <Box direction="row" justify="between" margin={{ top: 'medium' }}>
              {(() => {
                switch (props.type) {
                  case 'Users':
                    return (
                      <PrimaryButton
                        type="button"
                        label="Yes"
                        onClick={() => onConfirm(props.type)}
                      />
                    );
                  case 'Order':
                    return (
                      <PrimaryButton
                        type="button"
                        label="Yes"
                        onClick={() => onConfirm(props.type)}
                      />
                    );
                  default:
                    return null;
                }
              })()}
              <Button label="No" onClick={() => onClose(false)} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Layer>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  advancedCurrentUser: selectAdvancedCurrentUsers,
  currentOrdersSelected: selectCurrentOrdersSelected,
  detailsForOrderSelected: selectDetailsForOrderSelected,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentAdvancedUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DeleteModal));

const SetupUsersDetailsForOrderSelectedActionTypes = {
  GET_DETAILS_FOR_ORDER_SELECTED_START: 'GET_DETAILS_FOR_ORDER_SELECTED_START',
  GET_DETAILS_FOR_ORDER_SELECTED_SUCCESS: 'GET_DETAILS_FOR_ORDER_SELECTED_SUCCESS',
  GET_DETAILS_FOR_ORDER_SELECTED_FAILURE: 'GET_DETAILS_FOR_ORDER_SELECTED_FAILURE',
  UPDATE_DETAILS_FOR_ORDER_SELECTED_START: 'UPDATE_DETAILS_FOR_ORDER_SELECTED_START',
  UPDATE_DETAILS_FOR_ORDER_SELECTED_SUCCESS: 'UPDATE_DETAILS_FOR_ORDER_SELECTED_SUCCESS',
  UPDATE_DETAILS_FOR_ORDER_SELECTED_FAILURE: 'UPDATE_DETAILS_FOR_ORDER_SELECTED_FAILURE',
};

export default SetupUsersDetailsForOrderSelectedActionTypes;

import SetupUsersHandHeldNumberActionTypes from './setup-users-hand-held-numbers.types';

export const getHandHeldNumberStart = () => ({
  type: SetupUsersHandHeldNumberActionTypes.GET_HAND_HELD_NUMBER_START,
  payload: null,
});

export const getHandHeldNumberSuccess = (handHeldNumber) => ({
  type: SetupUsersHandHeldNumberActionTypes.GET_HAND_HELD_NUMBER_SUCCESS,
  payload: handHeldNumber,
});

export const getHandHeldNumberFailure = (error) => ({
  type: SetupUsersHandHeldNumberActionTypes.GET_HAND_HELD_NUMBER_FAILURE,
  payload: error,
});

import { Box, CheckBox, Select, Text, TextInput } from 'grommet';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { filterInput, INPUT_TYPE } from '@Helpers/common.helper';
import {
  CountLinenPageID,
  Messages,
  searchKeys,
  tableConfig,
} from '@Pages/count-linen/count-linen-config';
import {
  FilterControlsBox,
  ResultsDataTable,
  ResultsSummary,
} from '@Pages/count-linen/count-linen.styles';
import {
  selectCountLinenCartsAndClosetsOrderSchedulePrevious,
  selectRcID,
} from '@State/count-linen-carts-and-closets/count-linen-carts-and-closets.selectors';
import { BrandButton, colors, measures, ScrollableBox } from '@ThemeMain';

const CountLinenTable = ({
  data,
  userPrivileges,
  className,
  pageID,
  style,
  type,
  table,
  evaluateModifiedItem,
  setCalculatingReqrd,
  calculateRequired,
  getDeliveryByID,
  setRequiredValue,
  currentDate,
  selectedRcID,
  changes,
  updateGauges,
  setActiveOrder,
}) => {
  const [resultsData, setResultsData] = useState([]);

  const [searchKey, setSearchKey] = useState(searchKeys[table][0]);
  const [searchValue, setSearchValue] = useState('');
  const [onHandPrev, setOnHandPrev] = useState(null);
  const [rcID, setRcID] = useState(0);

  useEffect(() => {
    if (data && data.data) {
      setResultsData(data.data);

      setRcID(selectedRcID);
    } else {
      setResultsData([]);
    }
  }, [data]);

  const assignClass = (datum) => {
    if (rcID === datum.RcID) {
      return 'white-label';
    }
    return '';
  };

  const convertHandheldTimeFormat = (handheldTime) => {
    if (handheldTime !== '' && handheldTime !== undefined) {
      const options = {
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      };
      const handheldTimeSeconds = new Date(parseInt(handheldTime) * 1000);
      const timeZoneOffset = handheldTimeSeconds.getTimezoneOffset() * 60 * 1000;
      const adjustedHandheldTime = new Date(handheldTimeSeconds.getTime() + timeZoneOffset);
      handheldTime = adjustedHandheldTime.toLocaleDateString(undefined, options);
    }
    return handheldTime;
  };

  const getInputByType = (inputType, key, datum) => {
    switch (inputType) {
      case 'text':
        const displayedValue =
          key !== 'HhldTime' ? datum[key] : convertHandheldTimeFormat(datum[key]);
        return (
          <Box
            fill="horizontal"
            className={assignClass(datum)}
            onClick={() => onRowSelection(datum, key)}
          >
            <Text>{displayedValue}</Text>
          </Box>
        );
      case 'textInput':
        return (
          <TextInput
            value={datum[key]}
            type="text"
            className={assignClass(datum)}
            onKeyDown={(event) => filterInput(event, INPUT_TYPE.int, ['-'], 'count-linen')}
            onClick={(event) => event.target.closest('td').classList.add('cell-focus')}
            onBlur={(event) => {
              event.target.closest('td').classList.remove('cell-focus');
              if (changes) {
                calculateRequired(
                  {
                    id: datum.RcID,
                    key,
                    value: event.target.value,
                    data: datum,
                  },
                  'columnsRight',
                );
                setCalculatingReqrd(false);
              }
            }}
            onFocus={(event) => {
              event.target.select();
              event.target.closest('td').classList.add('cell-focus');
              setRcID(datum.RcID);

              if (key === 'OhndQnty') {
                setOnHandPrev(event.target.value);
              }

              if (pageID === CountLinenPageID.cartAndClosets && key === 'RqrdQnty') {
                setCalculatingReqrd(false);
              }
            }}
            onChange={(event) =>
              evaluateModifiedItem(
                {
                  id: datum.RcID,
                  key,
                  value: event.target.value,
                  data: datum,
                },
                type,
              )
            }
          />
        );
      case 'checkBox':
        if (datum[key] && datum[key] !== undefined) {
          return (
            <Box fill="horizontal" onClick={() => onRowSelection(datum, key)}>
              <CheckBox
                className={selectedRcID === datum.RcID ? 'white-checkbox' : null}
                checked={JSON.parse(datum[key])}
              />
            </Box>
          );
        }
        return <></>;

      default:
        return null;
    }
  };

  const filterColumns = (columns) => {
    const result = {};
    let canView = false;

    Object.entries(columns).forEach(([key, value]) => {
      switch (key) {
        case 'RcID':
        case 'ItemDesc':
        case 'OrdrDesc':
        case 'UsefTaly':
        case 'HhldTime':
        case 'CensQnty':
          canView = true;
          break;
        case 'StckQnty':
          canView = userPrivileges.canViewCountLinenPar;
          break;
        case 'OhndQnty':
          canView = userPrivileges.canViewCountLinenOnHand;
          break;
        case 'RqrdQnty':
          canView = userPrivileges.canViewCountLinenRequired;
          break;
        case 'LdryQnty':
          canView = userPrivileges.canViewCountLinenStocked;
          break;
        case 'LnrmQnty':
          canView = userPrivileges.canViewCountLinenEmergency;
          break;
        case 'RetnQnty':
          canView = userPrivileges.canViewCountLinenReturned;
          break;
        default:
          canView = false;
          break;
      }

      if (canView) {
        result[key] = value;
      }
    });

    return result;
  };

  const hasInputPermission = (key) => {
    let canEdit = false;
    switch (key) {
      case 'StckQnty':
        canEdit = userPrivileges.canModifyCountLinenPar;
        break;
      case 'OhndQnty':
        canEdit = userPrivileges.canModifyCountLinenOnHand;
        break;
      case 'RqrdQnty':
        canEdit = userPrivileges.canModifyCountLinenRequired;
        break;
      case 'LdryQnty':
        canEdit = userPrivileges.canModifyCountLinenStocked;
        break;
      case 'LnrmQnty':
        canEdit = userPrivileges.canModifyCountLinenEmergency;
        break;
      case 'RetnQnty':
        canEdit = userPrivileges.canModifyCountLinenReturned;
        break;
      case 'CensQnty':
        canEdit = userPrivileges.canModifyCountLinenOrders;
        break;
      default:
        canEdit = false;
        break;
    }

    return (
      canEdit &&
      ((pageID === CountLinenPageID.cartAndClosets && userPrivileges.canModifyCountLinenOrders) ||
        (pageID === CountLinenPageID.linenRoom && userPrivileges.canModifyCountLinenLinenRoom))
    );
  };

  const getColumns = (mode = '') => {
    const columnConfig = filterColumns(tableConfig[pageID][type]);
    const columns = [];
    let column = {};

    for (const key in columnConfig) {
      column = {};
      column.property = key;
      column.header = (
        <Box fill="horizontal">
          <Text>{columnConfig[key].label}</Text>
        </Box>
      );
      switch (columnConfig[key].type) {
        case 'text':
          column.render = (datum) => {
            let inputType = !hasInputPermission(key)
              ? 'text'
              : key === 'CensQnty'
              ? 'text'
              : columnConfig[key].type;
            if (
              table === 'order' &&
              key === 'CensQnty' &&
              parseInt(datum.UsefCens) === 1 &&
              hasInputPermission(key)
            ) {
              inputType = 'textInput';
            }
            return getInputByType(inputType, key, datum);
          };
          break;
        case 'textInput':
          column.render = (datum) =>
            getInputByType(!hasInputPermission(key) ? 'text' : columnConfig[key].type, key, datum);
          break;
        case 'checkBox':
          column.render = (datum) => getInputByType(columnConfig[key].type, key, datum);
          break;
        default:
          break;
      }
      columns.push(column);
    }
    return columns;
  };

  const filter = () => {
    const searchResults = [];
    const currentData = typeof data.data === 'undefined' ? [] : [...data.data];
    for (const i in currentData) {
      if (typeof currentData[i][searchKey.id] !== 'undefined') {
        const value = currentData[i][searchKey.id].toLowerCase();
        if (value.includes(searchValue.toLowerCase())) {
          searchResults.push(currentData[i]);
        }
      }
    }

    setResultsData(searchResults);
  };

  const onRowSelection = (row, key = '') => {
    const undoChanges =
      table === 'order' && changes ? window.confirm(Messages.unSaveChanges) : true;

    if (undoChanges && table === 'order') {
      if (key !== 'CensQnty') {
        getDeliveryByID(row);
        setActiveOrder(row);
        updateGauges(row.RcID);
      }
      setRcID(row.RcID);
    } else if (undoChanges) {
      setRcID(row.RcID);
    }
  };

  return (
    <Box style={{ display: 'block' }} direction="column" gap="0.2rem">
      <FilterControlsBox
        direction="row"
        gap="small"
        pad="xsmall"
        className={`${className} overlapped-box`}
        align="center"
      >
        <Box direction="row" gap="small">
          <Text className="middle-align">Find</Text>
          <TextInput
            className="all-h-30"
            placeholder=""
            value={searchValue}
            onChange={(event) => setSearchValue(event.target.value)}
          />
          <BrandButton type="button" label="Search" onClick={() => filter()} />
          <Select
            className="all-h-30"
            labelKey="name"
            style={{ width: '150px' }}
            options={searchKeys[table]}
            value={searchKey.name}
            onChange={({ option }) => setSearchKey(option)}
          />
        </Box>
        {pageID === 'linen-room' ? (
          <BrandButton
            type="button"
            label="Set Required From To Orders"
            onClick={() => setRequiredValue('orders')}
          />
        ) : null}
        {pageID === 'linen-room' ? (
          <BrandButton
            type="button"
            label="Set Required From On Hand"
            onClick={() => setRequiredValue('onHand')}
          />
        ) : null}
      </FilterControlsBox>
      {pageID === 'carts-and-closets' ? (
        <ResultsSummary className="overlapped-box">
          `{resultsData.length}
          {table === 'order' ? ' Orders ' : ' Products '}
          {currentDate}`
        </ResultsSummary>
      ) : null}
      <ScrollableBox>
        <ResultsDataTable
          className={`overlapped-box cl-${pageID} hide-col1`}
          columns={getColumns()}
          data={resultsData}
          rowProps={{ [rcID]: { background: colors.brand } }}
          step={resultsData.length}
          bodySize={measures.dataTableDoubleHeaderBodyHeight}
          sortable
        />
        {typeof data.data === 'undefined' || data.data.length === 0 ? (
          <Box background={colors['status-unknown']} style={{ textAlign: 'center' }} fill>
            No data available
          </Box>
        ) : null}
      </ScrollableBox>
    </Box>
  );
};

const mapStateToProps = createStructuredSelector({
  orderSchedulePrevious: selectCountLinenCartsAndClosetsOrderSchedulePrevious,
  selectedRcID: selectRcID,
});

export default connect(mapStateToProps, null)(CountLinenTable);

export const AdministrationUrl = {
  systemConfiguration: '/advance/log-transactions/',
};

export const AdministrationPageID = {
  systemConfiguration: 'advance-system-configuration',
};

// TODO: [LH-320] no references to the old LH site; use env variable for S3 help docs
export const Links = {
  [AdministrationPageID.systemConfiguration]: {
    help: 'http://cloudapex.linenhelper.net/Help/SystemConfig.pdf',
  },
};

export default AdministrationUrl;

import { Box, Button, CheckBox, Heading, Table, TableBody, TableCell, TableRow } from 'grommet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import { setCurrentAdvancedUser } from '@State/setup-advanced-users/setup-advanced-users.actions';
import { selectAdvancedCurrentUsers } from '@State/setup-advanced-users/setup-advanced-users.selectors';
import { selectCurrentUser } from '@State/user/user.selectors';

const PrivilegesTable = styled(Table)`
  .cols-2 {
    tr {
      td:nth-child(1) {
        display: table-cell;
        width: 50px;
      }

      td:nth-child(2) {
        width: auto !important;
      }
    }
  }

  .cols-3 {
    tr {
      td:nth-child(1),
      td:nth-child(2) {
        display: table-cell;
        width: 50px;
      }

      td:nth-child(3) {
        width: auto !important;
      }
    }
  }
`;
const noModify = [
  'Customer',
  'Create Reports',
  'Add New Orders',
  'Add New Items',
  'Global Adjustments',
  'Auto Stock',
];

const SetupUsersPrivilegesSubTab = (props) => {
  const handleChange = (event) => {
    const { value, name } = event.target;
    const currentadvancedUser = props.advancedCurrentUser;
    let newValue = '';
    const result = {};
    let key = '';

    {
      value === '1' ? (newValue = '0') : (newValue = '1');
    }
    props.evaluateChange({ new: newValue, key: name });

    result[name] = newValue;
    if (name.endsWith('Mdfy')) {
      key = name.replace('Mdfy', 'View');
      result[key] = newValue;
    } else if (value === '1') {
      key = name.replace('View', 'Mdfy');
      result[key] = newValue;
    }

    props.setCurrentUser({ ...currentadvancedUser, ...result });
    if ((name.endsWith('Mdfy') || value === '1') && name !== key) {
      props.evaluateChange({ new: newValue, key });
    }
  };

  const onAllChecboxes = (value) => {
    const currentadvancedUser = props.advancedCurrentUser;
    const result = {};

    props.data.map((item) => {
      item.boxes.map((box, i) => {
        result[box.key] = value;
        props.evaluateChange({ new: value, key: box.key });
      });
    });
    props.setCurrentUser({ ...currentadvancedUser, ...result });
  };

  return (
    <Box direction="column">
      <Box direction="column" pad="small" gap="xsmall">
        <Heading margin="0" level={6}>
          {props.type}
        </Heading>
        <Box alignSelf="start" width="medium" direction="row">
          <Button label="Clear All" onClick={() => onAllChecboxes('0')} />
          <Button label="Set All" onClick={() => onAllChecboxes('1')} />
        </Box>
      </Box>
      <PrivilegesTable>
        <TableBody className={props.type === 'Advanced Privileges' ? 'cols-2' : 'cols-3'}>
          {props.type === 'Advanced Privileges' ? null : (
            <TableRow>
              <TableCell>
                <Heading margin="0" pad="medium" level={6}>
                  View
                </Heading>
              </TableCell>
              <TableCell>
                <Heading margin="0" pad="medium" level={6}>
                  Modify
                </Heading>
              </TableCell>
              <TableCell />
            </TableRow>
          )}
          {props.data.map((item) => (
            <TableRow key={item.name}>
              {item.boxes.map((box, i) => (
                <TableCell key={box.key}>
                  <CheckBox
                    id={box.key}
                    name={box.key}
                    checked={
                      !!(
                        props.advancedCurrentUser[box.key] === '1' ||
                        props.advancedCurrentUser[box.key] === '255'
                      )
                    }
                    value={props.advancedCurrentUser[box.key]}
                    onChange={handleChange}
                  />
                </TableCell>
              ))}
              {noModify.includes(item.name) && <TableCell />}
              <TableCell>
                <Heading margin="0" level={6}>
                  {item.name}
                </Heading>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </PrivilegesTable>
    </Box>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  advancedCurrentUser: selectAdvancedCurrentUsers,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentAdvancedUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SetupUsersPrivilegesSubTab));

import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from '@API';

import {
  deleteMobileDeviceOptionsFailure,
  deleteMobileDeviceOptionsSuccess,
  getGeneralTabOptionsFailure,
  getGeneralTabOptionsSuccess,
  getItemsInLogFailure,
  getItemsInLogSuccess,
  getLogTransactionsSelectionTreeFailure,
  getLogTransactionsSelectionTreeSuccess,
  getMobileDeviceTabOptionsFailure,
  getMobileDeviceTabOptionsSuccess,
  getMobileDeviceTabsFailure,
  getMobileDeviceTabsSuccess,
  getSelectedLogsFailure,
  getSelectedLogsSuccess,
  getSystemConfigurationSelectionTreeFailure,
  getSystemConfigurationSelectionTreeSuccess,
  setGeneralTabOptionsFailure,
  setGeneralTabOptionsSuccess,
  setMobileDeviceTabOptionsFailure,
  setMobileDeviceTabOptionsSuccess,
  updateGeneralOptionsFailure,
  updateGeneralOptionsSuccess,
  updateMobileDeviceTabOptionsFailure,
  updateMobileDeviceTabOptionsSuccess,
} from './administration.actions';
import AdministrationTypes from './administration.types';

/* #region Log Transactions Enpoints */
const getLogTransactionsSelectionTreeApi = (params) => {
  return api.post('/logtransactions/get-selection-tree', params);
};

const getSystemConfigurationSelectionTreeApi = (params) => {
  return api.post('/system-configuration/get-linen-helper-selection-tree', params);
};

const getSelectedLogsApi = (params) => {
  return api.post('/logtransactions/get-selected-logs', params);
};

const getItemsInLogApi = (params) => {
  return api.post('/logtransactions/get-items-in-log', params);
};
/* #endregion */

/* #region System Configuration Endpoints */
const getGeneralTabOptionsApi = (params) => {
  return api.post('/system-configuration/get-general-tab-options', params);
};

const updateGeneralOptionsApi = (params) => {
  return api.post('/system-configuration/update-general-options', params);
};

const getMobileDeviceTabsApi = (params) => {
  return api.post('/system-configuration/get-mobile-device-tabs', params);
};

const getMobileDeviceTabOptionsApi = (params) => {
  return api.post('/system-configuration/get-mobile-device-tab-options', params);
};

const updateMobileDeviceTabOptionsApi = (params) => {
  return api.post('/system-configuration/update-mobile-options', params);
};

const deleteMobileDeviceOptionsApi = (params) => {
  return api.post('/system-configuration/delete-mobile-options', params);
};
/* #endregion */

/* #region Log Transactions */
export function* getLogTransactionsSelectionTree(action) {
  try {
    const params = action.payload;
    const { data } = yield call(getLogTransactionsSelectionTreeApi, params);

    yield put(getLogTransactionsSelectionTreeSuccess(data));
  } catch (error) {
    yield put(getLogTransactionsSelectionTreeFailure(error));
  }
}

export function* getSystemConfigurationSelectionTree(action) {
  try {
    const params = action.payload;
    const { data } = yield call(getSystemConfigurationSelectionTreeApi, params);

    yield put(getSystemConfigurationSelectionTreeSuccess(data));
  } catch (error) {
    yield put(getSystemConfigurationSelectionTreeFailure(error));
  }
}

export function* getSelectedLogs(action) {
  try {
    const params = action.payload;
    const { data } = yield call(getSelectedLogsApi, params);

    yield put(getSelectedLogsSuccess(data));
  } catch (error) {
    yield put(getSelectedLogsFailure(error));
  }
}

export function* getItemsInLog(action) {
  try {
    const params = action.payload;
    const { data } = yield call(getItemsInLogApi, params);

    yield put(getItemsInLogSuccess(data));
  } catch (error) {
    yield put(getItemsInLogFailure(error));
  }
}
/* #endregion */

/* #region System Configuration */
export function* getGeneralTabOptions() {
  try {
    const { data } = yield call(getGeneralTabOptionsApi, {});

    yield put(getGeneralTabOptionsSuccess(data));
  } catch (error) {
    yield put(getGeneralTabOptionsFailure(error));
  }
}

export function* setGeneralTabOptions(action) {
  try {
    yield put(setGeneralTabOptionsSuccess(action.payload));
  } catch (error) {
    yield put(setGeneralTabOptionsFailure(error));
  }
}

export function* updateGeneralOptions(action) {
  try {
    const params = action.payload;
    const { data } = yield call(updateGeneralOptionsApi, params);

    yield put(updateGeneralOptionsSuccess(data));
  } catch (error) {
    yield put(updateGeneralOptionsFailure(error));
  }
}

export function* getMobileDeviceTabs(action) {
  try {
    const params = action.payload;
    const { data } = yield call(getMobileDeviceTabsApi, params);

    yield put(getMobileDeviceTabsSuccess(data));
  } catch (error) {
    yield put(getMobileDeviceTabsFailure(error));
  }
}

export function* getMobileDeviceTabOptions(action) {
  try {
    const params = action.payload;
    const { data } = yield call(getMobileDeviceTabOptionsApi, params);

    yield put(getMobileDeviceTabOptionsSuccess(data));
  } catch (error) {
    yield put(getMobileDeviceTabOptionsFailure(error));
  }
}

export function* setMobileDeviceTabOptions(action) {
  try {
    yield put(setMobileDeviceTabOptionsSuccess(action.payload));
  } catch (error) {
    yield put(setMobileDeviceTabOptionsFailure(error));
  }
}

export function* updateMobileDeviceTabOptions(action) {
  try {
    const params = action.payload;
    const { data } = yield call(updateMobileDeviceTabOptionsApi, params);

    yield put(updateMobileDeviceTabOptionsSuccess(data));
  } catch (error) {
    yield put(updateMobileDeviceTabOptionsFailure(error));
  }
}

export function* deleteMobileDeviceOptions(action) {
  try {
    const params = action.payload;
    const { data } = yield call(deleteMobileDeviceOptionsApi, params);

    yield put(deleteMobileDeviceOptionsSuccess(data));
  } catch (error) {
    yield put(deleteMobileDeviceOptionsFailure(error));
  }
}
/* #endregion */

/* #region Log Transactions Sagas Methods */
export function* onGetLogTransactionsSelectionTreeStart() {
  yield takeLatest(
    AdministrationTypes.GET_LOG_TRANSACTIONS_SELECTION_TREE_START,
    getLogTransactionsSelectionTree,
  );
}

export function* onGetSystemConfigurationSelectionTreeStart() {
  yield takeLatest(
    AdministrationTypes.GET_SYSTEM_CONFIGURATION_SELECTION_TREE_START,
    getSystemConfigurationSelectionTree,
  );
}

export function* onGetSelectedLogsStart() {
  yield takeLatest(AdministrationTypes.GET_SELECTED_LOGS_START, getSelectedLogs);
}

export function* onGetItemsInLogStart() {
  yield takeLatest(AdministrationTypes.GET_ITEMS_IN_LOG_START, getItemsInLog);
}
/* #endregion */

/* #region System Configuration Sagas Methods */
export function* onGetGeneralTabOptionsStart() {
  yield takeLatest(AdministrationTypes.GET_GENERAL_TAB_OPTIONS_START, getGeneralTabOptions);
}

export function* onSetGeneralTabOptionsStart() {
  yield takeLatest(AdministrationTypes.SET_GENERAL_TAB_OPTIONS_START, setGeneralTabOptions);
}

export function* onUpdateGeneralOptionsStart() {
  yield takeLatest(AdministrationTypes.UPDATE_GENERAL_OPTIONS_START, updateGeneralOptions);
}

export function* onGetMobileDeviceTabsStart() {
  yield takeLatest(AdministrationTypes.GET_MOBILE_DEVICE_TABS_START, getMobileDeviceTabs);
}

export function* onGetMobileDeviceTabOptionsStart() {
  yield takeLatest(
    AdministrationTypes.GET_MOBILE_DEVICE_TAB_OPTIONS_START,
    getMobileDeviceTabOptions,
  );
}

export function* onSetMobileDeviceTabOptionsStart() {
  yield takeLatest(
    AdministrationTypes.SET_MOBILE_DEVICE_TAB_OPTIONS_START,
    setMobileDeviceTabOptions,
  );
}

export function* onUpdateMobileDeviceTabOptionsStart() {
  yield takeLatest(
    AdministrationTypes.UPDATE_MOBILE_DEVICE_TAB_OPTIONS_START,
    updateMobileDeviceTabOptions,
  );
}

export function* onDeleteMobileDeviceOptionsStart() {
  yield takeLatest(
    AdministrationTypes.DELETE_MOBILE_DEVICE_OPTIONS_START,
    deleteMobileDeviceOptions,
  );
}
/* #endregion */

export function* administrationSagas() {
  yield all([
    call(onGetLogTransactionsSelectionTreeStart),
    call(onGetSystemConfigurationSelectionTreeStart),
    call(onGetSelectedLogsStart),
    call(onGetItemsInLogStart),
    call(onSetGeneralTabOptionsStart),
    call(onGetGeneralTabOptionsStart),
    call(onUpdateGeneralOptionsStart),
    call(onGetMobileDeviceTabsStart),
    call(onGetMobileDeviceTabOptionsStart),
    call(onSetMobileDeviceTabOptionsStart),
    call(onUpdateMobileDeviceTabOptionsStart),
    call(onDeleteMobileDeviceOptionsStart),
  ]);
}

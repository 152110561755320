import {
  Button,
  Heading,
  Layer,
  List,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from 'grommet';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  createCountLinenCartsAndClosetsDeliveryForOrderOnDateStart,
  getCountLinenCartsAndClosetsOrderScheduleStart,
} from '@State/count-linen-carts-and-closets/count-linen-carts-and-closets.actions';
import {
  selectCountLinenCartsAndClosetsDeliveryForDeliveryID,
  selectCreateCountLinenCartsAndClosetsDeliveryForOrderOnDateReponse,
} from '@State/count-linen-carts-and-closets/count-linen-carts-and-closets.selectors';
import { selectCurrentCustomer } from '@State/user/user.selectors';
import { Page } from '@ThemeMain';

const AddUnScheduledModal = (props) => {
  const {
    showModal,
    toggleModal,
    type,
    data,
    currentCustomer,
    currentDate,
    deliveryData,
    addUnScheduledEvent,
  } = props;

  const [show, setShow] = useState(showModal);
  const [selectedRows, setSelectedRows] = useState([]);
  const [listData, setlisData] = useState([]);
  const [itemListProp, setItemListProp] = useState({});

  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  useEffect(() => {
    if (data.hasOwnProperty('data')) {
      setlisData(data.data);
    } else {
      setlisData([]);
    }
  }, [data]);

  const updateSelected = (row, selected) => {
    const rows = selected;
    rows.push(row);
    setSelectedRows(rows);
  };

  const onCtrlSelect = (row) => {
    updateSelected(row.item, selectedRows);

    const listProps = itemListProp;
    if (typeof listProps[row.index] === 'undefined') {
      listProps[row.index] = { background: 'brand' };
    } else {
      const temp = selectedRows;
      delete listProps[row.index];
      for (const i in temp) {
        if (temp[i].index === row.index) {
          delete temp[i];
        }
      }
      setSelectedRows(temp);
    }
    setItemListProp(listProps);
  };

  const onShiftSelect = (row) => {
    const selected = [];

    const listProps = {};
    let startIndex = 0;
    let endIndex = 0;
    const keys = Object.keys(itemListProp);
    const firstKey = keys.length > 0 ? keys[0] : 0;
    setItemListProp({});

    if (row.index > firstKey) {
      startIndex = keys[keys.length - 1];
      endIndex = row.index;
    } else {
      startIndex = row.index;
      endIndex = firstKey;
    }

    for (let i = startIndex; i <= endIndex; i++) {
      const temp = listData[i];
      temp.index = i;

      updateSelected(temp, selected);
      listProps[i] = { background: 'brand' };
    }

    setItemListProp(listProps);
  };

  const onDefaultSelect = (row) => {
    setSelectedRows([]);
    setItemListProp({});
    updateSelected(row.item, []);

    const listProps = {};
    if (typeof listProps[row.index] === 'undefined') {
      listProps[row.index] = { background: 'brand' };
      setItemListProp(listProps);
    }
  };

  const addSelectedRows = (row) => {
    if (row.shiftKey) {
      onShiftSelect(row);
    } else if (row.ctrlKey || row.metaKey) {
      onCtrlSelect(row);
    } else {
      onDefaultSelect(row);
    }
  };

  const clearData = () => {
    setSelectedRows([]);
    setItemListProp({});
  };

  const onCancel = () => {
    toggleModal({ id: 'AddUnscheduled', data: [] });
    clearData();
  };

  const add = () => {
    if (selectedRows.length > 0) {
      selectedRows.forEach((item) => {
        if (type === 'Orders') {
          addUnScheduledEvent({
            iOrderID: item.RcID,
            RequestedDay: currentDate,
            iCallerUTCOffset: 0,
            customerID: currentCustomer.RcID,
          });
        } else if (type === 'Items') {
          addUnScheduledEvent({
            iDeliveryID: deliveryData.data.deliveryTable.RcID,
            iItemID: item.RcID,
            iItemType: item.ItemType,
          });
        }
      });
      clearData();
      toggleModal({ id: 'AddUnscheduled', data: [] });
    }
  };
  return (
    <Page>
      {show && (
        <Layer onEsc={onCancel} onClickOutside={onCancel}>
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell colSpan="2">
                  <Heading level="4" textAlign="center" margin="auto">
                    Add {type}
                  </Heading>
                </TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableCell colSpan="2">
                  Restock Date {moment(currentDate).format('MM/DD/YYYY')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan="2">Use CTRL/SHIFT+Click to select multiple items</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan="2">
                  <List
                    primaryKey={type === 'Orders' ? 'OrdrDesc' : 'ItemDesc'}
                    data={listData}
                    style={{ height: '400px', overflowY: 'scroll' }}
                    itemProps={itemListProp}
                    onClickItem={(datum) => addSelectedRows(datum)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Button label="Add" onClick={() => add()} />
                </TableCell>
                <TableCell>
                  <Button label="Cancel" onClick={() => onCancel()} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Layer>
      )}
    </Page>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getCountLinenCartsAndClosetsOrderSchedule: (data) =>
    dispatch(getCountLinenCartsAndClosetsOrderScheduleStart(data)),
  createDeliveryForOrderOnDate: (data) =>
    dispatch(createCountLinenCartsAndClosetsDeliveryForOrderOnDateStart(data)),
  getCountLinenCartsAndClosetsOrderScheduleStart,
});

const mapStateToProps = createStructuredSelector({
  currentCustomer: selectCurrentCustomer,
  deliveryData: selectCountLinenCartsAndClosetsDeliveryForDeliveryID,
  createDeliveryForOrderOnDateReponse:
    selectCreateCountLinenCartsAndClosetsDeliveryForOrderOnDateReponse,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUnScheduledModal);

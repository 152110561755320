import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import api from '@API';

import { selectAdvancedCurrentUsers } from '../setup-advanced-users/setup-advanced-users.selectors';
import {
  getCustomerNotSelectedFailure,
  getCustomerNotSelectedSuccess,
} from './setup-users-customer-not-selected-in-user.actions';
import SetupUsersCustomerNotSelectedActionTypes from './setup-users-customer-not-selected-in-user.types';

const customerNotSelectedApi = (params) => {
  return api.post('security/get-customers-not-selected-in-user', params);
};

export function* getCustomerNotSelected(action) {
  try {
    const currentUserInfo = yield select(selectAdvancedCurrentUsers);
    if (currentUserInfo) {
      const params = { iUserID: currentUserInfo.RcID };
      const { data } = yield call(customerNotSelectedApi, params);
      yield put(getCustomerNotSelectedSuccess(data.data));
    }
  } catch (error) {
    yield put(getCustomerNotSelectedFailure(error));
  }
}

export function* onGetCustomerNotSelectedStart() {
  yield takeLatest(
    SetupUsersCustomerNotSelectedActionTypes.GET_CUSTOMER_NOT_SELECTED_START,
    getCustomerNotSelected,
  );
}

export function* advancedUserCustomerNotSelectedCustomer() {
  yield all([call(onGetCustomerNotSelectedStart)]);
}

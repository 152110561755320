import SetupAdvancedCustomerAccountActionTypes from './setup-advanced-customers-account-type.types';

export const getAdvancedCustomerAccountStart = () => ({
  type: SetupAdvancedCustomerAccountActionTypes.GET_ADVANCED_CUSTOMER_ACCOUNT_START,
  payload: null,
});

export const getAdvancedCustomerAccountSuccess = (advancedCustomerAccount) => ({
  type: SetupAdvancedCustomerAccountActionTypes.GET_ADVANCED_CUSTOMER_ACCOUNT_SUCCESS,
  payload: advancedCustomerAccount,
});

export const getAdvancedCustomerAccountFailure = (error) => ({
  type: SetupAdvancedCustomerAccountActionTypes.GET_ADVANCED_CUSTOMER_ACCOUNT_FAILURE,
  payload: error,
});

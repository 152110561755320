import 'core-js/stable';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './datadog';

import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import Root from '@Containers/Root';
import { persistor, store } from '@State/store';

const appRoot = document.getElementById('root');

ReactDOM.render(
  <Router>
    <Root store={store} persistor={persistor} />
  </Router>,
  appRoot,
);

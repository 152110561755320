import SetupUsersCustomerNotSelectedActionTypes from './setup-users-customer-not-selected-in-user.types';

export const getCustomerNotSelectedStart = () => ({
  type: SetupUsersCustomerNotSelectedActionTypes.GET_CUSTOMER_NOT_SELECTED_START,
  payload: null,
});

export const getCustomerNotSelectedSuccess = (customerNotSelected) => ({
  type: SetupUsersCustomerNotSelectedActionTypes.GET_CUSTOMER_NOT_SELECTED_SUCCESS,
  payload: customerNotSelected,
});

export const getCustomerNotSelectedFailure = (error) => ({
  type: SetupUsersCustomerNotSelectedActionTypes.GET_CUSTOMER_NOT_SELECTED_FAILURE,
  payload: error,
});

export const updateCustomerNotSelected = (customer) => ({
  type: SetupUsersCustomerNotSelectedActionTypes.UPDATE_CUSTOMER_NOT_SELECTED,
  payload: customer,
});

import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from '@API';

import {
  getAccountsFailure,
  getAccountsSuccess,
  saveAccountsFailure,
  saveAccountsSuccess,
} from './setup-accounts.actions';
import SetupAccountsActionTypes from './setup-accounts.types';

const accountApi = (params) => {
  return api.post('setuptables/get-accounts', params);
};

export function* getAccounts(action) {
  try {
    const { data } = yield call(accountApi, action.payload);
    yield put(getAccountsSuccess(data.data));
  } catch (error) {
    yield put(getAccountsFailure(error));
  }
}

const saveAccountApi = (params) => {
  return api.post('setuptables/update-accounts', params);
};

export function* saveAccounts(action) {
  try {
    const params = action.payload;
    const { data } = yield call(saveAccountApi, params);
    yield put(saveAccountsSuccess(data));
  } catch (error) {
    yield put(saveAccountsFailure(error));
  }
}

export function* onGetAccountStart() {
  yield takeLatest(SetupAccountsActionTypes.GET_ACCOUNTS_START, getAccounts);
}

export function* onSaveAccountStart() {
  yield takeLatest(SetupAccountsActionTypes.SAVE_ACCOUNTS_START, saveAccounts);
}

export function* accountSagas() {
  yield all([call(onGetAccountStart), call(onSaveAccountStart)]);
}

const UserActionTypes = {
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  SIGN_IN_START: 'SIGN_IN_START',
  SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
  SIGN_IN_FAILURE: 'SIGN_IN_FAILURE',
  SIGN_OUT_START: 'SIGN_OUT_START',
  SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS',
  SIGN_OUT_FAILURE: 'SIGN_OUT_FAILURE',
  SITE_INFO_START: 'SITE_INFO_START',
  SITE_INFO_SUCCESS: 'SITE_INFO_SUCCESS',
  SITE_INFO_FAILURE: 'SITE_INFO_FAILURE',
  CUSTOMERS_START: 'CUSTOMERS_START',
  CUSTOMERS_SUCCESS: 'CUSTOMERS_SUCCESS',
  CUSTOMERS_FAILURE: 'CUSTOMERS_FAILURE',
  SET_CURRENT_CUSTOMER: 'SET_CURRENT_CUSTOMER',
  RESET_PASSWORD_START: 'RESET_PASSWORD_START',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',
  LOGIN_MESSAGE_START: 'LOGIN_MESSAGE_START',
  LOGIN_MESSAGE_SUCCESS: 'LOGIN_MESSAGE_SUCCESS',
  LOGIN_MESSAGE_FAILURE: 'LOGIN_MESSAGE_FAILURE',
  CLEAR_MESSAGE_START: 'CLEAR_MESSAGE_START',
  CLEAR_MESSAGE_SUCCESS: 'CLEAR_MESSAGE_SUCCESS',
  CLEAR_MESSAGE_FAILURE: 'CLEAR_MESSAGE_FAILURE',
};

export default UserActionTypes;

import styled from 'styled-components';

export const StyleList = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 0;

  li {
    flex-direction: column;
    list-style: none;
    flex-basis: fit-content;
    margin-right: 20px;
  }
`;

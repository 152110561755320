import {
  Button,
  Heading,
  Layer,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from 'grommet';
import { useEffect, useState } from 'react';

const ConfirmationModal = (props) => {
  const { showModal, toggleModal, method, data, pageTitle, confirm } = props;

  const [show, setShow] = useState(showModal);

  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  const onCancel = () => {
    toggleModal({ id: 'ConfirmationModal', data: [], method });
  };

  const onConfirm = () => {
    confirm();
    toggleModal({ id: 'ConfirmationModal', data: [] });
  };

  return (
    <>
      {show && (
        <Layer onEsc={onCancel} onClickOutside={onCancel}>
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell colSpan="2">
                  <Heading level="4" textAlign="center" margin="auto">
                    {method} {pageTitle}
                  </Heading>
                </TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {method === 'Delete' ? (
                <TableRow>
                  <TableCell colSpan="2">
                    Are you sure you want to delete "{data.Name}
                    "?
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  <TableRow>
                    <TableCell colSpan="2">
                      <Heading level="4" textAlign="center" margin="auto">
                        Adding a customer to LinenHelper incurs an additional subscription fee.
                      </Heading>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan="2">
                      <Heading level="6" textAlign="center" margin="auto">
                        Please contact LinenMaster, LLC at +1 (772) 212-2710 or
                        support@linenmaster.com for details and assistance.
                      </Heading>
                    </TableCell>
                  </TableRow>
                </>
              )}
              <TableRow>
                {method === 'Delete' ? (
                  <>
                    <TableCell>
                      <Button alignSelf="end" label="Yes" onClick={() => onConfirm()} />
                    </TableCell>
                    <TableCell>
                      <Button alignSelf="end" label="No" onClick={() => onCancel()} />
                    </TableCell>
                  </>
                ) : (
                  <TableCell>
                    <Button alignSelf="center" label="Continue" onClick={() => onCancel()} />
                  </TableCell>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </Layer>
      )}
    </>
  );
};

export default ConfirmationModal;

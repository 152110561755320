import {
  Box,
  Button,
  CheckBox,
  Heading,
  Layer,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Text,
  TextInput,
} from 'grommet';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { MESSAGE } from '@Helpers/common.helper';
import { Messages } from '@Pages/count-linen/count-linen-config';
import { methodCalls, SetUpPageID, tableConfig } from '@Pages/setup/setup-config';
import { selectAccountsPrevious } from '@State/setup-accounts/setup-accounts.selectors';
import { moveToRowStart } from '@State/setup/setup.actions';
import {
  selectDepartmentsUsedForCustomerPrevious,
  selectOrdersUsedForCustomerPrevious,
  selectPacksUsedForCustomerPrevious,
  selectProceduresUsedForCustomerPrevious,
  selectProductsUsedForCustomer,
  selectProductsUsedForCustomerPrevious,
  selectValidateDeletionResponse,
} from '@State/setup/setup.selectors';
import { selectCurrentCustomer } from '@State/user/user.selectors';
import { PrimaryButton } from '@ThemeMain';

const EditPackModal = (props) => {
  const {
    showModal,
    toggleModal,
    type,
    method,
    data,
    pageTitle,
    rcID,
    pageID,
    accntTypeOptions,
    departmentOptions,
    handheldOptions,
    inventoryFlowOptions,
    orderTypeOptions,
    departmentAccntTypeOptions,
    itemAccounts,
    departments,
    handheldNumbers,
    inventoryFlow,
    orderTypes,
    departmentAcctTypes,
    updateMainData,
    remove,
    validateDeletion,
    packsUsedForCustomerPrevious,
    proceduresUsedForCustomerPrevious,
    ordersUsedForCustomerPrevious,
    departmentsUsedForCustomerPrevious,
    productsUsedForCustomerPrevious,
    productsUsedForCustomer,
    accountsPrevious,
    validateDeletionResponse,
    moveToRow,
    hasChanges,
  } = props;

  const [show, setShow] = useState(showModal);
  const [selectedRows, setSelectedRows] = useState([]);
  const [formData, setFormData] = useState(data);
  const [options, setOptions] = useState([]);
  const [error, setError] = useState({ code: '', descriptions: [] });
  const [deletionMode, setDeletionMode] = useState(false);
  const [row, setRow] = useState(null);

  useEffect(() => {
    setShow(showModal);
    setError({ code: '', descriptions: [] });
    setDeletionMode(false);
  }, [showModal]);

  useEffect(() => {
    setFormData(data);
    data && data.hasOwnProperty('Row') && setRow(data.Row);
  }, [data]);

  useEffect(() => {
    if (typeof itemAccounts.data !== 'undefined') {
      setOptions(itemAccounts.data);
    }
  }, [itemAccounts]);

  useEffect(() => {
    if (validateDeletionResponse !== null) {
      const response = validateDeletionResponse.data;

      if (
        response.code !== 'Error' &&
        response.description.length === 1 &&
        response.description[0] === ''
      ) {
        response.description[0] = MESSAGE.canDeleteItem;
      }
      if (response.code === 'Success') {
        setDeletionMode(true);
      }

      setError({ code: response.code, descriptions: response.description });
    }
  }, [validateDeletionResponse]);

  const evaluateData = (data) => {
    if (typeof data === 'undefined') {
      return [];
    }

    return data;
  };

  const evaluateOptions = (data, type = '') => {
    if (type === 'department') {
      let departments = evaluateData(data.data);
      departments = evaluateData(departments.departmentTable);
      return departments;
    }
    return evaluateData(data);
  };

  const onCancel = () => {
    toggleModal({ id: 'EditPackModal', data: [], method });
    setDeletionMode(false);
    setSelectedRows([]);
  };

  const evaluateCheckBoxValue = (value) => {
    return parseInt(value, 10) === 1;
  };

  const setCheckBoxValue = (value) => {
    return parseInt(value, 10) === 1;
  };

  const evaluateDefaultValue = (defaultValue, options, key) => {
    const result = Object.keys(options).find((key) => options[key] === defaultValue);
    setFormData({ ...formData, [key]: result });

    return result;
  };

  const applyClenPiecChrg = (key, value) => {
    if (key == 'Enter') {
      setFormData({ ...formData, ClenPiecChrg: formData.ClenPiecChrg * (value / 100) });
    }
  };

  const validateItemToDelete = () => {
    if (pageID !== SetUpPageID.orders) {
      validateDeletion({
        [tableConfig[type].validateKey]: rcID,
        apiCall: methodCalls[type].validateDeletion,
      });
    } else {
      setDeletionMode(true);
      setError({ code: 'Success', descriptions: [MESSAGE.canDeleteItem] });
    }
  };

  const getForm = () => {
    switch (pageID) {
      case 'products':
        if (method === 'Delivery Cost') {
          return (
            <TableRow>
              <TableCell>Cost to Deliver One Pound of Linen</TableCell>
              <TableCell>
                <TextInput
                  type="number"
                  value={formData.WghtChrg}
                  onChange={(event) => setFormData({ ...formData, WghtChrg: event.target.value })}
                />
              </TableCell>
            </TableRow>
          );
        }
        return (
          <>
            <TableRow>
              <TableCell>Product Name</TableCell>
              <TableCell>
                <TextInput
                  value={formData.ProdDesc}
                  onChange={(event) => setFormData({ ...formData, ProdDesc: event.target.value })}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Account Type</TableCell>
              <TableCell>
                <Select
                  labelKey="AcctDesc"
                  value={
                    accntTypeOptions[
                      formData.ItemAcct01RcID === '0'
                        ? evaluateDefaultValue('Bath', accntTypeOptions, 'ItemAcct01RcID')
                        : formData.ItemAcct01RcID
                    ]
                  }
                  options={options}
                  onChange={({ option }) =>
                    setFormData({ ...formData, ItemAcct01RcID: option.RcID })
                  }
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Weight</TableCell>
              <TableCell>
                <TextInput
                  type="text"
                  value={formData.Wght}
                  onChange={(event) =>
                    setFormData({ ...formData, Wght: event.target.value.replace(/[^0-9.]/g, '') })
                  }
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Piece Cost</TableCell>
              <TableCell>
                <TextInput
                  type="text"
                  value={formData.ClenPiecChrg}
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      ClenPiecChrg: event.target.value.replace(/[^0-9.]/g, ''),
                    })
                  }
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Piece Cost Percentage</TableCell>
              <TableCell>
                <TextInput
                  type="number"
                  defaultValue={100}
                  onKeyDown={(event) => applyClenPiecChrg(event.key, event.target.value)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Purchase Cost</TableCell>
              <TableCell>
                <TextInput
                  type="text"
                  value={formData.SaleChrg}
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      SaleChrg: event.target.value.replace(/[^0-9.]/g, ''),
                    })
                  }
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Linen Room Par</TableCell>
              <TableCell>
                <TextInput
                  type="number"
                  value={formData.LnrmRqrdQnty}
                  onChange={(event) =>
                    setFormData({ ...formData, LnrmRqrdQnty: event.target.value })
                  }
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>System Par</TableCell>
              <TableCell>
                <TextInput
                  type="number"
                  value={formData.StckRqrdQnty}
                  onChange={(event) =>
                    setFormData({ ...formData, StckRqrdQnty: event.target.value })
                  }
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Target Lbs/Census</TableCell>
              <TableCell>
                <TextInput
                  type="text"
                  value={formData.TargCensQnty}
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      TargCensQnty: event.target.value.replace(/[^0-9.]/g, ''),
                    })
                  }
                />
              </TableCell>
            </TableRow>
          </>
        );
      case 'departments':
        return (
          <>
            <TableRow>
              <TableCell>Department Name</TableCell>
              <TableCell>
                <TextInput
                  value={formData.DeptDesc}
                  onChange={(event) => setFormData({ ...formData, DeptDesc: event.target.value })}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Cost Account</TableCell>
              <TableCell>
                <TextInput
                  value={formData.IntfNmbr}
                  onChange={(event) => setFormData({ ...formData, IntfNmbr: event.target.value })}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Account Type</TableCell>
              <TableCell>
                <Select
                  labelKey="AcctDesc"
                  value={
                    departmentAccntTypeOptions[
                      formData.DeptAcctRcID === ''
                        ? evaluateDefaultValue(
                            'Inpatient',
                            departmentAccntTypeOptions,
                            'DeptAcctRcID',
                          )
                        : formData.DeptAcctRcID
                    ]
                  }
                  options={evaluateOptions(departmentAcctTypes.data)}
                  onChange={({ option }) => setFormData({ ...formData, DeptAcctRcID: option.RcID })}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Target Lbs/Census</TableCell>
              <TableCell>
                <TextInput
                  type="text"
                  value={formData.Qnty01}
                  onChange={(event) =>
                    setFormData({ ...formData, Qnty01: event.target.value.replace(/[^0-9.]/g, '') })
                  }
                />
              </TableCell>
            </TableRow>
          </>
        );
      case 'orders':
        return (
          <>
            <TableRow>
              <TableCell>Order Name</TableCell>
              <TableCell>
                <TextInput
                  value={formData.OrdrDesc}
                  onChange={(event) => setFormData({ ...formData, OrdrDesc: event.target.value })}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Department</TableCell>
              <TableCell>
                <Select
                  labelKey="DeptDesc"
                  value={departmentOptions[formData.DeptRcID]}
                  options={evaluateOptions(departments, 'department')}
                  dropHeight="medium"
                  onChange={({ option }) => setFormData({ ...formData, DeptRcID: option.RcID })}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Build</TableCell>
              <TableCell>
                <Select
                  labelKey="Desc"
                  value={
                    inventoryFlowOptions[
                      formData.InvtFlow === ''
                        ? evaluateDefaultValue('At Customer', inventoryFlowOptions, 'InvtFlow')
                        : formData.InvtFlow
                    ]
                  }
                  options={evaluateOptions(inventoryFlow.data)}
                  onChange={({ option }) => {
                    setFormData({ ...formData, InvtFlow: option.RcID });
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Stock By</TableCell>
              <TableCell>
                <Select
                  labelKey="Desc"
                  value={
                    orderTypeOptions[
                      formData.Type === ''
                        ? evaluateDefaultValue('Fixed', orderTypeOptions, 'Type')
                        : formData.Type
                    ]
                  }
                  options={evaluateOptions(orderTypes.data)}
                  onChange={({ option }) => setFormData({ ...formData, Type: option.RcID })}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Hand-held</TableCell>
              <TableCell>
                <Select
                  labelKey="Desc"
                  value={
                    handheldOptions[
                      formData.HhldNmbr === ''
                        ? evaluateDefaultValue('None', handheldOptions, 'HhldNmbr')
                        : formData.HhldNmbr
                    ]
                  }
                  options={evaluateOptions(handheldNumbers.data)}
                  onChange={({ option }) => setFormData({ ...formData, HhldNmbr: option.Value })}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Deliver On</TableCell>
              <TableCell>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <CheckBox
                          checked={evaluateCheckBoxValue(formData.UsefDelvMond)}
                          label="Mon"
                          onClick={() => {
                            setFormData({
                              ...formData,
                              UsefDelvMond: +!+formData.UsefDelvMond,
                            });
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <CheckBox
                          checked={evaluateCheckBoxValue(formData.UsefDelvTues)}
                          label="Tue"
                          onClick={() =>
                            setFormData({
                              ...formData,
                              UsefDelvTues: +!+formData.UsefDelvTues,
                            })
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <CheckBox
                          checked={evaluateCheckBoxValue(formData.UsefDelvWedn)}
                          label="Wed"
                          onClick={() =>
                            setFormData({
                              ...formData,
                              UsefDelvWedn: +!+formData.UsefDelvWedn,
                            })
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <CheckBox
                          checked={evaluateCheckBoxValue(formData.UsefDelvThur)}
                          label="Thu"
                          onClick={() =>
                            setFormData({
                              ...formData,
                              UsefDelvThur: +!+formData.UsefDelvThur,
                            })
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <CheckBox
                          checked={evaluateCheckBoxValue(formData.UsefDelvFrid)}
                          label="Fri"
                          onClick={() =>
                            setFormData({
                              ...formData,
                              UsefDelvFrid: +!+formData.UsefDelvFrid,
                            })
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <CheckBox
                          checked={evaluateCheckBoxValue(formData.UsefDelvSatr)}
                          label="Sat"
                          onClick={() =>
                            setFormData({
                              ...formData,
                              UsefDelvSatr: +!+formData.UsefDelvSatr,
                            })
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <CheckBox
                          checked={evaluateCheckBoxValue(formData.UsefDelvSund)}
                          label="Sun"
                          onClick={() =>
                            setFormData({
                              ...formData,
                              UsefDelvSund: +!+formData.UsefDelvSund,
                            })
                          }
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Prefill</TableCell>
              <TableCell>
                <CheckBox
                  checked={evaluateCheckBoxValue(formData.UsefPfil)}
                  onClick={() => setFormData({ ...formData, UsefPfil: +!+formData.UsefPfil })}
                />
              </TableCell>
            </TableRow>
          </>
        );
      case 'customer-accounts':
      case 'item-accounts':
      case 'department-accounts':
        return (
          <>
            <TableRow>
              <TableCell>Account Name</TableCell>
              <TableCell>
                <TextInput
                  value={formData.AcctDesc}
                  onChange={(event) => setFormData({ ...formData, AcctDesc: event.target.value })}
                />
              </TableCell>
            </TableRow>
            {pageID === 'department-accounts' ? (
              <TableRow>
                <TableCell>Target Lbs/Census</TableCell>
                <TableCell>
                  <TextInput
                    type="text"
                    value={formData.Qnty01}
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        Qnty01: event.target.value.replace(/[^0-9.]/g, ''),
                      })
                    }
                  />
                </TableCell>
              </TableRow>
            ) : null}
          </>
        );
      default:
        return (
          <>
            <TableRow>
              <TableCell>{pageTitle} Name</TableCell>
              <TableCell>
                <TextInput
                  value={formData.PackDesc}
                  onChange={(event) => setFormData({ ...formData, PackDesc: event.target.value })}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Account Type</TableCell>
              <TableCell>
                <Select
                  labelKey="AcctDesc"
                  value={
                    accntTypeOptions[
                      formData.ItemAcct01RcID === 0
                        ? evaluateDefaultValue('Bed', accntTypeOptions, 'ItemAcct01RcID')
                        : formData.ItemAcct01RcID
                    ]
                  }
                  options={options}
                  onChange={({ option }) =>
                    setFormData({ ...formData, ItemAcct01RcID: option.RcID })
                  }
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Weight</TableCell>
              <TableCell>
                <TextInput
                  value={formData.Wght}
                  onChange={(event) => setFormData({ ...formData, Wght: event.target.value })}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Piece Cost</TableCell>
              <TableCell>
                <TextInput
                  value={formData.ClenPiecChrg}
                  onChange={(event) =>
                    setFormData({ ...formData, ClenPiecChrg: event.target.value })
                  }
                />
              </TableCell>
            </TableRow>
          </>
        );
    }
  };

  const getErrorMessages = () => {
    const errors = [];
    console.log('getErrorMessages', error.descriptions);
    error.descriptions.forEach((item) => {
      errors.push(
        <TableRow>
          <TableCell>
            <Text>{error.code}</Text>
          </TableCell>
          <TableCell>
            <Text>{item}</Text>
          </TableCell>
        </TableRow>,
      );
    });
    return errors;
  };

  const validate = () => {
    const errors = [];
    let result = true;
    const requiredFields =
      method === 'Delivery Cost' ? tableConfig[type].requiredV2 : tableConfig[type].required;
    requiredFields.forEach((item) => {
      if (
        formData[item.id] === '' ||
        formData[item.id] === null ||
        (formData[item.id] === '0' && item.id === 'DeptRcID')
      ) {
        errors.push(item.message);
        result = false;
      }
    });
    setError({ code: 'Error', descriptions: errors });
    return result;
  };

  const add = () => {
    let requestData = {};
    let newData = [];
    if (validate()) {
      switch (pageID) {
        case 'packs':
          requestData = {
            formData: {
              modifiedPackPricingTable: [formData],
              modifiedWeightPricingTable: packsUsedForCustomerPrevious.data.weightPricingTable,
            },
          };
          break;
        case 'procedures':
          requestData = {
            formData: {
              modifiedPackPricingTable: [formData],
              modifiedWeightPricingTable: proceduresUsedForCustomerPrevious.data.weightPricingTable,
            },
          };
          break;
        case 'orders':
          requestData = {
            formData: {
              modifiedOrderTable: [formData],
              modifiedCustomerTable: ordersUsedForCustomerPrevious.data.customerTable,
            },
          };
          break;
        case 'products':
          let modifiedWeightPricingTable = productsUsedForCustomer.data.weightPricingTable;
          if (method === 'Delivery Cost') {
            modifiedWeightPricingTable = formData;
          } else {
            newData = productsUsedForCustomerPrevious.data.productPricingTable
              ? [...productsUsedForCustomerPrevious.data.productPricingTable]
              : [];
            newData.push(formData);
          }
          requestData = {
            formData: {
              productPricingTable: productsUsedForCustomerPrevious.data.productPricingTable,
              modifiedProductPricingTable: newData,
              modifiedWeightPricingTable,
              weightPricingTable: productsUsedForCustomerPrevious.data.weightPricingTable,
            },
          };
          break;
        case 'departments':
          newData = departmentsUsedForCustomerPrevious.data.departmentTable
            ? [...departmentsUsedForCustomerPrevious.data.departmentTable]
            : [];
          newData.push(formData);
          requestData = {
            formData: {
              modifiedDepartmentTable: newData,
              departmentTable: departmentsUsedForCustomerPrevious.data.departmentTable,
              modifiedCustomerTable: departmentsUsedForCustomerPrevious.data.customerTable,
            },
          };
          break;
        case 'customer-accounts':
        case 'item-accounts':
        case 'department-accounts':
          newData = [...accountsPrevious];
          newData.push(formData);
          requestData = {
            accountType: tableConfig[type].accountType,
            formData: {
              accountTable: accountsPrevious,
              modifiedAccountTable: newData,
            },
          };
          break;
        default:
          break;
      }

      updateMainData(requestData);
      setDeletionMode(false);
      toggleModal({ id: 'EditPackModal', data: [] });
    }
  };

  const onMoveToRow = () => {
    if ((hasChanges && window.confirm(Messages.unSaveChanges)) || !hasChanges) {
      moveToRow({
        [tableConfig[type].moveToRowKey]: formData[tableConfig[type].primaryKey],
        iFromRow: formData.Row,
        iToRow: row,
        type: tableConfig[type].moveToRowType,
      });
      toggleModal({ id: 'EditPackModal', data: [] });
    }
  };

  return (
    <>
      {show && (
        <Layer onEsc={onCancel} onClickOutside={onCancel}>
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell colSpan="2">
                  <Heading level="4" textAlign="center" margin="auto">
                    {method} {method !== 'Delivery Cost' ? pageTitle : ''}
                  </Heading>
                </TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {getForm()}
              {method === 'Edit' &&
                SetUpPageID.customerAccounts !== pageID &&
                SetUpPageID.departmentAccounts !== pageID &&
                SetUpPageID.itemAccounts !== pageID && (
                  <TableRow>
                    <TableCell>
                      <Button
                        label="Move to Row"
                        disabled={!(row && row !== '' && row !== '0')}
                        style={{ width: '160px' }}
                        onClick={() => onMoveToRow()}
                      />
                    </TableCell>
                    <TableCell>
                      <TextInput
                        value={row}
                        style={{ width: '160px' }}
                        onChange={(event) => setRow(event.target.value)}
                      />
                    </TableCell>
                  </TableRow>
                )}
              {method === 'Edit' &&
              error.code !== 'Error' &&
              method !== 'Delivery Cost' &&
              !deletionMode ? (
                <TableRow>
                  <TableCell colSpan="2">
                    <Button label="Delete" onClick={() => validateItemToDelete()} />
                  </TableCell>
                </TableRow>
              ) : null}
              {error.descriptions.length > 0 ? (
                <TableRow>
                  <TableCell colSpan="2">
                    <Table>
                      <TableHeader>
                        <TableRow>
                          <TableCell>Severity</TableCell>
                          <TableCell>Description</TableCell>
                        </TableRow>
                      </TableHeader>
                      <TableBody>{getErrorMessages()}</TableBody>
                    </Table>
                  </TableCell>
                </TableRow>
              ) : null}
              <TableRow>
                <TableCell colSpan="2">
                  <Box direction="row-reverse" gap="small">
                    {(error.code !== 'Error' || method === 'Add' || method === 'Delivery Cost') &&
                    !deletionMode ? (
                      <PrimaryButton
                        label={
                          method === 'Edit' || method === 'Delivery Cost'
                            ? deletionMode
                              ? 'Continue Delete'
                              : 'Save Changes'
                            : method
                        }
                        onClick={() => add()}
                      />
                    ) : null}
                    {deletionMode ? (
                      <Button label="Continue Delete" onClick={() => remove()} />
                    ) : null}
                    <Button label="Cancel" onClick={() => onCancel()} />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Layer>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  moveToRow: (data) => dispatch(moveToRowStart(data)),
});

const mapStateToProps = createStructuredSelector({
  currentCustomer: selectCurrentCustomer,
  packsUsedForCustomerPrevious: selectPacksUsedForCustomerPrevious,
  proceduresUsedForCustomerPrevious: selectProceduresUsedForCustomerPrevious,
  ordersUsedForCustomerPrevious: selectOrdersUsedForCustomerPrevious,
  departmentsUsedForCustomerPrevious: selectDepartmentsUsedForCustomerPrevious,
  accountsPrevious: selectAccountsPrevious,
  validateDeletionResponse: selectValidateDeletionResponse,
  productsUsedForCustomer: selectProductsUsedForCustomer,
  productsUsedForCustomerPrevious: selectProductsUsedForCustomerPrevious,
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPackModal);

import 'jspdf-autotable';

import jsPDF from 'jspdf';
import PropTypes from 'prop-types';

const Printer = (parameters) => {
  // [{headers: [xxx], data: [[yyy], [zzz]]}]
  const { title, tableData, htmlSelector } = parameters;
  const pdf = new jsPDF({ orientation: 'l', unit: 'mm', format: 'a4' });

  if (htmlSelector && htmlSelector !== '') {
    pdf.autoTable({ html: htmlSelector });
  } else {
    pdf.autoTable({});
    const { finalY } = pdf.previousAutoTable;
    pdf.text(title, 14, finalY + 15);

    pdf.setFontSize(12);

    tableData.forEach(({ headers, data }) => {
      const finalYInner = pdf.previousAutoTable.finalY;
      pdf.autoTable({
        startY: finalYInner + 20,
        head: [headers],
        body: data,
        showHead: 'firstPage',
      });
    });
  }

  pdf.save('pdf');
};

Printer.propTypes = {
  whatToPrint: PropTypes.element.isRequired,
};

export default Printer;

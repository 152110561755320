import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import api from '@API';

import {
  createCountLinenCartsAndClosetsDeliveryForOrderOnDateFailure,
  createCountLinenCartsAndClosetsDeliveryForOrderOnDateSuccess,
  createCountLinenLinenRoomDeliveryForCustomerOnDateFailure,
  createCountLinenLinenRoomDeliveryForCustomerOnDateSuccess,
  getCountLinenCartsAndClosetsActiveHandheldNumbersFailure,
  getCountLinenCartsAndClosetsActiveHandheldNumbersSuccess,
  getCountLinenCartsAndClosetsAddItemsToDeliveryFailure,
  getCountLinenCartsAndClosetsAddItemsToDeliverySuccess,
  getCountLinenCartsAndClosetsDashboardGaugeInfoFailure,
  getCountLinenCartsAndClosetsDashboardGaugeInfoSuccess,
  getCountLinenCartsAndClosetsDeliveriesForGlobalAdjustOnDateFailure,
  getCountLinenCartsAndClosetsDeliveriesForGlobalAdjustOnDateSuccess,
  getCountLinenCartsAndClosetsDeliveryForDeliveryIDFailure,
  getCountLinenCartsAndClosetsDeliveryForDeliveryIDSuccess,
  getCountLinenCartsAndClosetsDeliveryItemRightsFailure,
  getCountLinenCartsAndClosetsDeliveryItemRightsSuccess,
  getCountLinenCartsAndClosetsGetItemsNotInDeliveryFailure,
  getCountLinenCartsAndClosetsGetItemsNotInDeliverySuccess,
  getCountLinenCartsAndClosetsOrderScheduleFailure,
  getCountLinenCartsAndClosetsOrderScheduleSuccess,
  getCountLinenCartsAndClosetsOrdersNotScheduledFailure,
  getCountLinenCartsAndClosetsOrdersNotScheduledSuccess,
  getCountLinenCartsAndClosetsUpdateDeliveryCountsFailure,
  getCountLinenCartsAndClosetsUpdateDeliveryCountsSuccess,
  linenRoomPickFormFailure,
  linenRoomPickFormSuccess,
  pickFormFailure,
  pickFormSuccess,
  sendCountLinenCartsAndClosetsHandheldInfoFailure,
  sendCountLinenCartsAndClosetsHandheldInfoSuccess,
  updateCountLinenCartsAndClosetsUpdateDeliveriesForGlobalAdjustOnDateFailure,
  updateCountLinenCartsAndClosetsUpdateDeliveriesForGlobalAdjustOnDateSuccess,
  updateDeliveryCensusFailure,
  updateDeliveryCensusSuccess,
} from './count-linen-carts-and-closets.actions';
import {
  selectPickFormInterval,
  selectPickFormType,
} from './count-linen-carts-and-closets.selectors';
import CountLinenCartsAndClosetsTypes from './count-linen-carts-and-closets.types';

const countLinenCartsAndClosetsOrderScheduleApi = (params) => {
  return api.post('/count-linen/get-order-schedule-for-customer-on-date', params);
};

const countLinenCartsAndClosetsOrderNotScheduledApi = (params) => {
  return api.post('/count-linen/get-orders-not-scheduled-for-customer-on-date', params);
};

const countLinenCartsAndClosetsDeliveryItemRightsForUserApi = (params) => {
  return api.post('/count-linen/get-delivery-item-rights-for-user', params);
};

const countLinenCartsAndClosetsDeliveryForDeliveryIDApi = (params) => {
  return api.post('/count-linen/get-delivery-for-delivery-id', params);
};

const countLinenCartsAndClosetsDashboardGaugeInfoApi = (params) => {
  return api.post('/count-linen/get-dashboard-gauge-info', params);
};

const countLinenCartsAndClosetsUpdateDeliveryCountsApi = (params) => {
  return api.post('/count-linen/update-delivery-counts', params);
};

const countLinenCartsAndClosetsCreateDeliveryForOrderOnDateApi = (params) => {
  return api.post('/count-linen/create-delivery-for-order-on-date', params);
};

const countLinenCartsAndClosetsGetItemsNotInDeliveryApi = (params) => {
  return api.post('/count-linen/get-items-not-in-delivery', params);
};

const countLinenCartsAndClosetsGetAddItemsToDeliveryApi = (params) => {
  return api.post('/count-linen/add-item-to-delivery', params);
};

const countLinenCartsAndClosetsGetDeliveriesForGlobalAdjustmentOnDateApi = (params) => {
  return api.post('/count-linen/get-deliveries-for-global-adjust-on-date', params);
};

const countLinenCartsAndClosetsUpdateDeliveriesForGlobalAdjustmentOnDateApi = (params) => {
  return api.post('/count-linen/update-deliveries-for-global-adjust-on-date', params);
};

const countLinenCartsAndClosetsUpdateDeliveryCensusApi = (params) => {
  return api.post('/count-linen/update-delivery-census', params);
};

const countLinenCartsAndClosetsGetActiveHandheldNumbersApi = (params) => {
  return api.post('/count-linen/get-active-hand-held-numbers', params);
};

const countLinenCartsAndClosetsSendHandheldInfoApi = (params) => {
  return api.post('/count-linen/send-hand-held-info', params);
};

const pickFormApi = (type, interval, params) => {
  return api.post(`/count-linen/pick-form?how=${type}&interval=${interval}`, params);
};

const countLinenLinenRoomCreateDeliveryForCustomerOnDateApi = (params) => {
  return api.post('/count-linen/create-linen-room-delivery-for-customer-on-date', params);
};

const linenRoomPickFormApi = (type, params) => {
  return api.post(`/count-linen/linen-room-pick-form?how=${type}`, params);
};

export function* getCountLinenCartsAndClosetsOrderSchedule(action) {
  try {
    const params = action.payload;
    const { data } = yield call(countLinenCartsAndClosetsOrderScheduleApi, params);

    yield put(getCountLinenCartsAndClosetsOrderScheduleSuccess(data));
  } catch (error) {
    yield put(getCountLinenCartsAndClosetsOrderScheduleFailure(error));
  }
}

export function* getCountLinenCartsAndClosetsOrdersNotScheduled(action) {
  try {
    const params = action.payload;
    const { data } = yield call(countLinenCartsAndClosetsOrderNotScheduledApi, params);

    yield put(getCountLinenCartsAndClosetsOrdersNotScheduledSuccess(data));
  } catch (error) {
    yield put(getCountLinenCartsAndClosetsOrdersNotScheduledFailure(error));
  }
}

export function* getCountLinenCartsAndClosetsDeliveryItemRights(action) {
  try {
    const params = { FormData: action.payload };
    const { data } = yield call(countLinenCartsAndClosetsDeliveryItemRightsForUserApi, params);

    yield put(getCountLinenCartsAndClosetsDeliveryItemRightsSuccess(data));
  } catch (error) {
    yield put(getCountLinenCartsAndClosetsDeliveryItemRightsFailure(error));
  }
}

export function* getCountLinenCartsAndClosetsDeliveryForDeliveryID(action) {
  try {
    const params = action.payload;
    const { data } = yield call(countLinenCartsAndClosetsDeliveryForDeliveryIDApi, params);

    yield put(getCountLinenCartsAndClosetsDeliveryForDeliveryIDSuccess(data));
  } catch (error) {
    yield put(getCountLinenCartsAndClosetsDeliveryForDeliveryIDFailure(error));
  }
}

export function* getCountLinenCartsAndClosetsDashboardGaugeInfo(action) {
  try {
    const params = action.payload;
    const { data } = yield call(countLinenCartsAndClosetsDashboardGaugeInfoApi, params);

    yield put(getCountLinenCartsAndClosetsDashboardGaugeInfoSuccess(data));
  } catch (error) {
    yield put(getCountLinenCartsAndClosetsDashboardGaugeInfoFailure(error));
  }
}

export function* getCountLinenCartsAndClosetsUpdateDeliveryCounts(action) {
  try {
    const params = action.payload;
    const { data } = yield call(countLinenCartsAndClosetsUpdateDeliveryCountsApi, params);

    yield put(getCountLinenCartsAndClosetsUpdateDeliveryCountsSuccess(data));
  } catch (error) {
    yield put(getCountLinenCartsAndClosetsUpdateDeliveryCountsFailure(error));
  }
}

export function* getCountLinenCartsAndClosetsCreateDeliveryForOrderOnDate(action) {
  try {
    const params = action.payload;
    const { data } = yield call(countLinenCartsAndClosetsCreateDeliveryForOrderOnDateApi, params);

    yield put(createCountLinenCartsAndClosetsDeliveryForOrderOnDateSuccess(data));
  } catch (error) {
    yield put(createCountLinenCartsAndClosetsDeliveryForOrderOnDateFailure(error));
  }
}

export function* getCountLinenCartsAndClosetsGetItemsNotInDelivery(action) {
  try {
    const params = action.payload;
    const { data } = yield call(countLinenCartsAndClosetsGetItemsNotInDeliveryApi, params);

    yield put(getCountLinenCartsAndClosetsGetItemsNotInDeliverySuccess(data));
  } catch (error) {
    yield put(getCountLinenCartsAndClosetsGetItemsNotInDeliveryFailure(error));
  }
}

export function* getCountLinenCartsAndClosetsAddItemsToDelivery(action) {
  try {
    const params = action.payload;
    const { data } = yield call(countLinenCartsAndClosetsGetAddItemsToDeliveryApi, params);

    yield put(getCountLinenCartsAndClosetsAddItemsToDeliverySuccess(data));
  } catch (error) {
    yield put(getCountLinenCartsAndClosetsAddItemsToDeliveryFailure(error));
  }
}

export function* getCountLinenCartsAndClosetsGetDeliveriesForGlobalAdjustmentOnDate(action) {
  try {
    const params = action.payload;
    const { data } = yield call(
      countLinenCartsAndClosetsGetDeliveriesForGlobalAdjustmentOnDateApi,
      params,
    );

    yield put(getCountLinenCartsAndClosetsDeliveriesForGlobalAdjustOnDateSuccess(data));
  } catch (error) {
    yield put(getCountLinenCartsAndClosetsDeliveriesForGlobalAdjustOnDateFailure(error));
  }
}

export function* getCountLinenCartsAndClosetsUpdateDeliveriesForGlobalAdjustmentOnDate(action) {
  try {
    const params = action.payload;
    const { data } = yield call(
      countLinenCartsAndClosetsUpdateDeliveriesForGlobalAdjustmentOnDateApi,
      params,
    );

    yield put(updateCountLinenCartsAndClosetsUpdateDeliveriesForGlobalAdjustOnDateSuccess(data));
  } catch (error) {
    yield put(updateCountLinenCartsAndClosetsUpdateDeliveriesForGlobalAdjustOnDateFailure(error));
  }
}

export function* getCountLinenCartsAndClosetsGetActiveHandheldNumbers(action) {
  try {
    const params = action.payload;
    const { data } = yield call(countLinenCartsAndClosetsGetActiveHandheldNumbersApi, params);

    yield put(getCountLinenCartsAndClosetsActiveHandheldNumbersSuccess(data));
  } catch (error) {
    yield put(getCountLinenCartsAndClosetsActiveHandheldNumbersFailure(error));
  }
}

export function* sendCountLinenCartsAndClosetsHandheldInfo(action) {
  try {
    const params = action.payload;
    const { data } = yield call(countLinenCartsAndClosetsSendHandheldInfoApi, params);

    yield put(sendCountLinenCartsAndClosetsHandheldInfoSuccess(data));
  } catch (error) {
    yield put(sendCountLinenCartsAndClosetsHandheldInfoFailure(error));
  }
}

export function* updateDeliveryCensus(action) {
  try {
    const params = action.payload;
    const { data } = yield call(countLinenCartsAndClosetsUpdateDeliveryCensusApi, params);

    yield put(updateDeliveryCensusSuccess(data));
  } catch (error) {
    yield put(updateDeliveryCensusFailure(error));
  }
}

export function* pickForm(action) {
  try {
    const params = action.payload;
    const pickFormType = yield select(selectPickFormType);
    const pickFormInterval = yield select(selectPickFormInterval);
    const { data } = yield call(pickFormApi, pickFormType, pickFormInterval, params);

    yield put(pickFormSuccess(data));
  } catch (error) {
    yield put(pickFormFailure(error));
  }
}

export function* createCountLinenLinenRoomCreateDeliveryForCustomerOnDate(action) {
  try {
    const params = action.payload;
    const { data } = yield call(countLinenLinenRoomCreateDeliveryForCustomerOnDateApi, params);

    yield put(createCountLinenLinenRoomDeliveryForCustomerOnDateSuccess(data));
  } catch (error) {
    yield put(createCountLinenLinenRoomDeliveryForCustomerOnDateFailure(error));
  }
}

export function* linenRoomPickForm(action) {
  try {
    const params = action.payload;
    const pickFormType = yield select(selectPickFormType);
    const { data } = yield call(linenRoomPickFormApi, pickFormType, params);

    yield put(linenRoomPickFormSuccess(data));
  } catch (error) {
    yield put(linenRoomPickFormFailure(error));
  }
}

export function* onCountLinenCartsAndClosetsOrderScheduleStart() {
  yield takeLatest(
    CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_ORDER_SCHEDULE_START,
    getCountLinenCartsAndClosetsOrderSchedule,
  );
}

export function* onCountLinenCartsAndClosetsOrdersNotScheduledStart() {
  yield takeLatest(
    CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_ORDERS_NOT_SCHEDULED_START,
    getCountLinenCartsAndClosetsOrdersNotScheduled,
  );
}

export function* onCountLinenCartsAndClosetsDeliveryItemRightsStart() {
  yield takeLatest(
    CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERY_ITEM_RIGHTS_START,
    getCountLinenCartsAndClosetsDeliveryItemRights,
  );
}

export function* onCountLinenCartsAndClosetsDeliveryForDeliveryIDStart() {
  yield takeLatest(
    CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERY_FOR_DELIVERY_ID_START,
    getCountLinenCartsAndClosetsDeliveryForDeliveryID,
  );
}

export function* onCountLinenCartsAndClosetsDashboardGaugeInfoStart() {
  yield takeLatest(
    CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_DASHBOARD_GAUGE_INFO_START,
    getCountLinenCartsAndClosetsDashboardGaugeInfo,
  );
}

export function* onCountLinenCartsAndClosetsUpdateDeliveryCountsStart() {
  yield takeLatest(
    CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_UPDATE_DELIVERY_COUNTS_START,
    getCountLinenCartsAndClosetsUpdateDeliveryCounts,
  );
}

export function* onCountLinenCartsAndClosetsCreateDeliveryForOrderOnDateStart() {
  yield takeLatest(
    CountLinenCartsAndClosetsTypes.CREATE_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERY_FOR_ORDER_ON_DATE_START,
    getCountLinenCartsAndClosetsCreateDeliveryForOrderOnDate,
  );
}

export function* onCountLinenCartsAndClosetsGetItemsNotInDeliveryStart() {
  yield takeLatest(
    CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_GET_ITEMS_NOT_IN_DELIVERY_START,
    getCountLinenCartsAndClosetsGetItemsNotInDelivery,
  );
}

export function* onCountLinenCartsAndClosetsAddItemsToDelivery() {
  yield takeLatest(
    CountLinenCartsAndClosetsTypes.COUNT_LINEN_CARTS_AND_CLOSETS_ADD_ITEMS_TO_DELIVERY_START,
    getCountLinenCartsAndClosetsAddItemsToDelivery,
  );
}

export function* onCountLinenCartsAndClosetsGetDeliveriesForGlobalAdjustmentOnDate() {
  yield takeLatest(
    CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERIES_FOR_GLOBAL_ADJUSTMENT_ON_DATE_START,
    getCountLinenCartsAndClosetsGetDeliveriesForGlobalAdjustmentOnDate,
  );
}

export function* onCountLinenCartsAndClosetsUpdateDeliveriesForGlobalAdjustmentOnDate() {
  yield takeLatest(
    CountLinenCartsAndClosetsTypes.UPDATE_COUNT_LINEN_CARTS_AND_CLOSETS_DELIVERIES_FOR_GLOBAL_ADJUSTMENT_ON_DATE_START,
    getCountLinenCartsAndClosetsUpdateDeliveriesForGlobalAdjustmentOnDate,
  );
}

export function* onCountLinenCartsAndClosetsGetActiveHandheldNumbers() {
  yield takeLatest(
    CountLinenCartsAndClosetsTypes.GET_COUNT_LINEN_CARTS_AND_CLOSETS_ACTIVE_HANDHELD_NUMBERS_START,
    getCountLinenCartsAndClosetsGetActiveHandheldNumbers,
  );
}

export function* onCountLinenCartsAndClosetsSendHandheldInfo() {
  yield takeLatest(
    CountLinenCartsAndClosetsTypes.SEND_COUNT_LINEN_CARTS_AND_CLOSETS_HANDHELD_INFO_START,
    sendCountLinenCartsAndClosetsHandheldInfo,
  );
}

export function* onUpdateDeliveryCensus() {
  yield takeLatest(
    CountLinenCartsAndClosetsTypes.UPDATE_DELIVERY_CENSUS_START,
    updateDeliveryCensus,
  );
}

export function* onPickForm() {
  yield takeLatest(CountLinenCartsAndClosetsTypes.PICK_FORM_START, pickForm);
}

export function* onCountLinenCartsLinenRoomCreateDeliveryForCustomerOnDate() {
  yield takeLatest(
    CountLinenCartsAndClosetsTypes.CREATE_COUNT_LINEN_LINEN_ROOM_DELIVERY_FOR_CUSTOMER_ON_DATE_START,
    createCountLinenLinenRoomCreateDeliveryForCustomerOnDate,
  );
}

export function* onLinenRoomPickForm() {
  yield takeLatest(CountLinenCartsAndClosetsTypes.LINEN_ROOM_PICK_FORM_START, linenRoomPickForm);
}

export function* countLinenCartsAndClosetsSagas() {
  yield all([
    call(onCountLinenCartsAndClosetsOrderScheduleStart),
    call(onCountLinenCartsAndClosetsOrdersNotScheduledStart),
    call(onCountLinenCartsAndClosetsDeliveryItemRightsStart),
    call(onCountLinenCartsAndClosetsDeliveryForDeliveryIDStart),
    call(onCountLinenCartsAndClosetsDashboardGaugeInfoStart),
    call(onCountLinenCartsAndClosetsUpdateDeliveryCountsStart),
    call(onCountLinenCartsAndClosetsCreateDeliveryForOrderOnDateStart),
    call(onCountLinenCartsAndClosetsGetItemsNotInDeliveryStart),
    call(onCountLinenCartsAndClosetsAddItemsToDelivery),
    call(onCountLinenCartsAndClosetsGetDeliveriesForGlobalAdjustmentOnDate),
    call(onCountLinenCartsAndClosetsUpdateDeliveriesForGlobalAdjustmentOnDate),
    call(onCountLinenCartsAndClosetsGetActiveHandheldNumbers),
    call(onCountLinenCartsAndClosetsSendHandheldInfo),
    call(onUpdateDeliveryCensus),
    call(onPickForm),
    call(onCountLinenCartsLinenRoomCreateDeliveryForCustomerOnDate),
    call(onLinenRoomPickForm),
  ]);
}

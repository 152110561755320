const SetupAccountsActionTypes = {
  GET_ACCOUNTS_START: 'GET_ACCOUNTS_START',
  GET_ACCOUNTS_SUCCESS: 'GET_ACCOUNTS_SUCCESS',
  GET_ACCOUNTS_FAILURE: 'GET_ACCOUNTS_FAILURE',
  SAVE_ACCOUNTS_START: 'SAVE_ACCOUNTS_START',
  SAVE_ACCOUNTS_SUCCESS: 'SAVE_ACCOUNTS_SUCCESS',
  SAVE_ACCOUNTS_FAILURE: 'SAVE_ACCOUNTS_FAILURE',
};

export default SetupAccountsActionTypes;

const SetupAdvancedUserActionTypes = {
  GET_ADVANCED_USER_START: 'GET_ADVANCED_USER_START',
  GET_ADVANCED_USER_SUCCESS: 'GET_ADVANCED_USER_SUCCESS',
  GET_ADVANCED_USER_FAILURE: 'GET_ADVANCED_USER_FAILURE',
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  DELETE_ADVANCED_USER_START: 'DELETE_ADVANCED_USER_START',
  DELETE_ADVANCED_USER_SUCCESS: 'DELETE_ADVANCED_USER_SUCCESS',
  DELETE_ADVANCED_USER_FAILURE: 'DELETE_ADVANCED_USER_FAILURE',
  UPDATE_ADVANCED_USER_START: 'UPDATE_ADVANCED_USER_START',
  UPDATE_ADVANCED_USER_SUCCESS: 'UPDATE_ADVANCED_USER_SUCCESS',
  UPDATE_ADVANCED_USER_FAILURE: 'UPDATE_ADVANCED_USER_FAILURE',
};

export default SetupAdvancedUserActionTypes;

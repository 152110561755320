import SetupAdvancedUserActionTypes from './setup-advanced-users.types';

const INITIAL_STATE = {
  advanced_users: null,
  advanced_current_user: null,
  error: null,
};

const setupAdvancedUserReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SetupAdvancedUserActionTypes.GET_ADVANCED_USER_SUCCESS:
      return {
        ...state,
        advanced_users: action.payload,
        error: null,
      };

    case SetupAdvancedUserActionTypes.GET_ADVANCED_USER_FAILURE:
    case SetupAdvancedUserActionTypes.UPDATE_ADVANCED_USER_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case SetupAdvancedUserActionTypes.SET_CURRENT_USER:
      return {
        ...state,
        advanced_current_user: action.payload,
        error: null,
      };

    default:
      return state;
  }
};

export default setupAdvancedUserReducer;

import SetupUsersOrderSelectedActionTypes from './setup-users-order-selected-in-user.types';

const INITIAL_STATE = {
  order_selected: [],
  current_order_selected: null,
  orders_to_add: [],
  orders_to_delete: [],
};

const setupUserOrderSelectedReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SetupUsersOrderSelectedActionTypes.GET_ORDER_SELECTED_SUCCESS:
      return {
        ...state,
        order_selected: action.payload ? action.payload : [],
        orders_to_add: [],
        orders_to_delete: [],
        error: null,
      };

    case SetupUsersOrderSelectedActionTypes.GET_ORDER_SELECTED_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case SetupUsersOrderSelectedActionTypes.SET_CURRENT_ORDER:
      return {
        ...state,
        current_order_selected: action.payload,
        error: null,
      };

    case SetupUsersOrderSelectedActionTypes.PUSH_ORDER_TO_DELETE_QUEUE_SUCCESS:
      const ordersToDeleteAndSelectedMatchIndex = state.order_selected.findIndex(
        (order) => order.RcID === action.payload.RcID,
      );
      if (ordersToDeleteAndSelectedMatchIndex !== -1)
        state.order_selected.splice(ordersToDeleteAndSelectedMatchIndex, 1);
      const ordersToDeleteMatchIndex = state.orders_to_add.findIndex(
        (order) => order.RcID === action.payload.RcID,
      );
      if (ordersToDeleteMatchIndex !== -1) state.orders_to_add.splice(ordersToDeleteMatchIndex, 1);
      const orderRowForDeleteQueue = {
        RcID: action.payload.RcID,
        Name: action.payload.ObjDesc,
        ObjDesc: action.payload.ObjDesc,
        ObjRcID: action.payload.ObjRcID,
        UserRcID: action.payload.UserRcID,
        details: action.payload.details,
        RowOrder: state.order_selected.length + 1,
      };
      state.orders_to_delete.unshift(orderRowForDeleteQueue);

      return {
        ...state,
        current_order_selected: null,
        error: null,
      };

    case SetupUsersOrderSelectedActionTypes.PUSH_ORDER_TO_ADD_QUEUE_SUCCESS:
      const orderToAddMatchIndex = state.orders_to_delete.findIndex(
        (order) => order.RcID === action.payload.RcID,
      );
      if (orderToAddMatchIndex !== -1) state.orders_to_delete.splice(orderToAddMatchIndex, 1);
      const orderRowForAddQueue = {
        RcID: action.payload.RcID,
        ObjDesc: action.payload.Name,
        ObjRcID: action.payload.ObjRcID,
        UserRcID: action.payload.UserRcID,
        RowOrder: state.order_selected.length + 1,
      };
      state.orders_to_add.unshift(orderRowForAddQueue);
      state.order_selected.unshift(orderRowForAddQueue);
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export default setupUserOrderSelectedReducer;

import { all, call } from 'redux-saga/effects';

import { administrationSagas } from './administration/administration.sagas';
import { countLinenCartsAndClosetsSagas } from './count-linen-carts-and-closets/count-linen-carts-and-closets.sagas';
import { inventorySagas } from './inventory/inventory.sagas';
import { reportsSagas } from './reports/reports.sagas';
import { accountSagas } from './setup-accounts/setup-accounts.sagas';
import { advancedCustomerAccountSagas } from './setup-advanced-customers-account-type/setup-advanced-customers-account-type.sagas';
import { advancedCustomerSagas } from './setup-advanced-customers/setup-advanced-customers.sagas';
import { advancedUserSagas } from './setup-advanced-users/setup-advanced-users.sagas';
import { advancedUserCustomerNotSelectedCustomer } from './setup-users-customer-not-selected-in-user/setup-users-customer-not-selected-in-user.sagas';
import { advancedUserCustomerSelectedCustomer } from './setup-users-customer-selected-in-user/setup-users-customer-selected-in-user.sagas';
import { advancedUserDetailsForOrderSelectedCustomer } from './setup-users-details-for-order-selected-in-user/setup-users-details-for-order-selected-in-user.sagas';
import { advancedUserHandHeldNumber } from './setup-users-hand-held-numbers/setup-users-hand-held-numbers.sagas';
import { advancedUserLeadDayValues } from './setup-users-lead-day-values/setup-users-lead-day-values.sagas';
import { advancedUserOrderNotSelectedCustomer } from './setup-users-order-not-selected-in-user/setup-users-order-not-selected-in-user.sagas';
import { advancedUserOrderSelectedCustomer } from './setup-users-order-selected-in-user/setup-users-order-selected-in-user.sagas';
import { setUpSagas } from './setup/setup.sagas';
import { userSagas } from './user/user.sagas';

export default function* rootSaga() {
  yield all([
    call(userSagas),
    call(accountSagas),
    call(advancedUserSagas),
    call(advancedCustomerSagas),
    call(advancedCustomerAccountSagas),
    call(reportsSagas),
    call(advancedUserCustomerSelectedCustomer),
    call(advancedUserCustomerNotSelectedCustomer),
    call(advancedUserHandHeldNumber),
    call(advancedUserLeadDayValues),
    call(advancedUserOrderSelectedCustomer),
    call(advancedUserOrderNotSelectedCustomer),
    call(advancedUserDetailsForOrderSelectedCustomer),
    call(countLinenCartsAndClosetsSagas),
    call(inventorySagas),
    call(setUpSagas),
    call(administrationSagas),
  ]);
}

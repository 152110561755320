import {
  Box,
  Button,
  Heading,
  Layer,
  List,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Text,
} from 'grommet';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectCountLinenCartsAndClosetsSendHandheldInfoResponse } from '@State/count-linen-carts-and-closets/count-linen-carts-and-closets.selectors';

const SendToHandHeldModal = ({
  showModal,
  toggleModal,
  data,
  currentDate,
  sendCountLinenCartsAndClosetsHandheldInfo,
  sendHandheldResponse,
}) => {
  const [show, setShow] = useState(showModal);

  const [responseMessage, setResponseMessage] = useState('');
  const [showResponse, setShowResponse] = useState(false);
  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(currentDate);
  const [handheldNumber, setHandheldNumber] = useState(null);
  const [selected, setSelected] = useState('');

  useEffect(() => {
    let date = currentDate;
    if (!(currentDate instanceof moment)) {
      date = moment(currentDate);
    }
    date = date._d;
    setStartDate(date);
    setEndDate(date);
    setShow(showModal);
    setHandheldNumber(null);
    setSelected('');
    setShowResponse(false);
  }, [showModal]);

  const getList = (rows) => {
    const rowData = [];

    if (_.isObject(rows) && Object.keys(rows).length) {
      if (rows.hasOwnProperty('0')) {
        for (const i in rows) {
          rowData.push({ name: rows[i].Desc, id: rows[i].Value });
        }
      } else if (rows.hasOwnProperty('Desc')) {
        rowData.push({ name: rows.Desc, id: rows.Value });
      }
    }

    return rowData;
  };

  const onCancel = () => {
    toggleModal({ id: 'SendToHandHeld', data: [] });
    setShowResponse(false);
  };

  const onItemClick = (data) => {
    setSelected(data.index);
    setHandheldNumber(data.item.id);
  };

  const save = () => {
    if (handheldNumber !== null) {
      sendCountLinenCartsAndClosetsHandheldInfo({
        periodStart: startDate,
        periodEnd: endDate,
        iCallerUTCOffset: 0,
        handheldNumber,
      });
      setShow(false);
      setShowResponse(false);
    } else {
      setResponseMessage('Please select a handheld number.');
      setShowResponse(true);
    }
  };

  return (
    <>
      {show && (
        <Layer onEsc={onCancel} onClickOutside={onCancel}>
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell>
                  <Heading level="5" textAlign="center" margin="auto">
                    Restock Dates
                  </Heading>
                </TableCell>
                <TableCell>
                  <Heading level="5" textAlign="center" margin="auto">
                    Handheld #
                  </Heading>
                </TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Box fill="horizontal" height="small">
                    <Box fill="horizontal" direction="row-responsive" alignSelf="start">
                      <Text>Start</Text>
                      <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                    </Box>
                    <Box fill="horizontal" direction="row-responsive" alignSelf="start">
                      <Text>End</Text>
                      <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
                    </Box>
                  </Box>
                  <Button label="Send" onClick={() => save()} />
                  <Button label="Cancel" onClick={() => onCancel()} />
                </TableCell>
                <TableCell>
                  <Box fill="horizontal" height="small" overflow="auto">
                    <List
                      primaryKey="name"
                      data={getList(data)}
                      itemProps={
                        selected >= 0 ? { [selected]: { background: 'brand' } } : undefined
                      }
                      onClickItem={(datum) => onItemClick(datum)}
                    />
                  </Box>
                </TableCell>
              </TableRow>
              {/* <TableRow>
                <TableCell><Button onClick={() => save()} label="Send"></Button></TableCell>
                <TableCell><Button onClick={() => onCancel()} label="Cancel"></Button></TableCell>
              </TableRow> */}
              {showResponse && (
                <TableRow>
                  <TableCell colSpan="2">
                    <Text size="xsmall" style={{ color: 'red' }}>
                      {responseMessage}
                    </Text>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Layer>
      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  sendHandheldResponse: selectCountLinenCartsAndClosetsSendHandheldInfoResponse,
});

export default connect(mapStateToProps, null)(SendToHandHeldModal);

import { Box, Button, Form } from 'grommet';
import styled from 'styled-components';

import { boxFormStyle, BoxHeading, colors, measures } from '@ThemeMain';

const SigninBox = styled(Box)`
  margin: auto auto auto 20%;
  border: 1px solid ${colors['dark-2']};
  box-shadow: ${measures.dropShadow} ${colors.dropShadow};
  ${boxFormStyle}
  background: ${colors.innerBg}
`;

const ResetPasswordBox = styled(Box)`
  margin: 20% auto auto auto;
  border: 1px solid ${colors['dark-2']};
  box-shadow: ${measures.dropShadow} ${colors.dropShadow};
  ${boxFormStyle}
  background: ${colors.innerBg}
`;

export const SigninForm = (props) => {
  const { heading } = props;

  return (
    <SigninBox flex={false} responsive={false} fill={false}>
      <BoxHeading>{heading}</BoxHeading>
      <Box pad="medium">
        <Form>{props.children}</Form>
      </Box>
    </SigninBox>
  );
};

export const ResetPasswordForm = (props) => {
  const { heading } = props;

  return (
    <ResetPasswordBox flex={false} responsive={false} fill={false} style={{ 'min-width': '400px' }}>
      <BoxHeading style={{ 'text-align': 'center' }}>{heading}</BoxHeading>
      <Box pad="medium">
        <Form style={{ padding: '10px' }}>{props.children}</Form>
      </Box>
    </ResetPasswordBox>
  );
};
export const ViewLicenseBtn = styled(Button)`
  width: 194px;
`;

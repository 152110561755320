import { Anchor, Box, FormField, Text, TextInput } from 'grommet';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import versionFile from '@AppRoot/version.json';
import { signInStart, siteInfoStart } from '@State/user/user.actions';
import { selectCurrentUser, selectError, selectSiteInfo } from '@State/user/user.selectors';
import { Page, PrimaryButton } from '@ThemeMain';

import { SigninForm, ViewLicenseBtn } from './sign-in.styles';

const SignInPage = ({ signInStart, siteInfoStart, user, error, siteInfo }) => {
  const [userCredentials, setCredentials] = useState({
    username: '',
    password: '',
  });

  useEffect(() => {
    if (siteInfo === null) {
      siteInfoStart();
    }
  }, [siteInfo]);
  const { username, password } = userCredentials;

  const handleChange = (event) => {
    const { value, name } = event.target;
    setCredentials({ ...userCredentials, [name]: value });
  };

  const showLicense = () => {
    window.open(
      '/license-agreement',
      'targetWindow',
      `toolbar=no,
      location=no,
      status=no,
      menubar=no,
      scrollbars=yes,
      resizable=yes,
      width=790px,
      height=630px`,
    );
  };

  const showResetPassword = (e) => {
    e.preventDefault();

    window.open(
      '/reset-password',
      'targetWindow',
      `toolbar=no,
      location=no,
      status=no,
      menubar=no,
      scrollbars=yes,
      resizable=yes,
      width=400px,
      height=400px`,
    );
  };

  let authRedirect = null;
  if (user) {
    authRedirect = <Redirect to="/" />;
  }

  return (
    <>
      {authRedirect}
      <Page flex={false} responsive={false}>
        <SigninForm flex={false} responsive={false} heading="Welcome">
          <FormField label="Username">
            <TextInput
              name="username"
              value={username}
              placeholder="Username"
              onChange={handleChange}
            />
          </FormField>
          <FormField label="Password">
            <TextInput
              name="password"
              value={password}
              placeholder="Password"
              type="password"
              onChange={handleChange}
            />
          </FormField>
          <Box direction="row" justify="end" pad="small">
            <ViewLicenseBtn
              label="View License"
              margin={{ left: 'medium' }}
              onClick={() => showLicense()}
            />
            <PrimaryButton
              type="submit"
              label="Login"
              margin={{ left: 'xsmall' }}
              primary
              onClick={() => signInStart(username, password)}
            />
          </Box>
          <Box direction="row" justify="center" pad="small">
            <Anchor
              label="Reset Password"
              alignSelf="center"
              onClick={(e) => showResetPassword(e)}
            />
          </Box>
          <Box direction="row" justify="end" pad="small">
            <Text color="status-error">
              {error ? 'This user/password is not on file.' : <>&nbsp;</>}
            </Text>
          </Box>
          <Box direction="row" justify="center" pad="small">
            <Text title={versionFile.timestamp}>{`v${versionFile.version}`}</Text>
          </Box>
        </SigninForm>
      </Page>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  user: selectCurrentUser,
  siteInfo: selectSiteInfo,
  error: selectError,
});

const mapDispatchToProps = (dispatch) => ({
  signInStart: (username, password) => dispatch(signInStart({ username, password })),
  siteInfoStart: () => dispatch(siteInfoStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignInPage);

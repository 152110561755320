import { Box, Grid, TableCell, Text, TextInput } from 'grommet';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import SearchSortBar from '@Components/search-sort-bar/search-sort-bar.component';
import { filterInput, hightlightCell, INPUT_TYPE } from '@Helpers/common.helper';
import { download } from '@Helpers/download.helper';
import { Messages } from '@Pages/count-linen/count-linen-config';
import { ButtonMenu, ResultsDataTable } from '@Pages/count-linen/count-linen.styles';
import {
  dateFormat,
  InventoryHelpUrls,
  InventoryPageID,
  SetUpMessage,
  tableConfig,
} from '@Pages/inventory/inventory-config';
import {
  addItemToInventoryStart,
  createInventoryForOrderOnDateStart,
  getInventoryForInventoryIDStart,
  getInventoryFormPDFStart,
  getItemsNotInInventoryStart,
  setSelectedRcID,
} from '@State/inventory/inventory.actions';
import {
  selectAddItemToInventoryResponse,
  selectCreateInventoryForOrderOnDateResponse,
  selectInventoryForInventoryID,
  selectInventoryForInventoryIDPrevious,
  selectInventoryFormPDF,
  selectItemsNotInInventory,
  selectOrderScheduleForCustomerOnDate,
  selectRcID,
} from '@State/inventory/inventory.selectors';
import { selectCurrentCustomer } from '@State/user/user.selectors';
import { colors, measures, ResizableColumnTable, ScrollableBox, SideHeading } from '@ThemeMain';

import AddUnScheduledModal from './add-unscheduled-modal.component';

const InventoryResultBox = (props) => {
  const {
    pageID,
    pageTitle,
    type,
    currentCustomer,
    currentDate,
    inventoryForInventoryIDPrevious,
    inventoryForInventoryID,
    itemsNotInInventory,
    inventoryFormPDF,
    createInventoryForOrderOnDateResponse,
    addItemToInventoryResponse,
    orderScheduleForCustomerOnDate,
    getInventoryForInventoryID,
    getItemsNotInInventory,
    updateInventoryCounts,
    addItemToInventory,
    getInventoryFormPDF,
    createInventoryForOrderOnDate,
    setSelectedRcID,
    selectedRcID,
    userPrivileges,
    hasChanges,
    setHasChanges,
  } = props;
  const [showAddUnscheduledModal, setShowAddUnscheduledModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [rightTableData, setRightTableData] = useState([]);
  const [prevValue, setPrevValue] = useState(0);
  const [searchKey, setSearchKey] = useState(tableConfig[pageID].searchKeys[0]);
  const [searchValue, setSearchValue] = useState('');
  const [searchKeyRight, setSearchKeyRight] = useState(
    pageID === InventoryPageID.physicalInventory ? tableConfig[pageID].searchKeysRight[0] : '',
  );
  const [searchValueRight, setSearchValueRight] = useState('');
  const [invtRcID, setInvtRcID] = useState(0);
  const [saveCancelDisabledStatus, setSaveCancelDisabledStatus] = useState(true);
  const [invtID, setInvtID] = useState(0);
  const [selectedID, setSelectedID] = useState(0);
  const [selectedIDRightTable, setSelectedIDRightTable] = useState(0);

  useEffect(() => {
    if (hasChanges) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  });

  useEffect(() => {
    setSearchValue('');
    setSearchValueRight('');
    setSearchKey(tableConfig[pageID].searchKeys[0]);
    setSearchKeyRight(
      pageID === InventoryPageID.physicalInventory ? tableConfig[pageID].searchKeysRight[0] : '',
    );
  }, [pageID]);

  /** * Product table data changes ** */
  useEffect(() => {
    if (typeof inventoryForInventoryID.data !== 'undefined') {
      let ID = null;

      if (inventoryForInventoryID.data.inventoryItemTable !== undefined) {
        ID = selectedRcID || inventoryForInventoryID.data.inventoryItemTable[0].RcID;
      }

      if (pageID === InventoryPageID.physicalInventory) {
        setSelectedIDRightTable(ID);
        setRightTableData(inventoryForInventoryID.data.inventoryItemTable);
      } else {
        setSelectedRcID(ID);
        setTableData(inventoryForInventoryID.data.inventoryItemTable);
      }
      setSearchValue('');
      setHasChanges(false);
      setInvtID(inventoryForInventoryID.data.inventoryTable.RcID);
    } else if (pageID === InventoryPageID.physicalInventory) {
      setRightTableData([]);
    } else {
      setTableData([]);
    }
  }, [inventoryForInventoryID]);

  useEffect(() => {
    if (typeof orderScheduleForCustomerOnDate.data !== 'undefined') {
      setTableData(orderScheduleForCustomerOnDate.data);
      if (orderScheduleForCustomerOnDate?.data?.length > 0) {
        const key = pageID === InventoryPageID.physicalInventory ? 'InvtRcID' : 'RcID';
        let index = selectedRcID
          ? orderScheduleForCustomerOnDate.data.findIndex((item) => item.RcID === selectedRcID)
          : 0;
        index = index > -1 ? index : 0;

        const initialData = orderScheduleForCustomerOnDate.data[index];

        if (parseInt(initialData[key], 10) !== 0) {
          setInvtRcID(initialData[key]);
          setSelectedRcID(initialData.RcID);
          getInventoryForInventoryID({ inventoryID: initialData[key] });
        } else {
          createInventoryForOrderOnDate({
            customerID: parseInt(currentCustomer.RcID, 10),
            requestedDay: moment(currentDate).format('YYYY-MM-DDT12:00:00'),
            orderID: parseInt(initialData.RcID, 10),
            iCallerUTCOffset: 0,
          });
        }
      }
    } else {
      setTableData([]);
    }
  }, [orderScheduleForCustomerOnDate]);

  useEffect(() => {
    if (createInventoryForOrderOnDateResponse !== null) {
      setInvtRcID(createInventoryForOrderOnDateResponse.data);
      getInventoryForInventoryID({ inventoryID: createInventoryForOrderOnDateResponse.data });
    }
  }, [createInventoryForOrderOnDateResponse]);

  useEffect(() => {
    if (addItemToInventoryResponse !== null) {
      getInventoryForInventoryID({ inventoryID: invtRcID });
    }
  }, [addItemToInventoryResponse]);

  useEffect(() => {
    if (inventoryFormPDF !== null) {
      // const linkSource = `data:application/pdf;base64,${inventoryFormPDF.data.ReptData}`;
      // downloadPDF(linkSource, inventoryFormPDF.data.ReptDesc);
      // TODO: untested 01ikaxb
      download({
        name: 'inventory',
        type: 'pdf',
        base64Data: inventoryFormPDF.data.ReptData,
      });
    }
  }, [inventoryFormPDF]);

  const filter = (type = 'left') => {
    const searchResults = [];
    const customerOnDateData = orderScheduleForCustomerOnDate?.error
      ? []
      : orderScheduleForCustomerOnDate?.data || [];
    const inventoryForInventoryIDData = inventoryForInventoryID?.data?.inventoryItemTable || [];

    let currentData =
      pageID === InventoryPageID.physicalInventory
        ? [...customerOnDateData]
        : [...inventoryForInventoryIDData];

    if (type === 'right') {
      currentData = [...inventoryForInventoryIDData];
    }
    const sKeys = type === 'right' ? searchKeyRight : searchKey;
    const sValue = type === 'right' ? searchValueRight : searchValue;

    for (const i in currentData) {
      if (typeof currentData[i][sKeys.id] !== 'undefined') {
        const value = currentData[i][sKeys.id].toLowerCase();
        if (value.includes(sValue.toLowerCase())) {
          searchResults.push(currentData[i]);
        }
      }
    }

    if (type === 'right') {
      setRightTableData(searchResults);
    } else {
      setTableData(searchResults);
    }
  };

  const evaluateModifiedItem = (data) => {
    const tempData =
      pageID === InventoryPageID.physicalInventory ? [...rightTableData] : [...tableData];
    data.value = data.value === '' ? 0 : parseInt(data.value, 10);
    updateTableData('RcID', data.id, data.key, data.value, tempData);

    if (pageID === InventoryPageID.physicalInventory) {
      setRightTableData(tempData);
    } else {
      setTableData(tempData);
    }
    let dataChange = true;
    const prevData = inventoryForInventoryIDPrevious.data.inventoryItemTable;
    const currData = inventoryForInventoryID.data.inventoryItemTable;
    Object.keys(prevData).forEach((key) => {
      if (prevData[key].TotlNmbr !== currData[key].TotlNmbr) {
        dataChange = false;
      }
    });
    setHasChanges(!dataChange);
  };

  const updateTableData = (primaryKey, primaryKeyValue, prop, value, obj) => {
    let matchFound = false;
    if (obj.constructor === Object || obj.constructor === Array) {
      Object.keys(obj).forEach((key) => {
        if (!matchFound) {
          if (key === primaryKey && obj[key] === primaryKeyValue) {
            obj[prop] = parseInt(value, 10);
            matchFound = true;
          } else {
            updateTableData(primaryKey, primaryKeyValue, prop, value, obj[key]);
          }
        } else {
        }
      });
    }
  };

  const getOrderItems = (data) => {
    if ('OrdrDesc' in data) {
      setSelectedRcID(data.RcID);
    }

    if (parseInt(data.InvtRcID, 10) === 0) {
      createInventoryForOrderOnDate({
        customerID: parseInt(currentCustomer.RcID, 10),
        requestedDay: moment(currentDate).format(dateFormat.param),
        orderID: parseInt(data.RcID, 10),
        iCallerUTCOffset: 0,
      });
    } else {
      setInvtRcID(data.InvtRcID);
      getInventoryForInventoryID({ inventoryID: data.InvtRcID });
    }
  };

  const assignClass = (datum, type) => {
    if (
      (selectedRcID === datum.RcID && type === 'columns') ||
      (selectedIDRightTable === datum.RcID && type === 'colmnsRight')
    ) {
      return 'white-label';
    }
    return '';
  };

  const onRowClick = (type, datum) => {
    if (
      (type === 'columns' &&
        pageID === InventoryPageID.physicalInventory &&
        hasChanges &&
        selectedRcID !== datum.RcID &&
        window.confirm(Messages.unSaveChanges)) ||
      (pageID === InventoryPageID.physicalInventory && !hasChanges)
    ) {
      getOrderItems(datum);
    } else if (pageID === InventoryPageID.retirement || pageID === InventoryPageID.injection) {
      setSelectedRcID(datum.RcID);
    } else if (type !== 'columns') {
      setSelectedIDRightTable(datum.RcID);
    }
  };

  const onFocus = (type, datum, event) => {
    event.target.select();
    if (type === 'columns') {
      setSelectedRcID(datum.RcID);
    } else {
      setSelectedIDRightTable(datum.RcID);
    }
    setPrevValue(event.target.value);
  };

  const getColumns = (type = 'columns') => {
    const columns = [];
    const columnConfig = tableConfig[pageID][type];

    Object.entries(columnConfig).forEach(([key, columnInfo]) => {
      const column = {
        property: key,
        header: <Text>{columnInfo.label}</Text>,
      };

      switch (columnInfo.type) {
        case 'text':
          column.render = (datum) => (
            <Box
              fill="horizontal"
              className={assignClass(datum, type)}
              onClick={() => onRowClick(type, datum)}
            >
              <Text>{datum[key]}</Text>
            </Box>
          );
          break;
        case 'textInput':
          if (userPrivileges && userPrivileges.canModifyCountInventory) {
            column.render = (datum) => (
              <TextInput
                type="text"
                value={datum[key]}
                className={assignClass(datum, type)}
                onClick={(event) => hightlightCell(event)}
                onFocus={(event) => {
                  hightlightCell(event);
                  onFocus(type, datum, event);
                }}
                onBlur={(event) => event.target.closest('td').classList.remove('cell-focus')}
                onKeyDown={(event) => filterInput(event, INPUT_TYPE.int, ['-'])}
                onChange={(event) =>
                  evaluateModifiedItem({
                    id: datum.RcID,
                    key,
                    value: event.target.value,
                  })
                }
              />
            );
          } else {
            column.render = (datum) => (
              <Text className={assignClass(datum, type)}>{datum[key]}</Text>
            );
          }

          break;
        default:
          break;
      }
      columns.push(column);
    });

    return columns;
  };

  // const downloadPDF = (url, filename) => {
  //   var link = document.createElement('a');
  //   link.href = url;
  //   link.download = filename + '.pdf';
  //   link.dispatchEvent(new MouseEvent('click'));
  // };

  const saveChanges = () => {
    updateInventoryCounts({
      formData: {
        inventoryItemTable: inventoryForInventoryIDPrevious.data.inventoryItemTable,
        modifiedInventoryItemTable: inventoryForInventoryID.data.inventoryItemTable,
      },
    });
    setHasChanges(false);
  };

  const helpButtonClick = () => {
    // TODO: [LH-276] env variable the first part
    const win = window.open(
      `https://linenhelper-help-documents.s3.amazonaws.com${InventoryHelpUrls[pageID]}`,
      '_blank',
    );
    win.focus();
  };

  const toggleModal = () => {
    if (!showAddUnscheduledModal) {
      getItemsNotInInventory({
        iInventoryID: inventoryForInventoryID?.data?.inventoryTable?.RcID || 0,
      });
    }
    setShowAddUnscheduledModal(!showAddUnscheduledModal);
  };
  const evaluateAddUnscheduled = (data) => {
    addItemToInventory({
      iInventoryID: data.id,
      iItemID: data.iItemID,
      iItemType: data.iItemType,
    });
  };

  const dataTableBodySize =
    pageID === InventoryPageID.physicalInventory
      ? measures.dataTableDoubleHeaderBodyHeight
      : measures.dataTableBodyHeight;
  const handleGetInventoryFromPdf = async () => {
    const data = await getInventoryFormPDF({
      customerRcID: currentCustomer.RcID,
      iCallerUTCOffset: 0,
      selectedDate: currentDate,
    });
    console.log('>>data', data);
  };
  return (
    <>
      <Prompt when={hasChanges} message={SetUpMessage.unSaveChanges} />
      <Grid
        areas={[
          { name: 'header', start: [0, 0], end: [0, 0] },
          { name: 'report-table', start: [0, 1], end: [0, 1] },
        ]}
        className="sideContent"
        columns={['100%', '100%', '100%', '100%']}
        rows={['auto', 'auto', 'auto', 'flex']}
        gap="0px"
        justify="stretch"
      >
        <Box
          gridArea="header"
          border={{ color: colors.faintestBlue, side: 'bottom', size: 'medium' }}
        >
          <SideHeading level="3">{pageTitle}</SideHeading>
          <Box direction="row" gap="small" pad="small">
            {userPrivileges && userPrivileges.canModifyCountInventory && (
              <ButtonMenu
                type="button"
                label="Save Changes"
                disabled={!hasChanges}
                onClick={saveChanges}
              />
            )}

            {userPrivileges && userPrivileges.canModifyCountInventory && (
              <ButtonMenu
                type="button"
                label="Cancel"
                disabled={!hasChanges}
                onClick={() => {
                  getInventoryForInventoryID({ inventoryID: invtID });
                  setHasChanges(false);
                }}
              />
            )}

            {pageID !== InventoryPageID.physicalInventory ? (
              <SearchSortBar
                options={tableConfig[pageID].searchKeys}
                searchKey={searchKey}
                setSearchKey={(data) => setSearchKey(data)}
                searchValue={searchValue}
                setSearchValue={(data) => setSearchValue(data)}
                filter={() => filter()}
              />
            ) : null}

            {pageID === InventoryPageID.physicalInventory ? (
              <ButtonMenu
                type="button"
                label="View Inventory"
                alignSelf="start"
                onClick={handleGetInventoryFromPdf}
              />
            ) : null}
            {pageID === InventoryPageID.physicalInventory &&
            userPrivileges &&
            userPrivileges.canModifyCountInventory ? (
              <ButtonMenu
                type="button"
                label="Add Unscheduled Products"
                alignSelf="start"
                onClick={() => toggleModal()}
              />
            ) : null}
            <ButtonMenu type="button" label="Help" onClick={helpButtonClick} />
          </Box>
        </Box>
        <Box gridArea="report-table" background={colors.innerBg}>
          <ResizableColumnTable
            style={{ marginTop: '20px' }}
            className={`${
              pageID === InventoryPageID.physicalInventory ? 'pi-table td-block' : 'wd-100'
            }`}
          >
            <TableCell>
              <ScrollableBox>
                {pageID === InventoryPageID.physicalInventory ? (
                  <SearchSortBar
                    options={tableConfig[pageID].searchKeys}
                    searchKey={searchKey}
                    setSearchKey={(data) => setSearchKey(data)}
                    searchValue={searchValue}
                    setSearchValue={(data) => setSearchValue(data)}
                    filter={() => filter()}
                  />
                ) : null}
                {tableData?.length > 0 && (
                  <ResultsDataTable
                    primaryKey="RcID"
                    key="left"
                    bodySize={dataTableBodySize || 0}
                    columns={getColumns()}
                    data={tableData || []}
                    step={tableData ? tableData?.length : 0}
                    rowProps={{ [selectedRcID]: { background: colors.brand } }}
                    search
                    sortable
                  />
                )}
              </ScrollableBox>
            </TableCell>
            {pageID === InventoryPageID.physicalInventory ? (
              <TableCell>
                <ScrollableBox>
                  <SearchSortBar
                    options={tableConfig[pageID].searchKeysRight}
                    searchKey={searchKeyRight}
                    setSearchKey={(data) => setSearchKeyRight(data)}
                    searchValue={searchValueRight}
                    setSearchValue={(data) => setSearchValueRight(data)}
                    filter={() => filter('right')}
                  />
                  <ResultsDataTable
                    primaryKey="RcID"
                    key="right"
                    bodySize={dataTableBodySize}
                    columns={getColumns('colmnsRight')}
                    data={rightTableData}
                    step={rightTableData ? rightTableData.length : 0}
                    rowProps={{ [selectedIDRightTable]: { background: colors.brand } }}
                    search
                    sortable
                  />
                </ScrollableBox>
              </TableCell>
            ) : null}
          </ResizableColumnTable>
        </Box>
      </Grid>
      <AddUnScheduledModal
        addUnScheduledEvent={evaluateAddUnscheduled}
        rcID={invtRcID}
        toggleModal={toggleModal}
        showModal={showAddUnscheduledModal}
        type="Items"
        currentDate={currentDate}
        data={itemsNotInInventory}
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  currentCustomer: selectCurrentCustomer,
  inventoryForInventoryID: selectInventoryForInventoryID,
  inventoryForInventoryIDPrevious: selectInventoryForInventoryIDPrevious,
  itemsNotInInventory: selectItemsNotInInventory,
  orderScheduleForCustomerOnDate: selectOrderScheduleForCustomerOnDate,
  addItemToInventoryResponse: selectAddItemToInventoryResponse,
  inventoryFormPDF: selectInventoryFormPDF,
  selectedRcID: selectRcID,
  createInventoryForOrderOnDateResponse: selectCreateInventoryForOrderOnDateResponse,
});

const mapDispatchToProps = (dispatch) => ({
  getInventoryForInventoryID: (data) => dispatch(getInventoryForInventoryIDStart(data)),
  getItemsNotInInventory: (data) => dispatch(getItemsNotInInventoryStart(data)),
  addItemToInventory: (data) => dispatch(addItemToInventoryStart(data)),
  getInventoryFormPDF: (data) => dispatch(getInventoryFormPDFStart(data)),
  setSelectedRcID: (data) => dispatch(setSelectedRcID(data)),
  createInventoryForOrderOnDate: (data) => dispatch(createInventoryForOrderOnDateStart(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryResultBox);

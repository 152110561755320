import SetupAdvancedCustomerAccountActionTypes from './setup-advanced-customers-account-type.types';

const INITIAL_STATE = {
  advanced_customer_accounts: null,
  error: null,
};

const setupAdvancedCustomerAccountReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SetupAdvancedCustomerAccountActionTypes.GET_ADVANCED_CUSTOMER_ACCOUNT_SUCCESS:
      return {
        ...state,
        advanced_customer_accounts: action.payload,
        error: null,
      };

    case SetupAdvancedCustomerAccountActionTypes.GET_ADVANCED_CUSTOMER_ACCOUNT_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default setupAdvancedCustomerAccountReducer;

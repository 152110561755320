import '@AppRoot/index.css';

import { Box, DataTable, Tab, TableCell, Tabs, Text } from 'grommet';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import ResultBox from '@Components/administration/result-box.component';
import SideBarControls from '@Components/administration/sidebar-controls.component';
import SystemConfigForm from '@Components/administration/system-config-form.component';
import Modal from '@Components/modal/modal.component';
import { Messages } from '@Pages/count-linen/count-linen-config';
import { ButtonMenu } from '@Pages/count-linen/count-linen.styles';
import { SetUpPageID } from '@Pages/setup/setup-config';
import {
  deleteMobileDeviceOptionsStart,
  getMobileDeviceTabOptionsStart,
  setGeneralTabOptionsStart,
  setMobileDeviceTabOptionsStart,
  updateGeneralOptionsStart,
  updateMobileDeviceTabOptionsStart,
} from '@State/administration/administration.actions';
import {
  selectDeleteMobileDeviceOptionsResponse,
  selectGeneralTabOptions,
  selectMobileDeviceTabOptions,
  selectMobileDeviceTabs,
  selectPrevGeneralTabOptions,
  selectPrevMobileDeviceTabOptions,
  selectUpdateGeneralOptionsResponse,
  selectUpdateMobileDeviceTabOptionsResponse,
} from '@State/administration/administration.selectors';
import { colors, ResizableColumnTable, SideHeading } from '@ThemeMain';

import { Links } from './administration.config';

const AdministrationPanel = (props) => {
  const {
    currentCustomer,
    privileges,
    pageID,
    pageTitle,
    formData,
    setFormData,
    getSelectionTree,
    getGeneralTabOptions,
    getMobileDeviceTabs,
    setGeneralTabOptions,
    updateGeneralOptions,
    getMobileDeviceTabOptions,
    setMobileDeviceTabOptions,
    updateMobileDeviceTabOption,
    deleteMobileDeviceOptions,
    selectionTree,
    getData,
    mainData,
    generalTabOptions,
    prevGeneralTabOptions,
    updateGeneralOptionsResponse,
    mobileDeviceTabs,
    mobileDeviceTabOptions,
    prevMobileDeviceTabOptions,
    updateMobileDeviceTabOptionsResponse,
    deleteMobileDeviceOptionsResponse,
  } = props;

  const [hasChanges, setHasChanges] = useState(false);
  const [selectedMobile, setSelectedMobile] = useState({ ObjtDesc: '' });
  const [activeIndex, setactiveIndex] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (hasChanges) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  });

  useEffect(() => {
    if (mobileDeviceTabs !== null) {
      if (mobileDeviceTabs.status === 'ok') {
        const data = mobileDeviceTabs.data.TransactionTable[0];
        setSelectedMobile({
          ObjtDesc: data.ObjtDesc._text,
          RcID: data.RcID._text,
        });
        getMobileDeviceTabOptions({ mobileRcID: data.RcID._text });
      }
    }
  }, [mobileDeviceTabs, getMobileDeviceTabOptions]);

  useEffect(() => {
    if (updateGeneralOptionsResponse !== null) {
      if (updateGeneralOptionsResponse.status === 'ok') {
        getGeneralTabOptions();
      }
    }
  }, [updateGeneralOptionsResponse, getGeneralTabOptions]);

  useEffect(() => {
    if (updateMobileDeviceTabOptionsResponse !== null) {
      if (updateMobileDeviceTabOptionsResponse.status === 'ok') {
        getMobileDeviceTabs();
      }
    }
  }, [updateMobileDeviceTabOptionsResponse, getMobileDeviceTabs]);

  useEffect(() => {
    if (deleteMobileDeviceOptionsResponse !== null) {
      if (deleteMobileDeviceOptionsResponse.status === 'ok') {
        getMobileDeviceTabs();
      }
    }
  }, [deleteMobileDeviceOptionsResponse, getMobileDeviceTabs]);

  const formatTableData = (data) => {
    const result = [];
    const rows = data || [];

    rows.forEach((item) => {
      result.push({
        ObjtDesc: item.ObjtDesc._text,
        RcID: item.RcID._text,
      });
    });

    return result;
  };

  const onCancel = () => {
    setGeneralTabOptions(JSON.parse(JSON.stringify(prevGeneralTabOptions)));
    setMobileDeviceTabOptions(JSON.parse(JSON.stringify(prevMobileDeviceTabOptions)));
    setHasChanges(false);
  };

  const onSave = () => {
    if (activeIndex === 0) {
      const data = { ...generalTabOptions.data };
      delete data._attributes;
      updateGeneralOptions({ FormData: data });
    } else {
      updateMobileDeviceTabOption({
        FormData: mobileDeviceTabOptions.data,
        mobileRcID: selectedMobile.RcID,
      });
    }
    setHasChanges(false);
  };

  const onActive = (index) => {
    if ((hasChanges && window.confirm(Messages.unSaveChanges)) || !hasChanges) {
      onCancel();
      setactiveIndex(index);
    }
  };

  let contentElement = null;

  if (pageID === SetUpPageID.advanceSystemConfiguration) {
    contentElement = (
      <Box direction="column" background={colors.innerBg} pad="medium">
        <Box direction="row" gap="small" fill="horizontal">
          <Box direction="row" gap="small" pad="small" fill="horizontal" height="60px">
            <ButtonMenu type="button" label="Save" disabled={!hasChanges} onClick={onSave} />
            <ButtonMenu type="button" label="Cancel" disabled={!hasChanges} onClick={onCancel} />
            {activeIndex === 1 && (
              <ButtonMenu type="button" label="Delete" onClick={() => setShowDeleteModal(true)} />
            )}
            <ButtonMenu
              type="button"
              label="Help"
              onClick={() => {
                window.open(Links[pageID].help, '_blank');
              }}
            />
          </Box>
        </Box>
        <Tabs activeIndex={activeIndex} onActive={(nextIndex) => onActive(nextIndex)}>
          <Tab title="General">
            <Box direction="row" gap="small" justify="stretch">
              <SystemConfigForm
                tabID="general"
                options={generalTabOptions}
                hasChanges={hasChanges}
                setHasChanges={setHasChanges}
              />
            </Box>
          </Tab>
          <Tab title="Mobile Devices">
            <ResizableColumnTable>
              <TableCell>
                <DataTable
                  columns={[
                    {
                      property: 'ObjtDesc',
                      header: <Text>Device ID - Description</Text>,
                      render: (datum) => (
                        <Text
                          className={
                            selectedMobile.ObjtDesc === datum.ObjtDesc ? 'white-label' : ''
                          }
                        >
                          {datum.ObjtDesc}
                        </Text>
                      ),
                    },
                  ]}
                  data={
                    mobileDeviceTabs !== null
                      ? formatTableData(mobileDeviceTabs.data.TransactionTable)
                      : []
                  }
                  rowProps={{
                    [selectedMobile.ObjtDesc]: { background: colors.brand },
                  }}
                  onClickRow={({ datum }) => {
                    setSelectedMobile(datum);
                    getMobileDeviceTabOptions({ mobileRcID: datum.RcID });
                  }}
                />
              </TableCell>
              <TableCell>
                <SystemConfigForm
                  tabID="mobile"
                  selectedMobile={selectedMobile}
                  options={mobileDeviceTabOptions}
                  hasChanges={hasChanges}
                  setHasChanges={setHasChanges}
                  setErrorMessage={(data) => setErrorMessage(data)}
                />
              </TableCell>
            </ResizableColumnTable>
          </Tab>
        </Tabs>
      </Box>
    );
  } else if (pageID === SetUpPageID.advanceLogTransactions) {
    contentElement = (
      <Box direction="row" gap="small" height="large">
        <SideBarControls
          currentCustomer={currentCustomer}
          pageID={pageID}
          formData={formData}
          setFormData={setFormData}
          getSelectionTree={getSelectionTree}
          selectionTree={selectionTree}
          getData={getData}
          setShowErrorModal={(data) => setShowErrorModal(data)}
          setErrorMessage={(data) => setErrorMessage(data)}
        />
        <Box background={colors.innerBg} fill>
          <SideHeading level="5">{pageTitle}</SideHeading>
          <ResultBox pageID={pageID} mainData={mainData} />
        </Box>
      </Box>
    );
  }
  return (
    <>
      <>
        <Prompt when={hasChanges} message={Messages.unSaveChanges} />
        {contentElement}
        <Modal
          show={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          title="Delete Mobile Device"
          confirmLabel="Yes"
          cancelLabel="No"
          setShow={setShowDeleteModal}
          onCancel={() => setShowDeleteModal(false)}
          onConfirm={() => deleteMobileDeviceOptions({ mobileRcID: selectedMobile.RcID })}
        >
          <Text>{`Are you sure you want to delete "${selectedMobile.ObjtDesc}" ?`}</Text>
        </Modal>
        <Modal
          title="Error"
          show={showErrorModal}
          setShow={setShowErrorModal}
          confirmAction={() => setShowErrorModal(false)}
          cancelLabel=""
        >
          <Text>{errorMessage}</Text>
        </Modal>
      </>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  generalTabOptions: selectGeneralTabOptions,
  prevGeneralTabOptions: selectPrevGeneralTabOptions,
  updateGeneralOptionsResponse: selectUpdateGeneralOptionsResponse,
  mobileDeviceTabs: selectMobileDeviceTabs,
  mobileDeviceTabOptions: selectMobileDeviceTabOptions,
  prevMobileDeviceTabOptions: selectPrevMobileDeviceTabOptions,
  updateMobileDeviceTabOptionsResponse: selectUpdateMobileDeviceTabOptionsResponse,
  deleteMobileDeviceOptionsResponse: selectDeleteMobileDeviceOptionsResponse,
});

const mapDispatchToProps = (dispatch) => ({
  setGeneralTabOptions: (data) => dispatch(setGeneralTabOptionsStart(data)),
  updateGeneralOptions: (data) => dispatch(updateGeneralOptionsStart(data)),
  getMobileDeviceTabOptions: (data) => dispatch(getMobileDeviceTabOptionsStart(data)),
  setMobileDeviceTabOptions: (data) => dispatch(setMobileDeviceTabOptionsStart(data)),
  updateMobileDeviceTabOption: (data) => dispatch(updateMobileDeviceTabOptionsStart(data)),
  deleteMobileDeviceOptions: (data) => dispatch(deleteMobileDeviceOptionsStart(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdministrationPanel);

import '@AppRoot/index.css';

import { useState } from 'react';

import CountLinenResultBox from '@Components/count-linen/count-linen-result-box.component';
import SideBarControl from '@Components/count-linen/sidebar-controls.component';
import { Panel } from '@ThemeMain';

import { CountLinenPageID } from './count-linen-config';

const CountLinenPanel = (props) => {
  const {
    currentCustomer,
    pageID,
    userPrivileges,
    activeOrder,
    orderScheduleParams,
    setOrderScheduleParams,
    orderScheduleData,
    gaugeInfo,
    getOrderSchedule,
    getOrdersNotScheduled,
    getDeliveryItemRights,
    getDelivery,
    getGaugeInfo,
    updateDeliveryCount,
    setActiveOrder,
    createLinenRoomDeliveryForCustomerOnDate,
  } = props;
  const [currentDate, setCurrentDate] = useState(new Date());
  const [laundryWeight, setLaundryWeight] = useState(0);
  const [soilWeight, setSoilWeight] = useState(0);
  const [laundryCharge, setLaundryCharge] = useState(0);
  const [onInitialize, setOnInitialize] = useState(true);
  const [changes, setChanges] = useState(false);
  const [productsLoaded, setProductsLoaded] = useState(false);

  const updateSharedVariables = (data) => {
    switch (data.key) {
      case 'date':
        setCurrentDate(data.value);
        break;
      case 'laundryWeight':
        setLaundryWeight(data.value);
        break;
      case 'soilWeight':
        setSoilWeight(data.value);
        break;
      case 'laundryCharge':
        setLaundryCharge(data.value);
        break;
      case 'onInitialize':
        setOnInitialize(data.value);
        break;
      default:
        break;
    }
  };

  return (
    <>
      {currentCustomer ? (
        <Panel direction="row" gap="small" justify="stretch" className="count-linen-panel">
          <SideBarControl
            currentCustomer={currentCustomer}
            pageID={pageID}
            type={pageID === CountLinenPageID.cartAndClosets ? 'Restock' : 'Inventory'}
            orderScheduleParams={orderScheduleParams}
            setOrderScheduleParams={setOrderScheduleParams}
            activeOrder={activeOrder}
            currentDate={currentDate}
            updateSharedVariables={(data) => updateSharedVariables(data)}
            getDelivery={(data) => getDelivery(data)}
            gaugeInfo={gaugeInfo}
            getOrderSchedule={(data) => getOrderSchedule(data)}
            getOrdersNotScheduled={(data) => getOrdersNotScheduled(data)}
            getGaugeInfo={(data) => getGaugeInfo(data)}
            updateDeliveryCount={(data) => updateDeliveryCount(data)}
            laundryWeight={laundryWeight}
            soilWeight={soilWeight}
            laundryCharge={laundryCharge}
            changes={changes}
            productsLoaded={productsLoaded}
            setProductsLoaded={(data) => setProductsLoaded(data)}
            setChanges={(data) => setChanges(data)}
            createLinenRoomDeliveryForCustomerOnDate={(data) =>
              createLinenRoomDeliveryForCustomerOnDate(data)
            }
            onInitialize={onInitialize}
          />
          <CountLinenResultBox
            activeOrder={activeOrder}
            currentDate={currentDate}
            orderScheduleParams={orderScheduleParams}
            setOrderScheduleParams={setOrderScheduleParams}
            orderScheduleData={orderScheduleData}
            getOrderSchedule={getOrderSchedule}
            getDelivery={(data) => getDelivery(data)}
            getDeliveryItemRights={(data) => getDeliveryItemRights(data)}
            updateDeliveryCount={(data) => updateDeliveryCount(data)}
            updateSharedVariables={(data) => updateSharedVariables(data)}
            getGaugeInfo={(data) => getGaugeInfo(data)}
            laundryWeight={laundryWeight}
            soilWeight={soilWeight}
            laundryCharge={laundryCharge}
            pageID={pageID}
            setChanges={(data) => setChanges(data)}
            changes={changes}
            productsLoaded={productsLoaded}
            setProductsLoaded={(data) => setProductsLoaded(data)}
            setActiveOrder={(data) => setActiveOrder(data)}
            userPrivileges={userPrivileges}
            onInitialize={onInitialize}
          />
        </Panel>
      ) : null}
    </>
  );
};

export default CountLinenPanel;

import SetupUsersDetailsForOrderSelectedActionTypes from './setup-users-details-for-order-selected-in-user.types';

const INITIAL_STATE = {
  details_for_order: null,
  details_order: null,
};

const setupUserDetailsForOrderSelectedReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SetupUsersDetailsForOrderSelectedActionTypes.GET_DETAILS_FOR_ORDER_SELECTED_SUCCESS:
      return {
        ...state,
        details_for_order: action.payload,
        error: null,
      };

    case SetupUsersDetailsForOrderSelectedActionTypes.GET_DETAILS_FOR_ORDER_SELECTED_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default setupUserDetailsForOrderSelectedReducer;

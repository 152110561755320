import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import api from '@API';

import { selectCurrentCustomer, selectSiteInfo } from '../user/user.selectors';
import {
  getAdvancedCustomerFailure,
  getAdvancedCustomerSuccess,
} from './setup-advanced-customers.actions';
import SetupAdvancedCustomerActionTypes from './setup-advanced-customers.types';

const advancedCustomerApi = (params) => {
  return api.post('setuptables/get-page-of-customer', params);
};

export function* getAdvancedCustomer(action) {
  try {
    const siteInfo = yield select(selectSiteInfo);
    const customerInfo = yield select(selectCurrentCustomer);
    const params = {};
    const { data } = yield call(advancedCustomerApi, params);
    yield put(getAdvancedCustomerSuccess(data.data));
  } catch (error) {
    yield put(getAdvancedCustomerFailure(error));
  }
}

export function* onGetAdvancedCustomerStart() {
  yield takeLatest(
    SetupAdvancedCustomerActionTypes.GET_ADVANCED_CUSTOMER_START,
    getAdvancedCustomer,
  );
}

export function* advancedCustomerSagas() {
  yield all([call(onGetAdvancedCustomerStart)]);
}

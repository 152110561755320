import { createSelector } from 'reselect';

const selectAdvancedUser = (state) => state.setup_advanced_user;

export const selectAdvancedUsers = createSelector(
  [selectAdvancedUser],
  (advancedUser) => advancedUser.advanced_users,
);

export const selectAdvancedCurrentUsers = createSelector(
  [selectAdvancedUser],
  (currentuser) => currentuser.advanced_current_user,
);

export const selectError = createSelector([selectAdvancedUser], (currentuser) => currentuser.error);

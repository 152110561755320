import { createSelector } from 'reselect';

const selectOrderNotSelected = (state) => state.setup_advanced_user_order_not_selected;
const selectCustomerSelected = (state) => state.setup_advanced_user_customer_selected;
const selectAdvancedCurrentUser = (state) => state.setup_advanced_user.advanced_current_user;

export const selectOrdersNotSelected = createSelector(
  [selectOrderNotSelected],
  (orderNotSelected) => orderNotSelected.order_not_selected,
);

export const selectUserOrdersNotSelected = createSelector(
  [selectOrderNotSelected, selectCustomerSelected, selectAdvancedCurrentUser],
  (orderNotSelected, customerSelected, currentUser) => {
    if (currentUser.PrivEvryCust !== undefined && currentUser.PrivEvryCust === '1') {
      return orderNotSelected.order_not_selected;
    }
    if (
      orderNotSelected.order_not_selected !== null &&
      customerSelected.customer_selected !== null
    ) {
      const customers = customerSelected.customer_selected.map((customer) => customer.ObjDesc);
      const tempOrders = orderNotSelected.order_not_selected;
      const orders = tempOrders.filter((order) => {
        let addOrder = false;
        customers.forEach(function (customer) {
          const orderName = order.Name;
          if (orderName.includes(customer)) {
            addOrder = true;
          }
        });
        return addOrder;
      });
      return orders;
    }
    return [];
  },
);

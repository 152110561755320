import { Grid } from 'grommet';

import SetUpResultBox from '@Components/setup/setup-result-box.component';
import { colors, Panel } from '@ThemeMain';

const SetUpPanel = (props) => {
  const {
    currentCustomer,
    canModify,
    canView,
    pageID,
    pageTitle,
    type,
    updateMainData,
    getMainData,
    getRightTableData,
    updateRightTableData,
    getProducts,
    addProducts,
    validateDeletion,
    inactivate,
    getSnapshot,
  } = props;

  return (
    <>
      {currentCustomer ? (
        <Grid
          areas={[{ name: 'main', start: [0, 0], end: [1, 0] }]}
          columns={['medium']}
          rows={['large']}
          gap="small"
          justify="stretch"
        >
          <Panel gridArea="main" fill="horizontal" background={colors.background}>
            <SetUpResultBox
              pageTitle={pageTitle}
              pageID={pageID}
              canModify={canModify}
              canView={canView}
              type={type}
              updateMainData={updateMainData}
              updateRightTableData={updateRightTableData}
              getMainData={getMainData}
              getRightTableData={getRightTableData}
              getProducts={getProducts}
              addProducts={addProducts}
              validateDeletion={validateDeletion}
              inactivate={inactivate}
              getSnapshot={getSnapshot}
            />
          </Panel>
        </Grid>
      ) : null}
    </>
  );
};

export default SetUpPanel;

import SetupUsersHandHeldNumberActionTypes from './setup-users-hand-held-numbers.types';

const INITIAL_STATE = {
  hand_held_number: null,
};

const setupUserHandHeldNumberReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SetupUsersHandHeldNumberActionTypes.GET_HAND_HELD_NUMBER_SUCCESS:
      return {
        ...state,
        hand_held_number: action.payload,
        error: null,
      };

    case SetupUsersHandHeldNumberActionTypes.GET_HAND_HELD_NUMBER_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default setupUserHandHeldNumberReducer;

import { Box, Select, Text, TextInput } from 'grommet';
import { useEffect, useState } from 'react';

const SystemConfigForm = (props) => {
  const { tabID, options, setHasChanges, selectedMobile } = props;

  const [inputs, setInputs] = useState([]);
  const [selections, setSelections] = useState([]);

  useEffect(() => {
    if (options !== null && options.hasOwnProperty('data')) {
      if (tabID === 'general') {
        setInputs(options.data);
        extractSelection(options.data);
      } else {
        const data = {};
        options.data.map((item) => {
          if (!data.hasOwnProperty('ReportOptionTable')) {
            data.ReportOptionTable = [];
          }
          if (!data.hasOwnProperty('BOOLEAN')) {
            data.BOOLEAN = [];
          }
          if (item.hasOwnProperty('REPORTOPTION101')) {
            data.REPORTOPTION101 = item.REPORTOPTION101;
          }
          data.ReportOptionTable = data.ReportOptionTable.concat([
            item.ReportOptionTable[1],
            item.ReportOptionTable[0],
          ]);
          data.BOOLEAN = item.BOOLEAN;
        });

        setInputs(data);
        extractSelection(data);
      }
    }
  }, [options]);

  const extractSelection = (data) => {
    const result = {};
    for (const i in data) {
      if (i !== '_attributes' && i !== 'ReportOptionTable') {
        result[i] = [];
        data[i].map((item) => {
          result[i].push({
            name: item.Name._text,
            id: item.RcID._text,
          });
        });
      }
    }

    setSelections(result);
  };

  const onInputChange = (value, item, key) => {
    let index = 0;
    const formInputs = [...inputs.ReportOptionTable];

    index = formInputs.findIndex(
      (obj) => obj.OptionID._text === item.OptionID._text && obj.Prompt1 === item.Prompt1,
    );

    const prevValue = formInputs[index][key]._text;

    if (formInputs[index][key]._text !== value) {
      setHasChanges(true);
    }

    if (index >= 0) {
      formInputs[index][key]._text = value;
    }

    if (tabID === 'mobile') {
      options.data[0].ReportOptionTable = [formInputs[1], formInputs[0]];
      options.data[1].ReportOptionTable = [formInputs[3], formInputs[2]];
    }

    setInputs({ ...inputs, [item.DataType._text]: formInputs });
  };

  const getInputElement = (data) => {
    if (data.Prompt1._text === 'Enable Application') {
      return;
    }
    const row = [<Text>{data.Prompt1._text.replace('Mobile Device', '').trim()}</Text>];
    switch (data.DataType._text) {
      case 'LOOKUP':
        const type = data.LookupType._text;
        row.push(
          <Select
            name={data.OptionID._text}
            labelKey="name"
            options={selections[type]}
            value={getOptionValue(data.Int32Value._text, type)}
            onChange={({ option }) => onInputChange(option.id, data, 'Int32Value')}
          />,
        );
        break;
      case 'STRING':
        row.push(
          <TextInput
            value={data.StringValue._text}
            onChange={(event) => onInputChange(event.target.value, data, 'StringValue')}
          />,
        );
        break;
      default:
        break;
    }
    return row;
  };

  const getOptionValue = (value, type) => {
    let result = '';
    const index = selections[type].findIndex((obj) => obj.id === value);
    if (index >= 0) {
      result = selections[type][index].name;
    }
    return result;
  };

  const generateForm = (data) => {
    const formInputs = [];
    if (data.hasOwnProperty('ReportOptionTable')) {
      data.ReportOptionTable.forEach((item) => {
        formInputs.push(
          <Box padding="small" width="300px">
            {getInputElement(item)}
          </Box>,
        );
      });
    }

    return formInputs;
  };

  return (
    <Box gap="small" fill="horizontal" pad={{ left: '20px' }}>
      {tabID === 'mobile' && <Text>{selectedMobile.ObjtDesc.split('-')[0].trim()}</Text>}
      {generateForm(inputs)}
    </Box>
  );
};

export default SystemConfigForm;

import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import api from '@API';

import { selectAdvancedCurrentUsers } from '../setup-advanced-users/setup-advanced-users.selectors';
import {
  deleteAdvancedUserFailure,
  deleteAdvancedUserSuccess,
  getAdvancedUserFailure,
  getAdvancedUserStart,
  getAdvancedUserSuccess,
  setCurrentAdvancedUser,
  updateAdvancedUserFailure,
  updateAdvancedUserSuccess,
} from './setup-advanced-users.actions';
import SetupAdvancedUserActionTypes from './setup-advanced-users.types';

const AdvancedUserApi = (params) => {
  return api.post('security/get-users', params);
};

export function* getAdvancedUser(action) {
  try {
    const params = {};
    const { data } = yield call(AdvancedUserApi, params);
    yield put(getAdvancedUserSuccess(data.data));
    // yield put(setCurrentAdvancedUser(data.data[0]));
  } catch (error) {
    yield put(getAdvancedUserFailure(error));
  }
}

const deleteAdvancedUserApi = (params) => {
  return api.post('security/inactivate-user', params);
};

export function* deleteAdvancedUser(action) {
  try {
    const currentcustomer = yield select(selectAdvancedCurrentUsers);
    if (currentcustomer) {
      const params = { iUserID: currentcustomer.RcID };
      const { data } = yield call(deleteAdvancedUserApi, params);
      yield put(deleteAdvancedUserSuccess(data.data));
      yield put(getAdvancedUserStart());
      yield put(setCurrentAdvancedUser(null));
    }
  } catch (error) {
    yield put(deleteAdvancedUserFailure(error));
  }
}

const NEW_USER_ID = '0';
const API_ERROR_MESSAGES = {
  fetch: 'Failed to fetch users from the server. Please try again later.',
  update: 'Failed to update user data. Please try again.',
};

const getAllUsers = async () => {
  try {
    const response = await api.post('security/get-users');
    return response?.data;
  } catch (error) {
    console.error('API Error:', error?.message);
    throw new Error(API_ERROR_MESSAGES.fetch);
  }
};

const updateUserData = async (params) => {
  try {
    const response = await api.post('security/update-users', params);
    return response?.data;
  } catch (error) {
    console.error('API Error:', error?.message);
    throw new Error(API_ERROR_MESSAGES.update);
  }
};

function* fetchAllUsers() {
  const users = yield call(getAllUsers);
  return users?.data || [];
}

function userNameTakenByAnotherUser(users = [], id, name) {
  return users?.length > 0 && users.some((user) => user?.Name === name && user?.RcID !== id);
}

function isNewUser(id) {
  return id === NEW_USER_ID;
}

export function* updateAdvancedUser(action) {
  try {
    const [newCustomerData, users] = yield all([
      select(selectAdvancedCurrentUsers),
      call(fetchAllUsers),
    ]);

    const trimmedCustomerName = newCustomerData?.Name?.trim();
    const sanitizedCustomerName = trimmedCustomerName?.toUpperCase();

    const isUserNameAlreadyTaken = userNameTakenByAnotherUser(
      users,
      newCustomerData.RcID,
      sanitizedCustomerName,
    );

    if ((isNewUser(newCustomerData.RcID) && isUserNameAlreadyTaken) || isUserNameAlreadyTaken) {
      throw new Error(
        `The user name '${trimmedCustomerName}' is already taken. Please try another one.`,
      );
    }

    const updateResponse = yield call(updateUserData, {
      user: newCustomerData,
    });

    if (!updateResponse) {
      throw new Error(API_ERROR_MESSAGES.update);
    }

    yield put(updateAdvancedUserSuccess(updateResponse));
    yield put(getAdvancedUserStart());
  } catch (error) {
    yield put(updateAdvancedUserFailure(error));
  }
}

export function* onGetAdvancedUserStart() {
  yield takeLatest(SetupAdvancedUserActionTypes.GET_ADVANCED_USER_START, getAdvancedUser);
}

export function* onDeleteAdvancedUserStart() {
  yield takeLatest(SetupAdvancedUserActionTypes.DELETE_ADVANCED_USER_START, deleteAdvancedUser);
}

export function* onUpdateAdvancedUserStart() {
  yield takeLatest(SetupAdvancedUserActionTypes.UPDATE_ADVANCED_USER_START, updateAdvancedUser);
}

export function* advancedUserSagas() {
  yield all([
    call(onGetAdvancedUserStart),
    call(onDeleteAdvancedUserStart),
    call(onUpdateAdvancedUserStart),
  ]);
}

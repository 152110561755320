import { Box, Button, Grid } from 'grommet';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import validator from 'validator';

import Dialog from '@Components/dialog/dialog.component';
import DeleteModal from '@Components/setup-users-delete-modal/setup-users-delete-modal.component';
import { dialogConfig, formConfig, Links } from '@Pages/setup-users/setup-users.config';
import {
  deleteAdvancedUserStart,
  setCurrentAdvancedUser,
  updateAdvancedUserStart,
} from '@State/setup-advanced-users/setup-advanced-users.actions';
import {
  selectAdvancedCurrentUsers,
  selectError,
} from '@State/setup-advanced-users/setup-advanced-users.selectors';
import { getCustomerNotSelectedStart } from '@State/setup-users-customer-not-selected-in-user/setup-users-customer-not-selected-in-user.actions';
import {
  deleteCustomerSelectedStart,
  getCustomerSelectedStart,
  saveCustomerSelectedStart,
} from '@State/setup-users-customer-selected-in-user/setup-users-customer-selected-in-user.actions';
import {
  selectCustomersToAdd,
  selectCustomersToDelete,
} from '@State/setup-users-customer-selected-in-user/setup-users-customer-selected-in-user.selectors';
import {
  getDetailsForOrderSelectedStart,
  updateDetailsForOrderSelectedStart,
} from '@State/setup-users-details-for-order-selected-in-user/setup-users-details-for-order-selected-in-user.actions';
import { selectDetailsForOrderSelected } from '@State/setup-users-details-for-order-selected-in-user/setup-users-details-for-order-selected-in-user.selectors';
import { getOrderNotSelectedStart } from '@State/setup-users-order-not-selected-in-user/setup-users-order-not-selected-in-user.actions';
import {
  getOrderSelectedStart,
  saveOrderSelectedStart,
  setUserCurrentOrder,
} from '@State/setup-users-order-selected-in-user/setup-users-order-selected-in-user.actions';
import {
  selectOrdersSelected,
  selectOrdersToAdd,
  selectOrdersToDelete,
} from '@State/setup-users-order-selected-in-user/setup-users-order-selected-in-user.selectors';
import { selectCurrentUser } from '@State/user/user.selectors';
import { colors, Text16 } from '@ThemeMain';

import { StyleList } from './setup-advanced-header.styles';

const SetupHeader = (props) => {
  const {
    advancedCurrentUser,
    saveAdvancedUser,
    setCurrentUser,
    currentSelected,
    setCurrentSelected,
    deleteAdvancedUser,
    setChanges,
    changes,
    orders,
    getCustomerSelected,
    getCustomerNotSelected,
    saveCustomerSelected,
    deleteCustomerSelected,
    customersToAdd,
    customersToDelete,
    getOrderSelected,
    getOrderNotSelected,
    getDetailsForOrderSelected,
    saveOrderSelected,
    ordersToAdd,
    ordersToDelete,
    updateDetailsForOrderSelected,
    error,
  } = props;
  const [showDeleteUser, setShowDeleteUser] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');

  useEffect(() => {
    if (error) {
      setDialogTitle('Error');
      setDialogMessage(error.message);
      setShowDialog(true);
    }
  }, [error]);

  const save = async () => {
    saveAdvancedUser();
    customersToAdd.map((customer) => {
      saveCustomerSelected(customer);
    });
    customersToDelete.map((customer) => {
      deleteCustomerSelected(customer);
    });
    ordersToAdd.map((order) => {
      saveOrderSelected(order);
    });
    await Promise.all(
      ordersToDelete.map(async (order) => {
        updateDetailsForOrderSelected({ payload: '0', ...order });
      }),
    );
  };

  const validate = () => {
    let message = '';
    let error = false;

    for (const i in formConfig) {
      if (advancedCurrentUser[i] === '' && formConfig[i].required) {
        message += `${formConfig[i].vEmptyMessage}\n`;
        error = true;
      } else if (
        i === 'EmalAddr' &&
        !validator.isEmail(advancedCurrentUser[i]) &&
        formConfig[i].required
      ) {
        message += `${formConfig[i].vValueMessage}\n`;
        error = true;
      }
    }
    setDialogMessage(message);
    return error;
  };

  const handleSave = () => {
    if (validate()) {
      setDialogTitle(dialogConfig.titles.validationError);
      setShowDialog(true);
    } else if (orders === null && advancedCurrentUser && advancedCurrentUser.PrivEvryOrdr !== '1') {
      setDialogTitle(dialogConfig.titles.warning);
      setDialogMessage(dialogConfig.messages.noOrderWarning);
      setShowDialog(true);
    } else {
      save();
    }
  };

  const onCancel = () => {
    setCurrentUser(currentSelected);
    getCustomerSelected();
    getCustomerNotSelected();
    getOrderSelected();
    getOrderNotSelected();
    getDetailsForOrderSelected();
    setChanges([]);
  };

  const onNewUser = () => {
    const user = props.data;
    user.HomePageName = 'Linen Tech';
    setCurrentUser(user);
    setCurrentSelected(user);
  };

  return (
    <>
      <Box direction="row">
        {showDeleteUser ? (
          <DeleteModal
            type="Users"
            confirm={() => deleteAdvancedUser()}
            onClose={setShowDeleteUser}
          />
        ) : null}
        <Grid
          areas={[
            { name: 'nav', start: [0, 0], end: [1, 0] },
            { name: 'main', start: [1, 0], end: [1, 0] },
          ]}
          columns={['2/4', '2/4']}
          rows={['flex']}
          gap="small"
          fill
        >
          <Box gridArea="nav">
            <StyleList>
              <li>
                <Text16>{props.title}</Text16>
              </li>
              <li>
                <Button label="Add User" onClick={onNewUser} />
              </li>
              <li>
                <Button
                  label="Delete Users"
                  disabled={!advancedCurrentUser}
                  onClick={() => {
                    setShowDeleteUser(true);
                  }}
                />
              </li>
            </StyleList>
          </Box>
          <Box gridArea="main">
            <StyleList>
              <li>
                <Button label="Save" disabled={changes.length === 0} onClick={handleSave} />
              </li>
              <li>
                <Button label="Cancel" disabled={changes.length === 0} onClick={onCancel} />
              </li>
              <li>
                <Button
                  label="Help"
                  onClick={() => {
                    window.open(Links.help, '_blank');
                  }}
                />
              </li>
            </StyleList>
          </Box>
        </Grid>
      </Box>
      <Dialog
        showModal={showDialog}
        setShowModal={setShowDialog}
        message={dialogMessage}
        confirm={save}
        pageTitle={dialogTitle}
        showCancelButton={dialogTitle === dialogConfig.titles.warning}
        textColor={colors['neutral-4']}
        showOkButton
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  advancedCurrentUser: selectAdvancedCurrentUsers,
  orders: selectOrdersSelected,
  customersToAdd: selectCustomersToAdd,
  customersToDelete: selectCustomersToDelete,
  ordersToAdd: selectOrdersToAdd,
  ordersToDelete: selectOrdersToDelete,
  detailsForOrderSelected: selectDetailsForOrderSelected,
  error: selectError,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentAdvancedUser(user)),
  saveAdvancedUser: () => dispatch(updateAdvancedUserStart()),
  deleteAdvancedUser: () => dispatch(deleteAdvancedUserStart()),
  saveCustomerSelected: (customer) => dispatch(saveCustomerSelectedStart(customer)),
  deleteCustomerSelected: (customer) => dispatch(deleteCustomerSelectedStart(customer)),
  getCustomerSelected: () => dispatch(getCustomerSelectedStart()),
  getCustomerNotSelected: () => dispatch(getCustomerNotSelectedStart()),
  getOrderSelected: () => dispatch(getOrderSelectedStart()),
  getOrderNotSelected: () => dispatch(getOrderNotSelectedStart()),
  getDetailsForOrderSelected: () => dispatch(getDetailsForOrderSelectedStart()),
  saveOrderSelected: (order) => dispatch(saveOrderSelectedStart(order)),
  updateDetailsForOrderSelected: (order) => dispatch(updateDetailsForOrderSelectedStart(order)),
  setCurrentOrder: (order) => dispatch(setUserCurrentOrder(order)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SetupHeader));
